import { CancelToken } from 'axios';
import {
  LoginRequest,
  LoginResponse,
} from 'proto/v1/loginservice/loginservice';
import {
  OutOfBandRequest,
  OutOfBandResponse,
  ResetPasswordRequest,
  ResetPasswordResponse,
  RegisterPasswordRequest,
  RegisterPasswordResponse,
  SendPasswordResetEmailRequest,
  SendPasswordResetEmailResponse,
  VerifyEmailRequest,
  VerifyEmailResponse,
} from 'proto/v1/outofbandservice/outofbandservice';
import {
  GetActiveAccountResponse,
  ChangeAccountSettingsRequest,
  ChangeAccountSettingsResponse,
  GetAccountSettingsResponse,
  GetMyAccountsResponse,
  ChangeActiveAccountResponse,
  ChangeActiveAccountRequest,
  PublishActiveAccountRequest,
  PublishActiveAccountResponse,
  GetUserSettingsResponse,
  ChangeUserSettingsRequest,
  ChangeUserSettingsResponse,
} from 'proto/v1/accountservice/accountservice';

import client from './config/client';
import apiFirebase from '../firebase/firebase';

export default {
  login(req: LoginRequest): Promise<{ data: LoginResponse }> {
    const url = `${process.env.REACT_APP_API_URL}/v1/login`;
    return client()
      .post(url, LoginRequest.toJSON(req))
      .then(x => {
        return {
          data: LoginResponse.fromJSON(x.data),
        };
      });
  },

  sendPasswordResetEmail(
    req: SendPasswordResetEmailRequest,
  ): Promise<{ data: SendPasswordResetEmailResponse }> {
    const url = `${process.env.REACT_APP_API_URL}/v1/resetpassword/send`;
    return client()
      .post(url, SendPasswordResetEmailRequest.toJSON(req))
      .then(x => {
        return {
          data: SendPasswordResetEmailResponse.fromJSON(x.data),
        };
      });
  },

  resetPassword(
    req: ResetPasswordRequest,
  ): Promise<{ data: ResetPasswordResponse }> {
    const url = `${process.env.REACT_APP_API_URL}/v1/resetpassword`;
    return client()
      .post(url, ResetPasswordRequest.toJSON(req))
      .then(x => {
        return {
          data: ResetPasswordResponse.fromJSON(x.data),
        };
      });
  },

  registerPassword(
    req: RegisterPasswordRequest,
  ): Promise<{ data: RegisterPasswordResponse }> {
    const url = `${process.env.REACT_APP_API_URL}/v1/password`;
    return client()
      .post(url, RegisterPasswordRequest.toJSON(req))
      .then(x => {
        return {
          data: RegisterPasswordResponse.fromJSON(x.data),
        };
      });
  },

  checkOutOfBandExpired(
    params: OutOfBandRequest,
    requestCancelToken?: CancelToken,
  ): Promise<{ data: OutOfBandResponse }> {
    const url = `${process.env.REACT_APP_API_URL}/v1/oob`;
    return client()
      .get(url, {
        params,
        cancelToken: requestCancelToken,
      })
      .then(x => {
        return {
          data: OutOfBandResponse.fromJSON(x.data),
        };
      });
  },

  async verifyEmail(
    req: VerifyEmailRequest,
  ): Promise<{ data: VerifyEmailResponse }> {
    const url = `${process.env.REACT_APP_API_URL}/v1/verification_email`;
    return client()
      .post(url, VerifyEmailRequest.toJSON(req))
      .then(x => {
        return {
          data: VerifyEmailResponse.fromJSON(x.data),
        };
      });
  },

  async getUserSettings(
    requestCancelToken?: CancelToken,
  ): Promise<{ data: GetUserSettingsResponse }> {
    const token = await apiFirebase.currentToken();
    const url = `${process.env.REACT_APP_API_URL}/v1/user/setting`;
    const { data } = await client(token).get(url, {
      cancelToken: requestCancelToken,
    });
    return {
      data: GetUserSettingsResponse.fromJSON(data),
    };
  },

  async getAccountSettings(
    requestCancelToken?: CancelToken,
  ): Promise<{ data: GetAccountSettingsResponse }> {
    const token = await apiFirebase.currentToken();
    const url = `${process.env.REACT_APP_API_URL}/v1/account/setting`;
    return client(token)
      .get(url, { cancelToken: requestCancelToken })
      .then(x => {
        return {
          data: GetAccountSettingsResponse.fromJSON(x.data),
        };
      });
  },

  async getActiveAccount(
    requestCancelToken?: CancelToken,
  ): Promise<{ data: GetActiveAccountResponse }> {
    const token = await apiFirebase.currentToken();
    const url = `${process.env.REACT_APP_API_URL}/v1/active_account`;
    return client(token)
      .get(url, { cancelToken: requestCancelToken })
      .then(x => {
        return {
          data: GetActiveAccountResponse.fromJSON(x.data),
        };
      });
  },

  async changeActiveAccount(
    req: ChangeActiveAccountRequest,
  ): Promise<{
    data: ChangeActiveAccountResponse;
  }> {
    const token = await apiFirebase.currentToken();
    const url = `${process.env.REACT_APP_API_URL}/v1/active_account`;
    return client(token)
      .put(url, req)
      .then(x => {
        return { data: ChangeActiveAccountResponse.fromJSON(x.data) };
      });
  },

  async getMyAccounts(
    requestCancelToken?: CancelToken,
  ): Promise<{
    data: GetMyAccountsResponse;
  }> {
    const token = await apiFirebase.currentToken();
    const url = `${process.env.REACT_APP_API_URL}/v1/accountowner/accounts`;
    return client(token)
      .get(url, { cancelToken: requestCancelToken })
      .then(x => {
        return { data: GetMyAccountsResponse.fromJSON(x.data) };
      });
  },

  async changeUserSettings(
    req: ChangeUserSettingsRequest,
  ): Promise<{ data: ChangeUserSettingsResponse }> {
    const token = await apiFirebase.currentToken();
    const url = `${process.env.REACT_APP_API_URL}/v1/user/setting`;
    const { data } = await client(token).put(url, req);
    return {
      data: ChangeUserSettingsResponse.fromJSON(data),
    };
  },

  async changeAccountSettings(
    req: ChangeAccountSettingsRequest,
  ): Promise<{ data: ChangeAccountSettingsResponse }> {
    const token = await apiFirebase.currentToken();
    const url = `${process.env.REACT_APP_API_URL}/v1/account/setting`;
    return (
      client(token)
        /*
          Do not use toJson method on request
          - `notificationDisabled` property can take `false` value
          - but toJson method convert `false` into `undefined`
        */
        .put(url, req)
        .then(x => {
          return {
            data: ChangeAccountSettingsResponse.fromJSON(x.data),
          };
        })
    );
  },

  async putPublishActiveAccount(
    req: PublishActiveAccountRequest,
  ): Promise<{ data: PublishActiveAccountResponse }> {
    const token = await apiFirebase.currentToken();
    const url = `${process.env.REACT_APP_API_URL}/v1/active_account/publish`;
    return client(token)
      .put(url, PublishActiveAccountRequest.toJSON(req))
      .then(x => {
        return {
          data: PublishActiveAccountResponse.fromJSON(x.data),
        };
      });
  },
};
