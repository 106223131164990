import { useState, useCallback, useEffect, useMemo } from 'react';
import { MissionFormErrors, MissionFormValues } from 'types/mission';
import {
  getMissionMembersErrorMessages,
  filterEmptyErrors,
} from '../../../validate/validateToSave';
import { validateMissionFormValuesToPublish } from '../../validateToPublish';
import { isOverMissionNameMaxLength } from '../../../validate/utils';
import useMissionMembersField from '../../../hooks/useMissionMembersField';
import useMissionOfficeImagesField from '../../../hooks/useMissionOfficeImagesField';
import useMissionCategoriesField from '../../../hooks/useMissionCategoriesField';
import { stepAboutMissionSchema } from '../../validationSchema';
import useMissionFormContext from '../../useMissionFormContext';
import useMissionFormFormik from '../../useMissionFormFormik';

type Values = Pick<
  MissionFormValues,
  | 'name'
  | 'strengthPoint'
  | 'missionDescription'
  | 'memberRangeId'
  | 'coverImage'
  | 'missionOfficeImages'
  | 'missionMembers'
  | 'missionCategories'
>;

const useStepAboutMission = () => {
  const {
    movePrevStep,
    moveNextStep,
    form,
    isFirstPublished,
    missionFormValues,
    openImageCropper,
    isPublished,
    updateMission,
    publishMission,
    unPublishMission,
    imageCropperState,
    cropperAspectRatio,
    handleExceedImageFileSize,
    handleCroppedImage,
    handleClickCropperCloseButton,
    modalState,
    closeModal,
    openModal,
    validateToSaveErrors,
    setValidateToSaveErrors,
    validateToPublishStepsResult,
    setValidateToPublishStepsResult,
    getAddedItemId,
    missionOfficeImagesFieldRef,
    openPreview,
    canUseMissionFormStepMissionDemand,
    canUseMissionFormStepMissionJobOffers,
  } = useMissionFormContext();

  const defaultInitialValues: Values = {
    name: '',
    strengthPoint: '',
    missionDescription: '',
    memberRangeId: 0,
    coverImage: undefined,
    missionOfficeImages: [
      {
        missionOfficeImageId: getAddedItemId('missionOfficeImages'),
        image: undefined,
      },
    ],
    missionMembers: [
      {
        missionMemberId: getAddedItemId('missionMembers'),
        name: '',
        career: '',
        iconImage: undefined,
      },
    ],
    missionCategories: [],
  };

  const [initialValues, setInitialValues] = useState<Values>(
    defaultInitialValues,
  );

  const {
    formik,
    formError,
    handleChangeSelectedValueAsNumber,
  } = useMissionFormFormik<Values>({
    initialValues,
    validationSchema: stepAboutMissionSchema,
  });

  const {
    dirty,
    values,
    errors,
    touched,
    setFieldValue,
    setFieldTouched,
    resetForm,
  } = formik;

  const isDisabledSaveButton = useMemo(
    () => !dirty || isOverMissionNameMaxLength(values.name),
    [dirty, values],
  );

  const getErrorsToSave = useCallback(
    () =>
      filterEmptyErrors({
        missionMembers: getMissionMembersErrorMessages(
          errors.missionMembers as MissionFormErrors['missionMembers'],
          values.missionMembers,
        ),
      }),
    [errors.missionMembers, values.missionMembers],
  );

  const validateToSave = useCallback(() => {
    if (!missionFormValues) return;

    const errorsToSave = getErrorsToSave();
    if (Object.keys(errorsToSave).length > 0) {
      setValidateToSaveErrors(errorsToSave);
      openModal('invalidToSave');
      return false;
    }

    const { isValid, stepsResult } = validateMissionFormValuesToPublish(
      {
        ...missionFormValues,
        ...values,
      },
      canUseMissionFormStepMissionDemand,
      canUseMissionFormStepMissionJobOffers,
    );
    if (!isValid) {
      setValidateToPublishStepsResult(stepsResult);
      openModal('saveRequiredNoInputConfirmation');
      return false;
    }

    return true;
  }, [
    canUseMissionFormStepMissionDemand,
    canUseMissionFormStepMissionJobOffers,
    getErrorsToSave,
    missionFormValues,
    openModal,
    setValidateToPublishStepsResult,
    setValidateToSaveErrors,
    values,
  ]);

  const validateToPublish = useCallback(() => {
    if (!missionFormValues) return;

    const errorsToSave = getErrorsToSave();
    if (Object.keys(errorsToSave).length > 0) {
      setValidateToSaveErrors(errorsToSave);
      openModal('invalidToSave');
      return false;
    }

    const { isValid, stepsResult } = validateMissionFormValuesToPublish(
      {
        ...missionFormValues,
        ...values,
      },
      canUseMissionFormStepMissionDemand,
      canUseMissionFormStepMissionJobOffers,
    );

    setValidateToPublishStepsResult(stepsResult);

    if (!isValid) {
      openModal('invalidToPublish');
      return false;
    }

    return true;
  }, [
    canUseMissionFormStepMissionDemand,
    canUseMissionFormStepMissionJobOffers,
    getErrorsToSave,
    missionFormValues,
    openModal,
    setValidateToPublishStepsResult,
    setValidateToSaveErrors,
    values,
  ]);

  const handleClickSave = useCallback(() => {
    if (!dirty) return;
    if (!validateToSave()) return;
    openModal('saveMissionConfirmation');
  }, [dirty, openModal, validateToSave]);

  const saveMission = useCallback(async () => {
    if (!missionFormValues) return;
    try {
      await updateMission({ ...missionFormValues, ...values });
    } catch (error) {
      throw new Error('Failed to update mission');
    }
    setInitialValues(values);
    resetForm();
  }, [missionFormValues, resetForm, updateMission, values]);

  const handleClickNext = useCallback(async () => {
    if (dirty) {
      const errorsToSave = getErrorsToSave();
      if (Object.keys(errorsToSave).length > 0) {
        setValidateToSaveErrors(errorsToSave);
        openModal('invalidToSave');
        return;
      }
      try {
        await saveMission();
      } catch (error) {
        // Nothing to do.
        return;
      }
    }
    moveNextStep();
  }, [
    dirty,
    getErrorsToSave,
    moveNextStep,
    openModal,
    saveMission,
    setValidateToSaveErrors,
  ]);

  const handleClickSaveConfirmationOK = useCallback(async () => {
    try {
      await saveMission();
    } catch (error) {
      // Nothing to do.
      return;
    }
    openModal('saveMissionCompleted');
  }, [openModal, saveMission]);

  const handleClickPublish = useCallback(async () => {
    if (!validateToPublish()) {
      return;
    }
    openModal('publishMissionConfirmation');
  }, [openModal, validateToPublish]);

  const handleClickPublishConfirmationOk = useCallback(async () => {
    if (!missionFormValues) return;
    try {
      await publishMission({ ...missionFormValues, ...values });
    } catch (error) {
      // Nothing to do.
      return;
    }
    setInitialValues(values);
    resetForm();
  }, [missionFormValues, publishMission, resetForm, values]);

  const handleClickUnPublishConfirmationOk = useCallback(async () => {
    if (!missionFormValues) return;
    try {
      await unPublishMission({ ...missionFormValues, ...values });
    } catch (error) {
      // Nothing to do.
      return;
    }
    setInitialValues(values);
    resetForm();
  }, [missionFormValues, resetForm, unPublishMission, values]);

  const { handleChangeCategory } = useMissionCategoriesField({
    missionCategoryIds: values.missionCategories,
    setFieldValue,
    setFieldTouched,
  });

  const {
    handleAddMember,
    handleDeleteMember,
    handleChangeMissionMemberIconImage,
    missionMembersError,
  } = useMissionMembersField({
    missionMembers: values.missionMembers,
    openImageCropper,
    getAddedItemId,
    touched: touched.missionMembers,
    errors: errors.missionMembers,
    setFieldValue,
    setFieldTouched,
  });

  const {
    handleAddOfficeImage,
    handleChangeOfficeImage,
    handleDeleteOfficeImage,
  } = useMissionOfficeImagesField({
    missionOfficeImages: values.missionOfficeImages,
    getAddedItemId,
    openImageCropper,
    setFieldValue,
  });

  // Set initial values for fetched mission.
  useEffect(() => {
    if (!missionFormValues) return;
    setInitialValues({
      name: missionFormValues.name,
      strengthPoint: missionFormValues.strengthPoint,
      missionDescription: missionFormValues.missionDescription,
      memberRangeId: missionFormValues.memberRangeId,
      coverImage: missionFormValues.coverImage,
      missionMembers:
        missionFormValues.missionMembers.length === 0
          ? defaultInitialValues.missionMembers
          : missionFormValues.missionMembers,
      missionOfficeImages:
        missionFormValues.missionOfficeImages.length === 0
          ? defaultInitialValues.missionOfficeImages
          : missionFormValues.missionOfficeImages,
      missionCategories: missionFormValues.missionCategories,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [missionFormValues]);

  return {
    handleClickNext,
    movePrevStep,

    isPublished,
    isFirstPublished,
    formik,
    formError,
    handleChangeSelectedValueAsNumber,
    form,
    isDisabledSaveButton,
    openImageCropper,
    imageCropperState,
    cropperAspectRatio,
    handleCroppedImage,
    handleExceedImageFileSize,
    handleClickCropperCloseButton,
    modalState,
    closeModal,
    handleClickSave,
    validateToSaveErrors,
    handleClickSaveConfirmationOK,
    handleChangeCategory,
    handleAddMember,
    handleDeleteMember,
    handleChangeMissionMemberIconImage,
    missionMembersError,
    handleAddOfficeImage,
    handleChangeOfficeImage,
    handleDeleteOfficeImage,
    missionOfficeImagesFieldRef,
    handleClickPublish,
    handleClickPublishConfirmationOk,
    handleClickUnPublishConfirmationOk,
    validateToPublishStepsResult,
    openPreview,
    canUseMissionFormStepMissionDemand,
    canUseMissionFormStepMissionJobOffers,
  };
};

export default useStepAboutMission;
