enum ErrorCodes {
  MISSION_NOT_FOUND = 'MISSION_NOT_FOUND',
  FAILED_TO_FOLLOW_MISSION_BECAUSE_OWN_MISSION_IS_NOT_PUBLISHED = 'FAILED_TO_FOLLOW_MISSION_BECAUSE_OWN_MISSION_IS_NOT_PUBLISHED',
  FAILED_TO_FOLLOW_MISSION_BECAUSE_OPPONENT_MISSION_IS_NOT_PUBLISHED = 'FAILED_TO_FOLLOW_MISSION_BECAUSE_OPPONENT_MISSION_IS_NOT_PUBLISHED',
  FAILED_TO_FOLLOW_MISSION_BECAUSE_OWN_SUPPORTER_IS_NOT_PUBLISHED = 'FAILED_TO_FOLLOW_MISSION_BECAUSE_OWN_SUPPORTER_IS_NOT_PUBLISHED',
  SUPPORTER_NOT_FOUND = 'SUPPORTER_NOT_FOUND',
  FAILED_TO_FOLLOW_SUPPORTER_BECAUSE_OWN_MISSION_IS_NOT_PUBLISHED = 'FAILED_TO_FOLLOW_SUPPORTER_BECAUSE_OWN_MISSION_IS_NOT_PUBLISHED',
  FAILED_TO_FOLLOW_SUPPORTER_BECAUSE_OPPONENT_SUPPORTER_IS_NOT_PUBLISHED = 'FAILED_TO_FOLLOW_SUPPORTER_BECAUSE_OPPONENT_SUPPORTER_IS_NOT_PUBLISHED',
  FAILED_TO_FOLLOW_SUPPORTER_BECAUSE_OWN_SUPPORTER_IS_NOT_PUBLISHED = 'FAILED_TO_FOLLOW_SUPPORTER_BECAUSE_OWN_SUPPORTER_IS_NOT_PUBLISHED',
  FAILED_TO_CREATE_CHAT_ROOM_BECAUSE_OPPONENT_MISSION_IS_NOT_PUBLISHED = 'FAILED_TO_CREATE_CHAT_ROOM_BECAUSE_OPPONENT_MISSION_IS_NOT_PUBLISHED',
  FAILED_TO_CREATE_CHAT_ROOM_BECAUSE_OWN_MISSION_IS_NOT_PUBLISHED = 'FAILED_TO_CREATE_CHAT_ROOM_BECAUSE_OWN_MISSION_IS_NOT_PUBLISHED',
  FAILED_TO_POST_MESSAGE_BECAUSE_OPPONENT_MISSION_IS_NOT_PUBLISHED = 'FAILED_TO_POST_MESSAGE_BECAUSE_OPPONENT_MISSION_IS_NOT_PUBLISHED',
  FAILED_TO_POST_MESSAGE_BECAUSE_OPPONENT_INNOVATOR_IS_NOT_PUBLISHED = 'FAILED_TO_POST_MESSAGE_BECAUSE_OPPONENT_INNOVATOR_IS_NOT_PUBLISHED',
  FAILED_TO_POST_MESSAGE_BECAUSE_OWN_MISSION_IS_NOT_PUBLISHED = 'FAILED_TO_POST_MESSAGE_BECAUSE_OWN_MISSION_IS_NOT_PUBLISHED',
  FAILED_TO_UPLOAD_FILE = 'FAILED_TO_UPLOAD_FILE',
  FAILED_TO_UPLOAD_FILE_BECAUSE_OWN_MISSION_IS_NOT_PUBLISHED = 'FAILED_TO_UPLOAD_FILE_BECAUSE_OWN_MISSION_IS_NOT_PUBLISHED',
  FAILED_TO_DELETE_CHAT_MESSAGE = 'FAILED_TO_DELETE_CHAT_MESSAGE',
  FAILED_TO_DELETE_CHAT_MESSAGE_BECAUSE_THIS_MESSAGE_IS_NOT_POSTED_BY_THIS_USER = 'FAILED_TO_DELETE_CHAT_MESSAGE_BECAUSE_THIS_MESSAGE_IS_NOT_POSTED_BY_THIS_USER',
  FAILED_TO_DELETE_CHAT_MESSAGE_BECAUSE_NOT_FOUND = 'FAILED_TO_DELETE_CHAT_MESSAGE_BECAUSE_NOT_FOUND',
  FAILED_TO_DELETE_CHAT_MESSAGE_BECAUSE_NOT_MEMBER = 'FAILED_TO_DELETE_CHAT_MESSAGE_BECAUSE_NOT_MEMBER',
  MATTER_NOT_FOUND = 'MATTER_NOT_FOUND',
  FAILED_TO_CONTACT_MATTER_BECAUSE_IT_IS_NOT_PUBLISHED = 'FAILED_TO_CONTACT_MATTER_BECAUSE_IT_IS_NOT_PUBLISHED',
  FAILED_TO_CONTACT_MATTER_BECAUSE_OWN_MISSION_IS_NOT_PUBLISHED = 'FAILED_TO_CONTACT_MATTER_BECAUSE_OWN_MISSION_IS_NOT_PUBLISHED',
  FAILED_TO_SCOUT_MATTER_BECAUSE_IT_IS_NOT_PUBLISHED = 'FAILED_TO_SCOUT_MATTER_BECAUSE_IT_IS_NOT_PUBLISHED',
  FAILED_TO_SCOUT_MATTER_BECAUSE_OWN_MISSION_IS_NOT_PUBLISHED = 'FAILED_TO_SCOUT_MATTER_BECAUSE_OWN_MISSION_IS_NOT_PUBLISHED',
  FAILED_TO_GET_MATTER_FOR_BULK_SCOUT_BECAUSE_OF_EXCEEDING_MAX_BULK_SCOUT_COUNT = 'FAILED_TO_GET_MATTER_FOR_BULK_SCOUT_BECAUSE_OF_EXCEEDING_MAX_BULK_SCOUT_COUNT',
}

export default ErrorCodes;
