import React, { FC } from 'react';
import styled from 'styled-components/macro';
import { useTranslation } from 'react-i18next';
import { ButtonM } from 'components/atoms';
import { Modal, ModalContent, ModalFooter } from 'components/molecules';

const WarningImageFileSizeModalContent = styled.div`
  line-height: 1.5;
  text-align: center;
`;

type ImageFileSizeExceededModalProps = {
  show: boolean;
  closeModal: () => void;
};

const ImageFileSizeExceededModal: FC<ImageFileSizeExceededModalProps> = ({
  show,
  closeModal,
}) => {
  const { t } = useTranslation('missionSetting');
  return (
    <Modal type={'imageFileSizeExceeded'} show={show} closeModal={closeModal}>
      <ModalContent>
        <WarningImageFileSizeModalContent>
          {t('modal.warningFileSize.content')}
        </WarningImageFileSizeModalContent>
      </ModalContent>
      <ModalFooter>
        <ButtonM
          onClick={() => {
            closeModal();
          }}
          buttonTheme="default"
          type="button"
        >
          {t('common:button.ok')}
        </ButtonM>
      </ModalFooter>
    </Modal>
  );
};

export default ImageFileSizeExceededModal;
