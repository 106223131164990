import React, { FC } from 'react';
import styled from 'styled-components/macro';
import { useTranslation } from 'react-i18next';
import { GalleryImage } from 'proto/v1/apimodel/apimodel';
import { SupporterFormValues } from 'types/supporter';
import { GALLERY_IMAGES_MAX_COUNT } from 'constants/config';
import { imageSelector } from 'utils/imageSelector';
import { isEqualProps } from 'utils/object';
import { PlusGray } from 'assets/svg';
import {
  DeletableBlock,
  ImageUploader,
  FormItemLabel,
  MissionSettingFormItem,
  OutlinedIconButton,
} from 'components/molecules';

const AddGalleryImageField = styled.div`
  display: flex;
  justify-content: center;
  padding-top: 12px;
`;

const FormField = styled.div`
  display: flex;
  justify-content: space-between;

  .input-fields {
    width: 100%;
  }
`;

type GalleryImagesFieldProps = {
  value: SupporterFormValues['galleryImages'];
  onChangeImage: (imageSrc: string, index: number) => void;
  onAddImage: () => void;
  onDeleteImage: (missionOfficeImageId: GalleryImage['galleryImageId']) => void;
  onExceedImageFileSize: () => void;
};

const GalleryImagesField: FC<GalleryImagesFieldProps> = props => {
  const {
    value,
    onAddImage,
    onDeleteImage,
    onChangeImage,
    onExceedImageFileSize,
  } = props;

  const { t } = useTranslation('missionSetting');

  return (
    <MissionSettingFormItem style={{ marginBottom: 0 }}>
      <FormItemLabel
        label={t('field.galleryImages.label')}
        note={t('field.galleryImages.note', { returnObjects: true })}
      />
      <div>
        {value &&
          value.map((galleryImage: GalleryImage, index: number) => (
            <DeletableBlock
              onDelete={() => {
                onDeleteImage(galleryImage.galleryImageId);
              }}
              key={galleryImage && galleryImage.galleryImageId}
            >
              <FormField data-testid="gallery-image-form">
                <ImageUploader
                  imageSrc={imageSelector(galleryImage.image, 'large')}
                  onUpload={uploadedImage => {
                    onChangeImage(uploadedImage, index);
                  }}
                  onExceededFileSizeLimit={onExceedImageFileSize}
                  width={445}
                  height={297}
                />
              </FormField>
            </DeletableBlock>
          ))}
        {(!value || (value && value.length < GALLERY_IMAGES_MAX_COUNT)) && (
          <AddGalleryImageField>
            <OutlinedIconButton
              icon={<PlusGray />}
              onClick={onAddImage}
              data-testid="add-gallery-image"
            >
              {t('field.galleryImages.add')}
            </OutlinedIconButton>
          </AddGalleryImageField>
        )}
      </div>
    </MissionSettingFormItem>
  );
};

export default React.memo(GalleryImagesField, (prevProps, nextProps) =>
  isEqualProps(prevProps, nextProps, ['value']),
);
