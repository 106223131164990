import styled from 'styled-components/macro';
import typography from 'styles/typography';
import theme from 'styles/theme';

const DetailSubTitle = styled.div`
  ${typography.textBold}
  margin-bottom: 8px;
  color: ${theme.textSecondary};
`;

export default DetailSubTitle;
