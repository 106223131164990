import React, { FC, ComponentProps } from 'react';
import { useTranslation } from 'react-i18next';
import { isEqualProps } from 'utils/object';
import {
  ImageUploader,
  FormItemSubHeading,
  MissionSettingFormItem,
} from 'components/molecules';

type ImageUploaderProps = ComponentProps<typeof ImageUploader>;

type ImageFieldProps = { index: number; error: string | undefined } & Pick<
  ImageUploaderProps,
  'imageSrc' | 'onUpload' | 'onExceededFileSizeLimit'
>;

const ImageField: FC<ImageFieldProps> = props => {
  const { index, imageSrc, error, onUpload, onExceededFileSizeLimit } = props;

  const { t } = useTranslation('missionSetting');

  return (
    <MissionSettingFormItem error={error}>
      <FormItemSubHeading
        label={t('field.missionProducts.subField.image.label')}
        note={t('field.missionProducts.subField.image.note', {
          returnObjects: true,
        })}
      />
      <ImageUploader
        imageSrc={imageSrc}
        name={`missionProducts[${index}].image`}
        onUpload={onUpload}
        onExceededFileSizeLimit={onExceededFileSizeLimit}
        width={445}
        height={250}
      />
    </MissionSettingFormItem>
  );
};

export default React.memo(ImageField, (prevProps, nextProps) =>
  isEqualProps(prevProps, nextProps, ['imageSrc', 'error']),
);
