import { css } from 'styled-components/macro';

const textDefault = css`
  font-size: 14px;
  line-height: 1.5;
`;

const textBold = css`
  font-size: 14px;
  font-weight: bold;
  line-height: 1.5;
`;

const textSub = css`
  font-size: 12px;
  line-height: 1.5;
`;

const textNote = css`
  font-size: 11px;
  line-height: 1.5;
`;

const textMyAccountSelect = css`
  font-size: 11px;
  line-height: 16px;
`;

const textCardTitle = css`
  font-size: 15px;
  font-weight: bold;
  line-height: 1.5;
`;

const textMission = css`
  font-size: 14px;
  line-height: 26px;
`;

const textLink = css`
  font-size: 14px;
  line-height: 1.5;
`;

const textError = css`
  font-size: 11px;
  line-height: 1.5;
`;

const textFooter = css`
  font-size: 12px;
  line-height: 1.5;
`;

const textCopyright = css`
  font-size: 11px;
  line-height: 1.5;
`;

const headlineDefault = css`
  font-size: 16px;
  font-weight: bold;
  line-height: 1.5;
`;

const titleDefault = css`
  font-size: 18px;
  font-weight: bold;
  line-height: 1.5;
`;

const titlePage = css`
  font-size: 20px;
  font-weight: bold;
  line-height: 1.5;
`;

const labelDefault = css`
  font-size: 12px;
  line-height: 1.5;
`;

const labelBold = css`
  font-size: 12px;
  font-weight: bold;
  line-height: 1;
`;

const labelCategory = css`
  font-size: 10px;
  font-weight: bold;
  line-height: 1.5;
`;

const labelTags = css`
  font-size: 10px;
  line-height: 14px;
`;

export default {
  textDefault,
  textBold,
  textSub,
  textNote,
  textMyAccountSelect,
  textCardTitle,
  textMission,
  textLink,
  textError,
  textFooter,
  textCopyright,
  headlineDefault,
  titleDefault,
  titlePage,
  labelDefault,
  labelBold,
  labelCategory,
  labelTags,
};
