import { SupporterFormValues } from 'types/supporter';
import useGenerateKey from '../../hooks/useGenerateKey';

const useDefaultInitialValues = ({
  getAddedItemId,
}: ReturnType<typeof useGenerateKey>) => {
  const defaultInitialValues: SupporterFormValues = {
    supporterId: '',
    name: '',
    iconImage: undefined,
    coverImage: undefined,
    supportDescription: '',
    strengthPoint: '',
    companyName: '',
    companyUrl: '',
    countryId: '',
    japanPrefectureId: undefined,
    otherAddress: '',
    memberRangeId: 0,
    companyEstablishedDate: undefined,
    supportAreaIds: [],
    supporterMembers: [
      {
        supporterMemberId: getAddedItemId('supporterMembers'),
        name: '',
        career: '',
        iconImage: undefined,
      },
    ],
    galleryImages: [
      {
        galleryImageId: getAddedItemId('galleryImages'),
        image: undefined,
      },
    ],
  };

  return defaultInitialValues;
};

export default useDefaultInitialValues;
