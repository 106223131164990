import { useMemo } from 'react';
import { SupporterFormValues } from 'types/supporter';
import useMissionSettingFormFields from '../../hooks/useMissionSettingFormFields';
import { isOverMissionNameMaxLength } from '../../validate/utils';
import validateToSaveSupporter from '../validateToSaveSupporter';
import { validationSchema, requiredSchema } from '../validationSchema';
import { SupporterFormFormik } from './useSupporterFormFormik';
import { SupporterFormState } from './useSupporterFormState';

const useSupporterFormFields = (
  {
    headerRef,
    formWrapperRef,
    optionalFieldsTitleRef,
    isPublished,
    setShowPreview,
    setShowRequiredFieldsTitle,
    setFooterWidth,
    setValidateToSaveErrors,
    openModal,
  }: SupporterFormState,
  {
    values,
    errors,
    touched,
    dirty,
    setFieldValue,
    setFieldTouched,
  }: SupporterFormFormik,
) => {
  const {
    handleScroll,
    saveInput,
    formError,
    handleChangeChecked,
    handleChangeSelectedValueAsNumber,
    handleClickPreview,
  } = useMissionSettingFormFields<SupporterFormValues>({
    dirty,
    values,
    errors,
    touched,
    headerRef,
    formWrapperRef,
    optionalFieldsTitleRef,
    requiredSchema,
    openModal,
    setShowPreview,
    setShowRequiredFieldsTitle,
    setFooterWidth,
    setValidateToSaveErrors,
    setFieldValue,
    setFieldTouched,
    validateToSave: validateToSaveSupporter,
  });

  const isPublishable = useMemo(() => {
    const isValid = validationSchema.isValidSync(values);
    if (!isValid) return false;
    return isPublished ? dirty : true;
  }, [dirty, isPublished, values]);

  const isDisabledSaveButton = useMemo(
    () => !dirty || isOverMissionNameMaxLength(values.name),
    [dirty, values],
  );

  return {
    handleScroll,
    formError,
    handleChangeChecked,
    handleChangeSelectedValueAsNumber,
    handleClickPreview,
    isPublishable,
    isDisabledSaveButton,
    saveInput,
  };
};

export default useSupporterFormFields;
