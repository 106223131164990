import React, { FC } from 'react';
import styled from 'styled-components/macro';
import { useTranslation } from 'react-i18next';
import { ButtonXL, WrapText } from 'components/atoms';
import { InformationIcon } from 'components/molecules';
import theme from 'styles/theme';
import typography from 'styles/typography';

const Wrapper = styled.div`
  text-align: center;
`;

const UncontactableNote = styled(WrapText)`
  ${typography.textNote}
  display: flex;
  margin-top: 8px;
  padding-left: 12px;
  color: ${theme.textSecondary};
  text-align: left;
`;

type ContactButtonContentProps = {
  labelContact: string;
  labelContacted: string;
  show: boolean;
  isFollowed?: boolean;
  disabled?: boolean;
  onFollow?: (() => void) | undefined;
  buttonRef?: (ref: HTMLButtonElement | null) => void;
  isPreview?: boolean;
  style?: React.CSSProperties;
};

const ContactButtonContent: FC<ContactButtonContentProps> = props => {
  const {
    labelContact,
    labelContacted,
    show,
    onFollow,
    buttonRef,
    style,
    isFollowed = false,
    disabled = false,
    isPreview = false,
  } = props;
  const { t } = useTranslation('missionDetail');

  if (!show) return null;

  return (
    <Wrapper style={style}>
      {isFollowed ? (
        <ButtonXL
          buttonTheme="gradation"
          disabled
          ref={buttonRef}
          data-testid="followed-button"
        >
          {labelContacted}
        </ButtonXL>
      ) : (
        <>
          <ButtonXL
            onClick={onFollow}
            buttonTheme="gradation"
            ref={buttonRef}
            disabled={disabled}
            style={isPreview ? { cursor: 'not-allowed' } : {}}
            data-testid="follow-button"
          >
            {labelContact}
          </ButtonXL>
          {disabled && (
            <UncontactableNote>
              <InformationIcon description={t('tooltip.uncontactable')} />
              {t('description.uncontactableNote')}
            </UncontactableNote>
          )}
        </>
      )}
    </Wrapper>
  );
};

export default ContactButtonContent;
