import { useState, useCallback } from 'react';

const useContactModal = () => {
  const [showContactModal, setShowContactModal] = useState<boolean>(false);
  const handleToggleContactModal = useCallback(() => {
    setShowContactModal(!showContactModal);
  }, [showContactModal]);

  return {
    showContactModal,
    handleToggleContactModal,
  };
};

export default useContactModal;
