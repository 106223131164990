import { ChatRoom, ChatMessage } from '../../../proto/v1/apimodel/apimodel';
import { Reader, Writer } from 'protobufjs/minimal';
import * as Long from 'long';


export interface CreateChatRoomRequest {
  accountId: string;
}

export interface CreateChatRoomResponse {
  chatRoomId: string;
}

export interface GetChatRoomListRequest {
  limit: number;
  chatRoomIdBefore: string;
  updatedAtBefore: number;
}

export interface GetChatRoomListResponse {
  chatRooms: ChatRoom[];
}

export interface GetChatRoomUpdatesRequest {
  limit: number;
  chatRoomIdAfter: string;
  updatedAtAfter: number;
}

export interface GetChatRoomUpdatesResponse {
  chatRooms: ChatRoom[];
}

export interface GetChatMessagesRequest {
  chatRoomId: string;
  limit: number;
  messageIdBefore: number;
  messageIdAfter: number;
}

export interface GetChatMessagesResponse {
  messages: ChatMessage[];
  prevQuery: string;
  nextQuery: string;
}

export interface PostMessageRequest {
  chatRoomId: string;
  message: string;
}

export interface PostMessageResponse {
}

export interface PostFileRequest {
  chatRoomId: string;
  data: string;
  fileName: string;
}

export interface PostFileResponse {
}

export interface DeleteMessageRequest {
  chatRoomId: string;
  messageId: number;
}

export interface DeleteMessageResponse {
}

export interface FlushUnreadCountRequest {
  chatRoomId: string;
}

export interface FlushUnreadCountResponse {
}

const baseCreateChatRoomRequest: object = {
  accountId: "",
};

const baseCreateChatRoomResponse: object = {
  chatRoomId: "",
};

const baseGetChatRoomListRequest: object = {
  limit: 0,
  chatRoomIdBefore: "",
  updatedAtBefore: 0,
};

const baseGetChatRoomListResponse: object = {
  chatRooms: undefined,
};

const baseGetChatRoomUpdatesRequest: object = {
  limit: 0,
  chatRoomIdAfter: "",
  updatedAtAfter: 0,
};

const baseGetChatRoomUpdatesResponse: object = {
  chatRooms: undefined,
};

const baseGetChatMessagesRequest: object = {
  chatRoomId: "",
  limit: 0,
  messageIdBefore: 0,
  messageIdAfter: 0,
};

const baseGetChatMessagesResponse: object = {
  messages: undefined,
  prevQuery: "",
  nextQuery: "",
};

const basePostMessageRequest: object = {
  chatRoomId: "",
  message: "",
};

const basePostMessageResponse: object = {
};

const basePostFileRequest: object = {
  chatRoomId: "",
  data: "",
  fileName: "",
};

const basePostFileResponse: object = {
};

const baseDeleteMessageRequest: object = {
  chatRoomId: "",
  messageId: 0,
};

const baseDeleteMessageResponse: object = {
};

const baseFlushUnreadCountRequest: object = {
  chatRoomId: "",
};

const baseFlushUnreadCountResponse: object = {
};

export interface ChatService {

  CreateChatRoom(request: CreateChatRoomRequest): Promise<CreateChatRoomResponse>;

  GetChatRoomList(request: GetChatRoomListRequest): Promise<GetChatRoomListResponse>;

  GetChatRoomUpdates(request: GetChatRoomUpdatesRequest): Promise<GetChatRoomUpdatesResponse>;

  GetChatMessages(request: GetChatMessagesRequest): Promise<GetChatMessagesResponse>;

  PostMessage(request: PostMessageRequest): Promise<PostMessageResponse>;

  DeleteMessage(request: DeleteMessageRequest): Promise<DeleteMessageResponse>;

  PostFile(request: PostFileRequest): Promise<PostFileResponse>;

  FlushUnreadCount(request: FlushUnreadCountRequest): Promise<FlushUnreadCountResponse>;

}

export class ChatServiceClientImpl implements ChatService {

  private readonly rpc: Rpc;

  constructor(rpc: Rpc) {
    this.rpc = rpc;
  }

  CreateChatRoom(request: CreateChatRoomRequest): Promise<CreateChatRoomResponse> {
    const data = CreateChatRoomRequest.encode(request).finish();
    const promise = this.rpc.request("chatservice.ChatService", "CreateChatRoom", data);
    return promise.then(data => CreateChatRoomResponse.decode(new Reader(data)));
  }

  GetChatRoomList(request: GetChatRoomListRequest): Promise<GetChatRoomListResponse> {
    const data = GetChatRoomListRequest.encode(request).finish();
    const promise = this.rpc.request("chatservice.ChatService", "GetChatRoomList", data);
    return promise.then(data => GetChatRoomListResponse.decode(new Reader(data)));
  }

  GetChatRoomUpdates(request: GetChatRoomUpdatesRequest): Promise<GetChatRoomUpdatesResponse> {
    const data = GetChatRoomUpdatesRequest.encode(request).finish();
    const promise = this.rpc.request("chatservice.ChatService", "GetChatRoomUpdates", data);
    return promise.then(data => GetChatRoomUpdatesResponse.decode(new Reader(data)));
  }

  GetChatMessages(request: GetChatMessagesRequest): Promise<GetChatMessagesResponse> {
    const data = GetChatMessagesRequest.encode(request).finish();
    const promise = this.rpc.request("chatservice.ChatService", "GetChatMessages", data);
    return promise.then(data => GetChatMessagesResponse.decode(new Reader(data)));
  }

  PostMessage(request: PostMessageRequest): Promise<PostMessageResponse> {
    const data = PostMessageRequest.encode(request).finish();
    const promise = this.rpc.request("chatservice.ChatService", "PostMessage", data);
    return promise.then(data => PostMessageResponse.decode(new Reader(data)));
  }

  DeleteMessage(request: DeleteMessageRequest): Promise<DeleteMessageResponse> {
    const data = DeleteMessageRequest.encode(request).finish();
    const promise = this.rpc.request("chatservice.ChatService", "DeleteMessage", data);
    return promise.then(data => DeleteMessageResponse.decode(new Reader(data)));
  }

  PostFile(request: PostFileRequest): Promise<PostFileResponse> {
    const data = PostFileRequest.encode(request).finish();
    const promise = this.rpc.request("chatservice.ChatService", "PostFile", data);
    return promise.then(data => PostFileResponse.decode(new Reader(data)));
  }

  FlushUnreadCount(request: FlushUnreadCountRequest): Promise<FlushUnreadCountResponse> {
    const data = FlushUnreadCountRequest.encode(request).finish();
    const promise = this.rpc.request("chatservice.ChatService", "FlushUnreadCount", data);
    return promise.then(data => FlushUnreadCountResponse.decode(new Reader(data)));
  }

}

interface Rpc {

  request(service: string, method: string, data: Uint8Array): Promise<Uint8Array>;

}

function longToNumber(long: Long) {
  if (long.gt(Number.MAX_SAFE_INTEGER)) {
    throw new global.Error("Value is larger than Number.MAX_SAFE_INTEGER");
  }
  return long.toNumber();
}

export const CreateChatRoomRequest = {
  encode(message: CreateChatRoomRequest, writer: Writer = Writer.create()): Writer {
    writer.uint32(10).string(message.accountId);
    return writer;
  },
  decode(reader: Reader, length?: number): CreateChatRoomRequest {
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = Object.create(baseCreateChatRoomRequest) as CreateChatRoomRequest;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.accountId = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },
  fromJSON(object: any): CreateChatRoomRequest {
    const message = Object.create(baseCreateChatRoomRequest) as CreateChatRoomRequest;
    if (object.accountId) {
      message.accountId = String(object.accountId);
    }
    return message;
  },
  fromPartial(object: DeepPartial<CreateChatRoomRequest>): CreateChatRoomRequest {
    const message = Object.create(baseCreateChatRoomRequest) as CreateChatRoomRequest;
    if (object.accountId) {
      message.accountId = object.accountId;
    }
    return message;
  },
  toJSON(message: CreateChatRoomRequest): unknown {
    const obj: any = {};
    obj.accountId = message.accountId || "";
    return obj;
  },
};

export const CreateChatRoomResponse = {
  encode(message: CreateChatRoomResponse, writer: Writer = Writer.create()): Writer {
    writer.uint32(10).string(message.chatRoomId);
    return writer;
  },
  decode(reader: Reader, length?: number): CreateChatRoomResponse {
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = Object.create(baseCreateChatRoomResponse) as CreateChatRoomResponse;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.chatRoomId = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },
  fromJSON(object: any): CreateChatRoomResponse {
    const message = Object.create(baseCreateChatRoomResponse) as CreateChatRoomResponse;
    if (object.chatRoomId) {
      message.chatRoomId = String(object.chatRoomId);
    }
    return message;
  },
  fromPartial(object: DeepPartial<CreateChatRoomResponse>): CreateChatRoomResponse {
    const message = Object.create(baseCreateChatRoomResponse) as CreateChatRoomResponse;
    if (object.chatRoomId) {
      message.chatRoomId = object.chatRoomId;
    }
    return message;
  },
  toJSON(message: CreateChatRoomResponse): unknown {
    const obj: any = {};
    obj.chatRoomId = message.chatRoomId || "";
    return obj;
  },
};

export const GetChatRoomListRequest = {
  encode(message: GetChatRoomListRequest, writer: Writer = Writer.create()): Writer {
    writer.uint32(8).int64(message.limit);
    writer.uint32(18).string(message.chatRoomIdBefore);
    writer.uint32(24).int64(message.updatedAtBefore);
    return writer;
  },
  decode(reader: Reader, length?: number): GetChatRoomListRequest {
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = Object.create(baseGetChatRoomListRequest) as GetChatRoomListRequest;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.limit = longToNumber(reader.int64() as Long);
          break;
        case 2:
          message.chatRoomIdBefore = reader.string();
          break;
        case 3:
          message.updatedAtBefore = longToNumber(reader.int64() as Long);
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },
  fromJSON(object: any): GetChatRoomListRequest {
    const message = Object.create(baseGetChatRoomListRequest) as GetChatRoomListRequest;
    if (object.limit) {
      message.limit = Number(object.limit);
    }
    if (object.chatRoomIdBefore) {
      message.chatRoomIdBefore = String(object.chatRoomIdBefore);
    }
    if (object.updatedAtBefore) {
      message.updatedAtBefore = Number(object.updatedAtBefore);
    }
    return message;
  },
  fromPartial(object: DeepPartial<GetChatRoomListRequest>): GetChatRoomListRequest {
    const message = Object.create(baseGetChatRoomListRequest) as GetChatRoomListRequest;
    if (object.limit) {
      message.limit = object.limit;
    }
    if (object.chatRoomIdBefore) {
      message.chatRoomIdBefore = object.chatRoomIdBefore;
    }
    if (object.updatedAtBefore) {
      message.updatedAtBefore = object.updatedAtBefore;
    }
    return message;
  },
  toJSON(message: GetChatRoomListRequest): unknown {
    const obj: any = {};
    obj.limit = message.limit || 0;
    obj.chatRoomIdBefore = message.chatRoomIdBefore || "";
    obj.updatedAtBefore = message.updatedAtBefore || 0;
    return obj;
  },
};

export const GetChatRoomListResponse = {
  encode(message: GetChatRoomListResponse, writer: Writer = Writer.create()): Writer {
    for (const v of message.chatRooms) {
      ChatRoom.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },
  decode(reader: Reader, length?: number): GetChatRoomListResponse {
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = Object.create(baseGetChatRoomListResponse) as GetChatRoomListResponse;
    message.chatRooms = [];
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.chatRooms.push(ChatRoom.decode(reader, reader.uint32()));
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },
  fromJSON(object: any): GetChatRoomListResponse {
    const message = Object.create(baseGetChatRoomListResponse) as GetChatRoomListResponse;
    message.chatRooms = [];
    if (object.chatRooms) {
      for (const e of object.chatRooms) {
        message.chatRooms.push(ChatRoom.fromJSON(e));
      }
    }
    return message;
  },
  fromPartial(object: DeepPartial<GetChatRoomListResponse>): GetChatRoomListResponse {
    const message = Object.create(baseGetChatRoomListResponse) as GetChatRoomListResponse;
    message.chatRooms = [];
    if (object.chatRooms) {
      for (const e of object.chatRooms) {
        message.chatRooms.push(ChatRoom.fromPartial(e));
      }
    }
    return message;
  },
  toJSON(message: GetChatRoomListResponse): unknown {
    const obj: any = {};
    if (message.chatRooms) {
      obj.chatRooms = message.chatRooms.map(e => e ? ChatRoom.toJSON(e) : undefined);
    } else {
      obj.chatRooms = [];
    }
    return obj;
  },
};

export const GetChatRoomUpdatesRequest = {
  encode(message: GetChatRoomUpdatesRequest, writer: Writer = Writer.create()): Writer {
    writer.uint32(8).int64(message.limit);
    writer.uint32(18).string(message.chatRoomIdAfter);
    writer.uint32(24).int64(message.updatedAtAfter);
    return writer;
  },
  decode(reader: Reader, length?: number): GetChatRoomUpdatesRequest {
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = Object.create(baseGetChatRoomUpdatesRequest) as GetChatRoomUpdatesRequest;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.limit = longToNumber(reader.int64() as Long);
          break;
        case 2:
          message.chatRoomIdAfter = reader.string();
          break;
        case 3:
          message.updatedAtAfter = longToNumber(reader.int64() as Long);
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },
  fromJSON(object: any): GetChatRoomUpdatesRequest {
    const message = Object.create(baseGetChatRoomUpdatesRequest) as GetChatRoomUpdatesRequest;
    if (object.limit) {
      message.limit = Number(object.limit);
    }
    if (object.chatRoomIdAfter) {
      message.chatRoomIdAfter = String(object.chatRoomIdAfter);
    }
    if (object.updatedAtAfter) {
      message.updatedAtAfter = Number(object.updatedAtAfter);
    }
    return message;
  },
  fromPartial(object: DeepPartial<GetChatRoomUpdatesRequest>): GetChatRoomUpdatesRequest {
    const message = Object.create(baseGetChatRoomUpdatesRequest) as GetChatRoomUpdatesRequest;
    if (object.limit) {
      message.limit = object.limit;
    }
    if (object.chatRoomIdAfter) {
      message.chatRoomIdAfter = object.chatRoomIdAfter;
    }
    if (object.updatedAtAfter) {
      message.updatedAtAfter = object.updatedAtAfter;
    }
    return message;
  },
  toJSON(message: GetChatRoomUpdatesRequest): unknown {
    const obj: any = {};
    obj.limit = message.limit || 0;
    obj.chatRoomIdAfter = message.chatRoomIdAfter || "";
    obj.updatedAtAfter = message.updatedAtAfter || 0;
    return obj;
  },
};

export const GetChatRoomUpdatesResponse = {
  encode(message: GetChatRoomUpdatesResponse, writer: Writer = Writer.create()): Writer {
    for (const v of message.chatRooms) {
      ChatRoom.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },
  decode(reader: Reader, length?: number): GetChatRoomUpdatesResponse {
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = Object.create(baseGetChatRoomUpdatesResponse) as GetChatRoomUpdatesResponse;
    message.chatRooms = [];
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.chatRooms.push(ChatRoom.decode(reader, reader.uint32()));
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },
  fromJSON(object: any): GetChatRoomUpdatesResponse {
    const message = Object.create(baseGetChatRoomUpdatesResponse) as GetChatRoomUpdatesResponse;
    message.chatRooms = [];
    if (object.chatRooms) {
      for (const e of object.chatRooms) {
        message.chatRooms.push(ChatRoom.fromJSON(e));
      }
    }
    return message;
  },
  fromPartial(object: DeepPartial<GetChatRoomUpdatesResponse>): GetChatRoomUpdatesResponse {
    const message = Object.create(baseGetChatRoomUpdatesResponse) as GetChatRoomUpdatesResponse;
    message.chatRooms = [];
    if (object.chatRooms) {
      for (const e of object.chatRooms) {
        message.chatRooms.push(ChatRoom.fromPartial(e));
      }
    }
    return message;
  },
  toJSON(message: GetChatRoomUpdatesResponse): unknown {
    const obj: any = {};
    if (message.chatRooms) {
      obj.chatRooms = message.chatRooms.map(e => e ? ChatRoom.toJSON(e) : undefined);
    } else {
      obj.chatRooms = [];
    }
    return obj;
  },
};

export const GetChatMessagesRequest = {
  encode(message: GetChatMessagesRequest, writer: Writer = Writer.create()): Writer {
    writer.uint32(10).string(message.chatRoomId);
    writer.uint32(16).int64(message.limit);
    writer.uint32(24).int64(message.messageIdBefore);
    writer.uint32(32).int64(message.messageIdAfter);
    return writer;
  },
  decode(reader: Reader, length?: number): GetChatMessagesRequest {
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = Object.create(baseGetChatMessagesRequest) as GetChatMessagesRequest;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.chatRoomId = reader.string();
          break;
        case 2:
          message.limit = longToNumber(reader.int64() as Long);
          break;
        case 3:
          message.messageIdBefore = longToNumber(reader.int64() as Long);
          break;
        case 4:
          message.messageIdAfter = longToNumber(reader.int64() as Long);
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },
  fromJSON(object: any): GetChatMessagesRequest {
    const message = Object.create(baseGetChatMessagesRequest) as GetChatMessagesRequest;
    if (object.chatRoomId) {
      message.chatRoomId = String(object.chatRoomId);
    }
    if (object.limit) {
      message.limit = Number(object.limit);
    }
    if (object.messageIdBefore) {
      message.messageIdBefore = Number(object.messageIdBefore);
    }
    if (object.messageIdAfter) {
      message.messageIdAfter = Number(object.messageIdAfter);
    }
    return message;
  },
  fromPartial(object: DeepPartial<GetChatMessagesRequest>): GetChatMessagesRequest {
    const message = Object.create(baseGetChatMessagesRequest) as GetChatMessagesRequest;
    if (object.chatRoomId) {
      message.chatRoomId = object.chatRoomId;
    }
    if (object.limit) {
      message.limit = object.limit;
    }
    if (object.messageIdBefore) {
      message.messageIdBefore = object.messageIdBefore;
    }
    if (object.messageIdAfter) {
      message.messageIdAfter = object.messageIdAfter;
    }
    return message;
  },
  toJSON(message: GetChatMessagesRequest): unknown {
    const obj: any = {};
    obj.chatRoomId = message.chatRoomId || "";
    obj.limit = message.limit || 0;
    obj.messageIdBefore = message.messageIdBefore || 0;
    obj.messageIdAfter = message.messageIdAfter || 0;
    return obj;
  },
};

export const GetChatMessagesResponse = {
  encode(message: GetChatMessagesResponse, writer: Writer = Writer.create()): Writer {
    for (const v of message.messages) {
      ChatMessage.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    writer.uint32(18).string(message.prevQuery);
    writer.uint32(26).string(message.nextQuery);
    return writer;
  },
  decode(reader: Reader, length?: number): GetChatMessagesResponse {
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = Object.create(baseGetChatMessagesResponse) as GetChatMessagesResponse;
    message.messages = [];
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.messages.push(ChatMessage.decode(reader, reader.uint32()));
          break;
        case 2:
          message.prevQuery = reader.string();
          break;
        case 3:
          message.nextQuery = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },
  fromJSON(object: any): GetChatMessagesResponse {
    const message = Object.create(baseGetChatMessagesResponse) as GetChatMessagesResponse;
    message.messages = [];
    if (object.messages) {
      for (const e of object.messages) {
        message.messages.push(ChatMessage.fromJSON(e));
      }
    }
    if (object.prevQuery) {
      message.prevQuery = String(object.prevQuery);
    }
    if (object.nextQuery) {
      message.nextQuery = String(object.nextQuery);
    }
    return message;
  },
  fromPartial(object: DeepPartial<GetChatMessagesResponse>): GetChatMessagesResponse {
    const message = Object.create(baseGetChatMessagesResponse) as GetChatMessagesResponse;
    message.messages = [];
    if (object.messages) {
      for (const e of object.messages) {
        message.messages.push(ChatMessage.fromPartial(e));
      }
    }
    if (object.prevQuery) {
      message.prevQuery = object.prevQuery;
    }
    if (object.nextQuery) {
      message.nextQuery = object.nextQuery;
    }
    return message;
  },
  toJSON(message: GetChatMessagesResponse): unknown {
    const obj: any = {};
    if (message.messages) {
      obj.messages = message.messages.map(e => e ? ChatMessage.toJSON(e) : undefined);
    } else {
      obj.messages = [];
    }
    obj.prevQuery = message.prevQuery || "";
    obj.nextQuery = message.nextQuery || "";
    return obj;
  },
};

export const PostMessageRequest = {
  encode(message: PostMessageRequest, writer: Writer = Writer.create()): Writer {
    writer.uint32(10).string(message.chatRoomId);
    writer.uint32(18).string(message.message);
    return writer;
  },
  decode(reader: Reader, length?: number): PostMessageRequest {
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = Object.create(basePostMessageRequest) as PostMessageRequest;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.chatRoomId = reader.string();
          break;
        case 2:
          message.message = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },
  fromJSON(object: any): PostMessageRequest {
    const message = Object.create(basePostMessageRequest) as PostMessageRequest;
    if (object.chatRoomId) {
      message.chatRoomId = String(object.chatRoomId);
    }
    if (object.message) {
      message.message = String(object.message);
    }
    return message;
  },
  fromPartial(object: DeepPartial<PostMessageRequest>): PostMessageRequest {
    const message = Object.create(basePostMessageRequest) as PostMessageRequest;
    if (object.chatRoomId) {
      message.chatRoomId = object.chatRoomId;
    }
    if (object.message) {
      message.message = object.message;
    }
    return message;
  },
  toJSON(message: PostMessageRequest): unknown {
    const obj: any = {};
    obj.chatRoomId = message.chatRoomId || "";
    obj.message = message.message || "";
    return obj;
  },
};

export const PostMessageResponse = {
  encode(message: PostMessageResponse, writer: Writer = Writer.create()): Writer {
    return writer;
  },
  decode(reader: Reader, length?: number): PostMessageResponse {
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = Object.create(basePostMessageResponse) as PostMessageResponse;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },
  fromJSON(object: any): PostMessageResponse {
    const message = Object.create(basePostMessageResponse) as PostMessageResponse;
    return message;
  },
  fromPartial(object: DeepPartial<PostMessageResponse>): PostMessageResponse {
    const message = Object.create(basePostMessageResponse) as PostMessageResponse;
    return message;
  },
  toJSON(message: PostMessageResponse): unknown {
    const obj: any = {};
    return obj;
  },
};

export const PostFileRequest = {
  encode(message: PostFileRequest, writer: Writer = Writer.create()): Writer {
    writer.uint32(10).string(message.chatRoomId);
    writer.uint32(18).string(message.data);
    writer.uint32(26).string(message.fileName);
    return writer;
  },
  decode(reader: Reader, length?: number): PostFileRequest {
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = Object.create(basePostFileRequest) as PostFileRequest;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.chatRoomId = reader.string();
          break;
        case 2:
          message.data = reader.string();
          break;
        case 3:
          message.fileName = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },
  fromJSON(object: any): PostFileRequest {
    const message = Object.create(basePostFileRequest) as PostFileRequest;
    if (object.chatRoomId) {
      message.chatRoomId = String(object.chatRoomId);
    }
    if (object.data) {
      message.data = String(object.data);
    }
    if (object.fileName) {
      message.fileName = String(object.fileName);
    }
    return message;
  },
  fromPartial(object: DeepPartial<PostFileRequest>): PostFileRequest {
    const message = Object.create(basePostFileRequest) as PostFileRequest;
    if (object.chatRoomId) {
      message.chatRoomId = object.chatRoomId;
    }
    if (object.data) {
      message.data = object.data;
    }
    if (object.fileName) {
      message.fileName = object.fileName;
    }
    return message;
  },
  toJSON(message: PostFileRequest): unknown {
    const obj: any = {};
    obj.chatRoomId = message.chatRoomId || "";
    obj.data = message.data || "";
    obj.fileName = message.fileName || "";
    return obj;
  },
};

export const PostFileResponse = {
  encode(message: PostFileResponse, writer: Writer = Writer.create()): Writer {
    return writer;
  },
  decode(reader: Reader, length?: number): PostFileResponse {
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = Object.create(basePostFileResponse) as PostFileResponse;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },
  fromJSON(object: any): PostFileResponse {
    const message = Object.create(basePostFileResponse) as PostFileResponse;
    return message;
  },
  fromPartial(object: DeepPartial<PostFileResponse>): PostFileResponse {
    const message = Object.create(basePostFileResponse) as PostFileResponse;
    return message;
  },
  toJSON(message: PostFileResponse): unknown {
    const obj: any = {};
    return obj;
  },
};

export const DeleteMessageRequest = {
  encode(message: DeleteMessageRequest, writer: Writer = Writer.create()): Writer {
    writer.uint32(10).string(message.chatRoomId);
    writer.uint32(16).int64(message.messageId);
    return writer;
  },
  decode(reader: Reader, length?: number): DeleteMessageRequest {
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = Object.create(baseDeleteMessageRequest) as DeleteMessageRequest;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.chatRoomId = reader.string();
          break;
        case 2:
          message.messageId = longToNumber(reader.int64() as Long);
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },
  fromJSON(object: any): DeleteMessageRequest {
    const message = Object.create(baseDeleteMessageRequest) as DeleteMessageRequest;
    if (object.chatRoomId) {
      message.chatRoomId = String(object.chatRoomId);
    }
    if (object.messageId) {
      message.messageId = Number(object.messageId);
    }
    return message;
  },
  fromPartial(object: DeepPartial<DeleteMessageRequest>): DeleteMessageRequest {
    const message = Object.create(baseDeleteMessageRequest) as DeleteMessageRequest;
    if (object.chatRoomId) {
      message.chatRoomId = object.chatRoomId;
    }
    if (object.messageId) {
      message.messageId = object.messageId;
    }
    return message;
  },
  toJSON(message: DeleteMessageRequest): unknown {
    const obj: any = {};
    obj.chatRoomId = message.chatRoomId || "";
    obj.messageId = message.messageId || 0;
    return obj;
  },
};

export const DeleteMessageResponse = {
  encode(message: DeleteMessageResponse, writer: Writer = Writer.create()): Writer {
    return writer;
  },
  decode(reader: Reader, length?: number): DeleteMessageResponse {
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = Object.create(baseDeleteMessageResponse) as DeleteMessageResponse;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },
  fromJSON(object: any): DeleteMessageResponse {
    const message = Object.create(baseDeleteMessageResponse) as DeleteMessageResponse;
    return message;
  },
  fromPartial(object: DeepPartial<DeleteMessageResponse>): DeleteMessageResponse {
    const message = Object.create(baseDeleteMessageResponse) as DeleteMessageResponse;
    return message;
  },
  toJSON(message: DeleteMessageResponse): unknown {
    const obj: any = {};
    return obj;
  },
};

export const FlushUnreadCountRequest = {
  encode(message: FlushUnreadCountRequest, writer: Writer = Writer.create()): Writer {
    writer.uint32(10).string(message.chatRoomId);
    return writer;
  },
  decode(reader: Reader, length?: number): FlushUnreadCountRequest {
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = Object.create(baseFlushUnreadCountRequest) as FlushUnreadCountRequest;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.chatRoomId = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },
  fromJSON(object: any): FlushUnreadCountRequest {
    const message = Object.create(baseFlushUnreadCountRequest) as FlushUnreadCountRequest;
    if (object.chatRoomId) {
      message.chatRoomId = String(object.chatRoomId);
    }
    return message;
  },
  fromPartial(object: DeepPartial<FlushUnreadCountRequest>): FlushUnreadCountRequest {
    const message = Object.create(baseFlushUnreadCountRequest) as FlushUnreadCountRequest;
    if (object.chatRoomId) {
      message.chatRoomId = object.chatRoomId;
    }
    return message;
  },
  toJSON(message: FlushUnreadCountRequest): unknown {
    const obj: any = {};
    obj.chatRoomId = message.chatRoomId || "";
    return obj;
  },
};

export const FlushUnreadCountResponse = {
  encode(message: FlushUnreadCountResponse, writer: Writer = Writer.create()): Writer {
    return writer;
  },
  decode(reader: Reader, length?: number): FlushUnreadCountResponse {
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = Object.create(baseFlushUnreadCountResponse) as FlushUnreadCountResponse;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },
  fromJSON(object: any): FlushUnreadCountResponse {
    const message = Object.create(baseFlushUnreadCountResponse) as FlushUnreadCountResponse;
    return message;
  },
  fromPartial(object: DeepPartial<FlushUnreadCountResponse>): FlushUnreadCountResponse {
    const message = Object.create(baseFlushUnreadCountResponse) as FlushUnreadCountResponse;
    return message;
  },
  toJSON(message: FlushUnreadCountResponse): unknown {
    const obj: any = {};
    return obj;
  },
};

type DeepPartial<T> = {
  [P in keyof T]?: T[P] extends Array<infer U>
  ? Array<DeepPartial<U>>
  : T[P] extends ReadonlyArray<infer U>
  ? ReadonlyArray<DeepPartial<U>>
  : T[P] extends Date | Function | Uint8Array | undefined
  ? T[P]
  : T[P] extends infer U | undefined
  ? DeepPartial<U>
  : T[P] extends object
  ? DeepPartial<T[P]>
  : T[P]
};