import React, { FC, ComponentProps } from 'react';
import styled, { css } from 'styled-components/macro';
import theme from 'styles/theme';
import typography from 'styles/typography';
import { Link } from 'react-router-dom';
import { Card, ImageCenter, EllipsisBox } from 'components/atoms';
import { CategoryTag, AttentionDegree } from 'components/molecules';
import * as PROTECTED_ROUTES from 'routes/constants/protected';
import { imageSelector } from 'utils/imageSelector';
import { Mission } from 'proto/v1/apimodel/apimodel';
import {
  Collaboration,
  Financing,
  Information,
  SearchBusiness,
  Secondment,
  Office,
} from 'assets/svg';

const StyledCard = styled(Card)`
  width: 271px;
  height: 378px;
  border: none;
  border-radius: 8px;
  box-shadow: 0 0 16px rgba(0, 0, 0, 0.14);
  color: ${theme.textPrimary};

  > div {
    padding: 0;
  }
`;

const StyledCategoryTag = styled(props => <CategoryTag {...props} />)`
  && {
    ${typography.labelCategory}
    min-width: 100px;
    margin: 0 2px 4px 0;
    padding: 5px 8px 7px 8px;
    cursor: pointer;
  }
`;
const CardMainImageWrapper = styled.div`
  width: 100%;
  height: 152px;
`;
const CardMainImage = styled.img`
  border-radius: 8px 8px 0 0;
  object-fit: cover;
`;
const CardBody = styled.div`
  height: 227px;
  padding: 16px;
  overflow: hidden;
`;
const FirstRow = styled.div`
  display: flex;
  align-content: stretch;
  align-items: center;
  height: 16px;
  margin-bottom: 12px;

  > :not(:first-child) {
    margin-left: 12px;
  }
`;

const CategoriesContainer = styled.div`
  height: 58px;
`;
const CardTitleWrapper = styled.div`
  height: 48px;
  margin-bottom: 8px;
`;

const CardTitle = styled(EllipsisBox)`
  ${typography.textCardTitle}
  margin-right: 8px;
  color: ${theme.textPrimary};
  vertical-align: middle;
`;

const AttentionWrapper = styled.div`
  display: inline-block;
  vertical-align: middle;
`;

const Demands = styled.div`
  display: flex;
`;

const demandIconStyle = css`
  width: 18px;
  height: 18px;

  &:not(:first-child) {
    margin-left: 10px;
  }
`;

const CollaborationIcon = styled(Collaboration)`
  ${demandIconStyle}
`;

const FinancingIcon = styled(Financing)`
  ${demandIconStyle}
`;

const ServiceInformationIcon = styled(Information)`
  ${demandIconStyle}
`;

const SearchBusinessIcon = styled(SearchBusiness)`
  ${demandIconStyle}
`;

const SecondmentIcon = styled(Secondment)`
  ${demandIconStyle}
`;

const FurnishedOfficeRentalIcon = styled(Office)`
  ${demandIconStyle}
`;

const CardFooter = styled.div`
  display: flex;
  align-items: center;
  height: 48px;
`;
const CorporateName = styled.div`
  ${typography.textSub}
  width: 187px;
  padding-left: 4px;
  color: ${theme.textSecondary};
`;

export type MissionCardProps = ComponentProps<typeof Card> & {
  mission: Mission;
};

const MissionCard: FC<MissionCardProps> = props => {
  const {
    missionId,
    coverImage,
    iconImage,
    name,
    companyName,
    missionCategories,
    degreeOfAttention,
    missionDemand,
  } = props.mission;

  return (
    <StyledCard {...props} data-testid="mission-card">
      <Link
        style={{ textDecoration: 'none' }}
        to={{
          pathname: `${PROTECTED_ROUTES.PRIVATE_MISSIONS}/${missionId}`,
          state: { from: PROTECTED_ROUTES.PRIVATE_MISSIONS },
        }}
      >
        <CardMainImageWrapper>
          <CardMainImage src={imageSelector(coverImage, 'small')} alt={name} />
        </CardMainImageWrapper>
        <CardBody>
          <CardTitleWrapper>
            <CardTitle
              line={2}
              width={'240px'}
              maxHeight={'45px'}
              backgroundColor={theme.baseWhite}
            >
              {name}
            </CardTitle>
          </CardTitleWrapper>
          <FirstRow>
            <Demands>
              {missionDemand?.collaboration && <CollaborationIcon />}
              {(missionDemand?.fundraising || missionDemand?.invest) && (
                <FinancingIcon />
              )}
              {(missionDemand?.serviceInformation ||
                missionDemand?.serviceSuggestion) && <ServiceInformationIcon />}
              {(missionDemand?.transferOfBusiness ||
                missionDemand?.transferredOfBusiness) && <SearchBusinessIcon />}
              {(missionDemand?.sendEmployee ||
                missionDemand?.acceptSecondment) && <SecondmentIcon />}
              {(missionDemand?.furnishedOfficeRentalAvailability ||
                missionDemand?.furnishedOfficeRentalRequest) && (
                <FurnishedOfficeRentalIcon />
              )}
            </Demands>
            <AttentionWrapper>
              <AttentionDegree degreeOfAttention={degreeOfAttention} />
            </AttentionWrapper>
          </FirstRow>
          <CategoriesContainer>
            {missionCategories &&
              missionCategories.length > 0 &&
              missionCategories.map(category => (
                <StyledCategoryTag
                  backgroundColor={category.backgroundColor}
                  key={category.categoryId}
                  round
                  size="small"
                >
                  {category.name}
                </StyledCategoryTag>
              ))}
          </CategoriesContainer>
          <CardFooter>
            <ImageCenter
              imageSrc={imageSelector(iconImage, 'small')}
              width={40}
              height={40}
              data-testid="mission-card-icon-image"
            />
            <CorporateName>{companyName}</CorporateName>
          </CardFooter>
        </CardBody>
      </Link>
    </StyledCard>
  );
};

export default React.memo(
  MissionCard,
  (prevProps, nextProps) => prevProps.mission === nextProps.mission,
);
