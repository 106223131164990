import React, { FC } from 'react';
import styled from 'styled-components/macro';
import { useTranslation } from 'react-i18next';
import { Mission } from 'proto/v1/apimodel/apimodel';
import { WrapText } from 'components/atoms';
import { DetailSubTitle, DetailCompanyField } from 'components/molecules';

const Address = styled(WrapText)`
  line-height: 1.5;
`;

const formatMissionAddress = (address: AddressFieldProps) => {
  if (!address || !address.country) return '';

  if (address.country.countryId === 'JPN') {
    const japanPrefecture = address.japanPrefecture
      ? address.japanPrefecture.name
      : '';
    return `${japanPrefecture}${address.otherAddress}`;
  }
  return `${address.country.name}${address.otherAddress}`;
};

type AddressFieldProps = {
  country: Mission['country'];
  japanPrefecture: Mission['japanPrefecture'];
  otherAddress: Mission['otherAddress'];
};

const AddressField: FC<AddressFieldProps> = ({
  country,
  japanPrefecture,
  otherAddress,
}) => {
  const { t } = useTranslation('missionDetail');

  return (
    <DetailCompanyField>
      <DetailSubTitle>{t('field.address.label')}</DetailSubTitle>
      <Address>
        {formatMissionAddress({ country, japanPrefecture, otherAddress })}
      </Address>
    </DetailCompanyField>
  );
};

export default AddressField;
