import { UpdateSupporterRequest } from 'proto/v1/supporterservice/supporterservice';
import { SupporterFormValues } from 'types/supporter';
import {
  getIconImageToUpdate,
  getCoverImageToUpdate,
  getJapanPrefectureToUpdate,
  getSupporterMembersToUpdate,
  getGalleryImagesToUpdate,
} from '../update';

const getSupporterToUpdate = (values: SupporterFormValues) => {
  const requestParams: UpdateSupporterRequest = {
    supporterId: values.supporterId,
    name: values.name,
    iconImage: getIconImageToUpdate(values.iconImage),
    coverImage: getCoverImageToUpdate(values.coverImage),
    supportDescription: values.supportDescription,
    strengthPoint: values.strengthPoint,
    companyName: values.companyName,
    countryId: values.countryId,
    japanPrefectureId: getJapanPrefectureToUpdate(
      values.countryId,
      values.japanPrefectureId,
    ),
    otherAddress: values.otherAddress,
    companyUrl: values.companyUrl,
    companyEstablishedDate: values.companyEstablishedDate,
    memberRangeId: values.memberRangeId,
    supporterMembers: getSupporterMembersToUpdate(values.supporterMembers),
    supportAreaIds: values.supportAreaIds,
    galleryImages: getGalleryImagesToUpdate(values.galleryImages),
  };

  return requestParams;
};

export default getSupporterToUpdate;
