import React, { FC } from 'react';
import {
  Modal,
  ModalContent,
  ModalFooter,
  FormItemLabel,
} from 'components/molecules';
import { ButtonM, FlexContainer } from 'components/atoms';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

const StyledFlexContainer = styled(FlexContainer)`
  height: 100%;
`;

const StyledModalContent = styled(ModalContent)`
  flex: 1;
  align-content: flex-start;
  margin-bottom: 0;
  padding: 0;
`;

const AboutSupporter = styled.p`
  width: 100%;
  text-align: left;
`;

type AboutSupporterModalProps = {
  show: boolean;
  closeModal: () => void;
};

const AboutSupporterModal: FC<AboutSupporterModalProps> = ({
  show,
  closeModal,
}) => {
  const { t } = useTranslation(['supporterList', 'common']);
  return (
    <Modal show={show} closeModal={closeModal}>
      <StyledFlexContainer flexDirection="column">
        <StyledModalContent>
          <FormItemLabel
            label={t('aboutSupporter.label')}
            style={{ width: '100%', textAlign: 'left', marginBottom: 24 }}
          />
          <AboutSupporter>{t('aboutSupporter.description')}</AboutSupporter>
        </StyledModalContent>
        <ModalFooter>
          <ButtonM buttonTheme={'default'} onClick={closeModal}>
            {t('common:button.close')}
          </ButtonM>
        </ModalFooter>
      </StyledFlexContainer>
    </Modal>
  );
};

export default AboutSupporterModal;
