import styled, { css } from 'styled-components/macro';
import { Tag } from 'components/atoms';
import theme from 'styles/theme';
import typography from 'styles/typography';
import { ComponentProps } from 'react';

const borderBold = css`
  padding: 8px 17px 7px 16px; /* To fix width and height of PickerTag without using border-box  */
  border: 2px solid ${theme.basePrimary};
`;

type FilterTagProps = ComponentProps<typeof Tag> & {
  isActive?: boolean;
  isSelected?: boolean;
};

const FilterTag = styled(Tag)<FilterTagProps>`
  &&& {
    ${typography.textDefault}
    z-index: 2;
    min-width: 75px;
    margin-right: 0;
    margin-bottom: 0;
    padding: 9px 18px 8px 17px;
    border: 1px solid ${theme.basePrimary};
    border-radius: 20px;
    background-color: ${theme.baseWhite};
    color: ${theme.basePrimary};
    text-align: center;
    user-select: none;
    ${({ isActive }) =>
      isActive &&
      css`
        background-color: ${theme.basePrimary};
        color: ${theme.white};
        ${typography.textBold}
      `}
    ${({ isSelected, isActive }) =>
      isSelected &&
      !isActive &&
      css`
        ${borderBold}
        font-weight: normal;
      `}

    &:hover {
      border: 2px solid ${theme.basePrimary};
      ${borderBold}
      cursor: pointer;
    }
  }
`;

export default FilterTag;
