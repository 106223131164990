import styled from 'styled-components/macro';
import { Tooltip } from 'visits-style';
import theme from 'styles/theme';
import typography from 'styles/typography';

const StyledTooltip = styled(Tooltip)`
  ${typography.textNote}
  width: 200px;
  border-radius: 8px;
  background-color: ${theme.baseWhite};
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.32);
  color: ${theme.textPrimary};
  text-align: left;
  white-space: pre-wrap;
`;

export default StyledTooltip;
