import React, { useCallback, useState } from 'react';
import { MdKeyboardArrowDown } from 'react-icons/md';
import { Overlay } from 'components/molecules';
import { CirclePlus, CircleChecked, CircleMinus } from 'assets/svg';
import styled, { css } from 'styled-components/macro';
import theme from 'styles/theme';
import scrollbar from 'styles/scrollbar';

type OptionsPosition = 'top' | 'bottom';

const MultiSelectboxRoot = styled.div<{ width?: number }>`
  position: relative;
  ${({ width }) =>
    width &&
    css`
      width: ${width}px;
    `}
`;

const InputBase = styled.div`
  display: flex;
  padding: 5px 8px;
  padding-right: 4px;
  border: 1px solid ${theme.borderDefault};
  border-radius: 3px;
  background: ${theme.baseWhite};
  line-height: 1;
  cursor: pointer;
`;

const SelectedValue = styled.div`
  width: calc(100% - 20px);
  margin: auto;
  overflow: hidden;
  text-align: left;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const maxHeight = 36 * 7 + 12; // optionHeight * row + padding;

const OptionsPanel = styled.div<{ position: OptionsPosition }>`
  position: absolute;
  z-index: 2;
  left: 0;
  width: 100%;
  max-height: ${maxHeight}px;
  padding: 6px 0;
  overflow-x: hidden;
  overflow-y: scroll;
  border: 1px solid ${theme.borderDefault};
  border-radius: 3px;
  background: ${theme.baseWhite};
  ${scrollbar};
  /* stylelint-disable property-no-vendor-prefix */
  ::-webkit-scrollbar {
    background-color: transparent;
  }

  ${({ position }) =>
    position === 'bottom' &&
    css`
      top: 36px;
    `}
  ${({ position }) =>
    position === 'top' &&
    css`
      bottom: 36px;
    `}
`;

const iconStyle = css`
  position: absolute;
  right: 16px;
`;

const AddIcon = styled(CirclePlus)`
  ${iconStyle};
  visibility: collapse;
`;

const SelectedIcon = styled(CircleChecked)`
  ${iconStyle};
`;
const RemoveIcon = styled(CircleMinus)`
  ${iconStyle};
  visibility: collapse;
`;

const SelectboxOption = styled.li`
  display: inline-flex;
  width: 100%;
  padding: 10px 16px;
  text-align: left;
  cursor: pointer;

  :hover ${AddIcon} {
    visibility: visible;
  }

  :hover ${RemoveIcon} {
    visibility: visible;
  }

  :hover ${SelectedIcon} {
    visibility: collapse;
  }

  & > *:first-child {
    width: calc(100% - 18px);
    overflow: hidden;
    font-weight: 500;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
`;

const NoSelectboxOption = styled(SelectboxOption)<{ isSelected: boolean }>`
  ${({ isSelected }) =>
    isSelected &&
    css`
      cursor: default;
      pointer-events: none;

      &:hover ${SelectedIcon} {
        visibility: visible;
      }
    `}
`;

type Props<Option> = {
  placeholder: string;
  options: Option[];
  values: any[];
  idName: keyof Option;
  textName: keyof Option;
  width: number;
  testId: string;
  onSelect: (selectedOption: Option) => void;
  onDeselect: (selectedOption: Option) => void;
  /* eslint-disable react/require-default-props */
  optionsPosition?: OptionsPosition;
  onClear?: () => void;
  noSelectOptionLabel?: string;
  /* eslint-enable react/require-default-props */
};

function MultiSelectbox<Option extends {}>(props: Props<Option>) {
  const {
    placeholder,
    values,
    options,
    width,
    idName,
    textName,
    testId,
    onSelect,
    onDeselect,
    optionsPosition = 'bottom',
    noSelectOptionLabel,
    onClear,
  } = props;
  const [expanded, setExpanded] = useState(false);
  const handleClickBase = useCallback(() => {
    setExpanded(prev => !prev);
  }, []);

  const handleClickItem = useCallback(
    (option: Option) => {
      if (values.includes(option[idName])) {
        onDeselect(option);
      } else {
        onSelect(option);
      }
    },
    [idName, onDeselect, onSelect, values],
  );

  return (
    <MultiSelectboxRoot width={width}>
      <InputBase
        role="button"
        aria-haspopup
        area-expanded={expanded}
        onClick={handleClickBase}
        data-testid={testId}
      >
        <SelectedValue>
          {options.some(o => values.includes(o[idName]))
            ? options
                .filter(o => values.includes(o[idName]))
                .map(o => o[textName])
                .join(', ')
            : placeholder}
        </SelectedValue>
        <MdKeyboardArrowDown fill={theme.textPrimary} size={20} />
      </InputBase>
      {expanded && (
        <>
          <Overlay onDismiss={() => setExpanded(false)} />
          <OptionsPanel
            data-testid="multi-selectbox-options-panel"
            position={optionsPosition}
          >
            <ul role="listbox">
              {noSelectOptionLabel && (
                <NoSelectboxOption
                  onClick={onClear}
                  isSelected={values.length === 0}
                  data-testid="multi-selectbox-option-no-select"
                >
                  <>
                    <div>{noSelectOptionLabel}</div>
                    {values.length === 0 ? <SelectedIcon /> : <AddIcon />}
                  </>
                </NoSelectboxOption>
              )}
              {options.map((option, index) => (
                <SelectboxOption
                  key={(option[idName] as unknown) as string | number}
                  onClick={() => handleClickItem(option)}
                  data-testid={`multi-selectbox-option${index}`}
                >
                  <div>{option[textName]}</div>
                  {values.includes(option[idName]) ? (
                    <>
                      <SelectedIcon />
                      <RemoveIcon />
                    </>
                  ) : (
                    <AddIcon />
                  )}
                </SelectboxOption>
              ))}
            </ul>
          </OptionsPanel>
        </>
      )}
    </MultiSelectboxRoot>
  );
}

export default MultiSelectbox;
