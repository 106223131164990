import { useCallback, ChangeEvent } from 'react';
import { MissionCategory } from 'proto/v1/apimodel/apimodel';
import { MISSION_CATEGORIES_MAX_COUNT } from 'constants/config';
import { MissionCategoryFormValue } from 'types/form';
import { MissionSettingFormik } from './useMissionSettingFormik';

type Payload = {
  missionCategoryIds: MissionCategoryFormValue;
  setFieldValue: MissionSettingFormik['setFieldValue'];
  setFieldTouched: MissionSettingFormik['setFieldTouched'];
};

const useMissionCategoriesField = ({
  missionCategoryIds,
  setFieldValue,
  setFieldTouched,
}: Payload) => {
  const handleChangeCategory = useCallback(
    (
      event: ChangeEvent<HTMLInputElement>,
      targetCategory: MissionCategory,
    ): void => {
      const target = event.currentTarget;
      if (target.checked) {
        if (
          missionCategoryIds &&
          missionCategoryIds.length >= MISSION_CATEGORIES_MAX_COUNT
        ) {
          return;
        }
        setFieldValue('missionCategories', [
          ...missionCategoryIds,
          targetCategory.categoryId,
        ]);
      } else {
        setFieldValue(
          'missionCategories',
          missionCategoryIds.filter(
            (categoryId: number) => categoryId !== targetCategory.categoryId,
          ),
        );
      }
      // should set shouldValidate flag false to avoid incorrect validate errors
      setFieldTouched('missionCategories', true, false);
    },
    [setFieldTouched, setFieldValue, missionCategoryIds],
  );

  return {
    handleChangeCategory,
  };
};

export default useMissionCategoriesField;
