import { ComponentProps } from 'react';
import styled from 'styled-components/macro';
import BaseTextareaAutosize from 'react-textarea-autosize';
import theme from 'styles/theme';
import scrollbar from 'styles/scrollbar';

type Props = ComponentProps<typeof BaseTextareaAutosize>;

const TextareaAutosize = styled(BaseTextareaAutosize)<Props>`
  ${scrollbar}
  width: 100%;
  padding: 8px 12px;
  border: 1px solid ${theme.borderDefault};
  border-radius: 3px;
  outline: none;
  font-size: 14px;
  line-height: 1.5;
  appearance: none;

  &:hover,
  &:focus,
  &:active {
    border-color: ${theme.borderDefault};
    box-shadow: none;
  }

  &::placeholder {
    color: ${theme.borderDefault};
  }

  &:hover:not(:disabled):not(:focus) {
    border-color: ${theme.borderDefault};
  }

  &:hover:disabled {
    border-color: ${theme.borderDefault};
  }
`;

export default TextareaAutosize;
