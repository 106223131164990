import React, { FC, ChangeEvent } from 'react';
import styled from 'styled-components/macro';
import { useTranslation } from 'react-i18next';
import { isEqualProps } from 'utils/object';
import { Fund } from 'proto/v1/apimodel/apimodel';
import typography from 'styles/typography';
import { FundIds } from 'constants/models/mission';
import { Checkbox, Selectbox } from 'components/atoms';
import { FormItem, MissionDemandFieldItem } from 'components/molecules';

const Label = styled.p`
  ${typography.textDefault}
  margin-bottom: 5px;
`;

const FinancingSeriesField = styled(FormItem)`
  &&& {
    width: 100%;
    margin-bottom: 0;
    margin-left: 29px;
  }
`;

const StyledSelectbox = styled(Selectbox)`
  width: 180px;
`;

type TransferredOfBusinessFieldProps = {
  wantTransferredOfBusiness: boolean;
  fundId: number;
  form: Fund[];
  onChangeWantTransferredOfBusiness: (e: ChangeEvent<HTMLInputElement>) => void;
  onChangeTransferredOfBusinessFund: (
    e: ChangeEvent<HTMLSelectElement>,
  ) => void;
  onBlur: (e: any) => void;
};

const TransferredOfBusinessField: FC<TransferredOfBusinessFieldProps> = props => {
  const {
    wantTransferredOfBusiness,
    fundId,
    form,
    onChangeWantTransferredOfBusiness,
    onChangeTransferredOfBusinessFund,
    onBlur,
  } = props;

  const { t } = useTranslation('missionSetting');

  return (
    <MissionDemandFieldItem>
      <Checkbox
        name="missionDemand.wantTransferredOfBusiness"
        checked={wantTransferredOfBusiness}
        onChange={onChangeWantTransferredOfBusiness}
        aria-label={t('field.missionDemand.checkbox.wantTransferredOfBusiness')}
      >
        {t('field.missionDemand.checkbox.wantTransferredOfBusiness')}
      </Checkbox>
      {wantTransferredOfBusiness && (
        <FinancingSeriesField>
          <Label>{t('field.missionDemand.transferredOfBusiness.label')}</Label>
          <StyledSelectbox
            name="missionDemand.transferredOfBusiness.fundId"
            options={form.map(fund =>
              fund.fundId !== FundIds.NA
                ? fund
                : {
                    ...fund,
                    displayText: t(
                      'field.missionDemand.transferredOfBusiness.na',
                    ),
                  },
            )}
            idName="fundId"
            textName="displayText"
            value={fundId}
            onChange={onChangeTransferredOfBusinessFund}
            onBlur={onBlur}
            disabled={!wantTransferredOfBusiness}
            aria-label={t('field.missionDemand.transferredOfBusiness.label')}
          />
        </FinancingSeriesField>
      )}
    </MissionDemandFieldItem>
  );
};

export default React.memo(TransferredOfBusinessField, (prevProps, nextProps) =>
  isEqualProps(prevProps, nextProps, [
    'wantTransferredOfBusiness',
    'fundId',
    'form',
  ]),
);
