import { imageSelector } from 'utils/imageSelector';
import { isEmptyMissionProduct } from 'utils/models/mission';
import { MissionFormValues } from 'types/mission';
import { SupporterFormValues } from 'types/supporter';
import {
  MissionMemberFormValue,
  SupporterMemberFormValue,
  MissionProductFormValue,
} from 'types/form';
import { FinancingSeriesIds } from 'constants/models/mission';
import {
  MissionDemandToUpdate,
  MissionProductToUpdate,
} from 'proto/v1/apimodel/apimodel';
import { UpdateMissionRequest } from 'proto/v1/missionservice/missionservice';
import { UpdateSupporterRequest } from 'proto/v1/supporterservice/supporterservice';

const isEmptyMemberToUpdate = (
  member: MissionMemberFormValue | SupporterMemberFormValue,
) =>
  member.name === '' &&
  member.career === '' &&
  !imageSelector(member.iconImage);

export const getIconImageToUpdate = (
  iconImage: MissionFormValues['iconImage'] | SupporterFormValues['iconImage'],
): UpdateMissionRequest['iconImage'] | UpdateSupporterRequest['iconImage'] =>
  (iconImage &&
    'key' in iconImage && {
      data: '',
      delete: false,
      key: iconImage.key as string,
    }) ||
  undefined;

export const getCoverImageToUpdate = (
  coverImage:
    | MissionFormValues['coverImage']
    | SupporterFormValues['coverImage'],
): UpdateMissionRequest['coverImage'] | UpdateSupporterRequest['coverImage'] =>
  (coverImage &&
    'key' in coverImage && {
      data: '',
      delete: false,
      key: coverImage.key as string,
    }) ||
  undefined;

export const getJapanPrefectureToUpdate = (
  countryId: MissionFormValues['countryId'] | SupporterFormValues['countryId'],
  japanPrefectureId:
    | MissionFormValues['japanPrefectureId']
    | SupporterFormValues['japanPrefectureId'],
):
  | UpdateMissionRequest['japanPrefectureId']
  | UpdateSupporterRequest['japanPrefectureId'] =>
  countryId === 'JPN' ? japanPrefectureId : undefined;

export const getMissionMembersToUpdate = (
  members: MissionFormValues['missionMembers'],
): UpdateMissionRequest['missionMembers'] =>
  members
    .filter(member => !isEmptyMemberToUpdate(member))
    .map(member => {
      const missionMemberId =
        member && member.missionMemberId && member.missionMemberId > 0
          ? member.missionMemberId
          : undefined;
      const iconImage =
        (member &&
          member.iconImage &&
          'key' in member.iconImage && {
            data: '',
            delete: false,
            key: member.iconImage.key as string,
          }) ||
        undefined;
      return {
        ...member,
        missionMemberId,
        iconImage,
      };
    });

export const getSupporterMembersToUpdate = (
  members: SupporterFormValues['supporterMembers'],
): UpdateSupporterRequest['supporterMembers'] =>
  members
    .filter(member => !isEmptyMemberToUpdate(member))
    .map(member => {
      const supporterMemberId =
        member && member.supporterMemberId && member.supporterMemberId > 0
          ? member.supporterMemberId
          : undefined;
      const iconImage =
        (member &&
          member.iconImage &&
          'key' in member.iconImage && {
            data: '',
            delete: false,
            key: member.iconImage.key as string,
          }) ||
        undefined;
      return {
        ...member,
        supporterMemberId,
        iconImage,
      };
    });

export const getMissionDemandToUpdate = (
  missionDemand: MissionFormValues['missionDemand'],
): MissionDemandToUpdate => {
  const convert = <T>(want: boolean, data: T): T | undefined =>
    want && data ? data : undefined;

  const financingSeriesId: number[] = [];
  if (missionDemand.wantInvestSeed)
    financingSeriesId.push(FinancingSeriesIds.SEED);
  if (missionDemand.wantInvestEarly)
    financingSeriesId.push(FinancingSeriesIds.EARLY);
  if (missionDemand.wantInvestMiddle)
    financingSeriesId.push(FinancingSeriesIds.MIDDLE);
  if (missionDemand.wantInvestLater)
    financingSeriesId.push(FinancingSeriesIds.LATER);

  const assets =
    missionDemand.collaboration && missionDemand.collaboration.assets.length > 0
      ? [missionDemand.collaboration.assets[0]]
      : [];

  const issues =
    missionDemand.serviceInformation &&
    missionDemand.serviceInformation.issues.length > 0
      ? [missionDemand.serviceInformation.issues[0]]
      : [];

  return {
    collaboration: convert(missionDemand.wantCollaboration, { assets }),
    invest: convert(missionDemand.wantInvest, { financingSeriesId }),
    fundraising: convert(
      missionDemand.wantFundraising,
      missionDemand.fundraising,
    ),
    serviceInformation: convert(missionDemand.wantServiceInformation, {
      issues,
    }),
    serviceSuggestion: convert(missionDemand.wantServiceSuggestion, {}),
    transferOfBusiness: convert(missionDemand.wantTransferOfBusiness, {}),
    transferredOfBusiness: convert(
      missionDemand.wantTransferredOfBusiness,
      missionDemand.transferredOfBusiness,
    ),
    acceptSecondment: convert(
      missionDemand.wantAcceptSecondment,
      missionDemand.acceptSecondment,
    ),
    sendEmployee: convert(
      missionDemand.wantSendEmployee,
      missionDemand.sendEmployee,
    ),
    furnishedOfficeRentalAvailability: convert(
      missionDemand.wantFurnishedOfficeRentalAvailability,
      {},
    ),
    furnishedOfficeRentalRequest: convert(
      missionDemand.wantFurnishedOfficeRentalRequest,
      {},
    ),
  };
};

export const getMissionOfficeImagesToUpdate = (
  officeImages: MissionFormValues['missionOfficeImages'],
): UpdateMissionRequest['missionOfficeImages'] =>
  officeImages
    .map(officeImage => {
      if (!officeImage.image) {
        // empty (should omit)
        return {
          missionOfficeImageId: undefined,
          image: undefined,
        };
      }

      if (officeImage.missionOfficeImageId > 0) {
        // exist office image item
        if (
          'key' in officeImage.image &&
          typeof officeImage.image.key === 'string'
        ) {
          // update image
          return {
            ...officeImage,
            image: {
              data: '',
              delete: false,
              key: officeImage.image.key,
            },
          };
        }
        // not update
        return {
          ...officeImage,
          // must be undefined when image is not updated.
          image: undefined,
        };
      }
      // added
      if (
        'key' in officeImage.image &&
        typeof officeImage.image.key === 'string'
      ) {
        return {
          // must be undefined when added new image object.
          missionOfficeImageId: undefined,
          image: {
            data: '',
            delete: false,
            key: officeImage.image.key,
          },
        };
      }
      // unexpected (should omit)
      return {
        missionOfficeImageId: undefined,
        image: undefined,
      };
    })
    .filter(
      officeImage => officeImage.missionOfficeImageId || !!officeImage.image,
    );

export const getGalleryImagesToUpdate = (
  galleryImages: SupporterFormValues['galleryImages'],
): UpdateSupporterRequest['galleryImages'] =>
  galleryImages
    .map(galleryImage => {
      if (!galleryImage.image) {
        return {
          galleryImageId: undefined,
          image: undefined,
        };
      }

      if (galleryImage.galleryImageId > 0) {
        // is uploading image
        if ('key' in galleryImage.image) {
          // update image
          return {
            ...galleryImage,
            image: {
              data: '',
              delete: false,
              key: galleryImage.image.key,
            },
          };
        }
        // this galleryImage has not been updated
        return {
          ...galleryImage,
          // must be undefined when image is not updated.
          image: undefined,
        };
      }
      // added
      if ('key' in galleryImage.image) {
        return {
          // must be undefined when added new image object.
          galleryImageId: undefined,
          image: {
            data: '',
            delete: false,
            key: galleryImage.image.key,
          },
        };
      }
      // unexpected (should omit)
      return {
        galleryImageId: undefined,
        image: undefined,
      };
    })
    .filter(
      galleryImage => galleryImage.galleryImageId || !!galleryImage.image,
    );

export const getMissionProductIdToUpdate = (
  missionProduct: MissionProductFormValue,
): MissionProductToUpdate['missionProductId'] =>
  missionProduct.missionProductId && missionProduct.missionProductId > 0
    ? missionProduct.missionProductId
    : undefined;

export const getMissionProductMemberBenefitToUpdate = (
  missionProduct: MissionProductFormValue,
): MissionProductToUpdate['memberBenefit'] =>
  missionProduct.hasMemberBenefit && missionProduct.memberBenefit
    ? missionProduct.memberBenefit
    : '';

export const getMissionProductImageToUpdate = (
  missionProduct: MissionProductFormValue,
): MissionProductToUpdate['image'] =>
  (missionProduct.image &&
    'key' in missionProduct.image && {
      data: '',
      delete: false,
      key: missionProduct.image.key,
    }) ||
  undefined;

export const getMissionProductTagToUpdate = (
  missionProduct: MissionProductFormValue,
): MissionProductToUpdate['tags'] =>
  missionProduct.tags.filter(tag => tag.tag !== '').map(tag => tag.tag);

export const getMissionProductsToUpdate = (
  missionProducts: MissionFormValues['missionProducts'],
): UpdateMissionRequest['missionProducts'] =>
  missionProducts
    .filter(missionProduct => !isEmptyMissionProduct(missionProduct))
    .map(missionProduct => ({
      missionProductId: getMissionProductIdToUpdate(missionProduct),
      name: missionProduct.name,
      overview: missionProduct.overview,
      url: missionProduct.url,
      problem: missionProduct.problem,
      solution: missionProduct.solution,
      memberBenefit: getMissionProductMemberBenefitToUpdate(missionProduct),
      image: getMissionProductImageToUpdate(missionProduct),
      productTarget: missionProduct.productTarget,
      tags: getMissionProductTagToUpdate(missionProduct),
    }));

export const getMissionJobOffersUpdate = (
  missionJobOffers: MissionFormValues['missionJobOffers'],
): UpdateMissionRequest['missionJobOffers'] =>
  missionJobOffers.map(missionJobOffer => ({
    occupationId: missionJobOffer.occupationId,
    jobDescription: missionJobOffer.jobDescription,
    published: missionJobOffer.published,
  }));
