import React, { FC } from 'react';
import styled from 'styled-components/macro';
import typography from 'styles/typography';
import {
  ButtonXL,
  ButtonContainer,
  ImageCenter,
  WrapText,
} from 'components/atoms';
import { Images } from 'proto/v1/apimodel/apimodel';
import { noImageSquare } from 'assets/png';
import { imageSelector } from 'utils/imageSelector';
import theme from 'styles/theme';

type WrapperProps = { show: boolean; isPreview: boolean };

const Wrapper = styled.div<WrapperProps>`
  display: flex;
  position: fixed;
  bottom: 0;
  width: 100%;
  transform: translateY(${({ show }) => (show ? '0px' : '200px')});
  transition: all 300ms cubic-bezier(0.3, 0.3, 0.3, 1);
  background-color: rgba(255, 255, 255, 0.75);
  ${({ isPreview }) => isPreview && 'justify-content: center; left: 0;'}
`;

const Contents = styled.div`
  display: flex;
  justify-content: space-between;
  width: 864px;
  min-width: 864px;
  padding-top: 24px;
  padding-right: ${({ isPreview }: { isPreview: boolean }) =>
    isPreview ? '52px' : '22px'};
  padding-bottom: 24px;
  padding-left: ${({ isPreview }: { isPreview: boolean }) =>
    isPreview ? 0 : '30px'};
`;

const ContentsLeft = styled.div`
  display: flex;
  align-items: center;
`;

const ContentsRight = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

const MissionName = styled(WrapText)`
  ${typography.textBold}
  max-width: 458px;
`;

const CompanyName = styled.div`
  ${typography.textSub}
`;

const IconImage = styled(ImageCenter)`
  margin-right: 20px;
  padding: 4px;
  border-radius: 4px;
  background-origin: content-box;
  background-color: ${theme.white};
`;

type FooterContentProps = {
  missionName: string;
  iconImage: Images | undefined;
  companyName: string;
  labelContact: string;
  labelContacted: string;
  show?: boolean;
  onFollow?: () => void;
  isFollowed?: boolean;
  isPreview?: boolean;
  showContactButton?: boolean;
};

const FooterContent: FC<FooterContentProps> = props => {
  const {
    missionName,
    iconImage,
    companyName,
    onFollow,
    labelContact,
    labelContacted,
    show = true,
    isFollowed = false,
    isPreview = false,
    showContactButton = true,
  } = props;

  return (
    <Wrapper
      show={show}
      isPreview={isPreview}
      data-testid="mission-detail-footer"
      data-test-show-footer={show}
    >
      <Contents isPreview={isPreview}>
        <ContentsLeft>
          <IconImage
            imageSrc={imageSelector(iconImage, 'small') || noImageSquare}
            width={60}
            height={60}
            data-testid="mission-detail-footer-icon-image"
          />
          <div>
            <MissionName>{missionName}</MissionName>
            <CompanyName>{companyName}</CompanyName>
          </div>
        </ContentsLeft>
        <ContentsRight>
          {showContactButton && !isFollowed && (
            <ButtonContainer>
              <ButtonXL
                buttonTheme="gradation"
                onClick={onFollow}
                style={isPreview ? { cursor: 'not-allowed' } : {}}
                data-testid="mission-detail-footer-follow-button"
              >
                {labelContact}
              </ButtonXL>
            </ButtonContainer>
          )}
          {showContactButton && isFollowed && (
            <ButtonContainer>
              <ButtonXL
                buttonTheme="default"
                onClick={() => null}
                disabled
                data-testid="mission-detail-footer-followed-button"
              >
                {labelContacted}
              </ButtonXL>
            </ButtonContainer>
          )}
        </ContentsRight>
      </Contents>
    </Wrapper>
  );
};

export default FooterContent;
