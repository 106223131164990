import React, { FC, ChangeEvent, useCallback } from 'react';
import styled, { css } from 'styled-components/macro';
import { useTranslation } from 'react-i18next';
import { SupporterMember } from 'proto/v1/apimodel/apimodel';
import { SupporterMemberFormValue, SupporterMemberError } from 'types/form';
import { MEMBERS_MAX_COUNT } from 'constants/config';
import { imageSelector } from 'utils/imageSelector';
import { isEqualProps } from 'utils/object';
import { PlusGray } from 'assets/svg';
import {
  DeletableBlock,
  MissionSettingFormItem,
  FormItemLabel,
  OutlinedIconButton,
} from 'components/molecules';
import { IconImageField, NameField, CareerField } from './SubFields';

const AddSupporterMembersField = styled.div`
  display: flex;
  justify-content: center;
  padding-top: 12px;
`;

const InputFields = styled.div`
  width: 100%;
`;

const FormField = styled.div`
  display: flex;
  justify-content: space-between;
`;

const errorMessageStyle = css`
  padding: 0 0 10px 116px;
`;

type SupporterMembersFieldProps = {
  value: SupporterMemberFormValue[];
  error: SupporterMemberError[] | undefined;
  onAddMember: () => void;
  onDeleteMember: (index: number) => void;
  onChangeSupporterMemberName: (e: ChangeEvent<HTMLInputElement>) => void;
  onChangeSupporterMemberCareer: (e: ChangeEvent<HTMLTextAreaElement>) => void;
  onChangeSupporterMemberIconImage: (imageSrc: string, index: number) => void;
  onExceedImageFileSize: () => void;
  onBlur: (e: any) => void;
};

const SupporterMembersField: FC<SupporterMembersFieldProps> = props => {
  const {
    value,
    error,
    onAddMember,
    onDeleteMember,
    onChangeSupporterMemberName,
    onChangeSupporterMemberCareer,
    onChangeSupporterMemberIconImage,
    onExceedImageFileSize,
    onBlur,
  } = props;

  const { t } = useTranslation('missionSetting');

  const hasError = useCallback(
    (index: number) =>
      !!error && error[index] && Object.keys(error[index]).length > 0,
    [error],
  );

  return (
    <MissionSettingFormItem>
      <FormItemLabel
        label={t('field.supporterMembers.label')}
        note={t('field.supporterMembers.note', {
          maxCount: MEMBERS_MAX_COUNT,
        })}
        style={{ marginBottom: 12 }}
      />
      <div>
        {value &&
          value.map((supporterMember: SupporterMember, index: number) => (
            <DeletableBlock
              onDelete={() => {
                onDeleteMember(index);
              }}
              errorMessage={
                hasError(index)
                  ? t('validation.noInputSupporterMember')
                  : undefined
              }
              errorMessageStyle={errorMessageStyle}
              key={supporterMember && supporterMember.supporterMemberId}
              style={{ width: '100%' }}
            >
              <FormField data-testid="add-member-form">
                <IconImageField
                  imageSrc={imageSelector(supporterMember.iconImage, 'small')}
                  onUpload={uploadedImage => {
                    onChangeSupporterMemberIconImage(uploadedImage, index);
                  }}
                  onExceededFileSizeLimit={onExceedImageFileSize}
                />

                <InputFields>
                  <NameField
                    index={index}
                    value={value[index].name}
                    onChange={onChangeSupporterMemberName}
                    onBlur={onBlur}
                  />

                  <CareerField
                    index={index}
                    value={value[index].career}
                    onChange={onChangeSupporterMemberCareer}
                    onBlur={onBlur}
                  />
                </InputFields>
              </FormField>
            </DeletableBlock>
          ))}
        {(!value || (value && value.length < MEMBERS_MAX_COUNT)) && (
          <AddSupporterMembersField>
            <OutlinedIconButton
              icon={<PlusGray />}
              onClick={onAddMember}
              data-testid="add-member"
            >
              {t('field.supporterMembers.add')}
            </OutlinedIconButton>
          </AddSupporterMembersField>
        )}
      </div>
    </MissionSettingFormItem>
  );
};

export default React.memo(SupporterMembersField, (prevProps, nextProps) =>
  isEqualProps(prevProps, nextProps, ['value', 'error']),
);
