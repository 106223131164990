import styled from 'styled-components/macro';
import theme from 'styles/theme';

const DetailContentsLeftItem = styled.div`
  padding: 0 24px 32px 32px;

  &:not(:first-child) {
    padding-top: 32px;
    border-top: 1px solid ${theme.borderDefault};
  }
`;

export default DetailContentsLeftItem;
