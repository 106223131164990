import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import theme from 'styles/theme';
import {
  NewGraduateHumanResourceFilterFormPastExperienceAchievement,
  NewGraduateHumanResourceFilterFormPreferredBusinessType,
  NewGraduateHumanResourceFilterFormOccupation,
  NewGraduateHumanResourceFilterFormWorkLocation,
  NewGraduateHumanResourceFilterForm,
  NewGraduateMattersDetailFilterValues,
  NewGraduateHumanResourceFilterFormPreferredWorkStyle,
} from 'types/matter';
import { ButtonM, FlexContainer } from 'components/atoms';
import {
  Modal,
  ModalContent,
  ModalFooter,
  MultiSelectbox,
} from 'components/molecules';

const StyledFlexContainer = styled(FlexContainer)`
  height: 100%;
`;

const StyledModalContent = styled(ModalContent)`
  display: flex;
  flex: 1;
  justify-content: flex-start;
  margin-bottom: 0;
  padding: 0;
`;

const FilterItems = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
  padding: 24px 0;
  background-color: ${theme.bgAdd};
`;

const FilterItem = styled.div`
  margin-bottom: 24px;
`;

const FilterLabel = styled.p`
  margin-bottom: 6px;
  color: ${theme.textPrimaryHover};
  font-size: 11px;
  line-height: 1;
  text-align: left;
`;

type OnSelect<T> = (option: T) => void;
type OnDeselect<T> = (option: T) => void;

type DetailFilterModalProps = {
  show: boolean;
  closeModal: () => void;
  values: NewGraduateMattersDetailFilterValues;
  form: NewGraduateHumanResourceFilterForm;
  onSubmit: () => void;
  onSelectPastExperienceAchievement: OnSelect<
    NewGraduateHumanResourceFilterFormPastExperienceAchievement
  >;
  onDeselectPastExperienceAchievement: OnDeselect<
    NewGraduateHumanResourceFilterFormPastExperienceAchievement
  >;
  onSelectPreferredBusinessType: OnSelect<
    NewGraduateHumanResourceFilterFormPreferredBusinessType
  >;
  onDeselectPreferredBusinessType: OnDeselect<
    NewGraduateHumanResourceFilterFormPreferredBusinessType
  >;
  onSelectOccupation: OnSelect<NewGraduateHumanResourceFilterFormOccupation>;
  onDeselectOccupation: OnDeselect<
    NewGraduateHumanResourceFilterFormOccupation
  >;
  onSelectWorkLocation: OnSelect<
    NewGraduateHumanResourceFilterFormWorkLocation
  >;
  onDeselectWorkLocation: OnDeselect<
    NewGraduateHumanResourceFilterFormWorkLocation
  >;

  onSelectPreferredWorkStyle: OnSelect<
    NewGraduateHumanResourceFilterFormPreferredWorkStyle
  >;
  onDeselectPreferredWorkStyle: OnDeselect<
    NewGraduateHumanResourceFilterFormPreferredWorkStyle
  >;
};

const DetailFilterModal: React.VFC<DetailFilterModalProps> = ({
  show,
  closeModal,
  values,
  form,
  onSubmit,
  onSelectPastExperienceAchievement,
  onDeselectPastExperienceAchievement,
  onSelectPreferredBusinessType,
  onDeselectPreferredBusinessType,
  onSelectOccupation,
  onDeselectOccupation,
  onSelectWorkLocation,
  onDeselectWorkLocation,
  onSelectPreferredWorkStyle,
  onDeselectPreferredWorkStyle,
}) => {
  const { t } = useTranslation(['humanResource']);

  return (
    <Modal
      show={show}
      closeModal={closeModal}
      height="540px"
      style={{ paddingLeft: 0, paddingRight: 0 }}
    >
      <StyledFlexContainer flexDirection="column">
        <StyledModalContent data-testid="detail-filter-modal">
          <FilterItems>
            <FilterItem>
              <FilterLabel>
                {t('filter.field.preferredWorkStyle.label', { lng: 'ja' })}
              </FilterLabel>
              <MultiSelectbox<
                NewGraduateHumanResourceFilterFormPreferredWorkStyle
              >
                values={values.preferredWorkStyleIds}
                placeholder={t('filter.field.preferredWorkStyle.placeholder', {
                  lng: 'ja',
                })}
                options={form.preferredWorkStyles}
                width={640}
                onSelect={onSelectPreferredWorkStyle}
                onDeselect={onDeselectPreferredWorkStyle}
                idName="id"
                textName="name"
                testId="multi-select-box-preferred-work-style"
              />
            </FilterItem>
            <FilterItem>
              <FilterLabel>
                {t('filter.field.pastExperienceAchievement.label', {
                  lng: 'ja',
                })}
              </FilterLabel>
              <MultiSelectbox<
                NewGraduateHumanResourceFilterFormPastExperienceAchievement
              >
                values={values.pastExperienceAchievementIds}
                placeholder={t(
                  'filter.field.pastExperienceAchievement.placeholder',
                  {
                    lng: 'ja',
                  },
                )}
                options={form.pastExperienceAchievements}
                width={640}
                onSelect={onSelectPastExperienceAchievement}
                onDeselect={onDeselectPastExperienceAchievement}
                idName="id"
                textName="name"
                testId="multi-select-box-past-experience-achievement"
              />
            </FilterItem>
            <FilterItem>
              <FilterLabel>
                {t('filter.field.preferredBusinessType.label', {
                  lng: 'ja',
                })}
              </FilterLabel>
              <MultiSelectbox<
                NewGraduateHumanResourceFilterFormPreferredBusinessType
              >
                values={values.preferredBusinessTypeIds}
                placeholder={t(
                  'filter.field.preferredBusinessType.placeholder',
                  {
                    lng: 'ja',
                  },
                )}
                options={form.preferredBusinessTypes}
                width={640}
                onSelect={onSelectPreferredBusinessType}
                onDeselect={onDeselectPreferredBusinessType}
                idName="id"
                textName="name"
                testId="multi-select-box-preferred-business-type"
              />
            </FilterItem>
            <FilterItem>
              <FilterLabel>
                {t('filter.field.occupation.newGraduate.label', {
                  lng: 'ja',
                })}
              </FilterLabel>
              <MultiSelectbox<NewGraduateHumanResourceFilterFormOccupation>
                values={values.occupationIds}
                placeholder={t(
                  'filter.field.occupation.newGraduate.placeholder',
                  {
                    lng: 'ja',
                  },
                )}
                options={form.occupations}
                width={640}
                onSelect={onSelectOccupation}
                onDeselect={onDeselectOccupation}
                idName="id"
                textName="name"
                optionsPosition="top"
                testId="multi-select-box-occupation"
              />
            </FilterItem>
            <FilterItem style={{ marginBottom: 0 }}>
              <FilterLabel>
                {t('filter.field.workLocation.newGraduate.label', {
                  lng: 'ja',
                })}
              </FilterLabel>
              <MultiSelectbox<NewGraduateHumanResourceFilterFormWorkLocation>
                values={values.workLocationIds}
                placeholder={t(
                  'filter.field.workLocation.newGraduate.placeholder',
                  {
                    lng: 'ja',
                  },
                )}
                options={form.workLocations}
                width={640}
                onSelect={onSelectWorkLocation}
                onDeselect={onDeselectWorkLocation}
                idName="id"
                textName="name"
                testId="multi-select-box-work-location"
                optionsPosition="top"
              />
            </FilterItem>
          </FilterItems>
        </StyledModalContent>
        <ModalFooter style={{ paddingBottom: '24px', height: '88px' }}>
          <ButtonM
            buttonTheme="default"
            onClick={() => {
              onSubmit();
              closeModal();
            }}
          >
            {t('modal.newGraduateDetailFilter.button.submit', { lng: 'ja' })}
          </ButtonM>
        </ModalFooter>
      </StyledFlexContainer>
    </Modal>
  );
};

export default DetailFilterModal;
