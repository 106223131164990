import { SupporterFormValues, SupporterFormErrors } from 'types/supporter';
import {
  getSupporterMembersErrorMessages,
  getCompanyUrlErrorMessages,
  filterEmptyErrors,
  ValidateToSave,
} from '../validate/validateToSave';

const validateToSaveSupporter: ValidateToSave<SupporterFormValues> = (
  errors,
  values,
) => {
  const formErrors = errors as SupporterFormErrors;

  const errorMessages = {
    missionMembers: getSupporterMembersErrorMessages(
      formErrors.supporterMembers,
      values.supporterMembers,
    ),
    companyUrl: getCompanyUrlErrorMessages(formErrors.companyUrl),
  };

  return filterEmptyErrors(errorMessages);
};

export default validateToSaveSupporter;
