import React, { FC, ComponentProps, useMemo } from 'react';
import styled from 'styled-components/macro';
import { useTranslation } from 'react-i18next';
import { Checkbox, ButtonS } from 'components/atoms';
import {
  Picker,
  PickerHeading,
  PickerContent,
  PickerFooter,
} from 'components/molecules';
import { SupportArea } from 'proto/v1/apimodel/apimodel';
import {
  SupportAreaPickerValues,
  SupportAreaFilterValues,
} from 'types/supporter';
import { sortedSupportAreas } from 'utils/models/supporter';
import useSupportAreaPicker from './useSupportAreaPicker';

const COLUMNS = 3;

const Form = styled.form`
  width: 640px;
`;

const HeadingCheckbox = styled(Checkbox)`
  &&& {
    display: inline-block;
    margin-right: 0;
    margin-bottom: 0;
    margin-left: 16px;

    label {
      font-weight: normal;
    }
  }
`;

const PickerCheckboxes = styled.div`
  display: flex;
  flex-direction: column;
`;

const PickerCheckboxRow = styled.div`
  display: flex;
  margin-bottom: 16px;

  &:last-child {
    margin-bottom: 0;
  }
`;

const PickerCheckbox = styled(Checkbox)`
  &&& {
    display: flex;
    min-width: 192px;
    margin-right: 0;
    margin-bottom: 0;

    &:nth-child(3n + 2) {
      padding-left: 32px;
    }

    &:nth-child(3n) {
      padding-left: 48px;
    }
  }
`;

type PickerProps = ComponentProps<typeof Picker>;

type SupportAreaPickerProps = {
  onSubmit: (values: SupportAreaPickerValues) => void;
  form: { supportAreas: SupportArea[] | undefined };
  defaultValues: SupportAreaFilterValues;
} & Pick<
  PickerProps,
  'isActive' | 'isSelected' | 'onClickTag' | 'onClickOverlay'
>;

const SupportAreaPicker: FC<SupportAreaPickerProps> = props => {
  const {
    isActive,
    isSelected,
    onClickTag,
    onClickOverlay,
    onSubmit,
    form,
    defaultValues,
  } = props;

  const { t } = useTranslation(['supporterList', 'common']);

  const {
    formik,
    handleChangeSupportArea,
    handleChangeCheckAllSupportAreas,
  } = useSupportAreaPicker({
    onSubmit,
    form,
    defaultValues,
    isSelected,
  });

  const { values, handleSubmit } = formik;

  const supportAreas = useMemo(() => {
    if (!form?.supportAreas) return [];
    const ordered = sortedSupportAreas(form.supportAreas);
    return Array.from({ length: Math.ceil(ordered.length / COLUMNS) }, (_, i) =>
      ordered.slice(i * COLUMNS, i * COLUMNS + COLUMNS),
    );
  }, [form]);

  return (
    <Picker
      label={t('picker.supportArea.label')}
      isSelected={isSelected}
      isActive={isActive}
      onClickTag={onClickTag}
      onClickOverlay={onClickOverlay}
    >
      <Form onSubmit={handleSubmit} data-testid="support-area-picker">
        <PickerHeading>
          {t('picker.supportArea.field.supportArea.label')}
          <HeadingCheckbox
            name="checkAllSupportAreas"
            onChange={handleChangeCheckAllSupportAreas}
            checked={values.checkAllSupportAreas}
            aria-label={t('picker.checkbox.toggleCheckAll')}
          >
            {t('picker.checkbox.toggleCheckAll')}
          </HeadingCheckbox>
        </PickerHeading>

        <PickerContent>
          <PickerCheckboxes>
            {supportAreas.map((rowItems, i) => (
              <PickerCheckboxRow key={i.toString()}>
                {rowItems.map(item => (
                  <PickerCheckbox
                    key={item.supportAreaId}
                    name="supportAreaIds"
                    onChange={handleChangeSupportArea}
                    value={item.supportAreaId}
                    checked={values.supportAreaIds.includes(item.supportAreaId)}
                    aria-label={item.name}
                  >
                    {item.name}
                  </PickerCheckbox>
                ))}
              </PickerCheckboxRow>
            ))}
          </PickerCheckboxes>
        </PickerContent>

        <PickerFooter>
          <ButtonS buttonTheme="default" type="submit">
            {t('common:button.save')}
          </ButtonS>
        </PickerFooter>
      </Form>
    </Picker>
  );
};

export default SupportAreaPicker;
