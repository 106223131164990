import RequestError from 'classes/RequestError';
import { useCallback, useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import {
  errorDialogActions,
  errorDialogOptions,
  ErrorDialogTypes,
} from 'modules/errorDialog';
import { applyClientErrorMessage } from 'utils/error';
import useLogout from './useLogout';

const useErrorDialog = () => {
  const dispatch = useDispatch();
  const { handleLogoutWithRedirectToLogin } = useLogout();
  const [_401ErrorHandled, set401ErrorHandled] = useState(false);

  useEffect(() => {
    if (_401ErrorHandled) handleLogoutWithRedirectToLogin();
  }, [_401ErrorHandled, handleLogoutWithRedirectToLogin]);

  const openErrorDialog = useCallback(
    (options: errorDialogOptions) => {
      dispatch(errorDialogActions.open(options));
    },
    [dispatch],
  );

  const handleRequestError = useCallback(
    (
      error: RequestError,
      clientErrorMessage?: string,
      dialogType: ErrorDialogTypes = ErrorDialogTypes.CLOSE,
    ) => {
      if (error.isCanceled()) return;

      if (error.status === 401) {
        set401ErrorHandled(true);
        return;
      }

      const options = {
        ...applyClientErrorMessage(
          { ...error.toObject() },
          clientErrorMessage ?? error.message,
        ),
        showCloseIcon: true,
        type: dialogType,
      };
      openErrorDialog(options);
    },
    [openErrorDialog],
  );

  return {
    openErrorDialog,
    handleRequestError,
  };
};

export default useErrorDialog;
