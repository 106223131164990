import { Mission } from 'proto/v1/apimodel/apimodel';
import { MissionFormValues } from 'types/mission';
import { isCompanyCorporateAttribute } from 'utils/models/mission';
import {
  convertJapanPrefecture,
  convertCompanyEstablishDate,
  convertMissionProducts,
  convertMissionCategories,
  convertMissionDemand,
  convertMissionJobOffers,
} from '../convertToFormValue';

export const convertMissionCompanyEstablishDate = (
  companyEstablishedDate: Mission['companyEstablishedDate'],
  corporateAttributes: Mission['corporateAttributes'],
) => {
  if (!isCompanyCorporateAttribute(corporateAttributes)) {
    return undefined;
  }
  return convertCompanyEstablishDate(companyEstablishedDate);
};

const convertMissionToMissionFormValues: (
  mission: Mission,
) => MissionFormValues = mission => {
  return {
    missionId: mission.missionId,
    name: mission.name,
    missionDescription: mission.missionDescription,
    iconImage: mission.iconImage,
    coverImage: mission.coverImage,
    strengthPoint: mission.strengthPoint,
    companyName: mission.companyName,
    companyUrl: mission.companyUrl,
    companyEstablishedDate: convertMissionCompanyEstablishDate(
      mission.companyEstablishedDate,
      mission.corporateAttributes,
    ),
    countryId: mission.country?.countryId ?? '',
    japanPrefectureId: convertJapanPrefecture(mission.japanPrefecture),
    otherAddress: mission.otherAddress,
    memberRangeId: mission.memberRange?.memberRangeId ?? 0,
    fundId: mission.fund?.fundId ?? 0,
    missionMembers: mission.missionMembers,
    missionCategories: convertMissionCategories(mission.missionCategories),
    missionDemand: convertMissionDemand(mission.missionDemand),
    missionProducts: convertMissionProducts(mission.missionProducts),
    missionOfficeImages: mission.missionOfficeImages,
    missionJobOffers: convertMissionJobOffers(mission.missionJobOffers),
  };
};

export default convertMissionToMissionFormValues;
