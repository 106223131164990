import React, { FC, ComponentProps } from 'react';
import styled from 'styled-components/macro';
import theme from 'styles/theme';
import { ButtonS } from 'components/atoms';

const Button = styled(ButtonS)`
  display: flex;
  align-items: center;
  min-width: 148px;
  border: 1px solid ${theme.basePrimary};
  border-radius: 8px;
  background-color: transparent;
  color: ${theme.basePrimary};
  line-height: 1;

  &:hover {
    opacity: 0.8;
  }
`;

const Icon = styled.span`
  position: relative;
  bottom: 1px;
  left: 0;
  margin-right: 4px;
`;

const Text = styled.span`
  display: inline-flex;
  flex: 1;
  justify-content: center;
`;

type Props = ComponentProps<typeof ButtonS> & { icon: React.ReactNode };

const OutlinedIconButton: FC<Props> = ({ icon, children, ...rest }) => {
  return (
    <Button type="button" {...rest}>
      <Icon>{icon}</Icon>
      <Text>{children}</Text>
    </Button>
  );
};

export default OutlinedIconButton;
