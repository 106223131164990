import React, { useMemo } from 'react';
import styled from 'styled-components/macro';
import { useTranslation } from 'react-i18next';
import { ButtonXL, Wall } from 'components/atoms';
import MissionFormHeader from '../../MissionFormHeader';
import MissionFormFooter from '../../MissionFormFooter';
import MissionPreview from '../../MissionPreview';
import { MissionDemandField } from '../../../Fields';
import {
  SaveConfirmationModal,
  NoInputMissionConfirmationModal,
  InvalidToSaveModal,
  InvalidToPublishModal,
  SaveCompleteModal,
  PublishMissionConfirmationModal,
  PublishCompleteModal,
  UnpublishConfirmationModal,
  UnpublishCompleteModal,
  LeavePageConfirmationModal,
} from '../../../Modals';
import useStepMissionDemand from './useStepMissionDemand';

const Form = styled.form``;

const StepMissionDemandWall = styled(Wall)`
  padding: 70px 52px;
`;

const StepMissionDemand: React.VFC = () => {
  const { t } = useTranslation('missionForm');
  const {
    movePrevStep,
    handleClickPublish,
    isPublished,
    isFirstPublished,
    form,
    formik,
    handleChangeSelectedValueAsNumber,
    handleChangeChecked,
    modalState,
    closeModal,
    handleClickSave,
    validateToSaveErrors,
    validateToPublishStepsResult,
    handleClickSaveConfirmationOK,
    handleClickPublishConfirmationOk,
    handleClickUnPublishConfirmationOk,
    handleAddCollaborationAsset,
    handleDeleteCollaborationAsset,
    handleAddServiceInformationIssue,
    handleDeleteServiceInformationIssue,
    missionDemandError,
    missionDemandFieldRef,
    openPreview,
    canUseMissionFormStepMissionJobOffers,
    canUseMissionFormStepMissionDemand,
  } = useStepMissionDemand();

  const { values, handleChange, handleBlur, dirty } = formik;

  return useMemo(
    () => (
      <>
        <MissionFormHeader
          isDisabledSaveButton={!dirty}
          isPublished={isPublished}
          onClickPreview={openPreview}
          onClickSave={handleClickSave}
          type="optional"
          title={t('header.missionDemand.title')}
          description={t('header.missionDemand.description')}
        />

        <Form data-testid="mission-form-step-mission-demand">
          <StepMissionDemandWall color="baseWhite">
            <MissionDemandField
              values={values.missionDemand}
              financingSeriesForm={form.financingSeries}
              fundForm={form.funds}
              errors={missionDemandError}
              ref={missionDemandFieldRef}
              onChangeChecked={handleChangeChecked}
              onAddCollaborationAsset={handleAddCollaborationAsset}
              onDeleteCollaborationAsset={handleDeleteCollaborationAsset}
              onChangeCollaborationAssetGive={handleChange}
              onChangeCollaborationAssetWant={handleChange}
              onChangeWantInvest={handleChangeChecked}
              onChangeWantFundraising={handleChangeChecked}
              onChangeFinancingSeries={handleChangeSelectedValueAsNumber}
              onAddServiceInformationIssue={handleAddServiceInformationIssue}
              onDeleteServiceInformationIssue={
                handleDeleteServiceInformationIssue
              }
              onChangeServiceInformationIssue={handleChange}
              onChangeWantTransferredOfBusiness={handleChangeChecked}
              onChangeTransferredOfBusinessFund={
                handleChangeSelectedValueAsNumber
              }
              onChangeAcceptSecondmentWork={handleChange}
              onChangeSendEmployeeWork={handleChange}
              onBlur={handleBlur}
            />
          </StepMissionDemandWall>
        </Form>

        <MissionFormFooter
          buttons={
            isFirstPublished
              ? [
                  <ButtonXL
                    type="button"
                    buttonTheme="gradation"
                    onClick={handleClickPublish}
                    key="publish"
                    disabled={isPublished ? !dirty : false}
                  >
                    {t('button.publish')}
                  </ButtonXL>,
                ]
              : [
                  <ButtonXL
                    type="button"
                    buttonTheme="cancel"
                    onClick={movePrevStep}
                    key="go-back"
                  >
                    {t('button.back')}
                  </ButtonXL>,
                  <ButtonXL
                    type="button"
                    buttonTheme="gradation"
                    onClick={handleClickPublish}
                    key="publish"
                    disabled={isPublished ? !dirty : false}
                  >
                    {t('button.publish')}
                  </ButtonXL>,
                ]
          }
        />

        <MissionPreview values={values} step="missionDemand" />

        <SaveConfirmationModal
          show={
            modalState.show && modalState.content === 'saveMissionConfirmation'
          }
          onOk={handleClickSaveConfirmationOK}
          onCancel={closeModal}
        />
        <NoInputMissionConfirmationModal
          show={
            modalState.show &&
            modalState.content === 'saveRequiredNoInputConfirmation'
          }
          onCancel={closeModal}
          onOk={handleClickSaveConfirmationOK}
          isPublished={isPublished}
          stepsResult={validateToPublishStepsResult}
          canUseStepJobOffers={canUseMissionFormStepMissionJobOffers}
          canUseStepMissionDemand={canUseMissionFormStepMissionDemand}
        />
        <InvalidToSaveModal
          show={modalState.show && modalState.content === 'invalidToSave'}
          onClose={closeModal}
          errors={validateToSaveErrors}
        />
        <SaveCompleteModal
          show={
            modalState.show && modalState.content === 'saveMissionCompleted'
          }
          onClose={closeModal}
        />
        <InvalidToPublishModal
          show={modalState.show && modalState.content === 'invalidToPublish'}
          onClose={closeModal}
          stepsResult={validateToPublishStepsResult}
          canUseStepJobOffers={canUseMissionFormStepMissionJobOffers}
          canUseStepMissionDemand={canUseMissionFormStepMissionDemand}
        />
        <PublishMissionConfirmationModal
          show={
            modalState.show &&
            modalState.content === 'publishMissionConfirmation'
          }
          onOk={handleClickPublishConfirmationOk}
          onCancel={closeModal}
        />
        <PublishCompleteModal
          show={
            modalState.show && modalState.content === 'publishMissionCompleted'
          }
          onClose={closeModal}
        />
        <UnpublishConfirmationModal
          show={
            modalState.show &&
            modalState.content === 'unPublishMissionConfirmation'
          }
          onOk={handleClickUnPublishConfirmationOk}
          onCancel={closeModal}
        />
        <UnpublishCompleteModal
          show={
            modalState.show &&
            modalState.content === 'unPublishMissionCompleted'
          }
          onClose={closeModal}
        />
        <LeavePageConfirmationModal when={dirty} />
      </>
    ),
    [
      canUseMissionFormStepMissionDemand,
      canUseMissionFormStepMissionJobOffers,
      closeModal,
      dirty,
      form.financingSeries,
      form.funds,
      handleAddCollaborationAsset,
      handleAddServiceInformationIssue,
      handleBlur,
      handleChange,
      handleChangeChecked,
      handleChangeSelectedValueAsNumber,
      handleClickPublish,
      handleClickPublishConfirmationOk,
      handleClickSave,
      handleClickSaveConfirmationOK,
      handleClickUnPublishConfirmationOk,
      handleDeleteCollaborationAsset,
      handleDeleteServiceInformationIssue,
      isFirstPublished,
      isPublished,
      missionDemandError,
      missionDemandFieldRef,
      modalState.content,
      modalState.show,
      movePrevStep,
      openPreview,
      t,
      validateToPublishStepsResult,
      validateToSaveErrors,
      values,
    ],
  );
};

export default StepMissionDemand;
