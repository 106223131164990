import { onAuthStateChanged } from 'firebase/auth';
import axios, { CancelTokenSource } from 'axios';
import { useEffect, useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { configureScope } from '@sentry/react';
import { UiActions } from 'modules/ui';
import { AccountActions, getAccountId } from 'modules/account';
import { GetActiveAccountResponse } from 'proto/v1/accountservice/accountservice';
import apiFirebase from 'external/firebase/firebase';
import RequestError from 'classes/RequestError';
import apiAccount from 'external/api/account';
import apiMission from 'external/api/mission';
import useErrorDialog from 'hooks/useErrorDialog';
import { GetMissionUsePlansResponse } from 'proto/v1/missionservice/missionservice';

const useActiveAccount = () => {
  const dispatch = useDispatch();
  const [isFetchedActiveAccount, setIsFetchedActiveAccount] = useState<boolean>(
    false,
  );
  const activeAccountId = useSelector(getAccountId);
  const { handleRequestError } = useErrorDialog();
  const { t } = useTranslation(['error']);

  const fetchActiveAccount = useCallback(
    async (source: CancelTokenSource) => {
      dispatch(UiActions.setLoading(true));
      let activeAccountData: GetActiveAccountResponse;
      try {
        ({ data: activeAccountData } = await apiAccount.getActiveAccount(
          source.token,
        ));
      } catch (anyError) {
        dispatch(UiActions.setLoading(false));
        if (anyError instanceof RequestError) {
          handleRequestError(anyError, t('error:failedGettingAny'));
        }
        setIsFetchedActiveAccount(true);
        return;
      }
      configureScope(scope => {
        scope.setExtra('account', activeAccountData);
      });
      dispatch(
        AccountActions.setAccount({
          mission: activeAccountData.mission,
          supporter: activeAccountData.supporter,
          published: activeAccountData.published,
        }),
      );

      if (activeAccountData.mission) {
        let missionUsePlansData: GetMissionUsePlansResponse;
        try {
          ({ data: missionUsePlansData } = await apiMission.getMissionUsePlans(
            source.token,
          ));
        } catch (anyError) {
          dispatch(UiActions.setLoading(false));
          if (anyError instanceof RequestError) {
            handleRequestError(anyError, t('error:failedGettingAny'));
          }
          return;
        }
        dispatch(
          AccountActions.setMissionUsePlans(
            missionUsePlansData.missionUsePlans,
          ),
        );
      } else {
        dispatch(AccountActions.setMissionUsePlans([]));
      }
      setIsFetchedActiveAccount(true);
      dispatch(UiActions.setLoading(false));
    },
    [dispatch, handleRequestError, t],
  );

  // When the active account id is changed, fetch the active account.
  useEffect(() => {
    let source: CancelTokenSource | undefined;

    const unsubscribeAuthStateChanged = onAuthStateChanged(
      apiFirebase.auth(),
      user => {
        if (user) {
          // Set user for sentry
          configureScope(scope => {
            scope.setUser({ id: user.uid });
          });
          source?.cancel();
          source = axios.CancelToken.source();
          fetchActiveAccount(source);
        } else {
          source?.cancel();
        }
      },
    );

    return () => {
      unsubscribeAuthStateChanged();
      source?.cancel();
    };
  }, [activeAccountId, dispatch, fetchActiveAccount]);

  return {
    isFetchedActiveAccount,
  };
};

export default useActiveAccount;
