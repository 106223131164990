import React, { FC, useMemo } from 'react';
import { Supporter } from 'proto/v1/apimodel/apimodel';
import {
  DetailCompanyField,
  DetailSubTitle,
  SupportAreaTag,
} from 'components/molecules';
import { useTranslation } from 'react-i18next';
import { sortedSupportAreas } from 'utils/models/supporter';

type SupportAreasFieldProps = {
  supportAreas: Supporter['supportAreas'];
};

const SupportAreasField: FC<SupportAreasFieldProps> = ({ supportAreas }) => {
  const { t } = useTranslation('supporterDetail');

  const sorted = useMemo(() => sortedSupportAreas(supportAreas), [
    supportAreas,
  ]);

  return (
    <DetailCompanyField>
      <DetailSubTitle>{t('field.supportAreas.label')}</DetailSubTitle>
      {sorted.map(supportArea => (
        <SupportAreaTag
          key={supportArea.supportAreaId}
          data-testid="support-area-tag"
        >
          {supportArea.name}
        </SupportAreaTag>
      ))}
    </DetailCompanyField>
  );
};

export default SupportAreasField;
