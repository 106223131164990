import React, { FC, HTMLAttributes } from 'react';
import styled from 'styled-components/macro';
import theme from 'styles/theme';
import typography from 'styles/typography';
import { WrapText } from 'components/atoms';

type FontWeight = 'bold' | 'normal';

const Wrapper = styled.div<{ fontWeight: FontWeight }>`
  ${typography.textDefault};
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  color: ${theme.textPrimary};
  font-weight: ${({ fontWeight }) => fontWeight};
`;

type Props = { fontWeight?: FontWeight } & HTMLAttributes<HTMLDivElement>;

const HumanResourceCardBodyItemContent: FC<Props> = ({
  children,
  fontWeight = 'bold',
  ...rest
}) => {
  return (
    <Wrapper {...rest} fontWeight={fontWeight}>
      <WrapText style={{ maxWidth: '664px' }}>{children}</WrapText>
    </Wrapper>
  );
};

export default HumanResourceCardBodyItemContent;
