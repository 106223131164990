import { useCallback } from 'react';
import ImageTypes from 'constants/imageTypes';
import { MissionOfficeImage } from 'proto/v1/apimodel/apimodel';
import { MissionOfficeImageFormValue } from 'types/form';
import { MissionSettingFormState } from './useMissionSettingFormState';
import { MissionSettingFormik } from './useMissionSettingFormik';

type Payload = {
  missionOfficeImages: MissionOfficeImageFormValue[];
  getAddedItemId: MissionSettingFormState['getAddedItemId'];
  openImageCropper: MissionSettingFormState['openImageCropper'];
  setFieldValue: MissionSettingFormik['setFieldValue'];
};

const useMissionOfficeImagesField = ({
  missionOfficeImages,
  getAddedItemId,
  openImageCropper,
  setFieldValue,
}: Payload) => {
  const handleAddOfficeImage = useCallback(
    () =>
      setFieldValue(
        'missionOfficeImages',
        missionOfficeImages && [
          ...missionOfficeImages,
          {
            missionOfficeImageId: getAddedItemId('missionOfficeImages'),
          },
        ],
      ),
    [getAddedItemId, missionOfficeImages, setFieldValue],
  );

  const handleChangeOfficeImage = useCallback(
    (uploadedImage: string, index: number) => {
      openImageCropper(ImageTypes.OFFICE, uploadedImage, index);
    },
    [openImageCropper],
  );

  const handleDeleteOfficeImage = useCallback(
    (targetOfficeImageId: MissionOfficeImage['missionOfficeImageId']) => {
      setFieldValue(
        'missionOfficeImages',
        missionOfficeImages &&
          missionOfficeImages.filter(
            (officeImage: MissionOfficeImage) =>
              officeImage.missionOfficeImageId !== targetOfficeImageId,
          ),
      );
    },
    [missionOfficeImages, setFieldValue],
  );

  return {
    handleAddOfficeImage,
    handleChangeOfficeImage,
    handleDeleteOfficeImage,
  };
};

export default useMissionOfficeImagesField;
