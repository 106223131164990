import { useState, useCallback } from 'react';
import { FirebaseError } from 'firebase/app';
import apiFirebase from 'external/firebase/firebase';
import { CancelTokenSource } from 'axios';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import apiMission from 'external/api/mission';
import apiAccount from 'external/api/account';
import RequestError from 'classes/RequestError';
import { ErrorDialogTypes } from 'modules/errorDialog';
import { AccountActions, getIsLoggedIn, getAccountId } from 'modules/account';
import {
  ChangeActiveAccountResponse,
  GetActiveAccountResponse,
} from 'proto/v1/accountservice/accountservice';
import { DeleteMyMissionResponse } from 'proto/v1/missionservice/missionservice';
import { UiActions } from 'modules/ui';
import { ConfigActions, getAfterLoginUrl } from 'modules/config';
import { MyAccountsActions, getLoginAccountId } from 'modules/myAccounts';
import { getFirebaseErrorMessage } from 'utils/error';
import { removeQueryStringProps } from 'utils/string';
import useLogout from './useLogout';
import useErrorDialog from './useErrorDialog';

export default function useMyAccounts() {
  const { t } = useTranslation(['error']);
  const [error, setError] = useState<null | string>(null);
  const [isFetchingMyAccounts, setIsFetchingMyAccounts] = useState<boolean>(
    false,
  );
  const { logout, handleLogoutWithRedirectToLogin } = useLogout();
  const isLoggedIn = useSelector(getIsLoggedIn);
  const accountId = useSelector(getAccountId);
  const loginAccountId = useSelector(getLoginAccountId);
  const afterLoginUrl = useSelector(getAfterLoginUrl);
  const dispatch = useDispatch();
  const location = useLocation();
  const { handleRequestError } = useErrorDialog();

  const fetchMyAccounts = useCallback(
    async (source?: CancelTokenSource) => {
      if (!isLoggedIn || !accountId) {
        dispatch(MyAccountsActions.setMyAccounts([]));
        return;
      }
      setIsFetchingMyAccounts(true);
      let data;
      try {
        ({ data } = await apiAccount.getMyAccounts(source?.token));
      } catch (requestError) {
        if (requestError instanceof RequestError) {
          handleRequestError(
            requestError,
            t('error:failedGettingAny'),
            ErrorDialogTypes.RELOAD,
          );
        }
        setIsFetchingMyAccounts(false);
        return;
      }
      dispatch(MyAccountsActions.setMyAccounts(data.accounts));
      setIsFetchingMyAccounts(false);
    },
    [accountId, dispatch, handleRequestError, isLoggedIn, t],
  );

  const switchFirebaseAccount = useCallback(
    async (
      token: string,
      payload: {
        accountId?: string;
        missionId?: string;
        supporterId?: string;
      } = {},
    ) => {
      // ログアウトしてからログインすることで、各ページで使用しているhooksを再実行させる
      return new Promise((resolve, reject) => {
        dispatch(MyAccountsActions.setIsSwitching(true));
        logout(async () => {
          let data: GetActiveAccountResponse | undefined;
          try {
            await apiFirebase.login(token);
            if (
              !payload.accountId &&
              !(payload.missionId || payload.supporterId)
            ) {
              ({ data } = await apiAccount.getActiveAccount());
            }
          } catch (anyError) {
            if (typeof (anyError as FirebaseError).code === 'string') {
              setError(
                getFirebaseErrorMessage((anyError as FirebaseError).code),
              );
            } else {
              setError(t('error:failedUnexpected'));
            }
            dispatch(MyAccountsActions.setIsSwitching(false));
            reject();
            return;
          }
          dispatch(AccountActions.setIsLoggedIn(true));
          const user = apiFirebase.authUser();
          dispatch(
            AccountActions.setIds({
              userId: user?.uid,
              accountId: payload.accountId ?? data?.accountId,
              missionId: payload.missionId ?? data?.mission?.missionId,
              supporterId: payload.supporterId ?? data?.supporter?.supporterId,
            }),
          );
          dispatch(MyAccountsActions.setIsSwitching(false));
          resolve(null);
        });
      });
    },
    [dispatch, logout, t],
  );

  const changeActiveAccount = useCallback(
    async (targetAccountId: string) => {
      if (targetAccountId === accountId) return;
      setError(null);
      dispatch(UiActions.setLoading(true));
      let data: ChangeActiveAccountResponse;
      try {
        ({ data } = await apiAccount.changeActiveAccount({
          accountId: targetAccountId,
        }));
      } catch (requestError) {
        dispatch(UiActions.setLoading(false));
        if (requestError instanceof RequestError) {
          handleRequestError(
            requestError,
            t('error:failedToChangeActiveMission'),
          );
        }
        return;
      }

      await switchFirebaseAccount(data.token);
      dispatch(UiActions.setLoading(false));
    },
    [accountId, dispatch, handleRequestError, switchFirebaseAccount, t],
  );

  const selectMyAccount = useCallback(
    async (selectedAccountId: string) => {
      if (!selectedAccountId) return;
      changeActiveAccount(selectedAccountId);
    },
    [changeActiveAccount],
  );

  const addMission = useCallback(async () => {
    let data;
    try {
      ({ data } = await apiMission.createAnotherMission());
    } catch (requestError) {
      if (requestError instanceof RequestError) {
        handleRequestError(requestError, t('error:failedToCreateMission'));
      }
      return;
    }
    await fetchMyAccounts();
    await changeActiveAccount(data.accountId);
  }, [fetchMyAccounts, changeActiveAccount, handleRequestError, t]);

  const deleteMyMission = useCallback(
    async (missionId: string) => {
      let data;
      dispatch(UiActions.setLoading(true));
      try {
        ({ data } = await apiMission.deleteMyMission({ missionId }));
      } catch (requestError) {
        dispatch(UiActions.setLoading(false));
        if (requestError instanceof RequestError) {
          handleRequestError(requestError, t('error:failedToDeleteMission'));
        }
        return;
      }
      dispatch(UiActions.setLoading(false));
      return data;
    },
    [dispatch, handleRequestError, t],
  );

  const fetchMyAccountsBySelectedAccountId = useCallback(
    async (selectedAccountId: string, source?: CancelTokenSource) => {
      setIsFetchingMyAccounts(true);
      let data;
      try {
        ({ data } = await apiAccount.getMyAccounts(source?.token));
      } catch (requestError) {
        if (requestError instanceof RequestError) {
          handleRequestError(
            requestError,
            t('error:failedGettingAny'),
            ErrorDialogTypes.RELOAD,
          );
        }
        setIsFetchingMyAccounts(false);
        return;
      }
      const isMyAccount = data.accounts.some(
        myAccount => myAccount.accountId === selectedAccountId,
      );
      // Logout when selectedAccountId is not myAccount
      if (!isMyAccount) {
        if (loginAccountId) dispatch(MyAccountsActions.clearLoginAccountId());
        const url = afterLoginUrl ?? `${location.pathname}${location.search}`;
        const [path, queryString] = url.split('?');
        dispatch(
          ConfigActions.setAfterLoginUrl(
            `${path}?${removeQueryStringProps(queryString, 'accountId')}`,
          ),
        );
        setIsFetchingMyAccounts(false);
        handleLogoutWithRedirectToLogin();
        return;
      }

      await changeActiveAccount(selectedAccountId);
      if (loginAccountId) dispatch(MyAccountsActions.clearLoginAccountId());
      dispatch(MyAccountsActions.setMyAccounts(data.accounts));
      setIsFetchingMyAccounts(false);
    },
    [
      changeActiveAccount,
      loginAccountId,
      dispatch,
      handleRequestError,
      t,
      afterLoginUrl,
      location.pathname,
      location.search,
      handleLogoutWithRedirectToLogin,
    ],
  );

  const applyLatestAccountInfo = useCallback(
    async (deleteMyMissionResponse: DeleteMyMissionResponse) => {
      const {
        token,
        activeAccountId,
        activeMissionId,
      } = deleteMyMissionResponse;
      if (token) {
        await switchFirebaseAccount(token, {
          accountId: activeAccountId,
          missionId: activeMissionId,
        });
      }

      let data;
      try {
        ({ data } = await apiAccount.getMyAccounts());
      } catch (requestError) {
        if (requestError instanceof RequestError) {
          handleRequestError(
            requestError,
            t('error:failedGettingAny'),
            ErrorDialogTypes.RELOAD,
          );
        }
        return;
      }
      dispatch(MyAccountsActions.setMyAccounts(data.accounts));
    },
    [dispatch, handleRequestError, switchFirebaseAccount, t],
  );

  return {
    fetchMyAccounts,
    fetchMyAccountsBySelectedAccountId,
    selectMyAccount,
    addMission,
    changeActiveAccount,
    deleteMission: deleteMyMission,
    applyLatestAccountInfo,
    isFetchingMyAccounts,
    error,
  };
}

export type MyAccountsMethods = ReturnType<typeof useMyAccounts>;
