import React from 'react';
import styled from 'styled-components';
import theme from 'styles/theme';
import { useTranslation } from 'react-i18next';
import { InputActionMeta } from 'react-select';
import {
  CareerMattersFilterValues,
  CareerHumanResourceFilterForm,
  HumanResourceFilterFormEmploymentTypes,
  HumanResourceFilterFormOccupations,
  HumanResourceFilterWorkLocations,
  HumanResourceFilterYearsOfGraduation,
  HumanResourceFilterExcludeLatestMessageWithinDays,
} from 'types/matter';
import { ReactSelectOption } from 'types/form';
import { EXCLUDE_LATEST_MESSAGES_WITHIN_DAYS_NO_SELECT_VALUE } from 'constants/models/matter';
import { NO_SELECT_NUMBER_VALUE } from 'constants/form';
import { Checkbox } from 'components/atoms';
import {
  MultiSelectbox,
  InputMultiSelect,
  DecoratedSelectbox,
} from 'components/molecules';

const Wrapper = styled.div`
  padding-left: 24px;
`;

const Filters = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  margin-left: -24px;
  padding: 20px 32px;
  border-bottom: 1px solid ${theme.borderDefault};
  background-color: ${theme.bgAdd};
`;

const FilterItemsTop = styled.div`
  display: flex;
  gap: 24px;
`;

const FilterItemsBottom = styled.div`
  display: flex;
  gap: 20px;
`;

const FilterItem = styled.div``;

const YearOfGraduationRangeFilterItem = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

const FavoriteFilterItem = styled.div`
  display: flex;
  align-items: center;
`;

const FavoriteCheckBox = styled(Checkbox)`
  &&& {
    min-width: auto;
    margin-right: 0;
    margin-bottom: 0;

    div {
      margin-top: 0;
      margin-bottom: 0;
    }
  }
`;

type OnSelect<T> = (option: T) => void;
type OnDeselect<T> = (option: T) => void;

type Props = {
  values: CareerMattersFilterValues;
  form: CareerHumanResourceFilterForm;
  filterSkills: ReactSelectOption[];
  skillOptions: ReactSelectOption[];
  onChangeSkills: (value: ReactSelectOption[]) => void;
  fetchMoreSkillOptions: () => Promise<void>;
  onInputChangeSkill: (inputValue: string, actionMeta: InputActionMeta) => void;
  isLoadingSkillOptions: boolean;
  onSelectOccupation: OnSelect<HumanResourceFilterFormOccupations>;
  onDeselectOccupation: OnDeselect<HumanResourceFilterFormOccupations>;
  onClearOccupation: () => void;
  onSelectWorkLocation: OnSelect<HumanResourceFilterWorkLocations>;
  onDeselectWorkLocation: OnDeselect<HumanResourceFilterWorkLocations>;
  onClearWorkLocation: () => void;
  onSelectEmploymentType: OnSelect<HumanResourceFilterFormEmploymentTypes>;
  onDeselectEmploymentType: OnDeselect<HumanResourceFilterFormEmploymentTypes>;
  onClearEmploymentType: () => void;
  filterSchools: ReactSelectOption[];
  schoolOptions: ReactSelectOption[];
  onChangeSchools: (value: ReactSelectOption[]) => void;
  fetchMoreSchoolOptions: () => Promise<void>;
  onInputChangeSchool: (
    inputValue: string,
    actionMeta: InputActionMeta,
  ) => void;
  isLoadingSchoolOptions: boolean;
  onChangeYearOfGraduationFrom: (
    option: HumanResourceFilterYearsOfGraduation,
  ) => void;
  onChangeYearOfGraduationTo: (
    option: HumanResourceFilterYearsOfGraduation,
  ) => void;
  onChangeExcludeLatestMessageWithinDays: (
    option: HumanResourceFilterExcludeLatestMessageWithinDays,
  ) => void;
  onChangeFavorite: (e: React.ChangeEvent<HTMLInputElement>) => void;
};

const CareerHumanResourcesFilter: React.VFC<Props> = ({
  values,
  form,
  filterSkills,
  onSelectOccupation,
  onDeselectOccupation,
  onClearOccupation,
  onSelectWorkLocation,
  onDeselectWorkLocation,
  onClearWorkLocation,
  onSelectEmploymentType,
  onDeselectEmploymentType,
  onClearEmploymentType,
  onChangeSkills,
  skillOptions,
  onInputChangeSkill,
  fetchMoreSkillOptions,
  isLoadingSkillOptions,
  filterSchools,
  schoolOptions,
  onChangeSchools,
  onInputChangeSchool,
  isLoadingSchoolOptions,
  fetchMoreSchoolOptions,
  onChangeYearOfGraduationFrom,
  onChangeYearOfGraduationTo,
  onChangeExcludeLatestMessageWithinDays,
  onChangeFavorite,
}) => {
  const { t } = useTranslation(['humanResource']);

  return (
    <Wrapper>
      <Filters>
        <FilterItemsTop>
          <FilterItem>
            <MultiSelectbox<HumanResourceFilterFormOccupations>
              values={values.occupationIds}
              placeholder={t('filter.field.occupation.career.placeholder', {
                lng: 'ja',
              })}
              options={form.occupations}
              width={232}
              onSelect={onSelectOccupation}
              onDeselect={onDeselectOccupation}
              onClear={onClearOccupation}
              idName="id"
              textName="name"
              testId="multi-select-box-occupation"
              noSelectOptionLabel={t('filter.selectBox.all', { lng: 'ja' })}
            />
          </FilterItem>
          <FilterItem>
            <InputMultiSelect
              value={filterSkills}
              options={skillOptions}
              onInputChange={onInputChangeSkill}
              onChange={value =>
                onChangeSkills((value as unknown) as ReactSelectOption[])
              }
              isLoading={isLoadingSkillOptions}
              onMenuScrollToBottom={fetchMoreSkillOptions}
              placeholder={t('filter.field.skill.placeholder', { lng: 'ja' })}
              aria-label={t('filter.field.skill.label', { lng: 'ja' })}
              width="258px"
            />
          </FilterItem>
          <FilterItem>
            <MultiSelectbox<HumanResourceFilterWorkLocations>
              values={values.workLocationIds}
              placeholder={t('filter.field.workLocation.career.placeholder', {
                lng: 'ja',
              })}
              options={form.workLocations}
              width={144}
              onSelect={onSelectWorkLocation}
              onDeselect={onDeselectWorkLocation}
              onClear={onClearWorkLocation}
              idName="id"
              textName="name"
              testId="multi-select-box-work-location"
              noSelectOptionLabel={t('filter.selectBox.all', { lng: 'ja' })}
            />
          </FilterItem>
          <FilterItem>
            <MultiSelectbox<HumanResourceFilterFormEmploymentTypes>
              values={values.employmentTypeIds}
              placeholder={t('filter.field.employmentType.placeholder', {
                lng: 'ja',
              })}
              options={form.employmentTypes}
              width={170}
              onSelect={onSelectEmploymentType}
              onDeselect={onDeselectEmploymentType}
              onClear={onClearEmploymentType}
              idName="id"
              textName="name"
              testId="multi-select-box-employment-type"
              noSelectOptionLabel={t('filter.selectBox.all', { lng: 'ja' })}
            />
          </FilterItem>
        </FilterItemsTop>

        <FilterItemsBottom>
          <FilterItem>
            <InputMultiSelect
              value={filterSchools}
              options={schoolOptions}
              onInputChange={onInputChangeSchool}
              onChange={value =>
                onChangeSchools((value as unknown) as ReactSelectOption[])
              }
              isLoading={isLoadingSchoolOptions}
              onMenuScrollToBottom={fetchMoreSchoolOptions}
              placeholder={t('filter.field.school.career.placeholder', {
                lng: 'ja',
              })}
              aria-label={t('filter.field.school.career.label', { lng: 'ja' })}
              width="248px"
            />
          </FilterItem>
          <YearOfGraduationRangeFilterItem>
            <DecoratedSelectbox<HumanResourceFilterYearsOfGraduation>
              value={values.yearOfGraduationFrom ?? NO_SELECT_NUMBER_VALUE}
              placeholder={t('filter.field.yearOfGraduationFrom.placeholder', {
                lng: 'ja',
              })}
              options={form.yearsOfGraduation}
              width={108}
              onChange={onChangeYearOfGraduationFrom}
              idName="id"
              textName="name"
              testId="select-box-year-of-graduation-from"
              noSelectOption={{
                id: NO_SELECT_NUMBER_VALUE,
                name: t('filter.field.yearOfGraduationFrom.placeholder', {
                  lng: 'ja',
                }),
              }}
            />
            <span>~</span>
            <DecoratedSelectbox<HumanResourceFilterYearsOfGraduation>
              value={values.yearOfGraduationTo ?? NO_SELECT_NUMBER_VALUE}
              placeholder={t('filter.field.yearOfGraduationTo.placeholder', {
                lng: 'ja',
              })}
              options={form.yearsOfGraduation}
              width={108}
              onChange={onChangeYearOfGraduationTo}
              idName="id"
              textName="name"
              testId="select-box-year-of-graduation-to"
              noSelectOption={{
                id: NO_SELECT_NUMBER_VALUE,
                name: t('filter.field.yearOfGraduationTo.placeholder', {
                  lng: 'ja',
                }),
              }}
            />
          </YearOfGraduationRangeFilterItem>
          <FilterItem>
            <DecoratedSelectbox<
              HumanResourceFilterExcludeLatestMessageWithinDays
            >
              value={
                values.excludeLatestMessageWithinDays ??
                EXCLUDE_LATEST_MESSAGES_WITHIN_DAYS_NO_SELECT_VALUE
              }
              placeholder={t(
                'filter.field.excludeLatestMessageWithinDays.placeholder',
                {
                  lng: 'ja',
                },
              )}
              options={form.excludeLatestMessageWithinDays}
              width={230}
              onChange={onChangeExcludeLatestMessageWithinDays}
              idName="id"
              textName="name"
              testId="select-box-latest-message-days-before"
              noSelectOption={{
                id: EXCLUDE_LATEST_MESSAGES_WITHIN_DAYS_NO_SELECT_VALUE,
                name: t(
                  'filter.field.excludeLatestMessageWithinDays.placeholder',
                  {
                    lng: 'ja',
                  },
                ),
              }}
            />
          </FilterItem>
          <FavoriteFilterItem>
            <FavoriteCheckBox
              name="favorite"
              onChange={onChangeFavorite}
              checked={!!values.favorite}
              aria-label={t('filter.field.favorite.label', {
                lng: 'ja',
              })}
            >
              {t('filter.field.favorite.label', {
                lng: 'ja',
              })}
            </FavoriteCheckBox>
          </FavoriteFilterItem>
        </FilterItemsBottom>
      </Filters>
    </Wrapper>
  );
};

export default CareerHumanResourcesFilter;
