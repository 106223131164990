import React, { FC } from 'react';
import styled from 'styled-components/macro';
import { WrapText, LinkText } from 'components/atoms';
import { DetailTitle } from 'components/molecules';
import { Mission } from 'proto/v1/apimodel/apimodel';

const Wrapper = styled.div`
  margin-bottom: 56px;
`;

const TextWrapper = styled(WrapText)`
  min-height: 248px;
  overflow: auto;
  line-height: 26px;
`;

type MissionDetailStrengthPointProps = {
  label: string;
  strengthPoint: Mission['strengthPoint'];
};

const MissionDetailStrengthPoint: FC<MissionDetailStrengthPointProps> = props => {
  const { label, strengthPoint } = props;

  return (
    <Wrapper>
      <DetailTitle>{label}</DetailTitle>
      <TextWrapper>
        <LinkText color="secondary">{strengthPoint}</LinkText>
      </TextWrapper>
    </Wrapper>
  );
};

export default MissionDetailStrengthPoint;
