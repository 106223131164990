import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Mission } from 'proto/v1/apimodel/apimodel';
import { userLanguage } from 'i18n/i18n';
import { DetailCompanyField, DetailSubTitle } from 'components/molecules';

const formatCompanyEstablishedDate = (
  companyEstablishedDate: Mission['companyEstablishedDate'],
) => {
  if (!companyEstablishedDate) return '-';
  return userLanguage === 'ja'
    ? `${companyEstablishedDate.year}/${companyEstablishedDate.month}`
    : `${companyEstablishedDate.month}/${companyEstablishedDate.year}`;
};

type CompanyEstablishedDateFieldProps = {
  companyEstablishedDate: Mission['companyEstablishedDate'];
};

const CompanyEstablishedDateField: FC<CompanyEstablishedDateFieldProps> = ({
  companyEstablishedDate,
}) => {
  const { t } = useTranslation('missionDetail');

  return (
    <DetailCompanyField>
      <DetailSubTitle>{t('field.companyEstablishedDate.label')}</DetailSubTitle>
      <p>{formatCompanyEstablishedDate(companyEstablishedDate)}</p>
    </DetailCompanyField>
  );
};

export default CompanyEstablishedDateField;
