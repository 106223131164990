import axios, { CancelToken } from 'axios';
import {
  CreateChatRoomRequest,
  CreateChatRoomResponse,
  GetChatRoomUpdatesRequest,
  GetChatRoomUpdatesResponse,
  GetChatRoomListRequest,
  GetChatRoomListResponse,
  PostMessageRequest,
  PostMessageResponse,
  GetChatMessagesRequest,
  GetChatMessagesResponse,
  DeleteMessageRequest,
  DeleteMessageResponse,
  FlushUnreadCountRequest,
  FlushUnreadCountResponse,
  PostFileRequest,
  PostFileResponse,
} from 'proto/v1/chatservice/chatservice';
import client from './config/client';
import apiFirebase from '../firebase/firebase';

const chatFileClient = axios.create({ responseType: 'blob' });

export default {
  async getChatRooms(
    params: GetChatRoomListRequest,
    requestCancelToken: CancelToken,
  ): Promise<{ data: GetChatRoomListResponse }> {
    const token = await apiFirebase.currentToken();
    const url = `${process.env.REACT_APP_API_URL}/v1/chatrooms`;
    return client(token)
      .get(url, { params, cancelToken: requestCancelToken })
      .then(x => {
        return {
          data: GetChatRoomListResponse.fromJSON(x.data),
        };
      });
  },

  async getChatRoomsUpdates(
    params: GetChatRoomUpdatesRequest,
    requestCancelToken?: CancelToken,
  ): Promise<{ data: GetChatRoomUpdatesResponse }> {
    const token = await apiFirebase.currentToken();
    const url = `${process.env.REACT_APP_API_URL}/v1/chatrooms/updates`;
    return client(token)
      .get(url, { params, cancelToken: requestCancelToken })
      .then(x => {
        return {
          data: GetChatRoomListResponse.fromJSON(x.data),
        };
      });
  },

  async createChatRoom(
    data: CreateChatRoomRequest,
  ): Promise<{ data: CreateChatRoomResponse }> {
    const token = await apiFirebase.currentToken();
    const url = `${process.env.REACT_APP_API_URL}/v1/chatrooms`;
    return client(token)
      .post(url, CreateChatRoomRequest.toJSON(data))
      .then(x => {
        return {
          data: CreateChatRoomResponse.fromJSON(x.data),
        };
      });
  },

  async postMessage(
    data: PostMessageRequest,
  ): Promise<{ data: PostMessageResponse }> {
    const token = await apiFirebase.currentToken();
    const url = `${process.env.REACT_APP_API_URL}/v1/chatrooms/${data.chatRoomId}/messages`;
    return client(token)
      .post(url, PostMessageRequest.toJSON(data))
      .then(x => {
        return {
          data: PostMessageResponse.fromJSON(x.data),
        };
      });
  },

  async postFile(
    data: PostFileRequest,
    onUploadProgress: (e: ProgressEvent) => void,
    cancelToken: CancelToken,
  ): Promise<{ data: PostFileResponse }> {
    const token = await apiFirebase.currentToken();
    const url = `${process.env.REACT_APP_API_URL}/v1/chatrooms/${data.chatRoomId}/files`;
    return client(token)
      .post(url, PostFileRequest.toJSON(data), {
        onUploadProgress,
        cancelToken,
      })
      .then(x => {
        return {
          data: PostFileResponse.fromJSON(x.data),
        };
      });
  },

  async downloadFile(url: string): Promise<{ fileContent: Blob }> {
    const response = await chatFileClient.get(url);
    return { fileContent: response.data };
  },

  async getMessages(
    params: GetChatMessagesRequest,
    requestCancelToken?: CancelToken,
  ): Promise<{ data: GetChatMessagesResponse }> {
    const token = await apiFirebase.currentToken();
    const url = `${process.env.REACT_APP_API_URL}/v1/chatrooms/${params.chatRoomId}/messages`;
    return client(token)
      .get(url, { params, cancelToken: requestCancelToken })
      .then(x => {
        return {
          data: GetChatMessagesResponse.fromJSON(x.data),
        };
      });
  },

  async deleteMessage(
    data: DeleteMessageRequest,
  ): Promise<{ data: DeleteMessageResponse }> {
    const token = await apiFirebase.currentToken();
    const url = `${process.env.REACT_APP_API_URL}/v1/chatrooms/${data.chatRoomId}/messages/${data.messageId}`;
    return client(token)
      .delete(url)
      .then(x => {
        return {
          data: DeleteMessageResponse.fromJSON(x.data),
        };
      });
  },

  async putFlushUnreadCount(
    data: FlushUnreadCountRequest,
    requestCancelToken?: CancelToken,
  ): Promise<{ data: FlushUnreadCountResponse }> {
    const { chatRoomId } = data;
    const token = await apiFirebase.currentToken();
    const url = `${process.env.REACT_APP_API_URL}/v1/chatrooms/${chatRoomId}/flush`;
    return client(token)
      .put(url, FlushUnreadCountRequest.toJSON({ chatRoomId }), {
        cancelToken: requestCancelToken,
      })
      .then(x => {
        return {
          data: FlushUnreadCountResponse.fromJSON(x.data),
        };
      });
  },
};
