import React, { HTMLAttributes } from 'react';
import styled from 'styled-components/macro';
import theme from 'styles/theme';
import { formatToLatestMessageSentAtWithin } from 'utils/models/matter';

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-right: 6px;
  padding-top: 4px;
`;

const Badge = styled.div`
  width: 7px;
  height: 7px;
  margin-right: 4px;
  border-radius: 50%;
  background-color: ${theme.labelDefault};
`;

const Text = styled.span`
  color: ${theme.textPrimaryHover};
  font-size: 12px;
  line-height: 1;
`;

type Props = { latestMessageSentAt: number } & HTMLAttributes<HTMLDivElement>;

const HumanResourceCardHeadLatestMessageWithin: React.VFC<Props> = ({
  latestMessageSentAt,
  ...rest
}) => {
  return (
    <Wrapper {...rest}>
      <Badge />
      <Text>{formatToLatestMessageSentAtWithin(latestMessageSentAt)}</Text>
    </Wrapper>
  );
};

export default HumanResourceCardHeadLatestMessageWithin;
