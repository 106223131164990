import { ChangeEvent, useCallback, useEffect } from 'react';
import { useFormik } from 'formik';
import { MissionCategory } from 'proto/v1/apimodel/apimodel';
import { CategoryPickerValues, CategoryFilterValues } from 'types/mission';

const useCategoryPicker = ({
  form,
  onSubmit,
  defaultValues,
  isSelected,
}: {
  onSubmit: (values: CategoryPickerValues) => void;
  form: { categories: MissionCategory[] | undefined };
  defaultValues: CategoryFilterValues;
  isSelected: boolean;
}) => {
  const allCategoryIds = form.categories
    ? form.categories.map(v => v.categoryId)
    : [];

  const isCheckedAllDefaultCategoryIds =
    defaultValues.categoryIds.length === allCategoryIds.length;

  const formik = useFormik<CategoryPickerValues>({
    initialValues: {
      checkAllCategories: isCheckedAllDefaultCategoryIds,
      categoryIds: defaultValues.categoryIds,
    },
    onSubmit,
    enableReinitialize: true,
  });

  const { values, setFieldValue, resetForm } = formik;

  const handleChangeCategory = useCallback(
    (event: ChangeEvent<HTMLInputElement>): void => {
      const { target } = event;
      const targetValue = Number(target.value);
      const { categoryIds } = values;
      if (target.checked) {
        setFieldValue('categoryIds', [...categoryIds, targetValue]);
      } else {
        setFieldValue(
          'categoryIds',
          categoryIds.filter(categoryId => categoryId !== targetValue),
        );
      }
    },
    [setFieldValue, values],
  );

  const handleChangeCheckAllCategories = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const { checked } = event.currentTarget;
      setFieldValue('categoryIds', checked ? allCategoryIds : []);
      setFieldValue('checkAllCategories', checked);
    },
    [allCategoryIds, setFieldValue],
  );

  useEffect(() => {
    if (!isSelected) resetForm();
  }, [isSelected, resetForm]);

  return {
    formik,
    handleChangeCategory,
    handleChangeCheckAllCategories,
  };
};

export default useCategoryPicker;
