import React from 'react';
import { useTranslation } from 'react-i18next';
import { ButtonM } from 'components/atoms';
import { Modal, ModalContent, ModalFooter } from 'components/molecules';

type Props = {
  show: boolean;
  onOk: () => void;
  onCancel: () => void;
};

const SaveConfirmationModal: React.FC<Props> = ({ show, onOk, onCancel }) => {
  const { t } = useTranslation('missionSetting');

  return (
    <Modal show={show} closeModal={onCancel}>
      <ModalContent data-testid="modal-add-mission-confirmation">
        {t('modal.addMissionConfirmation.content')}
      </ModalContent>
      <ModalFooter>
        <ButtonM onClick={onOk} buttonTheme="default" type="button">
          {t('common:button.ok')}
        </ButtonM>
      </ModalFooter>
    </Modal>
  );
};

export default SaveConfirmationModal;
