import styled from 'styled-components/macro';
import theme from 'styles/theme';

const DetailContentsLeft = styled.div`
  width: 540px;
  min-width: 540px;
  max-width: 540px;
  border-right: 1px solid ${theme.borderDefault};
`;

export default DetailContentsLeft;
