import styled, { css } from 'styled-components/macro';

type ImageCenterProps = {
  imageSrc: string | undefined;
  width?: number;
  height?: number;
};

const ImageCenter = styled.div<ImageCenterProps>`
  background-color: transparent;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  ${({ imageSrc }) =>
    css`
      background-image: url(${imageSrc});
    `}
  ${({ width }) =>
    width &&
    css`
      width: ${width}px;
    `}
  ${({ height }) =>
    height &&
    css`
      height: ${height}px;
    `}
`;

export default ImageCenter;
