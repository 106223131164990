import React, { useMemo } from 'react';
import styled from 'styled-components/macro';
import theme from 'styles/theme';
import { ChevronLeftGray, ChevronRightGray } from 'assets/svg';
import MissionFormStepper from './MissionFormStepper';
import MissionFormMenu from './MissionFormMenu';
import {
  StepCompany,
  StepAboutMission,
  StepMissionProducts,
  StepMissionJobOffers,
  StepMissionDemand,
} from './Steps';
import useMissionFormContext, {
  MissionFormContextProvider,
} from './useMissionFormContext';

const Wrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
`;

const Body = styled.div`
  display: flex;
  justify-content: center;
  gap: 30px;
  width: 100%;
  height: 100%;
  padding: 52px 32px 0 32px;
  overflow-x: hidden;
  overflow-y: auto;
`;

const Content = styled.div`
  width: 1035px;
  height: fit-content;
  margin-bottom: 212px;
`;

const ContentHead = styled.div`
  display: flex;
  position: relative;
  justify-content: center;
`;

const ContentBody = styled.div``;

const StepContainer = styled.div`
  position: relative;
  width: 64px;
  min-width: 64px;
  height: 100%;
`;

const StepCircle = styled.div`
  display: flex;
  position: sticky;
  top: 50%;
  align-items: center;
  justify-content: center;
  width: 64px;
  min-width: 64px;
  height: 64px;
  min-height: 64px;
  border-radius: 50%;
  background-color: ${theme.baseWhite};
  box-shadow: 0 3px 8px rgba(0, 0, 0, 0.15);
  cursor: pointer;

  &:hover {
    opacity: 0.8;
  }
`;

const MissionForm: React.VFC = () => {
  const {
    currentStepIndex,
    isFirstStep,
    isFinalStep,
    isFirstPublished,
    moveNextStep,
    movePrevStep,
    isFetchedMissionForm,
    canUseMissionFormStepMissionJobOffers,
    canUseMissionFormStepMissionDemand,
  } = useMissionFormContext();

  const stepContent = useMemo(() => {
    return [
      <StepCompany />,
      <StepAboutMission />,
      <StepMissionProducts />,
      canUseMissionFormStepMissionJobOffers ? (
        <StepMissionJobOffers />
      ) : undefined,
      canUseMissionFormStepMissionDemand ? <StepMissionDemand /> : undefined,
    ].filter((step): step is JSX.Element => step !== undefined)[
      currentStepIndex
    ];
  }, [
    canUseMissionFormStepMissionDemand,
    canUseMissionFormStepMissionJobOffers,
    currentStepIndex,
  ]);

  if (!isFetchedMissionForm) return null;

  return (
    <Wrapper data-testid="mission-form">
      <Body>
        {isFirstPublished && (
          <StepContainer>
            {!isFirstStep && (
              <StepCircle onClick={movePrevStep} data-testid="step-icon-prev">
                <ChevronLeftGray />
              </StepCircle>
            )}
          </StepContainer>
        )}
        <Content>
          <ContentHead>
            <MissionFormStepper />
            <MissionFormMenu />
          </ContentHead>

          <ContentBody>{stepContent}</ContentBody>
        </Content>
        {isFirstPublished && (
          <StepContainer>
            {!isFinalStep && (
              <StepCircle onClick={moveNextStep} data-testid="step-icon-next">
                <ChevronRightGray />
              </StepCircle>
            )}
          </StepContainer>
        )}
      </Body>
    </Wrapper>
  );
};

export default () => (
  <MissionFormContextProvider>
    <MissionForm />
  </MissionFormContextProvider>
);
