import { useFormik } from 'formik';
import { ObjectSchema } from 'yup';

const useMissionSettingFormik = <Values>({
  initialValues,
  validationSchema,
}: {
  initialValues: Values;
  validationSchema: ObjectSchema<any>;
}) => {
  const formik = useFormik<Values>({
    initialValues,
    validationSchema,
    enableReinitialize: true,
    validateOnChange: true,
    onSubmit: () => {},
  });

  return formik;
};

export type MissionSettingFormik = ReturnType<typeof useMissionSettingFormik>;

export default useMissionSettingFormik;
