import React, { FC, useEffect, useRef, useCallback } from 'react';
import styled from 'styled-components/macro';
import { useTranslation } from 'react-i18next';
import { useFormik } from 'formik';
import { string, object } from 'yup';
import typography from 'styles/typography';
import theme from 'styles/theme';
import { MessageTemplate } from 'proto/v1/apimodel/apimodel';
import { MessageTemplateFormValues } from 'types/messageTemplate';
import { MESSAGE_TEMPLATE_REPLACE_TEXT } from 'constants/models/messageTemplate';
import { TemplateCompany, TemplateMember, TemplateSchool } from 'assets/svg';
import { ButtonM, Textarea, TextInput, IconMenuItem } from 'components/atoms';
import {
  Modal,
  ModalContent,
  ModalFooter,
  FormItemLabel,
} from 'components/molecules';

const MAX_NAME_LENGTH = 15;
const MAX_TEMPLATE_LENGTH = 1000;

const Head = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  margin-bottom: 24px;
`;

const StyledModalContent = styled(ModalContent)`
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  height: 439px;
  text-align: left;
`;

const StyledTextInput = styled(TextInput)`
  width: 100%;
  margin-bottom: 20px;
`;

const StyledTextarea = styled(Textarea)`
  width: 100%;
  height: 272px;
  margin-bottom: 8px;

  textarea {
    width: 672px;
    resize: none;
  }
`;

const TemplateMenu = styled.div`
  display: flex;
  gap: 32px;
  padding-top: 8px;
`;

const MessageTemplateFormBottom = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

const InputCount = styled.div`
  ${typography.textNote}
  color: ${theme.textSecondary};
`;

const TemplateIconMenuItem = styled(IconMenuItem)`
  height: 20px;
`;

type Props = {
  show: boolean;
  onClose: () => void;
  onSave: (values: MessageTemplateFormValues) => Promise<void>;
  messageTemplate?: Partial<MessageTemplate>;
};

const MessageTemplateFormModal: FC<Props> = ({
  show,
  messageTemplate,
  onClose,
  onSave,
}) => {
  const templateTextareaRef = useRef<HTMLTextAreaElement>(null);
  const { t } = useTranslation(['messageTemplate', 'common']);

  const {
    values,
    resetForm,
    handleBlur,
    handleChange,
    isValid,
    setFieldValue,
  } = useFormik<MessageTemplateFormValues>({
    initialValues: {
      name: messageTemplate?.name ? messageTemplate.name : '',
      template: messageTemplate?.template ? messageTemplate.template : '',
    },
    onSubmit: () => {},
    validationSchema: object({
      name: string().required().max(MAX_NAME_LENGTH),
      template: string().trim().required().max(MAX_TEMPLATE_LENGTH),
    }),
  });

  const isDisabledSubmitButton = !isValid || !values.name || !values.template;

  const insertText = useCallback(
    (valueToInsert: string) => {
      const pos = templateTextareaRef.current?.selectionStart ?? 0;
      setFieldValue(
        'template',
        `${values.template.slice(
          0,
          pos,
        )}${valueToInsert}${values.template.slice(pos)}`.slice(
          0,
          MAX_TEMPLATE_LENGTH,
        ),
      );
    },
    [setFieldValue, values.template],
  );

  const handleClickMemberNameMenu = useCallback(() => {
    insertText(MESSAGE_TEMPLATE_REPLACE_TEXT.MEMBER_NAME);
    templateTextareaRef.current?.focus();
  }, [insertText]);

  const handleClickSchoolNameMenu = useCallback(() => {
    insertText(MESSAGE_TEMPLATE_REPLACE_TEXT.SCHOOL_NAME);
    templateTextareaRef.current?.focus();
  }, [insertText]);

  const handleClickCompanyNameMenu = useCallback(() => {
    insertText(MESSAGE_TEMPLATE_REPLACE_TEXT.COMPANY_NAME);
    templateTextareaRef.current?.focus();
  }, [insertText]);

  // Reset states when this modal opened.
  useEffect(() => {
    if (show) resetForm();
  }, [resetForm, show]);

  return (
    <Modal show={show} closeModal={onClose} height="607px">
      <StyledModalContent data-testid="modal-message-template-form">
        <Head>
          <FormItemLabel
            label={
              messageTemplate?.id
                ? t('modal.messageTemplate.form.title.edit')
                : t('modal.messageTemplate.form.title.create')
            }
            style={{ marginBottom: 0 }}
          />
        </Head>

        <StyledTextInput
          maxLength={MAX_NAME_LENGTH}
          name="name"
          type="text"
          value={values.name}
          placeholder={t('modal.messageTemplate.form.field.name.placeholder')}
          onChange={handleChange}
          onBlur={handleBlur}
          outline
          aria-label={t('modal.messageTemplate.form.field.name.label')}
        />

        <StyledTextarea
          maxLength={MAX_TEMPLATE_LENGTH}
          ref={templateTextareaRef}
          name="template"
          value={values.template}
          placeholder={t(
            'modal.messageTemplate.form.field.template.placeholder',
          )}
          onChange={handleChange}
          onBlur={handleBlur}
          aria-label={t('modal.messageTemplate.form.field.template.label')}
        />

        <MessageTemplateFormBottom>
          <TemplateMenu>
            <TemplateIconMenuItem
              data-testid="insert-replace-member-name"
              icon={<TemplateMember />}
              label={t('messageTemplate:menu.memberName')}
              onClick={handleClickMemberNameMenu}
            />
            <TemplateIconMenuItem
              data-testid="insert-replace-school-name"
              icon={<TemplateSchool />}
              label={t('messageTemplate:menu.schoolName')}
              onClick={handleClickSchoolNameMenu}
            />
            <TemplateIconMenuItem
              data-testid="insert-replace-company-name"
              icon={<TemplateCompany />}
              label={t('messageTemplate:menu.companyName')}
              onClick={handleClickCompanyNameMenu}
            />
          </TemplateMenu>
          <InputCount>
            {t('modal.messageTemplate.form.field.template.inputCount', {
              currentLength: values.template.length,
              maxLength: MAX_TEMPLATE_LENGTH,
            })}
          </InputCount>
        </MessageTemplateFormBottom>
      </StyledModalContent>

      <ModalFooter>
        <ButtonM type="button" buttonTheme="cancel" onClick={onClose}>
          {t('common:button.cancel')}
        </ButtonM>
        <ButtonM
          type="button"
          buttonTheme="default"
          onClick={() => {
            onSave(values);
          }}
          disabled={isDisabledSubmitButton}
        >
          {t('common:button.save')}
        </ButtonM>
      </ModalFooter>
    </Modal>
  );
};

export default MessageTemplateFormModal;
