import React, { FC, ChangeEvent } from 'react';
import styled from 'styled-components/macro';
import { useTranslation } from 'react-i18next';
import { MissionDemandFormValue } from 'types/form';
import { isEqualProps } from 'utils/object';
import { Checkbox, ErrorMessage } from 'components/atoms';
import { MissionDemandFieldItem } from 'components/molecules';
import typography from 'styles/typography';

type FurnishedOfficeRentalAvailabilityFieldProps = {
  value: MissionDemandFormValue['wantFurnishedOfficeRentalAvailability'];
  error: string | undefined;
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
};

const StyledCheckbox = styled(Checkbox)`
  &&& {
    margin-bottom: 0;
  }
`;

const Description = styled.p`
  ${typography.textDefault}
`;

const StyledErrorMessage = styled(ErrorMessage)`
  position: absolute;
  top: 60px;
  left: 0;
`;

const FurnishedOfficeRentalAvailabilityField: FC<FurnishedOfficeRentalAvailabilityFieldProps> = props => {
  const { value, error, onChange } = props;

  const { t } = useTranslation('missionSetting');

  return (
    <MissionDemandFieldItem style={{ position: 'relative' }}>
      <StyledCheckbox
        name="missionDemand.wantFurnishedOfficeRentalAvailability"
        checked={value}
        onChange={onChange}
        aria-label={t(
          'field.missionDemand.checkbox.wantFurnishedOfficeRentalAvailability',
        )}
      >
        {t(
          'field.missionDemand.checkbox.wantFurnishedOfficeRentalAvailability',
        )}
      </StyledCheckbox>

      {value && (
        <Description>
          {t(
            'field.missionDemand.furnishedOfficeRentalAvailability.description',
          )}
        </Description>
      )}

      {error && <StyledErrorMessage>{error}</StyledErrorMessage>}
    </MissionDemandFieldItem>
  );
};

export default React.memo(
  FurnishedOfficeRentalAvailabilityField,
  (prevProps, nextProps) =>
    isEqualProps(prevProps, nextProps, ['value', 'error']),
);
