import React, { FC, ComponentProps, useEffect } from 'react';
import { onAuthStateChanged } from 'firebase/auth';
import { Route, useHistory, useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import useLogout from 'hooks/useLogout';
import * as PUBLIC_ROUTES from 'routes/constants/public';
import { PrivateRouteLayout } from 'components/templates';
import { useTranslation } from 'react-i18next';
import { NotificationActions } from 'modules/notification';
import { getIsSwitching } from 'modules/myAccounts';
import { ConfigActions, getAfterLoginUrl } from 'modules/config';
import useErrorDialog from 'hooks/useErrorDialog';
import apiFirebase from 'external/firebase/firebase';
import {
  AccountActions,
  getIsLoggedIn,
  getAccountId,
} from '../modules/account';

const PrivateRoute: FC<ComponentProps<
  typeof Route & typeof PrivateRouteLayout
>> = props => {
  const isLoggedIn = useSelector(getIsLoggedIn);
  const accountId = useSelector(getAccountId);
  const isSwitching = useSelector(getIsSwitching);
  const isCorrectLoggedIn = (isLoggedIn && accountId) || isSwitching;
  const afterLoginUrl = useSelector(getAfterLoginUrl);
  const { handleLogoutWithRedirectToLogin } = useLogout();
  const dispatch = useDispatch();
  const { openErrorDialog } = useErrorDialog();
  const history = useHistory();
  const location = useLocation();
  const { t } = useTranslation('error');

  // Redirect to login page if the user isn't logged in.
  useEffect(() => {
    if (!isCorrectLoggedIn) {
      handleLogoutWithRedirectToLogin();
      if (!afterLoginUrl) {
        dispatch(
          ConfigActions.setAfterLoginUrl(
            `${location.pathname}${location.search}`,
          ),
        );
      }
    }
  }, [
    isCorrectLoggedIn,
    handleLogoutWithRedirectToLogin,
    dispatch,
    location,
    history,
    afterLoginUrl,
  ]);

  useEffect(() => {
    const unsubscribeAuthStateChanged = onAuthStateChanged(
      apiFirebase.auth(),
      user => {
        if (!user && !isSwitching) {
          // 他のタブでログアウトされたときにエラーメッセージを出してログインページに移動する。
          dispatch(AccountActions.clear());
          dispatch(NotificationActions.clearNotification());

          history.push(PUBLIC_ROUTES.LOGIN);
          openErrorDialog({
            message: t('loggedOutInAnothreTab'),
            showCloseIcon: false,
            onClose: () => {
              // ミッションが切り替えられた時は再度ログインされているため、ここでcurrentUserを再取得。
              // ログイン画面からログインすると、ミッションを切り替えたタブでリクエストを投げた時にエラーになるため、
              // リロードしてログイン状態にする。
              if (apiFirebase.authUser()) {
                window.location.reload();
              }
            },
          });
        }
      },
    );
    dispatch(AccountActions.setUnsubscribeLogout(unsubscribeAuthStateChanged));
    return () => {
      unsubscribeAuthStateChanged();
    };
  }, [dispatch, history, isSwitching, openErrorDialog, t]);

  return isCorrectLoggedIn ? (
    <PrivateRouteLayout {...props}>
      <Route {...props} />
    </PrivateRouteLayout>
  ) : null;
};

export default PrivateRoute;
