import React, { FC, ChangeEvent } from 'react';
import { useTranslation } from 'react-i18next';
import { MissionForm, MissionFormValues } from 'types/mission';
import { isEqualProps } from 'utils/object';
import { Selectbox } from 'components/atoms';
import { FormItem, FormItemLabel } from 'components/molecules';

type CountryFieldProps = {
  value: MissionFormValues['countryId'];
  form: MissionForm['countries'];
  error: string | undefined;
  onChange: (e: ChangeEvent<HTMLSelectElement>) => void;
  onBlur: (e: any) => void;
};

const CountryField: FC<CountryFieldProps> = props => {
  const { value, error, form, onChange, onBlur } = props;

  const { t } = useTranslation('missionSetting');

  return (
    <FormItem error={error} style={{ marginBottom: 0 }}>
      <FormItemLabel
        label={t('field.address.subField.country.label')}
        level="secondary"
        required
      />
      <Selectbox
        margin="0 16px 0 0"
        name="countryId"
        options={form}
        idName="countryId"
        value={value}
        onBlur={onBlur}
        onChange={onChange}
        aria-label={t('field.address.label')}
        style={{ width: '136px' }}
      />
    </FormItem>
  );
};

export default React.memo(CountryField, (prevProps, nextProps) =>
  isEqualProps(prevProps, nextProps, ['value', 'error', 'form']),
);
