import React, { FC, ComponentProps } from 'react';
import styled, { css } from 'styled-components/macro';
import { CSSTransition } from 'react-transition-group';

const DetailPlane = styled.div<{ showScoutForm?: boolean }>`
  position: absolute;
  z-index: 5;
  top: 0;
  right: 0;
  height: 100%;
  transform: translateX(0);
  transition: transform 500ms;
  box-shadow: 0 0 30px rgba(0, 0, 0, 0.3);

  &.enter {
    transform: translateX(0);
  }

  &.enter-done {
    transform: translateX(0);
  }

  &.exit {
    transform: translateX(864px);
  }

  &.exit-done {
    transform: translateX(864px);
  }

  ${({ showScoutForm }) =>
    showScoutForm &&
    css`
      left: 0;
      transform: translateX(0);
      width: 100%;
    `}
`;

type Props = { show: boolean; showScoutForm?: boolean } & Pick<
  ComponentProps<typeof CSSTransition>,
  'onExited'
>;

const HumanResourceCardDetailPlane: FC<Props> = ({
  show,
  showScoutForm,
  onExited,
  children,
}) => {
  return (
    <CSSTransition in={show} appear={show} timeout={500} onExited={onExited}>
      <DetailPlane showScoutForm={showScoutForm}>{children}</DetailPlane>
    </CSSTransition>
  );
};

export default HumanResourceCardDetailPlane;
