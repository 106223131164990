import styled from 'styled-components/macro';

type ButtonContainerProps = {
  align?: string;
  layout?: 'horizontal' | 'vertical';
};

const ButtonContainer = styled.div`
  text-align: ${({ align = 'center' }: ButtonContainerProps) => align};
  ${({ layout = 'horizontal' }: ButtonContainerProps) =>
    layout === 'horizontal'
      ? `> button {
          margin-left: 10px;
          &:first-child {
            margin-left: 0;
          }
        `
      : ` > * {
          margin-bottom: 20px;
          &:last-child {
            margin-bottom: 0;
          }
        `}
`;

export default ButtonContainer;
