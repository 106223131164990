import React, { FC, useMemo } from 'react';
import { imageSelector } from 'utils/imageSelector';
import ImageTypes from 'constants/imageTypes';
import { useTranslation } from 'react-i18next';
import { TwoColumnContent } from 'components/atoms';
import { MissionSettingMaxLength } from 'constants/form';
import {
  StrengthPointField,
  SupportDescriptionField,
  CoverImageField,
  MissionNameField,
  CompanyNameField,
  IconImageField,
  CompanyUrlField,
  CompanyEstablishedDateField,
  AddressField,
  MemberRangeField,
  SupportAreasField,
} from '../../../Fields';
import useSupporterFormContext from '../../useSupporterFormContext';

const RequiredFieldsContainer: FC = () => {
  const {
    formik,
    form,
    formError,
    openImageCropper,
    handleChangeSelectedValueAsNumber,
    handleExceedImageFileSize,
    handleChangeOtherAddress,
    handleChangeCompanyEstablishedYear,
    handleChangeSupportArea,
  } = useSupporterFormContext();

  const { values, touched, errors, handleChange, handleBlur } = formik;

  const { t } = useTranslation('missionSetting');

  return useMemo(
    () => (
      <TwoColumnContent style={{ marginBottom: 0 }}>
        <div className="content-inner">
          <SupportDescriptionField
            value={values.supportDescription}
            error={formError('supportDescription')}
            onChange={handleChange}
            onBlur={handleBlur}
          />

          <StrengthPointField
            value={values.strengthPoint}
            error={formError('strengthPoint')}
            onChange={handleChange}
            onBlur={handleBlur}
            label={t('field.supporterStrengthPoint.label')}
            note={t('field.supporterStrengthPoint.note', {
              maxLength: MissionSettingMaxLength.STRENGTH_POINT,
            })}
            placeholder={t('field.supporterStrengthPoint.placeholder')}
            maxLength={MissionSettingMaxLength.STRENGTH_POINT}
            textAreaHeight={704}
            style={{ marginBottom: 0 }}
          />
        </div>

        <div className="content-inner">
          <CoverImageField
            imageSrc={imageSelector(values.coverImage, 'large')}
            error={touched.coverImage ? errors.coverImage : undefined}
            onUpload={uploadedImage => {
              openImageCropper(ImageTypes.COVER, uploadedImage);
            }}
            onExceededFileSizeLimit={handleExceedImageFileSize}
          />

          <MissionNameField
            value={values.name}
            error={formError('name')}
            onChange={handleChange}
            onBlur={handleBlur}
          />

          <CompanyNameField
            value={values.companyName}
            error={formError('companyName')}
            onChange={handleChange}
            onBlur={handleBlur}
          />

          <IconImageField
            imageSrc={imageSelector(values.iconImage, 'small')}
            error={touched.iconImage ? errors.iconImage : undefined}
            onUpload={uploadedImage => {
              openImageCropper(ImageTypes.ICON, uploadedImage);
            }}
            onExceededFileSizeLimit={handleExceedImageFileSize}
          />

          <CompanyUrlField
            value={values.companyUrl}
            error={formError('companyUrl')}
            onChange={handleChange}
            onBlur={handleBlur}
          />

          <CompanyEstablishedDateField
            values={values.companyEstablishedDate}
            onChangeYear={handleChangeCompanyEstablishedYear}
            onChangeMonth={handleChangeSelectedValueAsNumber}
            onBlur={handleBlur}
          />

          <AddressField
            values={{
              countryId: values.countryId,
              japanPrefectureId: values.japanPrefectureId,
              otherAddress: values.otherAddress,
            }}
            errors={{
              countryId: formError('countryId'),
              japanPrefectureId: formError('japanPrefectureId'),
              otherAddress: formError('otherAddress'),
            }}
            form={{
              countries: form.countries,
              japanPrefectures: form.japanPrefectures,
            }}
            onChangeCountry={handleChange}
            onChangeJapanPrefecture={handleChangeSelectedValueAsNumber}
            onChangeOtherAddress={handleChangeOtherAddress}
            onBlur={handleBlur}
          />

          <MemberRangeField
            value={values.memberRangeId}
            error={formError('memberRangeId')}
            form={form.memberRanges}
            onChange={handleChangeSelectedValueAsNumber}
            onBlur={handleBlur}
          />

          <SupportAreasField
            value={values.supportAreaIds}
            error={formError('supportAreaIds')}
            form={form.supportAreas}
            onChange={handleChangeSupportArea}
          />
        </div>
      </TwoColumnContent>
    ),
    [
      errors.coverImage,
      errors.iconImage,
      form.countries,
      form.japanPrefectures,
      form.memberRanges,
      form.supportAreas,
      formError,
      handleBlur,
      handleChange,
      handleChangeCompanyEstablishedYear,
      handleChangeOtherAddress,
      handleChangeSelectedValueAsNumber,
      handleChangeSupportArea,
      handleExceedImageFileSize,
      openImageCropper,
      t,
      touched.coverImage,
      touched.iconImage,
      values.companyEstablishedDate,
      values.companyName,
      values.companyUrl,
      values.countryId,
      values.coverImage,
      values.iconImage,
      values.japanPrefectureId,
      values.memberRangeId,
      values.name,
      values.otherAddress,
      values.strengthPoint,
      values.supportAreaIds,
      values.supportDescription,
    ],
  );
};

export default RequiredFieldsContainer;
