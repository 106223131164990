import { useCallback, ChangeEvent } from 'react';
import { MissionSettingFormik } from './useMissionSettingFormik';

type Payload = {
  setFieldValue: MissionSettingFormik['setFieldValue'];
};

const useAddressField = ({ setFieldValue }: Payload) => {
  const handleChangeOtherAddress = useCallback(
    (e: ChangeEvent<HTMLTextAreaElement>): void => {
      // Not to include line breaks
      setFieldValue('otherAddress', e.target.value.replace(/\r?\n/g, ''));
    },
    [setFieldValue],
  );

  return {
    handleChangeOtherAddress,
  };
};

export default useAddressField;
