import React, { Suspense, FC, useEffect } from 'react';
import { onAuthStateChanged } from 'firebase/auth';
import * as H from 'history';
import styled from 'styled-components/macro';
import theme from 'styles/theme';
import { Container } from 'visits-style';
import { Loading } from 'components/atoms';
import { Sidebar } from 'components/organisms';
import apiFirebase from 'external/firebase/firebase';
import useNotification from 'hooks/useNotification';
import { useDispatch, useSelector } from 'react-redux';
import { AccountActions } from 'modules/account';
import { getIsSidebarShrinked } from 'modules/ui';
import { getIsSwitching } from 'modules/myAccounts';
import useActiveAccount from './useActiveAccount';
// import useFeatures from './useFeatures';

const StyledContainer = styled(Container)`
  &&& {
    display: flex;
    max-width: 100%;
    height: 100vh;
    background: ${theme.bgMain};
  }
`;

const Contents = styled.section`
  position: relative;
  width: 100%;
  max-width: 100%;
  overflow: auto;
`;

const ContentsInner = styled.div<{ isSidebarShrinked: boolean }>`
  min-width: ${({ isSidebarShrinked }) =>
    isSidebarShrinked ? '1370px' : '1200px'};
  height: 100%;
`;

export const Spacer = styled.div`
  padding: 24px 32px 32px 32px;
`;

const PrivateRouteLayout: FC<{ location?: H.Location }> = props => {
  // Use useFeatures if you need feature flags
  // useFeatures();
  const { subscribeNotification } = useNotification();
  const dispatch = useDispatch();

  const isSidebarShrinked = useSelector(getIsSidebarShrinked);
  const isSwitching = useSelector(getIsSwitching);

  const { isFetchedActiveAccount } = useActiveAccount();

  useEffect(() => {
    const unSubscribeContactNotification = subscribeNotification('contact');
    const unSubscribeMessageNotification = subscribeNotification('message');

    const unSubscribeNotifications = () => {
      unSubscribeContactNotification && unSubscribeContactNotification();
      unSubscribeMessageNotification && unSubscribeMessageNotification();
    };

    const unsubscribeAuthStateChanged = onAuthStateChanged(
      apiFirebase.auth(),
      user => {
        if (!user) {
          unSubscribeNotifications();
        }
      },
    );
    dispatch(AccountActions.setUnsubscribeLogout(unsubscribeAuthStateChanged));

    return () => {
      unsubscribeAuthStateChanged();
      unSubscribeNotifications();
    };
  }, [dispatch, subscribeNotification]);

  return (
    <>
      <StyledContainer>
        {isFetchedActiveAccount && (
          <>
            <Sidebar />
            <Contents>
              <ContentsInner isSidebarShrinked={isSidebarShrinked}>
                <Suspense fallback={<Loading />}>
                  {!isSwitching && props.children}
                </Suspense>
              </ContentsInner>
            </Contents>
          </>
        )}
      </StyledContainer>
    </>
  );
};

export default PrivateRouteLayout;
