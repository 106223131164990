import React, { FC, ComponentProps } from 'react';
import { useTranslation } from 'react-i18next';
import { isEqualProps } from 'utils/object';
import {
  ImageUploader,
  FormItemLabel,
  MissionSettingFormItem,
} from 'components/molecules';

type ImageUploaderProps = ComponentProps<typeof ImageUploader>;

type CoverImageFieldProps = {
  error: string | undefined;
} & Pick<
  ImageUploaderProps,
  'imageSrc' | 'onUpload' | 'onExceededFileSizeLimit'
>;

const CoverImageField: FC<CoverImageFieldProps> = props => {
  const { imageSrc, error, onUpload, onExceededFileSizeLimit } = props;

  const { t } = useTranslation('missionSetting');

  return (
    <MissionSettingFormItem required error={error}>
      <FormItemLabel
        label={t('field.coverImage.label')}
        note={t('field.coverImage.note', { returnObjects: true })}
        required
      />
      <ImageUploader
        name="coverImage"
        imageSrc={imageSrc}
        onUpload={onUpload}
        onExceededFileSizeLimit={onExceededFileSizeLimit}
        width={445}
        height={250}
      />
    </MissionSettingFormItem>
  );
};

export default React.memo(CoverImageField, (prevProps, nextProps) =>
  isEqualProps(prevProps, nextProps, ['imageSrc', 'error']),
);
