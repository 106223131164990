import React, { FC, ReactNode, CSSProperties } from 'react';
import styled from 'styled-components/macro';
import theme from 'styles/theme';
import typography from 'styles/typography';

const TitleH1 = styled.h1`
  color: ${theme.textPrimary};

  &.public {
    margin-bottom: 32px;
    font-size: 26px;
    font-weight: 600;
    line-height: 1.5;
    text-align: center;
  }

  &.private {
    ${typography.titlePage}
    margin-bottom: 4px;
    text-align: left;
  }
`;

const TitleH2 = styled.h2`
  ${typography.headlineDefault}
  color: ${theme.textPrimary};
`;

const TitleH3 = styled.h3`
  ${typography.headlineDefault}
  margin-bottom: 16px;
  color: ${theme.gray};

  &.private {
    position: relative;
    padding-left: 12px;

    &::after {
      content: '';
      display: inline-block;
      position: absolute;
      top: 2px;
      left: 0;
      width: 4px;
      height: calc(100% - 4px);
      border-radius: 1.5px;
      background: ${theme.basePrimary};
    }
  }
`;

type HeadingProps = {
  level: 'h1' | 'h2' | 'h3';
  children: ReactNode;
  contents?: 'private' | 'public';
  style?: CSSProperties;
};

const switchTag = (props: HeadingProps) => {
  const { contents = 'public', level, children } = props;
  switch (level) {
    case 'h1':
      return (
        <TitleH1 {...props} className={contents}>
          {children}
        </TitleH1>
      );
    case 'h2':
      return (
        <TitleH2 {...props} className={contents}>
          {children}
        </TitleH2>
      );
    case 'h3':
      return (
        <TitleH3 {...props} className={contents}>
          {children}
        </TitleH3>
      );
  }
};

const Heading: FC<HeadingProps> = props => switchTag(props);

export default Heading;
