import {
  Mission,
  MissionDemandCollaboration,
  MissionDemandServiceInformation,
  MissionDemandCollaborationToUpdate,
  MissionDemandServiceInformationToUpdate,
  JapanPrefecture,
  SupportArea,
} from 'proto/v1/apimodel/apimodel';
import { MissionFormValues } from 'types/mission';
import { SupporterFormValues } from 'types/supporter';
import {
  JapanPrefectureIds,
  FinancingSeriesIds,
} from 'constants/models/mission';
import {
  MISSION_COMPANY_ESTABLISHED_YEAR_INITIAL_VALUE,
  MISSION_COMPANY_ESTABLISHED_MONTH_INITIAL_VALUE,
  MISSION_PRODUCT_PRODUCT_TAGS_MAX_COUNT,
} from 'constants/config';

export const convertJapanPrefecture = (
  japanPrefecture: JapanPrefecture | undefined,
):
  | MissionFormValues['japanPrefectureId']
  | SupporterFormValues['japanPrefectureId'] => {
  return japanPrefecture?.japanPrefectureId
    ? japanPrefecture?.japanPrefectureId
    : JapanPrefectureIds.TOKYO;
};

export const convertCompanyEstablishDate = (
  companyEstablishedDate: Mission['companyEstablishedDate'],
): MissionFormValues['companyEstablishedDate'] => {
  return (
    companyEstablishedDate ?? {
      year: MISSION_COMPANY_ESTABLISHED_YEAR_INITIAL_VALUE,
      month: MISSION_COMPANY_ESTABLISHED_MONTH_INITIAL_VALUE,
    }
  );
};

export const convertMissionCategories = (
  missionCategories: Mission['missionCategories'],
): MissionFormValues['missionCategories'] =>
  missionCategories.map(category => category.categoryId);

const convertMissionDemandCollaboration = (
  value: MissionDemandCollaboration | undefined,
): MissionDemandCollaborationToUpdate => {
  if (!value || !value?.assets.length) {
    return { assets: [{ give: '', want: '' }] };
  }
  return value;
};

const convertMissionDemandServiceInformation = (
  value: MissionDemandServiceInformation | undefined,
): MissionDemandServiceInformationToUpdate => {
  if (!value || !value?.issues.length) {
    return { issues: [''] };
  }
  return value;
};

export const convertMissionDemand = (
  missionDemand: Mission['missionDemand'],
): MissionFormValues['missionDemand'] => {
  const isSelectedTargetFinancingSeriesId = (
    targetSeriesId: FinancingSeriesIds,
  ) =>
    (missionDemand?.invest?.financingSeries ?? []).some(
      series => series.seriesId === targetSeriesId,
    );

  return {
    wantCollaboration: !!missionDemand?.collaboration,
    wantInvest: !!missionDemand?.invest,
    wantFundraising: !!missionDemand?.fundraising,
    wantServiceInformation: !!missionDemand?.serviceInformation,
    wantServiceSuggestion: !!missionDemand?.serviceSuggestion,
    wantTransferOfBusiness: !!missionDemand?.transferOfBusiness,
    wantTransferredOfBusiness: !!missionDemand?.transferredOfBusiness,
    wantAcceptSecondment: !!missionDemand?.acceptSecondment,
    wantSendEmployee: !!missionDemand?.sendEmployee,
    wantInvestSeed: isSelectedTargetFinancingSeriesId(FinancingSeriesIds.SEED),
    wantInvestEarly: isSelectedTargetFinancingSeriesId(
      FinancingSeriesIds.EARLY,
    ),
    wantInvestMiddle: isSelectedTargetFinancingSeriesId(
      FinancingSeriesIds.MIDDLE,
    ),
    wantInvestLater: isSelectedTargetFinancingSeriesId(
      FinancingSeriesIds.LATER,
    ),
    wantFurnishedOfficeRentalAvailability: !!missionDemand?.furnishedOfficeRentalAvailability,
    wantFurnishedOfficeRentalRequest: !!missionDemand?.furnishedOfficeRentalRequest,
    collaboration: convertMissionDemandCollaboration(
      missionDemand?.collaboration,
    ),
    fundraising: {
      financingSeriesId:
        missionDemand?.fundraising?.financingSeries?.seriesId ?? 0,
    },
    serviceInformation: convertMissionDemandServiceInformation(
      missionDemand?.serviceInformation,
    ),
    transferredOfBusiness: {
      fundId: missionDemand?.transferredOfBusiness?.fund?.fundId ?? 0,
    },
    acceptSecondment: missionDemand?.acceptSecondment ?? {
      work: '',
    },
    sendEmployee: missionDemand?.sendEmployee ?? { work: '' },
  };
};

export const convertMissionProducts = (
  missionProducts: Mission['missionProducts'],
): MissionFormValues['missionProducts'] =>
  missionProducts.map(missionProduct => ({
    ...missionProduct,
    productTarget: {
      isForBusiness: missionProduct.productTarget?.isForBusiness || false,
      isForCustomer: missionProduct.productTarget?.isForCustomer || false,
    },
    hasMemberBenefit:
      missionProduct.memberBenefit === undefined
        ? undefined
        : !!missionProduct.memberBenefit,
    tags: Array.from({ length: MISSION_PRODUCT_PRODUCT_TAGS_MAX_COUNT }).map(
      (_, i) => ({
        tag: missionProduct.tags[i] ?? '',
        id: i,
      }),
    ),
  }));

export const convertSupportAreas = (
  supportAreas: SupportArea[],
): SupporterFormValues['supportAreaIds'] =>
  supportAreas.map(supportArea => supportArea.supportAreaId);

export const convertMissionJobOffers = (
  missionJobOffers: Mission['missionJobOffers'],
): MissionFormValues['missionJobOffers'] =>
  missionJobOffers.map(missionJobOffer => ({
    id: missionJobOffer.id,
    occupationId: missionJobOffer.occupation?.id ?? 0,
    jobDescription: missionJobOffer.jobDescription,
    published: missionJobOffer.published,
  }));
