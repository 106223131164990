import { ComponentProps } from 'react';
import styled from 'styled-components/macro';
import { Radio } from 'visits-style';
import theme from 'styles/theme';

type Props = ComponentProps<typeof Radio>;
const StyledRadio = styled(Radio)<Props>`
  > label > div,
  > label > div.checked {
    width: 14px;
    height: 14px;
    margin-top: 0;
    margin-right: 10px;
    margin-bottom: 0;
    border-color: ${theme.basePrimary};

    > i {
      width: 10px;
      height: 10px;
      background-color: ${theme.basePrimary};
    }

    /* todo VI-1599 animation necessity
      normal : scale 1 , background:white
      checked : scale : inherit, bgcolor: define-color
     */
  }
`;

export default StyledRadio;
