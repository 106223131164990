import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components/macro';
import theme from 'styles/theme';
import { FiImage } from 'react-icons/fi';
import {
  ButtonM,
  FlexContainer,
  ImageCircle,
  EllipsisBox,
} from 'components/atoms';
import { Modal, ModalContent, ModalFooter } from 'components/molecules';

const Description = styled.div`
  width: 100%;
  margin-bottom: 32px;
`;

const MissionSummary = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 48px;
`;

const MissionName = styled(EllipsisBox)`
  margin-left: 8px;
`;

const MissionImagePlaceholder = styled.div<{ size: number }>`
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;
  width: ${({ size }) => `${size}px`};
  height: ${({ size }) => `${size}px`};
  border-radius: 50%;
  background-color: ${theme.bgMissionListImage};
`;

type DeleteMissionConfirmModalProps = {
  show: boolean;
  missionId: string | undefined;
  missionName: string | undefined;
  imageUrl: string | undefined;
  onClickOk: (missionId: string) => void;
  onClickCancel: () => void;
};

const DeleteMissionConfirmModal: FC<DeleteMissionConfirmModalProps> = props => {
  const {
    show,
    missionId,
    missionName,
    imageUrl,
    onClickOk,
    onClickCancel,
  } = props;
  const { t } = useTranslation('missionSetting');
  return (
    <Modal show={show} closeModal={onClickCancel}>
      <ModalContent data-testid="modal-delete-mission-confirm">
        <FlexContainer>
          <Description>{t('modal.deleteConfirmation.content')}</Description>
          <MissionSummary>
            {imageUrl ? (
              <ImageCircle imageSrc={imageUrl} diameter={'48px'} />
            ) : (
              <MissionImagePlaceholder
                size={48}
                data-testid="my-accounts-selector-selected-my-account-no-icon"
              >
                <FiImage size={27} color={theme.baseWhite} />
              </MissionImagePlaceholder>
            )}

            <MissionName
              line={2}
              width={'224px'}
              maxHeight={'45px'}
              backgroundColor={theme.baseWhite}
            >
              {missionName || t('modal.deleteConfirmation.noMissionName')}
            </MissionName>
          </MissionSummary>
        </FlexContainer>
      </ModalContent>
      <ModalFooter>
        <ButtonM
          buttonTheme="cancel"
          onClick={onClickCancel}
          data-testid="delete-mission-confirm-modal-cancel"
        >
          {t('common:button.cancel')}
        </ButtonM>
        <ButtonM
          buttonTheme="default"
          onClick={() => missionId && onClickOk(missionId)}
        >
          {t('common:button.ok')}
        </ButtonM>
      </ModalFooter>
    </Modal>
  );
};

export default DeleteMissionConfirmModal;
