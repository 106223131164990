import React, { FC, ComponentProps } from 'react';
import styled from 'styled-components/macro';
import typography from 'styles/typography';
import BaseButton from './BaseButton';

const StyledButtonS = styled(BaseButton)<ComponentProps<typeof BaseButton>>`
  ${typography.textDefault}
  min-width: 75px;
  height: 32px;
  padding: 6px 16px;
`;

const ButtonS: FC<ComponentProps<typeof BaseButton>> = React.forwardRef(
  (props, ref) => (
    <StyledButtonS {...props} ref={ref}>
      {props.children}
    </StyledButtonS>
  ),
);
export default ButtonS;
