import styled from 'styled-components/macro';
import { Tag } from 'components/atoms';
import theme from 'styles/theme';
import typography from 'styles/typography';

const SupportAreaTag = styled(Tag)`
  &&& {
    ${typography.labelCategory}
    min-width: 94px;
    height: 24px;
    margin-bottom: 4px;
    margin-left: 0;
    padding: 4px 8px 5px;
    border: 1px solid ${theme.textCardSecondary};
    border-radius: 12px;
    background-color: ${theme.baseWhite};
    color: ${theme.textCardSecondary};

    &:not(:last-child) {
      margin-right: 2px;
    }
  }
`;

export default SupportAreaTag;
