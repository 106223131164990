import React from 'react';
import styled from 'styled-components/macro';
import theme from 'styles/theme';
import { useTranslation, Trans } from 'react-i18next';
import { ButtonM } from 'components/atoms';
import { Modal, ModalContent, ModalFooter } from 'components/molecules';
import { ValidateToPublishStepsResult } from 'types/form';

const Message = styled.p``;

const StepsResult = styled.div`
  align-self: flex-start;
  width: 520px;
  padding: 12px 24px;
  background-color: ${theme.bgMain};
  color: ${theme.textPrimary};
  text-align: left;
`;

const StepsResultItem = styled.div``;

const Highlighted = styled.span`
  color: ${theme.labelDefault};
`;

type Props = {
  show: boolean;
  onClose: () => void;
  stepsResult: ValidateToPublishStepsResult | undefined;
  canUseStepJobOffers: boolean;
  canUseStepMissionDemand: boolean;
};

const InvalidToPublishModal: React.VFC<Props> = ({
  show,
  onClose,
  stepsResult,
  canUseStepJobOffers,
  canUseStepMissionDemand,
}) => {
  const { t } = useTranslation('missionSetting');

  return (
    <Modal show={show} closeModal={onClose} height={'auto'}>
      <ModalContent
        style={{ overflowY: 'auto', height: '264px' }}
        data-testid="modal-invalid-to-publish"
      >
        <Message>{t('modal.invalidToPublish.message')}</Message>
        <StepsResult>
          {!stepsResult?.company && (
            <StepsResultItem>
              {t('validateToPublishStepsResult.company')}
            </StepsResultItem>
          )}
          {!stepsResult?.aboutMission && (
            <StepsResultItem>
              {t('validateToPublishStepsResult.aboutMission')}
            </StepsResultItem>
          )}
          {canUseStepJobOffers &&
            !canUseStepMissionDemand &&
            !stepsResult?.missionJobOffers && (
              <StepsResultItem>
                <Trans
                  i18nKey="validateToPublishStepsResult.missionJobOffers"
                  ns="missionSetting"
                >
                  4. 求人情報 ※ミッションの公開のためには
                  <Highlighted>４</Highlighted>
                  の入力が必要です。
                </Trans>
              </StepsResultItem>
            )}
          {!canUseStepJobOffers &&
            canUseStepMissionDemand &&
            !stepsResult?.missionDemand && (
              <StepsResultItem>
                <Trans
                  i18nKey="validateToPublishStepsResult.MissionDemand"
                  ns="missionSetting"
                >
                  4. ビジネスニーズ ※ミッションの公開のためには
                  <Highlighted>４</Highlighted>
                  の入力が必要です。
                </Trans>
              </StepsResultItem>
            )}
          {canUseStepJobOffers &&
            canUseStepMissionDemand &&
            !stepsResult?.missionJobOffers &&
            !stepsResult?.missionDemand && (
              <StepsResultItem>
                <Trans
                  i18nKey="validateToPublishStepsResult.missionJobOffersOrMissionDemand"
                  ns="missionSetting"
                >
                  4. 求人情報 または 5. ビジネスニーズ
                  ※ミッションの公開のためには
                  <Highlighted>４または５のいずれか</Highlighted>
                  の入力が必要です。
                </Trans>
              </StepsResultItem>
            )}
        </StepsResult>
      </ModalContent>
      <ModalFooter>
        <ButtonM onClick={onClose} buttonTheme="default" type="button">
          {t('common:button.ok')}
        </ButtonM>
      </ModalFooter>
    </Modal>
  );
};

export default InvalidToPublishModal;
