import { del } from 'idb-keyval';
import { ChatRoom } from 'proto/v1/apimodel/apimodel';
import { LocalStorageKey } from 'constants/storage';

export const sortChatRooms = (chatRooms: ChatRoom[], ascending: boolean) => {
  const copied: ChatRoom[] = [...chatRooms];
  copied.sort((prev, next) => {
    return (prev.updatedAt - next.updatedAt) * (ascending ? 1 : -1) < 0
      ? 1
      : -1;
  });
  return copied;
};

export const getFileSize = (size: number) => {
  // use Megabytes unit size（1MB = 1000000Byte, not 1048576Byte)
  if (size / 1000 < 1) return '1KB';
  if (size / 1000 < 1000) return `${Math.round(size / 1000)}KB`;

  // FILE_SIZE_LIMIT_MB: 25MB
  return `${Math.round(size / 1000000)}MB`;
};

export const clearMessagesToResend = () => {
  del(LocalStorageKey.CHAT_MESSAGES_TO_RESEND);
};

export const isMissionChatRoom = (chatRoom: ChatRoom) =>
  !!chatRoom.chatUser?.mission;

export const isSupporterChatRoom = (chatRoom: ChatRoom) =>
  !!chatRoom.chatUser?.supporter;

export const isInnovatorChatRoom = (chatRoom: ChatRoom) =>
  !!chatRoom.chatUser?.innovator;
