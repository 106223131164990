import React, { FC, CSSProperties, useCallback } from 'react';
import styled, { css } from 'styled-components/macro';
import theme from 'styles/theme';
import typography from 'styles/typography';
import { formatTimestamp } from 'utils/time';
import {
  FlexContainer,
  ImageCircle,
  Badge,
  EllipsisBox,
} from 'components/atoms';
import { DefaultUser } from 'assets/svg';
import { ChatRoom, ChatUser } from 'proto/v1/apimodel/apimodel';
import { useTranslation } from 'react-i18next';
import { getEmploymentType } from 'utils/models/matter';
import { EMPLOYMENT_TYPE_LABEL } from 'constants/models/matter';

type CardWrapperProps = {
  selected: boolean;
};

const LatestMessage = styled(EllipsisBox)`
  color: ${theme.textSecondary};
`;

const CardWrapper = styled(FlexContainer)<CardWrapperProps>`
  position: relative;
  flex-wrap: nowrap;
  align-items: inherit;
  justify-content: space-between;
  height: 130px;
  padding: 16px 16px 16px 12px;
  overflow: hidden;
  border-bottom: 1px solid ${theme.borderDefault};
  background-color: ${theme.baseWhite};
  cursor: pointer;

  &:hover {
    background-color: ${theme.bgChatRoomCardHover};
  }

  &:hover ${LatestMessage} {
    background-color: ${theme.bgChatRoomCardHover};
  }

  &:last-child {
    margin-bottom: 0;
  }

  ${({ selected }) =>
    selected &&
    css`
      background-color: ${theme.bgChatRoomCardSelected};
      ${LatestMessage} {
        background-color: ${theme.bgChatRoomCardSelected};
      }
    `}
`;

const ColumnIcon = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 8px;
`;

const DeletedUserLabel = styled.div`
  ${typography.textSub}
  color: ${theme.textSecondary};
  text-align: center;
`;

const ColumnInfo = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  flex-shrink: 1;
  padding-left: 12px;
`;

const ItemsRow = styled.div`
  display: flex;
  align-items: start;
  justify-content: space-between;
`;

const Separator = styled.div`
  width: 280px;
  margin-top: 8px;
  margin-bottom: 8px;
  border-bottom: 1px solid ${theme.borderTable};
`;

const ItemsRowTop = styled(ItemsRow)`
  margin-bottom: 2px;
`;

const ItemsRowBottom = styled(ItemsRow)``;

const LeftItem = styled.div`
  width: 221px;
`;

const ChatUserName = styled(LeftItem)`
  ${typography.headlineDefault}
  height: 24px;
  overflow: hidden;
  color: ${theme.textPrimary};
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const CompanyWrapper = styled(LeftItem)`
  display: flex;
`;

const Affiliation = styled.div`
  ${typography.textNote};
  max-width: 278px;
  overflow: hidden;
  color: ${theme.textSecondary};
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const SupporterLabel = styled.div`
  ${typography.textNote}
  display: inline-block;
  margin-left: 8px;
  padding-left: 8px;
  border-left: 1px solid ${theme.textSecondary};
  color: ${theme.textSecondary};
  white-space: nowrap;
`;

const LastDate = styled.div`
  ${typography.textNote}
  display: flex;
  align-items: center;
  height: 41px;
  color: ${theme.textSecondary};
`;

const formatInnovatorAffiliation = (innovator: ChatUser['innovator']) => {
  const employmentType = getEmploymentType(
    innovator?.career?.preferredEmploymentType?.id,
    !!innovator?.newGraduate,
  );
  if (!employmentType) return '';
  const label = EMPLOYMENT_TYPE_LABEL[employmentType];
  if (innovator?.career) {
    return `${label}: ${innovator.career.companyName}`;
  }
  if (innovator?.newGraduate) {
    const { academicBackground } = innovator.newGraduate;
    return `${label}: ${academicBackground?.school?.name} ${academicBackground?.faculty}`;
  }
  return '';
};

type ChatRoomCardProps = {
  chatRoom: ChatRoom;
  selected: boolean;
  onClick: (e: React.MouseEvent<HTMLDivElement>, chatRoom: ChatRoom) => void;
  refToElement?: React.RefObject<HTMLDivElement>;
  style?: CSSProperties;
  onClickInnovatorIcon: (innovator: ChatUser['innovator']) => void;
};

const ChatRoomCard: FC<ChatRoomCardProps> = props => {
  const {
    chatRoom,
    onClick,
    selected,
    refToElement,
    onClickInnovatorIcon,
  } = props;
  const { chatUser } = chatRoom;
  const { t } = useTranslation(['chat']);

  const handleClickDefaultUserIcon = useCallback(() => {
    if (chatUser?.innovator) {
      onClickInnovatorIcon(chatUser.innovator);
    }
  }, [chatUser, onClickInnovatorIcon]);

  if (!chatUser) return null;

  return (
    <CardWrapper
      onClick={e => onClick(e, chatRoom)}
      selected={selected}
      ref={refToElement}
      data-testid={`chat-room-card${chatRoom.chatRoomId}`}
    >
      <ColumnIcon>
        {chatUser.iconImageUrl ? (
          <ImageCircle
            diameter="56px"
            imageSrc={chatUser.iconImageUrl}
            border
            data-testid={`chat-room-icon${chatRoom.chatRoomId}`}
          />
        ) : (
          <DefaultUser
            style={{ height: '56px', width: '56px' }}
            onClick={handleClickDefaultUserIcon}
          />
        )}
        {chatUser?.innovator?.deleted && (
          <DeletedUserLabel>{t('label.deletedUser')}</DeletedUserLabel>
        )}
      </ColumnIcon>
      <ColumnInfo style={{ justifyContent: 'start' }}>
        <ItemsRowTop>
          <ChatUserName>{chatUser.name}</ChatUserName>
          <div data-testid={`chat-room-badge${chatRoom.chatRoomId}`}>
            {chatRoom.unreadCount <= 0 ? null : (
              <Badge count={chatRoom.unreadCount} />
            )}
          </div>
        </ItemsRowTop>
        {chatUser?.mission && (
          <ItemsRow>
            <Affiliation>{chatUser.mission?.companyName ?? ''}</Affiliation>
          </ItemsRow>
        )}
        {chatUser?.supporter && (
          <ItemsRow>
            <CompanyWrapper>
              <Affiliation>{chatUser.supporter?.companyName ?? ''}</Affiliation>
              <SupporterLabel>{t('label.supporter')}</SupporterLabel>
            </CompanyWrapper>
          </ItemsRow>
        )}
        {chatUser?.innovator && (
          <ItemsRow>
            <Affiliation>
              {formatInnovatorAffiliation(chatUser?.innovator)}
            </Affiliation>
          </ItemsRow>
        )}
        <Separator />
        <ItemsRowBottom>
          {chatRoom.latestMessage && (
            <LatestMessage
              maxHeight={'36px'}
              width={'221px'}
              line={2}
              backgroundColor={'#fff'}
            >
              {chatRoom.latestMessage}
            </LatestMessage>
          )}
          {chatRoom.latestMessage && (
            <LastDate>{formatTimestamp(chatRoom.updatedAt)}</LastDate>
          )}
        </ItemsRowBottom>
      </ColumnInfo>
    </CardWrapper>
  );
};

export default ChatRoomCard;
