import React, { useMemo } from 'react';
import styled from 'styled-components/macro';
import { useTranslation } from 'react-i18next';
import { ButtonXL, Wall } from 'components/atoms';
import { imageSelector } from 'utils/imageSelector';
import ImageTypes from 'constants/imageTypes';
import { isCompanyCorporateAttribute } from 'utils/models/mission';
import MissionFormHeader from '../../MissionFormHeader';
import MissionFormFooter from '../../MissionFormFooter';
import MissionPreview from '../../MissionPreview';
import {
  CompanyNameField,
  CompanyUrlField,
  IconImageField,
  AddressField,
  FundField,
  CompanyEstablishedDateField,
} from '../../../Fields';
import {
  ImageFileSizeExceededModal,
  SaveConfirmationModal,
  NoInputMissionConfirmationModal,
  InvalidToSaveModal,
  SaveCompleteModal,
  PublishMissionConfirmationModal,
  PublishCompleteModal,
  InvalidToPublishModal,
  UnpublishConfirmationModal,
  UnpublishCompleteModal,
  ImageCropperModal,
  LeavePageConfirmationModal,
} from '../../../Modals';
import useStepCompany from './useStepCompany';

const Form = styled.form``;

const Layout = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin-bottom: -48px;
  column-gap: 64px;
`;

const StepCompanyWall = styled(Wall)`
  padding-top: 26px;
`;

const StepCompany: React.VFC = () => {
  const { t } = useTranslation('missionForm');
  const {
    handleClickNext,
    mission,
    isPublished,
    isFirstPublished,
    formik,
    formError,
    handleChangeSelectedValueAsNumber,
    form,
    handleChangeOtherAddress,
    handleChangeCompanyEstablishedYear,
    openImageCropper,
    imageCropperState,
    cropperAspectRatio,
    handleCroppedImage,
    handleExceedImageFileSize,
    handleClickCropperCloseButton,
    modalState,
    closeModal,
    handleClickSave,
    validateToSaveErrors,
    handleClickSaveConfirmationOK,
    handleClickPublish,
    handleClickPublishConfirmationOk,
    handleClickUnPublishConfirmationOk,
    validateToPublishStepsResult,
    openPreview,
    canUseMissionFormStepMissionJobOffers,
    canUseMissionFormStepMissionDemand,
  } = useStepCompany();

  const {
    values,
    handleChange,
    handleBlur,
    dirty,
    setFieldValue,
    setFieldTouched,
  } = formik;

  return useMemo(
    () => (
      <>
        <MissionFormHeader
          isDisabledSaveButton={!dirty}
          isPublished={isPublished}
          onClickPreview={openPreview}
          onClickSave={handleClickSave}
          type="required"
          title={t('header.company.title')}
        />

        <Form data-testid="mission-form-step-company">
          <StepCompanyWall color="baseWhite">
            <Layout>
              <div>
                <CompanyNameField
                  value={values.companyName}
                  error={formError('companyName')}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />

                {isCompanyCorporateAttribute(mission?.corporateAttributes) && (
                  <CompanyEstablishedDateField
                    values={values.companyEstablishedDate}
                    onChangeYear={handleChangeCompanyEstablishedYear}
                    onChangeMonth={handleChangeSelectedValueAsNumber}
                    onBlur={handleBlur}
                  />
                )}

                <AddressField
                  values={{
                    countryId: values.countryId,
                    japanPrefectureId: values.japanPrefectureId,
                    otherAddress: values.otherAddress,
                  }}
                  errors={{
                    countryId: formError('countryId'),
                    japanPrefectureId: formError('japanPrefectureId'),
                    otherAddress: formError('otherAddress'),
                  }}
                  form={{
                    countries: form.countries,
                    japanPrefectures: form.japanPrefectures,
                  }}
                  onChangeCountry={handleChange}
                  onChangeJapanPrefecture={handleChangeSelectedValueAsNumber}
                  onChangeOtherAddress={handleChangeOtherAddress}
                  onBlur={handleBlur}
                />

                <CompanyUrlField
                  value={values.companyUrl}
                  error={formError('companyUrl')}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              </div>

              <div>
                <IconImageField
                  imageSrc={imageSelector(values.iconImage, 'small')}
                  error={formError('iconImage')}
                  onUpload={uploadedImage => {
                    openImageCropper(ImageTypes.ICON, uploadedImage);
                  }}
                  onExceededFileSizeLimit={handleExceedImageFileSize}
                />

                <FundField
                  value={values.fundId}
                  error={formError('fundId')}
                  form={form.funds}
                  onChange={handleChangeSelectedValueAsNumber}
                  onBlur={handleBlur}
                />
              </div>
            </Layout>
          </StepCompanyWall>
        </Form>

        <MissionFormFooter
          buttons={
            isFirstPublished
              ? [
                  <ButtonXL
                    type="button"
                    buttonTheme="gradation"
                    onClick={handleClickPublish}
                    key="publish"
                    disabled={isPublished ? !dirty : false}
                  >
                    {t('button.publish')}
                  </ButtonXL>,
                ]
              : [
                  <ButtonXL
                    type="button"
                    buttonTheme="default"
                    onClick={handleClickNext}
                    key="go-next"
                  >
                    {t('button.next')}
                  </ButtonXL>,
                ]
          }
        />

        <MissionPreview
          values={values}
          step="company"
          forInnovator={
            !canUseMissionFormStepMissionDemand &&
            canUseMissionFormStepMissionJobOffers
          }
        />

        <ImageFileSizeExceededModal
          show={
            modalState.show && modalState.content === 'imageFileSizeExceeded'
          }
          closeModal={closeModal}
        />
        <SaveConfirmationModal
          show={
            modalState.show && modalState.content === 'saveMissionConfirmation'
          }
          onOk={handleClickSaveConfirmationOK}
          onCancel={closeModal}
        />
        <NoInputMissionConfirmationModal
          show={
            modalState.show &&
            modalState.content === 'saveRequiredNoInputConfirmation'
          }
          onCancel={closeModal}
          onOk={handleClickSaveConfirmationOK}
          isPublished={isPublished}
          stepsResult={validateToPublishStepsResult}
          canUseStepJobOffers={canUseMissionFormStepMissionJobOffers}
          canUseStepMissionDemand={canUseMissionFormStepMissionDemand}
        />
        <InvalidToSaveModal
          show={modalState.show && modalState.content === 'invalidToSave'}
          onClose={closeModal}
          errors={validateToSaveErrors}
        />
        <SaveCompleteModal
          show={
            modalState.show && modalState.content === 'saveMissionCompleted'
          }
          onClose={closeModal}
        />
        <InvalidToPublishModal
          show={modalState.show && modalState.content === 'invalidToPublish'}
          onClose={closeModal}
          stepsResult={validateToPublishStepsResult}
          canUseStepJobOffers={canUseMissionFormStepMissionJobOffers}
          canUseStepMissionDemand={canUseMissionFormStepMissionDemand}
        />
        <PublishMissionConfirmationModal
          show={
            modalState.show &&
            modalState.content === 'publishMissionConfirmation'
          }
          onOk={handleClickPublishConfirmationOk}
          onCancel={closeModal}
        />
        <PublishCompleteModal
          show={
            modalState.show && modalState.content === 'publishMissionCompleted'
          }
          onClose={closeModal}
        />
        <UnpublishConfirmationModal
          show={
            modalState.show &&
            modalState.content === 'unPublishMissionConfirmation'
          }
          onOk={handleClickUnPublishConfirmationOk}
          onCancel={closeModal}
        />
        <UnpublishCompleteModal
          show={
            modalState.show &&
            modalState.content === 'unPublishMissionCompleted'
          }
          onClose={closeModal}
        />
        <ImageCropperModal
          imageCropperState={imageCropperState}
          aspectRatio={cropperAspectRatio}
          onCroppedImage={croppedImage =>
            handleCroppedImage({ croppedImage, setFieldValue, setFieldTouched })
          }
          handleClickCropperCloseButton={type =>
            handleClickCropperCloseButton({ type, setFieldTouched })
          }
        />
        <LeavePageConfirmationModal when={dirty} />
      </>
    ),
    [
      canUseMissionFormStepMissionDemand,
      canUseMissionFormStepMissionJobOffers,
      closeModal,
      cropperAspectRatio,
      dirty,
      form.countries,
      form.funds,
      form.japanPrefectures,
      formError,
      handleBlur,
      handleChange,
      handleChangeCompanyEstablishedYear,
      handleChangeOtherAddress,
      handleChangeSelectedValueAsNumber,
      handleClickCropperCloseButton,
      handleClickNext,
      handleClickPublish,
      handleClickPublishConfirmationOk,
      handleClickSave,
      handleClickSaveConfirmationOK,
      handleClickUnPublishConfirmationOk,
      handleCroppedImage,
      handleExceedImageFileSize,
      imageCropperState,
      isFirstPublished,
      isPublished,
      mission,
      modalState.content,
      modalState.show,
      openImageCropper,
      openPreview,
      setFieldTouched,
      setFieldValue,
      t,
      validateToPublishStepsResult,
      validateToSaveErrors,
      values,
    ],
  );
};

export default StepCompany;
