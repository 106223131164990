import React from 'react';
import styled from 'styled-components/macro';
import { useTranslation } from 'react-i18next';
import theme from 'styles/theme';
import { Checkbox, ButtonS, About, ErrorMessage } from 'components/atoms';
import { MAX_BULK_SCOUT_HUMAN_RESOURCES } from 'constants/models/scout';

const Head = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 864px;
  margin-left: 32px;
  padding-top: 24px;
  padding-right: 16px;
`;

const HeadLeft = styled.div`
  display: flex;
  align-items: center;
  gap: 18px;
`;

const HeadRight = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
`;

const SelectAllCheckBox = styled(Checkbox)`
  &&& {
    min-width: auto;
    margin-right: 0;
    margin-bottom: 0;
  }
`;

const SelectAllCheckBoxLabel = styled.span`
  color: ${theme.basePrimary};
  font-size: 11px;
  line-height: 1;
`;

const TotalCount = styled.p`
  color: ${theme.textPrimaryHover};
  font-size: 11px;
  line-height: 1;
`;

type Props = {
  totalCount: number | undefined;
  selectedCount: number;
  onClickAbout: () => void;
  isCheckedSelectAll: boolean;
  onChangeSelectAll: (e: React.ChangeEvent<HTMLInputElement>) => void;
  selectAllCheckBoxRef: React.RefObject<HTMLInputElement>;
  onClickBulkScoutButton: () => void;
  isDisabledBulkScoutButton: boolean;
  showExceedMaxBulkScoutError: boolean;
};

const HumanResourceListHead: React.VFC<Props> = ({
  totalCount,
  selectedCount,
  onClickAbout,
  isCheckedSelectAll,
  onChangeSelectAll,
  selectAllCheckBoxRef,
  isDisabledBulkScoutButton,
  onClickBulkScoutButton,
  showExceedMaxBulkScoutError,
}) => {
  const { t } = useTranslation(['humanResource', 'error']);

  return (
    <Head>
      <HeadLeft>
        <SelectAllCheckBox
          name="selactAll"
          checked={isCheckedSelectAll}
          onChange={onChangeSelectAll}
          ref={selectAllCheckBoxRef}
          aria-label={t('checkbox.selectAll', { lng: 'ja' })}
        >
          <SelectAllCheckBoxLabel data-testid="select-all-checkbox-label">
            {selectedCount > 0
              ? t('checkbox.selectedCount', { lng: 'ja', selectedCount })
              : t('checkbox.selectAll', { lng: 'ja' })}
          </SelectAllCheckBoxLabel>
        </SelectAllCheckBox>

        <ButtonS
          onClick={onClickBulkScoutButton}
          buttonTheme="default"
          type="button"
          disabled={isDisabledBulkScoutButton}
        >
          {t('button.bulkScout', { lng: 'ja' })}
        </ButtonS>

        {showExceedMaxBulkScoutError && (
          <ErrorMessage data-testid="error-exceeded-max-bulk-scout">
            {t('error:exceededMaxBulkScout', {
              lng: 'ja',
              maxCount: MAX_BULK_SCOUT_HUMAN_RESOURCES,
            })}
          </ErrorMessage>
        )}
      </HeadLeft>
      <HeadRight>
        {totalCount !== undefined && (
          <TotalCount data-testid="human-resources-total-count">
            {t('totalCount', { lng: 'ja', totalCount })}
          </TotalCount>
        )}
        <About
          text={t('aboutScout', { lng: 'ja' })}
          onClick={onClickAbout}
          data-testid="about-scout"
        />
      </HeadRight>
    </Head>
  );
};

export default HumanResourceListHead;
