import React from 'react';
import styled from 'styled-components/macro';
import theme from 'styles/theme';
import typography from 'styles/typography';
import { isEqualProps } from 'utils/object';
import { useTranslation } from 'react-i18next';
import {
  Heading,
  FlexContainer,
  ButtonContainer,
  ButtonS,
  Tag,
  WrapText,
} from 'components/atoms';

const Header = styled.div`
  width: 1035px;
  margin-bottom: 32px;
`;

const TitleContainer = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 12px;
`;

const PublishStatusTag = styled(Tag)`
  min-width: 58px;
  margin: 0;
  padding: 4px 10px 2px 10px;
  color: ${theme.baseWhite};
  font-weight: bold;
`;

const FieldTypeTag = styled(Tag)`
  ${typography.labelBold}
  min-width: auto;
  margin: 2px 4px 0 0;
  padding: 3px 8px;
  border-radius: 18px;
  color: ${theme.baseWhite};
`;

const Description = styled(WrapText)`
  line-height: 1.3;
`;

const HeaderButton = styled(ButtonS)`
  width: 102px;
`;

type Props = {
  isDisabledSaveButton: boolean;
  isPublished: boolean;
  onClickPreview: () => void;
  onClickSave: () => void;
  type: 'required' | 'optional';
  title: string;
  description?: string;
};

const MissionFormHeader: React.VFC<Props> = ({
  onClickPreview,
  onClickSave,
  isDisabledSaveButton,
  isPublished,
  type,
  title,
  description,
}) => {
  const { t } = useTranslation(['missionSetting', 'common']);

  const tag = {
    required: {
      color: 'labelRequired',
      label: t('tag.required'),
    },
    optional: {
      color: 'labelOptional',
      label: t('tag.optional'),
    },
  }[type];

  return (
    <Header>
      <FlexContainer
        flexWrap="no-wrap"
        justifyContent="space-between"
        style={{ marginBottom: '20px' }}
        alignItems="center"
      >
        <FlexContainer alignItems="center">
          <PublishStatusTag
            data-testid="publish-status-tag"
            round
            color={isPublished ? 'labelBadge' : 'baseDisabled'}
          >
            {isPublished ? t('tag.published') : t('tag.unPublished')}
          </PublishStatusTag>
        </FlexContainer>
      </FlexContainer>

      <FlexContainer flexWrap="wrap" justifyContent="space-between">
        <TitleContainer>
          <FieldTypeTag round color={tag.color}>
            {tag.label}
          </FieldTypeTag>

          <div>
            <Heading
              level="h2"
              contents="private"
              style={{ whiteSpace: 'pre-wrap' }}
            >
              {title}
            </Heading>
            {description && <Description>{description}</Description>}
          </div>
        </TitleContainer>

        <div style={{ alignSelf: 'flex-end' }}>
          <ButtonContainer align="right">
            <HeaderButton
              onClick={onClickPreview}
              buttonTheme="default"
              type="button"
            >
              {t('button.preview')}
            </HeaderButton>
            <HeaderButton
              buttonTheme="default"
              type="button"
              onClick={onClickSave}
              disabled={isDisabledSaveButton}
              data-testid="save-mission"
            >
              {t('common:button.save')}
            </HeaderButton>
          </ButtonContainer>
        </div>
      </FlexContainer>
    </Header>
  );
};

export default React.memo(MissionFormHeader, isEqualProps);
