import { useCallback } from 'react';
import ImageTypes from 'constants/imageTypes';
import useImageUploader from '../../hooks/useImageUploader';
import useImageCropper from '../../hooks/useImageCropper';
import { MissionFormFormik } from '../useMissionFormFormik';
import { ModalContext } from '../../hooks/useModal';

const getImageFieldByImageType = (type?: ImageTypes, index?: number) => {
  switch (type) {
    case ImageTypes.COVER:
      return 'coverImage';
    case ImageTypes.ICON:
      return 'iconImage';
    case ImageTypes.MEMBER_ICON:
      return `missionMembers[${index}].iconImage`;
    case ImageTypes.OFFICE:
      return `missionOfficeImages[${index}].image`;
    case ImageTypes.PRODUCT:
      return `missionProducts[${index}].image`;
  }
};

type Payload = {
  openModal: ModalContext['openModal'];
};

const useMissionFormImage = ({ openModal }: Payload) => {
  const {
    imageCropperState,
    closeImageCropper,
    openImageCropper,
    saveImageCropper,
    cropperAspectRatio,
  } = useImageCropper();
  const { uploadImage } = useImageUploader();

  const handleExceedImageFileSize = useCallback(() => {
    openModal('imageFileSizeExceeded');
  }, [openModal]);

  const handleCroppedImage = useCallback(
    async ({
      croppedImage,
      setFieldValue,
      setFieldTouched,
    }: {
      croppedImage: string;
      setFieldValue: MissionFormFormik['setFieldValue'];
      setFieldTouched: MissionFormFormik['setFieldTouched'];
    }) => {
      const { type, index, field } = imageCropperState;
      if (!type) return;
      saveImageCropper(croppedImage);
      const data = await uploadImage({
        type,
        data: croppedImage,
      });
      if (data) {
        const { images, key } = data;
        const imageField = field || getImageFieldByImageType(type, index);
        if (!imageField) return;
        setFieldValue(imageField, {
          ...images,
          key,
        });
        setFieldTouched(imageField, true, false);
        closeImageCropper();
      }
    },
    [closeImageCropper, imageCropperState, saveImageCropper, uploadImage],
  );

  const handleClickCropperCloseButton = useCallback(
    ({
      type,
      index,
      setFieldTouched,
    }: {
      type?: ImageTypes;
      index?: number;
      setFieldTouched: MissionFormFormik['setFieldTouched'];
    }) => {
      const { field } = imageCropperState;
      closeImageCropper();
      const imageField = field || getImageFieldByImageType(type, index);
      if (!imageField) return;
      setFieldTouched(imageField, true);
    },
    [closeImageCropper, imageCropperState],
  );

  return {
    imageCropperState,
    closeImageCropper,
    openImageCropper,
    saveImageCropper,
    cropperAspectRatio,
    handleExceedImageFileSize,
    handleCroppedImage,
    handleClickCropperCloseButton,
  };
};

export default useMissionFormImage;
