import React, { FC, ChangeEvent } from 'react';
import { useTranslation } from 'react-i18next';
import { MissionProductFormValue } from 'types/form';
import { isEqualProps } from 'utils/object';
import { MissionSettingMaxLength } from 'constants/form';
import { TextInput } from 'components/atoms';
import {
  FormItemSubHeading,
  MissionSettingFormItem,
} from 'components/molecules';

type NameFieldProps = {
  index: number;
  value: MissionProductFormValue['name'];
  error: string | undefined;
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
  onBlur: (e: any) => void;
};

const NameField: FC<NameFieldProps> = props => {
  const { index, value, error, onChange, onBlur } = props;

  const { t } = useTranslation('missionSetting');

  return (
    <MissionSettingFormItem error={error}>
      <FormItemSubHeading
        label={t('field.missionProducts.subField.name.label')}
        note={t('field.missionProducts.subField.name.note', {
          maxLength: MissionSettingMaxLength.MISSION_PRODUCT_NAME,
        })}
      />
      <TextInput
        maxLength={MissionSettingMaxLength.MISSION_PRODUCT_NAME}
        name={`missionProducts[${index}].name`}
        type="text"
        value={value}
        placeholder={t('field.missionProducts.subField.name.placeholder')}
        onChange={onChange}
        onBlur={onBlur}
        outline
        aria-label={t('field.missionProducts.subField.name.label')}
      />
    </MissionSettingFormItem>
  );
};

export default React.memo(NameField, (prevProps, nextProps) =>
  isEqualProps(prevProps, nextProps, ['value', 'error']),
);
