import React, { FC, ChangeEvent } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components/macro';
import { isEqualProps } from 'utils/object';
import { Checkbox, FlexContainer, Note } from 'components/atoms';
import { FormItem, MissionDemandFieldItem } from 'components/molecules';
import typography from 'styles/typography';

const WantInvestField = styled(FormItem)`
  &&& {
    width: 250px;
    margin-bottom: 0;
    margin-left: 29px;
  }
`;

const Label = styled.p`
  ${typography.textDefault}
  margin-bottom: 5px;
`;

const InvestNote = styled(Note)`
  margin-left: 4px;
`;

type InvestFieldProps = {
  wantInvest: boolean;
  wantInvestSeed: boolean;
  wantInvestEarly: boolean;
  wantInvestMiddle: boolean;
  wantInvestLater: boolean;
  onChangeWantInvest: (e: ChangeEvent<HTMLInputElement>) => void;
  onChangeChecked: (e: ChangeEvent<HTMLInputElement>) => void;
  error: any;
};

const InvestField: FC<InvestFieldProps> = props => {
  const {
    wantInvest,
    wantInvestSeed,
    wantInvestEarly,
    wantInvestMiddle,
    wantInvestLater,
    onChangeWantInvest,
    onChangeChecked,
    error,
  } = props;

  const { t } = useTranslation('missionSetting');

  return (
    <MissionDemandFieldItem>
      <Checkbox
        name="missionDemand.wantInvest"
        checked={wantInvest}
        onChange={onChangeWantInvest}
        aria-label={t('field.missionDemand.checkbox.wantInvest')}
      >
        {t('field.missionDemand.checkbox.wantInvest')}
        <InvestNote note={t('field.missionDemand.wantInvest.note')} />
      </Checkbox>

      {wantInvest && (
        <WantInvestField error={error}>
          <Label>{t('field.missionDemand.wantInvest.label')}</Label>
          <FlexContainer>
            <Checkbox
              name="missionDemand.wantInvestSeed"
              onChange={onChangeChecked}
              checked={wantInvestSeed}
              disabled={!wantInvest}
              aria-label={t('field.missionDemand.checkbox.wantInvestSeed')}
            >
              {t('field.missionDemand.checkbox.wantInvestSeed')}
            </Checkbox>
            <Checkbox
              name="missionDemand.wantInvestMiddle"
              onChange={onChangeChecked}
              checked={wantInvestMiddle}
              disabled={!wantInvest}
              aria-label={t('field.missionDemand.checkbox.wantInvestMiddle')}
            >
              {t('field.missionDemand.checkbox.wantInvestMiddle')}
            </Checkbox>
            <Checkbox
              name="missionDemand.wantInvestEarly"
              onChange={onChangeChecked}
              checked={wantInvestEarly}
              disabled={!wantInvest}
              aria-label={t('field.missionDemand.checkbox.wantInvestEarly')}
            >
              {t('field.missionDemand.checkbox.wantInvestEarly')}
            </Checkbox>
            <Checkbox
              name="missionDemand.wantInvestLater"
              onChange={onChangeChecked}
              checked={wantInvestLater}
              disabled={!wantInvest}
              aria-label={t('field.missionDemand.checkbox.wantInvestLater')}
            >
              {t('field.missionDemand.checkbox.wantInvestLater')}
            </Checkbox>
          </FlexContainer>
        </WantInvestField>
      )}
    </MissionDemandFieldItem>
  );
};

export default React.memo(InvestField, (prevProps, nextProps) =>
  isEqualProps(prevProps, nextProps, [
    'wantInvest',
    'wantInvestEarly',
    'wantInvestSeed',
    'wantInvestMiddle',
    'wantInvestLater',
    'error',
  ]),
);
