import React, {
  useState,
  useRef,
  useEffect,
  useCallback,
  RefObject,
} from 'react';
import throttle from 'lodash.throttle';
import { useHistory } from 'react-router-dom';
import { MISSION_PRODUCTS_MAX_COUNT } from 'constants/config';
import { userLanguage } from 'i18n/i18n';
import { Mission } from 'proto/v1/apimodel/apimodel';

const ABOUT_MISSION_TAB_INDEX = 0;
const PRODUCT_TAB_INDEX = 1;

const useMissionDetail = ({
  mission,
  onNotFoundProduct,
}: {
  mission: Mission | undefined;
  onNotFoundProduct?: () => void;
}) => {
  const history = useHistory();
  const detailContainerRef = useRef<HTMLDivElement>(null);
  const [visibleFooterTargetRef, setVisibleFooterTargetRef] = useState<
    HTMLButtonElement | HTMLDivElement | null
  >(null);
  const [selectedTabIndex, setSelectedTabIndex] = useState<number>(
    ABOUT_MISSION_TAB_INDEX,
  );
  const [showFooter, setShowFooter] = useState<boolean>(false);
  const [clickedProductCardIndex, setClickedProductCardIndex] = useState<
    null | number
  >(null);
  const [isScrolledToTargetProduct, setIsScrolledToTargetProduct] = useState<
    boolean
  >(false);
  const productDetailRefs = useRef<RefObject<HTMLDivElement>[]>(
    [...Array(MISSION_PRODUCTS_MAX_COUNT)].map(() => React.createRef()),
  ).current;

  const showTranslateButton = userLanguage !== mission?.language;

  const shouldShowFooter = useCallback(() => {
    if (!visibleFooterTargetRef) return false;
    return visibleFooterTargetRef.getBoundingClientRect().bottom < 0;
  }, [visibleFooterTargetRef]);

  const handleScroll = useCallback(
    throttle(() => {
      setShowFooter(shouldShowFooter());
    }, 50),
    [shouldShowFooter],
  );

  const handleTabSelect = useCallback((tabIndex: number) => {
    setSelectedTabIndex(tabIndex);
  }, []);

  const handleClickProductCard = useCallback((productIndex: number) => {
    setSelectedTabIndex(PRODUCT_TAB_INDEX);
    setClickedProductCardIndex(productIndex);
  }, []);

  const scrollTargetProductId = history.location.hash.slice(1);

  useEffect(() => {
    const detailContainer = detailContainerRef.current;
    if (!scrollTargetProductId && mission && detailContainer) {
      setSelectedTabIndex(ABOUT_MISSION_TAB_INDEX);
      detailContainer.scrollTop = 0;
    }
  }, [mission, scrollTargetProductId]);

  // Handle click product cards
  useEffect(() => {
    if (clickedProductCardIndex === null) return;
    const productDetailElement =
      productDetailRefs[clickedProductCardIndex].current;
    productDetailElement?.scrollIntoView();
    setClickedProductCardIndex(null);
  }, [clickedProductCardIndex, productDetailRefs]);

  // Scroll to target product card when it is displayed from the product list
  useEffect(() => {
    if (!scrollTargetProductId || isScrolledToTargetProduct) return;
    setSelectedTabIndex(PRODUCT_TAB_INDEX);
    const scrollTargetProductIndex = mission?.missionProducts?.findIndex(
      product => `${product.missionProductId}` === scrollTargetProductId,
    );
    if (
      scrollTargetProductIndex === undefined ||
      scrollTargetProductIndex === -1
    ) {
      onNotFoundProduct && onNotFoundProduct();
      return;
    }
    const productDetailElement =
      productDetailRefs[scrollTargetProductIndex].current;
    if (!productDetailElement) return;
    productDetailElement?.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
    });
    setIsScrolledToTargetProduct(true);
  }, [
    isScrolledToTargetProduct,
    mission,
    onNotFoundProduct,
    productDetailRefs,
    scrollTargetProductId,
  ]);

  return {
    detailContainerRef,
    selectedTabIndex,
    showFooter,
    showTranslateButton,
    handleTabSelect,
    handleScroll,
    handleClickProductCard,
    productDetailRefs,
    setVisibleFooterTargetRef,
  };
};

export default useMissionDetail;
