import styled from 'styled-components/macro';

const TableBody = styled.tbody`
  display: block;
  width: 100%;
  height: calc(100% - 48px);
  overflow-x: hidden;
  overflow-y: auto;
`;
export default TableBody;
