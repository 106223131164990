import React, { ComponentProps } from 'react';
import styled from 'styled-components/macro';
import { Spinner } from 'visits-style';

type Props = ComponentProps<typeof Spinner>;

const Container = styled.div`
  /* need fixed height on spinning */
  display: flex;
  justify-content: center;
  overflow: hidden;
`;

const SpinnerWrap = styled.div`
  /* need fixed height on spinning */
  height: ${({ size }: Props) => size};
  overflow: hidden;
`;

export default function ItemLoading(props: Props) {
  return (
    <Container>
      <SpinnerWrap {...props}>
        <Spinner {...props} />
      </SpinnerWrap>
    </Container>
  );
}
