import React, { FC, HTMLAttributes } from 'react';
import styled from 'styled-components/macro';
import { Notice } from 'assets/svg';
import theme from 'styles/theme';
import typography from 'styles/typography';

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
`;

const Text = styled.p`
  ${typography.textSub}
  margin-left: 4px;
  color: ${theme.textSecondary};
`;

type AboutProps = { text: string } & HTMLAttributes<HTMLDivElement>;

const About: FC<AboutProps> = ({ text, onClick, ...rest }) => (
  <Wrapper onClick={onClick} {...rest}>
    <Notice />
    <Text>{text}</Text>
  </Wrapper>
);

export default About;
