import React, { FC, CSSProperties } from 'react';
import styled from 'styled-components/macro';
import typography from 'styles/typography';
import theme from 'styles/theme';
import { Present } from 'assets/svg';
import { useTranslation } from 'react-i18next';

const Wrapper = styled.div`
  display: flex;
  align-items: end; /* centerだと解像度やブラウザにより上下にずれるケースがあるためendにする */
  color: ${theme.labelRequired};
`;

const IconMemberBenefit = styled(Present)`
  width: 14px;
  height: 16px;
  margin-right: 4px;
`;

const TextMemberBenefit = styled.span<{ lng: string }>`
  ${typography.textSub}
  ${({ lng }) => !lng.startsWith('ja') && 'font-size: 11px;'}
  font-weight: bold;
`;

type MemberBenefitProps = {
  style?: CSSProperties;
};

const MemberBenefit: FC<MemberBenefitProps> = props => {
  const { t, i18n } = useTranslation('common');
  return (
    <Wrapper {...props}>
      <IconMemberBenefit />
      <TextMemberBenefit lng={i18n.language}>
        {t('component.memberBenefit')}
      </TextMemberBenefit>
    </Wrapper>
  );
};

export default MemberBenefit;
