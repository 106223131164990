import React from 'react';
import styled from 'styled-components/macro';
import { useTranslation } from 'react-i18next';
import { ChangeNotificationFormValues } from 'types/account';
import { Heading, Wall, ButtonS, Radio } from 'components/atoms';
import { ConfirmModal } from 'components/molecules';
import useChangeNotificationForm from './useChangeNotificationForm';

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 16px;
  padding-top: 16px;
`;

const Row = styled.div`
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 24px;
`;

const RadioSet = styled.div`
  display: flex;

  /* stylelint-disable-next-line selector-type-no-unknown */
  ${Radio}:not(:last-child) {
    margin-right: 13.5px;
  }

  /* stylelint-disable-next-line selector-type-no-unknown */
  ${Radio}:last-child label {
    margin-right: 0;
  }
`;

const Description = styled.p`
  line-height: 1.5;
`;

type ChangeNotificationFormProps = {
  initialValues: ChangeNotificationFormValues;
  onSubmitDone: () => Promise<void>;
};

export default function ChangeNotificationForm({
  initialValues,
  onSubmitDone,
}: ChangeNotificationFormProps) {
  const { t } = useTranslation(['accountSetting', 'common']);
  const {
    values,
    handleSubmit,
    isValid,
    dirty,
    submitDone,
    setSubmitDone,
    submitConfirm,
    setSubmitConfirm,
    handleChangeRadioChecked,
    canUsePublishedMissionNotification,
  } = useChangeNotificationForm({
    initialValues,
    onSubmitDone,
  });

  return (
    <>
      <Container>
        <Heading level="h2" contents="private">
          {t('form.changeNotification.title')}
        </Heading>
        <ButtonS
          onClick={() => setSubmitConfirm(!submitConfirm)}
          buttonTheme="default"
          type="button"
          disabled={!dirty || !isValid}
          data-testid="change-notification-submit-button"
        >
          {t('button.submit')}
        </ButtonS>
      </Container>
      <Wall color="baseWhite">
        <Heading style={{ marginBottom: '24px' }} level="h3" contents="private">
          {t('form.changeNotification.field.notificationDisabled.label')}
        </Heading>

        <Row>
          <Description>
            {t('form.changeNotification.description.notificationDisabled')}
          </Description>
          <RadioSet>
            <Radio
              name="notificationDisabled"
              value={0}
              checked={!values.notificationDisabled}
              onChange={handleChangeRadioChecked}
              aria-label={t(
                'form.changeNotification.field.notificationDisabled.radio.receive',
              )}
            >
              {t(
                'form.changeNotification.field.notificationDisabled.radio.receive',
              )}
            </Radio>
            <Radio
              name="notificationDisabled"
              value={1}
              checked={values.notificationDisabled}
              onChange={handleChangeRadioChecked}
              aria-label={t(
                'form.changeNotification.field.notificationDisabled.radio.notReceive',
              )}
            >
              {t(
                'form.changeNotification.field.notificationDisabled.radio.notReceive',
              )}
            </Radio>
          </RadioSet>
        </Row>

        {canUsePublishedMissionNotification && (
          <Row>
            <Description>
              {t(
                'form.changeNotification.description.publishedMissionNotificationDisabled',
              )}
            </Description>
            <RadioSet>
              <Radio
                name="publishedMissionNotificationDisabled"
                value={0}
                checked={!values.publishedMissionNotificationDisabled}
                onChange={handleChangeRadioChecked}
                aria-label={t(
                  'form.changeNotification.field.publishedMissionNotificationDisabled.radio.receive',
                )}
              >
                {t(
                  'form.changeNotification.field.publishedMissionNotificationDisabled.radio.receive',
                )}
              </Radio>
              <Radio
                name="publishedMissionNotificationDisabled"
                value={1}
                checked={values.publishedMissionNotificationDisabled}
                onChange={handleChangeRadioChecked}
                aria-label={t(
                  'form.changeNotification.field.publishedMissionNotificationDisabled.radio.notReceive',
                )}
              >
                {t(
                  'form.changeNotification.field.publishedMissionNotificationDisabled.radio.notReceive',
                )}
              </Radio>
            </RadioSet>
          </Row>
        )}
      </Wall>
      <ConfirmModal
        show={submitConfirm}
        confirmCaption={t('common:button.ok')}
        confirm={() => {
          handleSubmit();
        }}
        closeModal={() => setSubmitConfirm(!submitConfirm)}
      >
        <p data-testid="modal-confirm-change-notification">
          {t('form.changeNotification.modal.confirmChangeNotification.content')}
        </p>
      </ConfirmModal>

      <ConfirmModal
        show={submitDone}
        confirmCaption={t('common:button.close')}
        confirm={() => {
          setSubmitDone(!submitDone);
        }}
        closeModal={() => {
          setSubmitDone(!submitDone);
        }}
      >
        <p data-testid="modal-success-change-notification">
          {t('form.changeNotification.modal.successChangeNotification.content')}
        </p>
      </ConfirmModal>
    </>
  );
}
