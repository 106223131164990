import styled, { css } from 'styled-components/macro';

/* stylelint-disable value-no-vendor-prefix */
/* stylelint-disable property-no-vendor-prefix */

type EllipsisBoxProps = {
  line: number;
  maxHeight: string;
  width: string;
  backgroundColor?: string;
};
const EllipsisBox = styled.div<EllipsisBoxProps>`
  display: block;
  position: relative;
  ${({ width }) =>
    width &&
    css`
      width: ${width};
    `};
  max-height: ${({ maxHeight }) => maxHeight};
  overflow: hidden;
  background-color: ${({ backgroundColor }) => backgroundColor ?? 'inherit'};
  word-wrap: break-word;
  white-space: normal;

  &:hover {
    background-color: ${({ backgroundColor }) => backgroundColor ?? 'inherit'};
  }

  &::before {
    content: '\\2026';
    position: absolute;
    right: 0;
    bottom: 0;
    background-color: inherit;
  }

  &::after {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: inherit;
  }

  @supports (-webkit-line-clamp: ${({ line }) => line}) {
    display: -webkit-box;
    text-overflow: ellipsis;
    white-space: normal;
    -webkit-line-clamp: ${({ line }) => line};
    -webkit-box-orient: vertical;

    &::before {
      display: none;
    }

    &::after {
      display: none;
    }
  }
`;

export default EllipsisBox;
