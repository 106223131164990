import {
  useState,
  useMemo,
  useCallback,
  useRef,
  createRef,
  RefObject,
} from 'react';
import { useSelector } from 'react-redux';
import { getMyMissions } from 'modules/myAccounts';
import {
  getMissionId,
  getPublished,
  getCanUseMissionFormStepMissionJobOffers,
  getCanUseMissionFormStepMissionDemand,
} from 'modules/account';
import { Mission } from 'proto/v1/apimodel/apimodel';
import { MissionForm } from 'types/mission';
import { MISSION_PRODUCTS_MAX_COUNT } from 'constants/config';
import useMyAccounts from 'hooks/useMyAccounts';
import { ValidateToPublishStepsResult } from 'types/form';
import useModal from '../../hooks/useModal';
import useGenerateKey from '../../hooks/useGenerateKey';
import convertMissionToMissionFormValues from '../convertMissionToMissionFormValues';
import { ValidateToSaveErrors } from '../../validate/validateToSave';
import useMissionFormStepper from './useMissionFormStepper';
import useMissionFormMenu from './useMissionFormMenu';
import useFetchMissionForm from './useFetchMissionForm';
import useUpdateMission from './useUpdateMission';
import useMissionFormImage from './useMissionFormImage';

const useMissionForm = () => {
  const myMissions = useSelector(getMyMissions);
  const missionId = useSelector(getMissionId);
  const isPublished = useSelector(getPublished);
  const canUseMissionFormStepMissionJobOffers = useSelector(
    getCanUseMissionFormStepMissionJobOffers,
  );
  const canUseMissionFormStepMissionDemand = useSelector(
    getCanUseMissionFormStepMissionDemand,
  );

  const { modalState, openModal, closeModal } = useModal();

  const {
    changeActiveAccount,
    deleteMission,
    applyLatestAccountInfo,
    fetchMyAccounts,
  } = useMyAccounts();

  const [mission, setMission] = useState<Mission | undefined>(undefined);
  const [form, setForm] = useState<MissionForm>({
    countries: [],
    japanPrefectures: [],
    memberRanges: [],
    missionCategories: [],
    funds: [],
    financingSeries: [],
    occupations: [],
  });
  const [validateToSaveErrors, setValidateToSaveErrors] = useState<
    ValidateToSaveErrors
  >({});
  const [
    validateToPublishStepsResult,
    setValidateToPublishStepsResult,
  ] = useState<ValidateToPublishStepsResult | undefined>(undefined);
  const missionOfficeImagesFieldRef = useRef<HTMLDivElement>(null);
  const [isFirstPublished, setIsFirstPublished] = useState<boolean>(false);
  const [isMissionProductAdded, setMissionProductAdded] = useState<boolean>(
    false,
  );
  const [showPreview, setShowPreview] = useState<boolean>(false);
  const missionProductsFieldRef = useRef<HTMLDivElement>(null);
  const missionProductRefs = useRef<RefObject<HTMLDivElement>[]>(
    [...Array(MISSION_PRODUCTS_MAX_COUNT)].map(() => createRef()),
  ).current;
  const missionDemandFieldRef = useRef<HTMLDivElement>(null);

  const openPreview = useCallback(() => {
    setShowPreview(true);
  }, []);

  const closePreview = useCallback(() => {
    setShowPreview(false);
  }, []);

  const missionAccount = useMemo(
    () => myMissions.find(m => m.missionId === missionId),
    [missionId, myMissions],
  );

  const missionFormValues = useMemo(
    () => mission && convertMissionToMissionFormValues(mission),
    [mission],
  );

  const { fetchMissionForm, isFetchedMissionForm } = useFetchMissionForm({
    setMission,
    setForm,
    setIsFirstPublished,
  });

  const { updateMission, publishMission, unPublishMission } = useUpdateMission({
    setMission,
    fetchMyAccounts,
    openModal,
  });

  const { getAddedItemId } = useGenerateKey();

  const {
    stepperSteps,
    currentStepIndex,
    isFirstStep,
    isFinalStep,
    movePrevStep,
    moveNextStep,
    moveStepTo,
  } = useMissionFormStepper({
    missionFormValues,
    canUseStepJobOffers: canUseMissionFormStepMissionJobOffers,
    canUseStepMissionDemand: canUseMissionFormStepMissionDemand,
  });

  const {
    showMenu,
    openMenu,
    closeMenu,
    handleClickMenuUnPublish,
    handleClickMenuAdd,
    handleClickAddModalOk,
    handleCloseAddCompleteModal,
    handleClickMenuDelete,
    handleClickDeleteModalOk,
    handleCloseDeleteCompleteModal,
  } = useMissionFormMenu({
    openModal,
    closeModal,
    changeActiveAccount,
    fetchMyAccounts,
    deleteMission,
    applyLatestAccountInfo,
  });

  const {
    imageCropperState,
    closeImageCropper,
    openImageCropper,
    saveImageCropper,
    cropperAspectRatio,
    handleExceedImageFileSize,
    handleCroppedImage,
    handleClickCropperCloseButton,
  } = useMissionFormImage({ openModal });

  return {
    mission,
    setMission,
    form,
    setForm,
    isFirstPublished,
    missionFormValues,
    fetchMissionForm,
    isFetchedMissionForm,
    isPublished,
    stepperSteps,
    currentStepIndex,
    isFirstStep,
    isFinalStep,
    movePrevStep,
    moveNextStep,
    updateMission,
    publishMission,
    unPublishMission,
    moveStepTo,
    getAddedItemId,
    modalState,
    openModal,
    closeModal,
    myMissions,
    missionAccount,
    fetchMyAccounts,
    validateToSaveErrors,
    setValidateToSaveErrors,
    validateToPublishStepsResult,
    setValidateToPublishStepsResult,
    showMenu,
    openMenu,
    closeMenu,
    handleClickMenuUnPublish,
    handleClickMenuAdd,
    handleClickAddModalOk,
    handleCloseAddCompleteModal,
    handleClickMenuDelete,
    handleClickDeleteModalOk,
    handleCloseDeleteCompleteModal,
    imageCropperState,
    closeImageCropper,
    openImageCropper,
    saveImageCropper,
    cropperAspectRatio,
    handleExceedImageFileSize,
    handleCroppedImage,
    handleClickCropperCloseButton,
    missionOfficeImagesFieldRef,
    missionProductsFieldRef,
    missionProductRefs,
    isMissionProductAdded,
    setMissionProductAdded,
    missionDemandFieldRef,
    showPreview,
    openPreview,
    closePreview,
    canUseMissionFormStepMissionJobOffers,
    canUseMissionFormStepMissionDemand,
  };
};

export default useMissionForm;
