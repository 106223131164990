import React, { FC } from 'react';
import styled, { css } from 'styled-components/macro';
import theme from 'styles/theme';
import typography from 'styles/typography';
import { FiImage } from 'react-icons/fi';
import { useTranslation } from 'react-i18next';
import { MdCheck } from 'react-icons/md';
import { FlexContainer, ImageCircle, EllipsisBox } from 'components/atoms';
import { GetMyAccountsResponse_Account as Account } from 'proto/v1/accountservice/accountservice';

/* stylelint-disable property-no-vendor-prefix */

const MyAccountImagePlaceholder = styled.div<{ size: number }>`
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;
  width: ${({ size }) => `${size}px`};
  height: ${({ size }) => `${size}px`};
  border-radius: 50%;
  background-color: ${theme.bgMissionListImage};
`;

const StyledImageCircle = styled(ImageCircle)`
  background-size: cover;
`;

const MyAccountListContainer = styled.div`
  padding-bottom: 16px;

  &:not(:last-child) {
    border-bottom: 1px solid #eee;
  }
`;

const MyAccountListPanelHeader = styled(FlexContainer).attrs({
  justifyContent: 'center',
  alignItems: 'center',
})`
  width: calc(100% - 16px);
  height: 48px;
  margin: 0 8px;

  & * {
    flex: 1;
  }
`;

const MyAccountListPanelTitle = styled.p`
  ${typography.textSub}
  flex: 0 0 auto;
  color: ${theme.textPrimary};
  text-align: center;
`;

const MyAccountList = styled.div`
  max-height: 204px;
  overflow-y: auto;

  &::-webkit-scrollbar {
    width: 10px;
    -webkit-appearance: none;
  }

  &::-webkit-scrollbar-thumb {
    border: solid 1.5px ${theme.baseWhite};
    border-radius: 5px;
    background-color: rgba(0, 0, 0, 0.5);
    box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
  }
`;

const MyAccountListItemName = styled(EllipsisBox)<{ hasValue: boolean }>`
  ${({ hasValue }) =>
    !hasValue &&
    css`
      color: ${theme.textSecondary};
    `};
`;

const MyAccountListItem = styled(FlexContainer)`
  ${typography.textMyAccountSelect}
  height: 68px;
  padding: 0 16px;
  cursor: pointer;
  user-select: none;

  &:hover {
    background-color: ${theme.bgMissionListHover};
  }

  &:hover ${MyAccountListItemName} {
    background-color: ${theme.bgMissionListHover};

    &::after {
      background-color: ${theme.bgMissionListHover};
    }
  }
`;

const MyAccountListItemIconWrapper = styled.div`
  margin-right: 8px;
`;

const MyAccountListItemNameWrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
`;

const MyAccountListItemStatus = styled.div`
  color: ${theme.textSecondary};
`;

const SelectedIconWrapper = styled.div`
  width: 14px;
  margin-left: 12px;
`;

const AccountList: FC<{
  containerKey: string;
  title: string;
  accountId: string;
  accounts: Account[];
  onClickItem: (account: Account) => void;
}> = props => {
  const { containerKey, title, accountId, accounts, onClickItem } = props;
  const { t } = useTranslation('sidebar');

  return (
    <MyAccountListContainer>
      <MyAccountListPanelHeader>
        <div />
        <MyAccountListPanelTitle>{title}</MyAccountListPanelTitle>
        <div />
      </MyAccountListPanelHeader>
      <MyAccountList>
        {accounts.map((account, i) => (
          <MyAccountListItem
            key={account.accountId}
            alignItems="center"
            onClick={() => onClickItem(account)}
            data-testid={`my-${containerKey}-selector-account-${i + 1}`}
          >
            <MyAccountListItemIconWrapper>
              {account?.coverImage?.small ? (
                <StyledImageCircle
                  diameter={'24px'}
                  imageSrc={account.coverImage.small.url}
                  data-testid={`my-${containerKey}-selector-account-${
                    i + 1
                  }-icon`}
                />
              ) : (
                <MyAccountImagePlaceholder
                  size={24}
                  data-testid={`my-${containerKey}-selector-account-${
                    i + 1
                  }-no-icon`}
                >
                  <FiImage size={14} color={theme.white} />
                </MyAccountImagePlaceholder>
              )}
            </MyAccountListItemIconWrapper>
            <MyAccountListItemNameWrapper>
              <MyAccountListItemName
                line={2}
                width={'104px'}
                maxHeight={'32px'}
                backgroundColor={theme.baseWhite}
                hasValue={!!account.name}
              >
                {account.name
                  ? account.name
                  : t('myAccountSelector.noMissionName')}
              </MyAccountListItemName>
              <MyAccountListItemStatus>
                {`${t('myAccountSelector.status')}${
                  account.published
                    ? t('myAccountSelector.public')
                    : t('myAccountSelector.private')
                }`}
              </MyAccountListItemStatus>
            </MyAccountListItemNameWrapper>
            <SelectedIconWrapper>
              {account.accountId === accountId && (
                <MdCheck
                  size={18}
                  fill={theme.basePrimary}
                  data-testid={`my-${containerKey}-selector-account-${
                    i + 1
                  }-selected`}
                />
              )}
            </SelectedIconWrapper>
          </MyAccountListItem>
        ))}
      </MyAccountList>
    </MyAccountListContainer>
  );
};

export default AccountList;
