import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components/macro';
import theme from 'styles/theme';
import typography from 'styles/typography';
import { AccountFollower } from 'proto/v1/apimodel/apimodel';
import {
  Table,
  TableHead,
  TableCell,
  TableBody,
  TableRow,
  ButtonM,
} from 'components/atoms';
import { Spinner } from 'visits-style';
import {
  MissionCell,
  InterestCell,
  CategoryCell,
  AcceptButtonCell,
} from 'components/molecules';
import { Notification } from 'modules/notification';
import { sortedMissionCategories } from 'utils/models/mission';
import { getContactReasons } from 'utils/models/contact';

const TableCellTh = styled(TableCell).attrs({ as: 'th' })`
  justify-content: flex-start;
  min-height: 0;

  &&& {
    height: 100%;
    padding: 14px 24px 13px;

    &:nth-child(2) {
      padding-left: 0;
    }
  }
`;

const LoadingInfoRow = styled(TableRow)`
  tbody tr&&& {
    display: block;
    min-height: 0;
    border-top: none;
  }
`;

const LoadingInfo = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 10px;
  margin-bottom: 20px;
`;

const MissionNotfound = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  transform: translateY(-50%) translateX(-50%);
  color: ${theme.textSecondary};
  line-height: 1.5;
  text-align: center;
`;

const LoadingMessage = styled.p`
  ${typography.textNote}
  margin-bottom: 8px;
  color: ${theme.textSecondary};
`;

const SpinnerContainer = styled.div`
  margin-top: 10px;
`;

const TableNotification = styled.span`
  display: inline-block;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: ${theme.labelBadge};
`;

const COLUMN_WIDTH = ['48px', '380px', '120px', '192px', '224px', '192px'];

const MemoizedFollower = React.memo(
  ({
    follower,
    onClickStartChat,
    onClickFollower,
    onClickFollowBack,
    onClickConfirmMessage,
    contactNotifications,
  }: {
    follower: AccountFollower;
    onClickStartChat: (
      followerAccountId: string,
      followerChatRoomId: string,
    ) => void;
    onClickFollower: (
      id: string,
      role: 'mission' | 'supporter',
      accountId: string,
    ) => void;
    onClickFollowBack: (follower: AccountFollower) => void;
    onClickConfirmMessage: (follower: AccountFollower) => void;
    contactNotifications: Notification[];
  }) => {
    const { t } = useTranslation(['followersMissions']);

    const {
      followerAccountId: accountId,
      mission,
      supporter,
      chatRoomId,
      isFollowing,
      followedAt,
    } = follower;
    if (!mission?.mission && !supporter?.supporter) return null;

    const isContact = contactNotifications.some(
      (contactNotification: Notification) =>
        contactNotification.id === accountId,
    );

    const attribute = (() => {
      if (supporter) return t('cell.supporterLabel');

      const attributes = mission?.mission?.corporateAttributes;
      return attributes && attributes.length > 0
        ? attributes[0].nameShort
        : '-';
    })();

    const interests = getContactReasons(follower);

    return (
      <TableRow data-testid="mission-follower">
        <TableCell
          cellWidth={COLUMN_WIDTH[0]}
          style={{ justifyContent: 'center', padding: 0 }}
        >
          {isContact && <TableNotification />}
        </TableCell>
        <MissionCell
          accountId={accountId}
          follower={mission?.mission ?? supporter?.supporter}
          followedAt={followedAt}
          onClick={onClickFollower}
          cellWidth={COLUMN_WIDTH[1]}
        />
        <TableCell cellWidth={COLUMN_WIDTH[2]}>
          <p>{attribute}</p>
        </TableCell>
        <CategoryCell
          categories={sortedMissionCategories(
            mission?.mission?.missionCategories ?? [],
          )}
          cellWidth={COLUMN_WIDTH[3]}
        />
        <InterestCell interests={interests} cellWidth={COLUMN_WIDTH[4]} />
        <AcceptButtonCell
          isFollowing={isFollowing}
          hasContactMessage={!!follower.supporter?.contactMessage}
          onClickAcceptButton={() => onClickFollowBack(follower)}
          onClickStartChatButton={() => onClickStartChat(accountId, chatRoomId)}
          onClickConfirmMessage={() => onClickConfirmMessage(follower)}
          cellWidth={COLUMN_WIDTH[5]}
        />
      </TableRow>
    );
  },
  (prevProps, nextProps) =>
    prevProps.follower === nextProps.follower &&
    prevProps.onClickStartChat === nextProps.onClickStartChat &&
    prevProps.onClickFollowBack === nextProps.onClickFollowBack &&
    prevProps.onClickFollower === nextProps.onClickFollower &&
    prevProps.onClickConfirmMessage === nextProps.onClickConfirmMessage &&
    prevProps.contactNotifications === nextProps.contactNotifications,
);

type FollowersTableProps = {
  followers: AccountFollower[] | null;
  refTableBody?:
    | React.RefObject<HTMLTableSectionElement>
    | ((instance: HTMLTableSectionElement | null) => void);
  isFetchingNext: boolean;
  errorOnFetchNext: { message: string } | null;
  onScrollTable?: () => void;
  onClickRetry: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  onClickStartChat: (
    followerAccountId: string,
    followerChatRoomId: string,
  ) => void;
  onClickFollower: (
    id: string,
    role: 'mission' | 'supporter',
    accountId: string,
  ) => void;
  onClickFollowBack: (follower: AccountFollower) => void;
  onClickConfirmMessage: (follower: AccountFollower) => void;
  contactNotifications: Notification[];
};

const FollowersTable: FC<FollowersTableProps> = ({
  followers,
  refTableBody,
  isFetchingNext,
  errorOnFetchNext,
  onScrollTable,
  onClickRetry,
  onClickStartChat,
  onClickFollower,
  onClickFollowBack,
  onClickConfirmMessage,
  contactNotifications,
}) => {
  const { t } = useTranslation(['followersMissions']);
  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableCellTh cellWidth={COLUMN_WIDTH[0]}>&#160;</TableCellTh>
          <TableCellTh cellWidth={COLUMN_WIDTH[1]}>
            {t('table.head.names')}
          </TableCellTh>
          <TableCellTh cellWidth={COLUMN_WIDTH[2]}>
            {t('table.head.corporateAttributes')}
          </TableCellTh>
          <TableCellTh cellWidth={COLUMN_WIDTH[3]}>
            {t('table.head.categories')}
          </TableCellTh>
          <TableCellTh cellWidth={COLUMN_WIDTH[4]}>
            {t('table.head.interests')}
          </TableCellTh>
          <TableCellTh cellWidth={COLUMN_WIDTH[5]}>
            {t('table.head.accept')}
          </TableCellTh>
        </TableRow>
      </TableHead>
      <TableBody
        style={{ position: 'relative' }}
        ref={refTableBody}
        onScroll={onScrollTable}
      >
        {followers && followers.length === 0 ? (
          <TableRow>
            <TableCell>
              <MissionNotfound>
                {t('description.missionsNotFound')}
              </MissionNotfound>
            </TableCell>
          </TableRow>
        ) : (
          followers &&
          followers.map(follower => (
            <MemoizedFollower
              key={`row-${follower.followerAccountId}`}
              follower={follower}
              onClickStartChat={onClickStartChat}
              onClickFollower={onClickFollower}
              onClickFollowBack={onClickFollowBack}
              onClickConfirmMessage={onClickConfirmMessage}
              contactNotifications={contactNotifications}
            />
          ))
        )}
        {/* loadingInfoRow */}
        <LoadingInfoRow>
          {(isFetchingNext || errorOnFetchNext) && (
            <TableCell colSpan={7} style={{ width: '100%', display: 'block' }}>
              <LoadingInfo>
                {isFetchingNext && (
                  <SpinnerContainer>
                    <Spinner size="40px" />
                  </SpinnerContainer>
                )}
                {errorOnFetchNext && (
                  <>
                    <LoadingMessage>
                      <p>{errorOnFetchNext.message.replace('\n', '')}</p>
                    </LoadingMessage>
                    <ButtonM buttonTheme="default" onClick={onClickRetry}>
                      {t('common:button.retry')}
                    </ButtonM>
                  </>
                )}
              </LoadingInfo>
            </TableCell>
          )}
        </LoadingInfoRow>
      </TableBody>
    </Table>
  );
};

export default FollowersTable;
