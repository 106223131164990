import { logEvent } from 'firebase/analytics';
import apiFirebase, { doAnalytics } from 'external/firebase/firebase';

export function trackPage(path: string) {
  if (doAnalytics) {
    // eslint-disable-next-line
    logEvent(apiFirebase.analytics(), 'page_view', { page_path: path });
  }
}

export function logCustomEvent(
  eventName: string,
  params?: Record<string, any>,
) {
  if (doAnalytics) {
    logEvent(apiFirebase.analytics(), eventName, params);
  }
}
