import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Mission } from 'proto/v1/apimodel/apimodel';
import { DetailSubTitle, DetailCompanyField } from 'components/molecules';

type MemberRangeFieldProps = {
  memberRange: Mission['memberRange'];
};

const MemberRangeField: FC<MemberRangeFieldProps> = ({ memberRange }) => {
  const { t } = useTranslation('missionDetail');

  return (
    <DetailCompanyField>
      <DetailSubTitle>{t('field.memberRange.label')}</DetailSubTitle>
      <p>{memberRange ? memberRange.displayText : '-'}</p>
    </DetailCompanyField>
  );
};

export default MemberRangeField;
