import React, { FC, ChangeEvent } from 'react';
import { useTranslation } from 'react-i18next';
import { MissionForm, MissionFormValues } from 'types/mission';
import { isEqualProps } from 'utils/object';
import { FlexContainer } from 'components/atoms';
import { FormItemLabel, MissionSettingFormItem } from 'components/molecules';
import {
  CountryField,
  JapanPrefectureField,
  OtherAddressField,
} from './SubFields';

type AddressFieldProps = {
  values: Pick<
    MissionFormValues,
    'countryId' | 'japanPrefectureId' | 'otherAddress'
  >;
  form: Pick<MissionForm, 'countries' | 'japanPrefectures'>;
  errors: {
    countryId: string | undefined;
    japanPrefectureId: string | undefined;
    otherAddress: string | undefined;
  };
  onChangeCountry: (e: ChangeEvent<HTMLSelectElement>) => void;
  onChangeJapanPrefecture: (e: ChangeEvent<HTMLSelectElement>) => void;
  onChangeOtherAddress: (e: ChangeEvent<HTMLTextAreaElement>) => void;
  onBlur: (e: any) => void;
};

const AddressField: FC<AddressFieldProps> = props => {
  const {
    values,
    errors,
    form,
    onChangeCountry,
    onChangeJapanPrefecture,
    onChangeOtherAddress,
    onBlur,
  } = props;

  const { t } = useTranslation('missionSetting');

  return (
    <MissionSettingFormItem required>
      <FormItemLabel label={t('field.address.label')} required />
      <FlexContainer style={{ marginBottom: '24px' }}>
        <CountryField
          value={values.countryId}
          error={errors.countryId}
          form={form.countries || []}
          onBlur={onBlur}
          onChange={onChangeCountry}
        />

        {values.countryId === 'JPN' && (
          <JapanPrefectureField
            value={values.japanPrefectureId}
            error={errors.japanPrefectureId}
            form={form.japanPrefectures || []}
            onBlur={onBlur}
            onChange={onChangeJapanPrefecture}
          />
        )}
      </FlexContainer>

      <OtherAddressField
        value={values.otherAddress}
        countryId={values.countryId}
        error={errors.otherAddress}
        onChange={onChangeOtherAddress}
        onBlur={onBlur}
      />
    </MissionSettingFormItem>
  );
};

export default React.memo(AddressField, (prevProps, nextProps) =>
  isEqualProps(prevProps, nextProps, ['values', 'errors', 'form']),
);
