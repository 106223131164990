import { createSelector } from 'reselect';
import { ApiError } from 'types/error';
import { ActionsUnion, createAction } from './helper';

export enum ErrorDialogTypes {
  INITIAL = 'initial',
  CLOSE = 'close',
  RELOAD = 'reload',
}

export type errorDialogOptions = {
  type?: ErrorDialogTypes;
  showCloseIcon?: boolean;
  onClose?: () => void;
} & ApiError;

type ErrorDialogState = {
  readonly isShown: boolean;
  readonly options: errorDialogOptions | null;
};

/*
 * Redux Action actionTypes
 */
export const OPEN = 'errorDialog/OPEN';
export const CLOSE = 'errorDialog/CLOSE';

/*
 * Redux Actions
 */
export const errorDialogActions = {
  open: (options: errorDialogOptions) => createAction(OPEN, options),
  close: () => createAction(CLOSE),
};

export type Actions = ActionsUnion<typeof errorDialogActions>;

/*
 * Selectors
 */
export const getErrorDialogState = (state: any): ErrorDialogState =>
  state.errorDialog;

export const getIsShown = createSelector(
  [getErrorDialogState],
  errorDialogState => errorDialogState.isShown,
);

export const getOptions = createSelector(
  [getErrorDialogState],
  errorDialogState => errorDialogState.options,
);

/*
 * Reducer
 */
const initState: ErrorDialogState = {
  isShown: false,
  options: {
    type: ErrorDialogTypes.INITIAL,
    message: '',
  },
};

export default function reducer(state = initState, action: Actions) {
  if (!action) return state;

  switch (action.type) {
    case OPEN: {
      return {
        ...state,
        isShown: true,
        options: action.payload,
      };
    }
    case CLOSE: {
      return initState;
    }
    default:
      return state;
  }
}
