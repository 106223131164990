import { Tag } from 'components/atoms';
import styled from 'styled-components/macro';
import theme from 'styles/theme';
import typography from 'styles/typography';

const InterestTag = styled(Tag).attrs({ round: true, bordered: true })`
  ${typography.labelDefault}
  display: inline-block;
  min-width: unset;
  margin-right: 4px;
  margin-bottom: 4px;
  padding: 3px 12px;
  border-color: ${theme.labelRequest};
  color: ${theme.labelRequest};
  text-align: center;

  &:last-child {
    margin-right: 0;
    margin-bottom: 0;
  }
`;

export default InterestTag;
