import React, { useMemo } from 'react';
import styled from 'styled-components/macro';
import { useTranslation } from 'react-i18next';
import { ButtonXL, Wall } from 'components/atoms';
import MissionFormHeader from '../../MissionFormHeader';
import MissionFormFooter from '../../MissionFormFooter';
import MissionPreview from '../../MissionPreview';
import { MissionJobOffersField } from '../../../Fields';
import {
  SaveConfirmationModal,
  SaveCompleteModal,
  NoInputMissionConfirmationModal,
  PublishMissionConfirmationModal,
  PublishCompleteModal,
  InvalidToPublishModal,
  UnpublishConfirmationModal,
  UnpublishCompleteModal,
  LeavePageConfirmationModal,
  MissionJobOfferFormModal,
} from '../../../Modals';
import useStepMissionJobOffers from './useStepMissionJobOffers';

const Form = styled.form``;

const StepMissionJobOffersWall = styled(Wall)`
  margin-bottom: 32px;
  padding: 0;
`;

const StepMissionJobOffers: React.VFC = () => {
  const { t } = useTranslation('missionForm');
  const {
    handleClickNext,
    movePrevStep,
    isPublished,
    isFirstPublished,
    formik,
    form,
    modalState,
    closeModal,
    handleClickSave,
    handleClickSaveConfirmationOK,
    handleClickPublish,
    handleClickPublishConfirmationOk,
    handleClickUnPublishConfirmationOk,
    validateToPublishStepsResult,
    handleClickCreateMissionJobOffer,
    handleClickEditMissionJobOffer,
    handleSaveMissionJobOffer,
    handleDeleteMissionJobOffer,
    handlePublishMissionJobOffer,
    handleUnPublishMissionJobOffer,
    openPreview,
    canUseMissionFormStepMissionJobOffers,
    canUseMissionFormStepMissionDemand,
  } = useStepMissionJobOffers();

  const { values, dirty } = formik;

  return useMemo(
    () => (
      <>
        <MissionFormHeader
          isDisabledSaveButton={!dirty}
          isPublished={isPublished}
          onClickPreview={openPreview}
          onClickSave={handleClickSave}
          type="optional"
          title={t('header.missionJobOffers.title')}
          description={t('header.missionJobOffers.description')}
        />

        <Form data-testid="mission-form-step-mission-job-offers">
          <StepMissionJobOffersWall color="baseWhite">
            <MissionJobOffersField
              value={values.missionJobOffers}
              onClickCreate={handleClickCreateMissionJobOffer}
              onClickEdit={handleClickEditMissionJobOffer}
              onDelete={handleDeleteMissionJobOffer}
              onPublish={handlePublishMissionJobOffer}
              onUnPublish={handleUnPublishMissionJobOffer}
              occupations={form.occupations}
            />
          </StepMissionJobOffersWall>
        </Form>

        <MissionFormFooter
          buttons={
            isFirstPublished
              ? [
                  <ButtonXL
                    type="button"
                    buttonTheme="gradation"
                    onClick={handleClickPublish}
                    key="publish"
                    disabled={isPublished ? !dirty : false}
                  >
                    {t('button.publish')}
                  </ButtonXL>,
                ]
              : [
                  <ButtonXL
                    type="button"
                    buttonTheme="cancel"
                    onClick={movePrevStep}
                    key="go-back"
                  >
                    {t('button.back')}
                  </ButtonXL>,
                  canUseMissionFormStepMissionJobOffers &&
                  !canUseMissionFormStepMissionDemand ? (
                    <ButtonXL
                      type="button"
                      buttonTheme="gradation"
                      onClick={handleClickPublish}
                      key="publish"
                      disabled={isPublished ? !dirty : false}
                    >
                      {t('button.publish')}
                    </ButtonXL>
                  ) : (
                    <ButtonXL
                      type="button"
                      buttonTheme="default"
                      onClick={handleClickNext}
                      key="go-next"
                    >
                      {t('button.next')}
                    </ButtonXL>
                  ),
                ]
          }
        />

        <MissionPreview values={values} step="missionJobOffers" forInnovator />

        <SaveConfirmationModal
          show={
            modalState.show && modalState.content === 'saveMissionConfirmation'
          }
          onOk={handleClickSaveConfirmationOK}
          onCancel={closeModal}
        />
        <NoInputMissionConfirmationModal
          show={
            modalState.show &&
            modalState.content === 'saveRequiredNoInputConfirmation'
          }
          onCancel={closeModal}
          onOk={handleClickSaveConfirmationOK}
          isPublished={isPublished}
          stepsResult={validateToPublishStepsResult}
          canUseStepJobOffers={canUseMissionFormStepMissionJobOffers}
          canUseStepMissionDemand={canUseMissionFormStepMissionDemand}
        />
        <SaveCompleteModal
          show={
            modalState.show && modalState.content === 'saveMissionCompleted'
          }
          onClose={closeModal}
        />
        <InvalidToPublishModal
          show={modalState.show && modalState.content === 'invalidToPublish'}
          onClose={closeModal}
          stepsResult={validateToPublishStepsResult}
          canUseStepJobOffers={canUseMissionFormStepMissionJobOffers}
          canUseStepMissionDemand={canUseMissionFormStepMissionDemand}
        />
        <PublishMissionConfirmationModal
          show={
            modalState.show &&
            modalState.content === 'publishMissionConfirmation'
          }
          onOk={handleClickPublishConfirmationOk}
          onCancel={closeModal}
        />
        <PublishCompleteModal
          show={
            modalState.show && modalState.content === 'publishMissionCompleted'
          }
          onClose={closeModal}
        />
        <UnpublishConfirmationModal
          show={
            modalState.show &&
            modalState.content === 'unPublishMissionConfirmation'
          }
          onOk={handleClickUnPublishConfirmationOk}
          onCancel={closeModal}
        />
        <UnpublishCompleteModal
          show={
            modalState.show &&
            modalState.content === 'unPublishMissionCompleted'
          }
          onClose={closeModal}
        />
        <MissionJobOfferFormModal
          show={modalState.show && modalState.content === 'missionJobOfferForm'}
          index={modalState.index}
          onClose={closeModal}
          onSave={handleSaveMissionJobOffer}
          occupations={form.occupations}
          missionJobOffer={
            modalState.index !== undefined
              ? values.missionJobOffers[modalState.index]
              : undefined
          }
        />
        <LeavePageConfirmationModal when={dirty} />
      </>
    ),
    [
      canUseMissionFormStepMissionDemand,
      canUseMissionFormStepMissionJobOffers,
      closeModal,
      dirty,
      form.occupations,
      handleClickCreateMissionJobOffer,
      handleClickEditMissionJobOffer,
      handleClickNext,
      handleClickPublish,
      handleClickPublishConfirmationOk,
      handleClickSave,
      handleClickSaveConfirmationOK,
      handleClickUnPublishConfirmationOk,
      handleDeleteMissionJobOffer,
      handlePublishMissionJobOffer,
      handleSaveMissionJobOffer,
      handleUnPublishMissionJobOffer,
      isFirstPublished,
      isPublished,
      modalState.content,
      modalState.index,
      modalState.show,
      movePrevStep,
      openPreview,
      t,
      validateToPublishStepsResult,
      values,
    ],
  );
};

export default StepMissionJobOffers;
