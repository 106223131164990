import React, { FC } from 'react';
import { useTranslation, Trans } from 'react-i18next';
import styled from 'styled-components/macro';
import * as EXTERNAL_LINK from 'constants/externalLink';
import theme from 'styles/theme';
import { ButtonM, FlexContainer } from 'components/atoms';
import {
  Modal,
  ModalContent,
  ModalFooter,
  FormItemLabel,
} from 'components/molecules';

const StyledFlexContainer = styled(FlexContainer)`
  height: 100%;
`;

const StyledModalContent = styled(ModalContent)`
  flex: 1;
  align-content: flex-start;
  margin-bottom: 0;
  padding: 0;
`;

const AboutDtt = styled.p`
  width: 100%;
  margin-bottom: 36px;
  text-align: left;
`;

const DttRanks = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 20px 60px;
  border-radius: 4px;
  background-color: ${theme.bgAdd};
  text-align: left;
`;

const DttRanksLeft = styled.div``;

const DttRanksRight = styled.div``;

const DttRank = styled.div`
  line-height: 24px;
`;

const DttLink = styled.a`
  color: ${theme.labelBadge};

  &:hover {
    opacity: 0.8;
    color: ${theme.labelBadge};
  }
`;

type AboutDttModalModalProps = {
  show: boolean;
  closeModal: () => void;
};

const AboutDttModalModal: FC<AboutDttModalModalProps> = ({
  show,
  closeModal,
}) => {
  const { t } = useTranslation(['matter', 'common']);

  return (
    <Modal show={show} closeModal={closeModal} height="471px">
      <StyledFlexContainer flexDirection="column">
        <StyledModalContent data-testid="modal-about-dtt">
          <FormItemLabel
            label={t('modal.aboutDtt.title', { lng: 'ja' })}
            style={{ width: '100%', textAlign: 'left', marginBottom: 24 }}
          />
          <AboutDtt>
            <Trans i18nKey="modal.aboutDtt.description" ns="matter" lang="ja">
              デザイン思考スコアは、「デザイン思考テスト」によって創造力を数値化したスコアです。スコアの評価は以下のランクで表現されます。
              デザイン思考テストの詳細は、
              <DttLink
                href={EXTERNAL_LINK.DTT}
                target="_blank"
                rel="noopener noreferrer"
              >
                デザイン思考テストの公式サイト
              </DttLink>
              をご確認ください。
            </Trans>
          </AboutDtt>
          <DttRanks>
            <DttRanksLeft>
              <DttRank>
                {t('modal.aboutDtt.dttRanks.SSRank', { lng: 'ja' })}
              </DttRank>
              <DttRank>
                {t('modal.aboutDtt.dttRanks.SRank', { lng: 'ja' })}
              </DttRank>
              <DttRank>
                {t('modal.aboutDtt.dttRanks.ARank', { lng: 'ja' })}
              </DttRank>
              <DttRank>
                {t('modal.aboutDtt.dttRanks.BRank', { lng: 'ja' })}
              </DttRank>
            </DttRanksLeft>
            <DttRanksRight>
              <DttRank>
                {t('modal.aboutDtt.dttRanks.CRank', { lng: 'ja' })}
              </DttRank>
              <DttRank>
                {t('modal.aboutDtt.dttRanks.DRank', { lng: 'ja' })}
              </DttRank>
              <DttRank>
                {t('modal.aboutDtt.dttRanks.ERank', { lng: 'ja' })}
              </DttRank>
            </DttRanksRight>
          </DttRanks>
        </StyledModalContent>
        <ModalFooter>
          <ButtonM buttonTheme="default" onClick={closeModal}>
            {t('common:button.close', { lng: 'ja' })}
          </ButtonM>
        </ModalFooter>
      </StyledFlexContainer>
    </Modal>
  );
};

export default AboutDttModalModal;
