import {
  SupportArea,
  SupporterMember,
  GalleryImage,
} from 'proto/v1/apimodel/apimodel';
import { imageSelector } from 'utils/imageSelector';

export const sortedSupportAreas = (supportAreas: SupportArea[]) =>
  [...supportAreas].sort((a, b) => a.displayOrder - b.displayOrder);

export const isEmptySupporterMember = (member: SupporterMember) =>
  member.name === '' &&
  member.career === '' &&
  !imageSelector(member.iconImage);

export const isFullInfoSupporterMember = (member: SupporterMember) =>
  member.name !== '' && member.career !== '' && imageSelector(member.iconImage);

export const isEmptyGalleryImage = (galleryImage: GalleryImage) =>
  !imageSelector(galleryImage.image);
