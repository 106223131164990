import React, { forwardRef, HTMLAttributes } from 'react';
import styled from 'styled-components/macro';
import throttle from 'lodash.throttle';

const FormWrapper = styled.div`
  width: 100%;
  height: calc(100% - 100px);
  overflow: auto;
`;

const Form = styled.form`
  width: 1035px;
`;

type MissionSettingFormProps = {
  onScroll: ReturnType<typeof throttle>;
} & HTMLAttributes<HTMLDivElement>;

const MissionSettingForm = forwardRef<HTMLDivElement, MissionSettingFormProps>(
  (props, ref) => {
    const { onScroll, children, ...rest } = props;
    return (
      <FormWrapper onScroll={onScroll} ref={ref} {...rest}>
        <Form>{children}</Form>
      </FormWrapper>
    );
  },
);

export default MissionSettingForm;
