import { defaultTheme } from 'visits-style';

const base = {
  baseWhite: '#FFFFFF',
  basePrimary: '#696F7B',
  baseDisabled: '#DEDEDE',
  baseLink: '#2A72DD',
  baseError: '#E32D2D',
};

const text = {
  textPrimary: '#333333',
  textPrimaryHover: '#666666',
  textSecondary: '#9698A4',
  textSecondaryHover: '#BFC0C7',
  textSecondaryDisabled: '#DEDEDE',
  textCardSecondary: '#696F7B',
};

const bg = {
  bgMenuDefault: '#2F3032',
  bgMenuHover: '#404143',
  bgMain: '#FAFAFA',
  bgChatLeft: '#828A99',
  bgChatRight: '#888FD1',
  bgChatRoomCardSelected: '#CED4F5',
  bgChatRoomCardHover: '#F3F4FC',
  bgMessageHover: '#F4F5FA',
  bgAdd: '#F3F4F7',
  bgSecondary: '#FBFCFD',
  bgMissionListImage: '#DCDCDC',
  bgMissionListHover: '#F3F4F7',
  bgMessageDeleted: '#DEDEDE',
  bgPickerHeading: '#FBFCFE',
  bgHumanResourceIconHover: '#D5DAF4',
};

const button = {
  buttonDefaultHover: '#969AA3',
  buttonCancelHover: '#F2F2F2',
};

const label = {
  labelDefault: '#888FD1',
  labelRequest: '#8693A8',
  labelBadge: '#5E27FB',
  labelRequired: '#E31338',
  labelOptional: '#5D82E1',
};

const table = {
  tableThead: '#828A99',
  tableHover: '#F1F2F9',
};

const border = {
  borderDefault: '#DCDCDC',
  borderTable: '#EEEEEE',
  borderMissionDemand: '#DDDDDD',
};

const arrow = {
  arrowUp: '#5E27FB',
  arrowDown: '#78A5BD',
  arrowStatic: '#8B9697',
};

const sidebar = {
  sidebarSelectedAccount: '#3D3F4B',
  sidebarSelectedAccountHover: '#535672',
};

const origin: typeof base &
  typeof text &
  typeof bg &
  typeof label &
  typeof table &
  typeof border &
  typeof sidebar &
  typeof arrow = {
  ...base,
  ...text,
  ...bg,
  ...button,
  ...label,
  ...table,
  ...border,
  ...arrow,
  ...sidebar,
};

const theme = ({
  ...defaultTheme,
  ...origin,
} as unknown) as typeof defaultTheme & typeof origin;

export default theme;
