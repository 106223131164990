import React from 'react';
import styled from 'styled-components/macro';
import { Link, withRouter } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import theme from 'styles/theme';
import typography from 'styles/typography';
import { CopyRight } from 'components/atoms';
import { ConfirmModal } from 'components/molecules';
import * as EXTERNAL_LINK from 'constants/externalLink';
import * as PUBLIC_ROUTES from 'routes/constants/public';
import useContactModal from 'hooks/useContactModal';

const AppFooter = styled.footer`
  ${typography.textFooter}
  position: relative;
  z-index: 3;
  width: 100%;
  background: ${theme.bgMenuDefault};
`;
const AppFooterInner = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 66px;
  padding: 0 64px;
`;
const Nav = styled.ul`
  display: flex;
  align-items: center;
`;
const NavItem = styled.li`
  margin-right: 40px;

  button {
    padding: 0;
    border: none;
    background: none;
    cursor: pointer;
  }

  button,
  a {
    display: block;
    padding: 20px 0;
    color: ${theme.baseWhite};
    text-decoration: none;
    cursor: pointer;

    &:hover {
      color: ${theme.baseWhite};
      text-decoration: none;
    }
  }
`;

export function Footer() {
  const { t, i18n } = useTranslation(['footer', 'common']);
  const { showContactModal, handleToggleContactModal } = useContactModal();

  return (
    <>
      <AppFooter>
        <AppFooterInner>
          <Nav>
            <NavItem>
              <button type="button" onClick={handleToggleContactModal}>
                {t('menu.inquiry')}
              </button>
            </NavItem>
            <NavItem>
              <Link to={PUBLIC_ROUTES.TERMS} target="_blank">
                {t('menu.termsOfService')}
              </Link>
            </NavItem>
            <NavItem>
              <Link
                to={`${
                  i18n.languages[0] === 'en'
                    ? EXTERNAL_LINK.PRIVACY_POLICY_EN
                    : EXTERNAL_LINK.PRIVACY_POLICY
                }`}
                target="_blank"
              >
                Privacy Policy
              </Link>
            </NavItem>
          </Nav>
          <CopyRight />
        </AppFooterInner>
      </AppFooter>

      <ConfirmModal
        type="normal"
        show={showContactModal}
        closeModal={handleToggleContactModal}
        confirmCaption={t('common:button.close')}
        confirm={handleToggleContactModal}
      >
        {t('common:description.contact')}
      </ConfirmModal>
    </>
  );
}

export default withRouter(Footer);
