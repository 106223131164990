import React, { useMemo } from 'react';
import styled from 'styled-components/macro';
import { useTranslation } from 'react-i18next';
import { ButtonXL, Wall } from 'components/atoms';
import { MISSION_PRODUCTS_MAX_COUNT } from 'constants/config';
import { PlusGray } from 'assets/svg';
import { OutlinedIconButton } from 'components/molecules';
import MissionFormHeader from '../../MissionFormHeader';
import MissionFormFooter from '../../MissionFormFooter';
import MissionPreview from '../../MissionPreview';
import { MissionProductsField } from '../../../Fields';
import {
  ImageFileSizeExceededModal,
  SaveConfirmationModal,
  InvalidToSaveModal,
  SaveCompleteModal,
  PublishMissionConfirmationModal,
  PublishCompleteModal,
  InvalidToPublishModal,
  UnpublishConfirmationModal,
  UnpublishCompleteModal,
  ImageCropperModal,
  LeavePageConfirmationModal,
} from '../../../Modals';
import useStepMissionProducts from './useStepMissionProducts';

const Form = styled.form``;

const StepMissionProductsWall = styled(Wall)`
  margin-bottom: 32px;
  padding-top: 26px;
`;

const AddMissionProductButtonContainer = styled.div`
  display: flex;
  justify-content: center;
`;

const StepMissionProducts: React.VFC = () => {
  const { t } = useTranslation(['missionForm', 'missionSetting']);
  const {
    handleClickNext,
    movePrevStep,
    isPublished,
    isFirstPublished,
    formik,
    handleChangeChecked,
    imageCropperState,
    cropperAspectRatio,
    handleCroppedImage,
    handleExceedImageFileSize,
    handleClickCropperCloseButton,
    modalState,
    closeModal,
    handleClickSave,
    validateToSaveErrors,
    handleClickSaveConfirmationOK,
    handleClickPublish,
    handleClickPublishConfirmationOk,
    handleClickUnPublishConfirmationOk,
    validateToPublishStepsResult,
    handleAddMissionProduct,
    handleDeleteMissionProduct,
    handleChangeMissionProductImage,
    handleChangeHasMemberBenefit,
    missionProductsError,
    missionProductRefs,
    missionProductsFieldRef,
    openPreview,
    canUseMissionFormStepMissionDemand,
    canUseMissionFormStepMissionJobOffers,
  } = useStepMissionProducts();

  const {
    values,
    handleChange,
    handleBlur,
    dirty,
    setFieldValue,
    setFieldTouched,
  } = formik;

  return useMemo(
    () => (
      <>
        <MissionFormHeader
          isDisabledSaveButton={!dirty}
          isPublished={isPublished}
          onClickPreview={openPreview}
          onClickSave={handleClickSave}
          type="optional"
          title={t('header.missionProducts.title')}
        />

        <Form data-testid="mission-form-step-mission-products">
          <StepMissionProductsWall color="baseWhite">
            <MissionProductsField
              value={values.missionProducts}
              error={missionProductsError}
              onAddProduct={handleAddMissionProduct}
              onDeleteProduct={handleDeleteMissionProduct}
              onChangeText={handleChange}
              onBlurText={handleBlur}
              onChangeChecked={handleChangeChecked}
              onChangeImage={handleChangeMissionProductImage}
              onChangeHasMemberBenefit={handleChangeHasMemberBenefit}
              onExceedImageFileSize={handleExceedImageFileSize}
              productRefs={missionProductRefs}
              ref={missionProductsFieldRef}
            />
          </StepMissionProductsWall>

          {!values.missionProducts ||
            (values.missionProducts &&
              values.missionProducts.length < MISSION_PRODUCTS_MAX_COUNT && (
                <AddMissionProductButtonContainer>
                  <OutlinedIconButton
                    icon={<PlusGray />}
                    onClick={handleAddMissionProduct}
                    data-testid="add-product"
                  >
                    {t('missionSetting:field.missionProducts.add')}
                  </OutlinedIconButton>
                </AddMissionProductButtonContainer>
              ))}
        </Form>

        <MissionFormFooter
          buttons={
            isFirstPublished
              ? [
                  <ButtonXL
                    type="button"
                    buttonTheme="gradation"
                    onClick={handleClickPublish}
                    key="publish"
                    disabled={isPublished ? !dirty : false}
                  >
                    {t('button.publish')}
                  </ButtonXL>,
                ]
              : [
                  <ButtonXL
                    type="button"
                    buttonTheme="cancel"
                    onClick={movePrevStep}
                    key="go-back"
                  >
                    {t('button.back')}
                  </ButtonXL>,
                  <ButtonXL
                    type="button"
                    buttonTheme="default"
                    onClick={handleClickNext}
                    key="go-next"
                  >
                    {t('button.next')}
                  </ButtonXL>,
                ]
          }
        />

        <MissionPreview
          values={values}
          step="missionProducts"
          forInnovator={
            !canUseMissionFormStepMissionDemand &&
            canUseMissionFormStepMissionJobOffers
          }
        />

        <ImageFileSizeExceededModal
          show={
            modalState.show && modalState.content === 'imageFileSizeExceeded'
          }
          closeModal={closeModal}
        />
        <SaveConfirmationModal
          show={
            modalState.show && modalState.content === 'saveMissionConfirmation'
          }
          onOk={handleClickSaveConfirmationOK}
          onCancel={closeModal}
        />
        <InvalidToSaveModal
          show={modalState.show && modalState.content === 'invalidToSave'}
          onClose={closeModal}
          errors={validateToSaveErrors}
        />
        <SaveCompleteModal
          show={
            modalState.show && modalState.content === 'saveMissionCompleted'
          }
          onClose={closeModal}
        />
        <InvalidToPublishModal
          show={modalState.show && modalState.content === 'invalidToPublish'}
          onClose={closeModal}
          stepsResult={validateToPublishStepsResult}
          canUseStepJobOffers={canUseMissionFormStepMissionJobOffers}
          canUseStepMissionDemand={canUseMissionFormStepMissionDemand}
        />
        <PublishMissionConfirmationModal
          show={
            modalState.show &&
            modalState.content === 'publishMissionConfirmation'
          }
          onOk={handleClickPublishConfirmationOk}
          onCancel={closeModal}
        />
        <PublishCompleteModal
          show={
            modalState.show && modalState.content === 'publishMissionCompleted'
          }
          onClose={closeModal}
        />
        <UnpublishConfirmationModal
          show={
            modalState.show &&
            modalState.content === 'unPublishMissionConfirmation'
          }
          onOk={handleClickUnPublishConfirmationOk}
          onCancel={closeModal}
        />
        <UnpublishCompleteModal
          show={
            modalState.show &&
            modalState.content === 'unPublishMissionCompleted'
          }
          onClose={closeModal}
        />
        <ImageCropperModal
          imageCropperState={imageCropperState}
          aspectRatio={cropperAspectRatio}
          onCroppedImage={croppedImage =>
            handleCroppedImage({ croppedImage, setFieldValue, setFieldTouched })
          }
          handleClickCropperCloseButton={(type, index) =>
            handleClickCropperCloseButton({ type, setFieldTouched, index })
          }
        />
        <LeavePageConfirmationModal when={dirty} />
      </>
    ),
    [
      canUseMissionFormStepMissionDemand,
      canUseMissionFormStepMissionJobOffers,
      closeModal,
      cropperAspectRatio,
      dirty,
      handleAddMissionProduct,
      handleBlur,
      handleChange,
      handleChangeChecked,
      handleChangeHasMemberBenefit,
      handleChangeMissionProductImage,
      handleClickCropperCloseButton,
      handleClickNext,
      handleClickPublish,
      handleClickPublishConfirmationOk,
      handleClickSave,
      handleClickSaveConfirmationOK,
      handleClickUnPublishConfirmationOk,
      handleCroppedImage,
      handleDeleteMissionProduct,
      handleExceedImageFileSize,
      imageCropperState,
      isFirstPublished,
      isPublished,
      missionProductRefs,
      missionProductsError,
      missionProductsFieldRef,
      modalState.content,
      modalState.show,
      movePrevStep,
      openPreview,
      setFieldTouched,
      setFieldValue,
      t,
      validateToPublishStepsResult,
      validateToSaveErrors,
      values,
    ],
  );
};

export default StepMissionProducts;
