import { useEffect, useState } from 'react';
import * as H from 'history';
import { trackPage } from '../utils/analytics';

const useTrackPage = (history: H.History) => {
  const [, setCurrentPath] = useState<string>('');

  useEffect(() => {
    let path = history.location.pathname + history.location.search;
    setCurrentPath(path);
    trackPage(path);

    history.listen(async ({ pathname, search }) => {
      setCurrentPath(preCurrentPath => {
        path = pathname + search;
        if (preCurrentPath !== path) {
          trackPage(path);
          return path;
        }
        return preCurrentPath;
      });
    });
  }, [history, setCurrentPath]);
};

export default useTrackPage;
