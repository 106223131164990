import { useCallback } from 'react';
import {
  MissionJobOfferFormValue,
  MissionJobOfferFormModalValues,
} from 'types/form';
import { MissionFormValues } from 'types/mission';
import { useFormik } from 'formik';
import { GenerateKeyMethods } from './useGenerateKey';
import { ModalContext } from './useModal';

type Formik = ReturnType<typeof useFormik>;

type Payload = {
  missionJobOffers: MissionFormValues['missionJobOffers'];
  getAddedItemId: GenerateKeyMethods['getAddedItemId'];
  setFieldValue: Formik['setFieldValue'];
  openModal: ModalContext['openModal'];
  closeModal: ModalContext['closeModal'];
};

const useMissionJobOffersField = ({
  missionJobOffers,
  getAddedItemId,
  setFieldValue,
  openModal,
  closeModal,
}: Payload) => {
  const createJobOffer = useCallback(
    (values: MissionJobOfferFormModalValues) => {
      const value: MissionJobOfferFormValue = {
        id: `${getAddedItemId('missionJobOffers')}`,
        occupationId: values.occupationId,
        jobDescription: values.jobDescription,
        published: true,
      };
      setFieldValue('missionJobOffers', [...missionJobOffers, value], true);
    },
    [getAddedItemId, missionJobOffers, setFieldValue],
  );

  const updateJobOffer = useCallback(
    (values: MissionJobOfferFormModalValues, targetIndex: number) => {
      setFieldValue(
        `missionJobOffers[${targetIndex}]`,
        { ...missionJobOffers[targetIndex], ...values },
        true,
      );
    },
    [missionJobOffers, setFieldValue],
  );

  const changeMissionJobOfferPublished = useCallback(
    (published: MissionJobOfferFormValue['published'], targetIndex: number) => {
      setFieldValue(
        `missionJobOffers[${targetIndex}].published`,
        published,
        true,
      );
    },
    [setFieldValue],
  );

  const handleClickCreateMissionJobOffer = useCallback(() => {
    openModal('missionJobOfferForm');
  }, [openModal]);

  const handleClickEditMissionJobOffer = useCallback(
    (targetIndex: number) => {
      openModal('missionJobOfferForm', targetIndex);
    },
    [openModal],
  );

  const handlePublishMissionJobOffer = useCallback(
    (targetIndex: number) => {
      changeMissionJobOfferPublished(true, targetIndex);
    },
    [changeMissionJobOfferPublished],
  );

  const handleUnPublishMissionJobOffer = useCallback(
    (targetIndex: number) => {
      changeMissionJobOfferPublished(false, targetIndex);
    },
    [changeMissionJobOfferPublished],
  );

  const handleSaveMissionJobOffer = useCallback(
    (
      values: MissionJobOfferFormModalValues,
      targetIndex: number | undefined,
    ) => {
      if (targetIndex === undefined) {
        createJobOffer(values);
      } else {
        updateJobOffer(values, targetIndex);
      }
      closeModal();
    },
    [closeModal, createJobOffer, updateJobOffer],
  );

  const handleDeleteMissionJobOffer = useCallback(
    (targetIndex: number) => {
      if (!missionJobOffers || !Array.isArray(missionJobOffers)) {
        return;
      }
      setFieldValue(
        'missionJobOffers',
        missionJobOffers.filter((_, index) => index !== targetIndex),
        true,
      );
    },
    [missionJobOffers, setFieldValue],
  );

  return {
    handleClickCreateMissionJobOffer,
    handleClickEditMissionJobOffer,
    handleSaveMissionJobOffer,
    handleDeleteMissionJobOffer,
    handlePublishMissionJobOffer,
    handleUnPublishMissionJobOffer,
  };
};

export default useMissionJobOffersField;
