import React, { FC, SelectHTMLAttributes } from 'react';
import styled from 'styled-components/macro';
import theme from 'styles/theme';

const StyledSelectbox = styled.select`
  width: ${({ width = 'unset' }: SelectboxProps) => width};
  height: 40px;
  margin: ${({ margin = '0 0 0 0' }: SelectboxProps) => margin};
  border: 1px solid ${theme.borderDefault};
  border-radius: 4px;
  color: ${theme.textPrimary};
  font-size: 1rem;

  /* Chrome only */
  text-align-last: ${({ textAlign = 'left' }: SelectboxProps) => textAlign};
  ${({ disabled }: SelectboxProps) =>
    disabled
      ? `
      background-color: ${theme.borderDefault};
      color: ${theme.textSecondary};
      `
      : `background-color: transparent;`}
  select {
    width: 100%;
    padding: 0 26px 0 10px;

    &:focus {
      box-shadow: none;
    }
  }
`;

type OptionType = {
  [key: string]: any;
};
type SelectboxProps = SelectHTMLAttributes<HTMLSelectElement> & {
  margin?: string;
  width?: string;
  textAlign?: 'left' | 'right' | 'center';
  options: OptionType[];
  idName: string;
  textName?: string;
  selectType?: 'default' | 'range';
  noSelectValue?: string | number;
  noSelectText?: string;
};
const Selectbox: FC<SelectboxProps> = props => {
  const {
    value,
    options,
    idName,
    textName,
    noSelectValue,
    noSelectText,
    selectType = 'default',
  } = props;

  const selectBoxOptions =
    noSelectValue !== undefined && value === noSelectValue
      ? [
          { [idName]: noSelectValue, [textName ?? 'name']: noSelectText ?? '' },
          ...options,
        ]
      : options;

  return selectType === 'default' ? (
    <StyledSelectbox {...props}>
      {selectBoxOptions.map(option => (
        <option
          key={option[idName]}
          value={option[idName]}
          aria-label={textName ? option[textName] : option.name}
        >
          {textName ? option[textName] : option.name}
        </option>
      ))}
    </StyledSelectbox>
  ) : (
    <StyledSelectbox {...props}>
      {selectBoxOptions.map(option => (
        <option key={option[idName]} value={option[idName]}>
          {`${option.lowerLimit}〜${option.upperLimit}${option.unitName}`}
        </option>
      ))}
    </StyledSelectbox>
  );
};

export default Selectbox;
