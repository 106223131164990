import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import NavigationPrompt from 'react-router-navigation-prompt';
import { ButtonM } from 'components/atoms';
import { Modal, ModalContent, ModalFooter } from 'components/molecules';

type LeavePageConfirmationModalProps = {
  when: boolean;
};

const LeavePageConfirmationModal: FC<LeavePageConfirmationModalProps> = ({
  when,
}) => {
  const { t } = useTranslation(['missionSetting', 'common']);
  return (
    <NavigationPrompt when={when}>
      {({ onConfirm, onCancel }) => (
        <Modal show closeModal={onCancel}>
          <ModalContent data-testid="modal-leave-page-confirmation">
            {t('modal.leavePageConfirmation.content')}
          </ModalContent>
          <ModalFooter>
            <ButtonM buttonTheme="cancel" type="button" onClick={onCancel}>
              {t('common:button.cancel')}
            </ButtonM>
            <ButtonM onClick={onConfirm} buttonTheme="default" type="button">
              {t('common:button.ok')}
            </ButtonM>
          </ModalFooter>
        </Modal>
      )}
    </NavigationPrompt>
  );
};

export default LeavePageConfirmationModal;
