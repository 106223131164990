import React, { FC } from 'react';
import { Modal } from 'components/molecules';
import theme from 'styles/theme';

type PreviewModalProps = {
  show: boolean;
  onClose: () => void;
};

const PreviewModal: FC<PreviewModalProps> = ({ show, onClose, children }) => {
  return (
    <Modal
      size="full"
      color={theme.bgMain}
      show={show}
      closeModal={onClose}
      style={{ padding: 0, overflow: 'hidden' }}
      data-testid="modal-preview"
    >
      {children}
    </Modal>
  );
};

export default PreviewModal;
