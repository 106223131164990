import React, { useCallback } from 'react';
import styled from 'styled-components';
import theme from 'styles/theme';
import { useTranslation } from 'react-i18next';
import { InputActionMeta } from 'react-select';
import { ReactSelectOption } from 'types/form';
import { range } from 'utils/array';
import {
  NewGraduateMattersFilterValues,
  NewGraduateHumanResourceFilterForm,
  NewGraduateHumanResourceFilterFormYearOfGraduations,
  NewGraduateMattersDetailFilterValues,
  NewGraduateHumanResourceFilterFormDepartment,
  NewGraduateHumanResourceFilterFormDttRank,
  HumanResourceFilterExcludeLatestMessageWithinDays,
  NewGraduateHumanResourceFilterFormMessageReplied,
} from 'types/matter';
import { EXCLUDE_LATEST_MESSAGES_WITHIN_DAYS_NO_SELECT_VALUE } from 'constants/models/matter';
import {
  MultiSelectbox,
  InputMultiSelect,
  DecoratedSelectbox,
} from 'components/molecules';
import { Checkbox } from 'components/atoms';
import useDetailFilter from './useDetailFilter';
import DetailFilterModal from './DetailFilterModal';

const NEW_GRADUATE_YEAR_OF_GRADUATION_MIN_YEAR = 2021;
const NEW_GRADUATE_YEAR_OF_GRADUATION_PAST_YEARS = 1;
const NEW_GRADUATE_YEAR_OF_GRADUATION_ADDITIONAL_YEARS = 4;

const Wrapper = styled.div`
  padding-left: 24px;
`;

const Filters = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  margin-left: -24px;
  padding: 20px 32px;
  border-bottom: 1px solid ${theme.borderDefault};
  background-color: ${theme.bgAdd};
`;

const FilterItemsTop = styled.div`
  display: flex;
  gap: 24px;
  width: 864px;
`;

const FilterItemsBottom = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 864px;
`;

const FilterItemsBottomLeft = styled.div`
  display: flex;
  gap: 24px;
`;

const FilterItemsBottomRight = styled.div`
  display: flex;
`;

const FilterItem = styled.div``;

const FavoriteFilterItem = styled.div`
  display: flex;
  align-items: center;
`;

const FavoriteCheckBox = styled(Checkbox)`
  &&& {
    min-width: auto;
    margin-right: 0;
    margin-bottom: 0;

    div {
      margin-top: 0;
      margin-bottom: 0;
    }
  }
`;

const DetailFilterTag = styled.div`
  position: relative;
  box-sizing: border-box;
  padding: 8px 12px 6px 12px;
  border: 1px solid ${theme.basePrimary};
  border-radius: 14px;
  background-color: ${theme.baseWhite};
  color: ${theme.basePrimary};
  font-size: 12px;
  cursor: pointer;

  &:hover {
    padding: 7px 11px 5px 11px;
    border: 2px solid ${theme.basePrimary};
  }
`;

const DetailFilteredCountBadge = styled.div`
  display: flex;
  position: absolute;
  top: -6px;
  right: -8px;
  align-items: center;
  justify-content: center;
  width: 18px;
  height: 18px;
  border-radius: 50%;
  background-color: ${theme.labelBadge};
  color: ${theme.baseWhite};
  font-size: 12px;
  font-weight: bold;
  line-height: 14px;
`;

const yearOfGraduationOptions = (() => {
  const currentYear = new Date().getFullYear();

  const minYear = currentYear - NEW_GRADUATE_YEAR_OF_GRADUATION_PAST_YEARS;

  const startYear =
    minYear < NEW_GRADUATE_YEAR_OF_GRADUATION_MIN_YEAR ? currentYear : minYear;

  const endYear =
    currentYear + NEW_GRADUATE_YEAR_OF_GRADUATION_ADDITIONAL_YEARS;

  const years = range(startYear, endYear);

  return years.map(year => ({
    id: year,
    name: `${year}年`,
  }));
})();

type OnSelect<T> = (option: T) => void;
type OnDeselect<T> = (option: T) => void;

type Props = {
  values: NewGraduateMattersFilterValues;
  form: NewGraduateHumanResourceFilterForm;
  filterSchools: ReactSelectOption[];
  schoolOptions: ReactSelectOption[];
  onChangeSchools: (value: ReactSelectOption[]) => void;
  fetchMoreSchoolOptions: () => Promise<void>;
  onInputChangeSchool: (
    inputValue: string,
    actionMeta: InputActionMeta,
  ) => void;
  isLoadingSchoolOptions: boolean;
  onSelectYearOfGraduation: OnSelect<
    NewGraduateHumanResourceFilterFormYearOfGraduations
  >;
  onDeselectYearOfGraduation: OnDeselect<
    NewGraduateHumanResourceFilterFormYearOfGraduations
  >;
  onClearYearOfGraduation: () => void;
  onSelectDepartment: OnSelect<NewGraduateHumanResourceFilterFormDepartment>;
  onDeselectDepartment: OnDeselect<
    NewGraduateHumanResourceFilterFormDepartment
  >;
  onClearDepartment: () => void;
  onSelectDttRank: OnSelect<NewGraduateHumanResourceFilterFormDttRank>;
  onDeselectDttRank: OnDeselect<NewGraduateHumanResourceFilterFormDttRank>;
  onClearDttRank: () => void;
  onChangeExcludeLatestMessageWithinDays: (
    option: HumanResourceFilterExcludeLatestMessageWithinDays,
  ) => void;
  onChangeMessageReplied: (
    option: NewGraduateHumanResourceFilterFormMessageReplied,
  ) => void;
  onChangeFavorite: (e: React.ChangeEvent<HTMLInputElement>) => void;
  isDisabledMessageReplied: boolean;
  onSubmitDetailFilter: (values: NewGraduateMattersDetailFilterValues) => void;
};

const NewGraduateHumanResourcesFilter: React.VFC<Props> = ({
  values,
  form,
  filterSchools,
  schoolOptions,
  onChangeSchools,
  onInputChangeSchool,
  isLoadingSchoolOptions,
  fetchMoreSchoolOptions,
  onSelectYearOfGraduation,
  onDeselectYearOfGraduation,
  onClearYearOfGraduation,
  onSelectDepartment,
  onDeselectDepartment,
  onClearDepartment,
  onSelectDttRank,
  onDeselectDttRank,
  onClearDttRank,
  onChangeExcludeLatestMessageWithinDays,
  onChangeMessageReplied,
  onChangeFavorite,
  isDisabledMessageReplied,
  onSubmitDetailFilter,
}) => {
  const {
    detailFilterValues,
    showDetailFilter,
    openDetailFilter,
    closeDetailFilter,
    detailFilteredFieldsCount,
    handleSelectPastExperienceAchievement,
    handleDeselectPastExperienceAchievement,
    handleSelectPreferredBusinessType,
    handleDeselectPreferredBusinessType,
    handleSelectOccupation,
    handleDeselectOccupation,
    handleSelectWorkLocation,
    handleDeselectWorkLocation,
    handleSelectPreferredWorkStyle,
    handleDeselectPreferredWorkStyle,
  } = useDetailFilter({
    preferredWorkStyleIds: values.preferredWorkStyleIds,
    pastExperienceAchievementIds: values.pastExperienceAchievementIds,
    preferredBusinessTypeIds: values.preferredBusinessTypeIds,
    occupationIds: values.occupationIds,
    workLocationIds: values.workLocationIds,
  });

  const handleSubmitDetailFilter = useCallback(() => {
    onSubmitDetailFilter(detailFilterValues);
  }, [detailFilterValues, onSubmitDetailFilter]);

  const { t } = useTranslation(['humanResource']);

  return (
    <>
      <Wrapper>
        <Filters>
          <FilterItemsTop>
            <FilterItem>
              <MultiSelectbox<
                NewGraduateHumanResourceFilterFormYearOfGraduations
              >
                values={values.yearOfGraduation}
                placeholder={t('filter.field.yearOfGraduation.placeholder', {
                  lng: 'ja',
                })}
                options={yearOfGraduationOptions}
                width={160}
                onSelect={onSelectYearOfGraduation}
                onDeselect={onDeselectYearOfGraduation}
                onClear={onClearYearOfGraduation}
                idName="id"
                textName="name"
                testId="multi-select-box-year-of-graduation"
                noSelectOptionLabel={t('filter.selectBox.all', { lng: 'ja' })}
              />
            </FilterItem>

            <FilterItem>
              <InputMultiSelect
                value={filterSchools}
                options={schoolOptions}
                onInputChange={onInputChangeSchool}
                onChange={value =>
                  onChangeSchools((value as unknown) as ReactSelectOption[])
                }
                isLoading={isLoadingSchoolOptions}
                onMenuScrollToBottom={fetchMoreSchoolOptions}
                placeholder={t('filter.field.school.newGraduate.placeholder', {
                  lng: 'ja',
                })}
                aria-label={t('filter.field.school.newGraduate.label', {
                  lng: 'ja',
                })}
                width="350px"
              />
            </FilterItem>

            <FilterItem>
              <MultiSelectbox<NewGraduateHumanResourceFilterFormDepartment>
                values={values.departmentIds}
                placeholder={t('filter.field.department.placeholder', {
                  lng: 'ja',
                })}
                options={form.departments}
                width={190}
                onSelect={onSelectDepartment}
                onDeselect={onDeselectDepartment}
                onClear={onClearDepartment}
                idName="id"
                textName="name"
                testId="multi-select-box-department"
                noSelectOptionLabel={t('filter.selectBox.all', { lng: 'ja' })}
              />
            </FilterItem>
          </FilterItemsTop>

          <FilterItemsBottom>
            <FilterItemsBottomLeft>
              <FilterItem>
                <MultiSelectbox<NewGraduateHumanResourceFilterFormDttRank>
                  values={values.dttRankIds}
                  placeholder={t('filter.field.dttRank.placeholder', {
                    lng: 'ja',
                  })}
                  options={form.dttRanks}
                  width={172}
                  onSelect={onSelectDttRank}
                  onDeselect={onDeselectDttRank}
                  onClear={onClearDttRank}
                  idName="id"
                  textName="name"
                  testId="multi-select-box-dtt-rank"
                  noSelectOptionLabel={t('filter.selectBox.all', { lng: 'ja' })}
                />
              </FilterItem>

              <FilterItem>
                <DecoratedSelectbox<
                  HumanResourceFilterExcludeLatestMessageWithinDays
                >
                  value={
                    values.excludeLatestMessageWithinDays ??
                    EXCLUDE_LATEST_MESSAGES_WITHIN_DAYS_NO_SELECT_VALUE
                  }
                  placeholder={t(
                    'filter.field.excludeLatestMessageWithinDays.placeholder',
                    {
                      lng: 'ja',
                    },
                  )}
                  options={form.excludeLatestMessageWithinDays}
                  width={240}
                  onChange={onChangeExcludeLatestMessageWithinDays}
                  idName="id"
                  textName="name"
                  testId="select-box-latest-message-days-before"
                  noSelectOption={{
                    id: EXCLUDE_LATEST_MESSAGES_WITHIN_DAYS_NO_SELECT_VALUE,
                    name: t(
                      'filter.field.excludeLatestMessageWithinDays.placeholder',
                      { lng: 'ja' },
                    ),
                  }}
                />
              </FilterItem>

              <FilterItem>
                <DecoratedSelectbox<
                  NewGraduateHumanResourceFilterFormMessageReplied
                >
                  value={
                    values.messageReplied !== undefined
                      ? String(values.messageReplied)
                      : ''
                  }
                  placeholder={t('filter.field.messageReplied.placeholder', {
                    lng: 'ja',
                  })}
                  options={form.messageReplied}
                  width={160}
                  onChange={onChangeMessageReplied}
                  idName="value"
                  textName="name"
                  testId="select-box-message-replied"
                  noSelectOption={{
                    value: '',
                    name: t('filter.field.messageReplied.placeholder', {
                      lng: 'ja',
                    }),
                  }}
                  disabled={isDisabledMessageReplied}
                />
              </FilterItem>

              <FavoriteFilterItem>
                <FavoriteCheckBox
                  name="favorite"
                  onChange={onChangeFavorite}
                  checked={!!values.favorite}
                  aria-label={t('filter.field.favorite.label', {
                    lng: 'ja',
                  })}
                >
                  {t('filter.field.favorite.label', {
                    lng: 'ja',
                  })}
                </FavoriteCheckBox>
              </FavoriteFilterItem>
            </FilterItemsBottomLeft>
            <FilterItemsBottomRight>
              <DetailFilterTag onClick={openDetailFilter}>
                {t('filter.detail.label', { lng: 'ja' })}
                {detailFilteredFieldsCount > 0 && (
                  <DetailFilteredCountBadge>
                    {detailFilteredFieldsCount}
                  </DetailFilteredCountBadge>
                )}
              </DetailFilterTag>
            </FilterItemsBottomRight>
          </FilterItemsBottom>
        </Filters>
      </Wrapper>

      <DetailFilterModal
        values={detailFilterValues}
        form={form}
        show={showDetailFilter}
        closeModal={closeDetailFilter}
        onSubmit={handleSubmitDetailFilter}
        onSelectPastExperienceAchievement={
          handleSelectPastExperienceAchievement
        }
        onDeselectPastExperienceAchievement={
          handleDeselectPastExperienceAchievement
        }
        onSelectPreferredBusinessType={handleSelectPreferredBusinessType}
        onDeselectPreferredBusinessType={handleDeselectPreferredBusinessType}
        onSelectOccupation={handleSelectOccupation}
        onDeselectOccupation={handleDeselectOccupation}
        onSelectWorkLocation={handleSelectWorkLocation}
        onDeselectWorkLocation={handleDeselectWorkLocation}
        onSelectPreferredWorkStyle={handleSelectPreferredWorkStyle}
        onDeselectPreferredWorkStyle={handleDeselectPreferredWorkStyle}
      />
    </>
  );
};

export default NewGraduateHumanResourcesFilter;
