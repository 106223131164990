import React, { forwardRef } from 'react';
import styled, { css } from 'styled-components/macro';
import theme from 'styles/theme';
import { isEqualProps } from 'utils/object';
import { useTranslation } from 'react-i18next';
import { VerticalEllipsis } from 'assets/svg';
import { FiX } from 'react-icons/fi';
import { FlexContainer, ButtonContainer, ButtonS, Tag } from 'components/atoms';
import { MissionSettingFormSubTitle, Overlay } from 'components/molecules';

const Header = styled.div`
  width: 1035px;
  height: 100px;
`;

const MenuContainerWrap = styled.div`
  display: inline-flex;
  margin-left: 10px;
`;

const MenuContainer = styled(FlexContainer)`
  position: relative;
`;

const MenuButton = styled(ButtonS)<{ isActive: boolean }>`
  min-width: 32px;
  padding: 0;
  background: transparent;
  ${({ isActive }) =>
    isActive &&
    css`
      &:hover {
        background: ${theme.basePrimary};
        > svg {
          fill: ${theme.white};
        }
      }
    `}
`;

const Menu = styled.div`
  position: absolute;
  z-index: 2;
  top: 40px;
  right: 0;
  min-width: max-content;
  padding: 24px 0 16px 0;
  border-radius: 8px;
  background-color: ${theme.baseWhite};
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.16);
`;

const StyledIconClose = styled(FiX)`
  position: absolute;
  z-index: 3;
  top: 6px;
  right: 6px;
  cursor: pointer;
`;

const PublishStatusTag = styled(Tag)`
  &&& {
    min-width: 58px;
    margin: 0;
    padding: 3px 10px;
    color: ${theme.baseWhite};
    font-weight: bold;
  }
`;

type HeaderContentProps = {
  showMenu: boolean;
  showRequiredTitle: boolean;
  disabledSaveButton: boolean;
  isPublished: boolean;
  isActive: boolean;
  menuItems: React.ReactNode[];
  onOpen: () => void;
  onClose: () => void;
  onClickPreview: () => void;
  onClickSave: () => void;
};

const HeaderContent = forwardRef<HTMLDivElement, HeaderContentProps>(
  (props, ref) => {
    const {
      showMenu,
      showRequiredTitle,
      menuItems,
      onOpen,
      onClose,
      onClickPreview,
      onClickSave,
      disabledSaveButton,
      isPublished,
      isActive,
    } = props;

    const { t } = useTranslation('missionSetting');

    return (
      <Header ref={ref}>
        <FlexContainer
          flexWrap="no-wrap"
          justifyContent="space-between"
          style={{ marginBottom: '20px' }}
          alignItems="center"
        >
          <FlexContainer alignItems="center">
            <PublishStatusTag
              data-testid="publish-status-tag"
              round
              color={isPublished ? 'labelBadge' : 'baseDisabled'}
            >
              {isPublished ? t('tag.published') : t('tag.unPublished')}
            </PublishStatusTag>
          </FlexContainer>
        </FlexContainer>

        <FlexContainer
          flexWrap="wrap"
          justifyContent="space-between"
          alignItems="center"
          style={{ paddingBottom: '14px' }}
        >
          {showRequiredTitle ? (
            <MissionSettingFormSubTitle
              tagColor="labelRequired"
              tag={t('tag.required')}
              title={t('description.requiredFields')}
            />
          ) : (
            <MissionSettingFormSubTitle
              tagColor="labelOptional"
              tag={t('tag.optional')}
              title={t('description.optionalFields')}
            />
          )}

          <div>
            <ButtonContainer align="right">
              <ButtonS
                onClick={onClickPreview}
                buttonTheme="default"
                type="button"
              >
                {t('button.preview')}
              </ButtonS>
              <ButtonS
                buttonTheme="default"
                type="button"
                onClick={onClickSave}
                disabled={disabledSaveButton}
                data-testid="save-mission"
              >
                {t('button.save')}
              </ButtonS>
              <MenuContainerWrap>
                <MenuContainer>
                  <MenuButton
                    isActive={isActive}
                    onClick={onOpen}
                    data-testid="mission-setting-menu-button"
                  >
                    <VerticalEllipsis />
                  </MenuButton>
                  {showMenu && (
                    <>
                      <Overlay onDismiss={onClose} />
                      <Menu data-testid="mission-setting-menu">
                        <StyledIconClose
                          size={14}
                          color={theme.textPrimary}
                          onClick={onClose}
                        />
                        {menuItems}
                      </Menu>
                    </>
                  )}
                </MenuContainer>
              </MenuContainerWrap>
            </ButtonContainer>
          </div>
        </FlexContainer>
      </Header>
    );
  },
);

export default React.memo(HeaderContent, (prevProps, nextProps) =>
  isEqualProps(prevProps, nextProps, [
    'showMenu',
    'showRequiredTitle',
    'disabledSaveButton',
    'isPublished',
    'isActive',
    'onClickSave',
  ]),
);
