import React, { FC, useMemo } from 'react';
import { SupporterDetail } from 'components/organisms/Details';
import {
  ImageFileSizeExceededModal,
  ImageCropperModal,
  InvalidToSaveModal,
  NoInputSupporterConfirmationModal,
  SaveConfirmationModal,
  PublishSupporterConfirmationModal,
  UnpublishConfirmationModal,
  SaveCompleteModal,
  PublishCompleteModal,
  UnpublishCompleteModal,
  PreviewModal,
  LeavePageConfirmationModal,
} from '../../../Modals';
import useSupporterFormContext from '../../useSupporterFormContext';
import convertSupporterFormValuesToSupporter from '../../convertSupporterFormValuesToSupporter';

const ModalsContainer: FC = () => {
  const {
    formik,
    form,
    modalState,
    imageCropperState,
    cropperAspectRatio,
    validateToSaveErrors,
    isPublished,
    showPreview,
    updateSupporter,
    publishSupporter,
    unPublishSupporter,
    closeModal,
    handleCroppedImage,
    handleClickCropperCloseButton,
    setShowPreview,
  } = useSupporterFormContext();

  const { values, resetForm, dirty } = formik;

  return useMemo(
    () => (
      <>
        <ImageFileSizeExceededModal
          show={
            modalState.show && modalState.content === 'imageFileSizeExceeded'
          }
          closeModal={closeModal}
        />
        <ImageCropperModal
          imageCropperState={imageCropperState}
          aspectRatio={cropperAspectRatio}
          onCroppedImage={handleCroppedImage}
          handleClickCropperCloseButton={handleClickCropperCloseButton}
        />
        <InvalidToSaveModal
          show={modalState.content === 'invalidToSave'}
          onClose={closeModal}
          errors={validateToSaveErrors}
        />
        <SaveConfirmationModal
          show={
            modalState.show && modalState.content === 'saveMissionConfirmation'
          }
          onOk={() => {
            closeModal();
            updateSupporter(values);
          }}
          onCancel={closeModal}
        />
        <PublishSupporterConfirmationModal
          show={
            modalState.show &&
            modalState.content === 'publishMissionConfirmation'
          }
          onOk={() => {
            closeModal();
            publishSupporter(values);
          }}
          onCancel={closeModal}
        />
        <UnpublishConfirmationModal
          show={
            modalState.show &&
            modalState.content === 'unPublishMissionConfirmation'
          }
          onOk={() => {
            closeModal();
            unPublishSupporter(values);
          }}
          onCancel={closeModal}
        />
        <NoInputSupporterConfirmationModal
          show={
            modalState.show &&
            modalState.content === 'saveRequiredNoInputConfirmation'
          }
          onCancel={closeModal}
          onOk={() => {
            closeModal();
            updateSupporter(values);
          }}
          isPublished={isPublished}
        />
        <SaveCompleteModal
          show={
            modalState.show && modalState.content === 'saveMissionCompleted'
          }
          onClose={() => {
            resetForm();
            closeModal();
          }}
        />
        <PublishCompleteModal
          show={
            modalState.show && modalState.content === 'publishMissionCompleted'
          }
          onClose={() => {
            resetForm();
            closeModal();
          }}
        />
        <UnpublishCompleteModal
          show={
            modalState.show &&
            modalState.content === 'unPublishMissionCompleted'
          }
          onClose={() => {
            resetForm();
            closeModal();
          }}
        />
        <PreviewModal show={showPreview} onClose={() => setShowPreview(false)}>
          <SupporterDetail
            supporter={convertSupporterFormValuesToSupporter(values, form)}
            isPreview
          />
        </PreviewModal>
        <LeavePageConfirmationModal when={dirty} />
      </>
    ),
    [
      modalState.show,
      modalState.content,
      closeModal,
      imageCropperState,
      cropperAspectRatio,
      handleCroppedImage,
      handleClickCropperCloseButton,
      validateToSaveErrors,
      isPublished,
      showPreview,
      values,
      form,
      dirty,
      updateSupporter,
      publishSupporter,
      unPublishSupporter,
      resetForm,
      setShowPreview,
    ],
  );
};

export default ModalsContainer;
