import React from 'react';
import { useTranslation, Trans } from 'react-i18next';
import styled from 'styled-components/macro';
import theme from 'styles/theme';
import { ValidateToPublishStepsResult } from 'types/form';
import { ButtonM } from 'components/atoms';
import { Modal, ModalContent, ModalFooter } from 'components/molecules';

const StepsResult = styled.div`
  align-self: flex-start;
  width: 520px;
  padding: 12px 24px;
  background-color: ${theme.bgMain};
  color: ${theme.textPrimary};
  text-align: left;
`;

const StepsResultItem = styled.div``;

const Highlighted = styled.span`
  color: ${theme.labelDefault};
`;

type Props = {
  show: boolean;
  onOk: () => void;
  onCancel: () => void;
  isPublished: boolean;
  stepsResult: ValidateToPublishStepsResult | undefined;
  canUseStepJobOffers: boolean;
  canUseStepMissionDemand: boolean;
};

const NoInputMissionConfirmationModal: React.VFC<Props> = ({
  show,
  onOk,
  onCancel,
  isPublished,
  stepsResult,
  canUseStepJobOffers,
  canUseStepMissionDemand,
}) => {
  const { t } = useTranslation('missionSetting');
  return (
    <Modal show={show} closeModal={onCancel}>
      <ModalContent data-testid="modal-save-required-no-input">
        {t('modal.saveRequiredNoInputMissionConfirmation.content')}
        {isPublished && (
          <>
            <br />
            <br />
            {t('modal.saveRequiredNoInputMissionConfirmation.description')}
            <StepsResult>
              {!stepsResult?.company && (
                <StepsResultItem>
                  {t('validateToPublishStepsResult.company')}
                </StepsResultItem>
              )}
              {!stepsResult?.aboutMission && (
                <StepsResultItem>
                  {t('validateToPublishStepsResult.aboutMission')}
                </StepsResultItem>
              )}
              {canUseStepJobOffers &&
                !canUseStepMissionDemand &&
                !stepsResult?.missionJobOffers && (
                  <StepsResultItem>
                    <Trans
                      i18nKey="validateToPublishStepsResult.missionJobOffers"
                      ns="missionSetting"
                    >
                      4. 求人情報 ※ミッションの公開のためには
                      <Highlighted>４</Highlighted>
                      の入力が必要です。
                    </Trans>
                  </StepsResultItem>
                )}
              {!canUseStepJobOffers &&
                canUseStepMissionDemand &&
                !stepsResult?.missionDemand && (
                  <StepsResultItem>
                    <Trans
                      i18nKey="validateToPublishStepsResult.MissionDemand"
                      ns="missionSetting"
                    >
                      4. ビジネスニーズ ※ミッションの公開のためには
                      <Highlighted>４</Highlighted>
                      の入力が必要です。
                    </Trans>
                  </StepsResultItem>
                )}
              {canUseStepJobOffers &&
                canUseStepMissionDemand &&
                !stepsResult?.missionJobOffers &&
                !stepsResult?.missionDemand && (
                  <StepsResultItem>
                    <Trans
                      i18nKey="validateToPublishStepsResult.missionJobOffersOrMissionDemand"
                      ns="missionSetting"
                    >
                      4. 求人情報 または 5. ビジネスニーズ
                      ※ミッションの公開のためには
                      <Highlighted>４または５のいずれか</Highlighted>
                      の入力が必要です。
                    </Trans>
                  </StepsResultItem>
                )}
            </StepsResult>
          </>
        )}
      </ModalContent>
      <ModalFooter>
        <ButtonM onClick={onCancel} buttonTheme="cancel">
          {t('button.returnToEntryPage')}
        </ButtonM>
        <ButtonM
          onClick={onOk}
          buttonTheme="default"
          type="button"
          data-testid="modal-save-required-no-input-button"
        >
          {t('button.saveContents')}
        </ButtonM>
      </ModalFooter>
    </Modal>
  );
};

export default NoInputMissionConfirmationModal;
