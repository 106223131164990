import i18n from 'i18n/i18n';
import { string, number, object, array, boolean } from 'yup';
import { FinancingSeriesIds } from 'constants/models/mission';
import {
  MissionProductTarget,
  CorporateAttribute,
  MissionDemandFundraisingToUpdate,
  Images,
} from 'proto/v1/apimodel/apimodel';
import {
  MissionProductFormValue,
  MissionProductTagFormValue,
  MissionDemandFormValue,
  MissionJobOfferFormValue,
} from 'types/form';
import { imageSelector } from 'utils/imageSelector';
import {
  isSelectedMissionDemand,
  isEmptyMissionMember,
  isEmptyMissionProduct,
  isCompanyCorporateAttribute,
  isEmptyCollaborationAsset,
} from 'utils/models/mission';
import { isEmptySupporterMember } from 'utils/models/supporter';
import { isOverMissionNameMaxLength } from './utils';

const t = i18n.getFixedT(null, 'missionSetting');

const companyEstablishedDateValueWithCorporateAttributesSchema = number().when(
  '$corporateAttributes',
  (corporateAttributes: CorporateAttribute[], schema: any) =>
    isCompanyCorporateAttribute(corporateAttributes)
      ? schema.required(t('validation.noSelect'))
      : schema,
);

const companyEstablishedDateValueSchema = number().required(
  t('validation.noSelect'),
);

export const missionNameSchema = string()
  .trim()
  .required(t('validation.noInput'))
  .test(
    'max mission name',
    t('validation.maxMissionName'),
    missionName => !!missionName && !isOverMissionNameMaxLength(missionName),
  );

export const missionDescriptionSchema = string()
  .trim()
  .required(t('validation.noInput'));

export const supportDescriptionSchema = string()
  .trim()
  .required(t('validation.noInput'));

export const strengthPointSchema = string()
  .trim()
  .required(t('validation.noInput'));

export const companyNameSchema = string()
  .trim()
  .required(t('validation.noInput'));

export const companyEstablishedDateSchema = object({
  year: companyEstablishedDateValueSchema,
  month: companyEstablishedDateValueSchema,
});

export const companyEstablishedDateWithCorporateAttributesSchema = object({
  year: companyEstablishedDateValueWithCorporateAttributesSchema,
  month: companyEstablishedDateValueWithCorporateAttributesSchema,
});

export const companyIdSchema = string().required(t('validation.noSelect'));

export const japanPrefectureIdSchema = number().when(
  'countryId',
  (countryId: string, schema: any) =>
    countryId === 'JPN' ? schema.required(t('validation.noSelect')) : schema,
);

export const otherAddressSchema = string()
  .trim()
  .required(t('validation.noInput'));

export const fundIdSchema = number().required(t('validation.noSelect'));

export const memberRangeIdSchema = number().required(t('validation.noSelect'));

export const missionCategoriesSchema = array().required(
  t('validation.noSelectMissionCategories'),
);

export const iconImageSchema = object()
  .nullable()
  .test(
    'required iconImage',
    t('validation.noImage'),
    (imageObj: Images) => !!imageObj && !!imageSelector(imageObj),
  )
  .required(t('validation.noImage'));

export const coverImageSchema = object()
  .nullable()
  .test(
    'required coverImage',
    t('validation.noImage'),
    imageObj => !!imageSelector(imageObj),
  )
  .required(t('validation.noImage'));

export const companyUrlSchema = string()
  .url(t('validation.incorrectUrl'))
  .required(t('validation.noInput'));

export const missionDemandSchema = object({
  wantCollaboration: boolean(),
  collaboration: object({
    assets: array().of(
      object().when([], (_: any, schema: any) =>
        !isEmptyCollaborationAsset(schema)
          ? object({
              give: string().nullable(),
              want: string().nullable(),
            })
          : object(),
      ),
    ),
  }),
  fundraising: object({
    financingSeriesId: number(),
  }),
  wantFundraising: boolean().when('fundraising.financingSeriesId', {
    is: (
      financingSeriesId: MissionDemandFundraisingToUpdate['financingSeriesId'],
    ) => financingSeriesId > FinancingSeriesIds.NA,
    then: boolean(),
    otherwise: boolean().oneOf([false], t('validation.noSelectWantRaiseFund')),
  }),
  wantInvestSeed: boolean(),
  wantInvestEarly: boolean(),
  wantInvestMiddle: boolean(),
  wantInvestLater: boolean(),
  wantInvest: boolean().when(
    [
      'wantInvestSeed',
      'wantInvestEarly',
      'wantInvestMiddle',
      'wantInvestLater',
    ],
    {
      is: (
        wantInvestSeed: MissionDemandFormValue['wantInvestSeed'],
        wantInvestEarly: MissionDemandFormValue['wantInvestEarly'],
        wantInvestMiddle: MissionDemandFormValue['wantInvestMiddle'],
        wantInvestLater: MissionDemandFormValue['wantInvestLater'],
      ) =>
        wantInvestSeed ||
        wantInvestEarly ||
        wantInvestMiddle ||
        wantInvestLater,
      then: boolean(),
      otherwise: boolean().oneOf([false], t('validation.noSelectWantInvest')),
    },
  ),
  serviceInformation: object({
    issues: array().of(string()),
  }),
  wantServiceInformation: boolean(),
  wantServiceSuggestion: boolean(),
  wantTransferOfBusiness: boolean(),
  transferredOfBusiness: object({
    fundId: number().min(0),
  }),
  wantTransferredOfBusiness: boolean(),
  wantAcceptSecondment: boolean(),
  wantSendEmployee: boolean(),
  wantFurnishedOfficeRentalAvailability: boolean(),
  wantFurnishedOfficeRentalRequest: boolean(),
}).test('required missionDemand', '', isSelectedMissionDemand);

export const missionMembersSchema = array().of(
  object().when([], (_: any, schema: any) =>
    !isEmptyMissionMember(schema.originalValue)
      ? object({
          name: string().trim().required(t('validation.noInput')),
          career: string().trim().required(t('validation.noInput')),
          iconImage: object().test(
            'iconImage',
            t('validation.noImage'),
            imageObj => !!imageSelector(imageObj),
          ),
        })
      : object(),
  ),
);

export const supporterMembersSchema = array().of(
  object().when([], (_: any, schema: any) =>
    !isEmptySupporterMember(schema.originalValue)
      ? object({
          name: string().trim().required(t('validation.noInput')),
          career: string().trim().required(t('validation.noInput')),
          iconImage: object().test(
            'iconImage',
            t('validation.noImage'),
            imageObj => !!imageSelector(imageObj),
          ),
        })
      : object(),
  ),
);

export const missionProductsSchema = array().of(
  object().when([], (_: any, schema: any) =>
    schema.originalValue.hasMemberBenefit !== undefined ||
    !isEmptyMissionProduct(schema.originalValue)
      ? object({
          name: string().required(t('validation.noInput')),
          overview: string().required(t('validation.noInput')),
          url: string()
            .url(t('validation.incorrectUrl'))
            .required(t('validation.noInput')),
          problem: string().required(t('validation.noInput')),
          solution: string().required(t('validation.noInput')),
          hasMemberBenefit: boolean().required(t('validation.noSelect')),
          memberBenefit: string().when(
            'hasMemberBenefit',
            (
              hasMemberBenefit: MissionProductFormValue['hasMemberBenefit'],
              memberBenefitSchema: any,
            ) =>
              hasMemberBenefit
                ? memberBenefitSchema.required(t('validation.noInput'))
                : memberBenefitSchema,
          ),
          image: object()
            .nullable()
            .test(
              'required mission product image',
              t('validation.noImage'),
              imageObj => !!imageSelector(imageObj),
            )
            .required(t('validation.noImage')),
          productTarget: object().test(
            'productTarget',
            t('validation.noSelect'),
            (productTarget: MissionProductTarget) =>
              !!productTarget &&
              (productTarget.isForBusiness || productTarget.isForCustomer),
          ),
          tags: array().test(
            'tags',
            t('validation.noInputProductTags'),
            (tags: MissionProductTagFormValue[]) =>
              tags.find(tag => tag.tag) != null,
          ),
        })
      : object(),
  ),
);

export const supportAreaIdsSchema = array().required(t('validation.noSelect'));

export const missionJobOffersSchema = array()
  .of(
    object({
      id: string(),
      occupationId: number().required(t('validation.noSelect')),
      jobDescription: string(),
      published: boolean(),
    }),
  )
  .test(
    'required at least one published missionJobOffer',
    '',
    (missionJobOffers: MissionJobOfferFormValue[]) =>
      missionJobOffers.some(missionJobOffer => missionJobOffer.published),
  );
