import React, { useMemo } from 'react';
import styled, { css } from 'styled-components/macro';
import theme from 'styles/theme';
import { useTranslation } from 'react-i18next';
import { VerticalEllipsis } from 'assets/svg';
import { FiX } from 'react-icons/fi';
import { MISSION_MAX_COUNT } from 'constants/config';
import { FlexContainer, ButtonS } from 'components/atoms';
import { MissionSettingMenuItem, Overlay } from 'components/molecules';
import { imageSelector } from 'utils/imageSelector';
import {
  AddConfirmationModal,
  AddCompleteModal,
  DeleteConfirmationModal,
  DeleteCompleteModal,
} from '../Modals';
import useMissionFormContext from './useMissionFormContext';

const Wrapper = styled.div`
  display: inline-flex;
  position: absolute;
  top: 0;
  right: 0;
  margin-left: 10px;
`;

const MenuContainer = styled(FlexContainer)`
  position: relative;
`;

const MenuButton = styled(ButtonS)<{ isActive: boolean }>`
  min-width: 32px;
  padding: 0;
  background: transparent;
  ${({ isActive }) =>
    isActive &&
    css`
      &:hover {
        background: ${theme.basePrimary};
        > svg {
          fill: ${theme.white};
        }
      }
    `}
`;

const Menu = styled.div`
  position: absolute;
  z-index: 2;
  top: 40px;
  right: 0;
  min-width: max-content;
  padding: 24px 0 16px 0;
  border-radius: 8px;
  background-color: ${theme.baseWhite};
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.16);
`;

const StyledIconClose = styled(FiX)`
  position: absolute;
  z-index: 3;
  top: 6px;
  right: 6px;
  cursor: pointer;
`;

const MissionFormMenu: React.VFC = () => {
  const {
    isPublished,
    showMenu,
    myMissions,
    openMenu,
    closeMenu,
    handleClickMenuAdd,
    handleClickMenuDelete,
    handleClickMenuUnPublish,
    missionAccount,
    modalState,
    handleClickAddModalOk,
    handleCloseAddCompleteModal,
    handleClickDeleteModalOk,
    closeModal,
    handleCloseDeleteCompleteModal,
  } = useMissionFormContext();

  const { t } = useTranslation('missionSetting');

  return useMemo(
    () => (
      <>
        <Wrapper>
          <MenuContainer>
            <MenuButton
              isActive={!!myMissions.find(m => m.published)}
              onClick={openMenu}
              data-testid="mission-setting-menu-button"
            >
              <VerticalEllipsis />
            </MenuButton>
            {showMenu && (
              <>
                <Overlay onDismiss={closeMenu} />
                <Menu data-testid="mission-setting-menu">
                  <StyledIconClose
                    size={14}
                    color={theme.textPrimary}
                    onClick={closeMenu}
                  />
                  <MissionSettingMenuItem
                    disabled={myMissions.length >= MISSION_MAX_COUNT}
                    onClick={handleClickMenuAdd}
                    key="menu-add"
                  >
                    {t('menu.add')}
                  </MissionSettingMenuItem>
                  <MissionSettingMenuItem
                    disabled={!isPublished}
                    onClick={handleClickMenuUnPublish}
                    key="menu-unpublish"
                  >
                    {t('menu.unpublish')}
                  </MissionSettingMenuItem>
                  <MissionSettingMenuItem
                    disabled={myMissions.length < 2}
                    onClick={handleClickMenuDelete}
                    key="menu-delete"
                  >
                    {t('menu.delete')}
                  </MissionSettingMenuItem>
                </Menu>
              </>
            )}
          </MenuContainer>
        </Wrapper>
        <AddConfirmationModal
          show={
            modalState.show && modalState.content === 'addMissionConfirmation'
          }
          onOk={handleClickAddModalOk}
          onCancel={closeModal}
        />
        <AddCompleteModal
          show={modalState.show && modalState.content === 'addMissionCompleted'}
          onClose={handleCloseAddCompleteModal}
        />
        <DeleteConfirmationModal
          show={
            modalState.show &&
            modalState.content === 'deleteMissionConfirmation'
          }
          missionId={missionAccount?.missionId}
          missionName={missionAccount?.name}
          imageUrl={imageSelector(missionAccount?.coverImage, 'small')}
          onClickOk={handleClickDeleteModalOk}
          onClickCancel={closeModal}
        />
        <DeleteCompleteModal
          show={
            modalState.show && modalState.content === 'deleteMissionCompleted'
          }
          onClose={handleCloseDeleteCompleteModal}
        />
      </>
    ),
    [
      closeMenu,
      closeModal,
      handleClickAddModalOk,
      handleClickDeleteModalOk,
      handleClickMenuAdd,
      handleClickMenuDelete,
      handleClickMenuUnPublish,
      handleCloseAddCompleteModal,
      handleCloseDeleteCompleteModal,
      isPublished,
      missionAccount,
      modalState.content,
      modalState.show,
      myMissions,
      openMenu,
      showMenu,
      t,
    ],
  );
};

export default MissionFormMenu;
