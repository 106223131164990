import React, { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { MissionSettingMenuItem } from 'components/molecules';
import { HeaderContent } from '../../../Contents';
import useSupporterFormContext from '../../useSupporterFormContext';

const HeaderContainer: FC = () => {
  const {
    isPublished,
    isDisabledSaveButton,
    showMenu,
    openMenu,
    closeMenu,
    showRequiredFieldsTitle,
    headerRef,
    handleClickPreview,
    saveInput,
    handleClickMenuUnPublish,
  } = useSupporterFormContext();

  const { t } = useTranslation('missionSetting');

  return useMemo(
    () => (
      <HeaderContent
        showMenu={showMenu}
        onOpen={openMenu}
        onClose={closeMenu}
        showRequiredTitle={showRequiredFieldsTitle}
        onClickPreview={handleClickPreview}
        onClickSave={saveInput}
        disabledSaveButton={isDisabledSaveButton}
        menuItems={[
          <MissionSettingMenuItem
            disabled={!isPublished}
            onClick={handleClickMenuUnPublish}
            key="menu-unpublish"
          >
            {t('menu.unpublish')}
          </MissionSettingMenuItem>,
        ]}
        isPublished={isPublished}
        isActive={isPublished}
        ref={headerRef}
      />
    ),
    [
      closeMenu,
      handleClickMenuUnPublish,
      handleClickPreview,
      headerRef,
      isDisabledSaveButton,
      isPublished,
      openMenu,
      saveInput,
      showMenu,
      showRequiredFieldsTitle,
      t,
    ],
  );
};

export default HeaderContainer;
