import { lazy } from 'react';

const MissionsList = lazy(
  () =>
    import(
      /* webpackChunkName: 'missionsList' */ 'components/pages/Private/Missions/List'
    ),
);
const MissionsDetail = lazy(
  () =>
    import(
      /* webpackChunkName: 'missionsDetail' */ 'components/pages/Private/Missions/Detail'
    ),
);
const SupportersList = lazy(
  () =>
    import(
      /* webpackChunkName: 'supportersList' */ 'components/pages/Private/Supporters/List'
    ),
);
const SupportersDetail = lazy(
  () =>
    import(
      /* webpackChunkName: 'supportersDetail' */ 'components/pages/Private/Supporters/Detail'
    ),
);
const ProductsList = lazy(
  () =>
    import(
      /* webpackChunkName: 'productsList' */ 'components/pages/Private/Products/List'
    ),
);
const FollowersMissionsList = lazy(
  () =>
    import(
      /* webpackChunkName: 'followersMissions' */ 'components/pages/Private/Followers/Missions/List'
    ),
);
const FollowersInnovatorList = lazy(
  () =>
    import(
      /* webpackChunkName: 'followersInnovators' */ 'components/pages/Private/Followers/Innovators/List'
    ),
);
const FollowingMissionsList = lazy(
  () =>
    import(
      /* webpackChunkName: 'followingMissionsList' */ 'components/pages/Private/Following/Missions/List'
    ),
);
const Messages = lazy(
  () =>
    import(
      /* webpackChunkName: 'messages' */ 'components/pages/Private/Messages'
    ),
);

const HumanResourcesCareerList = lazy(
  () =>
    import(
      /* webpackChunkName: 'humanResourcesCareerList' */ 'components/pages/Private/HumanResources/Career/List'
    ),
);
const HumanResourcesNewGraduateList = lazy(
  () =>
    import(
      /* webpackChunkName: 'humanResourcesNewGraduateList' */ 'components/pages/Private/HumanResources/NewGraduate/List'
    ),
);
const FollowingHumanResourcesList = lazy(
  () =>
    import(
      /* webpackChunkName: 'followingHumanResourcesList' */ 'components/pages/Private/Following/HumanResources/List'
    ),
);

const SettingsMission = lazy(
  () =>
    import(
      /* webpackChunkName: 'settingsMission' */ 'components/pages/Private/Settings/Mission/Mission'
    ),
);
const SettingsMessageTemplate = lazy(
  () =>
    import(
      /* webpackChunkName: 'settingsMessageTemplate' */ 'components/pages/Private/Settings/MessageTemplate/MessageTemplate'
    ),
);
const SettingsAccount = lazy(
  () =>
    import(
      /* webpackChunkName: 'settingsAccount' */ 'components/pages/Private/Settings/Account/Account'
    ),
);

export {
  MissionsList,
  MissionsDetail,
  SupportersList,
  SupportersDetail,
  ProductsList,
  FollowersMissionsList,
  FollowersInnovatorList,
  FollowingMissionsList,
  FollowingHumanResourcesList,
  Messages,
  HumanResourcesCareerList,
  HumanResourcesNewGraduateList,
  SettingsMission,
  SettingsMessageTemplate,
  SettingsAccount,
};
