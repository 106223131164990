import React, { FC, useCallback } from 'react';
import styled, { css } from 'styled-components/macro';
import theme from 'styles/theme';
import typography from 'styles/typography';
import { TemporaryChatMessage } from 'types/chat';
import { ImageCircle } from 'components/atoms';
import { useTranslation } from 'react-i18next';
import MessageBalloon from './MessageBalloon';

const ICON_DIAMETER = '56px';

const Wrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-bottom: 32px;
`;

const BalloonWrapper = styled.div`
  margin-top: 24px;
  margin-right: 6px;
`;

const Note = styled.div`
  ${typography.textSub};
  margin-top: 4px;
  color: ${theme.textSecondary};
  text-align: right;
`;

const Delete = styled.div`
  display: inline-block;
  color: ${theme.baseError};
  cursor: pointer;

  :hover {
    text-decoration: underline;
  }
`;

const Resend = styled.div<{ disabled: boolean }>`
  display: inline-block;
  color: ${theme.bgChatRight};
  cursor: pointer;

  :hover {
    text-decoration: underline;
  }

  ${({ disabled }) =>
    disabled &&
    css`
      pointer-events: none;
    `}
`;

type TemporaryMessageCardProps = {
  message: TemporaryChatMessage;
  resend: boolean;
  disabled: boolean;
  ownIconImage: string;
  showProgress?: boolean;
  progress?: number;
  onResendMessage: (message: TemporaryChatMessage) => void;
  onDeleteMessage: (messageId: TemporaryChatMessage['messageId']) => void;
  onDownloadFile: (message: TemporaryChatMessage) => void;
};

const TemporaryMessageCard: FC<TemporaryMessageCardProps> = props => {
  const {
    message,
    resend,
    onResendMessage,
    onDeleteMessage,
    onDownloadFile,
    ownIconImage,
    disabled,
    showProgress = false,
    progress = 0,
  } = props;

  const { t } = useTranslation(['chat']);

  const handleClickResendIcon = useCallback(() => {
    if (disabled) return;
    onResendMessage(message);
  }, [disabled, message, onResendMessage]);

  const handleClickDeleteIcon = useCallback(
    () => onDeleteMessage(message.messageId),
    [message.messageId, onDeleteMessage],
  );

  const handleClickDownloadLink = useCallback(() => onDownloadFile(message), [
    message,
    onDownloadFile,
  ]);

  return (
    <Wrapper>
      <BalloonWrapper>
        <MessageBalloon
          message={message}
          isTalkTo={false}
          active={false}
          progress={progress}
          showProgress={showProgress}
          onClickDownloadLink={handleClickDownloadLink}
        />
        {resend && (
          <Note>
            <div>{t('SendMessageFailed')}</div>
            <Delete onClick={handleClickDeleteIcon}>{t('delete')}</Delete>・
            <Resend
              onClick={handleClickResendIcon}
              disabled={disabled}
              data-testid="resend"
            >
              {t('resend')}
            </Resend>
          </Note>
        )}
      </BalloonWrapper>
      <ImageCircle imageSrc={ownIconImage} diameter={ICON_DIAMETER} border />
    </Wrapper>
  );
};

export default TemporaryMessageCard;
