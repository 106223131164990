import { ComponentProps } from 'react';
import styled from 'styled-components/macro';
import { SelectableTag } from 'components/atoms';
import theme from 'styles/theme';

type colors = {
  backgroundColor?: string;
  inactiveBackgroundColor?: string;
  inactiveTextColor?: string;
};

type SelectableTagProps = ComponentProps<typeof SelectableTag> & {
  colors?: colors;
};

const categoryStyle = ({ colors }: SelectableTagProps) => {
  if (!colors) return;

  return `
    input {
      & + label {
        background: ${colors.inactiveBackgroundColor};
        color: ${colors.inactiveTextColor};
        border: none;
      }

      &:checked + label {
        background: ${colors.backgroundColor};
        color: ${theme.baseWhite};
      }
    }
  `;
};

const CategorySelectableTag = styled(SelectableTag)`
  &&& {
    ${categoryStyle}

    label {
      user-select: none;
    }
  }
`;

export default CategorySelectableTag;
