import { useState, useRef } from 'react';
import useGenerateKey from './useGenerateKey';
import { ValidateToSaveErrors } from '../validate/validateToSave';
import useModal from './useModal';
import useImageCropper from './useImageCropper';

const useMissionSettingFormState = () => {
  const { getAddedItemId } = useGenerateKey();
  const { modalState, openModal, closeModal } = useModal();
  const {
    imageCropperState,
    closeImageCropper,
    openImageCropper,
    saveImageCropper,
    cropperAspectRatio,
  } = useImageCropper();
  const [error, setError] = useState<null | string>(null);
  const [isPublished, setIsPublished] = useState<boolean>(false);
  const [showMenu, setShowMenu] = useState<boolean>(false);
  const [showPreview, setShowPreview] = useState<boolean>(false);
  const [showRequiredFieldsTitle, setShowRequiredFieldsTitle] = useState<
    boolean
  >(true);
  const [footerWidth, setFooterWidth] = useState<number | undefined>(undefined);
  const [validateToSaveErrors, setValidateToSaveErrors] = useState<
    ValidateToSaveErrors
  >();
  const headerRef = useRef<HTMLDivElement>(null);
  const optionalFieldsTitleRef = useRef<HTMLDivElement>(null);
  const formWrapperRef = useRef<HTMLDivElement>(null);

  return {
    getAddedItemId,
    error,
    setError,
    isPublished,
    setIsPublished,
    showMenu,
    setShowMenu,
    showPreview,
    setShowPreview,
    showRequiredFieldsTitle,
    setShowRequiredFieldsTitle,
    footerWidth,
    setFooterWidth,
    modalState,
    openModal,
    closeModal,
    imageCropperState,
    closeImageCropper,
    openImageCropper,
    saveImageCropper,
    cropperAspectRatio,
    validateToSaveErrors,
    setValidateToSaveErrors,
    headerRef,
    optionalFieldsTitleRef,
    formWrapperRef,
  };
};

export type MissionSettingFormState = ReturnType<
  typeof useMissionSettingFormState
>;

export default useMissionSettingFormState;
