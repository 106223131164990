import React, { FC, ChangeEvent } from 'react';
import styled from 'styled-components/macro';
import { useTranslation } from 'react-i18next';
import { isEqualProps } from 'utils/object';
import { MissionFormValues } from 'types/mission';
import { MissionSettingMaxLength } from 'constants/form';
import { Textarea } from 'components/atoms';
import { FormItemLabel, MissionSettingFormItem } from 'components/molecules';

const StyledTextarea = styled(Textarea)`
  width: 445px;

  textarea {
    min-height: 402px;
    line-height: 1.5;
  }
`;

type MissionDescriptionFieldProps = {
  value: MissionFormValues['missionDescription'];
  error: string | undefined;
  onChange: (e: ChangeEvent<HTMLTextAreaElement>) => void;
  onBlur: (e: any) => void;
};

const MissionDescriptionField: FC<MissionDescriptionFieldProps> = props => {
  const { value, error, onChange, onBlur } = props;

  const { t } = useTranslation('missionSetting');

  return (
    <MissionSettingFormItem required error={error}>
      <FormItemLabel
        label={t('field.missionDescription.label')}
        note={t('field.missionDescription.note', {
          maxLength: MissionSettingMaxLength.MISSION_DESCRIPTION,
        })}
        required
      />
      <StyledTextarea
        maxLength={MissionSettingMaxLength.MISSION_DESCRIPTION}
        name="missionDescription"
        value={value}
        placeholder={t('field.missionDescription.placeholder')}
        onChange={onChange}
        onBlur={onBlur}
        aria-label={t('field.missionDescription.label')}
      />
    </MissionSettingFormItem>
  );
};

export default React.memo(MissionDescriptionField, (prevProps, nextProps) =>
  isEqualProps(prevProps, nextProps, ['value', 'error']),
);
