import React, { FC, useState, useCallback, useEffect } from 'react';
import styled, { css } from 'styled-components/macro';
import { useTranslation, Trans } from 'react-i18next';
import { useLocalStorage } from '@rehooks/local-storage';
import theme from 'styles/theme';
import typography from 'styles/typography';
import { userLanguage } from 'i18n/i18n';
import * as EXTERNAL_LINK from 'constants/externalLink';
import { LocalStorageKey } from 'constants/storage';
import { ButtonM } from 'components/atoms';

const Wrapper = styled.div<{ show: boolean }>`
  display: flex;
  position: fixed;
  z-index: -1;
  bottom: 16px;
  left: calc(50% - 874px / 2);
  align-items: center;
  justify-content: space-between;
  padding: 16px 32px;
  transition: opacity 1.5s;
  border-radius: 8px;
  opacity: 0;

  /* theme.bgMenuDefault, Use rgba for opacity */
  background-color: rgba(47, 48, 50, 0.9);
  box-shadow: 0 0 16px rgba(0, 0, 0, 0.14);
  ${({ show }) =>
    show &&
    css`
      z-index: 10;
      opacity: 1;
    `}
`;

const Description = styled.p`
  ${typography.textDefault}
  width: 618px;
  margin-right: 32px;
  color: ${theme.baseWhite};
`;

const AgreeButton = styled(ButtonM).attrs({
  buttonTheme: 'default',
})`
  width: 160px;
  height: auto;
  padding: 14px 15px 13px 15px;
`;

const CookiePolicyLink = styled.a`
  color: ${theme.baseWhite};

  &:hover {
    opacity: 0.8;
    color: inherit;
  }
`;

const CookieAgreementNotification: FC = () => {
  const [isContentLoaded, setIsContentLoaded] = useState<boolean>(false);
  const [isAgreedToCookie, setIsAgreedToCookie] = useLocalStorage<boolean>(
    LocalStorageKey.IS_AGREED_TO_COOKIE,
    false,
  );
  const { t } = useTranslation('cookieAgreement');

  const handleClickAgreeButton = useCallback(() => {
    setIsAgreedToCookie(true);
  }, [setIsAgreedToCookie]);

  useEffect(() => {
    // for animation
    setIsContentLoaded(true);
  }, []);

  return (
    <Wrapper show={isContentLoaded && !isAgreedToCookie}>
      <Description>
        <Trans i18nKey="description.useCookie" ns="cookieAgreement">
          当Webサイトでは、サイトの利便性の向上を目的にクッキーを使用します。ブラウザの設定により
          クッキーの機能を変更することもできます。詳細は
          <CookiePolicyLink
            href={
              userLanguage === 'ja'
                ? EXTERNAL_LINK.COOKIE_POLICY
                : EXTERNAL_LINK.COOKIE_POLICY_EN
            }
            target="_blank"
            rel="noopener noreferrer"
          >
            クッキーポリシー
          </CookiePolicyLink>
          を参照ください。
        </Trans>
      </Description>
      <AgreeButton onClick={handleClickAgreeButton}>
        {t('button.agree')}
      </AgreeButton>
    </Wrapper>
  );
};

export default CookieAgreementNotification;
