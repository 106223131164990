import React, { useCallback, useState, useMemo } from 'react';
import { MdKeyboardArrowDown } from 'react-icons/md';
import { Overlay } from 'components/molecules';
import styled, { css } from 'styled-components/macro';
import theme from 'styles/theme';
import scrollbar from 'styles/scrollbar';

type OptionsPosition = 'top' | 'bottom';

const SelectboxRoot = styled.div<{ width?: number; disabled: boolean }>`
  position: relative;
  ${({ width }) =>
    width &&
    css`
      width: ${width}px;
    `}
  ${({ disabled }) =>
    disabled &&
    css`
      pointer-events: none;
    `}
`;

const InputBase = styled.div<{ disabled: boolean }>`
  display: flex;
  padding: 5px 8px;
  padding-right: 4px;
  border: 1px solid ${theme.borderDefault};
  border-radius: 3px;
  background-color: ${theme.baseWhite};
  line-height: 1;
  cursor: pointer;
  ${({ disabled }) =>
    disabled &&
    css`
      background-color: ${theme.baseDisabled};
      color: ${theme.textSecondary};
    `}
`;

const SelectedValue = styled.div`
  width: calc(100% - 20px);
  margin: auto;
  overflow: hidden;
  text-align: left;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const maxHeight = 36 * 7 + 12; // optionHeight * row + padding;

const OptionsPanel = styled.div<{ position: OptionsPosition }>`
  position: absolute;
  z-index: 2;
  left: 0;
  width: 100%;
  max-height: ${maxHeight}px;
  overflow-x: hidden;
  overflow-y: auto;
  border: 1px solid ${theme.borderDefault};
  border-radius: 3px;
  background: ${theme.baseWhite};
  ${scrollbar};
  /* stylelint-disable property-no-vendor-prefix */
  ::-webkit-scrollbar {
    background-color: transparent;
  }

  ${({ position }) =>
    position === 'bottom' &&
    css`
      top: 36px;
    `}
  ${({ position }) =>
    position === 'top' &&
    css`
      bottom: 36px;
    `}
`;

const SelectboxOption = styled.li<{ isSelected: boolean }>`
  display: inline-flex;
  width: 100%;
  padding: 10px 16px;
  text-align: left;
  cursor: pointer;

  &:hover {
    background-color: ${theme.labelDefault};
    color: ${theme.baseWhite};
  }

  & > *:first-child {
    width: calc(100% - 18px);
    overflow: hidden;
    font-weight: 500;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  ${({ isSelected }) =>
    isSelected &&
    css`
      background-color: ${theme.labelDefault};
      color: ${theme.baseWhite};
      cursor: default;
      pointer-events: none;
    `}
`;

type OptionValue = string | number;

type Props<Option> = {
  placeholder: string;
  options: Option[];
  value: OptionValue;
  idName: keyof Option;
  textName: keyof Option;
  width: number;
  testId: string;
  onChange: (selectedOption: Option) => void;
  /* eslint-disable react/require-default-props */
  optionsPosition?: OptionsPosition;
  noSelectOption?: Option;
  disabled?: boolean;
  /* eslint-enable react/require-default-props */
};

function DecoratedSelectbox<Option extends { [key: string]: OptionValue }>({
  placeholder,
  value,
  options,
  width,
  idName,
  textName,
  testId,
  onChange,
  optionsPosition = 'bottom',
  noSelectOption,
  disabled = false,
}: Props<Option>) {
  const [expanded, setExpanded] = useState(false);

  const handleClickBase = useCallback(() => {
    setExpanded(prev => !prev);
  }, []);

  const selectedOption = useMemo(
    () => options.find(option => option[idName] === value),
    [idName, options, value],
  );

  return (
    <SelectboxRoot width={width} disabled={disabled}>
      <InputBase
        role="button"
        aria-haspopup
        area-expanded={expanded}
        onClick={handleClickBase}
        disabled={disabled}
        data-testid={testId}
      >
        <SelectedValue>
          {selectedOption?.[textName] ?? placeholder}
        </SelectedValue>
        <MdKeyboardArrowDown fill={theme.textPrimary} size={20} />
      </InputBase>
      {expanded && (
        <>
          <Overlay onDismiss={() => setExpanded(false)} />
          <OptionsPanel
            data-testid="decorated-selectbox-options-panel"
            position={optionsPosition}
          >
            <ul role="listbox">
              {noSelectOption && (
                <SelectboxOption
                  onClick={() => onChange(noSelectOption)}
                  isSelected={noSelectOption[idName] === value}
                  data-testid="decorated-selectbox-option-no-select"
                >
                  <div>{noSelectOption[textName]}</div>
                </SelectboxOption>
              )}
              {options.map((option, index) => (
                <SelectboxOption
                  key={option[idName]}
                  onClick={() => onChange(option)}
                  data-testid={`decorated-selectbox-option${index}`}
                  isSelected={option[idName] === value}
                >
                  <div>{option[textName]}</div>
                </SelectboxOption>
              ))}
            </ul>
          </OptionsPanel>
        </>
      )}
    </SelectboxRoot>
  );
}

export default DecoratedSelectbox;
