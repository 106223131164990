import React, { FC, ComponentProps } from 'react';
import { ButtonM } from 'components/atoms';
import { Modal, ModalContent, ModalFooter } from 'components/molecules';

type ConfirmModalProps = ComponentProps<typeof Modal> & {
  cancelCaption?: string;
  confirmCaption?: string;
  confirm: () => void;
};

const ConfirmModal: FC<ConfirmModalProps> = props => {
  const {
    cancelCaption,
    closeModal,
    confirm,
    confirmCaption,
    children,
  } = props;
  return (
    <Modal {...props}>
      <ModalContent data-testid="modal-confirm">{children}</ModalContent>
      <ModalFooter>
        {cancelCaption && (
          <ButtonM buttonTheme="cancel" onClick={closeModal}>
            {cancelCaption}
          </ButtonM>
        )}

        <ButtonM
          buttonTheme="default"
          onClick={confirm}
          data-testid="confirm_modal.ok"
        >
          {confirmCaption}
        </ButtonM>
      </ModalFooter>
    </Modal>
  );
};

export default ConfirmModal;
