import { useCallback, useRef, useState, useEffect } from 'react';
import { useFormik } from 'formik';
import { string, object } from 'yup';
import { useDispatch } from 'react-redux';
import { UiActions } from 'modules/ui';
import apiMessageTemplate from 'external/api/messageTemplate';
import RequestError from 'classes/RequestError';
import { MessageTemplate } from 'proto/v1/apimodel/apimodel';
import { SCOUT_MESSAGE_MAX_LENGTH } from 'constants/models/scout';
import useErrorDialog from 'hooks/useErrorDialog';
import { MessageTemplateFormValues } from 'types/messageTemplate';

type Values = {
  message: string;
};

type Payload = {
  onScout: (message: string) => Promise<void>;
  insertTexts: {
    memberName: string;
    companyName: string;
    schoolName: string;
  };
  onUseMessageTemplate: (messageTemplate: MessageTemplate) => Promise<string>;
};

const useScoutMessageForm = ({
  onScout,
  insertTexts,
  onUseMessageTemplate,
}: Payload) => {
  const dispatch = useDispatch();
  const { handleRequestError } = useErrorDialog();
  const [showMessageTemplate, setShowMessageTemplate] = useState<boolean>(
    false,
  );
  const [
    showMessageTemplateFormModal,
    setShowMessageTemplateFormModal,
  ] = useState<boolean>(false);
  const [
    showMessageTemplateSavedModal,
    setShowMessageTemplateSavedModal,
  ] = useState<boolean>(false);
  const [showScoutConfirmModal, setShowScoutConfirmModal] = useState(false);
  const [messageTemplates, setMessageTemplates] = useState<MessageTemplate[]>(
    [],
  );
  const messageTextareaRef = useRef<HTMLTextAreaElement>(null);

  const formik = useFormik<Values>({
    initialValues: {
      message: '',
    },
    validationSchema: object({
      message: string().required().max(SCOUT_MESSAGE_MAX_LENGTH),
    }),
    onSubmit: ({ message }) => onScout(message),
  });

  const { setFieldValue, values } = formik;

  const insertText = useCallback(
    (valueToInsert: string) => {
      const pos = messageTextareaRef.current?.selectionStart ?? 0;
      setFieldValue(
        'message',
        `${values.message.slice(0, pos)}${valueToInsert}${values.message.slice(
          pos,
        )}`.slice(0, SCOUT_MESSAGE_MAX_LENGTH),
      );
      messageTextareaRef.current?.focus();
    },
    [setFieldValue, values.message],
  );

  const fetchMessageTemplates = useCallback(async () => {
    let data;
    dispatch(UiActions.setLoading(true));
    try {
      ({ data } = await apiMessageTemplate.getMessageTemplates());
    } catch (error: unknown) {
      dispatch(UiActions.setLoading(false));
      if (error instanceof RequestError) {
        handleRequestError(error);
      }
      return;
    }
    dispatch(UiActions.setLoading(false));
    setMessageTemplates(data.messageTemplates);
  }, [dispatch, handleRequestError]);

  const handleClickMemberNameMenu = useCallback(() => {
    insertText(insertTexts.memberName);
  }, [insertText, insertTexts.memberName]);

  const handleClickSchoolNameMenu = useCallback(() => {
    insertText(insertTexts.schoolName);
  }, [insertText, insertTexts.schoolName]);

  const handleClickCompanyNameMenu = useCallback(() => {
    insertText(insertTexts.companyName);
  }, [insertText, insertTexts.companyName]);

  const handleClickTemplateMenu = useCallback(async () => {
    setShowMessageTemplate(!showMessageTemplate);
  }, [showMessageTemplate]);

  const handleSelectMessageTemplate = useCallback(
    async (messageTemplate: MessageTemplate) => {
      const message = await onUseMessageTemplate(messageTemplate);
      setFieldValue('message', message);
      messageTextareaRef.current?.focus();
      setShowMessageTemplate(false);
    },
    [onUseMessageTemplate, setFieldValue],
  );

  const openMessageTemplateFormModal = useCallback(() => {
    setShowMessageTemplateFormModal(true);
  }, []);

  const closeMessageTemplateFormModal = useCallback(() => {
    setShowMessageTemplateFormModal(false);
  }, []);

  const openMessageTemplateSavedModal = useCallback(() => {
    setShowMessageTemplateSavedModal(true);
  }, []);

  const closeMessageTemplateSavedModal = useCallback(() => {
    setShowMessageTemplateSavedModal(false);
  }, []);

  const openScoutConfirmModal = useCallback(() => {
    setShowScoutConfirmModal(true);
  }, []);

  const closeScoutConfirmModal = useCallback(() => {
    setShowScoutConfirmModal(false);
  }, []);

  const handleSaveMessageTemplate = useCallback(
    async (messageTemplateValues: MessageTemplateFormValues) => {
      dispatch(UiActions.setLoading(true));
      try {
        await apiMessageTemplate.registerMessageTemplate({
          name: messageTemplateValues.name,
          template: messageTemplateValues.template,
        });
      } catch (error: unknown) {
        dispatch(UiActions.setLoading(false));
        if (error instanceof RequestError) {
          handleRequestError(error);
        }
        return;
      }
      fetchMessageTemplates();
      closeMessageTemplateFormModal();
      openMessageTemplateSavedModal();
      dispatch(UiActions.setLoading(false));
    },
    [
      closeMessageTemplateFormModal,
      dispatch,
      fetchMessageTemplates,
      handleRequestError,
      openMessageTemplateSavedModal,
    ],
  );

  const handleBlurMessageTemplate = useCallback(() => {
    setShowMessageTemplate(false);
  }, []);

  useEffect(() => {
    fetchMessageTemplates();
  }, [fetchMessageTemplates]);

  return {
    formik,
    messageTextareaRef,
    handleClickMemberNameMenu,
    handleClickSchoolNameMenu,
    handleClickCompanyNameMenu,
    handleClickTemplateMenu,
    handleSelectMessageTemplate,
    handleSaveMessageTemplate,
    showMessageTemplateFormModal,
    openMessageTemplateFormModal,
    closeMessageTemplateFormModal,
    showMessageTemplateSavedModal,
    openMessageTemplateSavedModal,
    closeMessageTemplateSavedModal,
    showScoutConfirmModal,
    openScoutConfirmModal,
    closeScoutConfirmModal,
    messageTemplates,
    showMessageTemplate,
    handleBlurMessageTemplate,
  };
};

export default useScoutMessageForm;
