import React, { FC } from 'react';
import styled from 'styled-components/macro';
import { Matter } from 'proto/v1/apimodel/apimodel';
import { isEqualProps } from 'utils/object';
import {
  CareerHeadField,
  CareerMainField,
  CareerJobHistoryField,
  SelfIntroductionField,
} from './Fields';

const Card = styled.div`
  width: 864px;
`;

const CardBody = styled.div``;

type Props = {
  matter: Matter;
  isFavorite?: boolean;
  onClickFavorite?: (matter: Matter, favorite: boolean) => void;
};

const HumanResourceCareerDetailCard: FC<Props> = ({
  matter,
  isFavorite,
  onClickFavorite,
}) => {
  const { humanResource } = matter;

  return (
    <Card
      id={`matter-${matter.matterId}`}
      data-testid="human-resource-career-detail-card"
    >
      <CareerHeadField
        matter={matter}
        isFavorite={isFavorite}
        onClickFavorite={onClickFavorite}
        isDetail
      />
      <CardBody>
        <CareerMainField matter={matter} isDetail />
        {humanResource?.career?.job &&
          humanResource.career.job.map((jobHistory, index) => (
            <CareerJobHistoryField
              jobHistory={jobHistory}
              background={index % 2 === 0}
              // eslint-disable-next-line react/no-array-index-key
              key={index}
            />
          ))}
        <SelfIntroductionField
          selfIntroduction={matter.humanResource?.selfIntroduction}
          background={(humanResource?.career?.job?.length ?? 0) % 2 === 0}
        />
      </CardBody>
    </Card>
  );
};

export default React.memo(
  HumanResourceCareerDetailCard,
  (prevProps, nextProps) => isEqualProps(prevProps, nextProps),
);
