import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { ButtonM } from 'components/atoms';
import { Modal, ModalContent, ModalFooter } from 'components/molecules';

type UnpublishConfirmationModalProps = {
  show: boolean;
  onOk: () => void;
  onCancel: () => void;
};

const UnpublishConfirmationModal: FC<UnpublishConfirmationModalProps> = ({
  show,
  onOk,
  onCancel,
}) => {
  const { t } = useTranslation('missionSetting');
  return (
    <Modal
      type={'unPublishMissionConfirmation'}
      show={show}
      closeModal={onCancel}
    >
      <ModalContent data-testid="modal-un-publish-mission-confirmation">
        {t('modal.unPublishMissionConfirmation.content')}
      </ModalContent>

      <ModalFooter>
        <ButtonM onClick={onOk} buttonTheme="default" type="button">
          {t('common:button.ok')}
        </ButtonM>
      </ModalFooter>
    </Modal>
  );
};

export default UnpublishConfirmationModal;
