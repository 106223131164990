export const formatDate = (date: Date, fmt: string) => {
  let format = fmt;
  format = format.replace(/yyyy/g, String(date.getFullYear()));
  format = format.replace(/MM/g, `0${date.getMonth() + 1}`.slice(-2));
  format = format.replace(/dd/g, `0${date.getDate()}`.slice(-2));
  format = format.replace(/HH/g, `0${date.getHours()}`.slice(-2));
  format = format.replace(/mm/g, `0${date.getMinutes()}`.slice(-2));
  format = format.replace(/ss/g, `0${date.getSeconds()}`.slice(-2));
  format = format.replace(/SSS/g, `00${date.getMilliseconds()}`.slice(-3));
  return format;
};

export const formatTimestamp = (timestamp: number) => {
  const date = new Date(timestamp);
  const now = new Date();
  if (
    now.getFullYear() === date.getFullYear() &&
    now.getMonth() === date.getMonth() &&
    now.getDate() === date.getDate()
  ) {
    return formatDate(date, 'HH:mm');
  }
  if (now.getFullYear() === date.getFullYear()) {
    return formatDate(date, 'MM/dd');
  }
  return formatDate(date, 'yyyy/MM/dd');
};
