import React, { FC, ChangeEvent } from 'react';
import styled from 'styled-components/macro';
import { useTranslation } from 'react-i18next';
import { isEqualProps } from 'utils/object';
import { MissionSettingMaxLength } from 'constants/form';
import { Checkbox, Textarea } from 'components/atoms';
import { MissionDemandFieldItem } from 'components/molecules';

const StyledCheckbox = styled(Checkbox)`
  &&& {
    margin-bottom: 0;
  }
`;

const StyledTextarea = styled(Textarea)`
  position: relative;
  width: 445px;

  textarea {
    min-height: 97px;
    line-height: 1.5;
  }

  small {
    display: block;
    position: absolute;
    bottom: -14px;
    left: 0;
    width: auto;
  }
`;

type AcceptSecondmentFieldProps = {
  wantAcceptSecondment: boolean;
  work: string;
  onChangeWantAcceptSecondment: (e: ChangeEvent<HTMLInputElement>) => void;
  onChangeWork: (e: ChangeEvent<HTMLTextAreaElement>) => void;
  onBlur: (e: any) => void;
};

const AcceptSecondmentField: FC<AcceptSecondmentFieldProps> = props => {
  const {
    wantAcceptSecondment,
    work,
    onChangeWantAcceptSecondment,
    onChangeWork,
    onBlur,
  } = props;

  const { t } = useTranslation('missionSetting');

  return (
    <MissionDemandFieldItem>
      <StyledCheckbox
        name="missionDemand.wantAcceptSecondment"
        checked={wantAcceptSecondment}
        onChange={onChangeWantAcceptSecondment}
        aria-label={t('field.missionDemand.checkbox.wantAcceptSecondment')}
      >
        {t('field.missionDemand.checkbox.wantAcceptSecondment')}
      </StyledCheckbox>
      {wantAcceptSecondment && (
        <StyledTextarea
          name={`missionDemand.acceptSecondment.work`}
          value={work}
          maxLength={
            MissionSettingMaxLength.MISSION_DEMAND_ACCEPT_SECONDMENT_WORK
          }
          placeholder={t('field.missionDemand.acceptSecondment.placeholder', {
            maxLength:
              MissionSettingMaxLength.MISSION_DEMAND_ACCEPT_SECONDMENT_WORK,
          })}
          onChange={onChangeWork}
          onBlur={onBlur}
          data-testid="accept-secondment-work"
        />
      )}
    </MissionDemandFieldItem>
  );
};

export default React.memo(AcceptSecondmentField, (prevProps, nextProps) =>
  isEqualProps(prevProps, nextProps, ['wantAcceptSecondment', 'work']),
);
