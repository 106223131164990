import React, { FC, CSSProperties } from 'react';
import styled from 'styled-components/macro';

const StyledModalContent = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 212px;
  line-height: 1.5;
  text-align: center;
  word-wrap: break-word;
  white-space: pre-wrap;
`;

const ModalContent: FC<{ style?: CSSProperties }> = props => {
  return <StyledModalContent {...props}>{props.children}</StyledModalContent>;
};

export default ModalContent;
