import React, { FC, CSSProperties } from 'react';
import styled from 'styled-components/macro';
import theme from 'styles/theme';
import typography from 'styles/typography';

const Wrapper = styled.span`
  ${typography.textNote}
  color: ${theme.textSecondary};
  font-weight: normal;
`;

const NoteItem = styled.span`
  &:not(:first-child) {
    margin-left: 10px;
  }
`;

type NoteProps = {
  note: string | string[];
  style?: CSSProperties;
};

const Note: FC<NoteProps> = ({ note, ...props }) => (
  <Wrapper {...props}>
    {(Array.isArray(note) ? note : [note]).map((n, i) => (
      <NoteItem key={i.toString()}>{n}</NoteItem>
    ))}
  </Wrapper>
);

export default Note;
