import styled, { css } from 'styled-components/macro';
import { CircleArrowRightGray } from 'assets/svg';
import theme from 'styles/theme';

const HumanResourceCardNextIcon = styled(CircleArrowRightGray)<{
  disabled: boolean;
}>`
  cursor: pointer;

  &:hover {
    opacity: 0.8;

    circle {
      fill: ${theme.bgHumanResourceIconHover};
    }
  }

  ${({ disabled }) =>
    disabled &&
    css`
      opacity: 0.5;
      cursor: default;
      pointer-events: none;
    `}
`;

export default HumanResourceCardNextIcon;
