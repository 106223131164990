import React, { FC, CSSProperties } from 'react';
import styled from 'styled-components/macro';
import { Heading, Note, RequiredMark } from 'components/atoms';
import theme from 'styles/theme';
import typography from 'styles/typography';

const PrimaryLabel = styled(Heading).attrs({
  level: 'h3',
  contents: 'private',
})`
  ${typography.headlineDefault}
  margin-bottom: 12px;
  color: ${theme.textPrimary};
`;

const SecondaryLabel = styled.div`
  ${typography.textNote}
  margin-bottom: 4px;
  color: ${theme.textSecondary};
`;

const LabelNote = styled(Note)`
  margin-left: 4px;
`;

type LabelLevel = 'primary' | 'secondary';

type FormItemLabelProps = {
  label: string;
  level?: LabelLevel;
  note?: string | string[];
  required?: boolean;
  style?: CSSProperties;
};

const FormItemLabel: FC<FormItemLabelProps> = ({
  label,
  note,
  level = 'primary',
  required = false,
  ...props
}) => {
  return (
    <>
      {level === 'primary' && (
        <PrimaryLabel {...props}>
          {label}
          {required && <RequiredMark style={{ marginLeft: '4px' }} />}
          {note && <LabelNote note={note} />}
        </PrimaryLabel>
      )}
      {level === 'secondary' && (
        <SecondaryLabel {...props}>
          {label}
          {required && <RequiredMark style={{ marginLeft: '2px' }} />}
          {note && <LabelNote note={note} />}
        </SecondaryLabel>
      )}
    </>
  );
};

export default FormItemLabel;
