import i18n from 'i18n/i18n';
import { ApiError } from 'types/error';
import ErrorTypes from 'constants/errorTypes';
import axios, { AxiosError, Cancel } from 'axios';

export const getErrorStatus = (error: AxiosError): number | undefined =>
  error.response && error.response.status;

export const isAxiosError = (error: AxiosError | Cancel): error is AxiosError =>
  'config' in error;

export const isClientError = (error: AxiosError) => {
  const status = getErrorStatus(error);
  return !!status && status >= 400 && status < 500;
};

export const isServerError = (error: AxiosError) => {
  const status = getErrorStatus(error);
  return !!status && status >= 500;
};

export const isTimeout = (error: AxiosError) => error.code === 'ECONNABORTED';

export const isNetworkError = (error: AxiosError) =>
  !error.response && !!error.request;

export const isCanceledError = (error: AxiosError | Cancel) =>
  axios.isCancel(error);

export const applyClientErrorMessage = (
  error: ApiError,
  clientErrorMessage: string,
) =>
  error.errorType === ErrorTypes.CLIENT_ERROR
    ? {
        ...error,
        message: clientErrorMessage,
      }
    : error;

export const getFirebaseErrorMessage = (errorCode: string): string => {
  switch (errorCode) {
    case 'auth/invalid-email':
      return i18n.t('error:invalidEmail');
    case 'auth/invalid-password':
    case 'auth/wrong-password':
    case 'auth/user-not-found':
      return i18n.t('error:failedToLoginIncorrect');
    default:
      return i18n.t('error:failedUnexpected');
  }
};
