import { useState, useCallback, useMemo } from 'react';
import {
  NewGraduateHumanResourceFilterFormPastExperienceAchievement,
  NewGraduateHumanResourceFilterFormPreferredBusinessType,
  NewGraduateHumanResourceFilterFormOccupation,
  NewGraduateHumanResourceFilterFormWorkLocation,
  NewGraduateMattersDetailFilterValues,
  NewGraduateHumanResourceFilterFormPreferredWorkStyle,
} from 'types/matter';

const useDetailFilter = (
  defaultValues: NewGraduateMattersDetailFilterValues,
) => {
  const [detailFilterValues, setDetailFilterValues] = useState<
    NewGraduateMattersDetailFilterValues
  >(defaultValues);

  const updateValues = useCallback(
    (valuesToUpdate: Partial<NewGraduateMattersDetailFilterValues>) => {
      setDetailFilterValues(prev => ({ ...prev, ...valuesToUpdate }));
    },
    [],
  );

  const [showDetailFilter, setShowDetailFilter] = useState<boolean>(false);

  const openDetailFilter = useCallback(() => {
    setShowDetailFilter(true);
  }, []);

  const closeDetailFilter = useCallback(() => {
    setShowDetailFilter(false);
  }, []);

  const handleSelectPastExperienceAchievement = useCallback(
    (option: NewGraduateHumanResourceFilterFormPastExperienceAchievement) => {
      updateValues({
        pastExperienceAchievementIds: [
          ...detailFilterValues.pastExperienceAchievementIds,
          option.id,
        ],
      });
    },
    [updateValues, detailFilterValues.pastExperienceAchievementIds],
  );

  const handleDeselectPastExperienceAchievement = useCallback(
    (option: NewGraduateHumanResourceFilterFormPreferredBusinessType) => {
      updateValues({
        pastExperienceAchievementIds: detailFilterValues.pastExperienceAchievementIds.filter(
          id => id !== option.id,
        ),
      });
    },
    [updateValues, detailFilterValues.pastExperienceAchievementIds],
  );

  const handleSelectPreferredBusinessType = useCallback(
    (option: NewGraduateHumanResourceFilterFormPreferredBusinessType) => {
      updateValues({
        preferredBusinessTypeIds: [
          ...detailFilterValues.preferredBusinessTypeIds,
          option.id,
        ],
      });
    },
    [updateValues, detailFilterValues.preferredBusinessTypeIds],
  );

  const handleDeselectPreferredBusinessType = useCallback(
    (option: NewGraduateHumanResourceFilterFormPreferredBusinessType) => {
      updateValues({
        preferredBusinessTypeIds: detailFilterValues.preferredBusinessTypeIds.filter(
          id => id !== option.id,
        ),
      });
    },
    [updateValues, detailFilterValues.preferredBusinessTypeIds],
  );

  const handleSelectOccupation = useCallback(
    (option: NewGraduateHumanResourceFilterFormOccupation) => {
      updateValues({
        occupationIds: [...detailFilterValues.occupationIds, option.id],
      });
    },
    [updateValues, detailFilterValues.occupationIds],
  );

  const handleDeselectOccupation = useCallback(
    (option: NewGraduateHumanResourceFilterFormOccupation) => {
      updateValues({
        occupationIds: detailFilterValues.occupationIds.filter(
          id => id !== option.id,
        ),
      });
    },
    [updateValues, detailFilterValues.occupationIds],
  );

  const handleSelectWorkLocation = useCallback(
    (option: NewGraduateHumanResourceFilterFormWorkLocation) => {
      updateValues({
        workLocationIds: [...detailFilterValues.workLocationIds, option.id],
      });
    },
    [updateValues, detailFilterValues.workLocationIds],
  );

  const handleDeselectWorkLocation = useCallback(
    (option: NewGraduateHumanResourceFilterFormWorkLocation) => {
      updateValues({
        workLocationIds: detailFilterValues.workLocationIds.filter(
          id => id !== option.id,
        ),
      });
    },
    [updateValues, detailFilterValues.workLocationIds],
  );

  const handleSelectPreferredWorkStyle = useCallback(
    (option: NewGraduateHumanResourceFilterFormPreferredWorkStyle) => {
      updateValues({
        preferredWorkStyleIds: [
          ...detailFilterValues.preferredWorkStyleIds,
          option.id,
        ],
      });
    },
    [updateValues, detailFilterValues.preferredWorkStyleIds],
  );

  const handleDeselectPreferredWorkStyle = useCallback(
    (option: NewGraduateHumanResourceFilterFormPreferredWorkStyle) => {
      updateValues({
        preferredWorkStyleIds: detailFilterValues.preferredWorkStyleIds.filter(
          id => id !== option.id,
        ),
      });
    },
    [updateValues, detailFilterValues.preferredWorkStyleIds],
  );

  const detailFilteredFieldsCount = useMemo(() => {
    return (Object.keys(
      defaultValues,
    ) as (keyof typeof defaultValues)[]).filter(
      key => defaultValues[key].length > 0,
    ).length;
  }, [defaultValues]);

  return {
    detailFilterValues,
    showDetailFilter,
    openDetailFilter,
    closeDetailFilter,
    detailFilteredFieldsCount,
    handleSelectPastExperienceAchievement,
    handleDeselectPastExperienceAchievement,
    handleSelectPreferredBusinessType,
    handleDeselectPreferredBusinessType,
    handleSelectOccupation,
    handleDeselectOccupation,
    handleSelectWorkLocation,
    handleDeselectWorkLocation,
    handleSelectPreferredWorkStyle,
    handleDeselectPreferredWorkStyle,
  };
};

export default useDetailFilter;
