import React, { FC, useState, useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { UiActions } from 'modules/ui';
import styled from 'styled-components/macro';
import theme from 'styles/theme';
import apiMatter from 'external/api/matter';
import RequestError from 'classes/RequestError';
import useErrorDialog from 'hooks/useErrorDialog';
import { Matter } from 'proto/v1/apimodel/apimodel';
import {
  HumanResourceCardCloseIcon,
  HumanResourceCardDetailPlane,
} from 'components/molecules';
import {
  HumanResourceCareerDetailCard,
  HumanResourceNewGraduateDetailCard,
  AboutDttModal,
} from 'components/organisms';

const Wrapper = styled.div`
  height: 100%;
  overflow-y: auto;
  background-color: ${theme.baseWhite};
`;

const HeadMenu = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px;
`;

type Props = {
  show: boolean;
  matter: Matter | undefined;
  onExited: () => void;
  onClose: () => void;
};

const HumanResourceDetail: FC<Props> = ({
  show,
  matter,
  onExited,
  onClose,
}) => {
  const dispatch = useDispatch();
  const { handleRequestError } = useErrorDialog();

  const [showAboutDttModal, setAboutShowDttModal] = useState<boolean>(false);
  const [isFavorite, setIsFavorite] = useState<boolean>(false);

  const handleOpenAboutDttModal = useCallback(() => {
    setAboutShowDttModal(true);
  }, []);

  const handleCloseAboutDttModal = useCallback(() => {
    setAboutShowDttModal(false);
  }, []);

  const handleClickFavorite = useCallback(
    async (targetMatter: Matter, favorite: boolean) => {
      dispatch(UiActions.setLoading(true));
      try {
        await apiMatter.putMatterFavorite({
          matterPublishId: targetMatter.matterPublishId,
          favorite,
        });
      } catch (error: unknown) {
        dispatch(UiActions.setLoading(false));
        if (error instanceof RequestError) {
          handleRequestError(error);
        }
        return;
      }
      setIsFavorite(prev => !prev);
      dispatch(UiActions.setLoading(false));
    },
    [dispatch, handleRequestError],
  );

  // Set default favorite status.
  useEffect(() => {
    setIsFavorite(!!matter?.favorite);
  }, [matter]);

  return (
    <>
      <HumanResourceCardDetailPlane show={show} onExited={onExited}>
        {matter?.humanResource && (
          <Wrapper>
            <HeadMenu>
              <HumanResourceCardCloseIcon onClick={onClose} />
            </HeadMenu>
            {matter.humanResource.career && (
              <HumanResourceCareerDetailCard
                matter={matter}
                isFavorite={isFavorite}
                onClickFavorite={handleClickFavorite}
              />
            )}
            {matter.humanResource.newGraduate && (
              <HumanResourceNewGraduateDetailCard
                matter={matter}
                onOpenAboutDttModal={handleOpenAboutDttModal}
                isFavorite={isFavorite}
                onClickFavorite={handleClickFavorite}
              />
            )}
          </Wrapper>
        )}
      </HumanResourceCardDetailPlane>
      <AboutDttModal
        show={showAboutDttModal}
        closeModal={handleCloseAboutDttModal}
      />
    </>
  );
};

export default HumanResourceDetail;
