import React, { FC, ComponentProps } from 'react';
import { useTranslation } from 'react-i18next';
import { isEqualProps } from 'utils/object';
import {
  ImageUploader,
  FormItemLabel,
  MissionSettingFormItem,
} from 'components/molecules';

type ImageUploaderProps = ComponentProps<typeof ImageUploader>;

type IconImageFieldProps = { error: string | undefined } & Pick<
  ImageUploaderProps,
  'imageSrc' | 'onUpload' | 'onExceededFileSizeLimit'
>;

const IconImageField: FC<IconImageFieldProps> = props => {
  const { imageSrc, error, onUpload, onExceededFileSizeLimit } = props;

  const { t } = useTranslation('missionSetting');

  return (
    <MissionSettingFormItem required error={error}>
      <FormItemLabel
        label={t('field.iconImage.label')}
        note={t('field.iconImage.note', { returnObjects: true })}
        required
      />
      <ImageUploader
        name="iconImage"
        imageSrc={imageSrc}
        onUpload={onUpload}
        onExceededFileSizeLimit={onExceededFileSizeLimit}
      />
    </MissionSettingFormItem>
  );
};

export default React.memo(IconImageField, (prevProps, nextProps) =>
  isEqualProps(prevProps, nextProps, ['imageSrc', 'error']),
);
