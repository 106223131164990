import React, { FC, HTMLAttributes } from 'react';
import styled from 'styled-components/macro';
import theme from 'styles/theme';

const Wrapper = styled.div`
  display: inline-block;
  cursor: pointer;

  &:hover {
    opacity: 0.8;
  }
`;

const Icon = styled.div`
  text-align: center;
`;

const Label = styled.p`
  padding-top: 3px;
  color: ${theme.basePrimary};
  font-size: 12px;
  line-height: 14px;
  text-align: center;
`;

type Props = {
  label: string;
  icon: React.ReactNode;
} & HTMLAttributes<HTMLDivElement>;

const IconMenuItem: FC<Props> = ({ icon, label, ...rest }) => (
  <Wrapper {...rest}>
    <Icon>{icon}</Icon>
    <Label>{label}</Label>
  </Wrapper>
);

export default IconMenuItem;
