import React, { FC } from 'react';
import styled from 'styled-components/macro';
import { useTranslation } from 'react-i18next';
import { WrapText, LinkText } from 'components/atoms';
import { MissionProduct } from 'proto/v1/apimodel/apimodel';
import theme from 'styles/theme';
import typography from 'styles/typography';
import { noImageCover } from 'assets/png';
import { imageSelector } from 'utils/imageSelector';
import UrlLink from 'components/atoms/UrlLink/UrlLink';
import {
  MemberBenefit,
  ProductTargetTag,
  DetailContentsLeftItem,
} from 'components/molecules';

// サービス一覧から１つ目のサービスに遷移してきた時に画像すれすれまでスクロールすると見栄えがよくないため、
// padding-top: 32px; margin-top: -32pxを指定してレイアウトを変えずにスクロール位置を上にずらす
const ProductDetail = styled(DetailContentsLeftItem)`
  padding: 32px 24px 32px 32px;

  &:first-child {
    margin-top: -32px;
  }

  &:not(:first-child) {
    border-top: 1px solid ${theme.borderDefault};
  }
`;

const ProductImage = styled.img`
  width: 100%;
  height: 254px;
  margin-bottom: 16px;
`;

const HeadlineProductName = styled.div`
  ${typography.headlineDefault}
  display: flex;
  align-items: center;
  margin-bottom: 4px;

  ${ProductTargetTag} {
    &:first-child {
      margin-left: 8px;
    }
  }
`;

const ProductName = styled(HeadlineProductName)`
  ${typography.headlineDefault}
`;

const ProductOverview = styled.div`
  ${typography.textDefault}
  margin-bottom: 16px;
`;

const ProductTargetTags = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 8px;
`;

const ProductTags = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const ProductTag = styled.div`
  ${typography.labelDefault}
  box-sizing: border-box;
  margin-bottom: 4px;
  padding: 3px 12px;
  border: 1px solid ${theme.labelRequest};
  border-radius: 18px;
  background: ${theme.baseWhite};
  color: ${theme.labelRequest};

  &:not(:last-child) {
    margin-right: 4px;
  }
`;

const ProductDetailSubTitle = styled.div`
  ${typography.titlePage}
  margin-bottom: 24px;
  color: ${theme.textPrimary};
`;

const ProductDetailItem = styled.div`
  margin-top: 32px;
`;

const ProductUrlLink = styled(UrlLink)`
  margin-bottom: 20px;
`;

const ProductUrl = styled.span`
  display: inline-block;
  max-width: 488px;
  margin-right: 5px;
  overflow: hidden;
  text-decoration: underline;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const ProductText = styled(WrapText)`
  overflow: auto;
  line-height: 26px;
`;

type ProductDetailContentProps = {
  missionProducts: MissionProduct[];
  productDetailRefs: React.RefObject<HTMLDivElement>[];
};

const ProductDetailContent: FC<ProductDetailContentProps> = ({
  missionProducts,
  productDetailRefs,
}) => {
  const { t } = useTranslation('missionDetail');
  return (
    <>
      {missionProducts.map((product, index) => (
        <ProductDetail
          key={product.missionProductId}
          ref={productDetailRefs[index]}
          data-testid="product-detail"
        >
          <ProductImage
            src={imageSelector(product.image, 'large') || noImageCover}
            data-testid="product-image"
          />
          <ProductTargetTags>
            {product.productTarget?.isForBusiness && (
              <ProductTargetTag>
                {t('field.productTarget.isForBusiness')}
              </ProductTargetTag>
            )}
            {product.productTarget?.isForCustomer && (
              <ProductTargetTag>
                {t('field.productTarget.isForCustomer')}
              </ProductTargetTag>
            )}
          </ProductTargetTags>
          <ProductName>{product.name}</ProductName>
          <ProductOverview>{product.overview}</ProductOverview>
          <ProductUrlLink href={product.url} data-testid="product-url">
            <ProductUrl>{product.url}</ProductUrl>
          </ProductUrlLink>
          <ProductTags>
            {product.tags
              .filter(tag => tag !== '')
              .map((tag, tagIndex) => (
                // eslint-disable-next-line react/no-array-index-key
                <ProductTag key={tagIndex}>{tag}</ProductTag>
              ))}
          </ProductTags>
          <ProductDetailItem>
            <ProductDetailSubTitle>
              {t('field.missionProductDetail.problem.label')}
            </ProductDetailSubTitle>
            <ProductText>
              <LinkText color={'secondary'}>{product.problem}</LinkText>
            </ProductText>
          </ProductDetailItem>
          <ProductDetailItem>
            <ProductDetailSubTitle>
              {t('field.missionProductDetail.solution.label')}
            </ProductDetailSubTitle>
            <ProductText>
              <LinkText color={'secondary'}>{product.solution}</LinkText>
            </ProductText>
          </ProductDetailItem>
          {product.memberBenefit && (
            <ProductDetailItem>
              <MemberBenefit style={{ marginBottom: 20 }} />
              <ProductText>
                <LinkText color={'secondary'}>{product.memberBenefit}</LinkText>
              </ProductText>
            </ProductDetailItem>
          )}
        </ProductDetail>
      ))}
    </>
  );
};

export default ProductDetailContent;
