import React, { FC, HTMLAttributes } from 'react';
import styled from 'styled-components/macro';
import { CrossGray } from 'assets/svg';
import theme from 'styles/theme';

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  cursor: pointer;

  &:hover {
    opacity: 0.8;
    background-color: ${theme.bgHumanResourceIconHover};
  }
`;

type Props = HTMLAttributes<HTMLDivElement>;

const HumanResourceCardCloseIcon: FC<Props> = props => {
  return (
    <Wrapper {...props}>
      <CrossGray />
    </Wrapper>
  );
};

export default HumanResourceCardCloseIcon;
