import { object } from 'yup';
import {
  missionNameSchema,
  supportDescriptionSchema,
  strengthPointSchema,
  companyNameSchema,
  companyIdSchema,
  japanPrefectureIdSchema,
  otherAddressSchema,
  memberRangeIdSchema,
  iconImageSchema,
  coverImageSchema,
  companyUrlSchema,
  companyEstablishedDateSchema,
  supporterMembersSchema,
  supportAreaIdsSchema,
} from '../validate/fieldSchemas';

export const requiredSchema = object({
  name: missionNameSchema,
  supportDescription: supportDescriptionSchema,
  strengthPoint: strengthPointSchema,
  companyName: companyNameSchema,
  countryId: companyIdSchema,
  japanPrefectureId: japanPrefectureIdSchema,
  otherAddress: otherAddressSchema,
  memberRangeId: memberRangeIdSchema,
  iconImage: iconImageSchema,
  coverImage: coverImageSchema,
  companyUrl: companyUrlSchema,
  companyEstablishedDate: companyEstablishedDateSchema,
  supportAreaIds: supportAreaIdsSchema,
});

const optionalSchema = object({
  supporterMembers: supporterMembersSchema,
});

export const validationSchema = object({})
  .concat(requiredSchema)
  .concat(optionalSchema);
