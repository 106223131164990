import React, { FC } from 'react';
import styled, { css } from 'styled-components/macro';
import theme from 'styles/theme';
import typography from 'styles/typography';

const StyledCopyRight = styled.div`
  ${typography.textFooter}
  color: ${theme.baseWhite};
  ${({ contents }: CopyRightProps) =>
    contents === 'private' &&
    css`
      ${typography.textCopyright}
      color: ${theme.textSecondary};
      padding: 0 20px;
    `}
`;
const year = new Date().getFullYear();

type CopyRightProps = { contents?: 'private' | 'public' };

const CopyRight: FC<CopyRightProps> = props => (
  <StyledCopyRight {...props}>
    &copy; {year} VISITS Technologies Inc.
  </StyledCopyRight>
);
export default CopyRight;
