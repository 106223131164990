import React, { forwardRef } from 'react';
import { MissionSettingFormSubTitle } from 'components/molecules';
import { useTranslation } from 'react-i18next';

const OptionalFieldsTitleContent = forwardRef<HTMLDivElement>((_, ref) => {
  const { t } = useTranslation('missionSetting');
  return (
    <div ref={ref}>
      <MissionSettingFormSubTitle
        tagColor="labelOptional"
        tag={t('tag.optional')}
        title={t('description.optionalFields')}
        style={{ marginBottom: 20 }}
      />
    </div>
  );
});

export default OptionalFieldsTitleContent;
