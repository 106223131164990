import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components/macro';
import { NewGraduateProfileForMatter } from 'proto/v1/apimodel/apimodel';
import {
  HumanResourceCardBodyItem,
  HumanResourceCardBodyItemLabel,
  HumanResourceCardBodyItemContent,
  HumanResourceCardBodyListItemContent,
} from 'components/molecules';

const InternshipExperience = styled.div`
  &:not(:last-child) {
    margin-bottom: 16px;
  }
`;

type NewGraduateStudyAbroadExperiencesFieldProps = {
  studyAbroadExperiences:
    | NewGraduateProfileForMatter['studyAbroadExperiences']
    | undefined;
};

const NewGraduateStudyAbroadExperiencesField: FC<NewGraduateStudyAbroadExperiencesFieldProps> = props => {
  const { studyAbroadExperiences } = props;
  const { t } = useTranslation(['matter']);

  return (
    <HumanResourceCardBodyItem background>
      <HumanResourceCardBodyItemLabel>
        {t('field.studyAbroadExperiences.label', {
          lng: 'ja',
        })}
      </HumanResourceCardBodyItemLabel>
      <HumanResourceCardBodyItemContent>
        <div>
          {studyAbroadExperiences && studyAbroadExperiences.length > 0
            ? studyAbroadExperiences.map((studyAbroadExperience, index) => (
                // eslint-disable-next-line react/no-array-index-key
                <InternshipExperience key={index}>
                  <div>{studyAbroadExperience.countryName}</div>
                  <HumanResourceCardBodyListItemContent>
                    {t('periods', {
                      startYear: studyAbroadExperience.startYear,
                      startMonth: studyAbroadExperience.startMonth,
                      endYear: studyAbroadExperience.endYear,
                      endMonth: studyAbroadExperience.endMonth,
                      lng: 'ja',
                    })}
                  </HumanResourceCardBodyListItemContent>
                </InternshipExperience>
              ))
            : '-'}
        </div>
      </HumanResourceCardBodyItemContent>
    </HumanResourceCardBodyItem>
  );
};

export default NewGraduateStudyAbroadExperiencesField;
