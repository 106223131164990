import React, { FC, CSSProperties } from 'react';
import styled from 'styled-components/macro';
import theme from 'styles/theme';
import typography from 'styles/typography';
import { Heading, Tag } from 'components/atoms';
import { ColorType } from 'visits-style';

const FieldTypeTag = styled(Tag)`
  &&& {
    ${typography.labelBold}
    min-width: auto;
    margin: 0 4px 0 0;
    padding: 3px 8px;
    border-radius: 18px;
    color: ${theme.baseWhite};
  }
`;

type MissionSettingFormSubTitleProps = {
  tagColor: ColorType;
  tag: string;
  title: string;
  style?: CSSProperties;
};

const MissionSettingFormSubTitle: FC<MissionSettingFormSubTitleProps> = props => {
  const { tagColor, tag, title, style } = props;
  return (
    <Heading
      level="h2"
      contents="private"
      style={{
        display: 'flex',
        alignItems: 'center',
        ...style,
      }}
    >
      <FieldTypeTag round color={tagColor}>
        {tag}
      </FieldTypeTag>
      {title}
    </Heading>
  );
};

export default MissionSettingFormSubTitle;
