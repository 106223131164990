import React, { FC, ChangeEvent } from 'react';
import { useTranslation } from 'react-i18next';
import { MissionDemandFormValue } from 'types/form';
import { isEqualProps } from 'utils/object';
import { Checkbox } from 'components/atoms';
import { MissionDemandFieldItem } from 'components/molecules';

type TransferredOfBusinessFieldProps = {
  value: MissionDemandFormValue['wantTransferOfBusiness'];
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
};

const TransferOfBusinessField: FC<TransferredOfBusinessFieldProps> = props => {
  const { value, onChange } = props;

  const { t } = useTranslation('missionSetting');

  return (
    <MissionDemandFieldItem>
      <Checkbox
        name="missionDemand.wantTransferOfBusiness"
        checked={value}
        onChange={onChange}
        aria-label={t('field.missionDemand.checkbox.wantTransferOfBusiness')}
      >
        {t('field.missionDemand.checkbox.wantTransferOfBusiness')}
      </Checkbox>
    </MissionDemandFieldItem>
  );
};

export default React.memo(TransferOfBusinessField, (prevProps, nextProps) =>
  isEqualProps(prevProps, nextProps, ['value']),
);
