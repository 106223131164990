import { useCallback, ChangeEvent } from 'react';
import { CompanyEstablishedDate } from 'proto/v1/apimodel/apimodel';
import { MISSION_COMPANY_ESTABLISHED_MONTH_INITIAL_VALUE } from 'constants/config';
import { MissionSettingFormik } from './useMissionSettingFormik';

type Payload = {
  companyEstablishedDate: CompanyEstablishedDate | undefined;
  setFieldValue: MissionSettingFormik['setFieldValue'];
};

const d = new Date();
const currentYear = d.getFullYear();
const currentMonth = d.getMonth() + 1;

const useCompanyEstablishedDateField = ({
  companyEstablishedDate,
  setFieldValue,
}: Payload) => {
  const handleChangeCompanyEstablishedYear = useCallback(
    (e: ChangeEvent<HTMLSelectElement>): void => {
      const selectedYear = Number(e.currentTarget.value);
      setFieldValue('companyEstablishedDate.year', selectedYear);

      if (selectedYear !== currentYear) return;

      const selectedMonth = companyEstablishedDate?.month;

      if (selectedMonth && selectedMonth > currentMonth) {
        setFieldValue(
          'companyEstablishedDate.month',
          MISSION_COMPANY_ESTABLISHED_MONTH_INITIAL_VALUE,
        );
      }
    },
    [companyEstablishedDate, setFieldValue],
  );

  return {
    handleChangeCompanyEstablishedYear,
  };
};

export default useCompanyEstablishedDateField;
