import React from 'react';
import styled from 'styled-components';
import { Tag } from 'components/atoms';
import typography from 'styles/typography';
import theme from 'styles/theme';

const MissionDemandTag = styled(props => (
  <Tag bordered size="medium" color="labelRequest" {...props} />
))`
  ${typography.labelDefault}
  min-width: 84px;
  height: 24px;
  padding: 3px 12px;
  border-radius: 18px;
  background-color: ${theme.baseWhite};
`;

export default MissionDemandTag;
