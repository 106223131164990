export const arrayNumbers = (target: any) => {
  if (target === null || target === undefined) {
    return [];
  }
  if (Array.isArray(target)) {
    return target.map(v => Number(v));
  }
  return [Number(target)];
};

export const range = (start: number, end: number) =>
  [...Array(end - start + 1).keys()].map((_, i) => start + i);

export const unique = <T>(array: T[]) => [...new Set(array)];
