import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { UiActions } from 'modules/ui';
import apiSupporter from 'external/api/supporter';
import apiAccount from 'external/api/account';
import { getSupporterId, AccountActions } from 'modules/account';
import RequestError from 'classes/RequestError';
import { SupporterFormValues } from 'types/supporter';
import useErrorDialog from 'hooks/useErrorDialog';
import GAEventNames from 'constants/gaEventNames';
import { logCustomEvent } from 'utils/analytics';
import { SupporterFormState } from './useSupporterFormState';
import getSupporterToUpdate from '../supporterToUpdate';
import convertSupporterToSupporterFormValues from '../convertSupporterToSupporterFormValues';

const useUpdateSupporter = ({
  setError,
  setInitialValues,
  setForm,
  setIsPublished,
  openModal,
  fetchMyAccounts,
}: SupporterFormState) => {
  const { t } = useTranslation(['error']);

  const dispatch = useDispatch();
  const { handleRequestError } = useErrorDialog();
  const supporterId = useSelector(getSupporterId) ?? '';

  const updateSupporterFormLatest = useCallback(async () => {
    const result = await apiSupporter.getSupporterForm({ supporterId });
    const { data } = result;
    setInitialValues(prevInitialValues => {
      if (!data.supporter) return prevInitialValues;
      return convertSupporterToSupporterFormValues(data.supporter);
    });
    setForm(prevForm => data.form || prevForm);
    setIsPublished(data.published);
    dispatch(
      AccountActions.setAccount({
        supporter: data.supporter,
        published: data.published,
      }),
    );
  }, [dispatch, setForm, setInitialValues, setIsPublished, supporterId]);

  const updatePublishStatus = useCallback(
    async (publish: boolean, values: SupporterFormValues) => {
      const valuesToUpdate = getSupporterToUpdate(values);
      await apiSupporter.putUpdateSupporter(valuesToUpdate);
      await apiAccount.putPublishActiveAccount({
        publish,
      });
      await updateSupporterFormLatest();
      await fetchMyAccounts();
    },
    [fetchMyAccounts, updateSupporterFormLatest],
  );

  const updateSupporter = useCallback(
    async values => {
      dispatch(UiActions.setLoading(true));
      setError(null);
      const valuesToUpdate = getSupporterToUpdate(values);
      try {
        await apiSupporter.putUpdateSupporter(valuesToUpdate);
        await updateSupporterFormLatest();
      } catch (anyError) {
        if (anyError instanceof Error) {
          setError(anyError.message);
        }
        dispatch(UiActions.setLoading(false));
        if (anyError instanceof RequestError) {
          handleRequestError(anyError, t('error:failedToSaveMission'));
        }
        return;
      }
      logCustomEvent(GAEventNames.SAVE_SUPPORTER);
      dispatch(UiActions.setLoading(false));
      openModal('saveMissionCompleted');
      await fetchMyAccounts();
    },
    [
      dispatch,
      fetchMyAccounts,
      handleRequestError,
      openModal,
      setError,
      t,
      updateSupporterFormLatest,
    ],
  );

  const publishSupporter = useCallback(
    async values => {
      setError(null);
      dispatch(UiActions.setLoading(true));
      try {
        await updatePublishStatus(true, values);
      } catch (anyError) {
        if (anyError instanceof Error) {
          setError(anyError.message);
        }
        dispatch(UiActions.setLoading(false));
        if (anyError instanceof RequestError) {
          handleRequestError(anyError, t('error:failedToPublishMission'));
        }
        return;
      }
      logCustomEvent(GAEventNames.PUBLISH_SUPPORTER);
      dispatch(UiActions.setLoading(false));
      openModal('publishMissionCompleted');
    },
    [dispatch, handleRequestError, openModal, setError, t, updatePublishStatus],
  );

  const unPublishSupporter = useCallback(
    async values => {
      dispatch(UiActions.setLoading(true));
      setError(null);
      try {
        await updatePublishStatus(false, values);
      } catch (anyError) {
        if (anyError instanceof Error) {
          setError(anyError.message);
        }
        dispatch(UiActions.setLoading(false));
        if (anyError instanceof RequestError) {
          handleRequestError(anyError, t('error:failedToUnPublishMission'));
        }
        return;
      }
      logCustomEvent(GAEventNames.UNPUBLISH_SUPPORTER);
      dispatch(UiActions.setLoading(false));
      openModal('unPublishMissionCompleted');
    },
    [dispatch, handleRequestError, openModal, setError, t, updatePublishStatus],
  );

  return {
    updateSupporter,
    publishSupporter,
    unPublishSupporter,
  };
};

export default useUpdateSupporter;
