import React, { FC, ComponentProps } from 'react';
import styled from 'styled-components/macro';
import { useTranslation } from 'react-i18next';
import { Checkbox, ButtonS } from 'components/atoms';
import {
  Picker,
  PickerHeading,
  PickerContent,
  PickerFooter,
} from 'components/molecules';
import { CorporateAttribute } from 'proto/v1/apimodel/apimodel';
import {
  CorporateAttributePickerValues,
  CorporateAttributeMissionFilterValues,
} from 'types/mission';
import useCorporateAttributePicker from './useCorporateAttributePicker';

const Form = styled.form`
  width: 640px;
`;

const HeadingCheckbox = styled(Checkbox)`
  &&& {
    display: inline-block;
    margin-right: 0;
    margin-bottom: 0;
    margin-left: 16px;

    label {
      font-weight: normal;
    }
  }
`;

const PickerCheckboxes = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const PickerCheckbox = styled(Checkbox)`
  &&& {
    display: flex;
    min-width: 192px;
    margin-right: 0;
    margin-bottom: 0;

    &:nth-child(3n + 2) {
      padding-left: 32px;
    }

    &:nth-child(3n) {
      padding-left: 48px;
    }
  }
`;

type PickerProps = ComponentProps<typeof Picker>;

type CorporateAttributePickerProps = {
  onSubmit: (values: CorporateAttributePickerValues) => void;
  form: { corporateAttributes: CorporateAttribute[] | undefined };
  defaultValues: CorporateAttributeMissionFilterValues;
} & Pick<
  PickerProps,
  'isActive' | 'isSelected' | 'onClickTag' | 'onClickOverlay'
>;

const CorporateAttributePicker: FC<CorporateAttributePickerProps> = props => {
  const {
    isActive,
    isSelected,
    onClickTag,
    onClickOverlay,
    onSubmit,
    form,
    defaultValues,
  } = props;

  const { t } = useTranslation(['missionList', 'common']);

  const {
    formik,
    handleChangeCorporateAttribute,
    handleChangeCheckAllCorporateAttributes,
  } = useCorporateAttributePicker({
    onSubmit,
    form,
    defaultValues,
    isSelected,
  });

  const { values, handleSubmit } = formik;

  return (
    <Picker
      label={t('picker.corporateAttribute.label')}
      isSelected={isSelected}
      isActive={isActive}
      onClickTag={onClickTag}
      onClickOverlay={onClickOverlay}
    >
      <Form onSubmit={handleSubmit} data-testid="corporate-attribute-picker">
        <PickerHeading>
          {t('picker.corporateAttribute.field.corporateAttribute.label')}
          <HeadingCheckbox
            name="checkAllCorporateAttributes"
            onChange={handleChangeCheckAllCorporateAttributes}
            checked={values.checkAllCorporateAttributes}
            aria-label={t('picker.checkbox.toggleCheckAll')}
          >
            {t('picker.checkbox.toggleCheckAll')}
          </HeadingCheckbox>
        </PickerHeading>

        <PickerContent>
          <PickerCheckboxes>
            {form?.corporateAttributes &&
              form.corporateAttributes.map(item => (
                <PickerCheckbox
                  key={item.id}
                  name="corporateAttributeIds"
                  onChange={handleChangeCorporateAttribute}
                  value={item.id}
                  checked={values.corporateAttributeIds.includes(item.id)}
                  aria-label={item.nameShort}
                >
                  {item.nameShort}
                </PickerCheckbox>
              ))}
          </PickerCheckboxes>
        </PickerContent>

        <PickerFooter>
          <ButtonS buttonTheme="default" type="submit">
            {t('common:button.save')}
          </ButtonS>
        </PickerFooter>
      </Form>
    </Picker>
  );
};

export default CorporateAttributePicker;
