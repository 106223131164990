import React, { FC } from 'react';
import { Supporter } from 'proto/v1/apimodel/apimodel';
import { DetailContentsLeftItem } from 'components/molecules';
import {
  MissionDescriptionField,
  StrengthPointField,
  ImageGalleryField,
} from 'components/organisms/Details/Common/Fields';
import { useTranslation } from 'react-i18next';

type AboutSupporterContentProps = {
  supporter: Supporter;
};

const AboutSupporterContent: FC<AboutSupporterContentProps> = ({
  supporter,
}) => {
  const { t } = useTranslation('supporterDetail');

  return (
    <>
      <DetailContentsLeftItem>
        <MissionDescriptionField
          label={t('field.supportDescription.label')}
          missionDescription={supporter.supportDescription}
          style={{ marginBottom: 56 }}
        />
        <StrengthPointField
          label={t('field.strengthPoint.label')}
          strengthPoint={supporter.strengthPoint}
        />

        {supporter.galleryImages.length > 0 && (
          <ImageGalleryField
            title={t('field.galleryImages.label')}
            images={supporter.galleryImages.map(image => image.image)}
          />
        )}
      </DetailContentsLeftItem>
    </>
  );
};

export default AboutSupporterContent;
