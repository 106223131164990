import React, { FC, ComponentProps } from 'react';
import styled, { css } from 'styled-components/macro';
import { useTranslation } from 'react-i18next';
import typography from 'styles/typography';
import theme from 'styles/theme';
import { Checkbox, Selectbox, ButtonS } from 'components/atoms';
import {
  Picker,
  PickerHeading,
  PickerContent,
  PickerFooter,
} from 'components/molecules';
import { Country, JapanPrefecture } from 'proto/v1/apimodel/apimodel';
import { AddressPickerValues, AddressFilterValues } from 'types/mission';
import useAddressPicker from './useAddressPicker';

const Form = styled.form`
  width: 680px;
  overflow: auto;
`;

const AddressPickerContent = styled(PickerContent)`
  max-height: 512px;
  overflow: auto;
`;

const Note = styled.div`
  ${typography.textNote};
  margin-left: 8px;
  color: ${theme.textSecondary};
  font-weight: normal;
`;

const CountryField = styled.div`
  margin-bottom: 32px;
`;

const fieldLabelStyle = css`
  ${typography.textBold};
  color: ${theme.textPrimary};
`;

const CountryLabel = styled.div`
  ${fieldLabelStyle};
  margin-bottom: 12px;
`;

const CountrySelectBox = styled(Selectbox)`
  width: 275px;
`;

const JapanPrefectureField = styled.div`
  /* Cancel PickerContent’s padding-right to keep JapanPrefecture checkboxes’ width */
  margin-right: -32px;
`;

const CheckAllCheckbox = styled(Checkbox)`
  &&& {
    display: inline-block;
    margin-right: 0;
    margin-bottom: 0;
    margin-left: 16px;

    label {
      font-weight: normal;
    }
  }
`;

const JapanPrefectureLabel = styled.div`
  ${fieldLabelStyle};
  display: flex;
  align-items: center;
  margin-bottom: 32px;
`;

const JapanPrefectureCheckboxes = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const JapanPrefectureCheckbox = styled(Checkbox)`
  &&& {
    display: flex;
    margin-right: 0;
    margin-bottom: 16px;

    &:nth-child(4n + 1) {
      width: 170px;
    }

    &:nth-child(4n + 2) {
      width: 172px;
    }

    &:nth-child(4n + 3) {
      width: 160px;
    }

    &:nth-child(4n) {
      width: auto;
    }
  }
`;

type PickerProps = ComponentProps<typeof Picker>;

type AddressPickerProps = {
  onSubmit: (values: AddressPickerValues) => void;
  form: {
    countries: Country[] | undefined;
    japanPrefectures: JapanPrefecture[] | undefined;
  };
  defaultValues: AddressFilterValues;
} & Pick<
  PickerProps,
  'isActive' | 'isSelected' | 'onClickTag' | 'onClickOverlay'
>;

const AddressPicker: FC<AddressPickerProps> = props => {
  const {
    isActive,
    isSelected,
    onClickTag,
    onClickOverlay,
    onSubmit,
    form,
    defaultValues,
  } = props;

  const { t } = useTranslation(['missionList', 'common']);

  const {
    formik,
    handleChangeJapanPrefecture,
    handleChangeCheckAllJapanPrefectures,
  } = useAddressPicker({
    onSubmit,
    form,
    defaultValues,
    isSelected,
  });

  const { values, handleSubmit, handleChange, handleBlur } = formik;

  return (
    <Picker
      label={t('picker.address.label')}
      isSelected={isSelected}
      isActive={isActive}
      onClickTag={onClickTag}
      onClickOverlay={onClickOverlay}
    >
      <Form onSubmit={handleSubmit} data-testid="address-picker">
        <PickerHeading>
          {t('picker.address.title')}
          <Note>{t('picker.address.description.selectAddress')}</Note>
        </PickerHeading>
        <AddressPickerContent>
          <CountryField>
            <CountryLabel>
              {t('picker.address.field.country.label')}
            </CountryLabel>
            <CountrySelectBox
              name="countryId"
              options={form.countries || []}
              idName="countryId"
              value={values.countryId}
              onBlur={handleBlur}
              onChange={handleChange}
              aria-label={t('picker.address.field.country.label')}
            />
          </CountryField>
          {values.countryId === 'JPN' && (
            <JapanPrefectureField>
              <JapanPrefectureLabel>
                {t('picker.address.field.JapanPrefecture.label')}
                <CheckAllCheckbox
                  name="checkAllJapanPrefectures"
                  onChange={handleChangeCheckAllJapanPrefectures}
                  checked={values.checkAllJapanPrefectures}
                  aria-label={t('picker.checkbox.toggleCheckAll')}
                >
                  {t('picker.checkbox.toggleCheckAll')}
                </CheckAllCheckbox>
              </JapanPrefectureLabel>
              <JapanPrefectureCheckboxes>
                {form?.japanPrefectures &&
                  form.japanPrefectures.map(item => (
                    <JapanPrefectureCheckbox
                      key={item.japanPrefectureId}
                      name="japanPrefectureIds"
                      onChange={handleChangeJapanPrefecture}
                      value={item.japanPrefectureId}
                      checked={values.japanPrefectureIds.includes(
                        item.japanPrefectureId,
                      )}
                      aria-label={item.name}
                    >
                      {item.name}
                    </JapanPrefectureCheckbox>
                  ))}
              </JapanPrefectureCheckboxes>
            </JapanPrefectureField>
          )}
        </AddressPickerContent>

        <PickerFooter>
          <ButtonS buttonTheme="default" type="submit">
            {t('common:button.save')}
          </ButtonS>
        </PickerFooter>
      </Form>
    </Picker>
  );
};

export default AddressPicker;
