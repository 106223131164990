import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { UiActions } from 'modules/ui';
import { getMissionId, AccountActions } from 'modules/account';
import apiMission from 'external/api/mission';
import apiAccount from 'external/api/account';
import { MissionFormValues } from 'types/mission';
import RequestError from 'classes/RequestError';
import useErrorDialog from 'hooks/useErrorDialog';
import GAEventNames from 'constants/gaEventNames';
import { logCustomEvent } from 'utils/analytics';
import { MyAccountsMethods } from 'hooks/useMyAccounts';
import { Mission } from 'proto/v1/apimodel/apimodel';
import getMissionToUpdate from '../missionToUpdate';
import { ModalContext } from '../../hooks/useModal';

type Payload = {
  setMission: React.Dispatch<React.SetStateAction<Mission | undefined>>;
  fetchMyAccounts: MyAccountsMethods['fetchMyAccounts'];
  openModal: ModalContext['openModal'];
};

const useUpdateMission = ({
  setMission,
  fetchMyAccounts,
  openModal,
}: Payload) => {
  const { t } = useTranslation(['error']);
  const dispatch = useDispatch();
  const { handleRequestError } = useErrorDialog();
  const missionId = useSelector(getMissionId);

  const updateMissionFormLatest = useCallback(async () => {
    const res = await apiMission.getMissionForm(missionId);
    setMission(res.data.mission);
    dispatch(
      AccountActions.setAccount({
        mission: res.data.mission,
        published: res.data.published,
      }),
    );
  }, [dispatch, missionId, setMission]);

  const updateMission = useCallback(
    async (values: MissionFormValues) => {
      dispatch(UiActions.setLoading(true));
      const valuesToUpdateMission = getMissionToUpdate(values);
      try {
        await apiMission.putUpdateMission(valuesToUpdateMission);
        await updateMissionFormLatest();
      } catch (error) {
        dispatch(UiActions.setLoading(false));
        if (error instanceof RequestError) {
          handleRequestError(error, t('error:failedToSaveMission'));
        }
        throw error;
      }
      logCustomEvent(GAEventNames.SAVE_MISSION);
      dispatch(UiActions.setLoading(false));
      fetchMyAccounts();
    },
    [dispatch, fetchMyAccounts, handleRequestError, t, updateMissionFormLatest],
  );

  const updatePublishStatus = useCallback(
    async (publish: boolean, values: MissionFormValues) => {
      const valuesToUpdateMission = getMissionToUpdate(values);
      await apiMission.putUpdateMission(valuesToUpdateMission);
      await apiAccount.putPublishActiveAccount({
        publish,
      });
      await updateMissionFormLatest();
      await fetchMyAccounts();
    },
    [fetchMyAccounts, updateMissionFormLatest],
  );

  const publishMission = useCallback(
    async (values: MissionFormValues) => {
      dispatch(UiActions.setLoading(true));
      try {
        await updatePublishStatus(true, values);
      } catch (error) {
        dispatch(UiActions.setLoading(false));
        if (error instanceof RequestError) {
          handleRequestError(error, t('error:failedToPublishMission'));
        }
        throw error;
      }
      logCustomEvent(GAEventNames.PUBLISH_MISSION);
      dispatch(UiActions.setLoading(false));
      openModal('publishMissionCompleted');
    },
    [dispatch, openModal, updatePublishStatus, handleRequestError, t],
  );

  const unPublishMission = useCallback(
    async (values: MissionFormValues) => {
      dispatch(UiActions.setLoading(true));
      try {
        await updatePublishStatus(false, values);
      } catch (error) {
        dispatch(UiActions.setLoading(false));
        if (error instanceof RequestError) {
          handleRequestError(error, t('error:failedToUnPublishMission'));
        }
        throw error;
      }
      logCustomEvent(GAEventNames.UNPUBLISH_MISSION);
      dispatch(UiActions.setLoading(false));
      openModal('unPublishMissionCompleted');
    },
    [dispatch, openModal, updatePublishStatus, handleRequestError, t],
  );

  return {
    updateMission,
    publishMission,
    unPublishMission,
  };
};

export default useUpdateMission;
