import React, { FC } from 'react';
import { Wall } from 'components/atoms';
import { MissionSettingForm } from 'components/molecules';
import {
  HeaderContainer,
  FooterContainer,
  RequiredFieldsContainer,
  OptionalFieldsTitleContainer,
  OptionalFieldsContainer,
  ModalsContainer,
} from './Containers';
import useSupporterFormContext, {
  SupporterFormContextProvider,
} from './useSupporterFormContext';

const SupporterForm: FC = () => {
  const { handleScroll, formWrapperRef } = useSupporterFormContext();

  return (
    <>
      <HeaderContainer />

      <MissionSettingForm
        onScroll={handleScroll}
        ref={formWrapperRef}
        data-testid="supporter-form"
      >
        <Wall color="baseWhite" style={{ paddingRight: '57px' }}>
          <RequiredFieldsContainer />
        </Wall>

        <OptionalFieldsTitleContainer />

        <Wall color="white" style={{ marginBottom: '212px' }}>
          <OptionalFieldsContainer />
        </Wall>
      </MissionSettingForm>

      <FooterContainer />

      <ModalsContainer />
    </>
  );
};

export default () => (
  <SupporterFormContextProvider>
    <SupporterForm />
  </SupporterFormContextProvider>
);
