import React, { FC } from 'react';
import { imageSelector } from 'utils/imageSelector';
import { Mission } from 'proto/v1/apimodel/apimodel';
import { noImageSquare } from 'assets/png';
import { ImageCenter } from 'components/atoms';

type IconImageFieldProps = {
  iconImage: Mission['iconImage'];
};

const IconImageField: FC<IconImageFieldProps> = ({ iconImage }) => {
  return (
    <ImageCenter
      imageSrc={imageSelector(iconImage, 'small') || noImageSquare}
      width={100}
      height={100}
      style={{ marginBottom: '8px' }}
      data-test-image={imageSelector(iconImage, 'small')}
      data-testid="mission-icon-image"
    />
  );
};

export default IconImageField;
