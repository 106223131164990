import React, { useState, ComponentProps, FC } from 'react';
import { TextInput } from 'components/atoms';
import styled from 'styled-components/macro';
import { FiEye, FiEyeOff } from 'react-icons/fi';

const StyledTextInput = styled(TextInput)`
  &&& {
    input {
      padding-right: 40px;
    }
  }
`;

const WithIconInput = styled.div`
  position: relative;
  z-index: 1;

  .icon {
    display: flex;
    position: absolute;
    z-index: 2;
    top: 4px;
    right: 4px;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 30px;
    height: 30px;
    cursor: pointer;
  }
`;

const InputPassword: FC<ComponentProps<typeof TextInput>> = props => {
  const [isShowPassword, setIsShowPassword] = useState<boolean>(true);
  const showPassword = () => setIsShowPassword(!isShowPassword);
  return (
    <WithIconInput>
      <StyledTextInput type={isShowPassword ? 'password' : 'text'} {...props} />
      <div className="icon">
        {isShowPassword ? (
          <FiEyeOff onClick={showPassword} data-testid="hide-password" />
        ) : (
          <FiEye onClick={showPassword} data-testid="show-password" />
        )}
      </div>
    </WithIconInput>
  );
};
export default InputPassword;
