import { useCallback, ChangeEvent } from 'react';
import { useFormik } from 'formik';
import { ObjectSchema } from 'yup';

type Payload<Values> = {
  initialValues: Values;
  validationSchema: ObjectSchema<any>;
};

const useMissionFormFormik = <Values>({
  initialValues,
  validationSchema,
}: Payload<Values>) => {
  const formik = useFormik<Values>({
    initialValues,
    validationSchema,
    enableReinitialize: true,
    validateOnChange: true,
    onSubmit: () => {},
  });

  const { setFieldValue, setFieldTouched, touched, errors } = formik;

  const handleChangeChecked = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const { name, checked } = event.target;
      setFieldValue(name, checked);
      // should set shouldValidate flag false to avoid incorrect validate errors
      setFieldTouched(name, true, false);
    },
    [setFieldTouched, setFieldValue],
  );

  const handleChangeSelectedValueAsNumber = useCallback(
    (event: ChangeEvent<HTMLSelectElement>) => {
      const { name, value } = event.target;
      setFieldValue(name, Number(value));
    },
    [setFieldValue],
  );

  const formError = useCallback(
    (key: keyof Values) =>
      (touched[key] ? errors[key] : undefined) as string | undefined,
    [errors, touched],
  );

  return {
    formik,
    formError,
    handleChangeChecked,
    handleChangeSelectedValueAsNumber,
  };
};

export type MissionFormFormik = ReturnType<
  typeof useMissionFormFormik
>['formik'];

export default useMissionFormFormik;
