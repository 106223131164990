import React from 'react';
import { useTranslation } from 'react-i18next';
import { ButtonM } from 'components/atoms';
import { Modal, ModalContent, ModalFooter } from 'components/molecules';

type Props = {
  show: boolean;
  onOk: () => void;
  onCancel: () => void;
};

const PublishSupporterConfirmationModal: React.VFC<Props> = ({
  show,
  onOk,
  onCancel,
}) => {
  const { t } = useTranslation('missionSetting');
  return (
    <Modal show={show} closeModal={onCancel}>
      <ModalContent data-testid="modal-publish-supporter-confirmation">
        {t('modal.publishSupporterConfirmation.content')}
      </ModalContent>
      <ModalFooter>
        <ButtonM buttonTheme="default" type="submit" onClick={onOk}>
          {t('common:button.ok')}
        </ButtonM>
      </ModalFooter>
    </Modal>
  );
};

export default PublishSupporterConfirmationModal;
