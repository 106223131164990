import React from 'react';
import styled, { css } from 'styled-components/macro';
import { Matter } from 'proto/v1/apimodel/apimodel';
import { isEqualProps } from 'utils/object';
import { NewGraduateHeadField, NewGraduateMainField } from './Fields';

const Card = styled.div<{ isSelected: boolean }>`
  width: 864px;
  margin-bottom: 24px;
  margin-left: 32px;
  overflow: hidden;
  border-radius: 8px;
  box-shadow: 0 0 16px rgba(0, 0, 0, 0.14);
  cursor: pointer;

  &:hover {
    opacity: 0.8;
  }

  ${({ isSelected }) =>
    isSelected &&
    css`
      box-shadow: 0px 0px 16px #888fd1;
    `}
`;

const CardBody = styled.div``;

type Props = {
  matter: Matter;
  onOpenAboutDttModal: () => void;
  onSelect: (matterId: Matter['matterId']) => void;
  isSelected: boolean;
  isChecked: boolean;
  onChangeChecked: (e: React.ChangeEvent<HTMLInputElement>) => void;
  isFavorite: boolean;
  onClickFavorite: (matter: Matter, favorite: boolean) => void;
};

const HumanResourceNewGraduateListCard: React.VFC<Props> = ({
  matter,
  onOpenAboutDttModal,
  onSelect,
  isSelected,
  isChecked,
  onChangeChecked,
  isFavorite,
  onClickFavorite,
}) => {
  return (
    <Card
      id={`matter-${matter.matterId}`}
      data-testid="human-resource-new-graduate-list-card"
      onClick={() => onSelect(matter.matterId)}
      isSelected={isSelected}
    >
      <NewGraduateHeadField
        matter={matter}
        isChecked={isChecked}
        onChangeChecked={onChangeChecked}
        isFavorite={isFavorite}
        onClickFavorite={onClickFavorite}
      />
      <CardBody>
        <NewGraduateMainField
          matter={matter}
          onOpenAboutDttModal={onOpenAboutDttModal}
        />
      </CardBody>
    </Card>
  );
};

export default React.memo(
  HumanResourceNewGraduateListCard,
  (prevProps, nextProps) => isEqualProps(prevProps, nextProps),
);
