import React, { FC, ChangeEvent } from 'react';
import { useTranslation } from 'react-i18next';
import { MissionForm, MissionFormValues } from 'types/mission';
import { isEqualProps } from 'utils/object';
import { Selectbox } from 'components/atoms';
import { MissionSettingFormItem, FormItemLabel } from 'components/molecules';

type FundFieldProps = {
  value: MissionFormValues['fundId'];
  form: MissionForm['funds'];
  error: string | undefined;
  onChange: (e: ChangeEvent<HTMLSelectElement>) => void;
  onBlur: (e: any) => void;
};

const FundField: FC<FundFieldProps> = props => {
  const { value, error, form, onChange, onBlur } = props;

  const { t } = useTranslation('missionSetting');

  return (
    <MissionSettingFormItem required error={error}>
      <FormItemLabel label={t('field.fund.label')} required />
      <Selectbox
        margin="0 10px 0 0"
        name="fundId"
        idName="fundId"
        textName="displayText"
        value={value}
        options={form || []}
        onChange={onChange}
        onBlur={onBlur}
        aria-label={t('field.fund.label')}
      />
    </MissionSettingFormItem>
  );
};

export default React.memo(FundField, (prevProps, nextProps) =>
  isEqualProps(prevProps, nextProps, ['value', 'error', 'form']),
);
