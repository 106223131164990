import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { ButtonM } from 'components/atoms';
import { Modal, ModalContent, ModalFooter } from 'components/molecules';

type UnpublishCompleteModalProps = {
  show: boolean;
  onClose: () => void;
};

const UnpublishCompleteModal: FC<UnpublishCompleteModalProps> = ({
  show,
  onClose,
}) => {
  const { t } = useTranslation('missionSetting');
  return (
    <Modal show={show} closeModal={onClose}>
      <ModalContent data-testid="modal-un-publish-complete">
        {t('modal.unPublished.content')}
      </ModalContent>
      <ModalFooter>
        <ButtonM type="button" buttonTheme="default" onClick={onClose}>
          {t('common:button.ok')}
        </ButtonM>
      </ModalFooter>
    </Modal>
  );
};

export default UnpublishCompleteModal;
