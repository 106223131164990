import React, { FC, ChangeEvent } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components/macro';
import { MissionProductFormValue, MissionProductsError } from 'types/form';
import { isEqualProps } from 'utils/object';
import { MissionSettingMaxLength } from 'constants/form';
import { MISSION_PRODUCT_PRODUCT_TAGS_MAX_COUNT } from 'constants/config';
import { TextInput, ErrorMessage } from 'components/atoms';
import {
  FormItemSubHeading,
  MissionSettingFormItem,
} from 'components/molecules';

const TagField = styled.div`
  position: relative;

  &:not(:last-child) {
    margin-bottom: 32px;
  }
`;

const TagsErrorMessage = styled(ErrorMessage)`
  position: absolute;
`;

type TagsFieldProps = {
  index: number;
  value: MissionProductFormValue['tags'];
  error: MissionProductsError['tags'];
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
  onBlur: (e: any) => void;
};

const TagsField: FC<TagsFieldProps> = props => {
  const { index, value, error, onChange, onBlur } = props;

  const { t } = useTranslation('missionSetting');

  return (
    <MissionSettingFormItem style={{ marginBottom: 0 }}>
      <FormItemSubHeading
        label={t('field.missionProducts.subField.tags.label')}
        note={t('field.missionProducts.subField.tags.note', {
          returnObjects: true,
          maxCount: MISSION_PRODUCT_PRODUCT_TAGS_MAX_COUNT,
          maxLength: MissionSettingMaxLength.MISSION_PRODUCT_TAG,
        })}
      />
      <div>
        {[...Array(MISSION_PRODUCT_PRODUCT_TAGS_MAX_COUNT).keys()].map(
          tagIndex => (
            <TagField key={`tag-${tagIndex}`}>
              <TextInput
                maxLength={MissionSettingMaxLength.MISSION_PRODUCT_TAG}
                name={`missionProducts[${index}].tags[${tagIndex}].tag`}
                type="text"
                value={value && value[tagIndex] ? value[tagIndex].tag : ''}
                placeholder={t(
                  'field.missionProducts.subField.tags.placeholder',
                )}
                onChange={onChange}
                onBlur={onBlur}
                outline
                aria-label={t('field.missionProducts.subField.tags.label')}
              />
            </TagField>
          ),
        )}
      </div>
      {error && (
        <TagsErrorMessage data-testid={`product-tag-error-${index}`}>
          {error}
        </TagsErrorMessage>
      )}
    </MissionSettingFormItem>
  );
};

export default React.memo(TagsField, (prevProps, nextProps) =>
  isEqualProps(prevProps, nextProps, ['value', 'error']),
);
