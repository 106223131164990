import React from 'react';
import styled from 'styled-components/macro';
import { isEqualProps } from 'utils/object';

type Props = {
  buttons: React.ReactNodeArray;
};

const Wrapper = styled.div`
  display: flex;
  position: absolute;
  bottom: 0;
  left: 0;
  align-items: center;
  justify-content: center;
  gap: 38px;
  width: 100%;
  height: 98px;
  background-color: rgba(255, 255, 255, 0.75);

  button {
    width: 254px;
  }
`;

const MissionFormFooter: React.VFC<Props> = ({ buttons }) => {
  return <Wrapper>{buttons}</Wrapper>;
};

export default React.memo(MissionFormFooter, isEqualProps);
