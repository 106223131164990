import React, { useState, useCallback } from 'react';
import styled, { css } from 'styled-components/macro';
import { useTranslation } from 'react-i18next';
import theme from 'styles/theme';
import { MissionJobOfferFormValue } from 'types/form';
import { VerticalEllipsis } from 'assets/svg';
import typography from 'styles/typography';
import { ButtonS } from 'components/atoms';
import { Overlay } from 'components/molecules';

const MenuContainer = styled.div`
  position: relative;
`;

const MenuButton = styled(ButtonS)<{ isActive?: boolean }>`
  min-width: 32px;
  padding: 0;
  background: transparent;

  &:hover {
    background: ${theme.basePrimary};

    > svg {
      fill: ${theme.white};
    }
  }
`;

const Menu = styled.div`
  position: absolute;
  z-index: 2;
  top: 34px;
  right: 12px;
  width: 158px;
  padding: 12px 0;
  border-radius: 8px;
  background-color: ${theme.baseWhite};
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.16);
`;

const MenuItem = styled.div<{ disabled?: boolean }>`
  ${typography.textDefault}
  display: flex;
  align-items: center;
  height: 32px;
  padding: 0 20px;
  line-height: 1;
  white-space: nowrap;
  cursor: pointer;
  user-select: none;

  &:hover {
    background-color: ${theme.bgMissionListHover};
  }

  ${({ disabled = false }) =>
    disabled &&
    css`
      color: ${theme.textSecondaryDisabled};
      cursor: default;
      pointer-events: none;
    `}
`;

type Props = {
  missionJobOffer: MissionJobOfferFormValue;
  onClickEdit: () => void;
  onClickPublish: () => void;
  onClickUnPublish: () => void;
  onClickDelete: () => void;
};

const MissionJobOfferMenu: React.VFC<Props> = ({
  missionJobOffer,
  onClickEdit,
  onClickPublish,
  onClickUnPublish,
  onClickDelete,
}) => {
  const { t } = useTranslation('missionSetting');

  const [showMenu, setShowMenu] = useState<boolean>(false);

  const openMenu = useCallback(() => {
    setShowMenu(true);
  }, []);

  const closeMenu = useCallback(() => {
    setShowMenu(false);
  }, []);

  const handleClickEdit = useCallback(() => {
    onClickEdit();
    closeMenu();
  }, [closeMenu, onClickEdit]);

  const handleClickPublish = useCallback(() => {
    onClickPublish();
    closeMenu();
  }, [closeMenu, onClickPublish]);

  const handleClickUnPublish = useCallback(() => {
    onClickUnPublish();
    closeMenu();
  }, [closeMenu, onClickUnPublish]);

  const handleClickDelete = useCallback(() => {
    onClickDelete();
    closeMenu();
  }, [closeMenu, onClickDelete]);

  return (
    <MenuContainer>
      <MenuButton
        onClick={openMenu}
        data-testid="mission-job-offer-menu-button"
        type="button"
      >
        <VerticalEllipsis />
      </MenuButton>
      {showMenu && (
        <>
          <Overlay onDismiss={closeMenu} />
          <Menu data-testid="mission-job-offer-menu">
            <MenuItem onClick={handleClickEdit} key="menu-edit">
              {t('field.missionJobOffer.menu.edit')}
            </MenuItem>
            <MenuItem
              disabled={missionJobOffer.published}
              onClick={handleClickPublish}
              key="menu-publish"
            >
              {t('field.missionJobOffer.menu.publish')}
            </MenuItem>
            <MenuItem
              disabled={!missionJobOffer.published}
              onClick={handleClickUnPublish}
              key="menu-unpublish"
            >
              {t('field.missionJobOffer.menu.unPublish')}
            </MenuItem>
            <MenuItem onClick={handleClickDelete} key="menu-delete">
              {t('field.missionJobOffer.menu.delete')}
            </MenuItem>
          </Menu>
        </>
      )}
    </MenuContainer>
  );
};

export default MissionJobOfferMenu;
