import { onAuthStateChanged } from 'firebase/auth';
import { useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { UiActions } from 'modules/ui';
import { getSupporterId } from 'modules/account';
import apiSupporter from 'external/api/supporter';
import apiFirebase from 'external/firebase/firebase';
import axios, { CancelTokenSource } from 'axios';
import RequestError from 'classes/RequestError';
import useErrorDialog from 'hooks/useErrorDialog';
import { SupporterFormState } from './useSupporterFormState';
import convertSupporterToSupporterFormValues from '../convertSupporterToSupporterFormValues';

const useFetchSupporterForm = ({
  setError,
  defaultInitialValues,
  setInitialValues,
  setForm,
  setIsPublished,
}: SupporterFormState) => {
  const { t } = useTranslation(['error']);
  const dispatch = useDispatch();
  const { handleRequestError } = useErrorDialog();
  const supporterId = useSelector(getSupporterId) ?? '';

  const fetchSupporterForm = useCallback(
    async (source: CancelTokenSource) => {
      dispatch(UiActions.setLoading(true));
      setError(null);
      let data;
      try {
        ({ data } = await apiSupporter.getSupporterForm(
          { supporterId },
          source.token,
        ));
      } catch (anyError) {
        if (anyError instanceof Error) {
          setError(anyError.message);
        }
        dispatch(UiActions.setLoading(false));
        if (anyError instanceof RequestError) {
          handleRequestError(anyError, t('error:failedGettingAny'));
        }
        return;
      }

      if (data?.supporter) {
        const converted = convertSupporterToSupporterFormValues(data.supporter);

        setInitialValues({
          ...converted,
          supporterMembers:
            converted.supporterMembers &&
            converted.supporterMembers.length === 0
              ? defaultInitialValues.supporterMembers
              : converted.supporterMembers,
          galleryImages:
            converted.galleryImages && converted.galleryImages.length === 0
              ? defaultInitialValues.galleryImages
              : converted.galleryImages,
        });
      }
      if (data.form) setForm(data.form);
      setIsPublished(data.published);
      dispatch(UiActions.setLoading(false));
    },
    [
      defaultInitialValues.galleryImages,
      defaultInitialValues.supporterMembers,
      dispatch,
      handleRequestError,
      setError,
      setForm,
      setInitialValues,
      setIsPublished,
      supporterId,
      t,
    ],
  );

  useEffect(() => {
    let source: CancelTokenSource | undefined;

    const unsubscribeAuthStateChanged = onAuthStateChanged(
      apiFirebase.auth(),
      user => {
        if (user) {
          source?.cancel();
          source = axios.CancelToken.source();
          fetchSupporterForm(source);
        } else {
          source?.cancel();
        }
      },
    );

    return () => {
      unsubscribeAuthStateChanged();
      source?.cancel();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);
};

export default useFetchSupporterForm;
