import { ComponentProps } from 'react';
import styled, { css } from 'styled-components/macro';
import { Tag } from 'visits-style';
import theme from 'styles/theme';

const StyledTag = styled(Tag)<StyledTagProps>`
  min-width: 68px;
  height: auto;
  margin-bottom: 10px;
  padding: 10px 20px;
  font-size: 12px;
  ${({ size = 'medium' }) =>
    size === 'small' &&
    css`
      min-width: auto;
      padding: 5px 12px 7px 12px;
    `}
  ${({ bordered = false, color = 'primary' }) =>
    bordered &&
    css`
      background: ${theme.baseWhite};
      color: ${theme[color]};
      border: 1px solid ${theme[color]};
    `}
`;

type StyledTagProps = ComponentProps<typeof Tag> & {
  bordered?: boolean;
  size?: 'medium' | 'small';
};

export default StyledTag;
