import { ChangeEvent, useCallback, useEffect } from 'react';
import { useFormik } from 'formik';
import { CorporateAttribute } from 'proto/v1/apimodel/apimodel';
import {
  CorporateAttributePickerValues,
  CorporateAttributeMissionFilterValues,
} from 'types/mission';

const useCorporateAttributePicker = ({
  form,
  onSubmit,
  defaultValues,
  isSelected,
}: {
  onSubmit: (values: CorporateAttributePickerValues) => void;
  form: { corporateAttributes: CorporateAttribute[] | undefined };
  defaultValues: CorporateAttributeMissionFilterValues;
  isSelected: boolean;
}) => {
  const allCorporateAttributeIds = form.corporateAttributes
    ? form.corporateAttributes.map(v => v.id)
    : [];

  const isCheckedAllDefaultCorporateAttributeIds =
    defaultValues.corporateAttributeIds.length ===
    allCorporateAttributeIds.length;

  const formik = useFormik<CorporateAttributePickerValues>({
    initialValues: {
      checkAllCorporateAttributes: isCheckedAllDefaultCorporateAttributeIds,
      corporateAttributeIds: defaultValues.corporateAttributeIds,
    },
    onSubmit,
    enableReinitialize: true,
  });

  const { values, setFieldValue, resetForm } = formik;

  const handleChangeCorporateAttribute = useCallback(
    (event: ChangeEvent<HTMLInputElement>): void => {
      const { target } = event;
      const targetValue = Number(target.value);
      const { corporateAttributeIds } = values;
      if (target.checked) {
        setFieldValue('corporateAttributeIds', [
          ...corporateAttributeIds,
          targetValue,
        ]);
      } else {
        setFieldValue(
          'corporateAttributeIds',
          corporateAttributeIds.filter(
            corporateAttributeId => corporateAttributeId !== targetValue,
          ),
        );
      }
    },
    [setFieldValue, values],
  );

  const handleChangeCheckAllCorporateAttributes = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const { checked } = event.currentTarget;
      setFieldValue(
        'corporateAttributeIds',
        checked ? allCorporateAttributeIds : [],
      );
      setFieldValue('checkAllCorporateAttributes', checked);
    },
    [allCorporateAttributeIds, setFieldValue],
  );

  useEffect(() => {
    if (!isSelected) resetForm();
  }, [isSelected, resetForm]);

  return {
    formik,
    handleChangeCorporateAttribute,
    handleChangeCheckAllCorporateAttributes,
  };
};

export default useCorporateAttributePicker;
