import React, {
  useState,
  useMemo,
  useCallback,
  useEffect,
  useRef,
  RefObject,
} from 'react';
import styled from 'styled-components/macro';
import { CSSTransition } from 'react-transition-group';
import { useTranslation } from 'react-i18next';
import theme from 'styles/theme';
import {
  Tabs,
  Tab,
  TabList,
  TabPanel,
  DetailContents,
  DetailContentsLeft,
  DetailContentsRight,
} from 'components/molecules';
import { CoverImageField } from 'components/organisms/Details/Common/Fields';
import {
  CompanyContent,
  DemandDetailContent,
  AboutMissionContent,
  ProductDetailContent,
  MissionJobOffersContent,
} from 'components/organisms/Details/MissionDetail/Contents';
import { ContactButtonContent } from 'components/organisms/Details/Common/Contents';
import { CrossWhite } from 'assets/svg';
import { isEmptyMissionDemand } from 'utils/models/mission';
import { isEqualProps } from 'utils/object';
import { MissionFormStep, MissionFormValues } from 'types/mission';
import { MISSION_PRODUCTS_MAX_COUNT } from 'constants/config';
import useMissionFormContext from './useMissionFormContext';
import convertMissionFormValuesToMission from './convertMissionFormValuesToMission';

const ABOUT_MISSION_TAB_INDEX = 0;
const MISSION_PRODUCTS_TAB_INDEX = 1;
const MISSION_DEMAND_OR_MISSION_JOB_OFFERS_TAB_INDEX = 2;

const PreviewPlane = styled.div<{ showScoutForm?: boolean }>`
  position: absolute;
  z-index: 5;
  top: 0;
  right: 0;
  height: 100%;
  transform: translateX(864px);
  transition: transform 500ms;
  box-shadow: 0 0 30px rgba(0, 0, 0, 0.3);

  &.enter {
    transform: translateX(0);
  }

  &.enter-done {
    transform: translateX(0);
  }

  &.exit {
    transform: translateX(864px);
  }

  &.exit-done {
    transform: translateX(864px);
  }
`;

const Preview = styled.div`
  position: relative;
  width: 864px;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  background-color: ${theme.baseWhite};
`;

const CoverImage = styled.div`
  position: relative;
`;

const CloseIcon = styled(CrossWhite)`
  position: absolute;
  top: 12px;
  left: 12px;
  cursor: pointer;

  &:hover {
    opacity: 0.8;
  }
`;

const TargetDescription = styled.div`
  position: absolute;
  top: 6px;
  left: 48px;
  padding: 12px 22px;
  border-radius: 8px;
  background: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(19px);
  color: ${theme.baseWhite};
  font-size: 14px;
`;

type Props = {
  values: Partial<MissionFormValues>;
  step: MissionFormStep;
  forInnovator?: boolean;
};

const MissionPreview: React.VFC<Props> = ({ values, step, forInnovator }) => {
  const { t } = useTranslation('missionDetail');

  const {
    missionFormValues,
    form,
    mission,
    showPreview,
    closePreview,
  } = useMissionFormContext();

  const [showPreviewContent, setShowPreviewContent] = useState<boolean>(false);
  const [selectedTabIndex, setSelectedTabIndex] = useState<number>(() => {
    switch (step) {
      case 'company':
      case 'aboutMission':
        return ABOUT_MISSION_TAB_INDEX;
      case 'missionProducts':
        return MISSION_PRODUCTS_TAB_INDEX;
      case 'missionDemand':
      case 'missionJobOffers':
        return MISSION_DEMAND_OR_MISSION_JOB_OFFERS_TAB_INDEX;
      default:
        return ABOUT_MISSION_TAB_INDEX;
    }
  });
  const [clickedProductCardIndex, setClickedProductCardIndex] = useState<
    null | number
  >(null);

  const productDetailRefs = useRef<RefObject<HTMLDivElement>[]>(
    [...Array(MISSION_PRODUCTS_MAX_COUNT)].map(() => React.createRef()),
  ).current;

  const handleTabSelect = useCallback((tabIndex: number) => {
    setSelectedTabIndex(tabIndex);
  }, []);

  const handleClickProductCard = useCallback((productIndex: number) => {
    setSelectedTabIndex(MISSION_PRODUCTS_TAB_INDEX);
    setClickedProductCardIndex(productIndex);
  }, []);

  const handleExitedPreview = useCallback(() => {
    setShowPreviewContent(false);
  }, []);

  const missionToPreview = useMemo(() => {
    if (!missionFormValues) return undefined;
    return convertMissionFormValuesToMission(
      {
        ...missionFormValues,
        ...values,
      },
      form,
      { corporateAttributes: mission?.corporateAttributes ?? [] },
    );
  }, [form, mission, missionFormValues, values]);

  // Handle click product cards
  useEffect(() => {
    if (clickedProductCardIndex === null) return;
    const productDetailElement =
      productDetailRefs[clickedProductCardIndex].current;
    productDetailElement?.scrollIntoView();
    setClickedProductCardIndex(null);
  }, [clickedProductCardIndex, productDetailRefs]);

  // show preview content when showPreview is true.
  useEffect(() => {
    if (showPreview) setShowPreviewContent(true);
  }, [showPreview]);

  // Close preview when mission preview is unmounted.
  useEffect(() => {
    return () => {
      closePreview();
    };
  }, [closePreview]);

  return useMemo(
    () => (
      <CSSTransition
        in={showPreview}
        appear={showPreview}
        timeout={500}
        onExited={handleExitedPreview}
      >
        <PreviewPlane>
          {showPreviewContent && missionToPreview && (
            <Preview data-testid="mission-preview">
              <CoverImage>
                <CoverImageField
                  name={missionToPreview.name}
                  coverImage={missionToPreview.coverImage}
                />
                <CloseIcon onClick={closePreview} />
                <TargetDescription>
                  {forInnovator
                    ? t('description.forInnovator')
                    : t('description.forMission')}
                </TargetDescription>
              </CoverImage>
              <DetailContents>
                <DetailContentsLeft>
                  <Tabs
                    selectedIndex={selectedTabIndex}
                    onSelect={handleTabSelect}
                  >
                    <TabList
                      style={{
                        padding: '40px 24px 0 32px',
                        marginBottom: '40px',
                      }}
                    >
                      <Tab>{t('tab.mission')}</Tab>
                      <Tab
                        disabled={missionToPreview.missionProducts.length === 0}
                        data-testid="tab-mission-products"
                      >
                        {t('tab.missionProducts')}
                      </Tab>
                      {forInnovator ? (
                        <Tab
                          disabled={
                            missionToPreview.missionJobOffers.length === 0
                          }
                          data-testid="tab-mission-job-offers"
                        >
                          {t('tab.missionJobOffers')}
                        </Tab>
                      ) : (
                        <Tab
                          disabled={isEmptyMissionDemand(
                            missionToPreview.missionDemand,
                          )}
                          data-testid="tab-mission-demand"
                        >
                          {t('tab.missionDemand')}
                        </Tab>
                      )}
                    </TabList>

                    <TabPanel>
                      <AboutMissionContent
                        mission={missionToPreview}
                        onClickProductCard={handleClickProductCard}
                      />
                    </TabPanel>

                    <TabPanel>
                      <ProductDetailContent
                        missionProducts={missionToPreview.missionProducts}
                        productDetailRefs={productDetailRefs}
                      />
                    </TabPanel>

                    {forInnovator ? (
                      <TabPanel>
                        <MissionJobOffersContent
                          missionJobOffers={missionToPreview.missionJobOffers}
                        />
                      </TabPanel>
                    ) : (
                      <TabPanel>
                        <DemandDetailContent
                          missionDemand={missionToPreview.missionDemand}
                        />
                      </TabPanel>
                    )}
                  </Tabs>
                </DetailContentsLeft>

                <DetailContentsRight data-testid="company-info">
                  {!forInnovator && (
                    <ContactButtonContent
                      labelContact={t('button.contact')}
                      labelContacted={t('button.contacted')}
                      show
                      isPreview
                      style={{ paddingTop: 32 }}
                    />
                  )}
                  <CompanyContent
                    mission={missionToPreview}
                    forInnovator={forInnovator}
                  />
                </DetailContentsRight>
              </DetailContents>
            </Preview>
          )}
        </PreviewPlane>
      </CSSTransition>
    ),
    [
      closePreview,
      forInnovator,
      handleClickProductCard,
      handleExitedPreview,
      handleTabSelect,
      missionToPreview,
      productDetailRefs,
      selectedTabIndex,
      showPreview,
      showPreviewContent,
      t,
    ],
  );
};

export default React.memo(MissionPreview, isEqualProps);
