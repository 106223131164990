import i18n from 'i18n/i18n';
import { MissionFormValues, MissionFormErrors } from 'types/mission';
import { SupporterFormValues, SupporterFormErrors } from 'types/supporter';
import {
  isEmptyMissionMember,
  isFullInfoMissionMember,
  isEmptyMissionProduct,
} from 'utils/models/mission';
import {
  isEmptySupporterMember,
  isFullInfoSupporterMember,
} from 'utils/models/supporter';

export type ValidateToSaveErrorKey =
  | 'missionDemand'
  | 'missionMembers'
  | 'companyUrl'
  | 'missionProducts';

export type ValidateToSaveErrors = Partial<
  Record<ValidateToSaveErrorKey, string[]>
>;

export type ValidateToSave<Values> = (
  errors: unknown,
  values: Values,
) => ValidateToSaveErrors;

const t = i18n.getFixedT(null, 'missionSetting');

const isIncorrectUrlError = (urlError: string | undefined) =>
  urlError === t('validation.incorrectUrl');

const isMissionMembersRequiredError = (
  errors: MissionFormErrors['missionMembers'],
  missionMembers: MissionFormValues['missionMembers'],
) =>
  errors &&
  !missionMembers.every(
    missionMember =>
      isEmptyMissionMember(missionMember) ||
      isFullInfoMissionMember(missionMember),
  );

const isSupporterMembersRequiredError = (
  errors: SupporterFormErrors['supporterMembers'],
  supporterMembers: SupporterFormValues['supporterMembers'],
) =>
  errors &&
  !supporterMembers.every(
    supporterMember =>
      isEmptySupporterMember(supporterMember) ||
      isFullInfoSupporterMember(supporterMember),
  );

const isMissionProductsRequiredError = (
  errors: MissionFormErrors['missionProducts'],
  missionProducts: MissionFormValues['missionProducts'],
) =>
  errors.some(
    (error, index) =>
      !!error &&
      !isIncorrectUrlError(error?.url) &&
      missionProducts[index] &&
      !isEmptyMissionProduct(missionProducts[index]),
  );

const isMissionProductsIncorrectUrlError = (
  errors: MissionFormErrors['missionProducts'],
) => errors.some(error => isIncorrectUrlError(error?.url));

export const getMissionDemandErrorMessages = (
  errors: MissionFormErrors['missionDemand'],
) => {
  if (!errors) return [];
  const errorMessages: string[] = [];
  if (errors.wantInvest) {
    errorMessages.push(
      t('modal.inValidToSave.missionDemand.message.requiredWantInvest'),
    );
  }
  if (errors.wantFundraising) {
    errorMessages.push(
      t('modal.inValidToSave.missionDemand.message.requiredFinancingSeries'),
    );
  }
  return errorMessages;
};

export const getMissionMembersErrorMessages = (
  errors: MissionFormErrors['missionMembers'],
  missionMembers: MissionFormValues['missionMembers'],
) => {
  if (!errors) return [];
  const errorMessages: string[] = [];
  if (isMissionMembersRequiredError(errors, missionMembers)) {
    errorMessages.push(
      t('modal.inValidToSave.missionMembers.message.requiredAllInputs'),
    );
  }
  return errorMessages;
};

export const getSupporterMembersErrorMessages = (
  errors: SupporterFormErrors['supporterMembers'],
  missionMembers: SupporterFormValues['supporterMembers'],
) => {
  if (!errors) return [];
  const errorMessages: string[] = [];
  if (isSupporterMembersRequiredError(errors, missionMembers)) {
    errorMessages.push(
      t('modal.inValidToSave.missionMembers.message.requiredAllInputs'),
    );
  }
  return errorMessages;
};

export const getCompanyUrlErrorMessages = (
  error: MissionFormErrors['companyUrl'],
) => {
  const errorMessages: string[] = [];
  if (isIncorrectUrlError(error)) {
    errorMessages.push(
      t('modal.inValidToSave.companyUrl.message.incorrectUrl'),
    );
  }
  return errorMessages;
};

export const getMissionProductsErrorMessages = (
  errors: MissionFormErrors['missionProducts'],
  missionProducts: MissionFormValues['missionProducts'],
) => {
  if (!errors) return [];

  const errorMessages: string[] = [];

  if (isMissionProductsRequiredError(errors, missionProducts)) {
    errorMessages.push(
      t('modal.inValidToSave.missionProducts.message.requiredAllInputs'),
    );
  }
  if (isMissionProductsIncorrectUrlError(errors)) {
    errorMessages.push(
      t('modal.inValidToSave.missionProducts.message.incorrectUrl'),
    );
  }

  return errorMessages;
};

export const filterEmptyErrors = (errorMessages: ValidateToSaveErrors) =>
  Object.entries<string[] | undefined>(errorMessages)
    .filter(([_, messages]) => messages && messages.length > 0)
    .reduce<ValidateToSaveErrors>((prevValue, [key, messages]) => {
      prevValue[key as keyof ValidateToSaveErrors] = messages;
      return prevValue;
    }, {});
