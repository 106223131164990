import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Mission } from 'proto/v1/apimodel/apimodel';
import { sortedMissionCategories } from 'utils/models/mission';
import {
  CategoryTag,
  DetailSubTitle,
  DetailCompanyField,
} from 'components/molecules';

type MissionCategoriesFieldProps = {
  missionCategories: Mission['missionCategories'];
};

const MissionCategoriesField: FC<MissionCategoriesFieldProps> = ({
  missionCategories,
}) => {
  const { t } = useTranslation('missionDetail');

  return (
    <DetailCompanyField>
      <DetailSubTitle>{t('field.missionCategories.label')}</DetailSubTitle>
      {sortedMissionCategories(missionCategories).map(category => (
        <CategoryTag
          backgroundColor={category.backgroundColor}
          style={{ margin: '0 4px 4px 0' }}
          key={category.categoryId}
          size="small"
          round
          data-testid="category-tag"
        >
          {category.name}
        </CategoryTag>
      ))}
    </DetailCompanyField>
  );
};

export default MissionCategoriesField;
