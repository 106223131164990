import styled, { css } from 'styled-components/macro';
import theme from 'styles/theme';

type DetailContentsRightItemProps = {
  borderTop?: boolean;
};

const DetailContentsRightItem = styled.div<DetailContentsRightItemProps>`
  padding: 32px 32px 32px 24px;
  ${({ borderTop }) =>
    borderTop &&
    css`
      border-top: 1px solid ${theme.borderDefault};
    `}
`;

export default DetailContentsRightItem;
