import React from 'react';
import { Star } from 'assets/svg';
import styled, { css } from 'styled-components/macro';
import theme from 'styles/theme';

type Props = { isFavorite: boolean } & React.HTMLAttributes<HTMLDivElement>;

const Wrapper = styled.div<Props>`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 26px;
  height: 26px;
  cursor: pointer;
  ${({ isFavorite }) =>
    !isFavorite &&
    css`
      path {
        fill: ${theme.textSecondaryHover};
      }
    `}

  &:hover {
    opacity: 0.8;
  }
`;

const FavoriteIcon: React.FC<Props> = props => (
  <Wrapper {...props} data-testid="favorite-icon">
    <Star />
  </Wrapper>
);

export default FavoriteIcon;
