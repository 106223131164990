import axios, { AxiosInstance } from 'axios';
import i18n from 'i18n/i18n';
import handleError from './handleError';

const isIE = (() =>
  window.navigator.userAgent.toLowerCase().match(/(msie)|(trident)/))();

const client = (token?: string): AxiosInstance => {
  const axiosClient = axios.create();
  axiosClient.defaults.timeout = 20000; // ms
  axiosClient.defaults.headers = {
    'Content-Type': 'application/json',
    'X-Locale': i18n.languages[0],
  };
  if (token) axiosClient.defaults.headers.Authorization = `Bearer ${token}`;
  if (isIE) {
    // IEでミッションを切り替えた時にキャッシュによって画面が更新されないことへの対応
    // https://s8a.jp/no-cache-using-ajax-with-ie11#axios%E3%81%AE%E5%A0%B4%E5%90%88
    axiosClient.interceptors.request.use(config => {
      if (typeof config.params === 'undefined') {
        config.params = {};
      }
      if (typeof config.params === 'object') {
        if (
          typeof URLSearchParams === 'function' &&
          config.params instanceof URLSearchParams
        )
          config.params.append('_', Date.now().toString());
        else config.params._ = Date.now();
      }
      return config;
    });
  }

  axiosClient.interceptors.response.use(response => response, handleError);
  return axiosClient;
};

export default client;
