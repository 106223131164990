import { useState, useCallback, useEffect } from 'react';
import {
  MISSION_COMPANY_ESTABLISHED_YEAR_INITIAL_VALUE,
  MISSION_COMPANY_ESTABLISHED_MONTH_INITIAL_VALUE,
} from 'constants/config';
import { MissionFormValues } from 'types/mission';
import {
  getCompanyUrlErrorMessages,
  filterEmptyErrors,
} from '../../../validate/validateToSave';
import { validateMissionFormValuesToPublish } from '../../validateToPublish';
import useAddressField from '../../../hooks/useAddressField';
import useCompanyEstablishedDateField from '../../../hooks/useCompanyEstablishedDateField';
import { stepCompanySchema } from '../../validationSchema';
import useMissionFormContext from '../../useMissionFormContext';
import useMissionFormFormik from '../../useMissionFormFormik';

type Values = Pick<
  MissionFormValues,
  | 'companyName'
  | 'companyEstablishedDate'
  | 'countryId'
  | 'japanPrefectureId'
  | 'otherAddress'
  | 'companyUrl'
  | 'iconImage'
  | 'fundId'
>;

const defaultInitialValues: Values = {
  companyName: '',
  companyEstablishedDate: {
    year: MISSION_COMPANY_ESTABLISHED_YEAR_INITIAL_VALUE,
    month: MISSION_COMPANY_ESTABLISHED_MONTH_INITIAL_VALUE,
  },
  companyUrl: '',
  countryId: '',
  iconImage: undefined,
  japanPrefectureId: undefined,
  otherAddress: '',
  fundId: 0,
};

const useStepCompany = () => {
  const {
    moveNextStep,
    mission,
    form,
    isFirstPublished,
    missionFormValues,
    openImageCropper,
    isPublished,
    updateMission,
    publishMission,
    unPublishMission,
    imageCropperState,
    cropperAspectRatio,
    handleExceedImageFileSize,
    handleCroppedImage,
    handleClickCropperCloseButton,
    modalState,
    closeModal,
    openModal,
    validateToSaveErrors,
    setValidateToSaveErrors,
    validateToPublishStepsResult,
    setValidateToPublishStepsResult,
    openPreview,
    canUseMissionFormStepMissionDemand,
    canUseMissionFormStepMissionJobOffers,
  } = useMissionFormContext();

  const [initialValues, setInitialValues] = useState<Values>(
    defaultInitialValues,
  );

  const {
    formik,
    formError,
    handleChangeSelectedValueAsNumber,
  } = useMissionFormFormik<Values>({
    initialValues,
    validationSchema: stepCompanySchema,
  });

  const { dirty, values, errors, resetForm } = formik;

  const getErrorsToSave = useCallback(
    () =>
      filterEmptyErrors({
        companyUrl: getCompanyUrlErrorMessages(errors.companyUrl),
      }),
    [errors.companyUrl],
  );

  const validateToSave = useCallback(() => {
    if (!missionFormValues) return;

    const errorsToSave = getErrorsToSave();
    if (Object.keys(errorsToSave).length > 0) {
      setValidateToSaveErrors(errorsToSave);
      openModal('invalidToSave');
      return false;
    }

    const { isValid, stepsResult } = validateMissionFormValuesToPublish(
      {
        ...missionFormValues,
        ...values,
      },
      canUseMissionFormStepMissionDemand,
      canUseMissionFormStepMissionJobOffers,
    );
    if (!isValid) {
      setValidateToPublishStepsResult(stepsResult);
      openModal('saveRequiredNoInputConfirmation');
      return false;
    }

    return true;
  }, [
    canUseMissionFormStepMissionDemand,
    canUseMissionFormStepMissionJobOffers,
    getErrorsToSave,
    missionFormValues,
    openModal,
    setValidateToPublishStepsResult,
    setValidateToSaveErrors,
    values,
  ]);

  const validateToPublish = useCallback(() => {
    if (!missionFormValues) return;

    const errorsToSave = getErrorsToSave();
    if (Object.keys(errorsToSave).length > 0) {
      setValidateToSaveErrors(errorsToSave);
      openModal('invalidToSave');
      return false;
    }

    const { isValid, stepsResult } = validateMissionFormValuesToPublish(
      {
        ...missionFormValues,
        ...values,
      },
      canUseMissionFormStepMissionDemand,
      canUseMissionFormStepMissionJobOffers,
    );

    setValidateToPublishStepsResult(stepsResult);

    if (!isValid) {
      openModal('invalidToPublish');
      return false;
    }

    return true;
  }, [
    canUseMissionFormStepMissionDemand,
    canUseMissionFormStepMissionJobOffers,
    getErrorsToSave,
    missionFormValues,
    openModal,
    setValidateToPublishStepsResult,
    setValidateToSaveErrors,
    values,
  ]);

  const handleClickSave = useCallback(() => {
    if (!dirty) return;
    if (!validateToSave()) return;
    openModal('saveMissionConfirmation');
  }, [dirty, openModal, validateToSave]);

  const saveMission = useCallback(async () => {
    if (!missionFormValues) return;
    try {
      await updateMission({ ...missionFormValues, ...values });
    } catch (error) {
      throw new Error('Failed to update mission');
    }
    setInitialValues(values);
    resetForm();
  }, [missionFormValues, resetForm, updateMission, values]);

  const handleClickNext = useCallback(async () => {
    if (dirty) {
      const errorsToSave = getErrorsToSave();
      if (Object.keys(errorsToSave).length > 0) {
        setValidateToSaveErrors(errorsToSave);
        openModal('invalidToSave');
        return;
      }
      try {
        await saveMission();
      } catch (error) {
        // Nothing to do.
        return;
      }
    }
    moveNextStep();
  }, [
    dirty,
    getErrorsToSave,
    moveNextStep,
    openModal,
    saveMission,
    setValidateToSaveErrors,
  ]);

  const handleClickSaveConfirmationOK = useCallback(async () => {
    try {
      await saveMission();
    } catch (error) {
      // Nothing to do.
      return;
    }
    openModal('saveMissionCompleted');
  }, [openModal, saveMission]);

  const handleClickPublish = useCallback(async () => {
    if (!validateToPublish()) {
      return;
    }
    openModal('publishMissionConfirmation');
  }, [openModal, validateToPublish]);

  const handleClickPublishConfirmationOk = useCallback(async () => {
    if (!missionFormValues) return;
    try {
      await publishMission({ ...missionFormValues, ...values });
    } catch (error) {
      // Nothing to do.
      return;
    }
    setInitialValues(values);
    resetForm();
  }, [missionFormValues, publishMission, resetForm, values]);

  const handleClickUnPublishConfirmationOk = useCallback(async () => {
    if (!missionFormValues) return;
    try {
      await unPublishMission({ ...missionFormValues, ...values });
    } catch (error) {
      // Nothing to do.
      return;
    }
    setInitialValues(values);
    resetForm();
  }, [missionFormValues, resetForm, unPublishMission, values]);

  const { handleChangeOtherAddress } = useAddressField({
    setFieldValue: formik.setFieldValue,
  });

  const { handleChangeCompanyEstablishedYear } = useCompanyEstablishedDateField(
    {
      companyEstablishedDate: formik.values.companyEstablishedDate,
      setFieldValue: formik.setFieldValue,
    },
  );

  // Set initial values for fetched mission.
  useEffect(() => {
    if (!missionFormValues) return;
    setInitialValues({
      companyName: missionFormValues.companyName,
      companyEstablishedDate: missionFormValues.companyEstablishedDate,
      companyUrl: missionFormValues.companyUrl,
      countryId: missionFormValues.countryId,
      iconImage: missionFormValues.iconImage,
      japanPrefectureId: missionFormValues.japanPrefectureId,
      otherAddress: missionFormValues.otherAddress,
      fundId: missionFormValues.fundId,
    });
  }, [missionFormValues]);

  return {
    handleClickNext,
    mission,
    isPublished,
    isFirstPublished,
    formik,
    formError,
    handleChangeSelectedValueAsNumber,
    form,
    handleChangeOtherAddress,
    handleChangeCompanyEstablishedYear,
    openImageCropper,
    imageCropperState,
    cropperAspectRatio,
    handleCroppedImage,
    handleExceedImageFileSize,
    handleClickCropperCloseButton,
    modalState,
    closeModal,
    handleClickSave,
    validateToSaveErrors,
    handleClickSaveConfirmationOK,
    handleClickPublish,
    handleClickPublishConfirmationOk,
    validateToPublishStepsResult,
    handleClickUnPublishConfirmationOk,
    openPreview,
    canUseMissionFormStepMissionJobOffers,
    canUseMissionFormStepMissionDemand,
  };
};

export default useStepCompany;
