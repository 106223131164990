import apiFirebase, { doAnalytics } from 'external/firebase/firebase';
import { setUserId, setUserProperties } from 'firebase/analytics';
import { getAccountInfo } from 'modules/account';
import { getMyAccounts } from 'modules/myAccounts';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';

const useGAUserPropertyUpdater = () => {
  const { userId, accountId } = useSelector(getAccountInfo);
  const accounts = useSelector(getMyAccounts);
  const account = accounts.find(a => a.accountId === accountId);

  useEffect(() => {
    if (!doAnalytics) return;
    setUserId(apiFirebase.analytics(), userId);
    setUserProperties(apiFirebase.analytics(), {
      // eslint-disable-next-line
      account_id: account?.accountId ?? '',
      // eslint-disable-next-line
      mission_id: account?.missionId ?? '',
      // eslint-disable-next-line
      supporter_id: account?.supporterId ?? '',
      published: account?.published,
    });
  }, [account, userId]);
};

export default useGAUserPropertyUpdater;
