import React, { useMemo } from 'react';
import styled from 'styled-components/macro';
import { useTranslation } from 'react-i18next';
import theme from 'styles/theme';
import typography from 'styles/typography';
import { Spinner } from 'visits-style';
import { PrivateRouteSpacer } from 'components/templates';
import { ButtonM, FlexContainer } from 'components/atoms';

const SpacerAdjustView = styled(PrivateRouteSpacer)`
  height: 100vh;
  padding-top: 0;
  padding-right: 0;
  padding-bottom: 0;
`;

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

const ListContainer = styled.div`
  flex: 1;
  padding-right: 14px;
  overflow-x: hidden;
  overflow-y: auto;
`;

const LoadingInfo = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 7px;
  margin-bottom: 2px;
`;

const LoadingMessage = styled.p`
  ${typography.textNote}
  margin-bottom: 8px;
  color: ${theme.textSecondary};
`;

const SpinnerContainer = styled.div`
  height: 52px;
  margin-top: 5px;
  margin-bottom: 2px;
`;

type SearchPageTemplateProps<T> = {
  items: T[] | null;
  isFetchingNext: boolean;
  errorOnFetchNext: { message: string } | null;
  handleClickRetry: () => void;
  listContainerRef:
    | React.RefObject<HTMLDivElement>
    | ((instance: HTMLDivElement | null) => void);
  handleScroll: () => void;
  noItemsMessage: string;
  renderFilter: () => React.ReactElement;
  renderCard: (item: T) => React.ReactElement;
  renderListHead?: () => React.ReactElement;
  itemsWrapperStyle?: React.CSSProperties;
  spacerStyle?: React.CSSProperties;
};

type SearchPageTemplateType = <T>(
  props: SearchPageTemplateProps<T>,
) => React.ReactElement<SearchPageTemplateProps<T>>;

const SearchPageTemplate: SearchPageTemplateType = ({
  items,
  isFetchingNext,
  errorOnFetchNext,
  handleClickRetry,
  listContainerRef,
  handleScroll,
  noItemsMessage,
  renderFilter,
  renderCard,
  renderListHead,
  itemsWrapperStyle,
  spacerStyle,
}) => {
  const { t } = useTranslation(['common']);

  const cards = useMemo(() => {
    if (!items) return null;
    if (items.length === 0)
      return <div style={itemsWrapperStyle ?? {}}>{noItemsMessage}</div>;
    return (
      <div style={{ marginRight: '-24px', ...itemsWrapperStyle }}>
        <FlexContainer flexWrap="wrap">{items?.map(renderCard)}</FlexContainer>
      </div>
    );
  }, [items, itemsWrapperStyle, noItemsMessage, renderCard]);

  return (
    <SpacerAdjustView style={spacerStyle ?? {}}>
      <ContentWrapper>
        {renderFilter()}
        <ListContainer ref={listContainerRef} onScroll={handleScroll}>
          {renderListHead && renderListHead()}
          {cards}
          <LoadingInfo>
            {isFetchingNext && (
              <SpinnerContainer>
                <Spinner size="40px" />
              </SpinnerContainer>
            )}
            {errorOnFetchNext && (
              <>
                <LoadingMessage>
                  {errorOnFetchNext.message.split('\n').map((item, index) => (
                    // eslint-disable-next-line react/no-array-index-key
                    <p key={index}>{item}</p>
                  ))}
                </LoadingMessage>
                <ButtonM buttonTheme="default" onClick={handleClickRetry}>
                  {t('common:button.retry')}
                </ButtonM>
              </>
            )}
          </LoadingInfo>
        </ListContainer>
      </ContentWrapper>
    </SpacerAdjustView>
  );
};

export default SearchPageTemplate;
