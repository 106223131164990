export const PRIVATE_MISSIONS = '/missions';
export const PRIVATE_PRODUCTS = '/products';
export const PRIVATE_SUPPORTERS = '/supporters';
export const PRIVATE_MESSAGES = '/messages';
export const PRIVATE_SETTINGS_MISSION = '/settings/mission';
export const PRIVATE_SETTINGS_ACCOUNT = '/settings/account';
export const PRIVATE_MESSAGE_TEMPLATE = '/settings/message_template';
export const PRIVATE_FOLLOWERS_MISSIONS = '/followers/missions';
export const PRIVATE_FOLLOWERS_INNOVATORS = '/followers/innovators';
export const PRIVATE_FOLLOWING_MISSIONS = '/following/missions';
export const PRIVATE_FOLLOWING_HUMAN_RESOURCES = '/following/human_resources';
export const PRIVATE_HUMAN_RESOURCES_CAREER = '/human_resources/career';
export const PRIVATE_HUMAN_RESOURCES_NEW_GRADUATE =
  '/human_resources/new_graduate';
