import React, { FC, ChangeEvent } from 'react';
import styled from 'styled-components/macro';
import { useTranslation } from 'react-i18next';
import { MissionMemberFormValue } from 'types/form';
import { isEqualProps } from 'utils/object';
import { MissionSettingMaxLength } from 'constants/form';
import { Textarea } from 'components/atoms';
import { MissionMembersFieldItem } from 'components/molecules';

const StyledTextarea = styled(Textarea)`
  /* remove unnecessary height */
  line-height: 0;

  textarea {
    min-height: 114px;
    line-height: 1.5;
  }
`;

type CareerFieldProps = {
  index: number;
  value: MissionMemberFormValue['career'];
  onChange: (e: ChangeEvent<HTMLTextAreaElement>) => void;
  onBlur: (e: any) => void;
};

const CareerField: FC<CareerFieldProps> = props => {
  const { index, value, onChange, onBlur } = props;

  const { t } = useTranslation('missionSetting');

  return (
    <MissionMembersFieldItem>
      <StyledTextarea
        maxLength={MissionSettingMaxLength.SUPPORTER_MEMBER_CAREER}
        name={`supporterMembers[${index}].career`}
        value={value}
        placeholder={t('field.supporterMembers.subField.career.placeholder', {
          maxLength: MissionSettingMaxLength.SUPPORTER_MEMBER_CAREER,
        })}
        onChange={onChange}
        onBlur={onBlur}
      />
    </MissionMembersFieldItem>
  );
};

export default React.memo(CareerField, (prevProps, nextProps) =>
  isEqualProps(prevProps, nextProps, ['value']),
);
