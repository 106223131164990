import React, { FC, useCallback, useState, useRef } from 'react';
import styled, { css } from 'styled-components/macro';
import theme from 'styles/theme';
import typography from 'styles/typography';
import { ImageCircle, FlexContainer } from 'components/atoms';
import { DefaultUser } from 'assets/svg';
import {
  ChatRoom as ChatRoomType,
  ChatMessage as ChatMessageType,
  ChatUser,
} from 'proto/v1/apimodel/apimodel';
import { Link } from 'react-router-dom';
import * as PROTECTED_ROUTES from 'routes/constants/protected';
import { useTranslation } from 'react-i18next';
import { useLocalStorage } from '@rehooks/local-storage';
import { formatTimestamp } from 'utils/time';
import { isMissionChatRoom, isSupporterChatRoom } from 'utils/models/chat';
import { getFileDownloadedKey } from 'utils/storage';
import { getAccountId } from 'modules/account';
import { useSelector } from 'react-redux';
import MessageBalloon from './MessageBalloon';
import MessageMenu from './MessageMenu';

const ICON_DIAMETER = 56;
const MARGIN_BETWEEN_ICON_AND_MESSAGE = 6;

const sentTimeStyle = css`
  ${typography.textNote}
  display: flex;
  align-items: flex-end;
  color: ${theme.textSecondary};
`;

const Wrapper = styled.div`
  margin-bottom: 32px;
`;

const TalkToMessageContainer = styled.div`
  display: flex;
`;

const BalloonAndMenu = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const OwnMessageContainer = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const TalkToMessageNote = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 4px;
`;

const Downloaded = styled.div`
  ${sentTimeStyle}
  margin-left: 8px;
`;

const TalkToSentTime = styled.div`
  ${sentTimeStyle}
`;

const OwnSentTime = styled.div`
  ${sentTimeStyle}
  justify-content: flex-end;
  margin-top: 4px;
`;

const DeletedMessage = styled.div`
  width: fit-content;
  padding: 3px 16px;
  border-radius: 12px;
  background: ${theme.bgMessageDeleted};
  color: ${theme.white};
`;

const DeletedMessageContainer = styled(FlexContainer)<{ isTalkTo: boolean }>`
  justify-content: ${({ isTalkTo }) => (isTalkTo ? 'flex-start' : 'flex-end')};

  ${DeletedMessage} {
    margin-right: ${({ isTalkTo }) =>
      isTalkTo ? 0 : `${ICON_DIAMETER + MARGIN_BETWEEN_ICON_AND_MESSAGE}px`};
    margin-left: ${({ isTalkTo }) =>
      isTalkTo ? `${ICON_DIAMETER + MARGIN_BETWEEN_ICON_AND_MESSAGE}px` : 0};
  }
`;

const BalloonWrapper = styled.div<{ isTalkTo: boolean }>`
  margin-top: 24px;
  ${({ isTalkTo }) =>
    isTalkTo
      ? css`
          margin-left: 6px;
        `
      : css`
          margin-right: 6px;
        `}
`;

const isTalkToUser = (chatRoom: ChatRoomType, message: ChatMessageType) => {
  const talkToId = chatRoom.chatUser && chatRoom.chatUser.accountId;
  return message.senderAccountId === talkToId;
};

const getDetailUrl = (chatRoom: ChatRoomType) => {
  if (isMissionChatRoom(chatRoom)) {
    return `${PROTECTED_ROUTES.PRIVATE_MISSIONS}/${chatRoom?.chatUser?.mission?.missionId}`;
  }
  if (isSupporterChatRoom(chatRoom)) {
    return `${PROTECTED_ROUTES.PRIVATE_SUPPORTERS}/${chatRoom?.chatUser?.supporter?.supporterId}`;
  }
  return '';
};

const DownloadedText: FC<{ storageKey: string }> = ({ storageKey }) => {
  const [isDownloaded] = useLocalStorage(storageKey);
  const { t } = useTranslation('chat');
  return <>{isDownloaded && t('attachment.downloaded')}</>;
};

type MessageCardProps = {
  chatRoom: ChatRoomType;
  message: ChatMessageType;
  onDownloadFile: (message: ChatMessageType, writeFlag: boolean) => void;
  onDeleteMessage: (message: ChatMessageType) => void;
  ownIconImage: string;
  onClickInnovatorIcon: (innovator: ChatUser['innovator']) => void;
  unreadMessageRef: React.RefObject<HTMLDivElement> | null;
};

const MessageCard: FC<MessageCardProps> = props => {
  const {
    chatRoom,
    message,
    ownIconImage,
    onDownloadFile,
    onDeleteMessage,
    onClickInnovatorIcon,
    unreadMessageRef,
  } = props;
  const accountId = useSelector(getAccountId);
  const downloadedKey = getFileDownloadedKey(
    accountId,
    chatRoom.chatRoomId,
    message.messageId,
  );

  const isTalkTo = isTalkToUser(chatRoom, message);

  const handleDeleteMessage = useCallback(() => onDeleteMessage(message), [
    message,
    onDeleteMessage,
  ]);

  const handleDownloadFile = useCallback(
    () => onDownloadFile(message, isTalkTo),
    [isTalkTo, message, onDownloadFile],
  );

  const handleClickDefaultUserIcon = useCallback(() => {
    if (chatRoom.chatUser?.innovator) {
      onClickInnovatorIcon(chatRoom.chatUser.innovator);
    }
  }, [chatRoom.chatUser, onClickInnovatorIcon]);

  const [showMenu, setShowMenu] = useState(false);
  const ownMessageContainerRef = useRef<HTMLDivElement>(null);
  const handleCloseMenu = useCallback(
    (mousePos: { mouseX: number; mouseY: number } | null) => {
      if (!ownMessageContainerRef.current || !mousePos) return;
      const { mouseX, mouseY } = mousePos;
      const rect = ownMessageContainerRef.current.getBoundingClientRect();
      const { top, right, bottom, left } = rect;
      const isMouseOver =
        left < mouseX && mouseX < right && top < mouseY && mouseY < bottom;
      if (!isMouseOver) setShowMenu(false);
    },
    [],
  );

  return (
    <Wrapper ref={unreadMessageRef}>
      {message.deleted ? (
        <DeletedMessageContainer isTalkTo={isTalkTo}>
          <DeletedMessage data-testid={`message-deleted-${message.messageId}`}>
            {message.message}
          </DeletedMessage>
        </DeletedMessageContainer>
      ) : (
        <>
          {isTalkTo ? (
            <TalkToMessageContainer>
              {chatRoom?.chatUser?.iconImageUrl ? (
                <Link to={getDetailUrl(chatRoom)}>
                  <ImageCircle
                    imageSrc={chatRoom.chatUser.iconImageUrl}
                    diameter={`${ICON_DIAMETER}px`}
                    border
                    data-testid="talk-to-message-icon"
                  />
                </Link>
              ) : (
                <DefaultUser
                  style={{
                    height: `${ICON_DIAMETER}px`,
                    width: `${ICON_DIAMETER}px`,
                  }}
                  data-testid="talk-to-message-icon"
                  onClick={handleClickDefaultUserIcon}
                />
              )}
              <BalloonWrapper isTalkTo>
                <MessageBalloon
                  isTalkTo
                  message={message}
                  active
                  onClickDownloadLink={handleDownloadFile}
                />
                <TalkToMessageNote>
                  <TalkToSentTime>
                    {formatTimestamp(message.sentAt)}
                  </TalkToSentTime>
                  <Downloaded data-testid="talk-to-message-note">
                    <DownloadedText storageKey={downloadedKey} />
                  </Downloaded>
                </TalkToMessageNote>
              </BalloonWrapper>
            </TalkToMessageContainer>
          ) : (
            <OwnMessageContainer
              ref={ownMessageContainerRef}
              onMouseEnter={() => setShowMenu(true)}
              onMouseLeave={() => setShowMenu(false)}
              data-testid="own-message-container"
            >
              <BalloonWrapper isTalkTo={false}>
                <BalloonAndMenu>
                  <MessageMenu
                    show={showMenu}
                    onDeleteMessage={handleDeleteMessage}
                    onCloseMenu={handleCloseMenu}
                  />
                  <MessageBalloon
                    isTalkTo={false}
                    message={message}
                    active
                    onClickDownloadLink={handleDownloadFile}
                  />
                </BalloonAndMenu>
                <OwnSentTime>{formatTimestamp(message.sentAt)}</OwnSentTime>
              </BalloonWrapper>
              <ImageCircle
                imageSrc={ownIconImage}
                diameter={`${ICON_DIAMETER}px`}
                border
              />
            </OwnMessageContainer>
          )}
        </>
      )}
    </Wrapper>
  );
};

export default MessageCard;
