import React, { FC, ComponentProps } from 'react';
import styled from 'styled-components/macro';
import typography from 'styles/typography';
import BaseButton from './BaseButton';

const StyledButtonXL = styled(BaseButton)<ComponentProps<typeof BaseButton>>`
  ${typography.titleDefault}
  min-width: 240px;
  height: 48px;
  padding: 6px 16px;
  text-align: center;
`;

const ButtonXL: FC<ComponentProps<typeof BaseButton>> = React.forwardRef(
  (props, ref) => (
    <StyledButtonXL {...props} ref={ref}>
      {props.children}
    </StyledButtonXL>
  ),
);
export default ButtonXL;
