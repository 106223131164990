// eslint-disable-next-line import/prefer-default-export
export enum MissionSettingMaxLength {
  MISSION_NAME = 60, // count by byte point
  MISSION_DESCRIPTION = 1000,
  SUPPORT_DESCRIPTION = 1000,
  STRENGTH_POINT = 1000,
  COMPANY_NAME = 30,
  COMPANY_URL = 2048,
  OTHER_ADDRESS = 170,
  MISSION_DEMAND_COLLABORATION_ASSETS_GIVE = 50,
  MISSION_DEMAND_COLLABORATION_ASSETS_WANT = 50,
  MISSION_DEMAND_SERVICE_INFORMATION_ISSUE = 50,
  MISSION_DEMAND_ACCEPT_SECONDMENT_WORK = 50,
  MISSION_DEMAND_SEND_EMPLOYEE_WORK = 50,
  MISSION_MEMBER_NAME = 30,
  MISSION_MEMBER_CAREER = 200,
  SUPPORTER_MEMBER_NAME = 30,
  SUPPORTER_MEMBER_CAREER = 200,
  MISSION_PRODUCT_NAME = 24,
  MISSION_PRODUCT_OVERVIEW = 20,
  MISSION_PRODUCT_URL = 2048,
  MISSION_PRODUCT_PROBLEM = 600,
  MISSION_PRODUCT_SOLUTION = 600,
  MISSION_PRODUCT_MEMBER_BENEFIT = 150,
  MISSION_PRODUCT_TAG = 20,
  MISSION_JOB_OFFER_JOB_DESCRIPTION = 1500,
}

export const NO_SELECT_NUMBER_VALUE = 0;
