import React, { FC, ChangeEvent, CSSProperties } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components/macro';
import { isEqualProps } from 'utils/object';
import { MissionFormValues } from 'types/mission';
import { MissionSettingMaxLength } from 'constants/form';
import { Textarea } from 'components/atoms';
import { FormItemLabel, MissionSettingFormItem } from 'components/molecules';

const StyledTextarea = styled(Textarea)<{ height: number | undefined }>`
  position: relative;
  width: 445px;

  textarea {
    min-height: ${({ height }) => height ?? 358}px;
    line-height: 1.5;
  }

  small {
    display: block;
    position: absolute;
    bottom: -14px;
    left: 0;
    width: auto;
  }
`;

type StrengthPointFieldProps = {
  value: MissionFormValues['strengthPoint'];
  error: string | undefined;
  label?: string;
  placeholder?: string;
  note?: string;
  maxLength?: number;
  style?: CSSProperties;
  textAreaHeight?: number;
  onChange: (e: ChangeEvent<HTMLTextAreaElement>) => void;
  onBlur: (e: any) => void;
};

const StrengthPointField: FC<StrengthPointFieldProps> = props => {
  const { t } = useTranslation('missionSetting');

  const {
    value,
    error,
    label = t('field.strengthPoint.label'),
    placeholder = t('field.strengthPoint.placeholder'),
    note = t('field.strengthPoint.note', {
      maxLength: MissionSettingMaxLength.STRENGTH_POINT,
    }),
    maxLength = MissionSettingMaxLength.STRENGTH_POINT,
    textAreaHeight,
    style,
    onChange,
    onBlur,
  } = props;

  return (
    <MissionSettingFormItem required error={error} style={style}>
      <FormItemLabel label={label as string} note={note} required />
      <StyledTextarea
        maxLength={maxLength}
        name="strengthPoint"
        value={value}
        placeholder={placeholder}
        onChange={onChange}
        onBlur={onBlur}
        aria-label={label}
        height={textAreaHeight}
      />
    </MissionSettingFormItem>
  );
};

export default React.memo(StrengthPointField, (prevProps, nextProps) =>
  isEqualProps(prevProps, nextProps, ['value', 'error']),
);
