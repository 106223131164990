import { Mission, Country, JapanPrefecture, MemberRange, MissionCategory, Fund, FinancingSeries, Occupation, ImageToUpdate, MissionMemberToUpdate, MissionDemandToUpdate, MissionOfficeImageToUpdate, MissionProductToUpdate, CompanyEstablishedDate, MissionJobOfferToUpdate, Demand, SupportArea, SelectionStep, AcademicBackground, EmploymentType, MissionUsePlan } from '../../../proto/v1/apimodel/apimodel';
import { Reader, Writer } from 'protobufjs/minimal';
import * as Long from 'long';
import { Int32Value } from '../../../proto/google/protobuf/wrappers';


export interface GetMissionFormRequest {
  missionId: string;
}

export interface GetMissionFormResponse {
  mission: Mission | undefined;
  form: GetMissionFormResponse_Form | undefined;
  firstPublished: boolean;
  published: boolean;
}

export interface GetMissionFormResponse_Form {
  countries: Country[];
  japanPrefectures: JapanPrefecture[];
  memberRanges: MemberRange[];
  missionCategories: MissionCategory[];
  funds: Fund[];
  financingSeries: FinancingSeries[];
  occupations: Occupation[];
}

export interface CreateAnotherMissionRequest {
}

export interface CreateAnotherMissionResponse {
  accountId: string;
  missionId: string;
}

export interface DeleteMyMissionRequest {
  missionId: string;
}

export interface DeleteMyMissionResponse {
  token: string;
  activeAccountId: string;
  activeMissionId: string;
}

export interface UpdateMissionRequest {
  missionId: string;
  name: string;
  iconImage: ImageToUpdate | undefined;
  coverImage: ImageToUpdate | undefined;
  missionDescription: string;
  strengthPoint: string;
  companyName: string;
  countryId: string;
  japanPrefectureId: number | undefined;
  otherAddress: string;
  memberRangeId: number;
  fundId: number;
  missionCategories: number[];
  missionMembers: MissionMemberToUpdate[];
  missionDemand: MissionDemandToUpdate | undefined;
  companyUrl: string;
  missionOfficeImages: MissionOfficeImageToUpdate[];
  missionProducts: MissionProductToUpdate[];
  companyEstablishedDate: CompanyEstablishedDate | undefined;
  missionJobOffers: MissionJobOfferToUpdate[];
}

export interface UpdateMissionResponse {
  mission: Mission | undefined;
}

export interface UpdateFollowerFavoriteRequest {
  followerAccountId: string;
  favorite: boolean;
}

export interface UpdateFollowerFavoriteResponse {
}

export interface GetContactMissionFormRequest {
  missionId: string;
}

export interface GetContactMissionFormResponse {
  demands: Demand[];
}

export interface GetContactSupporterFormRequest {
  supporterId: string;
}

export interface GetContactSupporterFormResponse {
  supportAreas: SupportArea[];
}

export interface FollowMissionRequest {
  missionId: string;
  demandIds: number[];
}

export interface FollowMissionResponse {
}

export interface FollowSupporterRequest {
  supporterId: string;
  supportAreaIds: number[];
}

export interface FollowSupporterResponse {
}

export interface GetScoutingHumanResourcesRequest {
  limit: number;
  q: string;
}

export interface GetScoutingHumanResourcesResponse {
  scoutingHumanResources: GetScoutingHumanResourcesResponse_ScoutingHumanResource[];
  paging: Paging | undefined;
}

export interface GetScoutingHumanResourcesResponse_ScoutingHumanResource {
  scoutId: string;
  career: GetScoutingHumanResourcesResponse_ScoutingHumanResource_CareerHumanResource | undefined;
  newGraduate: GetScoutingHumanResourcesResponse_ScoutingHumanResource_NewGraduateHumanResource | undefined;
  scoutedAt: number;
  matterId: string;
  selectionStep: SelectionStep | undefined;
  joinCompanyScheduleAt: number;
  unofficialOfferConsentAt: number;
  communicatable: boolean;
  publishedAt: number;
  chatRoomId: string;
  deleted: boolean;
}

export interface GetScoutingHumanResourcesResponse_ScoutingHumanResource_CareerHumanResource {
  lastName: string;
  firstName: string;
  academicBackground: AcademicBackground | undefined;
  jobName: string;
  preferredEmploymentType: EmploymentType | undefined;
  japanPrefecture: JapanPrefecture | undefined;
}

export interface GetScoutingHumanResourcesResponse_ScoutingHumanResource_NewGraduateHumanResource {
  lastName: string;
  firstName: string;
  academicBackground: AcademicBackground | undefined;
  japanPrefecture: JapanPrefecture | undefined;
}

export interface GetScoutingHumanResourcesFormRequest {
}

export interface GetScoutingHumanResourcesFormResponse {
  form: GetScoutingHumanResourcesFormResponse_Form | undefined;
}

export interface GetScoutingHumanResourcesFormResponse_Form {
  selectionSteps: SelectionStep[];
}

export interface PutSelectionStepRequest {
  scoutId: string;
  selectionStepId: number;
}

export interface PutSelectionStepResponse {
}

export interface UpdateUnofficialOfferRequest {
  scoutId: string;
  unofficialOfferConsentAt: number;
}

export interface UpdateUnofficialOfferResponse {
}

export interface PutJoinCompanyScheduleRequest {
  scoutId: string;
  joinCompanyScheduleAt: number;
  unofficialOfferConsentAt: number;
}

export interface PutJoinCompanyScheduleResponse {
}

export interface PutScoutContractExplanationViewedCountRequest {
}

export interface PutScoutContractExplanationViewedCountResponse {
}

export interface SendScoutFeatureRequestRequest {
}

export interface SendScoutFeatureRequestResponse {
}

export interface GetScoutContractedStatusRequest {
}

export interface GetScoutContractedStatusResponse {
  scoutContractedStatus: number;
}

export interface GetMissionUsePlansRequest {
}

export interface GetMissionUsePlansResponse {
  missionUsePlans: MissionUsePlan[];
}

export interface Paging {
  nextQ: string;
}

const baseGetMissionFormRequest: object = {
  missionId: "",
};

const baseGetMissionFormResponse: object = {
  mission: undefined,
  form: undefined,
  firstPublished: false,
  published: false,
};

const baseGetMissionFormResponse_Form: object = {
  countries: undefined,
  japanPrefectures: undefined,
  memberRanges: undefined,
  missionCategories: undefined,
  funds: undefined,
  financingSeries: undefined,
  occupations: undefined,
};

const baseCreateAnotherMissionRequest: object = {
};

const baseCreateAnotherMissionResponse: object = {
  accountId: "",
  missionId: "",
};

const baseDeleteMyMissionRequest: object = {
  missionId: "",
};

const baseDeleteMyMissionResponse: object = {
  token: "",
  activeAccountId: "",
  activeMissionId: "",
};

const baseUpdateMissionRequest: object = {
  missionId: "",
  name: "",
  iconImage: undefined,
  coverImage: undefined,
  missionDescription: "",
  strengthPoint: "",
  companyName: "",
  countryId: "",
  japanPrefectureId: undefined,
  otherAddress: "",
  memberRangeId: 0,
  fundId: 0,
  missionCategories: 0,
  missionMembers: undefined,
  missionDemand: undefined,
  companyUrl: "",
  missionOfficeImages: undefined,
  missionProducts: undefined,
  companyEstablishedDate: undefined,
  missionJobOffers: undefined,
};

const baseUpdateMissionResponse: object = {
  mission: undefined,
};

const baseUpdateFollowerFavoriteRequest: object = {
  followerAccountId: "",
  favorite: false,
};

const baseUpdateFollowerFavoriteResponse: object = {
};

const baseGetContactMissionFormRequest: object = {
  missionId: "",
};

const baseGetContactMissionFormResponse: object = {
  demands: undefined,
};

const baseGetContactSupporterFormRequest: object = {
  supporterId: "",
};

const baseGetContactSupporterFormResponse: object = {
  supportAreas: undefined,
};

const baseFollowMissionRequest: object = {
  missionId: "",
  demandIds: 0,
};

const baseFollowMissionResponse: object = {
};

const baseFollowSupporterRequest: object = {
  supporterId: "",
  supportAreaIds: 0,
};

const baseFollowSupporterResponse: object = {
};

const baseGetScoutingHumanResourcesRequest: object = {
  limit: 0,
  q: "",
};

const baseGetScoutingHumanResourcesResponse: object = {
  scoutingHumanResources: undefined,
  paging: undefined,
};

const baseGetScoutingHumanResourcesResponse_ScoutingHumanResource: object = {
  scoutId: "",
  scoutedAt: 0,
  matterId: "",
  selectionStep: undefined,
  joinCompanyScheduleAt: 0,
  unofficialOfferConsentAt: 0,
  communicatable: false,
  publishedAt: 0,
  chatRoomId: "",
  deleted: false,
};

const baseGetScoutingHumanResourcesResponse_ScoutingHumanResource_CareerHumanResource: object = {
  lastName: "",
  firstName: "",
  academicBackground: undefined,
  jobName: "",
  preferredEmploymentType: undefined,
  japanPrefecture: undefined,
};

const baseGetScoutingHumanResourcesResponse_ScoutingHumanResource_NewGraduateHumanResource: object = {
  lastName: "",
  firstName: "",
  academicBackground: undefined,
  japanPrefecture: undefined,
};

const baseGetScoutingHumanResourcesFormRequest: object = {
};

const baseGetScoutingHumanResourcesFormResponse: object = {
  form: undefined,
};

const baseGetScoutingHumanResourcesFormResponse_Form: object = {
  selectionSteps: undefined,
};

const basePutSelectionStepRequest: object = {
  scoutId: "",
  selectionStepId: 0,
};

const basePutSelectionStepResponse: object = {
};

const baseUpdateUnofficialOfferRequest: object = {
  scoutId: "",
  unofficialOfferConsentAt: 0,
};

const baseUpdateUnofficialOfferResponse: object = {
};

const basePutJoinCompanyScheduleRequest: object = {
  scoutId: "",
  joinCompanyScheduleAt: 0,
  unofficialOfferConsentAt: 0,
};

const basePutJoinCompanyScheduleResponse: object = {
};

const basePutScoutContractExplanationViewedCountRequest: object = {
};

const basePutScoutContractExplanationViewedCountResponse: object = {
};

const baseSendScoutFeatureRequestRequest: object = {
};

const baseSendScoutFeatureRequestResponse: object = {
};

const baseGetScoutContractedStatusRequest: object = {
};

const baseGetScoutContractedStatusResponse: object = {
  scoutContractedStatus: 0,
};

const baseGetMissionUsePlansRequest: object = {
};

const baseGetMissionUsePlansResponse: object = {
  missionUsePlans: undefined,
};

const basePaging: object = {
  nextQ: "",
};

export interface MissionService {

  GetMissionForm(request: GetMissionFormRequest): Promise<GetMissionFormResponse>;

  CreateAnotherMission(request: CreateAnotherMissionRequest): Promise<CreateAnotherMissionResponse>;

  DeleteMyMission(request: DeleteMyMissionRequest): Promise<DeleteMyMissionResponse>;

  UpdateMission(request: UpdateMissionRequest): Promise<UpdateMissionResponse>;

  UpdateFollowerFavorite(request: UpdateFollowerFavoriteRequest): Promise<UpdateFollowerFavoriteResponse>;

  GetContactMissionForm(request: GetContactMissionFormRequest): Promise<GetContactMissionFormResponse>;

  GetContactSupporterForm(request: GetContactSupporterFormRequest): Promise<GetContactSupporterFormResponse>;

  FollowMission(request: FollowMissionRequest): Promise<FollowMissionResponse>;

  FollowSupporter(request: FollowSupporterRequest): Promise<FollowSupporterResponse>;

  GetScoutingHumanResources(request: GetScoutingHumanResourcesRequest): Promise<GetScoutingHumanResourcesResponse>;

  GetScoutingHumanResourcesForm(request: GetScoutingHumanResourcesFormRequest): Promise<GetScoutingHumanResourcesFormResponse>;

  PutSelectionStep(request: PutSelectionStepRequest): Promise<PutSelectionStepResponse>;

  UpdateUnofficialOffer(request: UpdateUnofficialOfferRequest): Promise<UpdateUnofficialOfferResponse>;

  PutJoinCompanySchedule(request: PutJoinCompanyScheduleRequest): Promise<PutJoinCompanyScheduleResponse>;

  PutScoutContractExplanationViewedCount(request: PutScoutContractExplanationViewedCountRequest): Promise<PutScoutContractExplanationViewedCountResponse>;

  SendScoutFeatureRequest(request: SendScoutFeatureRequestRequest): Promise<SendScoutFeatureRequestResponse>;

  GetScoutContractedStatus(request: GetScoutContractedStatusRequest): Promise<GetScoutContractedStatusResponse>;

  GetMissionUsePlans(request: GetMissionUsePlansRequest): Promise<GetMissionUsePlansResponse>;

}

export class MissionServiceClientImpl implements MissionService {

  private readonly rpc: Rpc;

  constructor(rpc: Rpc) {
    this.rpc = rpc;
  }

  GetMissionForm(request: GetMissionFormRequest): Promise<GetMissionFormResponse> {
    const data = GetMissionFormRequest.encode(request).finish();
    const promise = this.rpc.request("missionservice.MissionService", "GetMissionForm", data);
    return promise.then(data => GetMissionFormResponse.decode(new Reader(data)));
  }

  CreateAnotherMission(request: CreateAnotherMissionRequest): Promise<CreateAnotherMissionResponse> {
    const data = CreateAnotherMissionRequest.encode(request).finish();
    const promise = this.rpc.request("missionservice.MissionService", "CreateAnotherMission", data);
    return promise.then(data => CreateAnotherMissionResponse.decode(new Reader(data)));
  }

  DeleteMyMission(request: DeleteMyMissionRequest): Promise<DeleteMyMissionResponse> {
    const data = DeleteMyMissionRequest.encode(request).finish();
    const promise = this.rpc.request("missionservice.MissionService", "DeleteMyMission", data);
    return promise.then(data => DeleteMyMissionResponse.decode(new Reader(data)));
  }

  UpdateMission(request: UpdateMissionRequest): Promise<UpdateMissionResponse> {
    const data = UpdateMissionRequest.encode(request).finish();
    const promise = this.rpc.request("missionservice.MissionService", "UpdateMission", data);
    return promise.then(data => UpdateMissionResponse.decode(new Reader(data)));
  }

  UpdateFollowerFavorite(request: UpdateFollowerFavoriteRequest): Promise<UpdateFollowerFavoriteResponse> {
    const data = UpdateFollowerFavoriteRequest.encode(request).finish();
    const promise = this.rpc.request("missionservice.MissionService", "UpdateFollowerFavorite", data);
    return promise.then(data => UpdateFollowerFavoriteResponse.decode(new Reader(data)));
  }

  GetContactMissionForm(request: GetContactMissionFormRequest): Promise<GetContactMissionFormResponse> {
    const data = GetContactMissionFormRequest.encode(request).finish();
    const promise = this.rpc.request("missionservice.MissionService", "GetContactMissionForm", data);
    return promise.then(data => GetContactMissionFormResponse.decode(new Reader(data)));
  }

  GetContactSupporterForm(request: GetContactSupporterFormRequest): Promise<GetContactSupporterFormResponse> {
    const data = GetContactSupporterFormRequest.encode(request).finish();
    const promise = this.rpc.request("missionservice.MissionService", "GetContactSupporterForm", data);
    return promise.then(data => GetContactSupporterFormResponse.decode(new Reader(data)));
  }

  FollowMission(request: FollowMissionRequest): Promise<FollowMissionResponse> {
    const data = FollowMissionRequest.encode(request).finish();
    const promise = this.rpc.request("missionservice.MissionService", "FollowMission", data);
    return promise.then(data => FollowMissionResponse.decode(new Reader(data)));
  }

  FollowSupporter(request: FollowSupporterRequest): Promise<FollowSupporterResponse> {
    const data = FollowSupporterRequest.encode(request).finish();
    const promise = this.rpc.request("missionservice.MissionService", "FollowSupporter", data);
    return promise.then(data => FollowSupporterResponse.decode(new Reader(data)));
  }

  GetScoutingHumanResources(request: GetScoutingHumanResourcesRequest): Promise<GetScoutingHumanResourcesResponse> {
    const data = GetScoutingHumanResourcesRequest.encode(request).finish();
    const promise = this.rpc.request("missionservice.MissionService", "GetScoutingHumanResources", data);
    return promise.then(data => GetScoutingHumanResourcesResponse.decode(new Reader(data)));
  }

  GetScoutingHumanResourcesForm(request: GetScoutingHumanResourcesFormRequest): Promise<GetScoutingHumanResourcesFormResponse> {
    const data = GetScoutingHumanResourcesFormRequest.encode(request).finish();
    const promise = this.rpc.request("missionservice.MissionService", "GetScoutingHumanResourcesForm", data);
    return promise.then(data => GetScoutingHumanResourcesFormResponse.decode(new Reader(data)));
  }

  PutSelectionStep(request: PutSelectionStepRequest): Promise<PutSelectionStepResponse> {
    const data = PutSelectionStepRequest.encode(request).finish();
    const promise = this.rpc.request("missionservice.MissionService", "PutSelectionStep", data);
    return promise.then(data => PutSelectionStepResponse.decode(new Reader(data)));
  }

  UpdateUnofficialOffer(request: UpdateUnofficialOfferRequest): Promise<UpdateUnofficialOfferResponse> {
    const data = UpdateUnofficialOfferRequest.encode(request).finish();
    const promise = this.rpc.request("missionservice.MissionService", "UpdateUnofficialOffer", data);
    return promise.then(data => UpdateUnofficialOfferResponse.decode(new Reader(data)));
  }

  PutJoinCompanySchedule(request: PutJoinCompanyScheduleRequest): Promise<PutJoinCompanyScheduleResponse> {
    const data = PutJoinCompanyScheduleRequest.encode(request).finish();
    const promise = this.rpc.request("missionservice.MissionService", "PutJoinCompanySchedule", data);
    return promise.then(data => PutJoinCompanyScheduleResponse.decode(new Reader(data)));
  }

  PutScoutContractExplanationViewedCount(request: PutScoutContractExplanationViewedCountRequest): Promise<PutScoutContractExplanationViewedCountResponse> {
    const data = PutScoutContractExplanationViewedCountRequest.encode(request).finish();
    const promise = this.rpc.request("missionservice.MissionService", "PutScoutContractExplanationViewedCount", data);
    return promise.then(data => PutScoutContractExplanationViewedCountResponse.decode(new Reader(data)));
  }

  SendScoutFeatureRequest(request: SendScoutFeatureRequestRequest): Promise<SendScoutFeatureRequestResponse> {
    const data = SendScoutFeatureRequestRequest.encode(request).finish();
    const promise = this.rpc.request("missionservice.MissionService", "SendScoutFeatureRequest", data);
    return promise.then(data => SendScoutFeatureRequestResponse.decode(new Reader(data)));
  }

  GetScoutContractedStatus(request: GetScoutContractedStatusRequest): Promise<GetScoutContractedStatusResponse> {
    const data = GetScoutContractedStatusRequest.encode(request).finish();
    const promise = this.rpc.request("missionservice.MissionService", "GetScoutContractedStatus", data);
    return promise.then(data => GetScoutContractedStatusResponse.decode(new Reader(data)));
  }

  GetMissionUsePlans(request: GetMissionUsePlansRequest): Promise<GetMissionUsePlansResponse> {
    const data = GetMissionUsePlansRequest.encode(request).finish();
    const promise = this.rpc.request("missionservice.MissionService", "GetMissionUsePlans", data);
    return promise.then(data => GetMissionUsePlansResponse.decode(new Reader(data)));
  }

}

interface Rpc {

  request(service: string, method: string, data: Uint8Array): Promise<Uint8Array>;

}

function longToNumber(long: Long) {
  if (long.gt(Number.MAX_SAFE_INTEGER)) {
    throw new global.Error("Value is larger than Number.MAX_SAFE_INTEGER");
  }
  return long.toNumber();
}

export const GetMissionFormRequest = {
  encode(message: GetMissionFormRequest, writer: Writer = Writer.create()): Writer {
    writer.uint32(10).string(message.missionId);
    return writer;
  },
  decode(reader: Reader, length?: number): GetMissionFormRequest {
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = Object.create(baseGetMissionFormRequest) as GetMissionFormRequest;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.missionId = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },
  fromJSON(object: any): GetMissionFormRequest {
    const message = Object.create(baseGetMissionFormRequest) as GetMissionFormRequest;
    if (object.missionId) {
      message.missionId = String(object.missionId);
    }
    return message;
  },
  fromPartial(object: DeepPartial<GetMissionFormRequest>): GetMissionFormRequest {
    const message = Object.create(baseGetMissionFormRequest) as GetMissionFormRequest;
    if (object.missionId) {
      message.missionId = object.missionId;
    }
    return message;
  },
  toJSON(message: GetMissionFormRequest): unknown {
    const obj: any = {};
    obj.missionId = message.missionId || "";
    return obj;
  },
};

export const GetMissionFormResponse = {
  encode(message: GetMissionFormResponse, writer: Writer = Writer.create()): Writer {
    if (message.mission !== undefined && message.mission !== undefined) {
      Mission.encode(message.mission, writer.uint32(10).fork()).ldelim();
    }
    if (message.form !== undefined && message.form !== undefined) {
      GetMissionFormResponse_Form.encode(message.form, writer.uint32(26).fork()).ldelim();
    }
    writer.uint32(32).bool(message.firstPublished);
    writer.uint32(40).bool(message.published);
    return writer;
  },
  decode(reader: Reader, length?: number): GetMissionFormResponse {
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = Object.create(baseGetMissionFormResponse) as GetMissionFormResponse;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.mission = Mission.decode(reader, reader.uint32());
          break;
        case 3:
          message.form = GetMissionFormResponse_Form.decode(reader, reader.uint32());
          break;
        case 4:
          message.firstPublished = reader.bool();
          break;
        case 5:
          message.published = reader.bool();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },
  fromJSON(object: any): GetMissionFormResponse {
    const message = Object.create(baseGetMissionFormResponse) as GetMissionFormResponse;
    if (object.mission) {
      message.mission = Mission.fromJSON(object.mission);
    }
    if (object.form) {
      message.form = GetMissionFormResponse_Form.fromJSON(object.form);
    }
    if (object.firstPublished) {
      message.firstPublished = Boolean(object.firstPublished);
    }
    if (object.published) {
      message.published = Boolean(object.published);
    }
    return message;
  },
  fromPartial(object: DeepPartial<GetMissionFormResponse>): GetMissionFormResponse {
    const message = Object.create(baseGetMissionFormResponse) as GetMissionFormResponse;
    if (object.mission) {
      message.mission = Mission.fromPartial(object.mission);
    }
    if (object.form) {
      message.form = GetMissionFormResponse_Form.fromPartial(object.form);
    }
    if (object.firstPublished) {
      message.firstPublished = object.firstPublished;
    }
    if (object.published) {
      message.published = object.published;
    }
    return message;
  },
  toJSON(message: GetMissionFormResponse): unknown {
    const obj: any = {};
    obj.mission = message.mission ? Mission.toJSON(message.mission) : undefined;
    obj.form = message.form ? GetMissionFormResponse_Form.toJSON(message.form) : undefined;
    obj.firstPublished = message.firstPublished || false;
    obj.published = message.published || false;
    return obj;
  },
};

export const GetMissionFormResponse_Form = {
  encode(message: GetMissionFormResponse_Form, writer: Writer = Writer.create()): Writer {
    for (const v of message.countries) {
      Country.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    for (const v of message.japanPrefectures) {
      JapanPrefecture.encode(v!, writer.uint32(18).fork()).ldelim();
    }
    for (const v of message.memberRanges) {
      MemberRange.encode(v!, writer.uint32(26).fork()).ldelim();
    }
    for (const v of message.missionCategories) {
      MissionCategory.encode(v!, writer.uint32(34).fork()).ldelim();
    }
    for (const v of message.funds) {
      Fund.encode(v!, writer.uint32(42).fork()).ldelim();
    }
    for (const v of message.financingSeries) {
      FinancingSeries.encode(v!, writer.uint32(50).fork()).ldelim();
    }
    for (const v of message.occupations) {
      Occupation.encode(v!, writer.uint32(58).fork()).ldelim();
    }
    return writer;
  },
  decode(reader: Reader, length?: number): GetMissionFormResponse_Form {
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = Object.create(baseGetMissionFormResponse_Form) as GetMissionFormResponse_Form;
    message.countries = [];
    message.japanPrefectures = [];
    message.memberRanges = [];
    message.missionCategories = [];
    message.funds = [];
    message.financingSeries = [];
    message.occupations = [];
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.countries.push(Country.decode(reader, reader.uint32()));
          break;
        case 2:
          message.japanPrefectures.push(JapanPrefecture.decode(reader, reader.uint32()));
          break;
        case 3:
          message.memberRanges.push(MemberRange.decode(reader, reader.uint32()));
          break;
        case 4:
          message.missionCategories.push(MissionCategory.decode(reader, reader.uint32()));
          break;
        case 5:
          message.funds.push(Fund.decode(reader, reader.uint32()));
          break;
        case 6:
          message.financingSeries.push(FinancingSeries.decode(reader, reader.uint32()));
          break;
        case 7:
          message.occupations.push(Occupation.decode(reader, reader.uint32()));
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },
  fromJSON(object: any): GetMissionFormResponse_Form {
    const message = Object.create(baseGetMissionFormResponse_Form) as GetMissionFormResponse_Form;
    message.countries = [];
    message.japanPrefectures = [];
    message.memberRanges = [];
    message.missionCategories = [];
    message.funds = [];
    message.financingSeries = [];
    message.occupations = [];
    if (object.countries) {
      for (const e of object.countries) {
        message.countries.push(Country.fromJSON(e));
      }
    }
    if (object.japanPrefectures) {
      for (const e of object.japanPrefectures) {
        message.japanPrefectures.push(JapanPrefecture.fromJSON(e));
      }
    }
    if (object.memberRanges) {
      for (const e of object.memberRanges) {
        message.memberRanges.push(MemberRange.fromJSON(e));
      }
    }
    if (object.missionCategories) {
      for (const e of object.missionCategories) {
        message.missionCategories.push(MissionCategory.fromJSON(e));
      }
    }
    if (object.funds) {
      for (const e of object.funds) {
        message.funds.push(Fund.fromJSON(e));
      }
    }
    if (object.financingSeries) {
      for (const e of object.financingSeries) {
        message.financingSeries.push(FinancingSeries.fromJSON(e));
      }
    }
    if (object.occupations) {
      for (const e of object.occupations) {
        message.occupations.push(Occupation.fromJSON(e));
      }
    }
    return message;
  },
  fromPartial(object: DeepPartial<GetMissionFormResponse_Form>): GetMissionFormResponse_Form {
    const message = Object.create(baseGetMissionFormResponse_Form) as GetMissionFormResponse_Form;
    message.countries = [];
    message.japanPrefectures = [];
    message.memberRanges = [];
    message.missionCategories = [];
    message.funds = [];
    message.financingSeries = [];
    message.occupations = [];
    if (object.countries) {
      for (const e of object.countries) {
        message.countries.push(Country.fromPartial(e));
      }
    }
    if (object.japanPrefectures) {
      for (const e of object.japanPrefectures) {
        message.japanPrefectures.push(JapanPrefecture.fromPartial(e));
      }
    }
    if (object.memberRanges) {
      for (const e of object.memberRanges) {
        message.memberRanges.push(MemberRange.fromPartial(e));
      }
    }
    if (object.missionCategories) {
      for (const e of object.missionCategories) {
        message.missionCategories.push(MissionCategory.fromPartial(e));
      }
    }
    if (object.funds) {
      for (const e of object.funds) {
        message.funds.push(Fund.fromPartial(e));
      }
    }
    if (object.financingSeries) {
      for (const e of object.financingSeries) {
        message.financingSeries.push(FinancingSeries.fromPartial(e));
      }
    }
    if (object.occupations) {
      for (const e of object.occupations) {
        message.occupations.push(Occupation.fromPartial(e));
      }
    }
    return message;
  },
  toJSON(message: GetMissionFormResponse_Form): unknown {
    const obj: any = {};
    if (message.countries) {
      obj.countries = message.countries.map(e => e ? Country.toJSON(e) : undefined);
    } else {
      obj.countries = [];
    }
    if (message.japanPrefectures) {
      obj.japanPrefectures = message.japanPrefectures.map(e => e ? JapanPrefecture.toJSON(e) : undefined);
    } else {
      obj.japanPrefectures = [];
    }
    if (message.memberRanges) {
      obj.memberRanges = message.memberRanges.map(e => e ? MemberRange.toJSON(e) : undefined);
    } else {
      obj.memberRanges = [];
    }
    if (message.missionCategories) {
      obj.missionCategories = message.missionCategories.map(e => e ? MissionCategory.toJSON(e) : undefined);
    } else {
      obj.missionCategories = [];
    }
    if (message.funds) {
      obj.funds = message.funds.map(e => e ? Fund.toJSON(e) : undefined);
    } else {
      obj.funds = [];
    }
    if (message.financingSeries) {
      obj.financingSeries = message.financingSeries.map(e => e ? FinancingSeries.toJSON(e) : undefined);
    } else {
      obj.financingSeries = [];
    }
    if (message.occupations) {
      obj.occupations = message.occupations.map(e => e ? Occupation.toJSON(e) : undefined);
    } else {
      obj.occupations = [];
    }
    return obj;
  },
};

export const CreateAnotherMissionRequest = {
  encode(message: CreateAnotherMissionRequest, writer: Writer = Writer.create()): Writer {
    return writer;
  },
  decode(reader: Reader, length?: number): CreateAnotherMissionRequest {
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = Object.create(baseCreateAnotherMissionRequest) as CreateAnotherMissionRequest;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },
  fromJSON(object: any): CreateAnotherMissionRequest {
    const message = Object.create(baseCreateAnotherMissionRequest) as CreateAnotherMissionRequest;
    return message;
  },
  fromPartial(object: DeepPartial<CreateAnotherMissionRequest>): CreateAnotherMissionRequest {
    const message = Object.create(baseCreateAnotherMissionRequest) as CreateAnotherMissionRequest;
    return message;
  },
  toJSON(message: CreateAnotherMissionRequest): unknown {
    const obj: any = {};
    return obj;
  },
};

export const CreateAnotherMissionResponse = {
  encode(message: CreateAnotherMissionResponse, writer: Writer = Writer.create()): Writer {
    writer.uint32(10).string(message.accountId);
    writer.uint32(18).string(message.missionId);
    return writer;
  },
  decode(reader: Reader, length?: number): CreateAnotherMissionResponse {
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = Object.create(baseCreateAnotherMissionResponse) as CreateAnotherMissionResponse;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.accountId = reader.string();
          break;
        case 2:
          message.missionId = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },
  fromJSON(object: any): CreateAnotherMissionResponse {
    const message = Object.create(baseCreateAnotherMissionResponse) as CreateAnotherMissionResponse;
    if (object.accountId) {
      message.accountId = String(object.accountId);
    }
    if (object.missionId) {
      message.missionId = String(object.missionId);
    }
    return message;
  },
  fromPartial(object: DeepPartial<CreateAnotherMissionResponse>): CreateAnotherMissionResponse {
    const message = Object.create(baseCreateAnotherMissionResponse) as CreateAnotherMissionResponse;
    if (object.accountId) {
      message.accountId = object.accountId;
    }
    if (object.missionId) {
      message.missionId = object.missionId;
    }
    return message;
  },
  toJSON(message: CreateAnotherMissionResponse): unknown {
    const obj: any = {};
    obj.accountId = message.accountId || "";
    obj.missionId = message.missionId || "";
    return obj;
  },
};

export const DeleteMyMissionRequest = {
  encode(message: DeleteMyMissionRequest, writer: Writer = Writer.create()): Writer {
    writer.uint32(10).string(message.missionId);
    return writer;
  },
  decode(reader: Reader, length?: number): DeleteMyMissionRequest {
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = Object.create(baseDeleteMyMissionRequest) as DeleteMyMissionRequest;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.missionId = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },
  fromJSON(object: any): DeleteMyMissionRequest {
    const message = Object.create(baseDeleteMyMissionRequest) as DeleteMyMissionRequest;
    if (object.missionId) {
      message.missionId = String(object.missionId);
    }
    return message;
  },
  fromPartial(object: DeepPartial<DeleteMyMissionRequest>): DeleteMyMissionRequest {
    const message = Object.create(baseDeleteMyMissionRequest) as DeleteMyMissionRequest;
    if (object.missionId) {
      message.missionId = object.missionId;
    }
    return message;
  },
  toJSON(message: DeleteMyMissionRequest): unknown {
    const obj: any = {};
    obj.missionId = message.missionId || "";
    return obj;
  },
};

export const DeleteMyMissionResponse = {
  encode(message: DeleteMyMissionResponse, writer: Writer = Writer.create()): Writer {
    writer.uint32(10).string(message.token);
    writer.uint32(18).string(message.activeAccountId);
    writer.uint32(26).string(message.activeMissionId);
    return writer;
  },
  decode(reader: Reader, length?: number): DeleteMyMissionResponse {
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = Object.create(baseDeleteMyMissionResponse) as DeleteMyMissionResponse;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.token = reader.string();
          break;
        case 2:
          message.activeAccountId = reader.string();
          break;
        case 3:
          message.activeMissionId = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },
  fromJSON(object: any): DeleteMyMissionResponse {
    const message = Object.create(baseDeleteMyMissionResponse) as DeleteMyMissionResponse;
    if (object.token) {
      message.token = String(object.token);
    }
    if (object.activeAccountId) {
      message.activeAccountId = String(object.activeAccountId);
    }
    if (object.activeMissionId) {
      message.activeMissionId = String(object.activeMissionId);
    }
    return message;
  },
  fromPartial(object: DeepPartial<DeleteMyMissionResponse>): DeleteMyMissionResponse {
    const message = Object.create(baseDeleteMyMissionResponse) as DeleteMyMissionResponse;
    if (object.token) {
      message.token = object.token;
    }
    if (object.activeAccountId) {
      message.activeAccountId = object.activeAccountId;
    }
    if (object.activeMissionId) {
      message.activeMissionId = object.activeMissionId;
    }
    return message;
  },
  toJSON(message: DeleteMyMissionResponse): unknown {
    const obj: any = {};
    obj.token = message.token || "";
    obj.activeAccountId = message.activeAccountId || "";
    obj.activeMissionId = message.activeMissionId || "";
    return obj;
  },
};

export const UpdateMissionRequest = {
  encode(message: UpdateMissionRequest, writer: Writer = Writer.create()): Writer {
    writer.uint32(10).string(message.missionId);
    writer.uint32(18).string(message.name);
    if (message.iconImage !== undefined && message.iconImage !== undefined) {
      ImageToUpdate.encode(message.iconImage, writer.uint32(26).fork()).ldelim();
    }
    if (message.coverImage !== undefined && message.coverImage !== undefined) {
      ImageToUpdate.encode(message.coverImage, writer.uint32(34).fork()).ldelim();
    }
    writer.uint32(42).string(message.missionDescription);
    writer.uint32(50).string(message.strengthPoint);
    writer.uint32(58).string(message.companyName);
    writer.uint32(66).string(message.countryId);
    if (message.japanPrefectureId !== undefined && message.japanPrefectureId !== undefined) {
      Int32Value.encode({ value: message.japanPrefectureId! }, writer.uint32(74).fork()).ldelim();
    }
    writer.uint32(82).string(message.otherAddress);
    writer.uint32(88).uint32(message.memberRangeId);
    writer.uint32(96).uint32(message.fundId);
    writer.uint32(106).fork();
    for (const v of message.missionCategories) {
      writer.uint32(v);
    }
    writer.ldelim();
    for (const v of message.missionMembers) {
      MissionMemberToUpdate.encode(v!, writer.uint32(114).fork()).ldelim();
    }
    if (message.missionDemand !== undefined && message.missionDemand !== undefined) {
      MissionDemandToUpdate.encode(message.missionDemand, writer.uint32(122).fork()).ldelim();
    }
    writer.uint32(130).string(message.companyUrl);
    for (const v of message.missionOfficeImages) {
      MissionOfficeImageToUpdate.encode(v!, writer.uint32(138).fork()).ldelim();
    }
    for (const v of message.missionProducts) {
      MissionProductToUpdate.encode(v!, writer.uint32(146).fork()).ldelim();
    }
    if (message.companyEstablishedDate !== undefined && message.companyEstablishedDate !== undefined) {
      CompanyEstablishedDate.encode(message.companyEstablishedDate, writer.uint32(154).fork()).ldelim();
    }
    for (const v of message.missionJobOffers) {
      MissionJobOfferToUpdate.encode(v!, writer.uint32(162).fork()).ldelim();
    }
    return writer;
  },
  decode(reader: Reader, length?: number): UpdateMissionRequest {
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = Object.create(baseUpdateMissionRequest) as UpdateMissionRequest;
    message.missionCategories = [];
    message.missionMembers = [];
    message.missionOfficeImages = [];
    message.missionProducts = [];
    message.missionJobOffers = [];
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.missionId = reader.string();
          break;
        case 2:
          message.name = reader.string();
          break;
        case 3:
          message.iconImage = ImageToUpdate.decode(reader, reader.uint32());
          break;
        case 4:
          message.coverImage = ImageToUpdate.decode(reader, reader.uint32());
          break;
        case 5:
          message.missionDescription = reader.string();
          break;
        case 6:
          message.strengthPoint = reader.string();
          break;
        case 7:
          message.companyName = reader.string();
          break;
        case 8:
          message.countryId = reader.string();
          break;
        case 9:
          message.japanPrefectureId = Int32Value.decode(reader, reader.uint32()).value;
          break;
        case 10:
          message.otherAddress = reader.string();
          break;
        case 11:
          message.memberRangeId = reader.uint32();
          break;
        case 12:
          message.fundId = reader.uint32();
          break;
        case 13:
          if ((tag & 7) === 2) {
            const end2 = reader.uint32() + reader.pos;
            while (reader.pos < end2) {
              message.missionCategories.push(reader.uint32());
            }
          } else {
            message.missionCategories.push(reader.uint32());
          }
          break;
        case 14:
          message.missionMembers.push(MissionMemberToUpdate.decode(reader, reader.uint32()));
          break;
        case 15:
          message.missionDemand = MissionDemandToUpdate.decode(reader, reader.uint32());
          break;
        case 16:
          message.companyUrl = reader.string();
          break;
        case 17:
          message.missionOfficeImages.push(MissionOfficeImageToUpdate.decode(reader, reader.uint32()));
          break;
        case 18:
          message.missionProducts.push(MissionProductToUpdate.decode(reader, reader.uint32()));
          break;
        case 19:
          message.companyEstablishedDate = CompanyEstablishedDate.decode(reader, reader.uint32());
          break;
        case 20:
          message.missionJobOffers.push(MissionJobOfferToUpdate.decode(reader, reader.uint32()));
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },
  fromJSON(object: any): UpdateMissionRequest {
    const message = Object.create(baseUpdateMissionRequest) as UpdateMissionRequest;
    message.missionCategories = [];
    message.missionMembers = [];
    message.missionOfficeImages = [];
    message.missionProducts = [];
    message.missionJobOffers = [];
    if (object.missionId) {
      message.missionId = String(object.missionId);
    }
    if (object.name) {
      message.name = String(object.name);
    }
    if (object.iconImage) {
      message.iconImage = ImageToUpdate.fromJSON(object.iconImage);
    }
    if (object.coverImage) {
      message.coverImage = ImageToUpdate.fromJSON(object.coverImage);
    }
    if (object.missionDescription) {
      message.missionDescription = String(object.missionDescription);
    }
    if (object.strengthPoint) {
      message.strengthPoint = String(object.strengthPoint);
    }
    if (object.companyName) {
      message.companyName = String(object.companyName);
    }
    if (object.countryId) {
      message.countryId = String(object.countryId);
    }
    if (object.japanPrefectureId) {
      message.japanPrefectureId = Number(object.japanPrefectureId);
    }
    if (object.otherAddress) {
      message.otherAddress = String(object.otherAddress);
    }
    if (object.memberRangeId) {
      message.memberRangeId = Number(object.memberRangeId);
    }
    if (object.fundId) {
      message.fundId = Number(object.fundId);
    }
    if (object.missionCategories) {
      for (const e of object.missionCategories) {
        message.missionCategories.push(Number(e));
      }
    }
    if (object.missionMembers) {
      for (const e of object.missionMembers) {
        message.missionMembers.push(MissionMemberToUpdate.fromJSON(e));
      }
    }
    if (object.missionDemand) {
      message.missionDemand = MissionDemandToUpdate.fromJSON(object.missionDemand);
    }
    if (object.companyUrl) {
      message.companyUrl = String(object.companyUrl);
    }
    if (object.missionOfficeImages) {
      for (const e of object.missionOfficeImages) {
        message.missionOfficeImages.push(MissionOfficeImageToUpdate.fromJSON(e));
      }
    }
    if (object.missionProducts) {
      for (const e of object.missionProducts) {
        message.missionProducts.push(MissionProductToUpdate.fromJSON(e));
      }
    }
    if (object.companyEstablishedDate) {
      message.companyEstablishedDate = CompanyEstablishedDate.fromJSON(object.companyEstablishedDate);
    }
    if (object.missionJobOffers) {
      for (const e of object.missionJobOffers) {
        message.missionJobOffers.push(MissionJobOfferToUpdate.fromJSON(e));
      }
    }
    return message;
  },
  fromPartial(object: DeepPartial<UpdateMissionRequest>): UpdateMissionRequest {
    const message = Object.create(baseUpdateMissionRequest) as UpdateMissionRequest;
    message.missionCategories = [];
    message.missionMembers = [];
    message.missionOfficeImages = [];
    message.missionProducts = [];
    message.missionJobOffers = [];
    if (object.missionId) {
      message.missionId = object.missionId;
    }
    if (object.name) {
      message.name = object.name;
    }
    if (object.iconImage) {
      message.iconImage = ImageToUpdate.fromPartial(object.iconImage);
    }
    if (object.coverImage) {
      message.coverImage = ImageToUpdate.fromPartial(object.coverImage);
    }
    if (object.missionDescription) {
      message.missionDescription = object.missionDescription;
    }
    if (object.strengthPoint) {
      message.strengthPoint = object.strengthPoint;
    }
    if (object.companyName) {
      message.companyName = object.companyName;
    }
    if (object.countryId) {
      message.countryId = object.countryId;
    }
    if (object.japanPrefectureId) {
      message.japanPrefectureId = object.japanPrefectureId;
    }
    if (object.otherAddress) {
      message.otherAddress = object.otherAddress;
    }
    if (object.memberRangeId) {
      message.memberRangeId = object.memberRangeId;
    }
    if (object.fundId) {
      message.fundId = object.fundId;
    }
    if (object.missionCategories) {
      for (const e of object.missionCategories) {
        message.missionCategories.push(e);
      }
    }
    if (object.missionMembers) {
      for (const e of object.missionMembers) {
        message.missionMembers.push(MissionMemberToUpdate.fromPartial(e));
      }
    }
    if (object.missionDemand) {
      message.missionDemand = MissionDemandToUpdate.fromPartial(object.missionDemand);
    }
    if (object.companyUrl) {
      message.companyUrl = object.companyUrl;
    }
    if (object.missionOfficeImages) {
      for (const e of object.missionOfficeImages) {
        message.missionOfficeImages.push(MissionOfficeImageToUpdate.fromPartial(e));
      }
    }
    if (object.missionProducts) {
      for (const e of object.missionProducts) {
        message.missionProducts.push(MissionProductToUpdate.fromPartial(e));
      }
    }
    if (object.companyEstablishedDate) {
      message.companyEstablishedDate = CompanyEstablishedDate.fromPartial(object.companyEstablishedDate);
    }
    if (object.missionJobOffers) {
      for (const e of object.missionJobOffers) {
        message.missionJobOffers.push(MissionJobOfferToUpdate.fromPartial(e));
      }
    }
    return message;
  },
  toJSON(message: UpdateMissionRequest): unknown {
    const obj: any = {};
    obj.missionId = message.missionId || "";
    obj.name = message.name || "";
    obj.iconImage = message.iconImage ? ImageToUpdate.toJSON(message.iconImage) : undefined;
    obj.coverImage = message.coverImage ? ImageToUpdate.toJSON(message.coverImage) : undefined;
    obj.missionDescription = message.missionDescription || "";
    obj.strengthPoint = message.strengthPoint || "";
    obj.companyName = message.companyName || "";
    obj.countryId = message.countryId || "";
    obj.japanPrefectureId = message.japanPrefectureId || undefined;
    obj.otherAddress = message.otherAddress || "";
    obj.memberRangeId = message.memberRangeId || 0;
    obj.fundId = message.fundId || 0;
    if (message.missionCategories) {
      obj.missionCategories = message.missionCategories.map(e => e || 0);
    } else {
      obj.missionCategories = [];
    }
    if (message.missionMembers) {
      obj.missionMembers = message.missionMembers.map(e => e ? MissionMemberToUpdate.toJSON(e) : undefined);
    } else {
      obj.missionMembers = [];
    }
    obj.missionDemand = message.missionDemand ? MissionDemandToUpdate.toJSON(message.missionDemand) : undefined;
    obj.companyUrl = message.companyUrl || "";
    if (message.missionOfficeImages) {
      obj.missionOfficeImages = message.missionOfficeImages.map(e => e ? MissionOfficeImageToUpdate.toJSON(e) : undefined);
    } else {
      obj.missionOfficeImages = [];
    }
    if (message.missionProducts) {
      obj.missionProducts = message.missionProducts.map(e => e ? MissionProductToUpdate.toJSON(e) : undefined);
    } else {
      obj.missionProducts = [];
    }
    obj.companyEstablishedDate = message.companyEstablishedDate ? CompanyEstablishedDate.toJSON(message.companyEstablishedDate) : undefined;
    if (message.missionJobOffers) {
      obj.missionJobOffers = message.missionJobOffers.map(e => e ? MissionJobOfferToUpdate.toJSON(e) : undefined);
    } else {
      obj.missionJobOffers = [];
    }
    return obj;
  },
};

export const UpdateMissionResponse = {
  encode(message: UpdateMissionResponse, writer: Writer = Writer.create()): Writer {
    if (message.mission !== undefined && message.mission !== undefined) {
      Mission.encode(message.mission, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },
  decode(reader: Reader, length?: number): UpdateMissionResponse {
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = Object.create(baseUpdateMissionResponse) as UpdateMissionResponse;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.mission = Mission.decode(reader, reader.uint32());
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },
  fromJSON(object: any): UpdateMissionResponse {
    const message = Object.create(baseUpdateMissionResponse) as UpdateMissionResponse;
    if (object.mission) {
      message.mission = Mission.fromJSON(object.mission);
    }
    return message;
  },
  fromPartial(object: DeepPartial<UpdateMissionResponse>): UpdateMissionResponse {
    const message = Object.create(baseUpdateMissionResponse) as UpdateMissionResponse;
    if (object.mission) {
      message.mission = Mission.fromPartial(object.mission);
    }
    return message;
  },
  toJSON(message: UpdateMissionResponse): unknown {
    const obj: any = {};
    obj.mission = message.mission ? Mission.toJSON(message.mission) : undefined;
    return obj;
  },
};

export const UpdateFollowerFavoriteRequest = {
  encode(message: UpdateFollowerFavoriteRequest, writer: Writer = Writer.create()): Writer {
    writer.uint32(10).string(message.followerAccountId);
    writer.uint32(16).bool(message.favorite);
    return writer;
  },
  decode(reader: Reader, length?: number): UpdateFollowerFavoriteRequest {
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = Object.create(baseUpdateFollowerFavoriteRequest) as UpdateFollowerFavoriteRequest;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.followerAccountId = reader.string();
          break;
        case 2:
          message.favorite = reader.bool();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },
  fromJSON(object: any): UpdateFollowerFavoriteRequest {
    const message = Object.create(baseUpdateFollowerFavoriteRequest) as UpdateFollowerFavoriteRequest;
    if (object.followerAccountId) {
      message.followerAccountId = String(object.followerAccountId);
    }
    if (object.favorite) {
      message.favorite = Boolean(object.favorite);
    }
    return message;
  },
  fromPartial(object: DeepPartial<UpdateFollowerFavoriteRequest>): UpdateFollowerFavoriteRequest {
    const message = Object.create(baseUpdateFollowerFavoriteRequest) as UpdateFollowerFavoriteRequest;
    if (object.followerAccountId) {
      message.followerAccountId = object.followerAccountId;
    }
    if (object.favorite) {
      message.favorite = object.favorite;
    }
    return message;
  },
  toJSON(message: UpdateFollowerFavoriteRequest): unknown {
    const obj: any = {};
    obj.followerAccountId = message.followerAccountId || "";
    obj.favorite = message.favorite || false;
    return obj;
  },
};

export const UpdateFollowerFavoriteResponse = {
  encode(message: UpdateFollowerFavoriteResponse, writer: Writer = Writer.create()): Writer {
    return writer;
  },
  decode(reader: Reader, length?: number): UpdateFollowerFavoriteResponse {
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = Object.create(baseUpdateFollowerFavoriteResponse) as UpdateFollowerFavoriteResponse;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },
  fromJSON(object: any): UpdateFollowerFavoriteResponse {
    const message = Object.create(baseUpdateFollowerFavoriteResponse) as UpdateFollowerFavoriteResponse;
    return message;
  },
  fromPartial(object: DeepPartial<UpdateFollowerFavoriteResponse>): UpdateFollowerFavoriteResponse {
    const message = Object.create(baseUpdateFollowerFavoriteResponse) as UpdateFollowerFavoriteResponse;
    return message;
  },
  toJSON(message: UpdateFollowerFavoriteResponse): unknown {
    const obj: any = {};
    return obj;
  },
};

export const GetContactMissionFormRequest = {
  encode(message: GetContactMissionFormRequest, writer: Writer = Writer.create()): Writer {
    writer.uint32(10).string(message.missionId);
    return writer;
  },
  decode(reader: Reader, length?: number): GetContactMissionFormRequest {
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = Object.create(baseGetContactMissionFormRequest) as GetContactMissionFormRequest;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.missionId = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },
  fromJSON(object: any): GetContactMissionFormRequest {
    const message = Object.create(baseGetContactMissionFormRequest) as GetContactMissionFormRequest;
    if (object.missionId) {
      message.missionId = String(object.missionId);
    }
    return message;
  },
  fromPartial(object: DeepPartial<GetContactMissionFormRequest>): GetContactMissionFormRequest {
    const message = Object.create(baseGetContactMissionFormRequest) as GetContactMissionFormRequest;
    if (object.missionId) {
      message.missionId = object.missionId;
    }
    return message;
  },
  toJSON(message: GetContactMissionFormRequest): unknown {
    const obj: any = {};
    obj.missionId = message.missionId || "";
    return obj;
  },
};

export const GetContactMissionFormResponse = {
  encode(message: GetContactMissionFormResponse, writer: Writer = Writer.create()): Writer {
    for (const v of message.demands) {
      Demand.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },
  decode(reader: Reader, length?: number): GetContactMissionFormResponse {
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = Object.create(baseGetContactMissionFormResponse) as GetContactMissionFormResponse;
    message.demands = [];
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.demands.push(Demand.decode(reader, reader.uint32()));
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },
  fromJSON(object: any): GetContactMissionFormResponse {
    const message = Object.create(baseGetContactMissionFormResponse) as GetContactMissionFormResponse;
    message.demands = [];
    if (object.demands) {
      for (const e of object.demands) {
        message.demands.push(Demand.fromJSON(e));
      }
    }
    return message;
  },
  fromPartial(object: DeepPartial<GetContactMissionFormResponse>): GetContactMissionFormResponse {
    const message = Object.create(baseGetContactMissionFormResponse) as GetContactMissionFormResponse;
    message.demands = [];
    if (object.demands) {
      for (const e of object.demands) {
        message.demands.push(Demand.fromPartial(e));
      }
    }
    return message;
  },
  toJSON(message: GetContactMissionFormResponse): unknown {
    const obj: any = {};
    if (message.demands) {
      obj.demands = message.demands.map(e => e ? Demand.toJSON(e) : undefined);
    } else {
      obj.demands = [];
    }
    return obj;
  },
};

export const GetContactSupporterFormRequest = {
  encode(message: GetContactSupporterFormRequest, writer: Writer = Writer.create()): Writer {
    writer.uint32(10).string(message.supporterId);
    return writer;
  },
  decode(reader: Reader, length?: number): GetContactSupporterFormRequest {
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = Object.create(baseGetContactSupporterFormRequest) as GetContactSupporterFormRequest;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.supporterId = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },
  fromJSON(object: any): GetContactSupporterFormRequest {
    const message = Object.create(baseGetContactSupporterFormRequest) as GetContactSupporterFormRequest;
    if (object.supporterId) {
      message.supporterId = String(object.supporterId);
    }
    return message;
  },
  fromPartial(object: DeepPartial<GetContactSupporterFormRequest>): GetContactSupporterFormRequest {
    const message = Object.create(baseGetContactSupporterFormRequest) as GetContactSupporterFormRequest;
    if (object.supporterId) {
      message.supporterId = object.supporterId;
    }
    return message;
  },
  toJSON(message: GetContactSupporterFormRequest): unknown {
    const obj: any = {};
    obj.supporterId = message.supporterId || "";
    return obj;
  },
};

export const GetContactSupporterFormResponse = {
  encode(message: GetContactSupporterFormResponse, writer: Writer = Writer.create()): Writer {
    for (const v of message.supportAreas) {
      SupportArea.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },
  decode(reader: Reader, length?: number): GetContactSupporterFormResponse {
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = Object.create(baseGetContactSupporterFormResponse) as GetContactSupporterFormResponse;
    message.supportAreas = [];
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.supportAreas.push(SupportArea.decode(reader, reader.uint32()));
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },
  fromJSON(object: any): GetContactSupporterFormResponse {
    const message = Object.create(baseGetContactSupporterFormResponse) as GetContactSupporterFormResponse;
    message.supportAreas = [];
    if (object.supportAreas) {
      for (const e of object.supportAreas) {
        message.supportAreas.push(SupportArea.fromJSON(e));
      }
    }
    return message;
  },
  fromPartial(object: DeepPartial<GetContactSupporterFormResponse>): GetContactSupporterFormResponse {
    const message = Object.create(baseGetContactSupporterFormResponse) as GetContactSupporterFormResponse;
    message.supportAreas = [];
    if (object.supportAreas) {
      for (const e of object.supportAreas) {
        message.supportAreas.push(SupportArea.fromPartial(e));
      }
    }
    return message;
  },
  toJSON(message: GetContactSupporterFormResponse): unknown {
    const obj: any = {};
    if (message.supportAreas) {
      obj.supportAreas = message.supportAreas.map(e => e ? SupportArea.toJSON(e) : undefined);
    } else {
      obj.supportAreas = [];
    }
    return obj;
  },
};

export const FollowMissionRequest = {
  encode(message: FollowMissionRequest, writer: Writer = Writer.create()): Writer {
    writer.uint32(10).string(message.missionId);
    writer.uint32(18).fork();
    for (const v of message.demandIds) {
      writer.uint32(v);
    }
    writer.ldelim();
    return writer;
  },
  decode(reader: Reader, length?: number): FollowMissionRequest {
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = Object.create(baseFollowMissionRequest) as FollowMissionRequest;
    message.demandIds = [];
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.missionId = reader.string();
          break;
        case 2:
          if ((tag & 7) === 2) {
            const end2 = reader.uint32() + reader.pos;
            while (reader.pos < end2) {
              message.demandIds.push(reader.uint32());
            }
          } else {
            message.demandIds.push(reader.uint32());
          }
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },
  fromJSON(object: any): FollowMissionRequest {
    const message = Object.create(baseFollowMissionRequest) as FollowMissionRequest;
    message.demandIds = [];
    if (object.missionId) {
      message.missionId = String(object.missionId);
    }
    if (object.demandIds) {
      for (const e of object.demandIds) {
        message.demandIds.push(Number(e));
      }
    }
    return message;
  },
  fromPartial(object: DeepPartial<FollowMissionRequest>): FollowMissionRequest {
    const message = Object.create(baseFollowMissionRequest) as FollowMissionRequest;
    message.demandIds = [];
    if (object.missionId) {
      message.missionId = object.missionId;
    }
    if (object.demandIds) {
      for (const e of object.demandIds) {
        message.demandIds.push(e);
      }
    }
    return message;
  },
  toJSON(message: FollowMissionRequest): unknown {
    const obj: any = {};
    obj.missionId = message.missionId || "";
    if (message.demandIds) {
      obj.demandIds = message.demandIds.map(e => e || 0);
    } else {
      obj.demandIds = [];
    }
    return obj;
  },
};

export const FollowMissionResponse = {
  encode(message: FollowMissionResponse, writer: Writer = Writer.create()): Writer {
    return writer;
  },
  decode(reader: Reader, length?: number): FollowMissionResponse {
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = Object.create(baseFollowMissionResponse) as FollowMissionResponse;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },
  fromJSON(object: any): FollowMissionResponse {
    const message = Object.create(baseFollowMissionResponse) as FollowMissionResponse;
    return message;
  },
  fromPartial(object: DeepPartial<FollowMissionResponse>): FollowMissionResponse {
    const message = Object.create(baseFollowMissionResponse) as FollowMissionResponse;
    return message;
  },
  toJSON(message: FollowMissionResponse): unknown {
    const obj: any = {};
    return obj;
  },
};

export const FollowSupporterRequest = {
  encode(message: FollowSupporterRequest, writer: Writer = Writer.create()): Writer {
    writer.uint32(10).string(message.supporterId);
    writer.uint32(18).fork();
    for (const v of message.supportAreaIds) {
      writer.uint32(v);
    }
    writer.ldelim();
    return writer;
  },
  decode(reader: Reader, length?: number): FollowSupporterRequest {
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = Object.create(baseFollowSupporterRequest) as FollowSupporterRequest;
    message.supportAreaIds = [];
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.supporterId = reader.string();
          break;
        case 2:
          if ((tag & 7) === 2) {
            const end2 = reader.uint32() + reader.pos;
            while (reader.pos < end2) {
              message.supportAreaIds.push(reader.uint32());
            }
          } else {
            message.supportAreaIds.push(reader.uint32());
          }
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },
  fromJSON(object: any): FollowSupporterRequest {
    const message = Object.create(baseFollowSupporterRequest) as FollowSupporterRequest;
    message.supportAreaIds = [];
    if (object.supporterId) {
      message.supporterId = String(object.supporterId);
    }
    if (object.supportAreaIds) {
      for (const e of object.supportAreaIds) {
        message.supportAreaIds.push(Number(e));
      }
    }
    return message;
  },
  fromPartial(object: DeepPartial<FollowSupporterRequest>): FollowSupporterRequest {
    const message = Object.create(baseFollowSupporterRequest) as FollowSupporterRequest;
    message.supportAreaIds = [];
    if (object.supporterId) {
      message.supporterId = object.supporterId;
    }
    if (object.supportAreaIds) {
      for (const e of object.supportAreaIds) {
        message.supportAreaIds.push(e);
      }
    }
    return message;
  },
  toJSON(message: FollowSupporterRequest): unknown {
    const obj: any = {};
    obj.supporterId = message.supporterId || "";
    if (message.supportAreaIds) {
      obj.supportAreaIds = message.supportAreaIds.map(e => e || 0);
    } else {
      obj.supportAreaIds = [];
    }
    return obj;
  },
};

export const FollowSupporterResponse = {
  encode(message: FollowSupporterResponse, writer: Writer = Writer.create()): Writer {
    return writer;
  },
  decode(reader: Reader, length?: number): FollowSupporterResponse {
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = Object.create(baseFollowSupporterResponse) as FollowSupporterResponse;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },
  fromJSON(object: any): FollowSupporterResponse {
    const message = Object.create(baseFollowSupporterResponse) as FollowSupporterResponse;
    return message;
  },
  fromPartial(object: DeepPartial<FollowSupporterResponse>): FollowSupporterResponse {
    const message = Object.create(baseFollowSupporterResponse) as FollowSupporterResponse;
    return message;
  },
  toJSON(message: FollowSupporterResponse): unknown {
    const obj: any = {};
    return obj;
  },
};

export const GetScoutingHumanResourcesRequest = {
  encode(message: GetScoutingHumanResourcesRequest, writer: Writer = Writer.create()): Writer {
    writer.uint32(8).uint32(message.limit);
    writer.uint32(18).string(message.q);
    return writer;
  },
  decode(reader: Reader, length?: number): GetScoutingHumanResourcesRequest {
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = Object.create(baseGetScoutingHumanResourcesRequest) as GetScoutingHumanResourcesRequest;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.limit = reader.uint32();
          break;
        case 2:
          message.q = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },
  fromJSON(object: any): GetScoutingHumanResourcesRequest {
    const message = Object.create(baseGetScoutingHumanResourcesRequest) as GetScoutingHumanResourcesRequest;
    if (object.limit) {
      message.limit = Number(object.limit);
    }
    if (object.q) {
      message.q = String(object.q);
    }
    return message;
  },
  fromPartial(object: DeepPartial<GetScoutingHumanResourcesRequest>): GetScoutingHumanResourcesRequest {
    const message = Object.create(baseGetScoutingHumanResourcesRequest) as GetScoutingHumanResourcesRequest;
    if (object.limit) {
      message.limit = object.limit;
    }
    if (object.q) {
      message.q = object.q;
    }
    return message;
  },
  toJSON(message: GetScoutingHumanResourcesRequest): unknown {
    const obj: any = {};
    obj.limit = message.limit || 0;
    obj.q = message.q || "";
    return obj;
  },
};

export const GetScoutingHumanResourcesResponse = {
  encode(message: GetScoutingHumanResourcesResponse, writer: Writer = Writer.create()): Writer {
    for (const v of message.scoutingHumanResources) {
      GetScoutingHumanResourcesResponse_ScoutingHumanResource.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    if (message.paging !== undefined && message.paging !== undefined) {
      Paging.encode(message.paging, writer.uint32(18).fork()).ldelim();
    }
    return writer;
  },
  decode(reader: Reader, length?: number): GetScoutingHumanResourcesResponse {
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = Object.create(baseGetScoutingHumanResourcesResponse) as GetScoutingHumanResourcesResponse;
    message.scoutingHumanResources = [];
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.scoutingHumanResources.push(GetScoutingHumanResourcesResponse_ScoutingHumanResource.decode(reader, reader.uint32()));
          break;
        case 2:
          message.paging = Paging.decode(reader, reader.uint32());
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },
  fromJSON(object: any): GetScoutingHumanResourcesResponse {
    const message = Object.create(baseGetScoutingHumanResourcesResponse) as GetScoutingHumanResourcesResponse;
    message.scoutingHumanResources = [];
    if (object.scoutingHumanResources) {
      for (const e of object.scoutingHumanResources) {
        message.scoutingHumanResources.push(GetScoutingHumanResourcesResponse_ScoutingHumanResource.fromJSON(e));
      }
    }
    if (object.paging) {
      message.paging = Paging.fromJSON(object.paging);
    }
    return message;
  },
  fromPartial(object: DeepPartial<GetScoutingHumanResourcesResponse>): GetScoutingHumanResourcesResponse {
    const message = Object.create(baseGetScoutingHumanResourcesResponse) as GetScoutingHumanResourcesResponse;
    message.scoutingHumanResources = [];
    if (object.scoutingHumanResources) {
      for (const e of object.scoutingHumanResources) {
        message.scoutingHumanResources.push(GetScoutingHumanResourcesResponse_ScoutingHumanResource.fromPartial(e));
      }
    }
    if (object.paging) {
      message.paging = Paging.fromPartial(object.paging);
    }
    return message;
  },
  toJSON(message: GetScoutingHumanResourcesResponse): unknown {
    const obj: any = {};
    if (message.scoutingHumanResources) {
      obj.scoutingHumanResources = message.scoutingHumanResources.map(e => e ? GetScoutingHumanResourcesResponse_ScoutingHumanResource.toJSON(e) : undefined);
    } else {
      obj.scoutingHumanResources = [];
    }
    obj.paging = message.paging ? Paging.toJSON(message.paging) : undefined;
    return obj;
  },
};

export const GetScoutingHumanResourcesResponse_ScoutingHumanResource = {
  encode(message: GetScoutingHumanResourcesResponse_ScoutingHumanResource, writer: Writer = Writer.create()): Writer {
    writer.uint32(10).string(message.scoutId);
    if (message.career !== undefined && message.career !== undefined) {
      GetScoutingHumanResourcesResponse_ScoutingHumanResource_CareerHumanResource.encode(message.career, writer.uint32(18).fork()).ldelim();
    }
    if (message.newGraduate !== undefined && message.newGraduate !== undefined) {
      GetScoutingHumanResourcesResponse_ScoutingHumanResource_NewGraduateHumanResource.encode(message.newGraduate, writer.uint32(26).fork()).ldelim();
    }
    writer.uint32(32).int64(message.scoutedAt);
    writer.uint32(42).string(message.matterId);
    if (message.selectionStep !== undefined && message.selectionStep !== undefined) {
      SelectionStep.encode(message.selectionStep, writer.uint32(50).fork()).ldelim();
    }
    writer.uint32(56).int64(message.joinCompanyScheduleAt);
    writer.uint32(64).int64(message.unofficialOfferConsentAt);
    writer.uint32(72).bool(message.communicatable);
    writer.uint32(80).int64(message.publishedAt);
    writer.uint32(90).string(message.chatRoomId);
    writer.uint32(96).bool(message.deleted);
    return writer;
  },
  decode(reader: Reader, length?: number): GetScoutingHumanResourcesResponse_ScoutingHumanResource {
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = Object.create(baseGetScoutingHumanResourcesResponse_ScoutingHumanResource) as GetScoutingHumanResourcesResponse_ScoutingHumanResource;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.scoutId = reader.string();
          break;
        case 2:
          message.career = GetScoutingHumanResourcesResponse_ScoutingHumanResource_CareerHumanResource.decode(reader, reader.uint32());
          break;
        case 3:
          message.newGraduate = GetScoutingHumanResourcesResponse_ScoutingHumanResource_NewGraduateHumanResource.decode(reader, reader.uint32());
          break;
        case 4:
          message.scoutedAt = longToNumber(reader.int64() as Long);
          break;
        case 5:
          message.matterId = reader.string();
          break;
        case 6:
          message.selectionStep = SelectionStep.decode(reader, reader.uint32());
          break;
        case 7:
          message.joinCompanyScheduleAt = longToNumber(reader.int64() as Long);
          break;
        case 8:
          message.unofficialOfferConsentAt = longToNumber(reader.int64() as Long);
          break;
        case 9:
          message.communicatable = reader.bool();
          break;
        case 10:
          message.publishedAt = longToNumber(reader.int64() as Long);
          break;
        case 11:
          message.chatRoomId = reader.string();
          break;
        case 12:
          message.deleted = reader.bool();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },
  fromJSON(object: any): GetScoutingHumanResourcesResponse_ScoutingHumanResource {
    const message = Object.create(baseGetScoutingHumanResourcesResponse_ScoutingHumanResource) as GetScoutingHumanResourcesResponse_ScoutingHumanResource;
    if (object.scoutId) {
      message.scoutId = String(object.scoutId);
    }
    if (object.career) {
      message.career = GetScoutingHumanResourcesResponse_ScoutingHumanResource_CareerHumanResource.fromJSON(object.career);
    }
    if (object.newGraduate) {
      message.newGraduate = GetScoutingHumanResourcesResponse_ScoutingHumanResource_NewGraduateHumanResource.fromJSON(object.newGraduate);
    }
    if (object.scoutedAt) {
      message.scoutedAt = Number(object.scoutedAt);
    }
    if (object.matterId) {
      message.matterId = String(object.matterId);
    }
    if (object.selectionStep) {
      message.selectionStep = SelectionStep.fromJSON(object.selectionStep);
    }
    if (object.joinCompanyScheduleAt) {
      message.joinCompanyScheduleAt = Number(object.joinCompanyScheduleAt);
    }
    if (object.unofficialOfferConsentAt) {
      message.unofficialOfferConsentAt = Number(object.unofficialOfferConsentAt);
    }
    if (object.communicatable) {
      message.communicatable = Boolean(object.communicatable);
    }
    if (object.publishedAt) {
      message.publishedAt = Number(object.publishedAt);
    }
    if (object.chatRoomId) {
      message.chatRoomId = String(object.chatRoomId);
    }
    if (object.deleted) {
      message.deleted = Boolean(object.deleted);
    }
    return message;
  },
  fromPartial(object: DeepPartial<GetScoutingHumanResourcesResponse_ScoutingHumanResource>): GetScoutingHumanResourcesResponse_ScoutingHumanResource {
    const message = Object.create(baseGetScoutingHumanResourcesResponse_ScoutingHumanResource) as GetScoutingHumanResourcesResponse_ScoutingHumanResource;
    if (object.scoutId) {
      message.scoutId = object.scoutId;
    }
    if (object.career) {
      message.career = GetScoutingHumanResourcesResponse_ScoutingHumanResource_CareerHumanResource.fromPartial(object.career);
    }
    if (object.newGraduate) {
      message.newGraduate = GetScoutingHumanResourcesResponse_ScoutingHumanResource_NewGraduateHumanResource.fromPartial(object.newGraduate);
    }
    if (object.scoutedAt) {
      message.scoutedAt = object.scoutedAt;
    }
    if (object.matterId) {
      message.matterId = object.matterId;
    }
    if (object.selectionStep) {
      message.selectionStep = SelectionStep.fromPartial(object.selectionStep);
    }
    if (object.joinCompanyScheduleAt) {
      message.joinCompanyScheduleAt = object.joinCompanyScheduleAt;
    }
    if (object.unofficialOfferConsentAt) {
      message.unofficialOfferConsentAt = object.unofficialOfferConsentAt;
    }
    if (object.communicatable) {
      message.communicatable = object.communicatable;
    }
    if (object.publishedAt) {
      message.publishedAt = object.publishedAt;
    }
    if (object.chatRoomId) {
      message.chatRoomId = object.chatRoomId;
    }
    if (object.deleted) {
      message.deleted = object.deleted;
    }
    return message;
  },
  toJSON(message: GetScoutingHumanResourcesResponse_ScoutingHumanResource): unknown {
    const obj: any = {};
    obj.scoutId = message.scoutId || "";
    obj.career = message.career ? GetScoutingHumanResourcesResponse_ScoutingHumanResource_CareerHumanResource.toJSON(message.career) : undefined;
    obj.newGraduate = message.newGraduate ? GetScoutingHumanResourcesResponse_ScoutingHumanResource_NewGraduateHumanResource.toJSON(message.newGraduate) : undefined;
    obj.scoutedAt = message.scoutedAt || 0;
    obj.matterId = message.matterId || "";
    obj.selectionStep = message.selectionStep ? SelectionStep.toJSON(message.selectionStep) : undefined;
    obj.joinCompanyScheduleAt = message.joinCompanyScheduleAt || 0;
    obj.unofficialOfferConsentAt = message.unofficialOfferConsentAt || 0;
    obj.communicatable = message.communicatable || false;
    obj.publishedAt = message.publishedAt || 0;
    obj.chatRoomId = message.chatRoomId || "";
    obj.deleted = message.deleted || false;
    return obj;
  },
};

export const GetScoutingHumanResourcesResponse_ScoutingHumanResource_CareerHumanResource = {
  encode(message: GetScoutingHumanResourcesResponse_ScoutingHumanResource_CareerHumanResource, writer: Writer = Writer.create()): Writer {
    writer.uint32(10).string(message.lastName);
    writer.uint32(18).string(message.firstName);
    if (message.academicBackground !== undefined && message.academicBackground !== undefined) {
      AcademicBackground.encode(message.academicBackground, writer.uint32(26).fork()).ldelim();
    }
    writer.uint32(34).string(message.jobName);
    if (message.preferredEmploymentType !== undefined && message.preferredEmploymentType !== undefined) {
      EmploymentType.encode(message.preferredEmploymentType, writer.uint32(42).fork()).ldelim();
    }
    if (message.japanPrefecture !== undefined && message.japanPrefecture !== undefined) {
      JapanPrefecture.encode(message.japanPrefecture, writer.uint32(50).fork()).ldelim();
    }
    return writer;
  },
  decode(reader: Reader, length?: number): GetScoutingHumanResourcesResponse_ScoutingHumanResource_CareerHumanResource {
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = Object.create(baseGetScoutingHumanResourcesResponse_ScoutingHumanResource_CareerHumanResource) as GetScoutingHumanResourcesResponse_ScoutingHumanResource_CareerHumanResource;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.lastName = reader.string();
          break;
        case 2:
          message.firstName = reader.string();
          break;
        case 3:
          message.academicBackground = AcademicBackground.decode(reader, reader.uint32());
          break;
        case 4:
          message.jobName = reader.string();
          break;
        case 5:
          message.preferredEmploymentType = EmploymentType.decode(reader, reader.uint32());
          break;
        case 6:
          message.japanPrefecture = JapanPrefecture.decode(reader, reader.uint32());
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },
  fromJSON(object: any): GetScoutingHumanResourcesResponse_ScoutingHumanResource_CareerHumanResource {
    const message = Object.create(baseGetScoutingHumanResourcesResponse_ScoutingHumanResource_CareerHumanResource) as GetScoutingHumanResourcesResponse_ScoutingHumanResource_CareerHumanResource;
    if (object.lastName) {
      message.lastName = String(object.lastName);
    }
    if (object.firstName) {
      message.firstName = String(object.firstName);
    }
    if (object.academicBackground) {
      message.academicBackground = AcademicBackground.fromJSON(object.academicBackground);
    }
    if (object.jobName) {
      message.jobName = String(object.jobName);
    }
    if (object.preferredEmploymentType) {
      message.preferredEmploymentType = EmploymentType.fromJSON(object.preferredEmploymentType);
    }
    if (object.japanPrefecture) {
      message.japanPrefecture = JapanPrefecture.fromJSON(object.japanPrefecture);
    }
    return message;
  },
  fromPartial(object: DeepPartial<GetScoutingHumanResourcesResponse_ScoutingHumanResource_CareerHumanResource>): GetScoutingHumanResourcesResponse_ScoutingHumanResource_CareerHumanResource {
    const message = Object.create(baseGetScoutingHumanResourcesResponse_ScoutingHumanResource_CareerHumanResource) as GetScoutingHumanResourcesResponse_ScoutingHumanResource_CareerHumanResource;
    if (object.lastName) {
      message.lastName = object.lastName;
    }
    if (object.firstName) {
      message.firstName = object.firstName;
    }
    if (object.academicBackground) {
      message.academicBackground = AcademicBackground.fromPartial(object.academicBackground);
    }
    if (object.jobName) {
      message.jobName = object.jobName;
    }
    if (object.preferredEmploymentType) {
      message.preferredEmploymentType = EmploymentType.fromPartial(object.preferredEmploymentType);
    }
    if (object.japanPrefecture) {
      message.japanPrefecture = JapanPrefecture.fromPartial(object.japanPrefecture);
    }
    return message;
  },
  toJSON(message: GetScoutingHumanResourcesResponse_ScoutingHumanResource_CareerHumanResource): unknown {
    const obj: any = {};
    obj.lastName = message.lastName || "";
    obj.firstName = message.firstName || "";
    obj.academicBackground = message.academicBackground ? AcademicBackground.toJSON(message.academicBackground) : undefined;
    obj.jobName = message.jobName || "";
    obj.preferredEmploymentType = message.preferredEmploymentType ? EmploymentType.toJSON(message.preferredEmploymentType) : undefined;
    obj.japanPrefecture = message.japanPrefecture ? JapanPrefecture.toJSON(message.japanPrefecture) : undefined;
    return obj;
  },
};

export const GetScoutingHumanResourcesResponse_ScoutingHumanResource_NewGraduateHumanResource = {
  encode(message: GetScoutingHumanResourcesResponse_ScoutingHumanResource_NewGraduateHumanResource, writer: Writer = Writer.create()): Writer {
    writer.uint32(10).string(message.lastName);
    writer.uint32(18).string(message.firstName);
    if (message.academicBackground !== undefined && message.academicBackground !== undefined) {
      AcademicBackground.encode(message.academicBackground, writer.uint32(26).fork()).ldelim();
    }
    if (message.japanPrefecture !== undefined && message.japanPrefecture !== undefined) {
      JapanPrefecture.encode(message.japanPrefecture, writer.uint32(34).fork()).ldelim();
    }
    return writer;
  },
  decode(reader: Reader, length?: number): GetScoutingHumanResourcesResponse_ScoutingHumanResource_NewGraduateHumanResource {
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = Object.create(baseGetScoutingHumanResourcesResponse_ScoutingHumanResource_NewGraduateHumanResource) as GetScoutingHumanResourcesResponse_ScoutingHumanResource_NewGraduateHumanResource;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.lastName = reader.string();
          break;
        case 2:
          message.firstName = reader.string();
          break;
        case 3:
          message.academicBackground = AcademicBackground.decode(reader, reader.uint32());
          break;
        case 4:
          message.japanPrefecture = JapanPrefecture.decode(reader, reader.uint32());
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },
  fromJSON(object: any): GetScoutingHumanResourcesResponse_ScoutingHumanResource_NewGraduateHumanResource {
    const message = Object.create(baseGetScoutingHumanResourcesResponse_ScoutingHumanResource_NewGraduateHumanResource) as GetScoutingHumanResourcesResponse_ScoutingHumanResource_NewGraduateHumanResource;
    if (object.lastName) {
      message.lastName = String(object.lastName);
    }
    if (object.firstName) {
      message.firstName = String(object.firstName);
    }
    if (object.academicBackground) {
      message.academicBackground = AcademicBackground.fromJSON(object.academicBackground);
    }
    if (object.japanPrefecture) {
      message.japanPrefecture = JapanPrefecture.fromJSON(object.japanPrefecture);
    }
    return message;
  },
  fromPartial(object: DeepPartial<GetScoutingHumanResourcesResponse_ScoutingHumanResource_NewGraduateHumanResource>): GetScoutingHumanResourcesResponse_ScoutingHumanResource_NewGraduateHumanResource {
    const message = Object.create(baseGetScoutingHumanResourcesResponse_ScoutingHumanResource_NewGraduateHumanResource) as GetScoutingHumanResourcesResponse_ScoutingHumanResource_NewGraduateHumanResource;
    if (object.lastName) {
      message.lastName = object.lastName;
    }
    if (object.firstName) {
      message.firstName = object.firstName;
    }
    if (object.academicBackground) {
      message.academicBackground = AcademicBackground.fromPartial(object.academicBackground);
    }
    if (object.japanPrefecture) {
      message.japanPrefecture = JapanPrefecture.fromPartial(object.japanPrefecture);
    }
    return message;
  },
  toJSON(message: GetScoutingHumanResourcesResponse_ScoutingHumanResource_NewGraduateHumanResource): unknown {
    const obj: any = {};
    obj.lastName = message.lastName || "";
    obj.firstName = message.firstName || "";
    obj.academicBackground = message.academicBackground ? AcademicBackground.toJSON(message.academicBackground) : undefined;
    obj.japanPrefecture = message.japanPrefecture ? JapanPrefecture.toJSON(message.japanPrefecture) : undefined;
    return obj;
  },
};

export const GetScoutingHumanResourcesFormRequest = {
  encode(message: GetScoutingHumanResourcesFormRequest, writer: Writer = Writer.create()): Writer {
    return writer;
  },
  decode(reader: Reader, length?: number): GetScoutingHumanResourcesFormRequest {
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = Object.create(baseGetScoutingHumanResourcesFormRequest) as GetScoutingHumanResourcesFormRequest;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },
  fromJSON(object: any): GetScoutingHumanResourcesFormRequest {
    const message = Object.create(baseGetScoutingHumanResourcesFormRequest) as GetScoutingHumanResourcesFormRequest;
    return message;
  },
  fromPartial(object: DeepPartial<GetScoutingHumanResourcesFormRequest>): GetScoutingHumanResourcesFormRequest {
    const message = Object.create(baseGetScoutingHumanResourcesFormRequest) as GetScoutingHumanResourcesFormRequest;
    return message;
  },
  toJSON(message: GetScoutingHumanResourcesFormRequest): unknown {
    const obj: any = {};
    return obj;
  },
};

export const GetScoutingHumanResourcesFormResponse = {
  encode(message: GetScoutingHumanResourcesFormResponse, writer: Writer = Writer.create()): Writer {
    if (message.form !== undefined && message.form !== undefined) {
      GetScoutingHumanResourcesFormResponse_Form.encode(message.form, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },
  decode(reader: Reader, length?: number): GetScoutingHumanResourcesFormResponse {
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = Object.create(baseGetScoutingHumanResourcesFormResponse) as GetScoutingHumanResourcesFormResponse;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.form = GetScoutingHumanResourcesFormResponse_Form.decode(reader, reader.uint32());
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },
  fromJSON(object: any): GetScoutingHumanResourcesFormResponse {
    const message = Object.create(baseGetScoutingHumanResourcesFormResponse) as GetScoutingHumanResourcesFormResponse;
    if (object.form) {
      message.form = GetScoutingHumanResourcesFormResponse_Form.fromJSON(object.form);
    }
    return message;
  },
  fromPartial(object: DeepPartial<GetScoutingHumanResourcesFormResponse>): GetScoutingHumanResourcesFormResponse {
    const message = Object.create(baseGetScoutingHumanResourcesFormResponse) as GetScoutingHumanResourcesFormResponse;
    if (object.form) {
      message.form = GetScoutingHumanResourcesFormResponse_Form.fromPartial(object.form);
    }
    return message;
  },
  toJSON(message: GetScoutingHumanResourcesFormResponse): unknown {
    const obj: any = {};
    obj.form = message.form ? GetScoutingHumanResourcesFormResponse_Form.toJSON(message.form) : undefined;
    return obj;
  },
};

export const GetScoutingHumanResourcesFormResponse_Form = {
  encode(message: GetScoutingHumanResourcesFormResponse_Form, writer: Writer = Writer.create()): Writer {
    for (const v of message.selectionSteps) {
      SelectionStep.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },
  decode(reader: Reader, length?: number): GetScoutingHumanResourcesFormResponse_Form {
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = Object.create(baseGetScoutingHumanResourcesFormResponse_Form) as GetScoutingHumanResourcesFormResponse_Form;
    message.selectionSteps = [];
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.selectionSteps.push(SelectionStep.decode(reader, reader.uint32()));
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },
  fromJSON(object: any): GetScoutingHumanResourcesFormResponse_Form {
    const message = Object.create(baseGetScoutingHumanResourcesFormResponse_Form) as GetScoutingHumanResourcesFormResponse_Form;
    message.selectionSteps = [];
    if (object.selectionSteps) {
      for (const e of object.selectionSteps) {
        message.selectionSteps.push(SelectionStep.fromJSON(e));
      }
    }
    return message;
  },
  fromPartial(object: DeepPartial<GetScoutingHumanResourcesFormResponse_Form>): GetScoutingHumanResourcesFormResponse_Form {
    const message = Object.create(baseGetScoutingHumanResourcesFormResponse_Form) as GetScoutingHumanResourcesFormResponse_Form;
    message.selectionSteps = [];
    if (object.selectionSteps) {
      for (const e of object.selectionSteps) {
        message.selectionSteps.push(SelectionStep.fromPartial(e));
      }
    }
    return message;
  },
  toJSON(message: GetScoutingHumanResourcesFormResponse_Form): unknown {
    const obj: any = {};
    if (message.selectionSteps) {
      obj.selectionSteps = message.selectionSteps.map(e => e ? SelectionStep.toJSON(e) : undefined);
    } else {
      obj.selectionSteps = [];
    }
    return obj;
  },
};

export const PutSelectionStepRequest = {
  encode(message: PutSelectionStepRequest, writer: Writer = Writer.create()): Writer {
    writer.uint32(10).string(message.scoutId);
    writer.uint32(16).uint32(message.selectionStepId);
    return writer;
  },
  decode(reader: Reader, length?: number): PutSelectionStepRequest {
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = Object.create(basePutSelectionStepRequest) as PutSelectionStepRequest;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.scoutId = reader.string();
          break;
        case 2:
          message.selectionStepId = reader.uint32();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },
  fromJSON(object: any): PutSelectionStepRequest {
    const message = Object.create(basePutSelectionStepRequest) as PutSelectionStepRequest;
    if (object.scoutId) {
      message.scoutId = String(object.scoutId);
    }
    if (object.selectionStepId) {
      message.selectionStepId = Number(object.selectionStepId);
    }
    return message;
  },
  fromPartial(object: DeepPartial<PutSelectionStepRequest>): PutSelectionStepRequest {
    const message = Object.create(basePutSelectionStepRequest) as PutSelectionStepRequest;
    if (object.scoutId) {
      message.scoutId = object.scoutId;
    }
    if (object.selectionStepId) {
      message.selectionStepId = object.selectionStepId;
    }
    return message;
  },
  toJSON(message: PutSelectionStepRequest): unknown {
    const obj: any = {};
    obj.scoutId = message.scoutId || "";
    obj.selectionStepId = message.selectionStepId || 0;
    return obj;
  },
};

export const PutSelectionStepResponse = {
  encode(message: PutSelectionStepResponse, writer: Writer = Writer.create()): Writer {
    return writer;
  },
  decode(reader: Reader, length?: number): PutSelectionStepResponse {
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = Object.create(basePutSelectionStepResponse) as PutSelectionStepResponse;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },
  fromJSON(object: any): PutSelectionStepResponse {
    const message = Object.create(basePutSelectionStepResponse) as PutSelectionStepResponse;
    return message;
  },
  fromPartial(object: DeepPartial<PutSelectionStepResponse>): PutSelectionStepResponse {
    const message = Object.create(basePutSelectionStepResponse) as PutSelectionStepResponse;
    return message;
  },
  toJSON(message: PutSelectionStepResponse): unknown {
    const obj: any = {};
    return obj;
  },
};

export const UpdateUnofficialOfferRequest = {
  encode(message: UpdateUnofficialOfferRequest, writer: Writer = Writer.create()): Writer {
    writer.uint32(10).string(message.scoutId);
    writer.uint32(16).int64(message.unofficialOfferConsentAt);
    return writer;
  },
  decode(reader: Reader, length?: number): UpdateUnofficialOfferRequest {
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = Object.create(baseUpdateUnofficialOfferRequest) as UpdateUnofficialOfferRequest;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.scoutId = reader.string();
          break;
        case 2:
          message.unofficialOfferConsentAt = longToNumber(reader.int64() as Long);
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },
  fromJSON(object: any): UpdateUnofficialOfferRequest {
    const message = Object.create(baseUpdateUnofficialOfferRequest) as UpdateUnofficialOfferRequest;
    if (object.scoutId) {
      message.scoutId = String(object.scoutId);
    }
    if (object.unofficialOfferConsentAt) {
      message.unofficialOfferConsentAt = Number(object.unofficialOfferConsentAt);
    }
    return message;
  },
  fromPartial(object: DeepPartial<UpdateUnofficialOfferRequest>): UpdateUnofficialOfferRequest {
    const message = Object.create(baseUpdateUnofficialOfferRequest) as UpdateUnofficialOfferRequest;
    if (object.scoutId) {
      message.scoutId = object.scoutId;
    }
    if (object.unofficialOfferConsentAt) {
      message.unofficialOfferConsentAt = object.unofficialOfferConsentAt;
    }
    return message;
  },
  toJSON(message: UpdateUnofficialOfferRequest): unknown {
    const obj: any = {};
    obj.scoutId = message.scoutId || "";
    obj.unofficialOfferConsentAt = message.unofficialOfferConsentAt || 0;
    return obj;
  },
};

export const UpdateUnofficialOfferResponse = {
  encode(message: UpdateUnofficialOfferResponse, writer: Writer = Writer.create()): Writer {
    return writer;
  },
  decode(reader: Reader, length?: number): UpdateUnofficialOfferResponse {
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = Object.create(baseUpdateUnofficialOfferResponse) as UpdateUnofficialOfferResponse;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },
  fromJSON(object: any): UpdateUnofficialOfferResponse {
    const message = Object.create(baseUpdateUnofficialOfferResponse) as UpdateUnofficialOfferResponse;
    return message;
  },
  fromPartial(object: DeepPartial<UpdateUnofficialOfferResponse>): UpdateUnofficialOfferResponse {
    const message = Object.create(baseUpdateUnofficialOfferResponse) as UpdateUnofficialOfferResponse;
    return message;
  },
  toJSON(message: UpdateUnofficialOfferResponse): unknown {
    const obj: any = {};
    return obj;
  },
};

export const PutJoinCompanyScheduleRequest = {
  encode(message: PutJoinCompanyScheduleRequest, writer: Writer = Writer.create()): Writer {
    writer.uint32(10).string(message.scoutId);
    writer.uint32(16).int64(message.joinCompanyScheduleAt);
    writer.uint32(24).int64(message.unofficialOfferConsentAt);
    return writer;
  },
  decode(reader: Reader, length?: number): PutJoinCompanyScheduleRequest {
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = Object.create(basePutJoinCompanyScheduleRequest) as PutJoinCompanyScheduleRequest;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.scoutId = reader.string();
          break;
        case 2:
          message.joinCompanyScheduleAt = longToNumber(reader.int64() as Long);
          break;
        case 3:
          message.unofficialOfferConsentAt = longToNumber(reader.int64() as Long);
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },
  fromJSON(object: any): PutJoinCompanyScheduleRequest {
    const message = Object.create(basePutJoinCompanyScheduleRequest) as PutJoinCompanyScheduleRequest;
    if (object.scoutId) {
      message.scoutId = String(object.scoutId);
    }
    if (object.joinCompanyScheduleAt) {
      message.joinCompanyScheduleAt = Number(object.joinCompanyScheduleAt);
    }
    if (object.unofficialOfferConsentAt) {
      message.unofficialOfferConsentAt = Number(object.unofficialOfferConsentAt);
    }
    return message;
  },
  fromPartial(object: DeepPartial<PutJoinCompanyScheduleRequest>): PutJoinCompanyScheduleRequest {
    const message = Object.create(basePutJoinCompanyScheduleRequest) as PutJoinCompanyScheduleRequest;
    if (object.scoutId) {
      message.scoutId = object.scoutId;
    }
    if (object.joinCompanyScheduleAt) {
      message.joinCompanyScheduleAt = object.joinCompanyScheduleAt;
    }
    if (object.unofficialOfferConsentAt) {
      message.unofficialOfferConsentAt = object.unofficialOfferConsentAt;
    }
    return message;
  },
  toJSON(message: PutJoinCompanyScheduleRequest): unknown {
    const obj: any = {};
    obj.scoutId = message.scoutId || "";
    obj.joinCompanyScheduleAt = message.joinCompanyScheduleAt || 0;
    obj.unofficialOfferConsentAt = message.unofficialOfferConsentAt || 0;
    return obj;
  },
};

export const PutJoinCompanyScheduleResponse = {
  encode(message: PutJoinCompanyScheduleResponse, writer: Writer = Writer.create()): Writer {
    return writer;
  },
  decode(reader: Reader, length?: number): PutJoinCompanyScheduleResponse {
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = Object.create(basePutJoinCompanyScheduleResponse) as PutJoinCompanyScheduleResponse;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },
  fromJSON(object: any): PutJoinCompanyScheduleResponse {
    const message = Object.create(basePutJoinCompanyScheduleResponse) as PutJoinCompanyScheduleResponse;
    return message;
  },
  fromPartial(object: DeepPartial<PutJoinCompanyScheduleResponse>): PutJoinCompanyScheduleResponse {
    const message = Object.create(basePutJoinCompanyScheduleResponse) as PutJoinCompanyScheduleResponse;
    return message;
  },
  toJSON(message: PutJoinCompanyScheduleResponse): unknown {
    const obj: any = {};
    return obj;
  },
};

export const PutScoutContractExplanationViewedCountRequest = {
  encode(message: PutScoutContractExplanationViewedCountRequest, writer: Writer = Writer.create()): Writer {
    return writer;
  },
  decode(reader: Reader, length?: number): PutScoutContractExplanationViewedCountRequest {
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = Object.create(basePutScoutContractExplanationViewedCountRequest) as PutScoutContractExplanationViewedCountRequest;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },
  fromJSON(object: any): PutScoutContractExplanationViewedCountRequest {
    const message = Object.create(basePutScoutContractExplanationViewedCountRequest) as PutScoutContractExplanationViewedCountRequest;
    return message;
  },
  fromPartial(object: DeepPartial<PutScoutContractExplanationViewedCountRequest>): PutScoutContractExplanationViewedCountRequest {
    const message = Object.create(basePutScoutContractExplanationViewedCountRequest) as PutScoutContractExplanationViewedCountRequest;
    return message;
  },
  toJSON(message: PutScoutContractExplanationViewedCountRequest): unknown {
    const obj: any = {};
    return obj;
  },
};

export const PutScoutContractExplanationViewedCountResponse = {
  encode(message: PutScoutContractExplanationViewedCountResponse, writer: Writer = Writer.create()): Writer {
    return writer;
  },
  decode(reader: Reader, length?: number): PutScoutContractExplanationViewedCountResponse {
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = Object.create(basePutScoutContractExplanationViewedCountResponse) as PutScoutContractExplanationViewedCountResponse;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },
  fromJSON(object: any): PutScoutContractExplanationViewedCountResponse {
    const message = Object.create(basePutScoutContractExplanationViewedCountResponse) as PutScoutContractExplanationViewedCountResponse;
    return message;
  },
  fromPartial(object: DeepPartial<PutScoutContractExplanationViewedCountResponse>): PutScoutContractExplanationViewedCountResponse {
    const message = Object.create(basePutScoutContractExplanationViewedCountResponse) as PutScoutContractExplanationViewedCountResponse;
    return message;
  },
  toJSON(message: PutScoutContractExplanationViewedCountResponse): unknown {
    const obj: any = {};
    return obj;
  },
};

export const SendScoutFeatureRequestRequest = {
  encode(message: SendScoutFeatureRequestRequest, writer: Writer = Writer.create()): Writer {
    return writer;
  },
  decode(reader: Reader, length?: number): SendScoutFeatureRequestRequest {
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = Object.create(baseSendScoutFeatureRequestRequest) as SendScoutFeatureRequestRequest;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },
  fromJSON(object: any): SendScoutFeatureRequestRequest {
    const message = Object.create(baseSendScoutFeatureRequestRequest) as SendScoutFeatureRequestRequest;
    return message;
  },
  fromPartial(object: DeepPartial<SendScoutFeatureRequestRequest>): SendScoutFeatureRequestRequest {
    const message = Object.create(baseSendScoutFeatureRequestRequest) as SendScoutFeatureRequestRequest;
    return message;
  },
  toJSON(message: SendScoutFeatureRequestRequest): unknown {
    const obj: any = {};
    return obj;
  },
};

export const SendScoutFeatureRequestResponse = {
  encode(message: SendScoutFeatureRequestResponse, writer: Writer = Writer.create()): Writer {
    return writer;
  },
  decode(reader: Reader, length?: number): SendScoutFeatureRequestResponse {
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = Object.create(baseSendScoutFeatureRequestResponse) as SendScoutFeatureRequestResponse;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },
  fromJSON(object: any): SendScoutFeatureRequestResponse {
    const message = Object.create(baseSendScoutFeatureRequestResponse) as SendScoutFeatureRequestResponse;
    return message;
  },
  fromPartial(object: DeepPartial<SendScoutFeatureRequestResponse>): SendScoutFeatureRequestResponse {
    const message = Object.create(baseSendScoutFeatureRequestResponse) as SendScoutFeatureRequestResponse;
    return message;
  },
  toJSON(message: SendScoutFeatureRequestResponse): unknown {
    const obj: any = {};
    return obj;
  },
};

export const GetScoutContractedStatusRequest = {
  encode(message: GetScoutContractedStatusRequest, writer: Writer = Writer.create()): Writer {
    return writer;
  },
  decode(reader: Reader, length?: number): GetScoutContractedStatusRequest {
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = Object.create(baseGetScoutContractedStatusRequest) as GetScoutContractedStatusRequest;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },
  fromJSON(object: any): GetScoutContractedStatusRequest {
    const message = Object.create(baseGetScoutContractedStatusRequest) as GetScoutContractedStatusRequest;
    return message;
  },
  fromPartial(object: DeepPartial<GetScoutContractedStatusRequest>): GetScoutContractedStatusRequest {
    const message = Object.create(baseGetScoutContractedStatusRequest) as GetScoutContractedStatusRequest;
    return message;
  },
  toJSON(message: GetScoutContractedStatusRequest): unknown {
    const obj: any = {};
    return obj;
  },
};

export const GetScoutContractedStatusResponse = {
  encode(message: GetScoutContractedStatusResponse, writer: Writer = Writer.create()): Writer {
    writer.uint32(8).uint32(message.scoutContractedStatus);
    return writer;
  },
  decode(reader: Reader, length?: number): GetScoutContractedStatusResponse {
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = Object.create(baseGetScoutContractedStatusResponse) as GetScoutContractedStatusResponse;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.scoutContractedStatus = reader.uint32();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },
  fromJSON(object: any): GetScoutContractedStatusResponse {
    const message = Object.create(baseGetScoutContractedStatusResponse) as GetScoutContractedStatusResponse;
    if (object.scoutContractedStatus) {
      message.scoutContractedStatus = Number(object.scoutContractedStatus);
    }
    return message;
  },
  fromPartial(object: DeepPartial<GetScoutContractedStatusResponse>): GetScoutContractedStatusResponse {
    const message = Object.create(baseGetScoutContractedStatusResponse) as GetScoutContractedStatusResponse;
    if (object.scoutContractedStatus) {
      message.scoutContractedStatus = object.scoutContractedStatus;
    }
    return message;
  },
  toJSON(message: GetScoutContractedStatusResponse): unknown {
    const obj: any = {};
    obj.scoutContractedStatus = message.scoutContractedStatus || 0;
    return obj;
  },
};

export const GetMissionUsePlansRequest = {
  encode(message: GetMissionUsePlansRequest, writer: Writer = Writer.create()): Writer {
    return writer;
  },
  decode(reader: Reader, length?: number): GetMissionUsePlansRequest {
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = Object.create(baseGetMissionUsePlansRequest) as GetMissionUsePlansRequest;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },
  fromJSON(object: any): GetMissionUsePlansRequest {
    const message = Object.create(baseGetMissionUsePlansRequest) as GetMissionUsePlansRequest;
    return message;
  },
  fromPartial(object: DeepPartial<GetMissionUsePlansRequest>): GetMissionUsePlansRequest {
    const message = Object.create(baseGetMissionUsePlansRequest) as GetMissionUsePlansRequest;
    return message;
  },
  toJSON(message: GetMissionUsePlansRequest): unknown {
    const obj: any = {};
    return obj;
  },
};

export const GetMissionUsePlansResponse = {
  encode(message: GetMissionUsePlansResponse, writer: Writer = Writer.create()): Writer {
    for (const v of message.missionUsePlans) {
      MissionUsePlan.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },
  decode(reader: Reader, length?: number): GetMissionUsePlansResponse {
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = Object.create(baseGetMissionUsePlansResponse) as GetMissionUsePlansResponse;
    message.missionUsePlans = [];
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.missionUsePlans.push(MissionUsePlan.decode(reader, reader.uint32()));
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },
  fromJSON(object: any): GetMissionUsePlansResponse {
    const message = Object.create(baseGetMissionUsePlansResponse) as GetMissionUsePlansResponse;
    message.missionUsePlans = [];
    if (object.missionUsePlans) {
      for (const e of object.missionUsePlans) {
        message.missionUsePlans.push(MissionUsePlan.fromJSON(e));
      }
    }
    return message;
  },
  fromPartial(object: DeepPartial<GetMissionUsePlansResponse>): GetMissionUsePlansResponse {
    const message = Object.create(baseGetMissionUsePlansResponse) as GetMissionUsePlansResponse;
    message.missionUsePlans = [];
    if (object.missionUsePlans) {
      for (const e of object.missionUsePlans) {
        message.missionUsePlans.push(MissionUsePlan.fromPartial(e));
      }
    }
    return message;
  },
  toJSON(message: GetMissionUsePlansResponse): unknown {
    const obj: any = {};
    if (message.missionUsePlans) {
      obj.missionUsePlans = message.missionUsePlans.map(e => e ? MissionUsePlan.toJSON(e) : undefined);
    } else {
      obj.missionUsePlans = [];
    }
    return obj;
  },
};

export const Paging = {
  encode(message: Paging, writer: Writer = Writer.create()): Writer {
    writer.uint32(10).string(message.nextQ);
    return writer;
  },
  decode(reader: Reader, length?: number): Paging {
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = Object.create(basePaging) as Paging;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.nextQ = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },
  fromJSON(object: any): Paging {
    const message = Object.create(basePaging) as Paging;
    if (object.nextQ) {
      message.nextQ = String(object.nextQ);
    }
    return message;
  },
  fromPartial(object: DeepPartial<Paging>): Paging {
    const message = Object.create(basePaging) as Paging;
    if (object.nextQ) {
      message.nextQ = object.nextQ;
    }
    return message;
  },
  toJSON(message: Paging): unknown {
    const obj: any = {};
    obj.nextQ = message.nextQ || "";
    return obj;
  },
};

type DeepPartial<T> = {
  [P in keyof T]?: T[P] extends Array<infer U>
  ? Array<DeepPartial<U>>
  : T[P] extends ReadonlyArray<infer U>
  ? ReadonlyArray<DeepPartial<U>>
  : T[P] extends Date | Function | Uint8Array | undefined
  ? T[P]
  : T[P] extends infer U | undefined
  ? DeepPartial<U>
  : T[P] extends object
  ? DeepPartial<T[P]>
  : T[P]
};