import styled from 'styled-components/macro';
import theme from 'styles/theme';
import TableRow from './TableRow';

const TableHead = styled.thead`
  display: block;
  background: ${theme.tableThead};
  color: ${theme.baseWhite};

  /* stylelint-disable-next-line selector-type-no-unknown */
  ${TableRow}:hover {
    background: ${theme.tableThead};
    cursor: default;
  }
`;

export default TableHead;
