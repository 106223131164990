import React, { FC, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import styled, { css } from 'styled-components/macro';
import theme from 'styles/theme';
import { SelectionStep } from 'proto/v1/apimodel/apimodel';
import { ScoutingHumanResource } from 'types/scout';
import { formatDate } from 'utils/time';
import { SELECTION_STEP_ID } from 'constants/models/matter';
import {
  TableCell,
  TableRow,
  LinkButton,
  Selectbox,
  ButtonS,
} from 'components/atoms';
import { EmploymentTypeTag } from 'components/molecules';
import typography from 'styles/typography';
import useStartChat from 'hooks/useStartChat';

const StyledTableRow = styled(TableRow)<{ isDisabled: boolean }>`
  padding: 0 12px;

  &:hover {
    cursor: default;
  }

  ${({ isDisabled }) =>
    isDisabled &&
    css`
      color: ${theme.textSecondary};
      background-color: ${theme.baseDisabled};

      &:hover {
        background-color: ${theme.baseDisabled};
      }
    `}
`;

const StyledTableCell = styled(TableCell)`
  &&& {
    padding: 28px 20px;
  }
`;

const EmploymentTypeCellInner = styled.div`
  position: relative;
`;

const AcademicBackgroundCellInner = styled.div``;

const InnovatorName = styled.p`
  ${typography.headlineDefault}
  margin-bottom: 6px;
  color: ${theme.textPrimary};
`;

const CompanyName = styled.p`
  ${typography.textBold}
  margin-bottom: 4px;
  color: ${theme.textPrimary};
`;

const AcademicBackGround = styled.p`
  ${typography.textSub}
`;

const Profile = styled.p`
  color: ${theme.textSecondary};
  font-size: 10px;
  line-height: 15px;
`;

const StatusDescription = styled.p`
  ${typography.textSub}
`;

const MessageButton = styled(ButtonS)`
  min-width: 144px;
`;

type FollowingHumanResourcesTableRowProps = {
  scoutingHumanResource: ScoutingHumanResource;
  columnWidth: string[];
  selectionSteps: SelectionStep[];
  onChangeSelectionStep: (
    scoutingHumanResource: ScoutingHumanResource | undefined,
    selectionStepId: SelectionStep['id'],
  ) => Promise<void>;
  onOpenReportJoiningDateModal: (
    scoutingHumanResource: ScoutingHumanResource | undefined,
  ) => void;
  onClickShowProfile: (
    scoutingHumanResource: ScoutingHumanResource | undefined,
  ) => Promise<void>;
  selectedSelectionStepId: SelectionStep['id'];
};

const FollowingHumanResourcesTableRow: FC<FollowingHumanResourcesTableRowProps> = props => {
  const {
    scoutingHumanResource,
    columnWidth,
    selectionSteps,
    selectedSelectionStepId,
    onChangeSelectionStep,
    onOpenReportJoiningDateModal,
    onClickShowProfile,
  } = props;
  const { t } = useTranslation(['followingHumanResources']);

  const isCareer = !!scoutingHumanResource.career;
  const isNewGraduate = !!scoutingHumanResource.newGraduate;
  const isPublished = !!scoutingHumanResource.publishedAt;
  const isDeleted = scoutingHumanResource.deleted;

  const { openChatRoom } = useStartChat();

  const handleClickShowProfile = useCallback(() => {
    if (!isPublished || isDeleted) return;
    onClickShowProfile(scoutingHumanResource);
  }, [isDeleted, isPublished, onClickShowProfile, scoutingHumanResource]);

  const handleClickMessageButton = useCallback(() => {
    if (!isPublished) return;
    openChatRoom(scoutingHumanResource.chatRoomId);
  }, [isPublished, openChatRoom, scoutingHumanResource.chatRoomId]);

  const selectionStepOptions = useMemo(() => {
    if (selectedSelectionStepId === SELECTION_STEP_ID.UNOFFICIAL_OFFERED) {
      return selectionSteps.filter(
        selectionStep =>
          selectionStep.id !== SELECTION_STEP_ID.UNOFFICIAL_OFFER_CONSENTED,
      );
    }
    if (
      selectedSelectionStepId === SELECTION_STEP_ID.UNOFFICIAL_OFFER_CONSENTED
    ) {
      return selectionSteps.filter(
        selectionStep =>
          selectionStep.id !== SELECTION_STEP_ID.UNOFFICIAL_OFFERED,
      );
    }
    return selectionSteps.filter(
      selectionStep =>
        selectionStep.id !== SELECTION_STEP_ID.UNOFFICIAL_OFFERED &&
        selectionStep.id !== SELECTION_STEP_ID.UNOFFICIAL_OFFER_CONSENTED,
    );
  }, [selectedSelectionStepId, selectionSteps]);

  const lastName = scoutingHumanResource.career?.lastName
    ? scoutingHumanResource.career.lastName
    : scoutingHumanResource.newGraduate?.lastName;

  const firstName = scoutingHumanResource.career?.firstName
    ? scoutingHumanResource.career.firstName
    : scoutingHumanResource.newGraduate?.firstName;

  const academicBackground = scoutingHumanResource.career?.academicBackground
    ? scoutingHumanResource.career.academicBackground
    : scoutingHumanResource.newGraduate?.academicBackground;

  const japanPrefecture = scoutingHumanResource.career?.japanPrefecture
    ? scoutingHumanResource.career.japanPrefecture
    : scoutingHumanResource.newGraduate?.japanPrefecture;

  const JoiningDateTableCellContent = useMemo(() => {
    if (!scoutingHumanResource.communicatable || !isPublished) {
      return '-';
    }
    if (scoutingHumanResource.joinCompanyScheduleAt) {
      return (
        <p>
          {formatDate(
            new Date(scoutingHumanResource.joinCompanyScheduleAt),
            'yyyy/MM/dd',
          )}
        </p>
      );
    }
    if (isDeleted) {
      return '-';
    }
    return (
      <LinkButton
        onClick={() => {
          onOpenReportJoiningDateModal(scoutingHumanResource);
        }}
      >
        {t('link.reportJoiningDate', { lng: 'ja' })}
      </LinkButton>
    );
  }, [
    isDeleted,
    isPublished,
    onOpenReportJoiningDateModal,
    scoutingHumanResource,
    t,
  ]);

  const StatusTableCellContent = useMemo(() => {
    if (isDeleted) {
      return (
        <StatusDescription>
          {t('description.deleted', { lng: 'ja' })}
        </StatusDescription>
      );
    }
    if (isPublished) {
      return (
        <MessageButton
          type="button"
          buttonTheme="default"
          onClick={handleClickMessageButton}
        >
          {t('button.message', { lng: 'ja' })}
        </MessageButton>
      );
    }
    return (
      <StatusDescription>
        {t('description.unPublished', { lng: 'ja' })}
      </StatusDescription>
    );
  }, [handleClickMessageButton, isDeleted, isPublished, t]);

  return (
    <StyledTableRow
      data-testid="following-contact-human-resource-row"
      isDisabled={!isPublished || isDeleted}
    >
      <StyledTableCell
        cellWidth={columnWidth[0]}
        style={{ position: 'relative' }}
      >
        <EmploymentTypeCellInner>
          <EmploymentTypeTag
            employmentTypeId={
              scoutingHumanResource.career?.preferredEmploymentType?.id
            }
            isNewGraduate={isNewGraduate}
            isPublished={isPublished}
          />
        </EmploymentTypeCellInner>
      </StyledTableCell>
      <StyledTableCell cellWidth={columnWidth[1]}>
        {isPublished ? (
          <div>
            <InnovatorName>
              {lastName && firstName ? `${lastName}${firstName}` : '-'}
            </InnovatorName>
            {!isDeleted && (
              <LinkButton onClick={handleClickShowProfile}>
                {t('link.showProfile', { lng: 'ja' })}
              </LinkButton>
            )}
          </div>
        ) : (
          '-'
        )}
      </StyledTableCell>
      <StyledTableCell cellWidth={columnWidth[2]}>
        <AcademicBackgroundCellInner>
          <CompanyName>
            {scoutingHumanResource?.career?.jobName ?? '-'}
          </CompanyName>
          <AcademicBackGround>
            {academicBackground?.school?.name}
          </AcademicBackGround>
          <AcademicBackGround style={{ marginBottom: '2px' }}>
            {academicBackground?.faculty}
            {academicBackground?.department?.name}
            {' / '}
            {academicBackground?.yearOfGraduation}
            {isCareer && t('label.graduationYear', { lng: 'ja' })}
            {isNewGraduate && t('label.expectedGraduationYear', { lng: 'ja' })}
          </AcademicBackGround>
          <Profile>
            {t('field.japanPrefecture')} : {japanPrefecture?.name ?? '-'} /{' '}
            {t('field.contactDate')} :{' '}
            {formatDate(
              new Date(scoutingHumanResource.scoutedAt),
              'yyyy/MM/dd',
            )}
          </Profile>
        </AcademicBackgroundCellInner>
      </StyledTableCell>
      <StyledTableCell cellWidth={columnWidth[3]}>
        {JoiningDateTableCellContent}
      </StyledTableCell>
      <StyledTableCell cellWidth={columnWidth[4]}>
        {isPublished && !isDeleted && (
          <Selectbox
            name="selectionStep"
            value={selectedSelectionStepId}
            idName="id"
            width="148px"
            options={selectionStepOptions}
            onChange={e => {
              onChangeSelectionStep(
                scoutingHumanResource,
                parseInt(e.target.value, 10),
              );
            }}
            noSelectValue={0}
            aria-label={t('field.selectionStep')}
          />
        )}
      </StyledTableCell>
      <StyledTableCell cellWidth={columnWidth[5]}>
        {StatusTableCellContent}
      </StyledTableCell>
    </StyledTableRow>
  );
};

export default React.memo(FollowingHumanResourcesTableRow);
