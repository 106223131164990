import React, { FC, HTMLAttributes } from 'react';
import styled, { css } from 'styled-components/macro';
import typography from 'styles/typography';
import theme from 'styles/theme';

type Position = 'left' | 'right';

const Wrapper = styled.div<{ position: Position }>`
  ${typography.textDefault}
  position: relative;
  box-sizing: border-box;
  margin-left: 8px;
  padding: 6px 12px;
  border: 1px solid ${theme.basePrimary};
  border-radius: 4px;
  background-color: ${theme.baseWhite};
  color: ${theme.textPrimary};

  &::before,
  &::after {
    content: '';
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    border: 5px solid transparent;
  }

  ${({ position }) =>
    position === 'left' &&
    css`
      &::before {
        left: -13px;
        border-right: 8px solid ${theme.basePrimary};
      }

      &::after {
        left: -11px;
        border-right: 8px solid ${theme.baseWhite};
      }
    `}

  ${({ position }) =>
    position === 'right' &&
    css`
      &::before {
        right: -13px;
        border-left: 8px solid ${theme.basePrimary};
      }

      &::after {
        right: -11px;
        border-left: 8px solid ${theme.baseWhite};
      }
    `}
`;

type BalloonProps = { position?: Position } & HTMLAttributes<HTMLDivElement>;

const Balloon: FC<BalloonProps> = ({
  children,
  position = 'left',
  ...props
}) => {
  return (
    <Wrapper {...props} position={position}>
      {children}
    </Wrapper>
  );
};

export default Balloon;
