import { useState, useCallback, useEffect } from 'react';
import { MissionFormValues } from 'types/mission';
import { validateMissionFormValuesToPublish } from '../../validateToPublish';
import useMissionJobOffersField from '../../../hooks/useMissionJobOffersField';
import { stepMissionJobOffersSchema } from '../../validationSchema';
import useMissionFormContext from '../../useMissionFormContext';
import useMissionFormFormik from '../../useMissionFormFormik';

type Values = Pick<MissionFormValues, 'missionJobOffers'>;

const defaultInitialValues: Values = {
  missionJobOffers: [],
};

const useStepMissionJobOffers = () => {
  const {
    movePrevStep,
    moveNextStep,
    form,
    isFirstPublished,
    missionFormValues,
    isPublished,
    updateMission,
    publishMission,
    unPublishMission,
    modalState,
    closeModal,
    openModal,
    validateToPublishStepsResult,
    setValidateToPublishStepsResult,
    getAddedItemId,
    openPreview,
    canUseMissionFormStepMissionJobOffers,
    canUseMissionFormStepMissionDemand,
  } = useMissionFormContext();

  const [initialValues, setInitialValues] = useState<Values>(
    defaultInitialValues,
  );

  const { formik } = useMissionFormFormik<Values>({
    initialValues,
    validationSchema: stepMissionJobOffersSchema,
  });

  const { dirty, values, setFieldValue, resetForm } = formik;

  const validateToSave = useCallback(() => {
    if (!missionFormValues) return;

    const { isValid, stepsResult } = validateMissionFormValuesToPublish(
      {
        ...missionFormValues,
        ...values,
      },
      canUseMissionFormStepMissionDemand,
      canUseMissionFormStepMissionJobOffers,
    );
    if (!isValid) {
      setValidateToPublishStepsResult(stepsResult);
      openModal('saveRequiredNoInputConfirmation');
      return false;
    }
    return true;
  }, [
    canUseMissionFormStepMissionDemand,
    canUseMissionFormStepMissionJobOffers,
    missionFormValues,
    openModal,
    setValidateToPublishStepsResult,
    values,
  ]);

  const validateToPublish = useCallback(() => {
    if (!missionFormValues) return;

    const { isValid, stepsResult } = validateMissionFormValuesToPublish(
      {
        ...missionFormValues,
        ...values,
      },
      canUseMissionFormStepMissionDemand,
      canUseMissionFormStepMissionJobOffers,
    );

    setValidateToPublishStepsResult(stepsResult);

    if (!isValid) {
      openModal('invalidToPublish');
      return false;
    }

    return true;
  }, [
    canUseMissionFormStepMissionDemand,
    canUseMissionFormStepMissionJobOffers,
    missionFormValues,
    openModal,
    setValidateToPublishStepsResult,
    values,
  ]);

  const handleClickSave = useCallback(() => {
    if (!dirty) return;
    if (!validateToSave()) return;
    openModal('saveMissionConfirmation');
  }, [dirty, openModal, validateToSave]);

  const saveMission = useCallback(async () => {
    if (!missionFormValues) return;
    try {
      await updateMission({ ...missionFormValues, ...values });
    } catch (error) {
      throw new Error('Failed to update mission');
    }
    setInitialValues(values);
    resetForm();
  }, [missionFormValues, resetForm, updateMission, values]);

  const handleClickNext = useCallback(async () => {
    if (dirty) {
      try {
        await saveMission();
      } catch (error) {
        // Nothing to do.
        return;
      }
    }
    moveNextStep();
  }, [dirty, moveNextStep, saveMission]);

  const handleClickSaveConfirmationOK = useCallback(async () => {
    try {
      await saveMission();
    } catch (error) {
      // Nothing to do.
      return;
    }
    openModal('saveMissionCompleted');
  }, [openModal, saveMission]);

  const handleClickPublish = useCallback(async () => {
    if (!validateToPublish()) {
      return;
    }
    openModal('publishMissionConfirmation');
  }, [openModal, validateToPublish]);

  const handleClickPublishConfirmationOk = useCallback(async () => {
    if (!missionFormValues) return;
    try {
      await publishMission({ ...missionFormValues, ...values });
    } catch (error) {
      // Nothing to do.
      return;
    }
    setInitialValues(values);
    resetForm();
  }, [missionFormValues, publishMission, resetForm, values]);

  const handleClickUnPublishConfirmationOk = useCallback(async () => {
    if (!missionFormValues) return;
    try {
      await unPublishMission({ ...missionFormValues, ...values });
    } catch (error) {
      // Nothing to do.
      return;
    }
    setInitialValues(values);
    resetForm();
  }, [missionFormValues, resetForm, unPublishMission, values]);

  const {
    handleClickCreateMissionJobOffer,
    handleClickEditMissionJobOffer,
    handleSaveMissionJobOffer,
    handleDeleteMissionJobOffer,
    handlePublishMissionJobOffer,
    handleUnPublishMissionJobOffer,
  } = useMissionJobOffersField({
    missionJobOffers: values.missionJobOffers,
    getAddedItemId,
    setFieldValue,
    openModal,
    closeModal,
  });

  // Set initial values for fetched mission.
  useEffect(() => {
    if (!missionFormValues) return;
    setInitialValues({
      missionJobOffers: missionFormValues.missionJobOffers,
    });
  }, [missionFormValues]);

  return {
    handleClickNext,
    movePrevStep,
    isPublished,
    isFirstPublished,
    formik,
    form,
    modalState,
    closeModal,
    handleClickSave,
    handleClickSaveConfirmationOK,
    handleClickPublish,
    handleClickPublishConfirmationOk,
    handleClickUnPublishConfirmationOk,
    validateToPublishStepsResult,
    handleClickCreateMissionJobOffer,
    handleClickEditMissionJobOffer,
    handleSaveMissionJobOffer,
    handleDeleteMissionJobOffer,
    handlePublishMissionJobOffer,
    handleUnPublishMissionJobOffer,
    openPreview,
    canUseMissionFormStepMissionJobOffers,
    canUseMissionFormStepMissionDemand,
  };
};

export default useStepMissionJobOffers;
