import { useState, useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { getIsShown as getIsErrorDialogShown } from 'modules/errorDialog';
import ImageTypes from 'constants/imageTypes';
import {
  ICON_IMAGE_ASPECT_RATIO,
  COVER_IMAGE_ASPECT_RATIO,
  OFFICE_IMAGE_ASPECT_RATIO,
} from 'constants/config';

export type ImageCropperStateType = {
  show: boolean;
  type?: ImageTypes;
  imageSrc?: string;
  croppedImage?: string;
  index?: number | undefined;
  field?: string | undefined;
};

const useImageCropper = () => {
  const [imageCropperState, setImageCropperState] = useState<
    ImageCropperStateType
  >({
    show: false,
  });

  const isErrorDialogShown = useSelector(getIsErrorDialogShown);

  const openImageCropper = useCallback(
    (
      type: ImageTypes,
      imageSrc: string,
      index = undefined,
      field = undefined,
    ) => {
      setImageCropperState({
        show: true,
        type,
        imageSrc,
        index,
        field,
      });
    },
    [setImageCropperState],
  );

  const saveImageCropper = useCallback(
    croppedImage => {
      // hide and save cropped state to restore crop modal
      setImageCropperState(prevState => ({
        ...prevState,
        show: false,
        croppedImage,
      }));
    },
    [setImageCropperState],
  );

  const closeImageCropper = useCallback(() => {
    setImageCropperState({ show: false });
  }, [setImageCropperState]);

  const cropperAspectRatio = (() => {
    const { type } = imageCropperState;
    if (!type) return undefined;
    return {
      [ImageTypes.ICON]: undefined,
      [ImageTypes.COVER]: COVER_IMAGE_ASPECT_RATIO,
      [ImageTypes.MEMBER_ICON]: ICON_IMAGE_ASPECT_RATIO,
      [ImageTypes.OFFICE]: OFFICE_IMAGE_ASPECT_RATIO,
      [ImageTypes.PRODUCT]: COVER_IMAGE_ASPECT_RATIO,
    }[type];
  })();

  useEffect(() => {
    // hide and re-show by error modal state change
    setImageCropperState(prevState => ({
      ...prevState,
      show: !!prevState.croppedImage && !isErrorDialogShown,
    }));
  }, [isErrorDialogShown]);

  return {
    imageCropperState,
    openImageCropper,
    saveImageCropper,
    closeImageCropper,
    cropperAspectRatio,
  };
};

export default useImageCropper;
