import React, { FC, ComponentProps, useMemo } from 'react';
import styled from 'styled-components';
import theme from 'styles/theme';
import typography from 'styles/typography';
import { Link } from 'react-router-dom';
import { Card, ImageCenter, EllipsisBox } from 'components/atoms';
import * as PROTECTED_ROUTES from 'routes/constants/protected';
import { imageSelector } from 'utils/imageSelector';
import { Supporter } from 'proto/v1/apimodel/apimodel';
import { sortedSupportAreas } from 'utils/models/supporter';
import { useTranslation } from 'react-i18next';
import { SupportAreaTag } from '..';

export const CARD_SIZE = {
  WIDTH: 271,
  HEIGHT: 356,
};

const StyledCard = styled(Card)`
  width: ${CARD_SIZE.WIDTH}px;
  height: ${CARD_SIZE.HEIGHT}px;
  border: none;
  border-radius: 8px;
  box-shadow: 0 0 16px rgba(0, 0, 0, 0.14);
  color: ${theme.textPrimary};

  > div {
    padding: 0;
  }
`;

const CardMainImageWrapper = styled.div`
  width: 100%;
  height: 152px;
`;

const CardMainImage = styled.img`
  border-radius: 8px 8px 0 0;
  object-fit: cover;
`;

const CardBody = styled.div`
  padding: 16px;
  overflow: hidden;
`;

const CardTitleWrapper = styled.div`
  height: 48px;
  margin-bottom: 16px;
`;

const CardTitle = styled(EllipsisBox)`
  ${typography.textCardTitle}
  color: ${theme.textPrimary};
  vertical-align: middle;
`;

const SupportAreas = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-content: stretch;
  align-items: baseline;
  height: 56px;
  margin-bottom: 4px;

  > :not(:first-child) {
    margin-left: 12px;
  }
`;

// https://stackoverflow.com/questions/28362688/css-display-inline-flex-and-text-overflow-ellipsis-not-working-togethier
const TagText = styled.div`
  overflow: hidden;
  text-align: center;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const CardFooter = styled.div`
  display: flex;
  align-items: center;
  height: 48;
`;

const CorporateName = styled.div`
  ${typography.textSub}
  width: 187px;
  padding-left: 4px;
  color: ${theme.textSecondary};
`;

export type SupporterCardProps = ComponentProps<typeof Card> & {
  supporter: Supporter;
};

const SupporterCard: FC<SupporterCardProps> = props => {
  const {
    supporterId,
    coverImage,
    iconImage,
    name,
    companyName,
    supportAreas,
  } = props.supporter;

  const sortedSupportArea = useMemo(
    () => sortedSupportAreas(supportAreas).slice(0, 3),
    [supportAreas],
  );

  const { i18n } = useTranslation();

  return (
    <StyledCard {...props} data-testid="supporter-card">
      <Link
        style={{ textDecoration: 'none' }}
        to={{
          pathname: `${PROTECTED_ROUTES.PRIVATE_SUPPORTERS}/${supporterId}`,
          state: { from: PROTECTED_ROUTES.PRIVATE_SUPPORTERS },
        }}
      >
        <CardMainImageWrapper>
          <CardMainImage src={imageSelector(coverImage, 'small')} alt={name} />
        </CardMainImageWrapper>
        <CardBody>
          <CardTitleWrapper>
            <CardTitle
              width={'240px'}
              line={2}
              maxHeight={'45px'}
              backgroundColor={theme.baseWhite}
            >
              {name}
            </CardTitle>
          </CardTitleWrapper>
          <SupportAreas>
            {sortedSupportArea.map(supportArea => (
              <SupportAreaTag
                key={supportArea.name}
                style={
                  i18n.language.startsWith('en')
                    ? { width: 'calc(50% - 2px)' }
                    : {}
                }
              >
                <TagText
                  title={i18n.language.startsWith('en') ? supportArea.name : ''}
                >
                  {supportArea.name}
                </TagText>
              </SupportAreaTag>
            ))}
          </SupportAreas>
          <CardFooter>
            <ImageCenter
              imageSrc={imageSelector(iconImage, 'small')}
              width={48}
              height={48}
              data-testid="supporter-card-icon-image"
            />
            <CorporateName>{companyName}</CorporateName>
          </CardFooter>
        </CardBody>
      </Link>
    </StyledCard>
  );
};

export default React.memo(
  SupporterCard,
  (prevProps, nextProps) => prevProps.supporter === nextProps.supporter,
);
