import isEqual from 'lodash.isequal';

export const isEqualProps = <T>(a: T, b: T, keys?: (keyof T)[]): boolean => {
  if (!keys) return isEqual(a, b);
  return !keys.some(key => !isEqual(a[key], b[key]));
};

export const isObject = (obj: any): boolean =>
  obj instanceof Object && Object.getPrototypeOf(obj) === Object.prototype;

export const removeEmptyValues = <T>(obj: T): Partial<T> =>
  Object.keys(obj).reduce((prev, key) => {
    const currentKey = key as keyof T;
    const currentValue = obj[currentKey];
    if (Array.isArray(currentValue) && currentValue.length === 0) {
      return prev;
    }
    if (!Array.isArray(currentValue) && !currentValue) {
      return prev;
    }
    return { ...prev, [currentKey]: currentValue };
  }, {});
