import { useRef, useCallback } from 'react';

export type AddedItemKey =
  | 'missionMembers'
  | 'missionProducts'
  | 'missionProductTags'
  | 'missionOfficeImages'
  | 'supporterMembers'
  | 'galleryImages'
  | 'missionJobOffers';

// サーバ側ですでに存在するデータと新しく追加するデータを区別するために、
// 新しく追加するデータのIDは負の値をセットするようにしている
export default function useGenerateKey() {
  const addedItemIdState = useRef(new Map<AddedItemKey, number>()).current;
  const getAddedItemId = useCallback(
    (key: AddedItemKey) => {
      const addedId = (addedItemIdState.get(key) || 0) - 1;
      addedItemIdState.set(key, addedId);
      return addedId;
    },
    [addedItemIdState],
  );
  return { getAddedItemId };
}

export type GenerateKeyMethods = ReturnType<typeof useGenerateKey>;
