import styled from 'styled-components/macro';
import theme from 'styles/theme';

const SquareTag = styled.div`
  box-sizing: border-box;
  margin-right: 4px;
  margin-bottom: 4px;
  padding: 7px 8px;
  border: 1px solid ${theme.labelDefault};
  border-radius: 5px;
  background-color: ${theme.baseWhite};
  color: ${theme.labelDefault};
  font-size: 14px;
  font-weight: bold;
  line-height: 14px;
`;

export default SquareTag;
