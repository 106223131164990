import { Supporter } from 'proto/v1/apimodel/apimodel';
import { useState, useMemo, useCallback, useRef, useEffect } from 'react';
import { userLanguage } from 'i18n/i18n';
import throttle from 'lodash.throttle';

const useSupporterDetail = (supporter: Supporter | undefined) => {
  const [visibleFooterTargetRef, setVisibleFooterTargetRef] = useState<
    HTMLButtonElement | HTMLDivElement | null
  >(null);
  const detailContainerRef = useRef<HTMLDivElement>(null);
  const [showFooter, setShowFooter] = useState<boolean>(false);
  const showTranslateButton = useMemo(
    () => userLanguage !== supporter?.language,
    [supporter],
  );
  const shouldShowFooter = useCallback(() => {
    if (!visibleFooterTargetRef) return false;
    return visibleFooterTargetRef.getBoundingClientRect().bottom < 0;
  }, [visibleFooterTargetRef]);

  const handleScroll = useCallback(
    throttle(() => {
      setShowFooter(shouldShowFooter());
    }, 50),
    [shouldShowFooter],
  );

  useEffect(() => {
    const detailContainer = detailContainerRef.current;
    if (supporter && detailContainer) {
      detailContainer.scrollTop = 0;
    }
  }, [supporter]);

  return {
    detailContainerRef,
    showFooter,
    showTranslateButton,
    handleScroll,
    setVisibleFooterTargetRef,
  };
};

export default useSupporterDetail;
