import styled from 'styled-components/macro';
import { FormItem } from 'components/molecules';

const MissionMembersFieldItem = styled(FormItem)`
  &&& {
    min-height: 0;
    margin-bottom: 8px;
  }

  &&&:last-child {
    margin-bottom: 0;
  }
`;

export default MissionMembersFieldItem;
