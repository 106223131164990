import { ComponentProps } from 'react';
import styled, { css } from 'styled-components/macro';
import { Checkbox } from 'visits-style';
import theme from 'styles/theme';

type StyledCheckboxProps = ComponentProps<typeof Checkbox>;
const StyledCheckbox = styled(Checkbox)<StyledCheckboxProps>`
  &&& {
    min-width: 115px;
    margin-right: 10px;
    margin-bottom: 10px;

    label {
      width: auto;
      margin-right: 0;
      padding-left: 0;

      div {
        margin-left: 0;
        transition: all 150ms ease-out;
        border: 1px solid ${theme.basePrimary};
        border-radius: 2px;
        background-color: ${theme.baseWhite};

        &.checked {
          border-color: ${theme.labelDefault};
          background: ${theme.labelDefault};
        }

        svg {
          opacity: 1;
          color: ${theme.baseWhite};
        }
      }
    }

    ${({ disabled }) =>
      disabled &&
      css`
        label {
          color: ${theme.baseDisabled};

          div {
            border-color: ${theme.baseDisabled};

            &.checked {
              border-color: ${theme.baseDisabled};
              background-color: ${theme.baseDisabled};
            }
          }
        }
        &:hover {
          cursor: default;
        }
      `}
  }
`;

export default StyledCheckbox;
