// eslint-disable-next-line
import { Images, Images_Image } from 'proto/v1/apimodel/apimodel';

type ImageSize = keyof Images;
// eslint-disable-next-line import/prefer-default-export
export const imageSelector = (
  imageObject: Images | undefined,
  imageSize: ImageSize = 'original',
) => {
  if (!imageObject) return;
  if (imageSize in imageObject)
    return (
      // eslint-disable-next-line
      imageObject[imageSize] && (imageObject[imageSize] as Images_Image).url
    );
};
