import React, {
  FC,
  useState,
  useRef,
  useEffect,
  useCallback,
  useMemo,
} from 'react';
import { onAuthStateChanged } from 'firebase/auth';
import axios, { CancelTokenSource } from 'axios';
import styled, { css } from 'styled-components/macro';
import theme from 'styles/theme';
import typography from 'styles/typography';
import { FiImage, FiX } from 'react-icons/fi';
import { useDispatch, useSelector } from 'react-redux';
import { getAccountId, getRole, getCanUseMissionMenu } from 'modules/account';
import { getIsSidebarShrinked, UiActions } from 'modules/ui';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';
import qs from 'qs';
import { Spinner } from 'visits-style';
import useMyAccounts from 'hooks/useMyAccounts';
import {
  getMyMissions,
  getMySupporters,
  getLoginAccountId,
} from 'modules/myAccounts';
import apiFirebase from 'external/firebase/firebase';
import * as PROTECTED_ROUTES from 'routes/constants/protected';
import { FlexContainer, ImageCircle, EllipsisBox } from 'components/atoms';
import { Overlay } from 'components/molecules';
import { GetMyAccountsResponse_Account as Account } from 'proto/v1/accountservice/accountservice';
import AccountList from './AccountList';
import SidebarTransition from './SidebarTransition';

/* stylelint-disable property-no-vendor-prefix */

// For IE
const FlexWrapper = styled.div``;

const MyAccountSelectorContainer = styled(FlexContainer)<{
  isShrinked: boolean;
}>`
  position: relative;
  width: 100%;
  margin-bottom: 16px;
  padding: 8px 12px;
  ${({ isShrinked }) =>
    isShrinked &&
    css`
      padding: 28px 4px 8px 4px;
    `}
`;

const SelectedAccount = styled(FlexContainer).attrs({
  alignItems: 'center',
  flexWrap: 'no-wrap',
})<{
  isShrinked: boolean;
  isDisabledMyAccountIcon: boolean;
}>`
  padding: 8px 14px 8px 8px;
  border-radius: 24px;
  background-color: ${theme.sidebarSelectedAccount};
  cursor: pointer;

  &:hover {
    background-color: ${theme.sidebarSelectedAccountHover};
  }

  ${({ isShrinked }) =>
    isShrinked &&
    css`
      display: flex;
      justify-content: center;
      padding: 6px;
    `}
  ${({ isShrinked, isDisabledMyAccountIcon }) =>
    isShrinked &&
    isDisabledMyAccountIcon &&
    css`
      cursor: auto;
      &:hover {
        background-color: ${theme.sidebarSelectedAccount};
      }
    `}
`;

const SelectedAccountIcon = styled.div`
  display: flex;
  align-items: center;
  height: 32px;

  &:nth-child(2) {
    flex: 1;
  }
`;

const SelectedAccountName = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  height: 32px;

  &.enter {
    display: none;
  }

  &.enter-done {
    display: flex;
  }

  &.exit {
    display: none;
  }

  &.exit-done {
    display: none;
  }
`;

const ActiveMyAccountIcon = styled.div<{ isDisabled: boolean }>`
  cursor: pointer;
  ${({ isDisabled }) =>
    isDisabled &&
    css`
      cursor: auto;
      pointer-events: none;
    `};
`;

const StyledImageCircle = styled(ImageCircle)`
  background-size: cover;
`;

const MyAccountImagePlaceholder = styled.div<{ size: number }>`
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;
  width: ${({ size }) => `${size}px`};
  height: ${({ size }) => `${size}px`};
  border-radius: 50%;
`;

const MyAccountName = styled(EllipsisBox).attrs({
  width: '136px',
  line: 2,
  maxHeight: '32px',
})`
  ${typography.textMyAccountSelect}
  margin: 0 8px;
  color: ${theme.baseWhite};
`;

const MyAccountListPanel = styled.div`
  position: absolute;
  z-index: 2;
  top: 56px;
  left: calc((100% - 204px) / 2);
  width: 204px;
  border-radius: 8px;
  background-color: ${theme.baseWhite};
`;

const StyledIconClose = styled(FiX)`
  position: absolute;
  z-index: 3;
  top: 18px;
  right: 14px;
  cursor: pointer;
`;

const MyAccountsSelector: FC = () => {
  const [showMyAccountList, setShowMyAccountList] = useState<boolean>(false);
  const [shouldFetchMyAccounts, setShouldFetchMyAccounts] = useState<boolean>(
    true,
  );
  const cancelTokenSourceRef = useRef<CancelTokenSource | undefined>(undefined);
  const {
    selectMyAccount,
    fetchMyAccountsBySelectedAccountId,
    fetchMyAccounts,
    isFetchingMyAccounts,
  } = useMyAccounts();
  const { t } = useTranslation('sidebar');
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const accountId = useSelector(getAccountId);
  const loginAccountId = useSelector(getLoginAccountId);
  const myMissions = useSelector(getMyMissions);
  const mySupporters = useSelector(getMySupporters);
  const role = useSelector(getRole);
  const isSidebarShrinked = useSelector(getIsSidebarShrinked);
  const canUseMissionMenu = useSelector(getCanUseMissionMenu);

  const myRoleAccounts = (() => {
    switch (role) {
      case 'mission':
        return myMissions;
      case 'supporter':
        return mySupporters;
      default:
        return [];
    }
  })();

  const selectedMyAccount = myRoleAccounts.find(
    myAccount => myAccount.accountId === accountId,
  );

  const parsedQuery: { accountId?: string } = qs.parse(location?.search, {
    ignoreQueryPrefix: true,
  });

  const queryAccountId = parsedQuery.accountId;

  const isDisabledMyAccountIcon = useMemo(() => {
    if (!selectedMyAccount?.published) return true;
    if (role === 'mission') return !canUseMissionMenu;
    if (role === 'supporter') return false;
    return true;
  }, [canUseMissionMenu, role, selectedMyAccount]);

  useEffect(() => {
    if (!shouldFetchMyAccounts || isFetchingMyAccounts) return;
    const cancelToken = axios.CancelToken;
    cancelTokenSourceRef.current = cancelToken.source();
    const unsubscribeAuthStateChanged = onAuthStateChanged(
      apiFirebase.auth(),
      user => {
        if (!user) {
          if (isFetchingMyAccounts) cancelTokenSourceRef.current?.cancel();
          return;
        }

        if (!shouldFetchMyAccounts || isFetchingMyAccounts) return;

        const selectedAccountId = loginAccountId || queryAccountId;

        const isActiveAccountId = selectedAccountId === accountId;

        if (selectedAccountId && !isActiveAccountId) {
          fetchMyAccountsBySelectedAccountId(
            selectedAccountId,
            cancelTokenSourceRef.current,
          );
        } else {
          fetchMyAccounts(cancelTokenSourceRef.current);
        }
        setShouldFetchMyAccounts(false);
      },
    );
    return () => {
      if (!shouldFetchMyAccounts || isFetchingMyAccounts) return;
      unsubscribeAuthStateChanged();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    loginAccountId,
    queryAccountId,
    shouldFetchMyAccounts,
    isFetchingMyAccounts,
  ]);

  // Cancel request when MyAccountsSelector is unmounted
  useEffect(() => {
    return () => {
      cancelTokenSourceRef.current?.cancel();
    };
  }, []);

  // Close MyAccountList when sidebar is shrinking.
  useEffect(() => {
    if (isSidebarShrinked) setShowMyAccountList(false);
  }, [isSidebarShrinked]);

  const moveToSelectedAccountDetail = useCallback(() => {
    if (!selectedMyAccount?.published) {
      return;
    }
    if (role === 'mission' && selectedMyAccount?.missionId) {
      history.push(
        `${PROTECTED_ROUTES.PRIVATE_MISSIONS}/${selectedMyAccount.missionId}`,
      );
    }
    if (role === 'supporter' && selectedMyAccount?.supporterId) {
      history.push(
        `${PROTECTED_ROUTES.PRIVATE_SUPPORTERS}/${selectedMyAccount.supporterId}`,
      );
    }
    if (isSidebarShrinked) {
      dispatch(UiActions.setIsSidebarShrinked(false));
    }
  }, [dispatch, history, isSidebarShrinked, role, selectedMyAccount]);

  const onClickItem = useCallback(
    (account: Account) => {
      if (accountId !== account.accountId) {
        selectMyAccount(account.accountId);
      }
      setShowMyAccountList(false);
    },
    [accountId, selectMyAccount],
  );

  return (
    <FlexWrapper>
      <MyAccountSelectorContainer
        flexDirection="column"
        alignItems="center"
        isShrinked={isSidebarShrinked}
      >
        <SelectedAccount
          data-testid="selected-account"
          isShrinked={isSidebarShrinked}
          isDisabledMyAccountIcon={isDisabledMyAccountIcon}
          onClick={() => {
            !isSidebarShrinked && setShowMyAccountList(!showMyAccountList);
          }}
        >
          <>
            {!selectedMyAccount && (
              <>
                <SelectedAccountIcon>
                  <Spinner size="28px" />
                </SelectedAccountIcon>
                <SidebarTransition isShrinked={isSidebarShrinked}>
                  <SelectedAccountName>
                    <MyAccountName>
                      {t('myAccountSelector.loading')}
                    </MyAccountName>
                  </SelectedAccountName>
                </SidebarTransition>
              </>
            )}
            {selectedMyAccount && (
              <>
                <SelectedAccountIcon>
                  <ActiveMyAccountIcon
                    isDisabled={isDisabledMyAccountIcon}
                    onClick={moveToSelectedAccountDetail}
                  >
                    {selectedMyAccount?.coverImage?.small ? (
                      <StyledImageCircle
                        imageSrc={selectedMyAccount.coverImage.small.url}
                        diameter={'28px'}
                        data-testid="my-accounts-selector-selected-mission-icon"
                      />
                    ) : (
                      <MyAccountImagePlaceholder
                        size={28}
                        data-testid="my-accounts-selector-selected-my-account-no-icon"
                      >
                        <FiImage size={18} color={theme.white} />
                      </MyAccountImagePlaceholder>
                    )}
                  </ActiveMyAccountIcon>
                </SelectedAccountIcon>
                <SidebarTransition isShrinked={isSidebarShrinked}>
                  <SelectedAccountName>
                    <MyAccountName data-testid="my-accounts-selector-selected-mission-name">
                      {selectedMyAccount?.name ||
                        t('myAccountSelector.noMissionName')}
                    </MyAccountName>
                  </SelectedAccountName>
                </SidebarTransition>
              </>
            )}
          </>
        </SelectedAccount>
        {showMyAccountList && (
          <>
            <Overlay onDismiss={() => setShowMyAccountList(false)} />
            <MyAccountListPanel data-testid="my-account-list">
              <StyledIconClose
                size={14}
                color={theme.textSecondary}
                onClick={() => setShowMyAccountList(false)}
              />
              {mySupporters.length > 0 && (
                <AccountList
                  containerKey={'supporters'}
                  title={t('myAccountSelector.supporterTitle')}
                  accountId={accountId}
                  accounts={mySupporters}
                  onClickItem={onClickItem}
                />
              )}
              {myMissions.length > 0 && (
                <AccountList
                  containerKey={'missions'}
                  title={t('myAccountSelector.missionTitle')}
                  accountId={accountId}
                  accounts={myMissions}
                  onClickItem={onClickItem}
                />
              )}
            </MyAccountListPanel>
          </>
        )}
      </MyAccountSelectorContainer>
    </FlexWrapper>
  );
};

export default MyAccountsSelector;
