import { css } from 'styled-components/macro';
import theme from 'styles/theme';
import typography from 'styles/typography';

const menuTextColor = theme.baseWhite;

export const menuButtonStyle = css`
  width: 100%;
  border: none;
  outline: none;
  background: none;
  appearance: none;
  text-align: left;
`;

export const menuItemStyle = css`
  ${typography.textDefault}
  display: block;
  padding: 14px 24px 13px 24px;
  color: ${menuTextColor};
  font-size: 13px;
  text-decoration: none;
  cursor: pointer;

  &:hover {
    background: ${theme.bgMenuHover};
    color: ${menuTextColor};
    text-decoration: none;
  }
`;

export const menuItemShrinkedStyle = css`
  padding: 14px 18px 13px 18px;
`;

export const accordionMenuItemStyle = css`
  ${menuItemStyle}
  padding: 10px 24px 9px 24px;
  font-size: 12px;
`;
