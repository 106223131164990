import React, { FC } from 'react';
import { Mission } from 'proto/v1/apimodel/apimodel';
import { DetailContentsLeftItem } from 'components/molecules';
import {
  StrengthPointField,
  MissionDescriptionField,
  ImageGalleryField,
} from 'components/organisms/Details/Common/Fields';
import { ProductCardsField } from 'components/organisms/Details/MissionDetail/Fields';
import { useTranslation } from 'react-i18next';

type AboutMissionContentProps = {
  mission: Mission;
  onClickProductCard: (productIndex: number) => void;
};

const AboutMissionContent: FC<AboutMissionContentProps> = ({
  mission,
  onClickProductCard,
}) => {
  const { t } = useTranslation('missionDetail');

  return (
    <>
      <DetailContentsLeftItem>
        <StrengthPointField
          label={t('field.strengthPoint.label')}
          strengthPoint={mission.strengthPoint}
        />
        <MissionDescriptionField
          label={t('field.missionDescription.label')}
          missionDescription={mission.missionDescription}
        />
      </DetailContentsLeftItem>

      {(mission.missionProducts.length > 0 ||
        mission.missionOfficeImages.length > 0) && (
        <DetailContentsLeftItem>
          {mission.missionProducts.length > 0 && (
            <ProductCardsField
              products={mission.missionProducts}
              onClickProduct={onClickProductCard}
            />
          )}
          {mission.missionOfficeImages.length > 0 && (
            <ImageGalleryField
              title={t('field.missionOfficeImages.label')}
              images={mission.missionOfficeImages.map(image => image.image)}
            />
          )}
        </DetailContentsLeftItem>
      )}
    </>
  );
};

export default AboutMissionContent;
