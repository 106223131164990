import styled from 'styled-components/macro';
import theme from 'styles/theme';

const TableRow = styled.tr`
  display: flex;

  & + & {
    border-top: 1px solid ${theme.borderTable};
  }

  &:hover {
    background-color: ${theme.tableHover};
    cursor: pointer;
  }
`;

export default TableRow;
