import React, { FC } from 'react';
import styled from 'styled-components/macro';
import useMissionFilter from 'components/pages/Private/Missions/useMissionFilter';
import { useTranslation } from 'react-i18next';
import {
  DemandPicker,
  CorporateAttributePicker,
  AddressPicker,
  CategoryPicker,
} from '..';

const Pickers = styled.div`
  display: flex;
  align-items: center;
  height: 68px;
`;

type MissionFilterProps = ReturnType<typeof useMissionFilter>;

const MissionFilter: FC<MissionFilterProps> = props => {
  const {
    form,
    missionsFilterValues,
    selectedPicker,
    togglePicker,
    closePicker,
    isActiveDemandPicker,
    isActiveCorporateAttributePicker,
    isActiveAddressPicker,
    isActiveCategoryPicker,
    handleDemandPickerSubmit,
    handleCorporateAttributePickerSubmit,
    handleAddressPickerSubmit,
    handleCategoryPickerSubmit,
  } = props;

  const { t } = useTranslation(['missionList', 'common']);

  return (
    <Pickers>
      <DemandPicker
        isActive={isActiveDemandPicker}
        isSelected={selectedPicker === 'demand'}
        onClickTag={() => togglePicker('demand')}
        onClickOverlay={closePicker}
        onSubmit={handleDemandPickerSubmit}
        form={{
          financingSeries: form?.financingSeries,
          demands: form?.demands,
        }}
        defaultValues={{
          demandIds: missionsFilterValues.demandIds,
          financingSeriesIds: missionsFilterValues.financingSeriesIds,
        }}
      />
      <CorporateAttributePicker
        isActive={isActiveCorporateAttributePicker}
        isSelected={selectedPicker === 'corporateAttribute'}
        onClickTag={() => togglePicker('corporateAttribute')}
        onClickOverlay={closePicker}
        onSubmit={handleCorporateAttributePickerSubmit}
        form={{ corporateAttributes: form?.corporateAttributes }}
        defaultValues={{
          corporateAttributeIds: missionsFilterValues.corporateAttributeIds,
        }}
      />
      <AddressPicker
        isActive={isActiveAddressPicker}
        isSelected={selectedPicker === 'address'}
        onClickTag={() => togglePicker('address')}
        onClickOverlay={closePicker}
        onSubmit={handleAddressPickerSubmit}
        form={{
          japanPrefectures: form?.japanPrefectures,
          countries: [
            {
              countryId: '',
              name: t('common:selectBox.notSelected'),
              phoneCountryCode: '',
            },
            ...(form?.countries || []),
          ],
        }}
        defaultValues={{
          countryId: missionsFilterValues.countryId,
          japanPrefectureIds: missionsFilterValues.japanPrefectureIds,
        }}
      />
      <CategoryPicker
        isActive={isActiveCategoryPicker}
        isSelected={selectedPicker === 'category'}
        onClickTag={() => togglePicker('category')}
        onClickOverlay={closePicker}
        onSubmit={handleCategoryPickerSubmit}
        form={{ categories: form?.missionCategories || [] }}
        defaultValues={{
          categoryIds: missionsFilterValues.categoryIds,
        }}
      />
    </Pickers>
  );
};

export default MissionFilter;
