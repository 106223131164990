import React, { FC, ComponentProps } from 'react';
import styled from 'styled-components/macro';
import { isEqualProps } from 'utils/object';
import { ImageUploader } from 'components/molecules';

const Wrapper = styled.div`
  margin-right: 16px;
`;

type ImageUploaderProps = ComponentProps<typeof ImageUploader>;

type IconImageFieldProps = Pick<
  ImageUploaderProps,
  'imageSrc' | 'onUpload' | 'onExceededFileSizeLimit'
>;

const IconImageField: FC<IconImageFieldProps> = props => {
  const { imageSrc, onUpload, onExceededFileSizeLimit } = props;

  return (
    <Wrapper>
      <ImageUploader
        imageSrc={imageSrc}
        onUpload={onUpload}
        onExceededFileSizeLimit={onExceededFileSizeLimit}
        width={100}
        height={100}
      />
    </Wrapper>
  );
};

export default React.memo(IconImageField, (prevProps, nextProps) =>
  isEqualProps(prevProps, nextProps, ['imageSrc']),
);
