import i18n from 'i18n/i18n';
import {
  FormExcludeLatestMessageWithinDays,
  FormMessageReplied,
} from 'types/matter';

export const EMPLOYMENT_TYPE_ID = {
  FULL_TIME: 1,
  OUT_SOURCING: 2,
  ADVISOR: 3,
} as const;

export const EMPLOYMENT_TYPE = {
  NEW_GRADUATE: 'NEW_GRADUATE',
  CAREER: 'CAREER',
  OUT_SOURCING: 'OUT_SOURCING',
  ADVISOR: 'ADVISOR',
} as const;

export const SELECTION_STEP_ID = {
  NO_SELECTED: 0,
  DOCUMENT_SCREENING: 1,
  FIRST_SELECTION: 2,
  SECONDARY_SELECTION: 3,
  THIRD_SELECTION: 4,
  FINAL_SELECTION: 5,
  UNOFFICIAL_OFFERED: 6,
  UNOFFICIAL_OFFER_CONSENTED: 7,
  REJECTED_SELECTION: 8,
  REFUSED_OFFER: 9,
} as const;

export const EMPLOYMENT_TYPE_LABEL = {
  [EMPLOYMENT_TYPE.NEW_GRADUATE]: i18n.t(
    'matter:employmentTypeTag.newGraduate',
    { lng: 'ja' },
  ),
  [EMPLOYMENT_TYPE.CAREER]: i18n.t('matter:employmentTypeTag.career', {
    lng: 'ja',
  }),
  [EMPLOYMENT_TYPE.OUT_SOURCING]: i18n.t(
    'matter:employmentTypeTag.outSourcing',
    {
      lng: 'ja',
    },
  ),
  [EMPLOYMENT_TYPE.ADVISOR]: i18n.t('matter:employmentTypeTag.advisor', {
    lng: 'ja',
  }),
} as const;

export const EXCLUDE_LATEST_MESSAGES_WITHIN_DAYS_NO_SELECT_VALUE = -1;

export const EXCLUDE_LATEST_MESSAGES_WITHIN_DAYS_FORM_ITEMS: FormExcludeLatestMessageWithinDays[] = [
  {
    id: 30,
    name: i18n.t(
      'humanResource:filter.field.excludeLatestMessageWithinDays.option.beforeOneMonth',
      { lng: 'ja' },
    ),
  },
  {
    id: 90,
    name: i18n.t(
      'humanResource:filter.field.excludeLatestMessageWithinDays.option.beforeThreeMonths',
      { lng: 'ja' },
    ),
  },
  {
    id: 180,
    name: i18n.t(
      'humanResource:filter.field.excludeLatestMessageWithinDays.option.beforeSixMonths',
      { lng: 'ja' },
    ),
  },
  {
    id: 365,
    name: i18n.t(
      'humanResource:filter.field.excludeLatestMessageWithinDays.option.beforeOneYear',
      { lng: 'ja' },
    ),
  },
  {
    id: 0,
    name: i18n.t(
      'humanResource:filter.field.excludeLatestMessageWithinDays.option.noScout',
      {
        lng: 'ja',
      },
    ),
  },
];

export const MESSAGE_REPLIED_FORM_ITEMS: FormMessageReplied[] = [
  {
    value: 'true',
    name: i18n.t('humanResource:filter.field.messageReplied.option.replied', {
      lng: 'ja',
    }),
  },
  {
    value: 'false',
    name: i18n.t(
      'humanResource:filter.field.messageReplied.option.notReplied',
      { lng: 'ja' },
    ),
  },
];
