import { useCallback } from 'react';
import { FormikTouched, FormikErrors } from 'formik';
import { MissionMember } from 'proto/v1/apimodel/apimodel';
import { MissionMemberFormValue, MissionMemberError } from 'types/form';
import ImageTypes from 'constants/imageTypes';
import { MissionSettingFormState } from './useMissionSettingFormState';
import { MissionSettingFormik } from './useMissionSettingFormik';

type Payload = {
  missionMembers: MissionMemberFormValue[];
  getAddedItemId: MissionSettingFormState['getAddedItemId'];
  openImageCropper: MissionSettingFormState['openImageCropper'];
  setFieldValue: MissionSettingFormik['setFieldValue'];
  setFieldTouched: MissionSettingFormik['setFieldTouched'];
  touched: FormikTouched<{
    missionMembers: MissionMemberFormValue[];
  }>['missionMembers'];
  errors: FormikErrors<{
    missionMembers: MissionMemberFormValue[];
  }>['missionMembers'];
};

const useMissionMembersField = ({
  missionMembers,
  getAddedItemId,
  openImageCropper,
  touched,
  errors,
  setFieldValue,
  setFieldTouched,
}: Payload) => {
  const handleChangeMissionMemberIconImage = useCallback(
    (image: string, index: number) => {
      const field = `missionMembers[${index}].iconImage`;
      openImageCropper(ImageTypes.MEMBER_ICON, image, index, field);
    },
    [openImageCropper],
  );

  const handleAddMember = useCallback(() => {
    setFieldValue(
      'missionMembers',
      missionMembers && [
        ...missionMembers,
        {
          missionMemberId: getAddedItemId('missionMembers'),
          name: '',
          career: '',
          iconImage: { data: '' },
        },
      ],
    );
  }, [getAddedItemId, missionMembers, setFieldValue]);

  const handleDeleteMember = useCallback(
    (targetIndex: number) => {
      if (!missionMembers || !Array.isArray(missionMembers)) {
        return;
      }

      setFieldValue(
        'missionMembers',
        missionMembers.filter(
          (_: MissionMember, index: number) => index !== targetIndex,
        ),
      );

      const prevMembersTouched = touched;

      if (prevMembersTouched && Array.isArray(prevMembersTouched)) {
        /*
        Generate touch values base on prev touch values,
        because touch values doesn't change automatically.
       */
        const deletedTouchedMembers = [...prevMembersTouched]
          .filter((tag, index) => index !== targetIndex)
          .reduce<FormikTouched<MissionMemberFormValue>[]>(
            (prev, itemOnArrayDeletedTarget, index) => {
              // @ts-ignore
              prev[index] =
                typeof itemOnArrayDeletedTarget === 'object'
                  ? { ...itemOnArrayDeletedTarget }
                  : prev[index];
              return prev;
            },
            // Process base on prev values(loop index and times are important)
            missionMembers.map(() => ({
              name: undefined,
              career: undefined,
              iconImage: undefined,
            })),
          );

        /*
          Should set shouldValidate-flag false.
          If set true will set invalid errors value.
          Read github PR #885.
        */
        // @ts-ignore
        setFieldTouched('missionMembers', deletedTouchedMembers, false);
      }
    },
    [missionMembers, setFieldTouched, setFieldValue, touched],
  );

  const isMissionMemberTouched = useCallback(
    (index: number) => {
      if (!touched || !touched[index]) {
        return false;
      }
      return (
        touched[index].career && touched[index].name && touched[index].iconImage
      );
    },
    [touched],
  );

  const missionMembersError = errors
    ? ((errors as MissionMemberError[]).map(
        (error: MissionMemberError, index: number) =>
          error && isMissionMemberTouched(index) ? error : undefined,
      ) as MissionMemberError[] | undefined)
    : undefined;

  return {
    handleAddMember,
    handleDeleteMember,
    handleChangeMissionMemberIconImage,
    missionMembersError,
  };
};

export default useMissionMembersField;
