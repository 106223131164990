import React, { forwardRef, PropsWithChildren } from 'react';
import styled, { css } from 'styled-components/macro';
import theme from 'styles/theme';
import { imageSelector } from 'utils/imageSelector';
import { Images } from 'proto/v1/apimodel/apimodel';
import { BackgroundImageWall } from 'components/atoms';

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  padding: 24px 32px 32px 32px;
  overflow: auto;
`;

type CardContainerProps = {
  marginBottom: number;
  isPreview: boolean;
};

const CardContainer = styled.div<CardContainerProps>`
  width: 1028px;
  margin-bottom: ${({ marginBottom }) => `${marginBottom}px`};
  ${({ isPreview }) =>
    isPreview
      ? css`
          margin-right: auto;
          margin-left: auto;
        `
      : css`
          padding-top: 50px;
        `}
`;

const Card = styled.div`
  width: 864px;
  overflow: hidden;
  border-radius: 8px;
  background: ${theme.baseWhite};
  box-shadow: 0 3px 8px rgba(0, 0, 0, 0.15);
  ${({ isPreview }: { isPreview: boolean }) =>
    isPreview &&
    css`
      margin: 0 auto;
    `}
`;

type DetailContainerProps = {
  backgroundImage: Images | undefined;
  marginBottom: number;
  onScroll: () => void;
  isPreview?: boolean;
};

const DetailContainer = forwardRef<
  HTMLDivElement,
  PropsWithChildren<DetailContainerProps>
>((props, ref) => {
  const { backgroundImage, marginBottom, onScroll, isPreview = false } = props;

  return (
    <BackgroundImageWall
      backgroundImage={imageSelector(backgroundImage, 'large')}
    >
      <Wrapper onScroll={onScroll} ref={ref} data-testid="detail-container">
        <CardContainer isPreview={isPreview} marginBottom={marginBottom}>
          <Card isPreview={isPreview}>{props.children}</Card>
        </CardContainer>
      </Wrapper>
    </BackgroundImageWall>
  );
});

export default DetailContainer;
