import React, { FC } from 'react';
import styled from 'styled-components/macro';
import { TableCell, ButtonS } from 'components/atoms';
import { useTranslation } from 'react-i18next';
import typography from 'styles/typography';
import theme from 'styles/theme';

const StyledTableCell = styled(TableCell)`
  position: relative;
  justify-content: center;
`;

const MessageButton = styled(ButtonS)`
  min-width: 144px;
`;

const ConfirmMessage = styled.div`
  ${typography.textSub};
  position: absolute;
  top: calc(50% + 16px + 8px);
  left: 0;
  width: 100%;
  color: ${theme.textSecondary};
  text-align: center;
  text-decoration: underline;
  cursor: pointer;
`;

type MessageButtonCellProps = {
  isFollowed: boolean;
  hasContactMessage: boolean;
  onClickStartChatButton: () => void;
  onClickConfirmMessage: () => void;
  cellWidth: string;
};

const MessageButtonCell: FC<MessageButtonCellProps> = props => {
  const {
    isFollowed,
    hasContactMessage,
    onClickStartChatButton,
    onClickConfirmMessage,
    cellWidth,
  } = props;

  const { t } = useTranslation(['followingMissions']);
  return (
    <StyledTableCell cellWidth={cellWidth}>
      {isFollowed ? (
        <MessageButton
          buttonTheme="default"
          onClick={onClickStartChatButton}
          data-testid="followee-list-message-button"
        >
          {t('button.message')}
        </MessageButton>
      ) : (
        <MessageButton buttonTheme="default" disabled>
          {t('button.contacted')}
        </MessageButton>
      )}
      {hasContactMessage && !isFollowed && (
        <ConfirmMessage onClick={onClickConfirmMessage}>
          {t('cell.confirmMessage')}
        </ConfirmMessage>
      )}
    </StyledTableCell>
  );
};

export default MessageButtonCell;
