import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { ButtonM } from 'components/atoms';
import { Modal, ModalContent, ModalFooter } from 'components/molecules';

type DeleteCompleteModalProps = {
  show: boolean;
  onClose: () => void;
};

const DeleteCompleteModal: FC<DeleteCompleteModalProps> = ({
  show,
  onClose,
}) => {
  const { t } = useTranslation('missionSetting');
  return (
    <Modal show={show} closeModal={onClose}>
      <ModalContent data-testid="modal-delete-complete">
        {t('modal.deleted.content')}
      </ModalContent>
      <ModalFooter>
        <ButtonM
          onClick={onClose}
          buttonTheme="default"
          type="button"
          data-testid="modal-delete-complete-button"
        >
          {t('common:button.ok')}
        </ButtonM>
      </ModalFooter>
    </Modal>
  );
};

export default DeleteCompleteModal;
