import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import languageDetector from './languageDetector';
import ja from './locales/ja';
import en from './locales/en';

const DEFAULT_LANGUAGE = 'en';

export const resources = {
  en,
  ja,
};

type ResourcesLanguage = keyof typeof resources;

export const isResourcesLanguage = (
  language: string,
): language is ResourcesLanguage => Object.keys(resources).includes(language);

i18n
  .use(languageDetector)
  .use(initReactI18next)
  .init({
    detection: {
      order: ['customPath', 'navigator'],
    },
    defaultNS: 'common',
    fallbackNS: 'common',
    resources: {
      en,
      ja,
    },
    fallbackLng: DEFAULT_LANGUAGE,
    load: 'languageOnly',
  });

export const userLanguage = isResourcesLanguage(i18n.languages[0])
  ? i18n.languages[0]
  : DEFAULT_LANGUAGE;

const firstPath = window.location.pathname.split('/')[1];
export const localePath = isResourcesLanguage(firstPath) ? `/${firstPath}` : '';

export default i18n;
