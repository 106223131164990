import qs from 'qs';

export const getMineTypeFromDataURI = (dataUri: string) =>
  dataUri.split(',')[0].replace(/(data:|;base64)/g, '');

export const bytes = (text: string) =>
  encodeURIComponent(text).replace(/%../g, 'x').length;

// Count byte point
// half-width(1byte, 2byte) -> 1 point
// full-width(3byte, 4byte) -> 2 point
export const countBytePoints = (text: string) =>
  [...text].reduce((p, c) => (bytes(c) > 2 ? p + 2 : p + 1), 0);

export const removeQueryStringProps = (
  queryString: string,
  ...deleteKeys: string[]
) => {
  const query = qs.parse(queryString);
  const removedPropsQuery = Object.keys(query).reduce<{ [key: string]: any }>(
    (a, c) => {
      if (!deleteKeys.includes(c)) a[c] = query[c];
      return a;
    },
    {},
  );
  return qs.stringify(removedPropsQuery);
};
