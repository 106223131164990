import React, { FC } from 'react';
import styled from 'styled-components/macro';
import { useTranslation } from 'react-i18next';
import { ButtonM, TranslatedByGoogleBadge } from 'components/atoms';

const TranslateButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 32px;
  padding-top: 12px;
`;

const TranslateButton = styled(ButtonM).attrs({ buttonTheme: 'cancel' })`
  width: 508px;
  height: 40px;
`;

const StyledTranslatedByGoogleBadge = styled(TranslatedByGoogleBadge)`
  width: 120px;
  margin-left: 6px;
`;

type TranslateButtonContentProps = {
  isTranslated: boolean;
  onToggleTranslate?: () => void;
};

const TranslateButtonContent: FC<TranslateButtonContentProps> = props => {
  const { isTranslated, onToggleTranslate } = props;
  const { t } = useTranslation('missionDetail');
  return (
    <TranslateButtonContainer>
      <TranslateButton
        onClick={onToggleTranslate}
        data-testid="translate-button"
      >
        {isTranslated ? (
          <>
            {t('button.unTranslate')}
            <StyledTranslatedByGoogleBadge />
          </>
        ) : (
          <>{t('button.translate')}</>
        )}
      </TranslateButton>
    </TranslateButtonContainer>
  );
};

export default TranslateButtonContent;
