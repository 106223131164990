import { useCallback } from 'react';
import { MissionSettingFormState } from './useMissionSettingFormState';

type Payload = {
  setShowMenu: MissionSettingFormState['setShowMenu'];
  openModal: MissionSettingFormState['openModal'];
};

const useMenu = ({ setShowMenu, openModal }: Payload) => {
  const openMenu = useCallback(() => {
    setShowMenu(true);
  }, [setShowMenu]);

  const closeMenu = useCallback(() => {
    setShowMenu(false);
  }, [setShowMenu]);

  const handleClickMenuUnPublish = useCallback(() => {
    setShowMenu(false);
    openModal('unPublishMissionConfirmation');
  }, [openModal, setShowMenu]);

  return {
    openMenu,
    closeMenu,
    handleClickMenuUnPublish,
  };
};

export default useMenu;
