import { useEffect, useCallback, useState, useMemo } from 'react';
import { onAuthStateChanged } from 'firebase/auth';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { UiActions } from 'modules/ui';
import ErrorCodes from 'constants/errorCodes';
import {
  CareerMattersFilterValues,
  MatterForBulkScout,
  NewGraduateMattersFilterValues,
} from 'types/matter';
import apiMatter from 'external/api/matter';
import apiFirebase from 'external/firebase/firebase';
import axios from 'axios';
import RequestError from 'classes/RequestError';
import useErrorDialog from 'hooks/useErrorDialog';
import {
  convertToCareerMattersFilterRequest,
  convertToNewGraduateMattersFilterRequest,
} from 'utils/models/matter';
import { Matter, MessageTemplate } from 'proto/v1/apimodel/apimodel';

type Payload = {
  shouldFetchAll: boolean;
  matterPublishIds: Matter['matterPublishId'][];
  excludeMatterPublishIds: Matter['matterPublishId'][];
  onBulkScout: (
    matterIds: Matter['matterId'][],
    message: string,
  ) => Promise<void>;
  onShowExceedMaxBulkScoutError: () => void;
  onClose: () => void;
  careerFilterValues: CareerMattersFilterValues | undefined;
  newGraduateFilterValues: NewGraduateMattersFilterValues | undefined;
};

const useBulkScoutScreenContent = ({
  shouldFetchAll,
  careerFilterValues,
  newGraduateFilterValues,
  matterPublishIds,
  excludeMatterPublishIds,
  onBulkScout,
  onShowExceedMaxBulkScoutError,
  onClose,
}: Payload) => {
  const { t } = useTranslation(['error']);
  const dispatch = useDispatch();
  const { handleRequestError } = useErrorDialog();

  const [matters, setMatters] = useState<MatterForBulkScout[]>([]);
  const [selectedMatterIds, setSelectedMatterIds] = useState<
    Matter['matterId'][]
  >([]);
  const [humanResourceDetailState, setHumanResourceDetailState] = useState<{
    show: boolean;
    matter?: Matter;
  }>({ show: false });

  const source = useMemo(() => axios.CancelToken.source(), []);

  const allMatterIds = useMemo(() => matters.map(matter => matter.matterId), [
    matters,
  ]);

  const isCheckedSelectAll = useMemo(
    () => selectedMatterIds.length === allMatterIds.length,
    [allMatterIds.length, selectedMatterIds.length],
  );

  const fetchMatters = useCallback(async () => {
    dispatch(UiActions.setLoading(true));
    let data;
    try {
      ({ data } = await apiMatter.getMattersForBulkScout(
        {
          all: shouldFetchAll
            ? {
                careerMattersFilter: careerFilterValues
                  ? convertToCareerMattersFilterRequest(careerFilterValues)
                  : undefined,
                newGraduateMattersFilter: newGraduateFilterValues
                  ? convertToNewGraduateMattersFilterRequest(
                      newGraduateFilterValues,
                    )
                  : undefined,
                excludeMatterPublishIds,
              }
            : undefined,
          ids: shouldFetchAll
            ? undefined
            : {
                matterPublishIds,
              },
        },
        source.token,
      ));
    } catch (error: unknown) {
      if (error instanceof RequestError) {
        if (
          error.errorCode ===
          ErrorCodes.FAILED_TO_GET_MATTER_FOR_BULK_SCOUT_BECAUSE_OF_EXCEEDING_MAX_BULK_SCOUT_COUNT
        ) {
          onShowExceedMaxBulkScoutError();
        } else {
          handleRequestError(error, t('error:failedGettingAny'));
          onClose();
        }
      }
      dispatch(UiActions.setLoading(false));
      return;
    }
    setMatters(data.matters);
    dispatch(UiActions.setLoading(false));
  }, [
    careerFilterValues,
    dispatch,
    excludeMatterPublishIds,
    handleRequestError,
    matterPublishIds,
    newGraduateFilterValues,
    onClose,
    onShowExceedMaxBulkScoutError,
    shouldFetchAll,
    source.token,
    t,
  ]);

  const handleChangeSelectAll = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const { checked } = e.currentTarget;
      setSelectedMatterIds(checked ? allMatterIds : []);
    },
    [allMatterIds],
  );

  const handleChangeHumanResourceCheckBox = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const { checked, value } = e.currentTarget;
      if (checked) {
        setSelectedMatterIds(prevIds => [...prevIds, value]);
      } else {
        setSelectedMatterIds(prevIds => prevIds.filter(id => id !== value));
      }
    },
    [],
  );

  const handleUseMessageTemplate = useCallback(
    async (messageTemplate: MessageTemplate) => messageTemplate.template,
    [],
  );

  const handleScout = useCallback(
    async (message: string) => {
      onBulkScout(selectedMatterIds, message);
    },
    [onBulkScout, selectedMatterIds],
  );

  const handleClickHumanResourceItem = useCallback(
    async (matterId: MatterForBulkScout['matterId']) => {
      dispatch(UiActions.setLoading(true));
      let data;
      try {
        ({ data } = await apiMatter.getMatter({
          matterId,
        }));
      } catch (error) {
        if (error instanceof RequestError) {
          const errorMessage =
            error.errorCode === ErrorCodes.MATTER_NOT_FOUND
              ? t('error:humanResourceNotFound')
              : t('error:failedGettingAny');
          handleRequestError(error, errorMessage);
        }
        dispatch(UiActions.setLoading(false));
        return;
      }
      setHumanResourceDetailState({ show: true, matter: data.matter });
      dispatch(UiActions.setLoading(false));
    },
    [dispatch, handleRequestError, t],
  );

  const handleExitedHumanResourceDetail = useCallback(() => {
    setHumanResourceDetailState(prev => ({ ...prev, matter: undefined }));
  }, []);

  const handleCloseHumanResourceDetail = useCallback(() => {
    setHumanResourceDetailState(prev => ({ ...prev, show: false }));
  }, []);

  // Fetch matters
  useEffect(() => {
    const unsubscribeAuthStateChanged = onAuthStateChanged(
      apiFirebase.auth(),
      user => {
        if (user) {
          fetchMatters();
        } else {
          source?.cancel();
        }
      },
    );

    return () => {
      unsubscribeAuthStateChanged();
      source?.cancel();
    };
  }, [dispatch, fetchMatters, source]);

  // Set all matter ids to selected matter ids default.
  useEffect(() => {
    setSelectedMatterIds(allMatterIds);
  }, [allMatterIds]);

  return {
    matters,
    isCheckedSelectAll,
    handleChangeSelectAll,
    selectedMatterIds,
    handleChangeHumanResourceCheckBox,
    handleUseMessageTemplate,
    handleScout,
    humanResourceDetailState,
    handleClickHumanResourceItem,
    handleExitedHumanResourceDetail,
    handleCloseHumanResourceDetail,
  };
};

export default useBulkScoutScreenContent;
