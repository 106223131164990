import React, { FC } from 'react';
import { useLocation } from 'react-router-dom';
import styled from 'styled-components/macro';
import * as PUBLIC_ROUTES from 'routes/constants/public';
import { Logo } from 'assets/svg';
import { bgLogin } from 'assets/jpg';
import { Footer } from 'components/organisms';
import { FlexContainer } from 'components/atoms';

const Contents = styled.section`
  width: 100%;
`;

const Wrapper = styled(FlexContainer)<{ pathName: string }>`
  min-height: 100vh;
  overflow: auto;
  ${({ pathName }) =>
    pathName === PUBLIC_ROUTES.LOGIN &&
    ` position: relative;
      background: url(${bgLogin}) repeat 0 0/cover;
      background-position-y: -50px;
      z-index: 1;
      &::before {
        content: "";
        position: absolute;
        z-index: 2;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        background: rgba(27, 34, 35, 0.6);
      }
      `}
`;

const StyledContainer = styled.div`
  position: relative;
  z-index: 3;
  margin-top: auto;
  margin-bottom: auto;
`;

const LogoWrapper = styled.div`
  margin: 32px 0;
  text-align: center;
`;

const StyledLogo = styled(Logo)`
  width: 90px;
  height: 32px;
  fill: #242424;
`;

const PublicRouteLayout: FC<{}> = props => {
  const location = useLocation();
  return (
    <Wrapper
      alignItems="center"
      justifyContent="space-between"
      flexDirection="column"
      flexWrap="no-wrap"
      pathName={location.pathname}
      {...props}
    >
      <StyledContainer>
        {location.pathname !== PUBLIC_ROUTES.LOGIN &&
          location.pathname !== PUBLIC_ROUTES.TERMS && (
            <LogoWrapper>
              <StyledLogo />
            </LogoWrapper>
          )}
        <Contents>{props.children}</Contents>
      </StyledContainer>
      <Footer />
    </Wrapper>
  );
};

export default PublicRouteLayout;
