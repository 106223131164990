import React, { FC, useState, useCallback, useEffect, useRef } from 'react';
import { keyframes } from 'styled-components';
import styled from 'styled-components/macro';
import { TableCell, ButtonS } from 'components/atoms';
import { useTranslation } from 'react-i18next';
import typography from 'styles/typography';
import theme from 'styles/theme';

const StyledTableCell = styled(TableCell)`
  position: relative;
  justify-content: center;
`;

const scaleUpAnimation = keyframes`
  0% {
    transform: scale(1);
  }

  50% {
    transform: scale(1.1);
  }

  100% {
    transform: scale(1);
  }
`;

const FOLLOW_BACK_ANIMATION_CLASS_NAME = 'canStartChatNow';
const MessageButton = styled(ButtonS)`
  min-width: 144px;
  &.${FOLLOW_BACK_ANIMATION_CLASS_NAME} {
    animation: ${scaleUpAnimation} 0.7s;
  }
`;

const ConfirmMessage = styled.div`
  ${typography.textSub};
  position: absolute;
  top: calc(50% + 16px + 8px);
  left: 0;
  width: 100%;
  text-align: center;
  text-decoration: underline;
  color: ${theme.textSecondary};
  cursor: pointer;
`;

type AcceptButtonCellProps = {
  isFollowing: boolean;
  hasContactMessage: boolean;
  onClickAcceptButton: () => void;
  onClickStartChatButton: () => void;
  onClickConfirmMessage: () => void;
  cellWidth: string;
};

const AcceptButtonCell: FC<AcceptButtonCellProps> = props => {
  const {
    isFollowing,
    hasContactMessage,
    onClickAcceptButton,
    onClickStartChatButton,
    onClickConfirmMessage,
    cellWidth,
  } = props;

  const { t } = useTranslation(['followersMissions']);
  const [isFollowingBack, setIsFollowingBack] = useState(false);
  const buttonRef = useRef<HTMLButtonElement>();

  const onButtonAnimationEnd = useCallback(() => {
    setIsFollowingBack(false);
  }, [setIsFollowingBack]);

  useEffect(() => {
    const buttonCurrent = buttonRef.current;
    buttonCurrent?.addEventListener('animationend', onButtonAnimationEnd);
    return () => {
      buttonCurrent?.removeEventListener('animationend', onButtonAnimationEnd);
    };
  }, [onButtonAnimationEnd]);

  const onButtonClick = useCallback(() => {
    if (!isFollowing) {
      setIsFollowingBack(true);
      onClickAcceptButton();
    } else {
      onClickStartChatButton();
    }
  }, [isFollowing, onClickAcceptButton, onClickStartChatButton]);

  return (
    <StyledTableCell cellWidth={cellWidth}>
      <MessageButton
        buttonTheme="default"
        ref={buttonRef}
        onClick={onButtonClick}
        className={
          isFollowing && isFollowingBack ? FOLLOW_BACK_ANIMATION_CLASS_NAME : ''
        }
        data-testid="follower-list-accept-or-message-button"
      >
        {!isFollowing ? t('button.accept') : t('button.message')}
      </MessageButton>
      {hasContactMessage && !isFollowing && (
        <ConfirmMessage onClick={onClickConfirmMessage}>
          {t('cell.confirmMessage')}
        </ConfirmMessage>
      )}
    </StyledTableCell>
  );
};

export default AcceptButtonCell;
