import React, { useCallback } from 'react';
import styled, { css } from 'styled-components/macro';
import { useTranslation } from 'react-i18next';
import { formatDate } from 'utils/time';
import { formatHumanResourceName } from 'utils/models/matter';
import { Matter } from 'proto/v1/apimodel/apimodel';
import theme from 'styles/theme';
import typography from 'styles/typography';
import { Checkbox, FavoriteIcon } from 'components/atoms';
import {
  EmploymentTypeTag,
  HumanResourceCardHeadLatestMessageWithin,
} from 'components/molecules';

const Wrapper = styled.div<{ isViewed: boolean; isDetail: boolean }>`
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 22px 24px 10px 12px;
  border-bottom: 1px solid ${theme.borderDefault};
  background-color: ${theme.bgAdd};
  ${({ isViewed }) =>
    isViewed &&
    css`
      background-color: ${theme.baseDisabled};
    `}
  ${({ isDetail }) =>
    isDetail &&
    css`
      padding-left: 20px;
    `}
`;

const HeadLeft = styled.div`
  display: flex;
`;

const HeadLeftLeading = styled.div`
  display: flex;
  gap: 12px;
  margin-right: 14px;
`;

const HeadLeftContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const HeadRight = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const HumanResourceNameContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 4px;
  color: ${theme.textPrimary};
`;

const CheckBoxWrapper = styled.div`
  display: inline-block;
`;

const StyledCheckBox = styled(Checkbox)`
  &&& {
    min-width: auto;
    margin-right: 0;
    margin-bottom: 0;

    div {
      margin: 0;
    }
  }
`;

const StyledFavoriteIcon = styled(FavoriteIcon)<{ isDetail: boolean }>`
  ${({ isDetail }) =>
    !isDetail &&
    css`
      position: relative;
      bottom: 6px;
    `}
`;

const HumanResourceNameLabel = styled.div`
  width: 4px;
  height: 24px;
  margin-right: 12px;
  border-radius: 1px;
  background-color: ${theme.labelDefault};
`;

const HumanResourceName = styled.div`
  margin-right: 16px;
  font-size: 18px;
  font-weight: bold;
  line-height: 1;
`;

const HumanResourceKanaName = styled.div`
  ${typography.textDefault}
  margin-right: 16px;
  color: ${theme.textPrimary};
`;

const BirthYearMonth = styled.div`
  ${typography.textDefault}
  color: ${theme.textPrimary};
`;

const AcademicBackground = styled.div<{ isDetail: boolean }>`
  display: flex;
  align-items: center;
  color: ${theme.textPrimary};
  ${({ isDetail }) =>
    isDetail &&
    css`
      padding-left: 16px;
    `}
`;

const AcademicBackgroundSchool = styled.div`
  margin-right: 12px;
  font-size: 18px;
  font-weight: bold;
`;

const AcademicBackgroundFaculty = styled.div`
  margin-right: 12px;
  font-size: 16px;
`;

const AcademicBackgroundDepartment = styled.div`
  margin-right: 12px;
  font-size: 16px;
`;

const AcademicBackgroundGraduation = styled.div`
  font-size: 16px;
`;

const StyledEmploymentTag = styled(EmploymentTypeTag)`
  display: inline-block;
  margin-bottom: 8px;
  border: none;
  white-space: nowrap;
`;

const HeadRightTop = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: flex-end;
`;

const HeadRightBottom = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const PublishedAt = styled.div`
  color: ${theme.textPrimaryHover};
  font-size: 12px;
  line-height: 17px;
`;

const LatestMessageSentAt = styled.div`
  margin-right: 8px;
  padding-top: 2px;
  color: ${theme.textPrimaryHover};
  font-size: 12px;
  line-height: 17px;
`;

type Props = {
  matter: Matter;
  isDetail?: boolean;
  isChecked?: boolean;
  onChangeChecked?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  isFavorite?: boolean;
  onClickFavorite?: (matter: Matter, favorite: boolean) => void;
};

const NewGraduateHeadField: React.VFC<Props> = ({
  matter,
  isDetail = false,
  isChecked,
  onChangeChecked,
  isFavorite,
  onClickFavorite,
}) => {
  const { humanResource } = matter;
  const { t } = useTranslation(['matter']);

  const handleClickFavoriteIcon = useCallback(
    (e: React.MouseEvent<HTMLDivElement>) => {
      e.stopPropagation();
      onClickFavorite && onClickFavorite(matter, !isFavorite);
    },
    [isFavorite, matter, onClickFavorite],
  );

  return (
    <Wrapper isViewed={!isDetail && matter.viewed} isDetail={isDetail}>
      <HeadLeft>
        <HeadLeftLeading>
          {!isDetail && (
            <CheckBoxWrapper onClick={e => e.stopPropagation()}>
              <StyledCheckBox
                name="matterPublishIds"
                value={matter.matterPublishId}
                checked={isChecked}
                onChange={onChangeChecked}
                data-testid="human-resource-checkbox"
              />
            </CheckBoxWrapper>
          )}
          <StyledFavoriteIcon
            onClick={handleClickFavoriteIcon}
            isFavorite={!!isFavorite}
            isDetail={isDetail}
          />
        </HeadLeftLeading>
        <HeadLeftContent>
          <HumanResourceNameContainer>
            {isDetail && <HumanResourceNameLabel />}
            <HumanResourceName>
              {formatHumanResourceName(humanResource)}
            </HumanResourceName>
            {humanResource?.lastNameKana && humanResource?.firstNameKana ? (
              <HumanResourceKanaName data-testid="human-resource-kana">{`${humanResource?.lastNameKana}${humanResource?.firstNameKana}`}</HumanResourceKanaName>
            ) : null}
            {humanResource?.yearOfBirth && humanResource?.monthOfBirth ? (
              <BirthYearMonth>
                {t('field.birthYearMonth.yearMonth', {
                  year: humanResource.yearOfBirth,
                  month: humanResource.monthOfBirth,
                  lng: 'ja',
                })}
              </BirthYearMonth>
            ) : null}
          </HumanResourceNameContainer>
          <AcademicBackground isDetail={isDetail}>
            <AcademicBackgroundSchool>
              {humanResource?.academicBackground?.school?.name}
            </AcademicBackgroundSchool>
            <AcademicBackgroundFaculty>
              {humanResource?.academicBackground?.faculty}
            </AcademicBackgroundFaculty>
            <AcademicBackgroundDepartment>
              {humanResource?.academicBackground?.department?.name}
            </AcademicBackgroundDepartment>
            <AcademicBackgroundGraduation>
              (
              {t('field.academicBackgroundNewGraduate.yearMonth', {
                year: humanResource?.academicBackground?.yearOfGraduation,
                month: humanResource?.academicBackground?.monthOfGraduation,
                lng: 'ja',
              })}
              )
            </AcademicBackgroundGraduation>
          </AcademicBackground>
        </HeadLeftContent>
      </HeadLeft>

      <HeadRight>
        <HeadRightTop>
          {!isDetail && !!matter.latestMessageSentAt && (
            <HumanResourceCardHeadLatestMessageWithin
              latestMessageSentAt={matter.latestMessageSentAt}
            />
          )}
          {isDetail && !!matter.latestMessageSentAt && (
            <LatestMessageSentAt>
              {t('field.latestMessageSentAt.label', { lng: 'ja' })}：
              {formatDate(new Date(matter.latestMessageSentAt), 'yyyy/MM/dd')}
            </LatestMessageSentAt>
          )}
          <StyledEmploymentTag
            employmentTypeId={
              matter?.humanResource?.career?.preferredEmploymentType?.id
            }
            isNewGraduate={!!matter?.humanResource?.newGraduate}
            isPublished={!!matter?.publishedAt}
          />
        </HeadRightTop>
        <HeadRightBottom>
          <PublishedAt>
            {t('field.publishedAt.label', { lng: 'ja' })}：
            {formatDate(new Date(matter.publishedAt), 'yyyy/MM/dd')}
          </PublishedAt>
        </HeadRightBottom>
      </HeadRight>
    </Wrapper>
  );
};

export default NewGraduateHeadField;
