import React from 'react';
import { Mission } from 'proto/v1/apimodel/apimodel';
import { DetailContentsLeftItem } from 'components/molecules';
import { MissionJobOffersField } from 'components/organisms/Details/MissionDetail/Fields';

type Props = {
  missionJobOffers: Mission['missionJobOffers'];
};

const MissionJobOffersContent: React.VFC<Props> = ({ missionJobOffers }) => {
  return (
    <DetailContentsLeftItem data-testid="mission-job-offers-content">
      <MissionJobOffersField missionJobOffers={missionJobOffers} />
    </DetailContentsLeftItem>
  );
};

export default MissionJobOffersContent;
