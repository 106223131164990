import React, { FC } from 'react';
import styled from 'styled-components/macro';
import { useTranslation } from 'react-i18next';
import { isEqualProps } from 'utils/object';
import { ButtonContainer, ButtonXL } from 'components/atoms';

const Wrapper = styled.div<{ width: number | undefined }>`
  position: absolute;
  bottom: 0;
  width: ${({ width }) => (width ? `${width + 32}px` : '100%')};
  margin-left: -32px;
  padding: 24px;
  background-color: rgba(255, 255, 255, 0.75);
`;

type FooterContentProps = {
  onClick: () => void;
  disabled: boolean;
  width: number | undefined;
};

const FooterContent: FC<FooterContentProps> = props => {
  const { onClick, disabled, width } = props;
  const { t } = useTranslation('missionSetting');

  return (
    <Wrapper width={width}>
      <ButtonContainer className="container">
        <ButtonXL buttonTheme="gradation" onClick={onClick} disabled={disabled}>
          {t('button.publish')}
        </ButtonXL>
      </ButtonContainer>
    </Wrapper>
  );
};

export default React.memo(FooterContent, (prevProps, nextProps) =>
  isEqualProps(prevProps, nextProps, ['disabled', 'width']),
);
