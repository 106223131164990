import React, { FC, useState, useMemo, useCallback } from 'react';
import styled from 'styled-components/macro';
import { TableCell } from 'components/atoms';
import InterestTag from 'components/molecules/InterestTag';
import theme from 'styles/theme';
import { ExpandListItem, CollapseListItem } from 'assets/svg';

const StyledTableCell = styled(TableCell)`
  position: relative;
  justify-content: center;
`;

const InterestList = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
`;

const StyledTag = styled(InterestTag)`
  width: 100%;
  margin-bottom: 8px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const Expander = styled.div`
  position: absolute;
  bottom: 5px;
  left: 0;
  width: 100%;
  color: ${theme.labelRequest};
  font-size: 10px;
  line-height: 1.5;
  text-align: center;
  cursor: pointer;
`;

type InterestCellProps = {
  interests?: { name: string }[];
  cellWidth?: string;
};

const ITEMS_COUNT_ON_COLLAPSED_STATE = 3;

const InterestCell: FC<InterestCellProps> = ({ interests, cellWidth }) => {
  const showExpander = useMemo(
    () => !!interests && interests.length > ITEMS_COUNT_ON_COLLAPSED_STATE,
    [interests],
  );
  const [expanded, setExpanded] = useState(false);
  const displayInterestItems = useMemo(
    () =>
      expanded && interests
        ? interests ?? []
        : (interests ?? []).slice(0, ITEMS_COUNT_ON_COLLAPSED_STATE),
    [expanded, interests],
  );
  const handleClickExpander = useCallback(() => setExpanded(!expanded), [
    expanded,
  ]);

  return (
    <StyledTableCell cellWidth={cellWidth}>
      <InterestList>
        {displayInterestItems.map(interest => (
          <StyledTag key={interest.name}>{interest.name}</StyledTag>
        ))}
      </InterestList>
      {showExpander && (
        <Expander onClick={handleClickExpander}>
          {expanded ? <CollapseListItem /> : <ExpandListItem />}
        </Expander>
      )}
    </StyledTableCell>
  );
};

export default InterestCell;
