import React, { FC } from 'react';
import styled, { css } from 'styled-components/macro';
import { FilterTag } from 'components/molecules';
import { useTranslation } from 'react-i18next';
import useProductFilter from 'components/pages/Private/Products/useProductFilter';

const Pickers = styled.div`
  display: flex;
  align-items: center;
  height: 68px;
`;

const StyledFilterButton = styled(FilterTag)<{
  minWidth?: string;
}>`
  &&& {
    margin-right: 12px;
    ${({ minWidth }) =>
      minWidth &&
      css`
        min-width: ${minWidth};
      `}
  }
`;

type MissionProductFilterProps = ReturnType<typeof useProductFilter>;

const MissionProductFilter: FC<MissionProductFilterProps> = props => {
  const {
    handleChangeFilterForBuisiness,
    handleChangeFilterForCustomer,
    handleChangeFilterHasMemberBenefit,
    productsFilterValues,
  } = props;

  const {
    t,
    i18n: { language },
  } = useTranslation(['productList', 'common']);

  return (
    <Pickers>
      <StyledFilterButton
        isActive={!!productsFilterValues.isForBusiness}
        minWidth={language.startsWith('en') ? '112px' : '95px'}
        onClick={() =>
          handleChangeFilterForBuisiness(!productsFilterValues.isForBusiness)
        }
      >
        {t('filters.forBusiness')}
      </StyledFilterButton>
      <StyledFilterButton
        isActive={!!productsFilterValues.isForCustomer}
        minWidth={language.startsWith('en') ? '112px' : '95px'}
        onClick={() =>
          handleChangeFilterForCustomer(!productsFilterValues.isForCustomer)
        }
      >
        {t('filters.forCustomer')}
      </StyledFilterButton>
      <StyledFilterButton
        isActive={!!productsFilterValues.hasMemberBenefit}
        minWidth={language.startsWith('en') ? '112px' : '95px'}
        onClick={() =>
          handleChangeFilterHasMemberBenefit(
            !productsFilterValues.hasMemberBenefit,
          )
        }
      >
        {t('filters.hasMemberBenefit')}
      </StyledFilterButton>
    </Pickers>
  );
};

export default MissionProductFilter;
