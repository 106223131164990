import { RequiredMark, Tag } from 'components/atoms';
import React from 'react';
import styled, { css } from 'styled-components/macro';
import theme from 'styles/theme';
import { useTranslation } from 'react-i18next';
import typography from 'styles/typography';
import { StepperStep } from 'types/form';

const Wrapper = styled.div<{ width: string | undefined }>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 82px;
  ${({ width }) =>
    width &&
    css`
      max-width: ${width};
    `}
`;

const Step = styled.div<{ isCurrent: boolean }>`
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  background-color: ${({ isCurrent }) =>
    isCurrent ? theme.basePrimary : theme.borderDefault};
  color: ${theme.baseWhite};
  font-size: 20px;
  cursor: pointer;

  &:hover {
    opacity: 0.8;
  }
`;

const StepContent = styled.div`
  position: absolute;
  top: 34px;
  left: 50%;
  width: 120px;
  transform: translateX(-50%);
  text-align: center;
`;

const StepLabel = styled.div<{ isCurrent: boolean }>`
  display: inline-block;
  position: relative;
  color: ${theme.basePrimary};
  font-size: 12px;
  line-height: 1.5;
  text-align: center;
  white-space: pre-wrap;
`;

const StepConnector = styled.span`
  display: inline-block;
  flex: 1;
  border-top-width: 1px;
  border-top-style: solid;
  border-color: ${theme.borderDefault};
`;

const StepCompleted = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 8px;
`;

const CompletedTag = styled(Tag).attrs({ color: 'labelBadge' })`
  ${typography.labelBold}
  min-width: 41px;
  margin: 0;
  padding: 4px 10px;
  border-radius: 18px;
  color: ${theme.baseWhite};
  font-weight: bold;
`;

const RequiredIcon = styled(RequiredMark)`
  position: absolute;
  top: -2px;
  right: -8px;
`;

type Props = {
  currentStepIndex: number;
  steps: StepperStep[];
  width?: string;
  onClickStep: (index: number) => void;
};

const Stepper: React.VFC<Props> = ({
  currentStepIndex,
  steps,
  width,
  onClickStep,
}) => {
  const { t } = useTranslation('common');

  return (
    <Wrapper width={width}>
      {steps.map((step, index) => (
        // eslint-disable-next-line react/no-array-index-key
        <React.Fragment key={index}>
          {index !== 0 && <StepConnector />}
          <Step
            isCurrent={currentStepIndex === index}
            onClick={() => {
              onClickStep(index);
            }}
            data-testid={`step-${index + 1}`}
          >
            {index + 1}
            <StepContent>
              <StepLabel isCurrent={currentStepIndex === index}>
                {step.label}
                {step.required && <RequiredIcon />}
              </StepLabel>
              {step.completed && (
                <StepCompleted>
                  <CompletedTag>{t('stepper.completed')}</CompletedTag>
                </StepCompleted>
              )}
            </StepContent>
          </Step>
        </React.Fragment>
      ))}
    </Wrapper>
  );
};

export default Stepper;
