import { ChangeEvent, useCallback, useEffect } from 'react';
import { useFormik } from 'formik';
import { FinancingSeries, Demand } from 'proto/v1/apimodel/apimodel';
import { DemandId } from 'constants/models/mission';
import { DemandPickerValues, DemandMissionFilterValues } from 'types/mission';

enum DemandFormIndex {
  WANT_COLLABORATION = 0,
  WANT_RAISE_FUND = 1,
  WANT_INVEST = 2,
}

const useDemandPicker = ({
  form,
  onSubmit,
  defaultValues,
  isSelected,
}: {
  onSubmit: (values: DemandPickerValues) => void;
  form: {
    demands: Demand[] | undefined;
    financingSeries: FinancingSeries[] | undefined;
  };
  defaultValues: DemandMissionFilterValues;
  isSelected: boolean;
}) => {
  const allDemandIds = form.demands ? form.demands.map(v => v.id) : [];

  const allSeriesIds = form.financingSeries
    ? form.financingSeries.map(v => v.seriesId)
    : [];

  const isCheckedAllDefaultDemandValues =
    defaultValues.demandIds.length === allDemandIds.length;

  const isCheckedAllDefaultFinancingSeries =
    defaultValues.financingSeriesIds.length === allSeriesIds.length;

  const formik = useFormik<DemandPickerValues>({
    initialValues: {
      checkAllDemands: isCheckedAllDefaultDemandValues,
      checkAllFinancingSeries: isCheckedAllDefaultFinancingSeries,
      demandIds: defaultValues.demandIds,
      financingSeriesIds: defaultValues.financingSeriesIds,
    },
    onSubmit,
    enableReinitialize: true,
  });

  const { values, touched, setFieldValue, setFieldTouched, resetForm } = formik;

  const setAllFinancingSeriesFieldValues = useCallback(
    (isCheckAll: boolean) => {
      setFieldValue('financingSeriesIds', isCheckAll ? allSeriesIds : []);
      setFieldValue('checkAllFinancingSeries', isCheckAll);
    },
    [allSeriesIds, setFieldValue],
  );

  const canCheckAllFinancingSeries = useCallback(
    (targetDemandId: Demand['id']) => {
      const touchedDemands = touched.demandIds as boolean[] | undefined;
      if (
        touchedDemands &&
        (touchedDemands[DemandFormIndex.WANT_RAISE_FUND] ||
          touchedDemands[DemandFormIndex.WANT_INVEST])
      ) {
        return false;
      }

      if (
        values.demandIds.includes(DemandId.FUNDRAISING) ||
        values.demandIds.includes(DemandId.INVEST)
      ) {
        return false;
      }

      return (
        targetDemandId === DemandId.FUNDRAISING ||
        targetDemandId === DemandId.INVEST
      );
    },
    [touched.demandIds, values.demandIds],
  );

  const handleChangeDemands = useCallback(
    (event: ChangeEvent<HTMLInputElement>): void => {
      const { target } = event;
      const targetValue = Number(target.value);
      const { demandIds } = values;
      if (target.checked) {
        setFieldValue('demandIds', [...demandIds, targetValue]);
        if (canCheckAllFinancingSeries(targetValue)) {
          setAllFinancingSeriesFieldValues(true);
        }
      } else {
        setFieldValue(
          'demandIds',
          demandIds.filter(demandId => demandId !== targetValue),
        );
      }
      setFieldTouched(target.name, true, false);
    },
    [
      canCheckAllFinancingSeries,
      setAllFinancingSeriesFieldValues,
      setFieldTouched,
      setFieldValue,
      values,
    ],
  );

  const handleChangeFinancingSeries = useCallback(
    (event: ChangeEvent<HTMLInputElement>): void => {
      const { target } = event;
      const targetValue = Number(target.value);
      const { financingSeriesIds } = values;
      if (target.checked) {
        setFieldValue('financingSeriesIds', [
          ...financingSeriesIds,
          targetValue,
        ]);
      } else {
        setFieldValue(
          'financingSeriesIds',
          financingSeriesIds.filter(seriesId => seriesId !== targetValue),
        );
      }
    },
    [setFieldValue, values],
  );

  const handleChangeCheckAllDemands = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const { checked } = event.target;
      setFieldValue('checkAllDemands', checked);
      setFieldValue('demandIds', checked ? allDemandIds : []);
      setAllFinancingSeriesFieldValues(checked);
    },
    [allDemandIds, setAllFinancingSeriesFieldValues, setFieldValue],
  );

  const handleChangeCheckAllFinancingSeries = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const { checked } = event.currentTarget;
      setAllFinancingSeriesFieldValues(checked);
    },
    [setAllFinancingSeriesFieldValues],
  );

  const isDisabledFinancingSeries =
    !values.demandIds.includes(DemandId.INVEST) &&
    !values.demandIds.includes(DemandId.FUNDRAISING);

  useEffect(() => {
    if (!isSelected) resetForm();
  }, [isSelected, resetForm]);

  return {
    formik,
    isDisabledFinancingSeries,
    handleChangeDemands,
    handleChangeFinancingSeries,
    handleChangeCheckAllDemands,
    handleChangeCheckAllFinancingSeries,
  };
};

export default useDemandPicker;
