import React from 'react';
import { Switch, Redirect } from 'react-router-dom';
import { useSelector } from 'react-redux';
import {
  getCanUseMissionMenu,
  getCanUseCareerHumanResourceMenu,
  getCanUseNewGraduateHumanResourceMenu,
  getCanUseFollowerMenu,
  getCanUseFollowingHumanResources,
  getCanUseFollowingMissions,
} from 'modules/account';
import {
  Login,
  PasswordReset,
  PasswordRegister,
  TermsOfService,
  VerificationEmail,
} from 'components/pages/Public';
import {
  MissionsList,
  SupportersList,
  FollowersMissionsList,
  FollowersInnovatorList,
  FollowingMissionsList,
  FollowingHumanResourcesList,
  Messages,
  SettingsMission,
  SettingsMessageTemplate,
  SettingsAccount,
  ProductsList,
  HumanResourcesCareerList,
  HumanResourcesNewGraduateList,
} from 'components/pages/Private';
import * as PROTECTED_ROUTES from 'routes/constants/protected';
import * as PUBLIC_ROUTES from 'routes/constants/public';
import NotFound from 'components/pages/404';
import PrivateRoute from 'routes/PrivateRoute';
import PublicRoute from 'routes/PublicRoute';

export default function Routes() {
  const canUseMissionMenu = useSelector(getCanUseMissionMenu);
  const canUseCareerHumanResourceMenu = useSelector(
    getCanUseCareerHumanResourceMenu,
  );
  const canUseNewGraduateHumanResourceMenu = useSelector(
    getCanUseNewGraduateHumanResourceMenu,
  );
  const canUseFollowerMenu = useSelector(getCanUseFollowerMenu);
  const canUseFollowingHumanResources = useSelector(
    getCanUseFollowingHumanResources,
  );
  const canUseFollowingMissions = useSelector(getCanUseFollowingMissions);

  return (
    <Switch>
      <PublicRoute path={PUBLIC_ROUTES.LOGIN} exact component={Login} />
      <PublicRoute
        path={PUBLIC_ROUTES.PASSWORD_RESET}
        exact
        component={PasswordReset}
      />
      <PublicRoute
        path={PUBLIC_ROUTES.PASSWORD_REGISTER}
        exact
        component={PasswordRegister}
      />
      <PublicRoute
        path={PUBLIC_ROUTES.TERMS}
        exact
        component={TermsOfService}
      />
      <PublicRoute
        path={PUBLIC_ROUTES.VERIFICATION_EMAIL}
        exact
        component={VerificationEmail}
      />
      {canUseMissionMenu && (
        <PrivateRoute
          path={[
            PROTECTED_ROUTES.PRIVATE_MISSIONS,
            `${PROTECTED_ROUTES.PRIVATE_MISSIONS}/:id`,
          ]}
          exact
          component={MissionsList}
        />
      )}
      <PrivateRoute
        path={[
          PROTECTED_ROUTES.PRIVATE_SUPPORTERS,
          `${PROTECTED_ROUTES.PRIVATE_SUPPORTERS}/:id`,
        ]}
        exact
        component={SupportersList}
      />
      <PrivateRoute
        path={[
          PROTECTED_ROUTES.PRIVATE_PRODUCTS,
          `${PROTECTED_ROUTES.PRIVATE_PRODUCTS}/:id`,
        ]}
        exact
        component={ProductsList}
      />
      {canUseFollowerMenu && (
        <PrivateRoute
          path={PROTECTED_ROUTES.PRIVATE_FOLLOWERS_MISSIONS}
          exact
          component={FollowersMissionsList}
        />
      )}
      <PrivateRoute
        path={PROTECTED_ROUTES.PRIVATE_FOLLOWERS_INNOVATORS}
        exact
        component={FollowersInnovatorList}
      />
      <PrivateRoute
        path={PROTECTED_ROUTES.PRIVATE_MESSAGES}
        exact
        component={Messages}
      />
      {canUseFollowingMissions && (
        <PrivateRoute
          path={PROTECTED_ROUTES.PRIVATE_FOLLOWING_MISSIONS}
          exact
          component={FollowingMissionsList}
        />
      )}
      {canUseFollowingHumanResources && (
        <PrivateRoute
          path={PROTECTED_ROUTES.PRIVATE_FOLLOWING_HUMAN_RESOURCES}
          exact
          component={FollowingHumanResourcesList}
        />
      )}
      {canUseCareerHumanResourceMenu && (
        <PrivateRoute
          path={PROTECTED_ROUTES.PRIVATE_HUMAN_RESOURCES_CAREER}
          exact
          component={HumanResourcesCareerList}
        />
      )}
      {canUseNewGraduateHumanResourceMenu && (
        <PrivateRoute
          path={PROTECTED_ROUTES.PRIVATE_HUMAN_RESOURCES_NEW_GRADUATE}
          exact
          component={HumanResourcesNewGraduateList}
        />
      )}
      <PrivateRoute
        path={PROTECTED_ROUTES.PRIVATE_SETTINGS_MISSION}
        exact
        component={SettingsMission}
      />
      <PrivateRoute
        path={PROTECTED_ROUTES.PRIVATE_MESSAGE_TEMPLATE}
        exact
        component={SettingsMessageTemplate}
      />
      <PrivateRoute
        path={PROTECTED_ROUTES.PRIVATE_SETTINGS_ACCOUNT}
        exact
        component={SettingsAccount}
      />
      <Redirect from={PUBLIC_ROUTES.LP} to={PUBLIC_ROUTES.LOGIN} exact />
      <PrivateRoute component={NotFound} />
    </Switch>
  );
}
