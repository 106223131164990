import React, { forwardRef } from 'react';
import styled from 'styled-components/macro';
import { useTranslation } from 'react-i18next';
import { MissionOfficeImage } from 'proto/v1/apimodel/apimodel';
import { MissionFormValues } from 'types/mission';
import { MISSION_OFFICE_IMAGES_MAX_COUNT } from 'constants/config';
import { imageSelector } from 'utils/imageSelector';
import { PlusGray } from 'assets/svg';
import { isEqualProps } from 'utils/object';
import {
  DeletableBlock,
  ImageUploader,
  FormItemLabel,
  MissionSettingFormItem,
  OutlinedIconButton,
} from 'components/molecules';

const Wrapper = styled.div``;

const MissionOfficeImageForm = styled.div`
  width: 445px;
  height: 297px;
`;

const AddOfficeImageField = styled.div`
  display: flex;
  justify-content: center;
  padding-top: 12px;
`;

type MissionOfficeImagesFieldProps = {
  value: MissionFormValues['missionOfficeImages'];
  onChangeImage: (imageSrc: string, index: number) => void;
  onAddImage: () => void;
  onDeleteImage: (
    missionOfficeImageId: MissionOfficeImage['missionOfficeImageId'],
  ) => void;
  onExceedImageFileSize: () => void;
};

const MissionOfficeImagesField = forwardRef<
  HTMLDivElement,
  MissionOfficeImagesFieldProps
>((props, ref) => {
  const {
    value,
    onAddImage,
    onDeleteImage,
    onChangeImage,
    onExceedImageFileSize,
  } = props;

  const { t } = useTranslation('missionSetting');

  return (
    <Wrapper ref={ref}>
      <MissionSettingFormItem>
        <FormItemLabel
          label={t('field.missionOfficeImages.label')}
          note={t('field.missionOfficeImages.note', { returnObjects: true })}
        />
        <div data-testid="office-images-field">
          {value &&
            value.map(
              (missionOfficeImage: MissionOfficeImage, index: number) => (
                <DeletableBlock
                  onDelete={() => {
                    onDeleteImage(missionOfficeImage.missionOfficeImageId);
                  }}
                  key={
                    missionOfficeImage &&
                    missionOfficeImage.missionOfficeImageId
                  }
                >
                  <MissionOfficeImageForm data-testid="office-image-form">
                    <ImageUploader
                      imageSrc={imageSelector(
                        missionOfficeImage.image,
                        'large',
                      )}
                      onUpload={uploadedImage => {
                        onChangeImage(uploadedImage, index);
                      }}
                      onExceededFileSizeLimit={onExceedImageFileSize}
                      width={445}
                      height={297}
                    />
                  </MissionOfficeImageForm>
                </DeletableBlock>
              ),
            )}
          {(!value ||
            (value && value.length < MISSION_OFFICE_IMAGES_MAX_COUNT)) && (
            <AddOfficeImageField>
              <OutlinedIconButton
                icon={<PlusGray />}
                onClick={onAddImage}
                data-testid="add-office-image"
              >
                {t('field.missionOfficeImages.add')}
              </OutlinedIconButton>
            </AddOfficeImageField>
          )}
        </div>
      </MissionSettingFormItem>
    </Wrapper>
  );
});

export default React.memo(MissionOfficeImagesField, (prevProps, nextProps) =>
  isEqualProps(prevProps, nextProps, ['value']),
);
