import React, { FC } from 'react';
import styled, { css } from 'styled-components/macro';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import * as EXTERNAL_LINK from 'constants/externalLink';
import * as PUBLIC_ROUTES from 'routes/constants/public';
import { getIsSidebarShrinked } from 'modules/ui';
import typography from 'styles/typography';
import theme from 'styles/theme';
import { menuButtonStyle } from 'styles/sidebar';
import { CopyRight, SidebarNavLink } from 'components/atoms';
import SidebarTransition from './SidebarTransition';

const footerMenuItemStyle = css`
  ${typography.textFooter}
  display: block;
  padding: 7px 20px;
  color: ${theme.textSecondary};
  text-decoration: none;
  cursor: pointer;

  &:hover {
    background: ${theme.bgMenuDefault};
    color: ${theme.textSecondaryHover};
    text-decoration: none;
  }
`;

const Footer = styled.div`
  display: block;

  &.enter {
    display: none;
  }

  &.enter-done {
    display: block;
  }

  &.exit {
    display: none;
  }

  &.exit-done {
    display: none;
  }
`;

const FooterMenu = styled.ul`
  margin-top: 20px;
  margin-bottom: 10px;
  list-style: none;
`;

const FooterMenuItemLink = styled(SidebarNavLink)`
  ${footerMenuItemStyle}
`;

const FooterMenuItemButton = styled.button`
  ${footerMenuItemStyle}
  ${menuButtonStyle}
`;

const MenuItem = styled.li`
  position: relative;
`;

type Props = {
  onToggleContactModal: () => void;
};

const SidebarFooter: FC<Props> = ({ onToggleContactModal }) => {
  const { t, i18n } = useTranslation(['sidebar', 'footer', 'common']);
  const isSidebarShrinked = useSelector(getIsSidebarShrinked);

  return (
    <SidebarTransition isShrinked={isSidebarShrinked}>
      <Footer>
        <FooterMenu>
          <MenuItem>
            <FooterMenuItemButton type="button" onClick={onToggleContactModal}>
              {t('footer:menu.inquiry')}
            </FooterMenuItemButton>
          </MenuItem>
          <MenuItem>
            <FooterMenuItemLink
              to={PUBLIC_ROUTES.TERMS}
              target="_blank"
              isActive={() => false}
            >
              {t('footer:menu.termsOfService')}
            </FooterMenuItemLink>
          </MenuItem>
          <MenuItem>
            <FooterMenuItemLink
              to={
                i18n.languages[0] === 'en'
                  ? EXTERNAL_LINK.PRIVACY_POLICY_EN
                  : EXTERNAL_LINK.PRIVACY_POLICY
              }
              target="_blank"
            >
              Privacy Policy
            </FooterMenuItemLink>
          </MenuItem>
        </FooterMenu>
        <CopyRight contents="private" />
      </Footer>
    </SidebarTransition>
  );
};

export default SidebarFooter;
