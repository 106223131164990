import React, { FC, ChangeEvent } from 'react';
import styled from 'styled-components/macro';
import { useTranslation } from 'react-i18next';
import { MissionSettingMaxLength } from 'constants/form';
import { isEqualProps } from 'utils/object';
import { Checkbox, Textarea } from 'components/atoms';
import { MissionDemandFieldItem } from 'components/molecules';

const StyledCheckbox = styled(Checkbox)`
  &&& {
    margin-bottom: 0;
  }
`;

const StyledTextarea = styled(Textarea)`
  position: relative;
  width: 445px;

  textarea {
    min-height: 97px;
    line-height: 1.5;
  }

  small {
    display: block;
    position: absolute;
    bottom: -14px;
    left: 0;
    width: auto;
  }
`;

type SendEmployeeFieldProps = {
  wantSendEmployee: boolean;
  work: string;
  onChangeWantSendEmployee: (e: ChangeEvent<HTMLInputElement>) => void;
  onChangeWork: (e: ChangeEvent<HTMLTextAreaElement>) => void;
  onBlur: (e: any) => void;
};

const SendEmployeeField: FC<SendEmployeeFieldProps> = props => {
  const {
    wantSendEmployee,
    work,
    onChangeWantSendEmployee,
    onChangeWork,
    onBlur,
  } = props;

  const { t } = useTranslation('missionSetting');

  return (
    <MissionDemandFieldItem>
      <StyledCheckbox
        name="missionDemand.wantSendEmployee"
        checked={wantSendEmployee}
        onChange={onChangeWantSendEmployee}
        aria-label={t('field.missionDemand.checkbox.wantSendEmployee')}
      >
        {t('field.missionDemand.checkbox.wantSendEmployee')}
      </StyledCheckbox>
      {wantSendEmployee && (
        <StyledTextarea
          name={`missionDemand.sendEmployee.work`}
          value={work}
          maxLength={MissionSettingMaxLength.MISSION_DEMAND_SEND_EMPLOYEE_WORK}
          placeholder={t('field.missionDemand.sendEmployee.placeholder', {
            maxLength:
              MissionSettingMaxLength.MISSION_DEMAND_SEND_EMPLOYEE_WORK,
          })}
          onChange={onChangeWork}
          onBlur={onBlur}
          data-testid="send-employee-work"
        />
      )}
    </MissionDemandFieldItem>
  );
};

export default React.memo(SendEmployeeField, (prevProps, nextProps) =>
  isEqualProps(prevProps, nextProps, ['wantSendEmployee', 'work']),
);
