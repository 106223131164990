import { useCallback, useState, useRef } from 'react';
import {
  CreateAnotherMissionResponse,
  DeleteMyMissionResponse,
} from 'proto/v1/missionservice/missionservice';
import RequestError from 'classes/RequestError';
import { useTranslation } from 'react-i18next';
import { MyAccountsMethods } from 'hooks/useMyAccounts';
import apiMission from 'external/api/mission';
import useErrorDialog from 'hooks/useErrorDialog';
import useMenu from '../../hooks/useMenu';
import { ModalContext } from '../../hooks/useModal';

type Payload = {
  openModal: ModalContext['openModal'];
  closeModal: ModalContext['closeModal'];
  changeActiveAccount: MyAccountsMethods['changeActiveAccount'];
  fetchMyAccounts: MyAccountsMethods['fetchMyAccounts'];
  deleteMission: MyAccountsMethods['deleteMission'];
  applyLatestAccountInfo: MyAccountsMethods['applyLatestAccountInfo'];
};

const useMissionFormMenu = ({
  openModal,
  closeModal,
  fetchMyAccounts,
  changeActiveAccount,
  deleteMission,
  applyLatestAccountInfo,
}: Payload) => {
  const addMissionResponseRef = useRef<CreateAnotherMissionResponse>();
  const deleteMissionResponseRef = useRef<DeleteMyMissionResponse>();
  const [showMenu, setShowMenu] = useState<boolean>(false);
  const { t } = useTranslation(['error']);

  const { handleRequestError } = useErrorDialog();

  const { openMenu, closeMenu, handleClickMenuUnPublish } = useMenu({
    openModal,
    setShowMenu,
  });

  const handleClickMenuAdd = useCallback(() => {
    setShowMenu(false);
    openModal('addMissionConfirmation');
  }, [openModal]);

  const handleClickAddModalOk = useCallback(async () => {
    setShowMenu(false);
    closeModal();
    try {
      const { data } = await apiMission.createAnotherMission();
      addMissionResponseRef.current = data;
      openModal('addMissionCompleted');
    } catch (requestError) {
      if (requestError instanceof RequestError) {
        handleRequestError(requestError, t('error:failedToCreateMission'));
      }
    }
  }, [closeModal, handleRequestError, openModal, t]);

  const handleCloseAddCompleteModal = useCallback(async () => {
    if (!addMissionResponseRef.current) return;
    closeModal();
    // Change the active account here,
    // because changing the active account before opening the mission add complete modal
    // prevents to open this modal due to the re-rendering of the entire screen.
    await fetchMyAccounts();
    await changeActiveAccount(addMissionResponseRef.current.accountId);
  }, [changeActiveAccount, closeModal, fetchMyAccounts]);

  const handleClickMenuDelete = useCallback(() => {
    setShowMenu(false);
    openModal('deleteMissionConfirmation');
  }, [openModal]);

  const handleClickDeleteModalOk = useCallback(
    async (deleteMissionId: string) => {
      setShowMenu(false);
      closeModal();
      const data = await deleteMission(deleteMissionId);
      if (!data) return;
      deleteMissionResponseRef.current = data;
      openModal('deleteMissionCompleted');
    },
    [closeModal, deleteMission, openModal],
  );

  const handleCloseDeleteCompleteModal = useCallback(() => {
    closeModal();
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    applyLatestAccountInfo(deleteMissionResponseRef.current!);
  }, [applyLatestAccountInfo, closeModal]);

  return {
    showMenu,
    openMenu,
    closeMenu,
    handleClickMenuUnPublish,
    handleClickMenuAdd,
    handleClickAddModalOk,
    handleCloseAddCompleteModal,
    handleClickMenuDelete,
    handleClickDeleteModalOk,
    handleCloseDeleteCompleteModal,
  };
};

export default useMissionFormMenu;
