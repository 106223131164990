import styled from 'styled-components/macro';
import { Checkbox } from 'visits-style';

const SelectableTag = styled(Checkbox)`
  display: inline-block;
  margin-right: 10px;

  input {
    display: none;

    &:disabled + label {
      &:hover {
        cursor: default;
      }
    }
  }

  label {
    display: inline-block;
    margin-bottom: 8px;
    padding: 10px 20px;
    border-radius: 30px;
    text-align: center;

    &::before {
      display: none;
    }

    &::after {
      display: none;
    }

    > div {
      display: none;
    }
  }
`;

export default SelectableTag;
