import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { getUnsubscribeLogout, AccountActions } from 'modules/account';
import { NotificationActions } from 'modules/notification';
import apiFirebase from 'external/firebase/firebase';
import { clearMessagesToResend } from 'utils/models/chat';
import * as PUBLIC_ROUTES from 'routes/constants/public';

const useLogout = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const unsubscribe = useSelector(getUnsubscribeLogout);

  const handleLogoutWithRedirectToLogin = useCallback(() => {
    unsubscribe && unsubscribe();
    apiFirebase.logout().then(() => {
      dispatch(AccountActions.clear());
      dispatch(NotificationActions.clearNotification());
      clearMessagesToResend();
      history.push(PUBLIC_ROUTES.LOGIN);
    });
  }, [dispatch, history, unsubscribe]);

  const logout = useCallback(
    (onLoggedout?: () => void) => {
      unsubscribe && unsubscribe();
      apiFirebase.logout().then(() => {
        dispatch(AccountActions.clear());
        dispatch(NotificationActions.clearNotification());
        clearMessagesToResend();
        onLoggedout && onLoggedout();
      });
    },
    [dispatch, unsubscribe],
  );

  return {
    handleLogoutWithRedirectToLogin,
    logout,
  };
};

export default useLogout;
