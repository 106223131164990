import React from 'react';
import styled, { css } from 'styled-components/macro';
import { useTranslation } from 'react-i18next';
import { Matter } from 'proto/v1/apimodel/apimodel';
import theme from 'styles/theme';
import {
  formatOccupations,
  formatAcademicBackground,
  formatCareerPositions,
} from 'utils/models/matter';
import { EllipsisBox, SquareTag } from 'components/atoms';
import {
  HumanResourceCardBodyItemLabel,
  HumanResourceCardBodyItemContent,
} from 'components/molecules';

const Wrapper = styled.div<{ isDetail: boolean }>`
  padding: 18px 32px 18px 32px;
  background-color: ${theme.baseWhite};
  ${({ isDetail }) =>
    isDetail &&
    css`
      padding: 36px 36px 30px 36px;
    `}
`;

const MainFieldTop = styled.div`
  display: flex;
`;

const MainFieldTopLeft = styled.div`
  padding-right: 28px;
  border-right: 1px solid ${theme.borderTable};
`;

const MainFieldTopRight = styled.div`
  padding-left: 28px;
`;

const MainFieldItem = styled.div<{ isDetail: boolean }>`
  display: flex;
  margin-bottom: 16px;
  ${({ isDetail }) =>
    isDetail &&
    css`
      margin-bottom: 24px;
    `}

  &:last-child {
    margin-bottom: 0;
  }
`;

const MainFieldItemContent = styled(HumanResourceCardBodyItemContent)`
  width: 240px;
`;

const Tags = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
`;

const SelfIntroduction = styled.div`
  display: flex;
  margin-top: 12px;
`;

const Output = styled.a.attrs({ target: '_blank', rel: 'noopener noreferrer' })`
  display: block;
  max-width: 238px;
  margin-bottom: 4px;
  overflow: hidden;
  color: ${theme.textPrimary};
  font-size: 14px;
  line-height: 16px;
  text-decoration: none;
  text-overflow: ellipsis;
  white-space: nowrap;

  &:hover {
    color: ${theme.textPrimaryHover};
    text-decoration: underline;
  }

  &:last-child {
    margin-bottom: 0;
  }
`;

type Props = {
  matter: Matter;
  isDetail?: boolean;
};

const CareerMainField: React.VFC<Props> = ({ matter, isDetail = false }) => {
  const { humanResource } = matter;
  const { t } = useTranslation(['matter']);
  return (
    <Wrapper isDetail={isDetail}>
      <MainFieldTop>
        <MainFieldTopLeft>
          <MainFieldItem isDetail={isDetail}>
            <HumanResourceCardBodyItemLabel>
              {t('field.occupations.label', { lng: 'ja' })}
            </HumanResourceCardBodyItemLabel>
            <MainFieldItemContent>
              {formatOccupations(humanResource?.career?.job)}
            </MainFieldItemContent>
          </MainFieldItem>
          <MainFieldItem isDetail={isDetail}>
            <HumanResourceCardBodyItemLabel>
              {t('field.skills.label', { lng: 'ja' })}
            </HumanResourceCardBodyItemLabel>
            <MainFieldItemContent>
              <Tags>
                {humanResource?.career?.skills &&
                  humanResource.career.skills.map(skill => (
                    <SquareTag key={skill.id}>{skill.name}</SquareTag>
                  ))}
              </Tags>
            </MainFieldItemContent>
          </MainFieldItem>
          <MainFieldItem isDetail={isDetail}>
            <HumanResourceCardBodyItemLabel style={{ paddingRight: '50px' }}>
              {t('field.position.label', { lng: 'ja' })}
            </HumanResourceCardBodyItemLabel>
            <MainFieldItemContent>
              {formatCareerPositions(humanResource?.career?.job)}
            </MainFieldItemContent>
          </MainFieldItem>
          {isDetail && (
            <>
              <MainFieldItem isDetail={isDetail}>
                <HumanResourceCardBodyItemLabel>
                  {t('field.englishSkill.label', {
                    lng: 'ja',
                  })}
                </HumanResourceCardBodyItemLabel>
                <MainFieldItemContent>
                  {humanResource?.englishSkill?.name ?? '-'}
                </MainFieldItemContent>
              </MainFieldItem>
              <MainFieldItem isDetail={isDetail}>
                <HumanResourceCardBodyItemLabel>
                  {t('field.achievement.label', {
                    lng: 'ja',
                  })}
                </HumanResourceCardBodyItemLabel>
                <MainFieldItemContent>
                  {humanResource?.career?.achievement || '-'}
                </MainFieldItemContent>
              </MainFieldItem>
              <MainFieldItem isDetail={isDetail}>
                <HumanResourceCardBodyItemLabel>
                  {t('field.certifications.label', {
                    lng: 'ja',
                  })}
                </HumanResourceCardBodyItemLabel>
                <MainFieldItemContent>
                  {humanResource?.certifications &&
                  humanResource?.certifications.length > 0
                    ? humanResource?.certifications.join(' / ')
                    : '-'}
                </MainFieldItemContent>
              </MainFieldItem>
              {humanResource?.outputs && humanResource.outputs.length > 0 && (
                <MainFieldItem isDetail={isDetail}>
                  <HumanResourceCardBodyItemLabel>
                    {t('field.outputs.label', {
                      lng: 'ja',
                    })}
                  </HumanResourceCardBodyItemLabel>
                  <MainFieldItemContent>
                    {humanResource?.outputs.map((output, index) => (
                      // eslint-disable-next-line react/no-array-index-key
                      <Output key={index} href={output}>
                        {output}
                      </Output>
                    ))}
                  </MainFieldItemContent>
                </MainFieldItem>
              )}
            </>
          )}
        </MainFieldTopLeft>

        <MainFieldTopRight>
          <MainFieldItem isDetail={isDetail}>
            <HumanResourceCardBodyItemLabel>
              {t('field.academicBackground.label', { lng: 'ja' })}
            </HumanResourceCardBodyItemLabel>
            <MainFieldItemContent>
              {formatAcademicBackground(humanResource?.academicBackground)}
            </MainFieldItemContent>
          </MainFieldItem>
          <MainFieldItem isDetail={isDetail}>
            <HumanResourceCardBodyItemLabel>
              {t('field.preferredOccupations.label', {
                lng: 'ja',
              })}
            </HumanResourceCardBodyItemLabel>
            <MainFieldItemContent>
              {humanResource?.preferredOccupations &&
                humanResource.preferredOccupations
                  .map(preferredOccupation => preferredOccupation.name)
                  .join(' / ')}
            </MainFieldItemContent>
          </MainFieldItem>
          <MainFieldItem isDetail={isDetail}>
            <HumanResourceCardBodyItemLabel>
              {t('field.expectations.label', {
                lng: 'ja',
              })}
            </HumanResourceCardBodyItemLabel>
            <MainFieldItemContent>
              <Tags>
                {humanResource?.career?.expectations &&
                  humanResource.career.expectations.map(expectation => (
                    <SquareTag key={expectation.id}>
                      {expectation.name}
                    </SquareTag>
                  ))}
              </Tags>
            </MainFieldItemContent>
          </MainFieldItem>
          {isDetail && (
            <>
              <MainFieldItem isDetail={isDetail}>
                <HumanResourceCardBodyItemLabel>
                  {t('field.preferredWorkLocations.label', {
                    lng: 'ja',
                  })}
                </HumanResourceCardBodyItemLabel>
                <MainFieldItemContent>
                  {humanResource?.preferredWorkLocations &&
                  humanResource.preferredWorkLocations.length > 0
                    ? humanResource.preferredWorkLocations
                        .map(
                          preferredWorkLocation =>
                            preferredWorkLocation.japanPrefecture?.name ??
                            t('field.preferredWorkLocations.overseas', {
                              lng: 'ja',
                            }),
                        )
                        .join(' / ')
                    : '-'}
                </MainFieldItemContent>
              </MainFieldItem>
              {humanResource?.japanPrefecture && (
                <MainFieldItem isDetail={isDetail}>
                  <HumanResourceCardBodyItemLabel>
                    {t('field.japanPrefecture.label', {
                      lng: 'ja',
                    })}
                  </HumanResourceCardBodyItemLabel>
                  <MainFieldItemContent>
                    {humanResource?.japanPrefecture?.name}
                  </MainFieldItemContent>
                </MainFieldItem>
              )}
              {humanResource?.career?.annualIncome && (
                <MainFieldItem isDetail={isDetail}>
                  <HumanResourceCardBodyItemLabel>
                    {t('field.annualIncome.label', {
                      lng: 'ja',
                    })}
                  </HumanResourceCardBodyItemLabel>
                  <MainFieldItemContent>
                    {humanResource.career.annualIncome.name}
                  </MainFieldItemContent>
                </MainFieldItem>
              )}
            </>
          )}
        </MainFieldTopRight>
      </MainFieldTop>

      {!isDetail && (
        <SelfIntroduction>
          <HumanResourceCardBodyItemLabel>
            {t('field.selfIntroduction.label', {
              lng: 'ja',
            })}
          </HumanResourceCardBodyItemLabel>
          <HumanResourceCardBodyItemContent
            style={{ width: '672px' }}
            fontWeight="normal"
          >
            <EllipsisBox line={2} width={'672px'} maxHeight={'45px'}>
              {humanResource?.selfIntroduction}
            </EllipsisBox>
          </HumanResourceCardBodyItemContent>
        </SelfIntroduction>
      )}
    </Wrapper>
  );
};

export default CareerMainField;
