import React, { FC, ComponentProps } from 'react';
import Select from 'react-select';
import styled from 'styled-components/macro';
import theme from 'styles/theme';

const StyledSelect = styled(Select)<InputMultiSelectProps>`
  width: ${({ width }) => width ?? '312px'};

  .react-select__control {
    min-height: 32px;
    padding: 0;
    border-width: 1px;
    border-color: ${theme.borderDefault};
    outline: none;
    box-shadow: none;

    &:hover {
      border-color: ${theme.borderDefault};
      cursor: text;
    }
  }

  .react-select__value-container {
    padding: 0 8px;
  }

  .react-select__multi-value {
    margin-right: 6px;
    margin-left: 0;
    border-radius: 5px;
    background-color: ${theme.basePrimary};
    color: ${theme.baseWhite};
  }

  .react-select__multi-value__label {
    padding: 4px 2px 4px 8px;
    color: ${theme.baseWhite};
    font-size: 14px;
  }

  .react-select__multi-value__remove {
    padding: 0 8px;
    border-radius: 0 5px 5px 0;
    background-color: ${theme.basePrimary};

    &:hover {
      opacity: 0.8;
      background-color: ${theme.basePrimary};
      cursor: pointer;
    }

    svg {
      width: 12px;
      height: 12px;
      border-radius: 50%;
      background-color: ${theme.baseWhite};
      color: ${theme.basePrimary};
    }
  }

  .react-select__option {
    padding: 12px;
    border-bottom: 1px solid ${theme.borderTable};
    color: ${theme.textPrimary};

    &:hover {
      background-color: ${theme.labelDefault};
      color: ${theme.baseWhite};
      cursor: pointer;
    }
  }

  .react-select__option--is-focused {
    background-color: ${theme.labelDefault};
    color: ${theme.baseWhite};
  }

  .react-select__menu {
    width: auto;
    min-width: 194px;
    border-radius: 3px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.16);
  }

  .react-select__menu-list {
    padding-top: 0;
    padding-bottom: 0;
    border-radius: 3px;
  }

  .react-select__dropdown-indicator,
  .react-select__indicator-separator {
    display: none;
  }

  .react-select__placeholder {
    color: ${theme.textSecondary};
  }
`;

type InputMultiSelectProps = ComponentProps<typeof Select> & {
  width?: string;
};

const InputMultiSelect: FC<InputMultiSelectProps> = ({
  children,
  width,
  ...props
}) => {
  return (
    <>
      <StyledSelect
        {...props}
        noOptionsMessage={() => null}
        classNamePrefix="react-select"
        isMulti
        openMenuOnClick={false}
        isClearable={false}
        width={width}
      />
    </>
  );
};

export default InputMultiSelect;
