import React, { FC } from 'react';
import ImageTypes from 'constants/imageTypes';
import { Modal, ImageCropper } from 'components/molecules';
import { ImageCropperStateType } from '../../hooks/useImageCropper';

type ImageCropperModalProps = {
  imageCropperState: ImageCropperStateType;
  aspectRatio?: number;
  onCroppedImage: (croppedImageSrc: string) => any;
  handleClickCropperCloseButton: (type?: ImageTypes, index?: number) => void;
};

const ImageCropperModal: FC<ImageCropperModalProps> = ({
  imageCropperState,
  aspectRatio,
  onCroppedImage,
  handleClickCropperCloseButton,
}) => {
  return (
    <Modal
      type={imageCropperState.type}
      show={imageCropperState.show}
      closeModal={() => {
        handleClickCropperCloseButton(
          imageCropperState.type,
          imageCropperState.index,
        );
      }}
      style={{ height: 'auto' }}
    >
      {/* remount ImageCropper because aspect ratio should be constant */}
      {imageCropperState.show && (
        <ImageCropper
          imageSrc={imageCropperState.imageSrc}
          aspectRatio={aspectRatio}
          croppedImage={imageCropperState.croppedImage}
          onCroppedImage={onCroppedImage}
        />
      )}
    </Modal>
  );
};

export default ImageCropperModal;
