import { useMemo, useCallback, useEffect, ChangeEvent } from 'react';
import { FormikTouched, FormikErrors } from 'formik';
import { MissionProductFormValue, MissionProductsError } from 'types/form';
import ImageTypes from 'constants/imageTypes';
import { MISSION_PRODUCT_PRODUCT_TAGS_MAX_COUNT } from 'constants/config';
import usePrevious from 'hooks/usePrevious';
import { MissionSettingFormState } from './useMissionSettingFormState';
import { MissionProductsState } from './useMissionProductsState';
import { MissionSettingFormik } from './useMissionSettingFormik';

type Payload = {
  missionProducts: MissionProductFormValue[];
  isPublished: boolean;
  isMissionProductAdded: MissionProductsState['isMissionProductAdded'];
  missionProductRefs: MissionProductsState['missionProductRefs'];
  setMissionProductAdded: MissionProductsState['setMissionProductAdded'];
  getAddedItemId: MissionSettingFormState['getAddedItemId'];
  openImageCropper: MissionSettingFormState['openImageCropper'];
  setFieldValue: MissionSettingFormik['setFieldValue'];
  setFieldTouched: MissionSettingFormik['setFieldTouched'];
  setTouched: MissionSettingFormik['setTouched'];
  errors: FormikErrors<{
    missionProducts: MissionProductFormValue[];
  }>['missionProducts'];
  touched: FormikTouched<{
    missionProducts: MissionProductFormValue[];
  }>['missionProducts'];
};

const useMissionProductsField = ({
  isPublished,
  isMissionProductAdded,
  missionProductRefs,
  setMissionProductAdded,
  getAddedItemId,
  openImageCropper,
  missionProducts,
  errors,
  touched,
  setFieldValue,
  setFieldTouched,
  setTouched,
}: Payload) => {
  const prevMissionProductLength = usePrevious(missionProducts.length);
  // scroll to added mission product
  useEffect(() => {
    // Check isMissionProductAdded for not to scroll when initialValue changed
    if (!isMissionProductAdded || prevMissionProductLength === undefined) {
      return;
    }
    if (prevMissionProductLength < missionProducts.length) {
      const addedMissionProductElement =
        missionProductRefs[missionProducts.length - 1].current;
      addedMissionProductElement?.scrollIntoView({ behavior: 'smooth' });
    }
    setMissionProductAdded(false);
  }, [
    isMissionProductAdded,
    missionProductRefs,
    prevMissionProductLength,
    setMissionProductAdded,
    missionProducts.length,
  ]);

  // Ref: VI-2256
  // 一時機能タグが未入力でも公開できる状態の時があったため、公開済みのミッションは強制的に機能タグのエラーを表示する
  useEffect(() => {
    if (isPublished) {
      setTouched({
        missionProducts: missionProducts
          .filter(missionProduct =>
            // Exclude added tags.
            missionProduct.tags.some(tag => tag.id > -1),
          )
          .map(_ => ({
            tags: [{ tag: true }],
          })),
      });
    }
  }, [isPublished, missionProducts, setTouched]);

  const missionProductsError = useMemo(() => {
    if (!errors) {
      return undefined;
    }
    return (errors as MissionProductsError[]).map((error, index) => {
      const targetTouched = touched && touched[index];

      return {
        name: targetTouched?.name ? error?.name : undefined,
        overview: targetTouched?.overview ? error?.overview : undefined,
        url: targetTouched?.url ? error?.url : undefined,
        problem: targetTouched?.problem ? error?.problem : undefined,
        solution: targetTouched?.solution ? error?.solution : undefined,
        hasMemberBenefit: targetTouched?.hasMemberBenefit
          ? error?.hasMemberBenefit
          : undefined,
        memberBenefit: targetTouched?.memberBenefit
          ? error?.memberBenefit
          : undefined,
        image: targetTouched?.image ? error?.image : undefined,
        productTarget:
          targetTouched?.productTarget?.isForBusiness ||
          targetTouched?.productTarget?.isForCustomer
            ? error?.productTarget
            : undefined,
        tags: targetTouched?.tags ? error?.tags : undefined,
      };
    });
  }, [errors, touched]);

  const handleChangeHasMemberBenefit = useCallback(
    (event: ChangeEvent<HTMLInputElement>, index: number) => {
      const { name, value } = event.target;
      setFieldValue(name, !!Number(value));
      // should set shouldValidate flag false to avoid incorrect validate errors
      setFieldTouched(name, true, false);
      setFieldTouched(`missionProducts[${index}].memberBenefit`, false, false);
    },
    [setFieldTouched, setFieldValue],
  );

  const handleChangeMissionProductImage = useCallback(
    (image: string, index: number) => {
      openImageCropper(ImageTypes.PRODUCT, image, index);
    },
    [openImageCropper],
  );

  const handleAddMissionProduct = useCallback(() => {
    setFieldValue('missionProducts', [
      ...missionProducts,
      {
        missionProductId: getAddedItemId('missionProducts'),
        name: '',
        overview: '',
        url: '',
        problem: '',
        solution: '',
        hasMemberBenefit: undefined,
        memberBenefit: '',
        image: undefined,
        productTarget: { isForBusiness: false, isForCustomer: false },
        tags: Array.from({
          length: MISSION_PRODUCT_PRODUCT_TAGS_MAX_COUNT,
        }).map(_ => ({
          id: getAddedItemId('missionProductTags'),
          tag: '',
        })),
      },
    ]);
    setMissionProductAdded(true);
  }, [getAddedItemId, missionProducts, setFieldValue, setMissionProductAdded]);

  const handleDeleteMissionProduct = useCallback(
    (targetIndex: number) => {
      setFieldValue(
        'missionProducts',
        missionProducts.filter(
          (_missionProduct, index) => index !== targetIndex,
        ),
      );
    },
    [missionProducts, setFieldValue],
  );

  return {
    missionProductsError,
    handleChangeHasMemberBenefit,
    handleChangeMissionProductImage,
    handleAddMissionProduct,
    handleDeleteMissionProduct,
  };
};

export default useMissionProductsField;
