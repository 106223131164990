import { useCallback } from 'react';
import { FormikTouched, FormikErrors } from 'formik';
import { SupporterMember } from 'proto/v1/apimodel/apimodel';
import { SupporterMemberFormValue, SupporterMemberError } from 'types/form';
import ImageTypes from 'constants/imageTypes';
import { MissionSettingFormState } from './useMissionSettingFormState';
import { MissionSettingFormik } from './useMissionSettingFormik';

type Payload = {
  supporterMembers: SupporterMemberFormValue[];
  getAddedItemId: MissionSettingFormState['getAddedItemId'];
  openImageCropper: MissionSettingFormState['openImageCropper'];
  setFieldValue: MissionSettingFormik['setFieldValue'];
  setFieldTouched: MissionSettingFormik['setFieldTouched'];
  touched: FormikTouched<{
    supporterMembers: SupporterMemberFormValue[];
  }>['supporterMembers'];
  errors: FormikErrors<{
    supporterMembers: SupporterMemberFormValue[];
  }>['supporterMembers'];
};

const useSupporterMembersField = ({
  supporterMembers,
  getAddedItemId,
  openImageCropper,
  touched,
  errors,
  setFieldValue,
  setFieldTouched,
}: Payload) => {
  const handleChangeSupporterMemberIconImage = useCallback(
    (image: string, index: number) => {
      const field = `supporterMembers[${index}].iconImage`;
      openImageCropper(ImageTypes.MEMBER_ICON, image, index, field);
    },
    [openImageCropper],
  );

  const handleAddMember = useCallback(() => {
    setFieldValue(
      'supporterMembers',
      supporterMembers && [
        ...supporterMembers,
        {
          supporterMemberId: getAddedItemId('supporterMembers'),
          name: '',
          career: '',
          iconImage: { data: '' },
        },
      ],
    );
  }, [getAddedItemId, supporterMembers, setFieldValue]);

  const handleDeleteMember = useCallback(
    (targetIndex: number) => {
      if (!supporterMembers || !Array.isArray(supporterMembers)) {
        return;
      }

      setFieldValue(
        'supporterMembers',
        supporterMembers.filter(
          (_: SupporterMember, index: number) => index !== targetIndex,
        ),
      );

      const prevMembersTouched = touched;

      if (prevMembersTouched && Array.isArray(prevMembersTouched)) {
        /*
        Generate touch values base on prev touch values,
        because touch values doesn't change automatically.
       */
        const deletedTouchedMembers = [...prevMembersTouched]
          .filter((tag, index) => index !== targetIndex)
          .reduce<FormikTouched<SupporterMemberFormValue>[]>(
            (prev, itemOnArrayDeletedTarget, index) => {
              // @ts-ignore
              prev[index] =
                typeof itemOnArrayDeletedTarget === 'object'
                  ? { ...itemOnArrayDeletedTarget }
                  : prev[index];
              return prev;
            },
            // Process base on prev values(loop index and times are important)
            supporterMembers.map(() => ({
              name: undefined,
              career: undefined,
              iconImage: undefined,
            })),
          );

        /*
          Should set shouldValidate-flag false.
          If set true will set invalid errors value.
          Read github PR #885.
        */
        // @ts-ignore
        setFieldTouched('supporterMembers', deletedTouchedMembers, false);
      }
    },
    [supporterMembers, setFieldTouched, setFieldValue, touched],
  );

  const isSupporterMemberTouched = useCallback(
    (index: number) => {
      if (!touched || !touched[index]) {
        return false;
      }
      return (
        touched[index].career && touched[index].name && touched[index].iconImage
      );
    },
    [touched],
  );

  const supporterMembersError = errors
    ? ((errors as SupporterMemberError[]).map(
        (error: SupporterMemberError, index: number) =>
          error && isSupporterMemberTouched(index) ? error : undefined,
      ) as SupporterMemberError[] | undefined)
    : undefined;

  return {
    handleAddMember,
    handleDeleteMember,
    handleChangeSupporterMemberIconImage,
    supporterMembersError,
  };
};

export default useSupporterMembersField;
