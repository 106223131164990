import React, { FC, useCallback, useMemo, UIEvent, RefObject } from 'react';
import styled from 'styled-components/macro';
import { useTranslation } from 'react-i18next';
import theme from 'styles/theme';
import typography from 'styles/typography';
import scrollbar from 'styles/scrollbar';
import { ItemLoading, ButtonM } from 'components/atoms';
import { MessageCard, TemporaryMessageCard } from 'components/molecules';
import { ChatRoom, ChatMessage, ChatUser } from 'proto/v1/apimodel/apimodel';
import { TemporaryChatMessage } from 'types/chat';

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  padding: 40px 24px 0 40px;
  overflow-x: hidden;
  overflow-y: auto;
  ${scrollbar}
`;

const LoadingContainer = styled.div`
  padding: 34px 0 34px 16px;
`;

const ErrorContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 19px 0 37px 16px;
`;

const ErrorMessage = styled.p`
  ${typography.textNote}
  margin-bottom: 4px;
  color: ${theme.textSecondary};
  text-align: center;
`;

const UncontactableMessageWrapper = styled.div`
  display: flex;
  align-self: center;
  justify-content: center;
`;

const UncontactableMessage = styled.div`
  ${typography.labelDefault}
  margin-bottom: 24px;
  padding: 3px 12px;
  border-radius: 18px;
  background-color: ${theme.baseDisabled};
  color: ${theme.baseWhite};
`;

type MessageListProps = {
  chatRoom: ChatRoom;
  messages: ChatMessage[];
  ownIconImage: string;
  showLoading: boolean;
  showUncontactableMessage: boolean;
  temporaryMessages?: TemporaryChatMessage[];
  messagesToResend?: TemporaryChatMessage[];
  getUploadProgress: (message: TemporaryChatMessage) => number | undefined;
  onDeleteMessage: (message: ChatMessage) => void;
  onDownloadFile: (message: ChatMessage, writeFlag: boolean) => void;
  onResendMessage: (message: TemporaryChatMessage) => void;
  onDeleteMessageToResend: (
    messageId: TemporaryChatMessage['messageId'],
  ) => void;
  onDownloadFileFromMessagesToResend: (message: TemporaryChatMessage) => void;
  onClickInnovatorIcon: (innovator: ChatUser['innovator']) => void;
  disabledResendMessage: boolean;
  onScrollTop: () => void;
  messageListRef: RefObject<HTMLDivElement>;
  error: string | null;
  onRetry: () => void;
  unreadFirstMessageId: number | undefined;
  unreadMessageRef: React.RefObject<HTMLDivElement>;
};

const MessageList: FC<MessageListProps> = props => {
  const {
    chatRoom,
    messages = [],
    temporaryMessages = [],
    messagesToResend = [],
    getUploadProgress,
    onDeleteMessage,
    onDownloadFile,
    onResendMessage,
    onDeleteMessageToResend,
    onDownloadFileFromMessagesToResend,
    onClickInnovatorIcon,
    disabledResendMessage,
    onScrollTop,
    ownIconImage,
    messageListRef,
    showLoading,
    showUncontactableMessage,
    error,
    onRetry,
    unreadFirstMessageId,
    unreadMessageRef,
  } = props;

  const { t } = useTranslation(['chat', 'common']);

  const handleScroll = useCallback(
    (e: UIEvent<HTMLDivElement>) => {
      if (e.currentTarget && e.currentTarget.scrollTop === 0) {
        onScrollTop();
      }
    },
    [onScrollTop],
  );

  const MessageCards = useMemo(
    () =>
      messages.map((message: ChatMessage) => {
        return (
          <MessageCard
            key={message.messageId}
            onDeleteMessage={onDeleteMessage}
            onDownloadFile={onDownloadFile}
            chatRoom={chatRoom}
            message={message}
            ownIconImage={ownIconImage}
            onClickInnovatorIcon={onClickInnovatorIcon}
            unreadMessageRef={
              message.messageId === unreadFirstMessageId
                ? unreadMessageRef
                : null
            }
          />
        );
      }),
    [
      chatRoom,
      messages,
      onClickInnovatorIcon,
      onDeleteMessage,
      onDownloadFile,
      ownIconImage,
      unreadFirstMessageId,
      unreadMessageRef,
    ],
  );

  const TemporaryMessageCards = useMemo(
    () =>
      temporaryMessages.map((message: TemporaryChatMessage) => {
        const progress = getUploadProgress(message);
        return (
          <TemporaryMessageCard
            key={message.messageId}
            onResendMessage={onResendMessage}
            onDownloadFile={onDownloadFileFromMessagesToResend}
            onDeleteMessage={onDeleteMessageToResend}
            disabled={disabledResendMessage}
            message={message}
            ownIconImage={ownIconImage}
            resend={false}
            showProgress={progress !== undefined}
            progress={progress}
          />
        );
      }),
    [
      disabledResendMessage,
      getUploadProgress,
      onDeleteMessageToResend,
      onDownloadFileFromMessagesToResend,
      onResendMessage,
      ownIconImage,
      temporaryMessages,
    ],
  );

  const MessageToResendCards = useMemo(
    () =>
      messagesToResend.map((message: TemporaryChatMessage) => {
        const progress = getUploadProgress(message);
        return (
          <TemporaryMessageCard
            key={message.messageId}
            onResendMessage={onResendMessage}
            onDownloadFile={onDownloadFileFromMessagesToResend}
            onDeleteMessage={onDeleteMessageToResend}
            disabled={disabledResendMessage}
            message={message}
            ownIconImage={ownIconImage}
            resend
            showProgress={progress !== undefined}
            progress={progress}
          />
        );
      }),
    [
      disabledResendMessage,
      getUploadProgress,
      messagesToResend,
      onDeleteMessageToResend,
      onDownloadFileFromMessagesToResend,
      onResendMessage,
      ownIconImage,
    ],
  );

  const isAdminMessage = chatRoom.admin;
  const isUnpublishedOwnMission = chatRoom.unpublished;
  const isUnpublishedTalkToMission = !!chatRoom?.chatUser?.mission?.unpublished;
  const isUnpublishedTalkToInnovator = !!chatRoom?.chatUser?.innovator
    ?.unpublished;
  const isDeletedTalkToInnovator = !!chatRoom?.chatUser?.innovator?.deleted;

  const uncontactableMessage = useMemo(() => {
    if (isAdminMessage) {
      return t('description.unableToReplyToAdmin');
    }
    if (!showUncontactableMessage) return '';
    if (isUnpublishedOwnMission) {
      if (chatRoom?.chatUser?.mission) {
        return t('description.unpublishedOwnMissionForMission');
      }
      return t('description.unpublishedOwnMission');
    }
    if (isUnpublishedTalkToMission) {
      return t('description.unpublishedTalkToMission');
    }
    if (isDeletedTalkToInnovator) {
      return t('description.deletedTalkToInnovator');
    }
    if (isUnpublishedTalkToInnovator) {
      return t('description.unpublishedTalkToInnovator');
    }
    return '';
  }, [
    chatRoom,
    isAdminMessage,
    isDeletedTalkToInnovator,
    isUnpublishedOwnMission,
    isUnpublishedTalkToInnovator,
    isUnpublishedTalkToMission,
    showUncontactableMessage,
    t,
  ]);

  if (!chatRoom) return null;

  return (
    <Wrapper
      ref={messageListRef}
      onScroll={handleScroll}
      data-testid={`message-list-${chatRoom.chatRoomId}`}
    >
      {error && (
        <ErrorContainer>
          <ErrorMessage>{error}</ErrorMessage>
          <ButtonM className="button" buttonTheme="default" onClick={onRetry}>
            {t('common:button.retry')}
          </ButtonM>
        </ErrorContainer>
      )}
      {!error && showLoading && (
        <LoadingContainer>
          <ItemLoading size="40px" />
        </LoadingContainer>
      )}
      {MessageCards}
      {TemporaryMessageCards}
      {MessageToResendCards}
      {uncontactableMessage && (
        <UncontactableMessageWrapper>
          <UncontactableMessage>{uncontactableMessage}</UncontactableMessage>
        </UncontactableMessageWrapper>
      )}
    </Wrapper>
  );
};
export default MessageList;
