import styled from 'styled-components/macro';
import { Card } from 'visits-style';
import theme from 'styles/theme';

const StyledCard = styled(Card)`
  background: ${theme.baseWhite};
  font-size: 12px;
`;

export default StyledCard;
