import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components/macro';
import i18n from 'i18n/i18n';
import theme from 'styles/theme';
import typography from 'styles/typography';
import { CareerProfileForMatter } from 'proto/v1/apimodel/apimodel';
import {
  HumanResourceCardBodyItem,
  HumanResourceCardBodyItemLabel,
  HumanResourceCardBodyItemContent,
} from 'components/molecules';

type JobHistory = CareerProfileForMatter['job'][0];

const JobHistoryField = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 12px;

  &:last-child {
    margin-bottom: 0;
  }
`;

const JobHistoryFieldLabel = styled(HumanResourceCardBodyItemLabel)`
  width: 158px;
`;

const JobHistoryFieldContent = styled.div`
  ${typography.textDefault}
  color: ${theme.textBody};
  font-weight: normal;
`;

const formatOccupations = (jobHistory: JobHistory | undefined) => {
  if (!jobHistory || jobHistory.occupations.length === 0) {
    return '';
  }

  const isCurrentJob =
    jobHistory.endYear === undefined && jobHistory.endMonth === undefined;

  return jobHistory.occupations
    .map((occupation, index) => {
      const isLatestOccupation = index === 0;
      if (isCurrentJob && isLatestOccupation) {
        return `${occupation.occupation?.name} (${i18n.t(
          'matter:field.jobHistory.field.occupations.currentOccupation',
          {
            lng: 'ja',
          },
        )})`;
      }
      return occupation.occupation?.name;
    })
    .join(' / ');
};

type Props = {
  jobHistory: JobHistory | undefined;
  background: boolean;
};

const CareerJobHistoryField: FC<Props> = ({ jobHistory, background }) => {
  const { t } = useTranslation(['matter']);

  return (
    <HumanResourceCardBodyItem background={background}>
      <HumanResourceCardBodyItemLabel>
        {t('field.jobHistory.label', {
          lng: 'ja',
        })}
      </HumanResourceCardBodyItemLabel>
      <HumanResourceCardBodyItemContent>
        <JobHistoryField>
          <JobHistoryFieldLabel>
            {t('field.jobHistory.field.name.label', {
              lng: 'ja',
            })}
          </JobHistoryFieldLabel>
          <JobHistoryFieldContent>{jobHistory?.name}</JobHistoryFieldContent>
        </JobHistoryField>
        <JobHistoryField>
          <JobHistoryFieldLabel>
            {t('field.jobHistory.field.periods.label', {
              lng: 'ja',
            })}
          </JobHistoryFieldLabel>
          <JobHistoryFieldContent>
            {t('field.jobHistory.field.periods.yearMonth', {
              year: jobHistory?.startYear,
              month: jobHistory?.startMonth,
              lng: 'ja',
            })}
            {' 〜 '}
            {jobHistory?.endYear && jobHistory?.endMonth
              ? t('field.jobHistory.field.periods.yearMonth', {
                  year: jobHistory?.endYear,
                  month: jobHistory?.endMonth,
                  lng: 'ja',
                })
              : t('field.jobHistory.field.periods.current', {
                  lng: 'ja',
                })}
          </JobHistoryFieldContent>
        </JobHistoryField>
        <JobHistoryField>
          <JobHistoryFieldLabel>
            {t('field.jobHistory.field.occupations.label', {
              lng: 'ja',
            })}
          </JobHistoryFieldLabel>
          <JobHistoryFieldContent>
            {formatOccupations(jobHistory)}
          </JobHistoryFieldContent>
        </JobHistoryField>
        <JobHistoryField>
          <JobHistoryFieldLabel>
            {t('field.jobHistory.field.position.label', {
              lng: 'ja',
            })}
          </JobHistoryFieldLabel>
          <JobHistoryFieldContent>
            {jobHistory?.position?.name}
          </JobHistoryFieldContent>
        </JobHistoryField>
      </HumanResourceCardBodyItemContent>
    </HumanResourceCardBodyItem>
  );
};

export default CareerJobHistoryField;
