import React, { FC, ChangeEvent } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components/macro';
import theme from 'styles/theme';
import typography from 'styles/typography';
import { Followee } from 'types/follow';
import {
  Table,
  TableHead,
  TableCell,
  TableBody,
  TableRow,
  ButtonS,
  ButtonM,
  Checkbox,
} from 'components/atoms';
import { Spinner } from 'visits-style';
import {
  MissionCell,
  InterestCell,
  CategoryCell,
  MessageButtonCell,
} from 'components/molecules';
import { sortedMissionCategories } from 'utils/models/mission';
import { getContactReasons } from 'utils/models/contact';

const UnfollowButton = styled(ButtonS)`
  ${typography.textNote}
  position: relative;
  width: 38px;
  min-width: auto;
  height: 24px;
  padding: 4px 8px;
  border-radius: 3px;
`;

const UnFollowCheckbox = styled(Checkbox)`
  &&& {
    min-width: 18px;
    margin: 0;

    & > label > div {
      margin: 0 auto;
    }
  }
`;

const TableCellTh = styled(TableCell).attrs({ as: 'th' })`
  justify-content: flex-start;
  min-height: 0;

  &&& {
    height: 48px;
    padding: 14px 24px 13px;
    text-align: center;

    &:first-child {
      padding: 0 8px;
    }

    &:nth-child(2) {
      padding-left: 0;
    }
  }
`;

const LoadingInfoRow = styled(TableRow)`
  tbody tr&&& {
    display: block;
    min-height: 0;
    border-top: none;
  }
`;

const LoadingInfo = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 10px;
  margin-bottom: 20px;
`;

const NotfoundTableRow = styled(TableRow)`
  &:hover {
    background-color: transparent;
    cursor: default;
  }
`;

const MissionNotfound = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  transform: translateY(-50%) translateX(-50%);
  color: ${theme.textSecondary};
  line-height: 1.5;
  text-align: center;
`;

const LoadingMessage = styled.p`
  ${typography.textNote}
  margin-bottom: 8px;
  color: ${theme.textSecondary};
`;

const SpinnerContainer = styled.div`
  margin-top: 10px;
`;

const COLUMN_WIDTH = ['54px', '380px', '120px', '192px', '224px', '192px'];

type FollowingsTableProps = {
  followees: Followee[] | null;
  refTableBody?:
    | React.RefObject<HTMLTableSectionElement>
    | ((instance: HTMLTableSectionElement | null) => void);
  isFetchingNext: boolean;
  errorOnFetchNext: { message: string } | null;
  unFollowable: boolean;
  onChangeUnFollowChecked: (event: ChangeEvent<HTMLInputElement>) => void;
  onClickFollowee: (id: string, role: 'mission' | 'supporter') => void;
  onScrollTable?: () => void;
  onShowDeleteModal?: () => void;
  onClickRetry: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  onClickStartChat: (
    followeeAccountId: string,
    followeeChatRoomId: string,
  ) => void;
  onClickConfirmMessage: (folloee: Followee) => void;
};

type MemoizedFollowingProps = {
  followee: Followee;
  onClickFollowee: (id: string, role: 'mission' | 'supporter') => void;
  onChangeUnFollowChecked: (event: ChangeEvent<HTMLInputElement>) => void;
  onClickStartChat: (
    followeeAccountId: string,
    followeeChatRoomId: string,
  ) => void;
  onClickConfirmMessage: (folloee: Followee) => void;
};
const MemoizedFollowing = React.memo(
  ({
    followee,
    onClickFollowee,
    onChangeUnFollowChecked,
    onClickStartChat,
    onClickConfirmMessage,
  }: MemoizedFollowingProps) => {
    const { t } = useTranslation(['followersMissions']);
    const {
      followeeAccountId: accountId,
      mission,
      supporter,
      chatRoomId,
      isFollowed,
      followedAt,
      unFollowChecked,
    } = followee;
    if (!mission?.mission && !supporter?.supporter) return null;

    const attribute = (() => {
      if (supporter) return t('cell.supporterLabel');

      const attributes = mission?.mission?.corporateAttributes;
      return attributes && attributes.length > 0
        ? attributes[0].nameShort
        : '-';
    })();

    const interests = getContactReasons(followee);

    return (
      <TableRow data-testid="mission-following">
        <TableCell
          cellWidth={COLUMN_WIDTH[0]}
          style={{ justifyContent: 'center', padding: 0 }}
        >
          <UnFollowCheckbox
            onChange={event => {
              onChangeUnFollowChecked(event);
            }}
            name={accountId}
            checked={!!unFollowChecked}
            data-testid="checkbox-un-follow"
          />
        </TableCell>
        <MissionCell
          accountId={accountId}
          follower={mission?.mission ?? supporter?.supporter}
          followedAt={followedAt}
          onClick={onClickFollowee}
          cellWidth={COLUMN_WIDTH[1]}
        />
        <TableCell cellWidth={COLUMN_WIDTH[2]}>
          <p>{attribute}</p>
        </TableCell>
        <CategoryCell
          categories={sortedMissionCategories(
            mission?.mission?.missionCategories ?? [],
          )}
          cellWidth={COLUMN_WIDTH[3]}
        />
        <InterestCell interests={interests} cellWidth={COLUMN_WIDTH[4]} />
        <MessageButtonCell
          isFollowed={isFollowed}
          hasContactMessage={
            !!followee.mission?.contactMessage ||
            !!followee.supporter?.contactMessage
          }
          onClickStartChatButton={() => onClickStartChat(accountId, chatRoomId)}
          onClickConfirmMessage={() => onClickConfirmMessage(followee)}
          cellWidth={COLUMN_WIDTH[5]}
        />
      </TableRow>
    );
  },
  (prevProps, nextProps) =>
    prevProps.followee === nextProps.followee &&
    prevProps.onClickFollowee === nextProps.onClickFollowee &&
    prevProps.onChangeUnFollowChecked === nextProps.onChangeUnFollowChecked &&
    prevProps.onClickStartChat === nextProps.onClickStartChat &&
    prevProps.onClickConfirmMessage === nextProps.onClickConfirmMessage,
);

const FollowingsTable: FC<FollowingsTableProps> = ({
  followees,
  refTableBody,
  isFetchingNext,
  errorOnFetchNext,
  unFollowable,
  onClickFollowee,
  onScrollTable,
  onClickRetry,
  onShowDeleteModal,
  onChangeUnFollowChecked,
  onClickStartChat,
  onClickConfirmMessage,
}) => {
  const { t } = useTranslation(['followingMissions', 'common']);

  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableCellTh cellWidth={COLUMN_WIDTH[0]}>
            {unFollowable && (
              <UnfollowButton buttonTheme="default" onClick={onShowDeleteModal}>
                {t('button.unFollow')}
              </UnfollowButton>
            )}
          </TableCellTh>
          <TableCellTh cellWidth={COLUMN_WIDTH[1]}>
            {t('table.head.names')}
          </TableCellTh>
          <TableCellTh cellWidth={COLUMN_WIDTH[2]}>
            {t('table.head.corporateAttributes')}
          </TableCellTh>
          <TableCellTh cellWidth={COLUMN_WIDTH[3]}>
            {t('table.head.categories')}
          </TableCellTh>
          <TableCellTh cellWidth={COLUMN_WIDTH[4]}>
            {t('table.head.interests')}
          </TableCellTh>
          <TableCellTh cellWidth={COLUMN_WIDTH[5]}>
            {t('table.head.status')}
          </TableCellTh>
        </TableRow>
      </TableHead>
      <TableBody
        style={{ position: 'relative' }}
        ref={refTableBody}
        onScroll={onScrollTable}
        data-testid="missions-following"
      >
        {followees && followees.length === 0 ? (
          <NotfoundTableRow>
            <TableCell>
              <MissionNotfound>
                {t('description.missionsNotFound')}
              </MissionNotfound>
            </TableCell>
          </NotfoundTableRow>
        ) : (
          followees &&
          followees.map(followee => (
            <MemoizedFollowing
              key={`${followee.followeeAccountId}`}
              followee={followee}
              onClickFollowee={onClickFollowee}
              onChangeUnFollowChecked={onChangeUnFollowChecked}
              onClickStartChat={onClickStartChat}
              onClickConfirmMessage={onClickConfirmMessage}
            />
          ))
        )}
        {/* loadingInfoRow */}
        <LoadingInfoRow>
          {(isFetchingNext || errorOnFetchNext) && (
            <TableCell colSpan={7} style={{ width: '100%', display: 'block' }}>
              <LoadingInfo>
                {isFetchingNext && (
                  <SpinnerContainer>
                    <Spinner size="40px" />
                  </SpinnerContainer>
                )}
                {errorOnFetchNext && (
                  <>
                    <LoadingMessage>
                      <p>{errorOnFetchNext.message.replace('\n', '')}</p>
                    </LoadingMessage>
                    <ButtonM buttonTheme="default" onClick={onClickRetry}>
                      {t('common:button.retry')}
                    </ButtonM>
                  </>
                )}
              </LoadingInfo>
            </TableCell>
          )}
        </LoadingInfoRow>
      </TableBody>
    </Table>
  );
};

export default FollowingsTable;
