import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { HumanResourceMatter } from 'proto/v1/apimodel/apimodel';
import {
  HumanResourceCardBodyItem,
  HumanResourceCardBodyItemLabel,
  HumanResourceCardBodyItemContent,
} from 'components/molecules';

type SelfIntroductionFieldProps = {
  selfIntroduction: HumanResourceMatter['selfIntroduction'] | undefined;
  background?: boolean;
};

const SelfIntroductionField: FC<SelfIntroductionFieldProps> = props => {
  const { selfIntroduction, background = true } = props;
  const { t } = useTranslation(['matter']);

  return (
    <HumanResourceCardBodyItem background={background}>
      <HumanResourceCardBodyItemLabel>
        {t('field.selfIntroduction.label', {
          lng: 'ja',
        })}
      </HumanResourceCardBodyItemLabel>
      <HumanResourceCardBodyItemContent fontWeight="normal">
        {selfIntroduction}
      </HumanResourceCardBodyItemContent>
    </HumanResourceCardBodyItem>
  );
};

export default SelfIntroductionField;
