import React, { ChangeEvent, FC } from 'react';
import styled from 'styled-components/macro';
import { MissionDemandCollaborationAsset } from 'proto/v1/apimodel/apimodel';
import { useTranslation } from 'react-i18next';
import { isEqualProps } from 'utils/object';
import { MissionSettingMaxLength } from 'constants/form';
import { FlexContainer, Textarea, Checkbox } from 'components/atoms';
import { FormItem, MissionDemandFieldItem } from 'components/molecules';

const StyledCheckbox = styled(Checkbox)`
  &&& {
    margin-bottom: 0;
  }
`;

const StyledForm = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .input-fields {
    width: 100%;
  }
`;

const StyledFormItem = styled(FormItem)`
  &&& {
    min-height: 0;
    margin-bottom: 16px;
  }

  &&&:last-child {
    margin-bottom: 0;
  }
`;

const StyledTextarea = styled(Textarea)`
  position: relative;
  width: 445px;

  textarea {
    min-height: 97px;
    line-height: 1.5;
  }

  small {
    display: block;
    position: absolute;
    bottom: -14px;
    left: 0;
    width: auto;
  }
`;

type CollaborationFieldProps = {
  wantCollaboration: boolean;
  asset: MissionDemandCollaborationAsset;
  onChangeWantCollaboration: (e: ChangeEvent<HTMLInputElement>) => void;
  onChangeAssetGive: (e: ChangeEvent<HTMLTextAreaElement>) => void;
  onChangeAssetWant: (e: ChangeEvent<HTMLTextAreaElement>) => void;
  onBlur: (e: any) => void;
};
const CollaborationField: FC<CollaborationFieldProps> = props => {
  const {
    wantCollaboration,
    asset,
    onChangeWantCollaboration,
    onChangeAssetGive,
    onChangeAssetWant,
    onBlur,
  } = props;

  const { t } = useTranslation('missionSetting');

  return (
    <MissionDemandFieldItem>
      <FlexContainer flexDirection="column">
        <StyledCheckbox
          name="missionDemand.wantCollaboration"
          checked={wantCollaboration}
          onChange={onChangeWantCollaboration}
          aria-label={t('field.missionDemand.checkbox.wantCollaboration')}
        >
          {t('field.missionDemand.checkbox.wantCollaboration')}
        </StyledCheckbox>
        {wantCollaboration && (
          <>
            <StyledForm>
              <StyledFormItem>
                <StyledTextarea
                  name={`missionDemand.collaboration.assets[0].give`}
                  value={asset.give ?? ''}
                  maxLength={
                    MissionSettingMaxLength.MISSION_DEMAND_COLLABORATION_ASSETS_GIVE
                  }
                  placeholder={t(
                    'field.missionDemand.collaborationAssets.give.placeholder',
                    {
                      maxLength:
                        MissionSettingMaxLength.MISSION_DEMAND_COLLABORATION_ASSETS_GIVE,
                    },
                  )}
                  onChange={onChangeAssetGive}
                  onBlur={onBlur}
                  data-testid="collaboration-asset-give"
                />
              </StyledFormItem>
              <StyledFormItem>
                <StyledTextarea
                  name={`missionDemand.collaboration.assets[0].want`}
                  value={asset.want ?? ''}
                  maxLength={
                    MissionSettingMaxLength.MISSION_DEMAND_COLLABORATION_ASSETS_WANT
                  }
                  placeholder={t(
                    'field.missionDemand.collaborationAssets.want.placeholder',
                    {
                      maxLength:
                        MissionSettingMaxLength.MISSION_DEMAND_COLLABORATION_ASSETS_WANT,
                    },
                  )}
                  onChange={onChangeAssetWant}
                  onBlur={onBlur}
                  data-testid="collaboration-asset-want"
                />
              </StyledFormItem>
            </StyledForm>
          </>
        )}
      </FlexContainer>
    </MissionDemandFieldItem>
  );
};

export default React.memo(CollaborationField, (prevProps, nextProps) =>
  isEqualProps(prevProps, nextProps, ['wantCollaboration', 'asset']),
);
