import React, { FC } from 'react';
import styled from 'styled-components/macro';

const ImageList = styled.ul`
  display: flex;
  justify-content: center;
`;

const ImageContainer = styled.li`
  margin-right: 7px;

  /* To heighten image on hover. So color should be fixed pure white. */
  background-color: #fff;

  &:last-child {
    margin-right: 0;
  }
`;

const Image = styled.div<{
  imageSrc: string | undefined;
}>`
  width: 75px;
  height: 50px;
  background-image: url(${props => props.imageSrc});
  background-position: center;
  background-size: contain;
  cursor: pointer;

  &:hover {
    opacity: 0.7;
  }
`;

const ImageSelectorList: FC<{
  imageUrlList: string[];
  onSelectImage: ({ index }: { index: number }) => void;
}> = ({ imageUrlList, onSelectImage }) => {
  return (
    <ImageList>
      {imageUrlList.map((imageUrl, index) => (
        // eslint-disable-next-line react/no-array-index-key
        <ImageContainer key={index}>
          <Image
            imageSrc={imageUrl}
            onClick={event => {
              onSelectImage({ index });
            }}
            data-testid="office-image-list-item"
          />
        </ImageContainer>
      ))}
    </ImageList>
  );
};

export default ImageSelectorList;
