import { useCallback, useEffect, ChangeEvent } from 'react';
import { FormikTouched, FormikErrors } from 'formik';
import throttle from 'lodash.throttle';
import { ObjectSchema } from 'yup';
import { ValidateToSave } from '../validate/validateToSave';
import { MissionSettingFormik } from './useMissionSettingFormik';
import { MissionSettingFormState } from './useMissionSettingFormState';

type Payload<Values> = {
  values: Values;
  touched: FormikTouched<Values>;
  errors: FormikErrors<Values>;
  headerRef: MissionSettingFormState['headerRef'];
  formWrapperRef: MissionSettingFormState['formWrapperRef'];
  optionalFieldsTitleRef: MissionSettingFormState['optionalFieldsTitleRef'];
  dirty: MissionSettingFormik['dirty'];
  requiredSchema: ObjectSchema;
  setShowPreview: MissionSettingFormState['setShowPreview'];
  setValidateToSaveErrors: MissionSettingFormState['setValidateToSaveErrors'];
  setShowRequiredFieldsTitle: MissionSettingFormState['setShowRequiredFieldsTitle'];
  setFooterWidth: MissionSettingFormState['setFooterWidth'];
  openModal: MissionSettingFormState['openModal'];
  setFieldValue: MissionSettingFormik['setFieldValue'];
  setFieldTouched: MissionSettingFormik['setFieldTouched'];
  validateToSave: ValidateToSave<Values>;
};

const useMissionSettingFormFields = <Values>({
  values,
  touched,
  errors,
  headerRef,
  formWrapperRef,
  optionalFieldsTitleRef,
  dirty,
  requiredSchema,
  validateToSave,
  openModal,
  setShowPreview,
  setShowRequiredFieldsTitle,
  setFooterWidth,
  setValidateToSaveErrors,
  setFieldValue,
  setFieldTouched,
}: Payload<Values>) => {
  const headerElement = headerRef.current;
  const optionalFieldsTitleElement = optionalFieldsTitleRef.current;

  const handleScroll = useCallback(
    throttle(() => {
      const headerBottom = headerElement?.getBoundingClientRect().bottom;
      const optionalTitleBottom = optionalFieldsTitleElement?.getBoundingClientRect()
        .bottom;
      if (!headerBottom || !optionalTitleBottom) return;
      setShowRequiredFieldsTitle(headerBottom < optionalTitleBottom);
    }, 50),
    [headerElement, optionalFieldsTitleElement],
  );

  const handleChangeChecked = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const { name, checked } = event.target;
      setFieldValue(name, checked);
      // should set shouldValidate flag false to avoid incorrect validate errors
      setFieldTouched(name, true, false);
    },
    [setFieldTouched, setFieldValue],
  );

  const handleChangeSelectedValueAsNumber = useCallback(
    (event: ChangeEvent<HTMLSelectElement>) => {
      const { name, value } = event.target;
      setFieldValue(name, Number(value));
    },
    [setFieldValue],
  );

  const handleClickPreview = useCallback(() => setShowPreview(true), [
    setShowPreview,
  ]);

  const formError = useCallback(
    (key: keyof Values) =>
      (touched[key] ? errors[key] : undefined) as string | undefined,
    [errors, touched],
  );

  const saveInput = useCallback(() => {
    if (!dirty) {
      return;
    }
    const errorMessages = validateToSave(errors, values);
    if (Object.keys(errorMessages).length > 0) {
      setValidateToSaveErrors(errorMessages);
      openModal('invalidToSave');
      return;
    }
    if (!requiredSchema.isValidSync(values)) {
      openModal('saveRequiredNoInputConfirmation');
      return;
    }
    openModal('saveMissionConfirmation');
  }, [
    dirty,
    errors,
    openModal,
    requiredSchema,
    setValidateToSaveErrors,
    validateToSave,
    values,
  ]);

  const updateFooterWidth = useCallback(() => {
    setFooterWidth(formWrapperRef.current?.clientWidth);
  }, [formWrapperRef, setFooterWidth]);

  // Change footer width depending on formWrapper's width
  useEffect(() => {
    updateFooterWidth();
    window.addEventListener('resize', updateFooterWidth);
    return () => {
      window.removeEventListener('resize', updateFooterWidth);
    };
  }, [updateFooterWidth]);

  return {
    handleScroll,
    saveInput,
    formError,
    handleChangeChecked,
    handleChangeSelectedValueAsNumber,
    handleClickPreview,
  };
};

export default useMissionSettingFormFields;
