import { useCallback } from 'react';
import ImageTypes from 'constants/imageTypes';
import { MissionSettingFormState } from './useMissionSettingFormState';
import { MissionSettingFormik } from './useMissionSettingFormik';
import useImageUploader from './useImageUploader';

const getImageFieldByImageType = (type?: ImageTypes, index?: number) => {
  switch (type) {
    case ImageTypes.COVER:
      return 'coverImage';
    case ImageTypes.ICON:
      return 'iconImage';
    case ImageTypes.MEMBER_ICON:
      return `missionMembers[${index}].iconImage`;
    case ImageTypes.OFFICE:
      return `missionOfficeImages[${index}].image`;
    case ImageTypes.PRODUCT:
      return `missionProducts[${index}].image`;
  }
};

type Payload = {
  imageCropperState: MissionSettingFormState['imageCropperState'];
  openModal: MissionSettingFormState['openModal'];
  saveImageCropper: MissionSettingFormState['saveImageCropper'];
  closeImageCropper: MissionSettingFormState['closeImageCropper'];
  setFieldValue: MissionSettingFormik['setFieldValue'];
  setFieldTouched: MissionSettingFormik['setFieldTouched'];
};

const useUploadImage = ({
  imageCropperState,
  openModal,
  saveImageCropper,
  closeImageCropper,
  setFieldValue,
  setFieldTouched,
}: Payload) => {
  const { uploadImage } = useImageUploader();

  const handleExceedImageFileSize = useCallback(() => {
    openModal('imageFileSizeExceeded');
  }, [openModal]);

  const handleCroppedImage = useCallback(
    async (croppedImage: string) => {
      const { type, index, field } = imageCropperState;
      if (!type) return;
      saveImageCropper(croppedImage);
      const data = await uploadImage({
        type,
        data: croppedImage,
      });
      if (data) {
        const { images, key } = data;
        const imageField = field || getImageFieldByImageType(type, index);
        if (!imageField) return;
        setFieldValue(imageField, {
          ...images,
          key,
        });
        setFieldTouched(imageField, true, false);
        closeImageCropper();
      }
    },
    [
      closeImageCropper,
      imageCropperState,
      saveImageCropper,
      setFieldTouched,
      setFieldValue,
      uploadImage,
    ],
  );

  const handleClickCropperCloseButton = useCallback(
    (type?: ImageTypes, index?: number) => {
      const { field } = imageCropperState;
      closeImageCropper();
      const imageField = field || getImageFieldByImageType(type, index);
      if (!imageField) return;
      setFieldTouched(imageField, true);
    },
    [closeImageCropper, imageCropperState, setFieldTouched],
  );

  return {
    handleExceedImageFileSize,
    handleCroppedImage,
    handleClickCropperCloseButton,
  };
};

export default useUploadImage;
