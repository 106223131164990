import { CareerMattersFilter, NewGraduateMattersFilter, AcademicBackground, DttRank, Occupation } from '../../../proto/v1/apimodel/apimodel';
import { Reader, Writer } from 'protobufjs/minimal';
import * as Long from 'long';
import { Int32Value } from '../../../proto/google/protobuf/wrappers';


export interface ScoutMatterRequest {
  matterId: string;
  message: string;
}

export interface ScoutMatterResponse {
  chatRoomId: string;
}

export interface GetMattersForBulkScoutRequest {
  ids: GetMattersForBulkScoutRequest_IdsFilter | undefined;
  all: GetMattersForBulkScoutRequest_AllMattersFilter | undefined;
}

export interface GetMattersForBulkScoutRequest_IdsFilter {
  matterPublishIds: number[];
}

export interface GetMattersForBulkScoutRequest_AllMattersFilter {
  careerMattersFilter: CareerMattersFilter | undefined;
  newGraduateMattersFilter: NewGraduateMattersFilter | undefined;
  excludeMatterPublishIds: number[];
}

export interface GetMattersForBulkScoutResponse {
  matters: GetMattersForBulkScoutResponse_MatterForBulkScout[];
}

export interface GetMattersForBulkScoutResponse_MatterForBulkScout {
  matterId: string;
  career: GetMattersForBulkScoutResponse_CareerHumanResource | undefined;
  newGraduate: GetMattersForBulkScoutResponse_NewGraduateHumanResource | undefined;
}

export interface GetMattersForBulkScoutResponse_CareerHumanResource {
  jobHistory: GetMattersForBulkScoutResponse_JobHistory | undefined;
  academicBackground: AcademicBackground | undefined;
}

export interface GetMattersForBulkScoutResponse_NewGraduateHumanResource {
  academicBackground: AcademicBackground | undefined;
  dttRank: DttRank | undefined;
}

export interface GetMattersForBulkScoutResponse_JobHistory {
  name: string;
  startYear: number;
  startMonth: number;
  endYear: number | undefined;
  endMonth: number | undefined;
  occupation: Occupation | undefined;
}

export interface BulkScoutMatterRequest {
  matterIds: string[];
  message: string;
}

export interface BulkScoutMatterResponse {
}

const baseScoutMatterRequest: object = {
  matterId: "",
  message: "",
};

const baseScoutMatterResponse: object = {
  chatRoomId: "",
};

const baseGetMattersForBulkScoutRequest: object = {
};

const baseGetMattersForBulkScoutRequest_IdsFilter: object = {
  matterPublishIds: 0,
};

const baseGetMattersForBulkScoutRequest_AllMattersFilter: object = {
  excludeMatterPublishIds: 0,
};

const baseGetMattersForBulkScoutResponse: object = {
  matters: undefined,
};

const baseGetMattersForBulkScoutResponse_MatterForBulkScout: object = {
  matterId: "",
};

const baseGetMattersForBulkScoutResponse_CareerHumanResource: object = {
  jobHistory: undefined,
  academicBackground: undefined,
};

const baseGetMattersForBulkScoutResponse_NewGraduateHumanResource: object = {
  academicBackground: undefined,
  dttRank: undefined,
};

const baseGetMattersForBulkScoutResponse_JobHistory: object = {
  name: "",
  startYear: 0,
  startMonth: 0,
  endYear: undefined,
  endMonth: undefined,
  occupation: undefined,
};

const baseBulkScoutMatterRequest: object = {
  matterIds: "",
  message: "",
};

const baseBulkScoutMatterResponse: object = {
};

export interface MatterService {

  ScoutMatter(request: ScoutMatterRequest): Promise<ScoutMatterResponse>;

  GetMattersForBulkScout(request: GetMattersForBulkScoutRequest): Promise<GetMattersForBulkScoutResponse>;

  BulkScoutMatter(request: BulkScoutMatterRequest): Promise<BulkScoutMatterResponse>;

}

export class MatterServiceClientImpl implements MatterService {

  private readonly rpc: Rpc;

  constructor(rpc: Rpc) {
    this.rpc = rpc;
  }

  ScoutMatter(request: ScoutMatterRequest): Promise<ScoutMatterResponse> {
    const data = ScoutMatterRequest.encode(request).finish();
    const promise = this.rpc.request("matterservice.MatterService", "ScoutMatter", data);
    return promise.then(data => ScoutMatterResponse.decode(new Reader(data)));
  }

  GetMattersForBulkScout(request: GetMattersForBulkScoutRequest): Promise<GetMattersForBulkScoutResponse> {
    const data = GetMattersForBulkScoutRequest.encode(request).finish();
    const promise = this.rpc.request("matterservice.MatterService", "GetMattersForBulkScout", data);
    return promise.then(data => GetMattersForBulkScoutResponse.decode(new Reader(data)));
  }

  BulkScoutMatter(request: BulkScoutMatterRequest): Promise<BulkScoutMatterResponse> {
    const data = BulkScoutMatterRequest.encode(request).finish();
    const promise = this.rpc.request("matterservice.MatterService", "BulkScoutMatter", data);
    return promise.then(data => BulkScoutMatterResponse.decode(new Reader(data)));
  }

}

interface Rpc {

  request(service: string, method: string, data: Uint8Array): Promise<Uint8Array>;

}

function longToNumber(long: Long) {
  if (long.gt(Number.MAX_SAFE_INTEGER)) {
    throw new global.Error("Value is larger than Number.MAX_SAFE_INTEGER");
  }
  return long.toNumber();
}

export const ScoutMatterRequest = {
  encode(message: ScoutMatterRequest, writer: Writer = Writer.create()): Writer {
    writer.uint32(10).string(message.matterId);
    writer.uint32(26).string(message.message);
    return writer;
  },
  decode(reader: Reader, length?: number): ScoutMatterRequest {
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = Object.create(baseScoutMatterRequest) as ScoutMatterRequest;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.matterId = reader.string();
          break;
        case 3:
          message.message = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },
  fromJSON(object: any): ScoutMatterRequest {
    const message = Object.create(baseScoutMatterRequest) as ScoutMatterRequest;
    if (object.matterId) {
      message.matterId = String(object.matterId);
    }
    if (object.message) {
      message.message = String(object.message);
    }
    return message;
  },
  fromPartial(object: DeepPartial<ScoutMatterRequest>): ScoutMatterRequest {
    const message = Object.create(baseScoutMatterRequest) as ScoutMatterRequest;
    if (object.matterId) {
      message.matterId = object.matterId;
    }
    if (object.message) {
      message.message = object.message;
    }
    return message;
  },
  toJSON(message: ScoutMatterRequest): unknown {
    const obj: any = {};
    obj.matterId = message.matterId || "";
    obj.message = message.message || "";
    return obj;
  },
};

export const ScoutMatterResponse = {
  encode(message: ScoutMatterResponse, writer: Writer = Writer.create()): Writer {
    writer.uint32(10).string(message.chatRoomId);
    return writer;
  },
  decode(reader: Reader, length?: number): ScoutMatterResponse {
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = Object.create(baseScoutMatterResponse) as ScoutMatterResponse;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.chatRoomId = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },
  fromJSON(object: any): ScoutMatterResponse {
    const message = Object.create(baseScoutMatterResponse) as ScoutMatterResponse;
    if (object.chatRoomId) {
      message.chatRoomId = String(object.chatRoomId);
    }
    return message;
  },
  fromPartial(object: DeepPartial<ScoutMatterResponse>): ScoutMatterResponse {
    const message = Object.create(baseScoutMatterResponse) as ScoutMatterResponse;
    if (object.chatRoomId) {
      message.chatRoomId = object.chatRoomId;
    }
    return message;
  },
  toJSON(message: ScoutMatterResponse): unknown {
    const obj: any = {};
    obj.chatRoomId = message.chatRoomId || "";
    return obj;
  },
};

export const GetMattersForBulkScoutRequest = {
  encode(message: GetMattersForBulkScoutRequest, writer: Writer = Writer.create()): Writer {
    if (message.ids !== undefined && message.ids !== undefined) {
      GetMattersForBulkScoutRequest_IdsFilter.encode(message.ids, writer.uint32(10).fork()).ldelim();
    }
    if (message.all !== undefined && message.all !== undefined) {
      GetMattersForBulkScoutRequest_AllMattersFilter.encode(message.all, writer.uint32(18).fork()).ldelim();
    }
    return writer;
  },
  decode(reader: Reader, length?: number): GetMattersForBulkScoutRequest {
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = Object.create(baseGetMattersForBulkScoutRequest) as GetMattersForBulkScoutRequest;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.ids = GetMattersForBulkScoutRequest_IdsFilter.decode(reader, reader.uint32());
          break;
        case 2:
          message.all = GetMattersForBulkScoutRequest_AllMattersFilter.decode(reader, reader.uint32());
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },
  fromJSON(object: any): GetMattersForBulkScoutRequest {
    const message = Object.create(baseGetMattersForBulkScoutRequest) as GetMattersForBulkScoutRequest;
    if (object.ids) {
      message.ids = GetMattersForBulkScoutRequest_IdsFilter.fromJSON(object.ids);
    }
    if (object.all) {
      message.all = GetMattersForBulkScoutRequest_AllMattersFilter.fromJSON(object.all);
    }
    return message;
  },
  fromPartial(object: DeepPartial<GetMattersForBulkScoutRequest>): GetMattersForBulkScoutRequest {
    const message = Object.create(baseGetMattersForBulkScoutRequest) as GetMattersForBulkScoutRequest;
    if (object.ids) {
      message.ids = GetMattersForBulkScoutRequest_IdsFilter.fromPartial(object.ids);
    }
    if (object.all) {
      message.all = GetMattersForBulkScoutRequest_AllMattersFilter.fromPartial(object.all);
    }
    return message;
  },
  toJSON(message: GetMattersForBulkScoutRequest): unknown {
    const obj: any = {};
    obj.ids = message.ids ? GetMattersForBulkScoutRequest_IdsFilter.toJSON(message.ids) : undefined;
    obj.all = message.all ? GetMattersForBulkScoutRequest_AllMattersFilter.toJSON(message.all) : undefined;
    return obj;
  },
};

export const GetMattersForBulkScoutRequest_IdsFilter = {
  encode(message: GetMattersForBulkScoutRequest_IdsFilter, writer: Writer = Writer.create()): Writer {
    writer.uint32(10).fork();
    for (const v of message.matterPublishIds) {
      writer.int64(v);
    }
    writer.ldelim();
    return writer;
  },
  decode(reader: Reader, length?: number): GetMattersForBulkScoutRequest_IdsFilter {
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = Object.create(baseGetMattersForBulkScoutRequest_IdsFilter) as GetMattersForBulkScoutRequest_IdsFilter;
    message.matterPublishIds = [];
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if ((tag & 7) === 2) {
            const end2 = reader.uint32() + reader.pos;
            while (reader.pos < end2) {
              message.matterPublishIds.push(longToNumber(reader.int64() as Long));
            }
          } else {
            message.matterPublishIds.push(longToNumber(reader.int64() as Long));
          }
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },
  fromJSON(object: any): GetMattersForBulkScoutRequest_IdsFilter {
    const message = Object.create(baseGetMattersForBulkScoutRequest_IdsFilter) as GetMattersForBulkScoutRequest_IdsFilter;
    message.matterPublishIds = [];
    if (object.matterPublishIds) {
      for (const e of object.matterPublishIds) {
        message.matterPublishIds.push(Number(e));
      }
    }
    return message;
  },
  fromPartial(object: DeepPartial<GetMattersForBulkScoutRequest_IdsFilter>): GetMattersForBulkScoutRequest_IdsFilter {
    const message = Object.create(baseGetMattersForBulkScoutRequest_IdsFilter) as GetMattersForBulkScoutRequest_IdsFilter;
    message.matterPublishIds = [];
    if (object.matterPublishIds) {
      for (const e of object.matterPublishIds) {
        message.matterPublishIds.push(e);
      }
    }
    return message;
  },
  toJSON(message: GetMattersForBulkScoutRequest_IdsFilter): unknown {
    const obj: any = {};
    if (message.matterPublishIds) {
      obj.matterPublishIds = message.matterPublishIds.map(e => e || 0);
    } else {
      obj.matterPublishIds = [];
    }
    return obj;
  },
};

export const GetMattersForBulkScoutRequest_AllMattersFilter = {
  encode(message: GetMattersForBulkScoutRequest_AllMattersFilter, writer: Writer = Writer.create()): Writer {
    if (message.careerMattersFilter !== undefined && message.careerMattersFilter !== undefined) {
      CareerMattersFilter.encode(message.careerMattersFilter, writer.uint32(10).fork()).ldelim();
    }
    if (message.newGraduateMattersFilter !== undefined && message.newGraduateMattersFilter !== undefined) {
      NewGraduateMattersFilter.encode(message.newGraduateMattersFilter, writer.uint32(18).fork()).ldelim();
    }
    writer.uint32(26).fork();
    for (const v of message.excludeMatterPublishIds) {
      writer.int64(v);
    }
    writer.ldelim();
    return writer;
  },
  decode(reader: Reader, length?: number): GetMattersForBulkScoutRequest_AllMattersFilter {
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = Object.create(baseGetMattersForBulkScoutRequest_AllMattersFilter) as GetMattersForBulkScoutRequest_AllMattersFilter;
    message.excludeMatterPublishIds = [];
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.careerMattersFilter = CareerMattersFilter.decode(reader, reader.uint32());
          break;
        case 2:
          message.newGraduateMattersFilter = NewGraduateMattersFilter.decode(reader, reader.uint32());
          break;
        case 3:
          if ((tag & 7) === 2) {
            const end2 = reader.uint32() + reader.pos;
            while (reader.pos < end2) {
              message.excludeMatterPublishIds.push(longToNumber(reader.int64() as Long));
            }
          } else {
            message.excludeMatterPublishIds.push(longToNumber(reader.int64() as Long));
          }
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },
  fromJSON(object: any): GetMattersForBulkScoutRequest_AllMattersFilter {
    const message = Object.create(baseGetMattersForBulkScoutRequest_AllMattersFilter) as GetMattersForBulkScoutRequest_AllMattersFilter;
    message.excludeMatterPublishIds = [];
    if (object.careerMattersFilter) {
      message.careerMattersFilter = CareerMattersFilter.fromJSON(object.careerMattersFilter);
    }
    if (object.newGraduateMattersFilter) {
      message.newGraduateMattersFilter = NewGraduateMattersFilter.fromJSON(object.newGraduateMattersFilter);
    }
    if (object.excludeMatterPublishIds) {
      for (const e of object.excludeMatterPublishIds) {
        message.excludeMatterPublishIds.push(Number(e));
      }
    }
    return message;
  },
  fromPartial(object: DeepPartial<GetMattersForBulkScoutRequest_AllMattersFilter>): GetMattersForBulkScoutRequest_AllMattersFilter {
    const message = Object.create(baseGetMattersForBulkScoutRequest_AllMattersFilter) as GetMattersForBulkScoutRequest_AllMattersFilter;
    message.excludeMatterPublishIds = [];
    if (object.careerMattersFilter) {
      message.careerMattersFilter = CareerMattersFilter.fromPartial(object.careerMattersFilter);
    }
    if (object.newGraduateMattersFilter) {
      message.newGraduateMattersFilter = NewGraduateMattersFilter.fromPartial(object.newGraduateMattersFilter);
    }
    if (object.excludeMatterPublishIds) {
      for (const e of object.excludeMatterPublishIds) {
        message.excludeMatterPublishIds.push(e);
      }
    }
    return message;
  },
  toJSON(message: GetMattersForBulkScoutRequest_AllMattersFilter): unknown {
    const obj: any = {};
    obj.careerMattersFilter = message.careerMattersFilter ? CareerMattersFilter.toJSON(message.careerMattersFilter) : undefined;
    obj.newGraduateMattersFilter = message.newGraduateMattersFilter ? NewGraduateMattersFilter.toJSON(message.newGraduateMattersFilter) : undefined;
    if (message.excludeMatterPublishIds) {
      obj.excludeMatterPublishIds = message.excludeMatterPublishIds.map(e => e || 0);
    } else {
      obj.excludeMatterPublishIds = [];
    }
    return obj;
  },
};

export const GetMattersForBulkScoutResponse = {
  encode(message: GetMattersForBulkScoutResponse, writer: Writer = Writer.create()): Writer {
    for (const v of message.matters) {
      GetMattersForBulkScoutResponse_MatterForBulkScout.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },
  decode(reader: Reader, length?: number): GetMattersForBulkScoutResponse {
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = Object.create(baseGetMattersForBulkScoutResponse) as GetMattersForBulkScoutResponse;
    message.matters = [];
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.matters.push(GetMattersForBulkScoutResponse_MatterForBulkScout.decode(reader, reader.uint32()));
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },
  fromJSON(object: any): GetMattersForBulkScoutResponse {
    const message = Object.create(baseGetMattersForBulkScoutResponse) as GetMattersForBulkScoutResponse;
    message.matters = [];
    if (object.matters) {
      for (const e of object.matters) {
        message.matters.push(GetMattersForBulkScoutResponse_MatterForBulkScout.fromJSON(e));
      }
    }
    return message;
  },
  fromPartial(object: DeepPartial<GetMattersForBulkScoutResponse>): GetMattersForBulkScoutResponse {
    const message = Object.create(baseGetMattersForBulkScoutResponse) as GetMattersForBulkScoutResponse;
    message.matters = [];
    if (object.matters) {
      for (const e of object.matters) {
        message.matters.push(GetMattersForBulkScoutResponse_MatterForBulkScout.fromPartial(e));
      }
    }
    return message;
  },
  toJSON(message: GetMattersForBulkScoutResponse): unknown {
    const obj: any = {};
    if (message.matters) {
      obj.matters = message.matters.map(e => e ? GetMattersForBulkScoutResponse_MatterForBulkScout.toJSON(e) : undefined);
    } else {
      obj.matters = [];
    }
    return obj;
  },
};

export const GetMattersForBulkScoutResponse_MatterForBulkScout = {
  encode(message: GetMattersForBulkScoutResponse_MatterForBulkScout, writer: Writer = Writer.create()): Writer {
    writer.uint32(10).string(message.matterId);
    if (message.career !== undefined && message.career !== undefined) {
      GetMattersForBulkScoutResponse_CareerHumanResource.encode(message.career, writer.uint32(18).fork()).ldelim();
    }
    if (message.newGraduate !== undefined && message.newGraduate !== undefined) {
      GetMattersForBulkScoutResponse_NewGraduateHumanResource.encode(message.newGraduate, writer.uint32(26).fork()).ldelim();
    }
    return writer;
  },
  decode(reader: Reader, length?: number): GetMattersForBulkScoutResponse_MatterForBulkScout {
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = Object.create(baseGetMattersForBulkScoutResponse_MatterForBulkScout) as GetMattersForBulkScoutResponse_MatterForBulkScout;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.matterId = reader.string();
          break;
        case 2:
          message.career = GetMattersForBulkScoutResponse_CareerHumanResource.decode(reader, reader.uint32());
          break;
        case 3:
          message.newGraduate = GetMattersForBulkScoutResponse_NewGraduateHumanResource.decode(reader, reader.uint32());
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },
  fromJSON(object: any): GetMattersForBulkScoutResponse_MatterForBulkScout {
    const message = Object.create(baseGetMattersForBulkScoutResponse_MatterForBulkScout) as GetMattersForBulkScoutResponse_MatterForBulkScout;
    if (object.matterId) {
      message.matterId = String(object.matterId);
    }
    if (object.career) {
      message.career = GetMattersForBulkScoutResponse_CareerHumanResource.fromJSON(object.career);
    }
    if (object.newGraduate) {
      message.newGraduate = GetMattersForBulkScoutResponse_NewGraduateHumanResource.fromJSON(object.newGraduate);
    }
    return message;
  },
  fromPartial(object: DeepPartial<GetMattersForBulkScoutResponse_MatterForBulkScout>): GetMattersForBulkScoutResponse_MatterForBulkScout {
    const message = Object.create(baseGetMattersForBulkScoutResponse_MatterForBulkScout) as GetMattersForBulkScoutResponse_MatterForBulkScout;
    if (object.matterId) {
      message.matterId = object.matterId;
    }
    if (object.career) {
      message.career = GetMattersForBulkScoutResponse_CareerHumanResource.fromPartial(object.career);
    }
    if (object.newGraduate) {
      message.newGraduate = GetMattersForBulkScoutResponse_NewGraduateHumanResource.fromPartial(object.newGraduate);
    }
    return message;
  },
  toJSON(message: GetMattersForBulkScoutResponse_MatterForBulkScout): unknown {
    const obj: any = {};
    obj.matterId = message.matterId || "";
    obj.career = message.career ? GetMattersForBulkScoutResponse_CareerHumanResource.toJSON(message.career) : undefined;
    obj.newGraduate = message.newGraduate ? GetMattersForBulkScoutResponse_NewGraduateHumanResource.toJSON(message.newGraduate) : undefined;
    return obj;
  },
};

export const GetMattersForBulkScoutResponse_CareerHumanResource = {
  encode(message: GetMattersForBulkScoutResponse_CareerHumanResource, writer: Writer = Writer.create()): Writer {
    if (message.jobHistory !== undefined && message.jobHistory !== undefined) {
      GetMattersForBulkScoutResponse_JobHistory.encode(message.jobHistory, writer.uint32(10).fork()).ldelim();
    }
    if (message.academicBackground !== undefined && message.academicBackground !== undefined) {
      AcademicBackground.encode(message.academicBackground, writer.uint32(18).fork()).ldelim();
    }
    return writer;
  },
  decode(reader: Reader, length?: number): GetMattersForBulkScoutResponse_CareerHumanResource {
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = Object.create(baseGetMattersForBulkScoutResponse_CareerHumanResource) as GetMattersForBulkScoutResponse_CareerHumanResource;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.jobHistory = GetMattersForBulkScoutResponse_JobHistory.decode(reader, reader.uint32());
          break;
        case 2:
          message.academicBackground = AcademicBackground.decode(reader, reader.uint32());
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },
  fromJSON(object: any): GetMattersForBulkScoutResponse_CareerHumanResource {
    const message = Object.create(baseGetMattersForBulkScoutResponse_CareerHumanResource) as GetMattersForBulkScoutResponse_CareerHumanResource;
    if (object.jobHistory) {
      message.jobHistory = GetMattersForBulkScoutResponse_JobHistory.fromJSON(object.jobHistory);
    }
    if (object.academicBackground) {
      message.academicBackground = AcademicBackground.fromJSON(object.academicBackground);
    }
    return message;
  },
  fromPartial(object: DeepPartial<GetMattersForBulkScoutResponse_CareerHumanResource>): GetMattersForBulkScoutResponse_CareerHumanResource {
    const message = Object.create(baseGetMattersForBulkScoutResponse_CareerHumanResource) as GetMattersForBulkScoutResponse_CareerHumanResource;
    if (object.jobHistory) {
      message.jobHistory = GetMattersForBulkScoutResponse_JobHistory.fromPartial(object.jobHistory);
    }
    if (object.academicBackground) {
      message.academicBackground = AcademicBackground.fromPartial(object.academicBackground);
    }
    return message;
  },
  toJSON(message: GetMattersForBulkScoutResponse_CareerHumanResource): unknown {
    const obj: any = {};
    obj.jobHistory = message.jobHistory ? GetMattersForBulkScoutResponse_JobHistory.toJSON(message.jobHistory) : undefined;
    obj.academicBackground = message.academicBackground ? AcademicBackground.toJSON(message.academicBackground) : undefined;
    return obj;
  },
};

export const GetMattersForBulkScoutResponse_NewGraduateHumanResource = {
  encode(message: GetMattersForBulkScoutResponse_NewGraduateHumanResource, writer: Writer = Writer.create()): Writer {
    if (message.academicBackground !== undefined && message.academicBackground !== undefined) {
      AcademicBackground.encode(message.academicBackground, writer.uint32(10).fork()).ldelim();
    }
    if (message.dttRank !== undefined && message.dttRank !== undefined) {
      DttRank.encode(message.dttRank, writer.uint32(18).fork()).ldelim();
    }
    return writer;
  },
  decode(reader: Reader, length?: number): GetMattersForBulkScoutResponse_NewGraduateHumanResource {
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = Object.create(baseGetMattersForBulkScoutResponse_NewGraduateHumanResource) as GetMattersForBulkScoutResponse_NewGraduateHumanResource;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.academicBackground = AcademicBackground.decode(reader, reader.uint32());
          break;
        case 2:
          message.dttRank = DttRank.decode(reader, reader.uint32());
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },
  fromJSON(object: any): GetMattersForBulkScoutResponse_NewGraduateHumanResource {
    const message = Object.create(baseGetMattersForBulkScoutResponse_NewGraduateHumanResource) as GetMattersForBulkScoutResponse_NewGraduateHumanResource;
    if (object.academicBackground) {
      message.academicBackground = AcademicBackground.fromJSON(object.academicBackground);
    }
    if (object.dttRank) {
      message.dttRank = DttRank.fromJSON(object.dttRank);
    }
    return message;
  },
  fromPartial(object: DeepPartial<GetMattersForBulkScoutResponse_NewGraduateHumanResource>): GetMattersForBulkScoutResponse_NewGraduateHumanResource {
    const message = Object.create(baseGetMattersForBulkScoutResponse_NewGraduateHumanResource) as GetMattersForBulkScoutResponse_NewGraduateHumanResource;
    if (object.academicBackground) {
      message.academicBackground = AcademicBackground.fromPartial(object.academicBackground);
    }
    if (object.dttRank) {
      message.dttRank = DttRank.fromPartial(object.dttRank);
    }
    return message;
  },
  toJSON(message: GetMattersForBulkScoutResponse_NewGraduateHumanResource): unknown {
    const obj: any = {};
    obj.academicBackground = message.academicBackground ? AcademicBackground.toJSON(message.academicBackground) : undefined;
    obj.dttRank = message.dttRank ? DttRank.toJSON(message.dttRank) : undefined;
    return obj;
  },
};

export const GetMattersForBulkScoutResponse_JobHistory = {
  encode(message: GetMattersForBulkScoutResponse_JobHistory, writer: Writer = Writer.create()): Writer {
    writer.uint32(10).string(message.name);
    writer.uint32(16).uint32(message.startYear);
    writer.uint32(24).uint32(message.startMonth);
    if (message.endYear !== undefined && message.endYear !== undefined) {
      Int32Value.encode({ value: message.endYear! }, writer.uint32(34).fork()).ldelim();
    }
    if (message.endMonth !== undefined && message.endMonth !== undefined) {
      Int32Value.encode({ value: message.endMonth! }, writer.uint32(42).fork()).ldelim();
    }
    if (message.occupation !== undefined && message.occupation !== undefined) {
      Occupation.encode(message.occupation, writer.uint32(50).fork()).ldelim();
    }
    return writer;
  },
  decode(reader: Reader, length?: number): GetMattersForBulkScoutResponse_JobHistory {
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = Object.create(baseGetMattersForBulkScoutResponse_JobHistory) as GetMattersForBulkScoutResponse_JobHistory;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.name = reader.string();
          break;
        case 2:
          message.startYear = reader.uint32();
          break;
        case 3:
          message.startMonth = reader.uint32();
          break;
        case 4:
          message.endYear = Int32Value.decode(reader, reader.uint32()).value;
          break;
        case 5:
          message.endMonth = Int32Value.decode(reader, reader.uint32()).value;
          break;
        case 6:
          message.occupation = Occupation.decode(reader, reader.uint32());
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },
  fromJSON(object: any): GetMattersForBulkScoutResponse_JobHistory {
    const message = Object.create(baseGetMattersForBulkScoutResponse_JobHistory) as GetMattersForBulkScoutResponse_JobHistory;
    if (object.name) {
      message.name = String(object.name);
    }
    if (object.startYear) {
      message.startYear = Number(object.startYear);
    }
    if (object.startMonth) {
      message.startMonth = Number(object.startMonth);
    }
    if (object.endYear) {
      message.endYear = Number(object.endYear);
    }
    if (object.endMonth) {
      message.endMonth = Number(object.endMonth);
    }
    if (object.occupation) {
      message.occupation = Occupation.fromJSON(object.occupation);
    }
    return message;
  },
  fromPartial(object: DeepPartial<GetMattersForBulkScoutResponse_JobHistory>): GetMattersForBulkScoutResponse_JobHistory {
    const message = Object.create(baseGetMattersForBulkScoutResponse_JobHistory) as GetMattersForBulkScoutResponse_JobHistory;
    if (object.name) {
      message.name = object.name;
    }
    if (object.startYear) {
      message.startYear = object.startYear;
    }
    if (object.startMonth) {
      message.startMonth = object.startMonth;
    }
    if (object.endYear) {
      message.endYear = object.endYear;
    }
    if (object.endMonth) {
      message.endMonth = object.endMonth;
    }
    if (object.occupation) {
      message.occupation = Occupation.fromPartial(object.occupation);
    }
    return message;
  },
  toJSON(message: GetMattersForBulkScoutResponse_JobHistory): unknown {
    const obj: any = {};
    obj.name = message.name || "";
    obj.startYear = message.startYear || 0;
    obj.startMonth = message.startMonth || 0;
    obj.endYear = message.endYear || undefined;
    obj.endMonth = message.endMonth || undefined;
    obj.occupation = message.occupation ? Occupation.toJSON(message.occupation) : undefined;
    return obj;
  },
};

export const BulkScoutMatterRequest = {
  encode(message: BulkScoutMatterRequest, writer: Writer = Writer.create()): Writer {
    for (const v of message.matterIds) {
      writer.uint32(10).string(v!);
    }
    writer.uint32(18).string(message.message);
    return writer;
  },
  decode(reader: Reader, length?: number): BulkScoutMatterRequest {
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = Object.create(baseBulkScoutMatterRequest) as BulkScoutMatterRequest;
    message.matterIds = [];
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.matterIds.push(reader.string());
          break;
        case 2:
          message.message = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },
  fromJSON(object: any): BulkScoutMatterRequest {
    const message = Object.create(baseBulkScoutMatterRequest) as BulkScoutMatterRequest;
    message.matterIds = [];
    if (object.matterIds) {
      for (const e of object.matterIds) {
        message.matterIds.push(String(e));
      }
    }
    if (object.message) {
      message.message = String(object.message);
    }
    return message;
  },
  fromPartial(object: DeepPartial<BulkScoutMatterRequest>): BulkScoutMatterRequest {
    const message = Object.create(baseBulkScoutMatterRequest) as BulkScoutMatterRequest;
    message.matterIds = [];
    if (object.matterIds) {
      for (const e of object.matterIds) {
        message.matterIds.push(e);
      }
    }
    if (object.message) {
      message.message = object.message;
    }
    return message;
  },
  toJSON(message: BulkScoutMatterRequest): unknown {
    const obj: any = {};
    if (message.matterIds) {
      obj.matterIds = message.matterIds.map(e => e || "");
    } else {
      obj.matterIds = [];
    }
    obj.message = message.message || "";
    return obj;
  },
};

export const BulkScoutMatterResponse = {
  encode(message: BulkScoutMatterResponse, writer: Writer = Writer.create()): Writer {
    return writer;
  },
  decode(reader: Reader, length?: number): BulkScoutMatterResponse {
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = Object.create(baseBulkScoutMatterResponse) as BulkScoutMatterResponse;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },
  fromJSON(object: any): BulkScoutMatterResponse {
    const message = Object.create(baseBulkScoutMatterResponse) as BulkScoutMatterResponse;
    return message;
  },
  fromPartial(object: DeepPartial<BulkScoutMatterResponse>): BulkScoutMatterResponse {
    const message = Object.create(baseBulkScoutMatterResponse) as BulkScoutMatterResponse;
    return message;
  },
  toJSON(message: BulkScoutMatterResponse): unknown {
    const obj: any = {};
    return obj;
  },
};

type DeepPartial<T> = {
  [P in keyof T]?: T[P] extends Array<infer U>
  ? Array<DeepPartial<U>>
  : T[P] extends ReadonlyArray<infer U>
  ? ReadonlyArray<DeepPartial<U>>
  : T[P] extends Date | Function | Uint8Array | undefined
  ? T[P]
  : T[P] extends infer U | undefined
  ? DeepPartial<U>
  : T[P] extends object
  ? DeepPartial<T[P]>
  : T[P]
};