import styled from 'styled-components/macro';
import theme from 'styles/theme';
import typography from 'styles/typography';

const PickerHeading = styled.div`
  ${typography.textBold}
  display: flex;
  align-items: center;
  padding: 16px 32px;
  background-color: ${theme.bgPickerHeading};
`;

export default PickerHeading;
