import { css, FlattenSimpleInterpolation } from 'styled-components/macro';
import theme from 'styles/theme';

const themeDefault = css`
  background: ${theme.basePrimary};
  color: ${theme.white};

  &:hover {
    background: ${theme.buttonDefaultHover};
  }

  &:disabled {
    background: ${theme.baseDisabled};
  }
`;

const themeGradation = css`
  background: linear-gradient(
    to right,
    rgb(144, 113, 212) 0%,
    rgb(125, 140, 222) 40%,
    rgb(145, 215, 226) 100%
  );
  color: ${theme.white};

  &:hover {
    background: linear-gradient(
      to right,
      rgb(144, 113, 212, 0.8) 0%,
      rgb(125, 140, 222, 0.8) 40%,
      rgb(145, 215, 226, 0.8) 100%
    );
  }

  &:disabled {
    background: ${theme.baseDisabled};
  }
`;

const themeCancel = css`
  border: 1px solid ${theme.borderDefault};
  background: ${theme.white};
  color: ${theme.textPrimary};

  &:hover {
    background: ${theme.buttonCancelHover};
  }

  &:disabled {
    background: ${theme.baseDisabled};
  }
`;

export type ThemesType = 'default' | 'gradation' | 'cancel';

const buttonThemes: { [key in ThemesType]?: FlattenSimpleInterpolation } = {
  default: themeDefault,
  gradation: themeGradation,
  cancel: themeCancel,
};

export default buttonThemes;
