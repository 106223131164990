import { object } from 'yup';
import {
  companyNameSchema,
  companyEstablishedDateWithCorporateAttributesSchema,
  companyIdSchema,
  japanPrefectureIdSchema,
  otherAddressSchema,
  fundIdSchema,
  iconImageSchema,
  companyUrlSchema,
  missionNameSchema,
  missionDescriptionSchema,
  strengthPointSchema,
  memberRangeIdSchema,
  coverImageSchema,
  missionMembersSchema,
  missionCategoriesSchema,
  missionProductsSchema,
  missionDemandSchema,
  missionJobOffersSchema,
} from '../validate/fieldSchemas';

export const stepCompanySchema = object({
  companyName: companyNameSchema,
  companyEstablishedDate: companyEstablishedDateWithCorporateAttributesSchema,
  countryId: companyIdSchema,
  japanPrefectureId: japanPrefectureIdSchema,
  otherAddress: otherAddressSchema,
  fundId: fundIdSchema,
  iconImage: iconImageSchema,
  companyUrl: companyUrlSchema,
});

export const stepAboutMissionRequiredSchema = object({
  name: missionNameSchema,
  missionDescription: missionDescriptionSchema,
  strengthPoint: strengthPointSchema,
  memberRangeId: memberRangeIdSchema,
  coverImage: coverImageSchema,
  missionCategories: missionCategoriesSchema,
});

export const stepAboutMissionOptionalSchema = object({
  missionMembers: missionMembersSchema,
});

export const stepAboutMissionSchema = object({})
  .concat(stepAboutMissionRequiredSchema)
  .concat(stepAboutMissionOptionalSchema);

export const stepMissionProductsSchema = object({
  missionProducts: missionProductsSchema,
});

export const stepMissionJobOffersSchema = object({
  missionJobOffers: missionJobOffersSchema,
});

export const stepMissionDemandSchema = object({
  missionDemand: missionDemandSchema,
});
