import { AccountFollowee, AccountFollower } from 'proto/v1/apimodel/apimodel';
import { sortedSupportAreas } from './supporter';

// eslint-disable-next-line import/prefer-default-export
export const getContactReasons = (
  followerOrFollowee: AccountFollower | AccountFollowee,
) => {
  const { mission, supporter } = followerOrFollowee;
  if (mission) {
    return mission.supportAreas.length > 0
      ? sortedSupportAreas(mission.supportAreas)
      : mission.interests;
  }
  return sortedSupportAreas(supporter?.supportAreas ?? []);
};
