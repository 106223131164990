import { Supporter } from 'proto/v1/apimodel/apimodel';
import { SupporterFormValues } from 'types/supporter';
import {
  convertJapanPrefecture,
  convertCompanyEstablishDate,
  convertSupportAreas,
} from '../convertToFormValue';

const convertSupporterToSupporterFormValues: (
  supporter: Supporter,
) => SupporterFormValues = supporter => {
  return {
    supporterId: supporter.supporterId,
    name: supporter.name,
    supportDescription: supporter.supportDescription,
    iconImage: supporter.iconImage,
    coverImage: supporter.coverImage,
    strengthPoint: supporter.strengthPoint,
    companyName: supporter.companyName,
    companyUrl: supporter.companyUrl,
    companyEstablishedDate: convertCompanyEstablishDate(
      supporter.companyEstablishedDate,
    ),
    countryId: supporter.country?.countryId ?? '',
    japanPrefectureId: convertJapanPrefecture(supporter.japanPrefecture),
    otherAddress: supporter.otherAddress,
    memberRangeId: supporter.memberRange?.memberRangeId ?? 0,
    supportAreaIds: convertSupportAreas(supporter.supportAreas),
    supporterMembers: supporter.supporterMembers,
    galleryImages: supporter.galleryImages,
  };
};

export default convertSupporterToSupporterFormValues;
