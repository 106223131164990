import React, { forwardRef, ChangeEvent } from 'react';
import styled from 'styled-components/macro';
import { MissionForm } from 'types/mission';
import { isEqualProps } from 'utils/object';
import { MissionDemandFormValue, MissionDemandError } from 'types/form';
import { MissionSettingFormItem } from 'components/molecules';
import {
  CollaborationField,
  ServiceInformationField,
  InvestField,
  FundraisingField,
  TransferredOfBusinessField,
  TransferOfBusinessField,
  AcceptSecondmentField,
  SendEmployeeField,
  ServiceSuggestionField,
  FurnishedOfficeRentalAvailabilityField,
  FurnishedOfficeRentalRequestField,
} from './SubFields';

const Wrapper = styled.div``;

const Layout = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 24px;
`;

type MissionDemandFieldProps = {
  values: MissionDemandFormValue | undefined;
  financingSeriesForm: MissionForm['financingSeries'];
  fundForm: MissionForm['funds'];
  errors: MissionDemandError;
  onChangeChecked: (e: ChangeEvent<HTMLInputElement>) => void;
  onAddCollaborationAsset: () => void;
  onDeleteCollaborationAsset: (index: number) => void;
  onChangeCollaborationAssetGive: (e: ChangeEvent<HTMLTextAreaElement>) => void;
  onChangeCollaborationAssetWant: (e: ChangeEvent<HTMLTextAreaElement>) => void;
  onChangeWantInvest: (e: ChangeEvent<HTMLInputElement>) => void;
  onChangeWantFundraising: (e: ChangeEvent<HTMLInputElement>) => void;
  onChangeFinancingSeries: (e: ChangeEvent<HTMLSelectElement>) => void;
  onAddServiceInformationIssue: () => void;
  onDeleteServiceInformationIssue: (index: number) => void;
  onChangeServiceInformationIssue: (
    e: ChangeEvent<HTMLTextAreaElement>,
  ) => void;
  onChangeWantTransferredOfBusiness: (e: ChangeEvent<HTMLInputElement>) => void;
  onChangeTransferredOfBusinessFund: (
    e: ChangeEvent<HTMLSelectElement>,
  ) => void;
  onChangeAcceptSecondmentWork: (e: ChangeEvent<HTMLTextAreaElement>) => void;
  onChangeSendEmployeeWork: (e: ChangeEvent<HTMLTextAreaElement>) => void;
  onBlur: (e: any) => void;
};

const MissionDemandField = forwardRef<HTMLDivElement, MissionDemandFieldProps>(
  (props, ref) => {
    const {
      values,
      financingSeriesForm,
      fundForm,
      errors,
      onChangeCollaborationAssetGive,
      onChangeCollaborationAssetWant,
      onChangeChecked,
      onChangeWantInvest,
      onChangeWantFundraising,
      onChangeFinancingSeries,
      onChangeServiceInformationIssue,
      onChangeWantTransferredOfBusiness,
      onChangeTransferredOfBusinessFund,
      onChangeAcceptSecondmentWork,
      onChangeSendEmployeeWork,
      onBlur,
    } = props;

    return (
      <Wrapper ref={ref}>
        <MissionSettingFormItem
          required
          style={{ width: '100%', marginBottom: 0 }}
        >
          <Layout>
            <div>
              <CollaborationField
                wantCollaboration={!!values?.wantCollaboration}
                asset={
                  values?.collaboration?.assets[0] ?? { want: '', give: '' }
                }
                onChangeWantCollaboration={onChangeChecked}
                onChangeAssetGive={onChangeCollaborationAssetGive}
                onChangeAssetWant={onChangeCollaborationAssetWant}
                onBlur={onBlur}
              />

              <FundraisingField
                wantFundraising={!!values?.wantFundraising}
                financingSeriesId={values?.fundraising?.financingSeriesId || 0}
                error={errors?.wantFundraising}
                form={financingSeriesForm ?? []}
                onBlur={onBlur}
                onChangeWantFundraising={onChangeWantFundraising}
                onChangeFinancingSeries={onChangeFinancingSeries}
              />

              <InvestField
                wantInvest={values?.wantInvest ?? false}
                wantInvestSeed={values?.wantInvestSeed ?? false}
                wantInvestEarly={values?.wantInvestEarly ?? false}
                wantInvestMiddle={values?.wantInvestMiddle ?? false}
                wantInvestLater={values?.wantInvestLater ?? false}
                onChangeWantInvest={onChangeWantInvest}
                onChangeChecked={onChangeChecked}
                error={errors?.wantInvest}
              />

              <ServiceSuggestionField
                wantServiceSuggestion={values?.wantServiceSuggestion ?? false}
                error={errors.wantServiceSuggestion}
                onChangeWantServiceSuggestion={onChangeChecked}
              />

              <ServiceInformationField
                wantServiceInformation={values?.wantServiceInformation ?? false}
                issue={values?.serviceInformation?.issues[0] ?? ''}
                onChangeWantServiceInformation={onChangeChecked}
                onChangeIssue={onChangeServiceInformationIssue}
                onBlur={onBlur}
              />

              <TransferOfBusinessField
                value={values?.wantTransferOfBusiness || false}
                onChange={onChangeChecked}
              />
            </div>

            <div>
              <TransferredOfBusinessField
                wantTransferredOfBusiness={
                  values?.wantTransferredOfBusiness ?? false
                }
                fundId={values?.transferredOfBusiness?.fundId ?? 0}
                form={fundForm}
                onChangeWantTransferredOfBusiness={
                  onChangeWantTransferredOfBusiness
                }
                onChangeTransferredOfBusinessFund={
                  onChangeTransferredOfBusinessFund
                }
                onBlur={onBlur}
              />

              <AcceptSecondmentField
                wantAcceptSecondment={values?.wantAcceptSecondment ?? false}
                work={values?.acceptSecondment?.work ?? ''}
                onChangeWantAcceptSecondment={onChangeChecked}
                onChangeWork={onChangeAcceptSecondmentWork}
                onBlur={onBlur}
              />

              <SendEmployeeField
                wantSendEmployee={values?.wantSendEmployee ?? false}
                work={values?.sendEmployee?.work ?? ''}
                onChangeWantSendEmployee={onChangeChecked}
                onChangeWork={onChangeSendEmployeeWork}
                onBlur={onBlur}
              />

              <FurnishedOfficeRentalAvailabilityField
                value={values?.wantFurnishedOfficeRentalAvailability ?? false}
                error={errors.wantFurnishedOfficeRentalAvailability}
                onChange={onChangeChecked}
              />

              <FurnishedOfficeRentalRequestField
                value={values?.wantFurnishedOfficeRentalRequest ?? false}
                onChange={onChangeChecked}
              />
            </div>
          </Layout>
        </MissionSettingFormItem>
      </Wrapper>
    );
  },
);

export default React.memo(MissionDemandField, (prevProps, nextProps) =>
  isEqualProps(prevProps, nextProps, [
    'values',
    'errors',
    'financingSeriesForm',
    'fundForm',
  ]),
);
