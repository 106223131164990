import styled, { css } from 'styled-components/macro';
import theme from 'styles/theme';
import { FlexContainer } from 'components/atoms';
import typography from 'styles/typography';

const MissionSettingMenuItem = styled(FlexContainer)<{ disabled?: boolean }>`
  ${typography.labelDefault}
  align-items: center;
  height: 32px;
  padding: 0 16px;
  white-space: nowrap;
  cursor: pointer;
  user-select: none;

  &:hover {
    background-color: ${theme.bgMissionListHover};
  }

  ${({ disabled = false }) =>
    disabled &&
    css`
      color: ${theme.textSecondaryDisabled};
      cursor: default;
      pointer-events: none;
    `}
`;

export default MissionSettingMenuItem;
