import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { UiActions } from 'modules/ui';
import apiChat from 'external/api/chat';
import apiCommon from 'external/api/common';
import RequestError from 'classes/RequestError';
import { ErrorDialogTypes } from 'modules/errorDialog';
import ErrorCodes from 'constants/errorCodes';
import * as PROTECTED_ROUTES from 'routes/constants/protected';
import { getAccountId } from 'modules/account';
import useHistoryState from './useHistoryState';
import useErrorDialog from './useErrorDialog';

export const CHAT_ROOM_HISTORY_STATE_KEY = 'SELECTED_CHAT_ROOM';

type ChatRoomHistoryState = {
  accountId?: string | undefined;
  chatRoomId?: string | undefined;
};

const useStartChat = () => {
  const dispatch = useDispatch();
  const { handleRequestError } = useErrorDialog();
  const { t } = useTranslation(['error']);
  const accountId = useSelector(getAccountId);

  const comparator = useCallback(
    (
      a: ChatRoomHistoryState | undefined,
      b: ChatRoomHistoryState | undefined,
    ) => a?.accountId === b?.accountId && a?.chatRoomId === b?.chatRoomId,
    [],
  );
  const { historyState, replace, push } = useHistoryState<ChatRoomHistoryState>(
    CHAT_ROOM_HISTORY_STATE_KEY,
    comparator,
  );

  const openChatRoom = useCallback(
    (chatRoomId: string | null, pushHistory = true) => {
      const state: ChatRoomHistoryState = {
        accountId: chatRoomId ? accountId : undefined,
        chatRoomId: chatRoomId || undefined,
      };
      if (pushHistory) {
        push(`${PROTECTED_ROUTES.PRIVATE_MESSAGES}`, state);
      } else {
        replace(state);
      }
    },
    [accountId, push, replace],
  );

  const handleStartChat = useCallback(
    async (followerAccountId: string, followerChatRoomId: string) => {
      if (followerAccountId) {
        await apiCommon.clearNotification({
          messageType: 'contact',
          notificationId: followerAccountId,
        });
      }
      if (followerChatRoomId) {
        openChatRoom(followerChatRoomId);
        return;
      }
      dispatch(UiActions.setLoading(true));
      let data;
      try {
        ({ data } = await apiChat.createChatRoom({
          accountId: followerAccountId,
        }));
      } catch (anyError) {
        if (anyError instanceof RequestError) {
          if (
            anyError.errorCode ===
              ErrorCodes.FAILED_TO_CREATE_CHAT_ROOM_BECAUSE_OPPONENT_MISSION_IS_NOT_PUBLISHED ||
            anyError.errorCode ===
              ErrorCodes.FAILED_TO_CREATE_CHAT_ROOM_BECAUSE_OWN_MISSION_IS_NOT_PUBLISHED
          ) {
            handleRequestError(anyError);
          } else {
            handleRequestError(
              anyError,
              t('error:errorOccurred'),
              ErrorDialogTypes.RELOAD,
            );
          }
        }
        dispatch(UiActions.setLoading(false));
        return;
      }
      dispatch(UiActions.setLoading(false));
      openChatRoom(data.chatRoomId);
    },
    [dispatch, handleRequestError, openChatRoom, t],
  );

  return {
    openChatRoom,
    chatRoomHistoryState: historyState,
    handleStartChat,
  };
};

export default useStartChat;
