import React, { FC } from 'react';
import styled from 'styled-components/macro';
import { useTranslation } from 'react-i18next';
import {
  ProductTargetTag,
  MemberBenefit,
  DetailTitle,
} from 'components/molecules';
import { MissionProduct } from 'proto/v1/apimodel/apimodel';
import theme from 'styles/theme';
import typography from 'styles/typography';
import { noImageCover } from 'assets/png';
import { imageSelector } from 'utils/imageSelector';

const Product = styled.div`
  margin-bottom: 8px;
`;

const ProductCard = styled.div`
  box-sizing: border-box;
  padding: 16px;
  border: 2px solid ${theme.bgAdd};
  border-radius: 8px;
  background: ${theme.baseWhite};

  &:hover {
    background: ${theme.bgAdd};
    cursor: pointer;
  }

  &:not(:last-child) {
    margin-bottom: 15px;
  }
`;

const ProductImage = styled.img`
  width: 100%;
  height: 254px;
  margin-bottom: 16px;
`;

const HeadItems = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 8px;
`;

const ProductName = styled.div`
  ${typography.headlineDefault}
  display: flex;
  align-items: center;
  margin-bottom: 4px;

  ${ProductTargetTag} {
    &:first-child {
      margin-left: 8px;
    }
  }
`;

const ProductOverview = styled.div`
  ${typography.textDefault}
  margin-bottom: 16px;
`;

const ProductTags = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const ProductTag = styled.div`
  ${typography.labelDefault}
  box-sizing: border-box;
  margin-bottom: 4px;
  padding: 3px 12px;
  border: 1px solid ${theme.labelRequest};
  border-radius: 18px;
  background: ${theme.baseWhite};
  color: ${theme.labelRequest};

  &:not(:last-child) {
    margin-right: 4px;
  }
`;

type MissionDetailProductsCardsProps = {
  products: MissionProduct[];
  onClickProduct: (index: number) => void;
};

const MissionDetailProductCards: FC<MissionDetailProductsCardsProps> = ({
  products,
  onClickProduct,
}) => {
  const { t } = useTranslation('missionDetail');

  return (
    <Product data-testid="mission-products-cards">
      <DetailTitle>{t('field.missionProducts.label')}</DetailTitle>
      {products.map((product, index) => (
        <ProductCard
          key={product.missionProductId}
          onClick={() => onClickProduct(index)}
          data-testid="mission-product"
        >
          <ProductImage
            src={imageSelector(product.image, 'large') || noImageCover}
          />
          <HeadItems>
            {product.productTarget?.isForBusiness && (
              <ProductTargetTag>
                {t('field.productTarget.isForBusiness')}
              </ProductTargetTag>
            )}
            {product.productTarget?.isForCustomer && (
              <ProductTargetTag>
                {t('field.productTarget.isForCustomer')}
              </ProductTargetTag>
            )}
            {product.memberBenefit && <MemberBenefit />}
          </HeadItems>
          <ProductName>{product.name}</ProductName>
          <ProductOverview>{product.overview}</ProductOverview>
          <ProductTags>
            {product.tags
              .filter(tag => tag !== '')
              .map((tag, tagIndex) => (
                // eslint-disable-next-line react/no-array-index-key
                <ProductTag key={tagIndex}>{tag}</ProductTag>
              ))}
          </ProductTags>
        </ProductCard>
      ))}
    </Product>
  );
};

export default MissionDetailProductCards;
