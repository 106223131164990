import React, { FC, useMemo } from 'react';
import styled, { css } from 'styled-components';
import typography from 'styles/typography';
import theme from 'styles/theme';
import { ChatMessage } from 'proto/v1/apimodel/apimodel';
import { useTranslation } from 'react-i18next';
import { WrapText, LinkText, FlexContainer } from 'components/atoms';
import { MessageDocumentPreview, MessageDocumentDownload } from 'assets/svg';
import { getFileSize } from 'utils/models/chat';

const messageStyle = css`
  max-width: 530px;
  padding: 12px 16px;
  ${typography.textDefault}
  color: ${theme.white};
`;

const TalkToMessage = styled.div<{ isFileMessage: boolean }>`
  ${messageStyle}
  border-radius: 0 18px 18px 18px;
  background-color: ${theme.bgChatLeft};
  ${({ isFileMessage }) =>
    isFileMessage &&
    css`
      &:hover {
        text-decoration: underline;
        cursor: pointer;
      }
    `}
`;

const OwnMessage = styled.div<{ isFileMessage: boolean; active: boolean }>`
  ${messageStyle}
  display: flex;
  position: relative;
  border-radius: 18px 0 18px 18px;
  background-color: ${({ active }) =>
    active ? theme.bgChatRight : theme.baseDisabled};
  ${({ isFileMessage }) =>
    isFileMessage &&
    css`
      &:hover:not(.is-own-delete-icon-hovered) {
        text-decoration: underline;
        cursor: pointer;
      }
    `}
`;

const ProgressBar = styled.div`
  width: 100%;
  height: 3px;
  margin-top: 4px;
  margin-bottom: -8px;
  border-radius: calc(3px / 2);
  background: rgba(255, 255, 255, 0.43);
`;

const Progress = styled.div<{ value: number }>`
  width: ${({ value: progress }) => Math.round(progress * 100)}%;
  height: 3px;
  transition: width 0.2s linear;
  border-radius: calc(3px / 2);
  background: ${theme.basePrimary};
`;

const MessageContent = WrapText;

const DownloadIcon = styled.button.attrs({ type: 'button' })`
  padding: 0;
  border: none;
  outline: none;
  background-color: transparent;
  cursor: pointer;
`;

const FileMessage = styled(FlexContainer)``;

const FileMessageTextWrapperForIE = styled.div``;

const FileMessageText = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  flex-grow: 1;
  flex-shrink: 1;
  margin-right: 12px;
  margin-left: 8px;
  word-break: break-all;
`;

type Message = Pick<ChatMessage, 'messageId' | 'message' | 'file'>;

type MessageBalloonProps = {
  isTalkTo: boolean;
  message: Message;
  active: boolean;
  showProgress?: boolean;
  progress?: number;
  onClickDownloadLink: () => void;
};

const MessageBalloon: FC<MessageBalloonProps> = props => {
  const {
    isTalkTo,
    message,
    active,
    showProgress = false,
    progress = 0,
    onClickDownloadLink,
  } = props;
  const { t } = useTranslation(['chat']);

  const content = useMemo(
    () => (
      <>
        {!message.file && <LinkText color="white">{message.message}</LinkText>}
        {message.file && (
          <FileMessage
            data-testid={`${isTalkTo ? 'talk-to' : 'own'}-file-message`}
          >
            <MessageDocumentPreview style={{ marginTop: 3 }} />
            <FileMessageTextWrapperForIE>
              <FileMessageText style={isTalkTo ? {} : { marginRight: 0 }}>
                <div>{message.message}</div>
                <div>
                  {t('attachment.fileSizeValue', {
                    value: getFileSize(message.file.size),
                  })}
                </div>
              </FileMessageText>
            </FileMessageTextWrapperForIE>
            {isTalkTo && (
              <DownloadIcon>
                <MessageDocumentDownload style={{ marginTop: 8 }} />
              </DownloadIcon>
            )}
            {showProgress && (
              <ProgressBar>
                <Progress value={progress} />
              </ProgressBar>
            )}
          </FileMessage>
        )}
      </>
    ),
    [isTalkTo, message.file, message.message, progress, showProgress, t],
  );

  return (
    <>
      {isTalkTo ? (
        <TalkToMessage
          isFileMessage={!!message.file}
          onClick={onClickDownloadLink}
          data-testid="talk-to-message"
        >
          <MessageContent>{content}</MessageContent>
        </TalkToMessage>
      ) : (
        <OwnMessage
          isFileMessage={!!message.file}
          active={active}
          onClick={onClickDownloadLink}
          data-testid="own-message"
        >
          <MessageContent>{content}</MessageContent>
        </OwnMessage>
      )}
    </>
  );
};

export default MessageBalloon;
