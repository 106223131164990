import React, { FC, HTMLAttributes } from 'react';
import styled from 'styled-components/macro';
import theme from 'styles/theme';

const Mark = styled.span`
  color: ${theme.labelRequired};
`;

const RequiredMark: FC<HTMLAttributes<HTMLSpanElement>> = props => {
  return <Mark {...props}>*</Mark>;
};

export default RequiredMark;
