import { useCallback, useMemo } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import qs from 'qs';
import i18n from 'i18n/i18n';
import { StepperStep } from 'types/form';
import * as PROTECTED_ROUTES from 'routes/constants/protected';
import { MissionFormStep, MissionFormValues } from 'types/mission';
import {
  stepCompanySchema,
  stepAboutMissionRequiredSchema,
  stepMissionDemandSchema,
  stepMissionJobOffersSchema,
} from '../validationSchema';

type Payload = {
  missionFormValues: MissionFormValues | undefined;
  canUseStepJobOffers: boolean;
  canUseStepMissionDemand: boolean;
};

const useMissionFormStepper = ({
  missionFormValues,
  canUseStepJobOffers,
  canUseStepMissionDemand,
}: Payload) => {
  const history = useHistory();
  const location = useLocation();

  const query: { step?: string } = qs.parse(location?.search, {
    ignoreQueryPrefix: true,
  });

  const queryStep =
    query.step && !Number.isNaN(parseInt(query.step, 10))
      ? parseInt(query.step, 10)
      : undefined;

  const stepperSteps = useMemo<StepperStep[]>(() => {
    const missionFormStepperSteps: {
      [key in MissionFormStep]: StepperStep | undefined;
    } = {
      company: {
        label: i18n.t('missionForm:step.company'),
        required: true,
        completed:
          !!missionFormValues &&
          stepCompanySchema.isValidSync(missionFormValues),
      },
      aboutMission: {
        label: i18n.t('missionForm:step.aboutMission'),
        required: true,
        completed:
          !!missionFormValues &&
          stepAboutMissionRequiredSchema.isValidSync(missionFormValues),
      },
      missionProducts: {
        label: i18n.t('missionForm:step.missionProducts'),
        completed:
          !!missionFormValues && missionFormValues.missionProducts.length > 0,
      },
      missionJobOffers: canUseStepJobOffers
        ? {
            label: i18n.t('missionForm:step.missionJobOffers'),
            completed:
              !!missionFormValues &&
              stepMissionJobOffersSchema.isValidSync(missionFormValues),
          }
        : undefined,
      missionDemand: canUseStepMissionDemand
        ? {
            label: i18n.t('missionForm:step.missionDemand'),
            completed:
              !!missionFormValues &&
              stepMissionDemandSchema.isValidSync(missionFormValues),
          }
        : undefined,
    };

    return Object.values(missionFormStepperSteps).filter(
      (step): step is StepperStep => step !== undefined,
    );
  }, [canUseStepJobOffers, canUseStepMissionDemand, missionFormValues]);

  const stepIndexes = Object.values(stepperSteps).map((_, index) => index);

  const currentStepIndex =
    queryStep && stepIndexes.includes(queryStep - 1) ? queryStep - 1 : 0;

  const isFirstStep = currentStepIndex === 0;

  const isFinalStep = stepperSteps.length === currentStepIndex + 1;

  const changeCurrentStepIndex = useCallback(
    (index: number) => {
      history.push(
        `${PROTECTED_ROUTES.PRIVATE_SETTINGS_MISSION}?step=${index + 1}`,
      );
    },
    [history],
  );

  const moveNextStep = useCallback(() => {
    if (isFinalStep) return;
    changeCurrentStepIndex(currentStepIndex + 1);
  }, [changeCurrentStepIndex, currentStepIndex, isFinalStep]);

  const movePrevStep = useCallback(() => {
    if (isFirstStep) return;
    changeCurrentStepIndex(currentStepIndex - 1);
  }, [changeCurrentStepIndex, currentStepIndex, isFirstStep]);

  const moveStepTo = useCallback(
    (index: number) => {
      changeCurrentStepIndex(index);
    },
    [changeCurrentStepIndex],
  );

  return {
    stepperSteps,
    currentStepIndex,
    changeCurrentStepIndex,
    isFirstStep,
    isFinalStep,
    movePrevStep,
    moveNextStep,
    moveStepTo,
  };
};

export default useMissionFormStepper;
