import { useCallback } from 'react';
import ImageTypes from 'constants/imageTypes';
import { GalleryImage } from 'proto/v1/apimodel/apimodel';
import { GalleryImageFormValue } from 'types/form';
import { MissionSettingFormState } from './useMissionSettingFormState';
import { MissionSettingFormik } from './useMissionSettingFormik';

type Payload = {
  galleryImages: GalleryImageFormValue[];
  getAddedItemId: MissionSettingFormState['getAddedItemId'];
  openImageCropper: MissionSettingFormState['openImageCropper'];
  setFieldValue: MissionSettingFormik['setFieldValue'];
};

const useGalleryImagesField = ({
  galleryImages,
  getAddedItemId,
  openImageCropper,
  setFieldValue,
}: Payload) => {
  const handleAddGalleryImage = useCallback(
    () =>
      setFieldValue(
        'galleryImages',
        galleryImages && [
          ...galleryImages,
          {
            galleryImageId: getAddedItemId('galleryImages'),
          },
        ],
      ),
    [galleryImages, getAddedItemId, setFieldValue],
  );

  const handleChangeGalleryImage = useCallback(
    (uploadedImage: string, index: number) => {
      const field = `galleryImages[${index}].image`;
      openImageCropper(ImageTypes.OFFICE, uploadedImage, index, field);
    },
    [openImageCropper],
  );

  const handleDeleteGalleryImage = useCallback(
    (targetGalleryImageId: GalleryImage['galleryImageId']) => {
      setFieldValue(
        'galleryImages',
        galleryImages &&
          galleryImages.filter(
            (galleryImage: GalleryImage) =>
              galleryImage.galleryImageId !== targetGalleryImageId,
          ),
      );
    },
    [galleryImages, setFieldValue],
  );

  return {
    handleAddGalleryImage,
    handleChangeGalleryImage,
    handleDeleteGalleryImage,
  };
};

export default useGalleryImagesField;
