import { useState, useCallback, useEffect } from 'react';
import { MissionFormErrors, MissionFormValues } from 'types/mission';
import {
  getMissionDemandErrorMessages,
  filterEmptyErrors,
} from '../../../validate/validateToSave';
import useMissionDemandField from '../../../hooks/useMissionDemandField';
import { stepMissionDemandSchema } from '../../validationSchema';
import useMissionFormContext from '../../useMissionFormContext';
import useMissionFormFormik from '../../useMissionFormFormik';
import { validateMissionFormValuesToPublish } from '../../validateToPublish';

type Values = Pick<MissionFormValues, 'missionDemand'>;

const defaultInitialValues: Values = {
  missionDemand: {
    wantCollaboration: false,
    wantInvest: false,
    wantInvestSeed: false,
    wantInvestEarly: false,
    wantInvestMiddle: false,
    wantInvestLater: false,
    wantFundraising: false,
    wantServiceInformation: false,
    wantServiceSuggestion: false,
    wantTransferOfBusiness: false,
    wantTransferredOfBusiness: false,
    wantAcceptSecondment: false,
    wantSendEmployee: false,
    wantFurnishedOfficeRentalAvailability: false,
    wantFurnishedOfficeRentalRequest: false,
    collaboration: { assets: [{ give: '', want: '' }] },
    fundraising: { financingSeriesId: 0 },
    serviceInformation: { issues: [''] },
    transferredOfBusiness: { fundId: 0 },
    acceptSecondment: { work: '' },
    sendEmployee: { work: '' },
  },
};

const useStepMissionDemand = () => {
  const {
    movePrevStep,
    missionFormValues,
    isPublished,
    isFirstPublished,
    form,
    updateMission,
    publishMission,
    unPublishMission,
    modalState,
    closeModal,
    openModal,
    validateToSaveErrors,
    setValidateToSaveErrors,
    validateToPublishStepsResult,
    setValidateToPublishStepsResult,
    missionDemandFieldRef,
    openPreview,
    canUseMissionFormStepMissionJobOffers,
    canUseMissionFormStepMissionDemand,
  } = useMissionFormContext();

  const [initialValues, setInitialValues] = useState<Values>(
    defaultInitialValues,
  );

  const {
    formik,
    handleChangeSelectedValueAsNumber,
    handleChangeChecked,
  } = useMissionFormFormik<Values>({
    initialValues,
    validationSchema: stepMissionDemandSchema,
  });

  const {
    dirty,
    values,
    errors,
    touched,
    setFieldValue,
    setFieldTouched,
    resetForm,
  } = formik;

  const getErrorsToSave = useCallback(
    () =>
      filterEmptyErrors({
        missionDemand: getMissionDemandErrorMessages(
          errors.missionDemand as MissionFormErrors['missionDemand'],
        ),
      }),
    [errors.missionDemand],
  );

  const validateToSave = useCallback(() => {
    if (!missionFormValues) return;

    const errorsToSave = getErrorsToSave();
    if (Object.keys(errorsToSave).length > 0) {
      setValidateToSaveErrors(errorsToSave);
      openModal('invalidToSave');
      return false;
    }

    const { isValid, stepsResult } = validateMissionFormValuesToPublish(
      {
        ...missionFormValues,
        ...values,
      },
      canUseMissionFormStepMissionDemand,
      canUseMissionFormStepMissionJobOffers,
    );
    if (!isValid) {
      setValidateToPublishStepsResult(stepsResult);
      openModal('saveRequiredNoInputConfirmation');
      return false;
    }

    return true;
  }, [
    canUseMissionFormStepMissionDemand,
    canUseMissionFormStepMissionJobOffers,
    getErrorsToSave,
    missionFormValues,
    openModal,
    setValidateToPublishStepsResult,
    setValidateToSaveErrors,
    values,
  ]);

  const validateToPublish = useCallback(() => {
    if (!missionFormValues) return;

    const errorsToSave = getErrorsToSave();
    if (Object.keys(errorsToSave).length > 0) {
      setValidateToSaveErrors(errorsToSave);
      openModal('invalidToSave');
      return false;
    }

    const { isValid, stepsResult } = validateMissionFormValuesToPublish(
      {
        ...missionFormValues,
        ...values,
      },
      canUseMissionFormStepMissionDemand,
      canUseMissionFormStepMissionJobOffers,
    );

    setValidateToPublishStepsResult(stepsResult);

    if (!isValid) {
      openModal('invalidToPublish');
      return false;
    }

    return true;
  }, [
    canUseMissionFormStepMissionDemand,
    canUseMissionFormStepMissionJobOffers,
    getErrorsToSave,
    missionFormValues,
    openModal,
    setValidateToPublishStepsResult,
    setValidateToSaveErrors,
    values,
  ]);

  const handleClickSave = useCallback(() => {
    if (!dirty) return;
    if (!validateToSave()) return;
    openModal('saveMissionConfirmation');
  }, [dirty, openModal, validateToSave]);

  const saveMission = useCallback(async () => {
    if (!missionFormValues) return;
    try {
      await updateMission({ ...missionFormValues, ...values });
    } catch (error) {
      throw new Error('Failed to update mission');
    }
    setInitialValues(values);
    resetForm();
  }, [missionFormValues, resetForm, updateMission, values]);

  const handleClickSaveConfirmationOK = useCallback(async () => {
    try {
      await saveMission();
    } catch (error) {
      // Nothing to do.
      return;
    }
    openModal('saveMissionCompleted');
  }, [openModal, saveMission]);

  const handleClickPublish = useCallback(async () => {
    if (!validateToPublish()) {
      return;
    }
    openModal('publishMissionConfirmation');
  }, [openModal, validateToPublish]);

  const handleClickPublishConfirmationOk = useCallback(async () => {
    if (!missionFormValues) return;
    try {
      await publishMission({ ...missionFormValues, ...values });
    } catch (error) {
      // Nothing to do.
      return;
    }
    setInitialValues(values);
    resetForm();
  }, [missionFormValues, publishMission, resetForm, values]);

  const handleClickUnPublishConfirmationOk = useCallback(async () => {
    if (!missionFormValues) return;
    try {
      await unPublishMission({ ...missionFormValues, ...values });
    } catch (error) {
      // Nothing to do.
      return;
    }
    setInitialValues(values);
    resetForm();
  }, [missionFormValues, resetForm, unPublishMission, values]);

  const {
    handleAddCollaborationAsset,
    handleDeleteCollaborationAsset,
    handleAddServiceInformationIssue,
    handleDeleteServiceInformationIssue,
    missionDemandError,
  } = useMissionDemandField({
    missionDemand: values.missionDemand,
    missionProducts: missionFormValues?.missionProducts ?? [],
    missionOfficeImages: missionFormValues?.missionOfficeImages ?? [],
    touched: touched.missionDemand,
    errors: errors.missionDemand,
    setFieldValue,
    setFieldTouched,
  });

  // Set initial values for fetched mission.
  useEffect(() => {
    if (!missionFormValues) return;
    setInitialValues({
      missionDemand: missionFormValues.missionDemand,
    });
  }, [missionFormValues]);

  return {
    movePrevStep,
    handleClickPublish,
    isPublished,
    isFirstPublished,
    form,
    formik,
    handleChangeSelectedValueAsNumber,
    handleChangeChecked,
    modalState,
    closeModal,
    handleClickSave,
    validateToSaveErrors,
    validateToPublishStepsResult,
    handleClickSaveConfirmationOK,
    handleClickPublishConfirmationOk,
    handleClickUnPublishConfirmationOk,
    handleAddCollaborationAsset,
    handleDeleteCollaborationAsset,
    handleAddServiceInformationIssue,
    handleDeleteServiceInformationIssue,
    missionDemandError,
    missionDemandFieldRef,
    openPreview,
    canUseMissionFormStepMissionJobOffers,
    canUseMissionFormStepMissionDemand,
  };
};

export default useStepMissionDemand;
