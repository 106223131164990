import React, { FC, ChangeEvent } from 'react';
import { useTranslation } from 'react-i18next';
import { MissionFormValues } from 'types/mission';
import { isEqualProps } from 'utils/object';
import { MissionSettingMaxLength } from 'constants/form';
import { TextInput } from 'components/atoms';
import { MissionSettingFormItem, FormItemLabel } from 'components/molecules';

type CompanyUrlFieldProps = {
  value: MissionFormValues['companyUrl'];
  error: string | undefined;
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
  onBlur: (e: any) => void;
};

const CompanyUrlField: FC<CompanyUrlFieldProps> = props => {
  const { value, error, onChange, onBlur } = props;

  const { t } = useTranslation('missionSetting');

  return (
    <MissionSettingFormItem required error={error}>
      <FormItemLabel label={t('field.companyUrl.label')} required />
      <TextInput
        maxLength={MissionSettingMaxLength.COMPANY_URL}
        name="companyUrl"
        type="text"
        value={value}
        placeholder={t('field.companyUrl.placeholder')}
        onChange={onChange}
        onBlur={onBlur}
        outline
        aria-label={t('field.companyUrl.label')}
      />
    </MissionSettingFormItem>
  );
};

export default React.memo(CompanyUrlField, (prevProps, nextProps) =>
  isEqualProps(prevProps, nextProps, ['value', 'error']),
);
