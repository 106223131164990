import React from 'react';
import styled from 'styled-components/macro';
import theme from 'styles/theme';
import typography from 'styles/typography';
import { GoArrowUp } from 'react-icons/go';
import { AttentionDegreeValues } from 'constants/models/mission';
import { useTranslation } from 'react-i18next';

const AttentionDegreeContainer = styled.div`
  display: flex;
  align-content: center;
  align-items: end; /* centerだと解像度やブラウザにより上下にずれるケースがあるためendにする */
  color: ${theme.arrowUp};
  vertical-align: middle;

  svg {
    width: 14px;
    height: 16px;
    margin-right: 3px;
  }
`;

const AttentionDegreeHeading = styled.span`
  ${typography.textSub}
  font-size: 11px;
  font-weight: bold;
`;

const AttentionDegree = ({
  degreeOfAttention,
}: {
  degreeOfAttention: number;
}) => {
  const { t } = useTranslation('common');
  return (
    <AttentionDegreeContainer>
      {degreeOfAttention === AttentionDegreeValues.INCREASED && (
        <>
          <GoArrowUp />
          <AttentionDegreeHeading>
            {t('component.attentionDegree')}
          </AttentionDegreeHeading>
        </>
      )}
    </AttentionDegreeContainer>
  );
};

export default AttentionDegree;
