import React, { FC, ComponentProps } from 'react';
import styled, { css } from 'styled-components/macro';
import { Modal } from 'visits-style';
import { FiX } from 'react-icons/fi';
import theme from 'styles/theme';

const StyledModal = styled(Modal)<AppModalProps>`
  ${({ size = 'normal' }) =>
    size === 'full'
      ? css`
          padding: 0;
          & div[role='document'] {
            border-radius: 0;
          }
        `
      : css`
          & div[role='document'] {
            border-radius: 8px;
          }
        `}
`;

const ModalBody = styled.div<AppModalProps>`
  width: ${({ width = '768px' }) => width};
  height: ${({ height = '384px' }) => height};
  ${({ size = 'normal' }) =>
    size === 'full'
      ? css`
          width: 100vw;
          height: 100vh;
          border-radius: 0;
        `
      : css`
          padding: 56px 48px 0;
          border-radius: 8px;
        `}
  overflow-y: auto;
  background: ${theme.white};
  box-shadow: 0 6px 10px rgba(0, 0, 0, 0.4);
`;

const StyledIconClose = styled(FiX)`
  position: absolute;
  z-index: 3;
  top: 16px;
  right: 16px;
  width: 24px;
  height: 24px;
  color: #999;
  cursor: pointer;
`;

type AppModalProps = ComponentProps<typeof Modal> & {
  size?: 'normal' | 'full';
  type?: string;
  width?: string;
  height?: string;
  hideCloseButton?: boolean;
};

const AppModal: FC<AppModalProps> = props => {
  props.show
    ? document.body.setAttribute('style', 'overflow: hidden;')
    : document.body.setAttribute('style', '');

  return (
    <StyledModal {...props}>
      <ModalBody {...props} className={props.type}>
        {!props.hideCloseButton && (
          <StyledIconClose
            onClick={props.closeModal}
            data-testid="modal-close-icon"
          />
        )}
        {props.children}
      </ModalBody>
    </StyledModal>
  );
};

export default AppModal;
