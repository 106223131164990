import React, { useMemo } from 'react';
import styled from 'styled-components/macro';
import { useTranslation } from 'react-i18next';
import theme from 'styles/theme';
import typography from 'styles/typography';
import { Occupation } from 'proto/v1/apimodel/apimodel';
import { MISSION_JOB_OFFERS_MAX_COUNT } from 'constants/config';
import { isEqualProps } from 'utils/object';
import { PlusGray } from 'assets/svg';
import { MissionFormValues } from 'types/mission';
import { EllipsisBox } from 'components/atoms';
import { OutlinedIconButton } from 'components/molecules';
import MissionJobOfferMenu from './MissionJobOfferMenu';

const AddMissionJobOfferField = styled.div`
  display: flex;
  justify-content: center;
  padding: 40px 0;
`;

const MissionJobOffer = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 34px 52px 34px 52px;
  border-bottom: 1px solid ${theme.borderTable};
`;

const MissionJobOfferLeft = styled.div``;

const MissionJobOfferRight = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 80px;
`;

const MissionJobOfferOccupation = styled.p`
  ${typography.headlineDefault}
  margin-bottom: 4px;
  color: ${theme.textPrimary};
`;

const MissionJobOfferJobDescription = styled(EllipsisBox)`
  ${typography.textDefault}
  height: 45px;
  color: ${theme.textPrimary};
  word-wrap: break-word;
  word-break: break-word;
  white-space: pre-wrap;
  overflow-wrap: break-word;
`;

const PublishStatusTag = styled.div`
  ${typography.textNote}
  padding: 8px 12px 7px 12px;
  border-radius: 100px;
  background-color: ${theme.basePrimary};
  color: ${theme.baseWhite};
  line-height: 1;
`;

type Props = {
  value: MissionFormValues['missionJobOffers'];
  onClickCreate: () => void;
  onClickEdit: (index: number) => void;
  onDelete: (index: number) => void;
  onPublish: (index: number) => void;
  onUnPublish: (index: number) => void;
  occupations: Occupation[];
};

const MissionJobOffersField: React.VFC<Props> = ({
  value,
  onClickCreate,
  onClickEdit,
  onDelete,
  onPublish,
  onUnPublish,
  occupations,
}) => {
  const { t } = useTranslation('missionSetting');

  const occupationsMap = useMemo(
    () =>
      occupations.reduce<{ [id: number]: string }>((a, c) => {
        a[c.id] = c.name;
        return a;
      }, {}),
    [occupations],
  );

  return (
    <div>
      {value.map((missionJobOffer, index) => (
        <MissionJobOffer
          key={missionJobOffer.id}
          data-testid="mission-job-offer"
        >
          <MissionJobOfferLeft>
            <MissionJobOfferOccupation>
              {occupationsMap[missionJobOffer.occupationId]}
            </MissionJobOfferOccupation>
            <MissionJobOfferJobDescription
              line={2}
              width={'658px'}
              maxHeight={'45px'}
            >
              {missionJobOffer.jobDescription}
            </MissionJobOfferJobDescription>
          </MissionJobOfferLeft>
          <MissionJobOfferRight>
            <PublishStatusTag data-testid="mission-job-offer-publish-status">
              {missionJobOffer.published
                ? t('field.missionJobOffer.published')
                : t('field.missionJobOffer.unPublished')}
            </PublishStatusTag>
            <MissionJobOfferMenu
              missionJobOffer={missionJobOffer}
              onClickEdit={() => {
                onClickEdit(index);
              }}
              onClickPublish={() => {
                onPublish(index);
              }}
              onClickUnPublish={() => {
                onUnPublish(index);
              }}
              onClickDelete={() => {
                onDelete(index);
              }}
            />
          </MissionJobOfferRight>
        </MissionJobOffer>
      ))}
      {value.length < MISSION_JOB_OFFERS_MAX_COUNT && (
        <AddMissionJobOfferField>
          <OutlinedIconButton
            icon={<PlusGray />}
            onClick={onClickCreate}
            data-testid="add-mission-job-offer"
          >
            {t('field.missionJobOffer.add')}
          </OutlinedIconButton>
        </AddMissionJobOfferField>
      )}
    </div>
  );
};

export default React.memo(MissionJobOffersField, isEqualProps);
