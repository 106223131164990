import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { FormikTouched, FormikErrors } from 'formik';
import {
  MissionDemandFormValue,
  MissionProductFormValue,
  MissionOfficeImageFormValue,
  MissionDemandError,
} from 'types/form';
import {
  isEmptyOfficeImage,
  isEmptyMissionProduct,
} from 'utils/models/mission';
import { MissionSettingFormik } from './useMissionSettingFormik';

type Payload = {
  missionDemand: MissionDemandFormValue;
  missionProducts: MissionProductFormValue[];
  missionOfficeImages: MissionOfficeImageFormValue[];
  setFieldValue: MissionSettingFormik['setFieldValue'];
  setFieldTouched: MissionSettingFormik['setFieldTouched'];
  errors: FormikErrors<{
    missionDemand: MissionDemandFormValue;
  }>['missionDemand'];
  touched: FormikTouched<{
    missionDemand: MissionDemandFormValue;
  }>['missionDemand'];
};

const useMissionDemandField = ({
  missionDemand,
  missionOfficeImages,
  missionProducts,
  setFieldValue,
  setFieldTouched,
  errors,
  touched,
}: Payload) => {
  const { t } = useTranslation('missionSetting');

  const handleAddCollaborationAsset = useCallback(() => {
    setFieldValue(
      'missionDemand.collaboration.assets',
      missionDemand.collaboration && [
        ...missionDemand.collaboration.assets,
        {
          give: '',
          want: '',
        },
      ],
    );
  }, [setFieldValue, missionDemand.collaboration]);

  const handleDeleteCollaborationAsset = useCallback(
    (targetIndex: number) => {
      const assets = missionDemand.collaboration?.assets;
      if (!assets || !Array.isArray(assets)) return;

      setFieldValue(
        'missionDemand.collaboration.assets',
        assets.filter((_asset, index) => index !== targetIndex),
      );
      setFieldTouched(
        `missionDemand.collaboration.assets[${targetIndex}]`,
        undefined,
      );
    },
    [setFieldTouched, setFieldValue, missionDemand.collaboration],
  );

  const handleAddServiceInformationIssue = useCallback(() => {
    setFieldValue(
      'missionDemand.serviceInformation.issues',
      missionDemand.serviceInformation && [
        ...missionDemand.serviceInformation.issues,
        [],
      ],
    );
  }, [setFieldValue, missionDemand.serviceInformation]);

  const handleDeleteServiceInformationIssue = useCallback(
    (targetIndex: number) => {
      const issues = missionDemand.serviceInformation?.issues;
      if (!issues || !Array.isArray(issues)) return;

      setFieldValue(
        'missionDemand.serviceInformation.issues',
        issues.filter((_issue, index) => index !== targetIndex),
      );
      setFieldTouched(
        `missionDemand.serviceInformation.issues[${targetIndex}]`,
        undefined,
      );
    },
    [setFieldTouched, setFieldValue, missionDemand.serviceInformation],
  );

  const furnishedOfficeRentalAvailabilityError = useMemo(() => {
    if (!missionDemand.wantFurnishedOfficeRentalAvailability) return undefined;
    const officeImages = missionOfficeImages.filter(
      missionOfficeImage => !isEmptyOfficeImage(missionOfficeImage),
    );
    return !officeImages.length
      ? t('validation.noMissionOfficeImages')
      : undefined;
  }, [
    missionDemand.wantFurnishedOfficeRentalAvailability,
    missionOfficeImages,
    t,
  ]);

  const serviceSuggestionError = useMemo(() => {
    if (!missionDemand.wantServiceSuggestion) return undefined;
    const products = missionProducts.filter(
      missionProduct => !isEmptyMissionProduct(missionProduct),
    );
    return !products.length ? t('validation.noMissionProducts') : undefined;
  }, [missionDemand.wantServiceSuggestion, missionProducts, t]);

  const missionDemandError = useMemo<MissionDemandError>(
    () => ({
      wantInvest:
        touched &&
        (touched.wantInvestSeed ||
          touched.wantInvestMiddle ||
          touched.wantInvestEarly ||
          touched.wantInvestLater) &&
        errors
          ? errors.wantInvest
          : undefined,
      wantFundraising:
        touched?.fundraising && errors ? errors.wantFundraising : undefined,
      wantServiceSuggestion: serviceSuggestionError,
      wantFurnishedOfficeRentalAvailability: furnishedOfficeRentalAvailabilityError,
    }),
    [
      errors,
      furnishedOfficeRentalAvailabilityError,
      serviceSuggestionError,
      touched,
    ],
  );

  return {
    handleAddCollaborationAsset,
    handleDeleteCollaborationAsset,
    handleAddServiceInformationIssue,
    handleDeleteServiceInformationIssue,
    missionDemandError,
  };
};

export default useMissionDemandField;
