import React, { FC, useState, useCallback } from 'react';
import styled from 'styled-components/macro';
import Cropper from 'react-cropper';
import { useTranslation } from 'react-i18next';
import { ButtonM } from 'components/atoms';
import { ModalFooter } from 'components/molecules';
import { getMineTypeFromDataURI } from 'utils/string';
// eslint-disable-next-line import/no-extraneous-dependencies
import 'cropperjs/dist/cropper.css';

const CROPPER_MAX_HEIGHT = 331;

const Wrapper = styled.div``;

const ImageWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 331px;
  margin: 0 auto 16px auto;
`;

const CroppedImage = styled.div`
  cursor: pointer;
`;

type ImageCropperProps = {
  imageSrc: string | undefined;
  croppedImage?: string;
  aspectRatio?: number;
  onCroppedImage: (croppedImageSrc: string) => any;
};

const ImageCropper: FC<ImageCropperProps> = ({
  imageSrc,
  croppedImage,
  onCroppedImage,
  aspectRatio,
}) => {
  const { t } = useTranslation('common');
  const [cropper, setCropper] = useState<Cropper | null>(null);
  const [croppedImageSrc, setCroppedImageSrc] = useState<string | undefined>(
    croppedImage,
  );
  const [previewWidth, setPreviewWidth] = useState<number | undefined>(
    undefined,
  );

  const cropImage = useCallback(() => {
    const canvas = cropper && cropper.getCroppedCanvas();
    if (!canvas) return;
    if (!aspectRatio) {
      setPreviewWidth((canvas.width / canvas.height) * CROPPER_MAX_HEIGHT);
    }
    const mineType = imageSrc && getMineTypeFromDataURI(imageSrc);
    setCroppedImageSrc(canvas.toDataURL(mineType));
  }, [cropper, aspectRatio, imageSrc]);

  const setUpLoadImage = useCallback(() => {
    croppedImageSrc && onCroppedImage(croppedImageSrc);
  }, [onCroppedImage, croppedImageSrc]);

  const clearCloppedImageSrc = useCallback(() => {
    setCroppedImageSrc(undefined);
  }, [setCroppedImageSrc]);

  const cropperWidth = aspectRatio && CROPPER_MAX_HEIGHT * aspectRatio;

  return (
    <Wrapper>
      {!croppedImageSrc ? (
        <>
          <ImageWrapper>
            <Cropper
              src={imageSrc}
              viewMode={1}
              initialAspectRatio={aspectRatio}
              zoomable={false}
              rotatable={false}
              guides={false}
              minContainerHeight={CROPPER_MAX_HEIGHT}
              minContainerWidth={cropperWidth}
              style={{
                width: '100%',
                maxHeight: `${CROPPER_MAX_HEIGHT}px`,
              }}
              onInitialized={cropperComponent => setCropper(cropperComponent)}
            />
          </ImageWrapper>
          <ModalFooter>
            <ButtonM buttonTheme="default" onClick={cropImage}>
              {t('button.decide')}
            </ButtonM>
          </ModalFooter>
        </>
      ) : (
        <>
          <ImageWrapper>
            <CroppedImage onClick={clearCloppedImageSrc}>
              <img
                src={croppedImageSrc}
                alt=""
                style={{
                  maxHeight: `${CROPPER_MAX_HEIGHT}px`,
                  maxWidth: `${aspectRatio ? cropperWidth : previewWidth}px`,
                }}
              />
            </CroppedImage>
          </ImageWrapper>
          <ModalFooter>
            <ButtonM
              buttonTheme="default"
              onClick={setUpLoadImage}
              data-testid="modal-image-cropper-save"
            >
              {t('button.save')}
            </ButtonM>
          </ModalFooter>
        </>
      )}
    </Wrapper>
  );
};

export default ImageCropper;
