import React, { FC } from 'react';
import styled from 'styled-components/macro';
import { ImageGallery } from 'components/organisms';
import { Images } from 'proto/v1/apimodel/apimodel';
import { DetailTitle } from 'components/molecules';

const GalleryImages = styled.div`
  &:not(:first-child) {
    margin-top: 32px;
  }
`;

type ImageGalleryFieldProps = {
  title: string;
  images: (Images | undefined)[];
};

const MissionOfficeImagesField: FC<ImageGalleryFieldProps> = ({
  title,
  images,
}) => {
  return (
    <GalleryImages data-testid="image-gallery">
      <DetailTitle>{title}</DetailTitle>
      <ImageGallery images={images} />
    </GalleryImages>
  );
};

export default MissionOfficeImagesField;
