import { Mission, MissionUsePlan, Supporter } from 'proto/v1/apimodel/apimodel';
import { MISSION_USE_PLAN_ID } from 'constants/models/mission';
import * as PROTECTED_ROUTES from 'routes/constants/protected';

// eslint-disable-next-line import/prefer-default-export
export const getInitialPrivateRoute = ({
  mission,
  supporter,
  missionUsePlans,
}: {
  mission?: Mission;
  supporter?: Supporter;
  missionUsePlans?: MissionUsePlan[];
}) => {
  if (!mission || supporter || !missionUsePlans?.length) {
    return PROTECTED_ROUTES.PRIVATE_HUMAN_RESOURCES_CAREER;
  }

  const usePlanIds = missionUsePlans.map(
    missionUsePlan => missionUsePlan.usePlanId,
  );

  if (usePlanIds.includes(MISSION_USE_PLAN_ID.CAREER)) {
    return PROTECTED_ROUTES.PRIVATE_HUMAN_RESOURCES_CAREER;
  }

  if (
    usePlanIds.includes(MISSION_USE_PLAN_ID.NEW_GRADUATE_SUBSCRIPTION) ||
    usePlanIds.includes(MISSION_USE_PLAN_ID.NEW_GRADUATE_PAY_FOR_PERFORMANCE)
  ) {
    return PROTECTED_ROUTES.PRIVATE_HUMAN_RESOURCES_NEW_GRADUATE;
  }

  if (usePlanIds.includes(MISSION_USE_PLAN_ID.OPEN_INNOVATION)) {
    return PROTECTED_ROUTES.PRIVATE_MISSIONS;
  }

  return PROTECTED_ROUTES.PRIVATE_HUMAN_RESOURCES_CAREER;
};
