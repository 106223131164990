import styled from 'styled-components/macro';
import theme from 'styles/theme';
import typography from 'styles/typography';

const LinkButton = styled.button.attrs({ type: 'button' })`
  ${typography.textLink}
  padding: 0;
  border: none;
  outline: none;
  background-color: transparent;
  color: ${theme.baseLink};
  text-decoration: underline;
  cursor: pointer;
  appearance: none;

  &:hover {
    opacity: 0.8;
  }
`;

export default LinkButton;
