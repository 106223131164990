import React, { FC } from 'react';
import styled from 'styled-components/macro';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet';
import typography from 'styles/typography';
import { ErrorOutline } from 'assets/svg';
import { Card } from 'components/atoms';

const Content = styled.div`
  margin: 0 auto;
`;
const ContentsDescription = styled.div`
  ${typography.textDefault}
  margin-bottom: 38px;
  text-align: center;
  white-space: pre-line;
`;

const StyledCard = styled(Card)`
  &&& {
    width: 730px;
    height: 508px;
    margin: 0 auto;
    padding: 40px 56px;
    border: none;
    border-radius: 8px;
    box-shadow: 0 3px 8px rgba(0, 0, 0, 0.15);

    > div {
      padding: 0;
    }
  }
`;

const StyledIconErrorOutline = styled(ErrorOutline)`
  display: block;
  width: 100px;
  height: 100px;
  margin: 127px auto 42px auto;
`;

const ExpiredUrl: FC<{}> = () => {
  const { t } = useTranslation(['password', 'head']);
  return (
    <>
      <Helmet>
        <title>{t('head:title.expiredUrl')}</title>
      </Helmet>
      <StyledCard data-testid="expired-url">
        <StyledIconErrorOutline />
        <Content>
          <ContentsDescription>{t('error:expiredUrl')}</ContentsDescription>
        </Content>
      </StyledCard>
    </>
  );
};

export default ExpiredUrl;
