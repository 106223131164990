import React, { FC, HTMLAttributes } from 'react';
import styled from 'styled-components/macro';

const StyledModalFooter = styled.div`
  width: 100%;
  height: 112px;
  padding: 24px 0 48px;
  text-align: center;

  > button {
    margin-left: 16px;

    &:first-child {
      margin-left: 0;
    }
  }
`;

type ModalFooterProps = HTMLAttributes<HTMLDivElement>;

const ModalFooter: FC<ModalFooterProps> = ({ children, ...rest }) => {
  return <StyledModalFooter {...rest}>{children}</StyledModalFooter>;
};

export default ModalFooter;
