import { differenceInDays } from 'date-fns';
import i18n from 'i18n/i18n';
import { ValueOf } from 'types/common';
import {
  HumanResourceMatter,
  CareerProfileForMatter,
  Matter,
  CareerMattersFilter,
  NewGraduateMattersFilter,
} from 'proto/v1/apimodel/apimodel';
import { WORK_LOCATION_OVERSEAS_ID } from 'constants/config';
import { EMPLOYMENT_TYPE_ID, EMPLOYMENT_TYPE } from 'constants/models/matter';
import {
  CareerMattersFilterValues,
  HumanResourceEmploymentType,
  NewGraduateMattersFilterValues,
} from 'types/matter';
import { unique } from 'utils/array';

export const getHumanResourceEmploymentType = (
  humanResourceMatter: HumanResourceMatter | undefined,
): HumanResourceEmploymentType => {
  if (humanResourceMatter?.newGraduate) return 'newGraduate';
  return 'career';
};

export const formatOccupations = (
  jobHistories: CareerProfileForMatter['job'] | undefined,
) => {
  if (!jobHistories) {
    return '';
  }

  const uniqueOccupations = jobHistories.reduce<{
    [id: number]: {
      name: string;
      jobHistoryOrder: number;
      occupationOrder: number;
      isCurrentJob: boolean;
    };
  }>((a, j, jobHistoryIndex) => {
    const { occupations } = j;
    occupations.forEach((occupation, occupationIndex) => {
      if (!occupation.occupation) return;
      if (!(occupation.occupation.id in a)) {
        const isLatestOccupation =
          jobHistoryIndex === 0 && occupationIndex === 0;
        a[occupation?.occupation?.id] = {
          name: occupation?.occupation?.name ?? '',
          jobHistoryOrder: jobHistoryIndex,
          occupationOrder: occupationIndex,
          isCurrentJob:
            isLatestOccupation &&
            j.endYear === undefined &&
            j.endMonth === undefined,
        };
      }
    });
    return a;
  }, {});

  return Object.values(uniqueOccupations)
    .sort((a, b) => {
      if (a.jobHistoryOrder === b.jobHistoryOrder) {
        return a.occupationOrder - b.occupationOrder;
      }
      return a.jobHistoryOrder - b.jobHistoryOrder;
    })
    .map(occupation => {
      if (occupation.isCurrentJob) {
        return `${occupation.name} ${i18n.t(
          'matter:field.occupations.currentOccupation',
          {
            lng: 'ja',
          },
        )} `;
      }
      return occupation.name;
    })
    .join(' / ');
};

export const getEmploymentType = (
  employmentTypeId: number | undefined,
  isNewGraduate: boolean,
): ValueOf<typeof EMPLOYMENT_TYPE> | undefined => {
  if (isNewGraduate) {
    return EMPLOYMENT_TYPE.NEW_GRADUATE;
  }
  switch (employmentTypeId) {
    case EMPLOYMENT_TYPE_ID.FULL_TIME:
      return EMPLOYMENT_TYPE.CAREER;
    case EMPLOYMENT_TYPE_ID.OUT_SOURCING:
      return EMPLOYMENT_TYPE.OUT_SOURCING;
    case EMPLOYMENT_TYPE_ID.ADVISOR:
      return EMPLOYMENT_TYPE.ADVISOR;
    default:
      return undefined;
  }
};

export const formatAcademicBackground = (
  academicBackground: HumanResourceMatter['academicBackground'],
) => {
  if (!academicBackground) return '';
  const { school, faculty, department, yearOfGraduation } = academicBackground;

  return `${school?.name} ${faculty} ${department?.name} (${i18n.t(
    'matter:field.academicBackground.year',
    {
      year: yearOfGraduation,
      lng: 'ja',
    },
  )})`;
};

export const formatCareerPositions = (
  jobHistories: CareerProfileForMatter['job'] | undefined,
) =>
  jobHistories
    ? unique(jobHistories.map(jobHistory => jobHistory.position?.name)).join(
        ' / ',
      )
    : '';

export const formatHumanResourceName = (
  humanResource: Matter['humanResource'],
) => {
  if (!humanResource) return '';
  const { firstName, lastName, displayId } = humanResource;
  if (firstName && lastName) {
    return `${lastName} ${firstName}`;
  }
  return `${i18n.t('matter:field.matterNumber.label')} ${displayId}`;
};

const LATEST_MESSAGE_WITH_IN_ONE_MONTH_DAYS = 30;
const LATEST_MESSAGE_WITH_IN_THREE_MONTHS_DAYS = 90;
const LATEST_MESSAGE_WITH_IN_SIX_MONTHS_DAYS = 180;
const LATEST_MESSAGE_WITH_IN_ONE_YEAR_DAYS = 365;

export const formatToLatestMessageSentAtWithin = (
  latestMessageSentAt: number,
) => {
  const diffDays = differenceInDays(new Date(), latestMessageSentAt);

  if (diffDays <= LATEST_MESSAGE_WITH_IN_ONE_MONTH_DAYS) {
    return i18n.t('matter:field.latestMessageSentAt.within.oneMonth', {
      lng: 'ja',
    });
  }
  if (diffDays <= LATEST_MESSAGE_WITH_IN_THREE_MONTHS_DAYS) {
    return i18n.t('matter:field.latestMessageSentAt.within.threeMonths', {
      lng: 'ja',
    });
  }
  if (diffDays <= LATEST_MESSAGE_WITH_IN_SIX_MONTHS_DAYS) {
    return i18n.t('matter:field.latestMessageSentAt.within.sixMonths', {
      lng: 'ja',
    });
  }
  if (diffDays <= LATEST_MESSAGE_WITH_IN_ONE_YEAR_DAYS) {
    return i18n.t('matter:field.latestMessageSentAt.within.oneYear', {
      lng: 'ja',
    });
  }
  return i18n.t('matter:field.latestMessageSentAt.within.moreThanOneYear', {
    lng: 'ja',
  });
};

export const convertToCareerMattersFilterRequest = (
  filterValues: CareerMattersFilterValues,
): CareerMattersFilter => {
  const {
    employmentTypeIds,
    occupationIds,
    skillIds,
    workLocationIds,
    schoolIds,
    yearOfGraduationFrom,
    yearOfGraduationTo,
    excludeLatestMessageWithinDays,
    favorite,
  } = filterValues;

  return {
    employmentTypeIds,
    occupationIds,
    skillIds,
    schoolIds,
    yearOfGraduationFrom,
    yearOfGraduationTo,
    excludeLatestMessageWithinDays,
    favorite,
    workLocations: workLocationIds.map(id => ({
      japanPrefecture:
        id !== WORK_LOCATION_OVERSEAS_ID
          ? {
              japanPrefectureId: id,
              name: '',
            }
          : undefined,
    })),
  };
};

export const convertToNewGraduateMattersFilterRequest = (
  filterValues: NewGraduateMattersFilterValues,
): NewGraduateMattersFilter => {
  const {
    preferredWorkStyleIds,
    yearOfGraduation,
    schoolIds,
    departmentIds,
    pastExperienceAchievementIds,
    preferredBusinessTypeIds,
    occupationIds,
    dttRankIds,
    workLocationIds,
    excludeLatestMessageWithinDays,
    messageReplied,
    favorite,
  } = filterValues;

  return {
    preferredWorkStyleIds,
    yearOfGraduation,
    schoolIds,
    departmentIds,
    pastExperienceAchievementIds,
    preferredBusinessTypeIds,
    occupationIds,
    dttRankIds,
    excludeLatestMessageWithinDays,
    messageReplied,
    favorite,
    workLocations: workLocationIds.map(id => ({
      japanPrefecture:
        id !== WORK_LOCATION_OVERSEAS_ID
          ? {
              japanPrefectureId: id,
              name: '',
            }
          : undefined,
    })),
  };
};
