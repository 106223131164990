import React, { FC, ChangeEvent } from 'react';
import { useTranslation } from 'react-i18next';
import { MissionFormValues } from 'types/mission';
import { isEqualProps } from 'utils/object';
import { MissionSettingMaxLength } from 'constants/form';
import { TextInput } from 'components/atoms';
import { FormItemLabel, MissionSettingFormItem } from 'components/molecules';

type CompanyNameFieldProps = {
  value: MissionFormValues['companyName'];
  error: string | undefined;
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
  onBlur: (e: any) => void;
};

const CompanyNameField: FC<CompanyNameFieldProps> = props => {
  const { value, error, onChange, onBlur } = props;

  const { t } = useTranslation('missionSetting');

  return (
    <MissionSettingFormItem error={error}>
      <FormItemLabel
        label={t('field.companyName.label')}
        note={t('field.companyName.note', {
          maxLength: MissionSettingMaxLength.COMPANY_NAME,
        })}
        required
      />
      <TextInput
        maxLength={MissionSettingMaxLength.COMPANY_NAME}
        name="companyName"
        type="text"
        value={value}
        placeholder={t('field.companyName.placeholder')}
        onChange={onChange}
        onBlur={onBlur}
        outline
        aria-label={t('field.companyName.label')}
      />
    </MissionSettingFormItem>
  );
};

export default React.memo(CompanyNameField, (prevProps, nextProps) =>
  isEqualProps(prevProps, nextProps, ['value', 'error']),
);
