import styled from 'styled-components/macro';
import { TextInput } from 'visits-style';
import theme from 'styles/theme';

const StyledTextInput = styled(TextInput)`
  height: 40px;

  input {
    border: 1px solid ${theme.borderDefault};
    font-size: 14px;

    &:hover,
    &:focus,
    &:active {
      border-color: ${theme.borderDefault};
      box-shadow: none;
    }

    &::-ms-reveal {
      display: none;
    }

    &::placeholder {
      color: ${theme.borderDefault};
    }
  }

  small {
    display: inline-block;
    margin-top: 4px;
  }

  &&& {
    &:hover {
      input:not(:disabled):not(:focus):not(:active) {
        border-color: ${theme.borderDefault};
      }
    }
  }
`;

export default StyledTextInput;
