import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components/macro';
import { NewGraduateProfileForMatter } from 'proto/v1/apimodel/apimodel';
import {
  HumanResourceCardBodyItem,
  HumanResourceCardBodyItemLabel,
  HumanResourceCardBodyItemContent,
  HumanResourceCardBodyListItemContent,
} from 'components/molecules';

const ClubExperience = styled.div`
  &:not(:last-child) {
    margin-bottom: 16px;
  }
`;

type NewGraduateClubExperiencesFieldProps = {
  clubExperiences: NewGraduateProfileForMatter['clubExperiences'] | undefined;
};

const NewGraduateClubExperiencesField: FC<NewGraduateClubExperiencesFieldProps> = props => {
  const { clubExperiences } = props;
  const { t } = useTranslation(['matter']);

  return (
    <HumanResourceCardBodyItem>
      <HumanResourceCardBodyItemLabel>
        {t('field.clubExperiences.label', {
          lng: 'ja',
        })}
      </HumanResourceCardBodyItemLabel>
      <HumanResourceCardBodyItemContent>
        <div>
          {clubExperiences && clubExperiences.length > 0
            ? clubExperiences.map((clubExperience, index) => (
                // eslint-disable-next-line react/no-array-index-key
                <ClubExperience key={index}>
                  <div>{clubExperience.name}</div>
                  <HumanResourceCardBodyListItemContent>
                    {clubExperience.content}
                  </HumanResourceCardBodyListItemContent>
                </ClubExperience>
              ))
            : '-'}
        </div>
      </HumanResourceCardBodyItemContent>
    </HumanResourceCardBodyItem>
  );
};

export default NewGraduateClubExperiencesField;
