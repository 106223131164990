import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { UploadImageRequest } from 'proto/v1/commonservice/commonservice';
import apiCommon from 'external/api/common';
import RequestError from 'classes/RequestError';
import { ErrorDialogTypes } from 'modules/errorDialog';
import { UiActions } from 'modules/ui';
import useErrorDialog from 'hooks/useErrorDialog';

function useMissionImageUploader() {
  const dispatch = useDispatch();
  const { t } = useTranslation('error');
  const { handleRequestError } = useErrorDialog();

  const uploadImage = async (params: UploadImageRequest) => {
    dispatch(UiActions.setLoading(true));
    try {
      const result = await apiCommon.uploadImages({
        params,
      });
      dispatch(UiActions.setLoading(false));
      return result.data;
    } catch (anyError) {
      dispatch(UiActions.setLoading(false));
      if (anyError instanceof RequestError) {
        if (anyError.isClientError()) {
          handleRequestError(
            anyError,
            t('failedToUploadMissionImage'),
            ErrorDialogTypes.RELOAD,
          );
        } else {
          handleRequestError(anyError);
        }
      }
    }
  };

  return {
    uploadImage,
  };
}

export default useMissionImageUploader;
