import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Mission } from 'proto/v1/apimodel/apimodel';
import { DetailCompanyField, DetailSubTitle } from 'components/molecules';

type CorporateAttributesFieldProps = {
  corporateAttributes: Mission['corporateAttributes'];
};

const CorporateAttributesField: FC<CorporateAttributesFieldProps> = ({
  corporateAttributes,
}) => {
  const { t } = useTranslation('missionDetail');

  return (
    <DetailCompanyField>
      <DetailSubTitle>{t('field.corporateAttribute.label')}</DetailSubTitle>
      <p>
        {corporateAttributes.length ? corporateAttributes[0].nameShort : '-'}
      </p>
    </DetailCompanyField>
  );
};

export default CorporateAttributesField;
