import { useState, useCallback, useEffect, useMemo } from 'react';
import { MissionFormErrors, MissionFormValues } from 'types/mission';
import { MISSION_PRODUCT_PRODUCT_TAGS_MAX_COUNT } from 'constants/config';
import {
  getMissionProductsErrorMessages,
  filterEmptyErrors,
} from '../../../validate/validateToSave';
import { validateMissionFormValuesToPublish } from '../../validateToPublish';
import useMissionProductsField from '../../../hooks/useMissionProductsField';
import { stepMissionProductsSchema } from '../../validationSchema';
import useMissionFormContext from '../../useMissionFormContext';
import useMissionFormFormik from '../../useMissionFormFormik';

type Values = Pick<MissionFormValues, 'missionProducts'>;

const useStepMissionProducts = () => {
  const {
    movePrevStep,
    moveNextStep,
    isFirstPublished,
    missionFormValues,
    openImageCropper,
    isPublished,
    updateMission,
    publishMission,
    unPublishMission,
    imageCropperState,
    cropperAspectRatio,
    handleExceedImageFileSize,
    handleCroppedImage,
    handleClickCropperCloseButton,
    modalState,
    closeModal,
    openModal,
    validateToSaveErrors,
    setValidateToSaveErrors,
    validateToPublishStepsResult,
    setValidateToPublishStepsResult,
    getAddedItemId,
    missionProductsFieldRef,
    missionProductRefs,
    isMissionProductAdded,
    setMissionProductAdded,
    openPreview,
    canUseMissionFormStepMissionDemand,
    canUseMissionFormStepMissionJobOffers,
  } = useMissionFormContext();

  const defaultInitialValues = useMemo<Values>(
    () => ({
      missionProducts: [
        {
          missionProductId: getAddedItemId('missionProducts'),
          name: '',
          overview: '',
          url: '',
          problem: '',
          solution: '',
          hasMemberBenefit: undefined,
          memberBenefit: '',
          image: undefined,
          productTarget: {
            isForBusiness: false,
            isForCustomer: false,
          },
          tags: Array.from({
            length: MISSION_PRODUCT_PRODUCT_TAGS_MAX_COUNT,
          }).map(_ => ({ tag: '', id: getAddedItemId('missionProductTags') })),
        },
      ],
    }),
    [getAddedItemId],
  );

  const [initialValues, setInitialValues] = useState<Values>(
    defaultInitialValues,
  );

  const { formik, handleChangeChecked } = useMissionFormFormik<Values>({
    initialValues,
    validationSchema: stepMissionProductsSchema,
  });

  const {
    dirty,
    values,
    errors,
    setFieldValue,
    setFieldTouched,
    setTouched,
    resetForm,
  } = formik;

  const getErrorsToSave = useCallback(
    () =>
      filterEmptyErrors({
        missionProducts: getMissionProductsErrorMessages(
          errors.missionProducts as MissionFormErrors['missionProducts'],
          values.missionProducts,
        ),
      }),
    [errors.missionProducts, values.missionProducts],
  );

  const validateToSave = useCallback(() => {
    const errorsToSave = getErrorsToSave();
    if (Object.keys(errorsToSave).length > 0) {
      setValidateToSaveErrors(errorsToSave);
      openModal('invalidToSave');
      return false;
    }
    return true;
  }, [getErrorsToSave, openModal, setValidateToSaveErrors]);

  const validateToPublish = useCallback(() => {
    if (!missionFormValues) return;

    const errorsToSave = getErrorsToSave();
    if (Object.keys(errorsToSave).length > 0) {
      setValidateToSaveErrors(errorsToSave);
      openModal('invalidToSave');
      return false;
    }

    const { isValid, stepsResult } = validateMissionFormValuesToPublish(
      {
        ...missionFormValues,
        ...values,
      },
      canUseMissionFormStepMissionDemand,
      canUseMissionFormStepMissionJobOffers,
    );

    setValidateToPublishStepsResult(stepsResult);

    if (!isValid) {
      openModal('invalidToPublish');
      return false;
    }

    return true;
  }, [
    canUseMissionFormStepMissionDemand,
    canUseMissionFormStepMissionJobOffers,
    getErrorsToSave,
    missionFormValues,
    openModal,
    setValidateToPublishStepsResult,
    setValidateToSaveErrors,
    values,
  ]);

  const handleClickSave = useCallback(() => {
    if (!dirty) return;
    if (!validateToSave()) return;
    openModal('saveMissionConfirmation');
  }, [dirty, openModal, validateToSave]);

  const saveMission = useCallback(async () => {
    if (!missionFormValues) return;
    try {
      await updateMission({ ...missionFormValues, ...values });
    } catch (error) {
      throw new Error('Failed to update mission');
    }
    setInitialValues(values);
    resetForm();
  }, [missionFormValues, resetForm, updateMission, values]);

  const handleClickNext = useCallback(async () => {
    if (dirty) {
      const errorsToSave = getErrorsToSave();
      if (Object.keys(errorsToSave).length > 0) {
        setValidateToSaveErrors(errorsToSave);
        openModal('invalidToSave');
        return;
      }
      try {
        await saveMission();
      } catch (error) {
        // Nothing to do.
        return;
      }
    }
    moveNextStep();
  }, [
    dirty,
    getErrorsToSave,
    moveNextStep,
    openModal,
    saveMission,
    setValidateToSaveErrors,
  ]);

  const handleClickSaveConfirmationOK = useCallback(async () => {
    try {
      await saveMission();
    } catch (error) {
      // Nothing to do.
      return;
    }
    openModal('saveMissionCompleted');
  }, [openModal, saveMission]);

  const handleClickPublish = useCallback(async () => {
    if (!validateToPublish()) {
      return;
    }
    openModal('publishMissionConfirmation');
  }, [openModal, validateToPublish]);

  const handleClickPublishConfirmationOk = useCallback(async () => {
    if (!missionFormValues) return;
    try {
      await publishMission({ ...missionFormValues, ...values });
    } catch (error) {
      // Nothing to do.
      return;
    }
    setInitialValues(values);
    resetForm();
  }, [missionFormValues, publishMission, resetForm, values]);

  const handleClickUnPublishConfirmationOk = useCallback(async () => {
    if (!missionFormValues) return;
    try {
      await unPublishMission({ ...missionFormValues, ...values });
    } catch (error) {
      // Nothing to do.
      return;
    }
    setInitialValues(values);
    resetForm();
  }, [missionFormValues, resetForm, unPublishMission, values]);

  const {
    handleAddMissionProduct,
    handleDeleteMissionProduct,
    handleChangeMissionProductImage,
    handleChangeHasMemberBenefit,
    missionProductsError,
  } = useMissionProductsField({
    missionProducts: values.missionProducts,
    isPublished,
    isMissionProductAdded,
    missionProductRefs,
    setMissionProductAdded,
    getAddedItemId,
    openImageCropper,
    setFieldValue,
    setFieldTouched,
    setTouched,
    errors: formik.errors.missionProducts,
    touched: formik.touched.missionProducts,
  });

  // Set initial values for fetched mission.
  useEffect(() => {
    if (!missionFormValues) return;
    setInitialValues({
      missionProducts:
        missionFormValues.missionProducts.length === 0
          ? defaultInitialValues.missionProducts
          : missionFormValues.missionProducts,
    });
  }, [defaultInitialValues.missionProducts, missionFormValues]);

  return {
    handleClickNext,
    movePrevStep,
    isPublished,
    isFirstPublished,
    formik,
    handleChangeChecked,
    imageCropperState,
    cropperAspectRatio,
    handleCroppedImage,
    handleExceedImageFileSize,
    handleClickCropperCloseButton,
    modalState,
    closeModal,
    handleClickSave,
    validateToSaveErrors,
    handleClickSaveConfirmationOK,
    handleClickPublish,
    handleClickPublishConfirmationOk,
    handleClickUnPublishConfirmationOk,
    validateToPublishStepsResult,
    handleAddMissionProduct,
    handleDeleteMissionProduct,
    handleChangeMissionProductImage,
    handleChangeHasMemberBenefit,
    missionProductsError,
    missionProductRefs,
    missionProductsFieldRef,
    openPreview,
    canUseMissionFormStepMissionDemand,
    canUseMissionFormStepMissionJobOffers,
  };
};

export default useStepMissionProducts;
