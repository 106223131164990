import React, { FC, ChangeEvent } from 'react';
import styled from 'styled-components/macro';
import { useTranslation } from 'react-i18next';
import { isEqualProps } from 'utils/object';
import { MissionFormValues } from 'types/mission';
import { MissionSettingMaxLength } from 'constants/form';
import { Textarea } from 'components/atoms';
import { FormItem, FormItemLabel } from 'components/molecules';

const OtherAddressTextarea = styled(Textarea)`
  textarea {
    width: 445px;
    height: 96px;
    min-height: 96px;
  }
`;

type OtherAddressFieldProps = {
  value: MissionFormValues['otherAddress'];
  countryId: MissionFormValues['countryId'];
  error: string | undefined;
  onChange: (e: ChangeEvent<HTMLTextAreaElement>) => void;
  onBlur: (e: any) => void;
};

const OtherAddressField: FC<OtherAddressFieldProps> = props => {
  const { value, error, countryId, onChange, onBlur } = props;

  const { t } = useTranslation('missionSetting');

  return (
    <FormItem error={error}>
      <FormItemLabel
        label={
          countryId === 'JPN'
            ? t('field.address.subField.otherAddress.labelJa')
            : t('field.address.subField.otherAddress.label')
        }
        note={t('field.address.subField.otherAddress.note', {
          maxLength: MissionSettingMaxLength.OTHER_ADDRESS,
        })}
        level="secondary"
        required
      />
      <OtherAddressTextarea
        maxLength={MissionSettingMaxLength.OTHER_ADDRESS}
        name="otherAddress"
        value={value}
        placeholder={
          countryId === 'JPN'
            ? t('field.address.subField.otherAddress.placeholderJa')
            : t('field.address.subField.otherAddress.placeholder')
        }
        onChange={onChange}
        onBlur={onBlur}
        aria-label={t('field.address.subField.otherAddress.label')}
      />
    </FormItem>
  );
};

export default React.memo(OtherAddressField, (prevProps, nextProps) =>
  isEqualProps(prevProps, nextProps, ['value', 'error', 'countryId']),
);
