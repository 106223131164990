import React, { FC, useMemo } from 'react';
import { OptionalFieldsTitleContent } from '../../../Contents';
import useSupporterFormContext from '../../useSupporterFormContext';

const OptionalFieldsTitleContainer: FC = () => {
  const { optionalFieldsTitleRef } = useSupporterFormContext();

  return useMemo(
    () => <OptionalFieldsTitleContent ref={optionalFieldsTitleRef} />,
    [optionalFieldsTitleRef],
  );
};

export default OptionalFieldsTitleContainer;
