import { useState } from 'react';
import { SupporterFormValues, SupporterForm } from 'types/supporter';
import useMyAccounts from 'hooks/useMyAccounts';
import useMissionSettingFormState from '../../hooks/useMissionSettingFormState';
import useDefaultInitialValues from './useDefaultInitialValues';

const supporterInitialForm: SupporterForm = {
  countries: [],
  japanPrefectures: [],
  memberRanges: [],
  supportAreas: [],
};

const useSupporterFormState = () => {
  const {
    getAddedItemId,
    error,
    setError,
    isPublished,
    setIsPublished,
    showMenu,
    setShowMenu,
    showPreview,
    setShowPreview,
    showRequiredFieldsTitle,
    setShowRequiredFieldsTitle,
    footerWidth,
    setFooterWidth,
    modalState,
    openModal,
    closeModal,
    imageCropperState,
    closeImageCropper,
    openImageCropper,
    saveImageCropper,
    cropperAspectRatio,
    validateToSaveErrors,
    setValidateToSaveErrors,
    headerRef,
    optionalFieldsTitleRef,
    formWrapperRef,
  } = useMissionSettingFormState();

  const { fetchMyAccounts } = useMyAccounts();

  const defaultInitialValues = useDefaultInitialValues({ getAddedItemId });

  const [initialValues, setInitialValues] = useState<SupporterFormValues>(
    defaultInitialValues,
  );

  const [form, setForm] = useState<SupporterForm>(supporterInitialForm);

  return {
    getAddedItemId,
    error,
    setError,
    showMenu,
    setShowMenu,
    showPreview,
    setShowPreview,
    showRequiredFieldsTitle,
    setShowRequiredFieldsTitle,
    footerWidth,
    setFooterWidth,
    modalState,
    openModal,
    closeModal,
    imageCropperState,
    closeImageCropper,
    openImageCropper,
    saveImageCropper,
    cropperAspectRatio,
    headerRef,
    optionalFieldsTitleRef,
    formWrapperRef,
    form,
    setForm,
    defaultInitialValues,
    initialValues,
    setInitialValues,
    isPublished,
    setIsPublished,
    fetchMyAccounts,
    validateToSaveErrors,
    setValidateToSaveErrors,
  };
};

export type SupporterFormState = ReturnType<typeof useSupporterFormState>;

export default useSupporterFormState;
