import { applyMiddleware, compose, createStore } from 'redux';
import { persistReducer, createMigrate } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import createReducer from './modules';

const middlewares: any[] = [];
const enhancers = [applyMiddleware(...middlewares)];

// If Redux DevTools Extension is installed use it, otherwise use Redux compose
/* eslint-disable no-underscore-dangle, indent */
// prettier-ignore
const composeEnhancers = (
  process.env.NODE_ENV !== 'production'
  && typeof window === 'object'
  // @ts-ignore
  && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    // @ts-ignore
    ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({})
    : compose
);
/* eslint-enable */

const migrations = {
  /* redux-persist can not downgrade */
  2: (prevState: any) => {
    // for migration ver.-1 to ver.2
    if (
      typeof prevState.account === 'object' &&
      typeof prevState.account.mission === 'object' &&
      typeof prevState.account.mission.missionId === 'string' &&
      prevState.account.mission.missionId.length > 0
    ) {
      // convert mission into missionId
      return {
        ...prevState,
        account: {
          ...prevState.account,
          // to keep compatibility (targetVer: -1)
          mission: { missionId: prevState.account.mission.missionId },
          missionId: prevState.account.mission.missionId,
        },
      };
    }
    return prevState;
  },
};

const persistConfig = {
  key: 'root',
  version: 2,
  storage,
  whitelist: ['account'],
  migrate: createMigrate(migrations, { debug: false }),
};

const persistedReducer = persistReducer(persistConfig, createReducer());

const store = createStore(persistedReducer, composeEnhancers(...enhancers));

// Make reducers hot reloadable, see http://mxs.is/googmo
/* istanbul ignore next */
// @ts-ignore
if (module.hot) {
  // @ts-ignore
  module.hot.accept('./modules', () => {
    // @ts-ignore
    store.replaceReducer(createReducer(store.injectedReducers));
  });
}

export default store;
