import React, { FC } from 'react';
import styled from 'styled-components/macro';
import theme from 'styles/theme';
import { useTranslation } from 'react-i18next';
import { ButtonM } from 'components/atoms';
import { Modal, ModalContent, ModalFooter } from 'components/molecules';
import {
  ValidateToSaveErrors,
  ValidateToSaveErrorKey,
} from '../../validate/validateToSave';

const InvalidToSaveModalContent = styled.div`
  width: 100%;
  margin-bottom: 32px;
  padding: 32px 0;
  border-bottom: 1px solid ${theme.borderDefault};
  line-height: 1.8;
  text-align: center;

  &:first-child {
    padding-top: 0;
  }

  &:last-child {
    margin-bottom: 0;
    border-bottom: none;
  }

  .subject {
    margin-bottom: 32px;
  }
`;

type InvalidToSaveModalProps = {
  show: boolean;
  onClose: () => void;
  errors: ValidateToSaveErrors | undefined;
};

const InvalidToSaveModal: FC<InvalidToSaveModalProps> = ({
  show,
  onClose,
  errors,
}) => {
  const { t } = useTranslation('missionSetting');

  const subjects: Record<ValidateToSaveErrorKey, string> = {
    missionDemand: t('modal.inValidToSave.missionDemand.subject'),
    missionMembers: t('modal.inValidToSave.missionMembers.subject'),
    companyUrl: t('modal.inValidToSave.companyUrl.subject'),
    missionProducts: t('modal.inValidToSave.missionProducts.subject'),
  };

  if (!errors) return null;

  return (
    <Modal show={show} closeModal={onClose} height={'auto'}>
      <ModalContent
        style={{ overflowY: 'auto', height: '264px' }}
        data-testid="modal-invalid-to-save"
      >
        {(Object.keys(errors) as (keyof ValidateToSaveErrors)[]).map(key => (
          <InvalidToSaveModalContent key={key}>
            <p className="subject">{subjects[key]}</p>
            {errors[key]?.map(message => (
              <p key={message}>{message}</p>
            ))}
          </InvalidToSaveModalContent>
        ))}
      </ModalContent>
      <ModalFooter>
        <ButtonM
          onClick={() => {
            onClose();
          }}
          buttonTheme="default"
          type="button"
        >
          {t('common:button.ok')}
        </ButtonM>
      </ModalFooter>
    </Modal>
  );
};

export default InvalidToSaveModal;
