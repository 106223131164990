import React from 'react';
import { Tag } from 'components/atoms';
import styled from 'styled-components';
import theme from 'styles/theme';
import typography from 'styles/typography';

const ProductTargetTag = styled(props => <Tag bordered {...props} />)`
  ${typography.labelDefault};
  display: inline-block;
  margin-bottom: 0;
  padding: 3px 13px;
  border-radius: 4px;
  border-color: ${theme.borderDefault};
  color: ${theme.basePrimary};
  font-weight: normal;
  text-align: center;
`;

export default ProductTargetTag;
