import { CancelToken } from 'axios';
import {
  RegisterMessageTemplateRequest,
  RegisterMessageTemplateResponse,
  PutMessageTemplateRequest,
  PutMessageTemplateResponse,
  GetMessageTemplatesResponse,
  UseMessageTemplateRequest,
  UseMessageTemplateResponse,
  DeleteMessageTemplatesRequest,
  DeleteMessageTemplatesResponse,
} from 'proto/v1/commonservice/commonservice';
import client from './config/client';
import apiFirebase from '../firebase/firebase';

export default {
  async registerMessageTemplate(
    req: RegisterMessageTemplateRequest,
  ): Promise<{ data: RegisterMessageTemplateResponse }> {
    const token = await apiFirebase.currentToken();
    const url = `${process.env.REACT_APP_API_URL}/v1/message_templates`;
    return client(token)
      .post(url, RegisterMessageTemplateRequest.toJSON(req))
      .then(x => {
        return {
          data: RegisterMessageTemplateResponse.fromJSON(x.data),
        };
      });
  },

  async putMessageTemplate(
    req: PutMessageTemplateRequest,
    cancelToken?: CancelToken,
  ): Promise<{ data: PutMessageTemplateResponse }> {
    const token = await apiFirebase.currentToken();
    const url = `${process.env.REACT_APP_API_URL}/v1/message_templates/${req.messageTemplateId}`;
    return client(token)
      .put(url, PutMessageTemplateRequest.toJSON(req), { cancelToken })
      .then(x => {
        return {
          data: PutMessageTemplateResponse.fromJSON(x.data),
        };
      });
  },

  async getMessageTemplates(
    cancelToken?: CancelToken,
  ): Promise<{ data: GetMessageTemplatesResponse }> {
    const token = await apiFirebase.currentToken();
    const url = `${process.env.REACT_APP_API_URL}/v1/message_templates`;
    return client(token)
      .get(url, { cancelToken })
      .then(x => {
        return {
          data: GetMessageTemplatesResponse.fromJSON(x.data),
        };
      });
  },

  async useMessageTemplate(
    req: UseMessageTemplateRequest,
    cancelToken?: CancelToken,
  ): Promise<{ data: UseMessageTemplateResponse }> {
    const token = await apiFirebase.currentToken();
    const url = `${process.env.REACT_APP_API_URL}/v1/use_message_template/${req.messageTemplateId}`;
    return client(token)
      .put(url, UseMessageTemplateRequest.toJSON(req), { cancelToken })
      .then(x => {
        return {
          data: UseMessageTemplateResponse.fromJSON(x.data),
        };
      });
  },

  async deleteMessageTemplates(
    req: DeleteMessageTemplatesRequest,
  ): Promise<{ data: DeleteMessageTemplatesResponse }> {
    const token = await apiFirebase.currentToken();
    const url = `${process.env.REACT_APP_API_URL}/v1/message_templates/delete`;
    return client(token)
      .post(url, DeleteMessageTemplatesRequest.toJSON(req))
      .then(x => {
        return {
          data: DeleteMessageTemplatesResponse.fromJSON(x.data),
        };
      });
  },
};
