import { CancelToken } from 'axios';
import {
  UploadImageRequest,
  UploadImageResponse,
  ClearNotificationRequest,
  ClearNotificationResponse,
  GetFeaturesResponse,
} from 'proto/v1/commonservice/commonservice';
import client from './config/client';
import apiFirebase from '../firebase/firebase';

export default {
  async uploadImages({
    params,
    requestCancelToken,
  }: {
    params: UploadImageRequest;
    requestCancelToken?: CancelToken;
  }): Promise<{ data: UploadImageResponse }> {
    const token = await apiFirebase.currentToken();
    const url = `${process.env.REACT_APP_API_URL}/v1/images`;
    return client(token).post(url, params, { cancelToken: requestCancelToken });
  },

  async clearNotification(
    req: ClearNotificationRequest,
  ): Promise<{ data: ClearNotificationResponse }> {
    const url = `${process.env.REACT_APP_API_URL}/v1/notifications/clear`;

    const token = await apiFirebase.currentToken();
    return client(token)
      .post(url, ClearNotificationRequest.toJSON(req))
      .then(x => {
        return {
          data: ClearNotificationResponse.fromJSON(x.data),
        };
      });
  },

  async getFeatures(
    requestCancelToken: CancelToken,
  ): Promise<{ data: GetFeaturesResponse }> {
    const token = await apiFirebase.currentToken();
    const url = `${process.env.REACT_APP_API_URL}/v1/features`;
    return client(token)
      .get(url, { cancelToken: requestCancelToken })
      .then(x => {
        return {
          data: x.data,
        };
      });
  },
};
