import { CancelToken } from 'axios';
import {
  UpdateSupporterRequest,
  UpdateSupporterResponse,
  GetSupporterFormRequest,
  GetSupporterFormResponse,
  GetContactMissionFormResponse,
  GetContactSupporterFormResponse,
  FollowMissionRequest,
  FollowMissionResponse,
  FollowSupporterRequest,
  FollowSupporterResponse,
} from 'proto/v1/supporterservice/supporterservice';
import {
  GetSupportersResponse,
  GetSupporterSearchConditionsResponse,
  GetSupportersRequest,
  GetSupporterResponse,
  GetSupporterRequest,
} from 'proto/v1/commonservice/commonservice';
import { GetSupportersRequestQuery } from 'types/supporter';
import client from './config/client';
import apiFirebase from '../firebase/firebase';

export default {
  async getSupporter(
    params: GetSupporterRequest,
    requestCancelToken?: CancelToken,
  ): Promise<{ data: GetSupporterResponse }> {
    const token = await apiFirebase.currentToken();
    const url = `${process.env.REACT_APP_API_URL}/v1/supporters/${params.supporterId}`;
    return client(token).get(url, {
      params,
      cancelToken: requestCancelToken,
    });
  },

  async getSupporters(
    params: GetSupportersRequestQuery,
    requestCancelToken?: CancelToken,
  ): Promise<{ data: GetSupportersResponse }> {
    const token = await apiFirebase.currentToken();
    const req: GetSupportersRequest = {
      limit: params.limit ?? 20,
      q: params.q ?? '',
      countryId: params.countryId,
      japanPrefectureIds: params.japanPrefectureIds ?? [],
      supportAreaIds: params.supportAreaIds ?? [],
    };
    const url = `${process.env.REACT_APP_API_URL}/v1/search_supporters`;
    return client(token).post(url, GetSupportersRequest.toJSON(req), {
      cancelToken: requestCancelToken,
    });
  },

  async getSupporterSearchConditions(
    requestCancelToken: CancelToken,
  ): Promise<{ data: GetSupporterSearchConditionsResponse }> {
    const token = await apiFirebase.currentToken();
    const url = `${process.env.REACT_APP_API_URL}/v1/search_conditions/supporter`;
    return client(token).get(url, { cancelToken: requestCancelToken });
  },

  async getContactMissionForm(
    requestCancelToken: CancelToken,
  ): Promise<{ data: GetContactMissionFormResponse }> {
    const token = await apiFirebase.currentToken();
    const url = `${process.env.REACT_APP_API_URL}/v1/supporter/follow_mission_form`;
    return client(token).get(url, { cancelToken: requestCancelToken });
  },

  async getContactSupporterForm(
    requestCancelToken: CancelToken,
  ): Promise<{ data: GetContactSupporterFormResponse }> {
    const token = await apiFirebase.currentToken();
    const url = `${process.env.REACT_APP_API_URL}/v1/supporter/follow_supporter_form`;
    return client(token).get(url, { cancelToken: requestCancelToken });
  },

  async followMission(
    params: FollowMissionRequest,
  ): Promise<{ data: FollowMissionResponse }> {
    const token = await apiFirebase.currentToken();
    const url = `${process.env.REACT_APP_API_URL}/v1/supporter/follow_mission`;
    return client(token).put(url, params);
  },

  async followSupporter(
    params: FollowSupporterRequest,
  ): Promise<{ data: FollowSupporterResponse }> {
    const token = await apiFirebase.currentToken();
    const url = `${process.env.REACT_APP_API_URL}/v1/supporter/follow_supporter`;
    return client(token).put(url, params);
  },

  async getSupporterForm(
    { supporterId }: GetSupporterFormRequest,
    requestCancelToken?: CancelToken,
  ): Promise<{ data: GetSupporterFormResponse }> {
    const token = await apiFirebase.currentToken();
    const url = `${process.env.REACT_APP_API_URL}/v1/supporters/${supporterId}/form`;
    return client(token).get(url, { cancelToken: requestCancelToken });
  },

  async putUpdateSupporter(
    supporter: UpdateSupporterRequest,
  ): Promise<{ data: UpdateSupporterResponse }> {
    const token = await apiFirebase.currentToken();
    const url = `${process.env.REACT_APP_API_URL}/v1/supporters/${supporter.supporterId}`;
    return client(token).put(url, supporter);
  },
};
