import {
  SupportAreaPickerValues,
  SupportAreaFilterValues,
} from 'types/supporter';
import { SupportArea } from 'proto/v1/apimodel/apimodel';
import { useMemo, ChangeEvent, useCallback, useEffect } from 'react';
import { useFormik } from 'formik';

const useSupportAreaPicker = ({
  form,
  onSubmit,
  defaultValues,
  isSelected,
}: {
  onSubmit: (values: SupportAreaPickerValues) => void;
  form: { supportAreas: SupportArea[] | undefined };
  defaultValues: SupportAreaFilterValues;
  isSelected: boolean;
}) => {
  const allSupportAreaIds = useMemo(
    () => form.supportAreas?.map(v => v.supportAreaId) ?? [],
    [form.supportAreas],
  );
  const isCheckedAllDefaultSupportAreaIds = useMemo(
    () => defaultValues.supportAreaIds.length === allSupportAreaIds.length,
    [allSupportAreaIds.length, defaultValues.supportAreaIds.length],
  );

  const formik = useFormik<SupportAreaPickerValues>({
    initialValues: {
      checkAllSupportAreas: isCheckedAllDefaultSupportAreaIds,
      supportAreaIds: defaultValues.supportAreaIds,
    },
    onSubmit,
    enableReinitialize: true,
  });

  const { values, setFieldValue, resetForm } = formik;

  const handleChangeSupportArea = useCallback(
    (event: ChangeEvent<HTMLInputElement>): void => {
      const { target } = event;
      const targetValue = Number(target.value);
      const { supportAreaIds } = values;
      if (target.checked) {
        setFieldValue('supportAreaIds', [...supportAreaIds, targetValue]);
      } else {
        setFieldValue(
          'supportAreaIds',
          supportAreaIds.filter(id => id !== targetValue),
        );
      }
    },
    [setFieldValue, values],
  );

  const handleChangeCheckAllSupportAreas = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const { checked } = event.currentTarget;
      setFieldValue('supportAreaIds', checked ? allSupportAreaIds : []);
      setFieldValue('checkAllSupportAreas', checked);
    },
    [allSupportAreaIds, setFieldValue],
  );

  useEffect(() => {
    if (!isSelected) resetForm();
  }, [isSelected, resetForm]);

  return {
    formik,
    handleChangeSupportArea,
    handleChangeCheckAllSupportAreas,
  };
};

export default useSupportAreaPicker;
