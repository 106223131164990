import { isObject } from 'utils/object';
import { CURRENT_VERSION, LocalStorageKey } from 'constants/storage';
import { DeprecatedLocalStorageState } from 'types/storage';

// Migration not to use "appState" key that is deprecated
export const migrateDeprecatedAppState = () => {
  const deprecatedStateJsonString = window.localStorage.getItem(
    LocalStorageKey.DEPRECATED_APP_STATE,
  );
  if (!deprecatedStateJsonString) return;

  const deprecatedState: DeprecatedLocalStorageState = JSON.parse(
    deprecatedStateJsonString,
  );

  // Flatten properties in AppState
  Object.keys(deprecatedState).forEach(key => {
    const targetKey = key as keyof DeprecatedLocalStorageState;
    const targetValue = deprecatedState[targetKey];
    window.localStorage.setItem(
      targetKey,
      isObject(targetValue) ? JSON.stringify(targetValue) : String(targetValue),
    );
  });
  window.localStorage.removeItem(LocalStorageKey.DEPRECATED_APP_STATE);
};

// Migration to use "showAboutScoutModalInitial" instead of "showAboutTimelineModalInitial" key that is deprecated
export const migrateDeprecatedShowAboutTimelineModalInitial = () => {
  const deprecatedShowAboutTimelineModalString = window.localStorage.getItem(
    LocalStorageKey.DEPRECATED_SHOW_ABOUT_TIMELINE_MODAL_INITIAL,
  );
  if (!deprecatedShowAboutTimelineModalString) return;
  const show = JSON.parse(deprecatedShowAboutTimelineModalString);
  if (typeof show !== 'boolean') return;
  window.localStorage.setItem(
    LocalStorageKey.SHOW_ABOUT_SCOUT_MODAL_INITIAL,
    String(show),
  );
  window.localStorage.removeItem(
    LocalStorageKey.DEPRECATED_SHOW_ABOUT_TIMELINE_MODAL_INITIAL,
  );
};

export const setStorageInfo = () => {
  const storageInfoJsonString = window.localStorage.getItem(
    LocalStorageKey.STORAGE_INFO,
  );
  if (storageInfoJsonString) return;
  window.localStorage.setItem(
    LocalStorageKey.STORAGE_INFO,
    JSON.stringify({ version: CURRENT_VERSION }),
  );
};

export const initLocalStorage = () => {
  migrateDeprecatedAppState();
  migrateDeprecatedShowAboutTimelineModalInitial();
  setStorageInfo();
};

export const getFileDownloadedKey = (
  accountId: string,
  chatRoomId: string,
  messageId: number,
) => `${accountId}_${chatRoomId}_${messageId}`;
