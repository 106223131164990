import React, { FC, HTMLAttributes } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components/macro';
import theme from 'styles/theme';
import typography from 'styles/typography';
import { FiTrash2 } from 'react-icons/fi';

const Wrapper = styled.div`
  ${typography.textNote}
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${theme.basePrimary};
  cursor: pointer;

  &:hover {
    opacity: 0.8;
  }
`;

const TrashIcon = styled.div`
  display: inline-block;
  margin-right: 4px;
`;

const Delete: FC<HTMLAttributes<HTMLDivElement>> = props => {
  const { t } = useTranslation('common');
  return (
    <Wrapper {...props} data-testid="delete">
      <TrashIcon>
        <FiTrash2 color={theme.basePrimary} size={16} />
      </TrashIcon>
      {t('button.delete')}
    </Wrapper>
  );
};

export default Delete;
