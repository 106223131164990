import { useState, useCallback } from 'react';

type ModalContentType =
  | 'missionLogo'
  | 'missionCover'
  | 'missionMemberIcon'
  | 'missionOfficeImage'
  | 'saveMissionConfirmation'
  | 'saveMissionCompleted'
  | 'saveRequiredNoInputConfirmation'
  | 'invalidToSave'
  | 'invalidToPublish'
  | 'publishMissionConfirmation'
  | 'publishMissionCompleted'
  | 'unPublishMissionConfirmation'
  | 'unPublishMissionCompleted'
  | 'imageFileSizeExceeded'
  | 'addMissionConfirmation'
  | 'addMissionCompleted'
  | 'deleteMissionConfirmation'
  | 'deleteMissionCompleted'
  | 'missionJobOfferForm';

type ModalStateType = {
  content?: ModalContentType;
  show: boolean;
  index?: number;
};

const useModal = () => {
  const [modalState, setModalState] = useState<ModalStateType>({
    show: false,
  });

  const openModal = useCallback(
    (modalContent: ModalContentType, index?: number) => {
      setModalState({
        content: modalContent,
        show: true,
        index,
      });
    },
    [setModalState],
  );

  const closeModal = useCallback(() => {
    setModalState({ show: false });
  }, [setModalState]);

  return {
    openModal,
    modalState,
    closeModal,
  };
};

export type ModalContext = ReturnType<typeof useModal>;

export default useModal;
