import React, { FC, HTMLAttributes } from 'react';
import styled from 'styled-components/macro';
import { GOOGLE_TRANSLATE } from 'constants/externalLink';
import { translatedByGoogle } from 'assets/png';

const Badge = styled.a.attrs({
  href: GOOGLE_TRANSLATE,
  target: '_blank',
  rel: 'noopener',
})`
  display: inline-block;

  &:hover {
    cursor: pointer;
  }
`;

const TranslatedByGoogleBadge: FC<HTMLAttributes<
  HTMLAnchorElement
>> = props => (
  <Badge {...props}>
    <img src={translatedByGoogle} alt="Translated by Google" />
  </Badge>
);

export default TranslatedByGoogleBadge;
