import React, { FC, ChangeEvent } from 'react';
import styled from 'styled-components/macro';
import { useTranslation } from 'react-i18next';
import { SupporterFormValues, SupporterForm } from 'types/supporter';
import { Checkbox, ErrorMessage } from 'components/atoms';
import { isEqualProps } from 'utils/object';
import { MissionSettingFormItem, FormItemLabel } from 'components/molecules';

const SupportAreaCheckbox = styled(Checkbox)`
  display: inline-block;
  width: 46%;
`;

const SupportAreaCheckboxes = styled.div`
  margin-bottom: -10px;
`;

const SupportAreaErrorMessage = styled(ErrorMessage)`
  position: absolute;
  bottom: -6px;
  left: 0;
`;

type SupportAreasFieldProps = {
  value: SupporterFormValues['supportAreaIds'];
  error: string | undefined;
  form: SupporterForm['supportAreas'];
  onChange: (event: ChangeEvent<HTMLInputElement>) => void;
};

const SupportAreasField: FC<SupportAreasFieldProps> = props => {
  const { value, error, form, onChange } = props;

  const { t } = useTranslation('missionSetting');

  return (
    <MissionSettingFormItem required style={{ marginBottom: 0 }}>
      <FormItemLabel
        label={t('field.supportAreas.label')}
        note={t('field.supportAreas.note')}
        required
      />
      <SupportAreaCheckboxes>
        {form &&
          form.map(supportAreaForm => (
            <SupportAreaCheckbox
              name="supportAreaIds"
              value={supportAreaForm.supportAreaId}
              checked={value.includes(supportAreaForm.supportAreaId)}
              onChange={onChange}
              aria-label={t('field.supportAreas.label')}
              key={supportAreaForm.supportAreaId}
            >
              {supportAreaForm.name}
            </SupportAreaCheckbox>
          ))}
      </SupportAreaCheckboxes>
      {error && <SupportAreaErrorMessage>{error}</SupportAreaErrorMessage>}
    </MissionSettingFormItem>
  );
};

export default React.memo(SupportAreasField, (prevProps, nextProps) =>
  isEqualProps(prevProps, nextProps, ['value', 'error', 'form']),
);
