import { isResourcesLanguage } from 'i18n/i18n';

export default {
  name: 'customPath',
  lookup() {
    if (typeof window === 'undefined') return undefined;
    const language = window.location.pathname.match(/\/([a-zA-Z-]*)/g);
    if (language === null) return undefined;
    const found = language[0].replace('/', '');
    return isResourcesLanguage(found) ? found : undefined;
  },
};
