import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import styled, { css } from 'styled-components/macro';
import { Accordion } from 'visits-style';
import * as PROTECTED_ROUTES from 'routes/constants/protected';
import theme from 'styles/theme';
import {
  menuButtonStyle,
  menuItemStyle,
  menuItemShrinkedStyle,
  accordionMenuItemStyle,
} from 'styles/sidebar';
import { Logo } from 'assets/svg';
import { SidebarNavLink, Badge } from 'components/atoms';
import { ConfirmModal } from 'components/molecules';
import {
  IconMissionList,
  IconHumanResource,
  IconFollowerList,
  IconChat,
  IconMyPage,
  AccordionIconUp,
  AccordionIconDown,
} from './sidebarIcons';
import SidebarTransition from './SidebarTransition';
import MyAccountsSelector from './MyAccountsSelector';
import SidebarFooter from './SidebarFooter';
import useSidebar from './useSidebar';

const AppSidebar = styled.div<{ isShrinked: boolean }>`
  min-width: 50px;
  height: auto;
  min-height: 100vh;
  transition: min-width 300ms 0s ease;
  ${({ isShrinked }) =>
    !isShrinked &&
    css`
      min-width: 220px;
    `}
`;

const SidebarContents = styled.div`
  display: flex;
  box-sizing: border-box;
  flex-direction: column;
  height: 100vh;
  padding: 24px 0 16px;
  overflow: auto;
  background: ${theme.bgMenuDefault};
  box-shadow: 5px 5px 12px rgba(0, 0, 0, 0.05);
`;

const LogoWrapper = styled.div`
  margin: 0 auto 16px;
  text-align: center;

  svg {
    fill: ${theme.baseWhite};
  }
`;

const Menu = styled.ul`
  list-style: none;
`;

const MenuItem = styled.li`
  position: relative;
`;

const MenuItemLink = styled(SidebarNavLink)<{ isShrinked: boolean }>`
  ${menuItemStyle}
  ${({ isShrinked }) => isShrinked && menuItemShrinkedStyle}
`;

const MenuItemLinkLabel = styled.div`
  display: inline-block;
  margin-left: 10px;

  &.enter {
    display: none;
  }

  &.enter-done {
    display: inline-block;
  }

  &.exit {
    display: none;
  }

  &.exit-done {
    display: none;
  }
`;

const MenuItemInnerLinkLabel = styled(MenuItemLinkLabel)`
  margin-left: 0;
`;

const MenuItemButton = styled.button<{ isShrinked: boolean }>`
  ${menuItemStyle}
  ${menuButtonStyle}
  ${({ isShrinked }) => isShrinked && menuItemShrinkedStyle}
`;

const MenuItemInnerLink = styled(SidebarNavLink)`
  ${accordionMenuItemStyle}
`;

const AccordionMenuItemButton = styled.button`
  ${accordionMenuItemStyle}
  ${menuButtonStyle}
`;

const NotificationWrapper = styled.div`
  position: absolute;
  top: 14px;
  right: 10px;
`;

const MenuItemInner = styled.div`
  a,
  button {
    padding-right: 0;
    padding-left: 50px;
  }

  &.enter {
    display: none;
  }

  &.enter-done {
    display: block;
  }

  &.exit {
    display: none;
  }

  &.exit-done {
    display: none;
  }
`;

const AccordionInner = styled.div<{ isShrinked: boolean }>`
  a,
  button {
    padding-right: 0;
    padding-left: 50px;
  }

  ${({ isShrinked }) =>
    isShrinked &&
    css`
      visibility: hidden;
    `}
`;

const HumanResourcesMenuItemButton = styled(MenuItemButton)`
  cursor: default;

  &:hover {
    background-color: inherit;
  }
`;

const Settings = styled.div`
  margin-bottom: auto;
`;

export function Sidebar() {
  const { t } = useTranslation(['sidebar', 'footer', 'common']);
  const {
    isSidebarShrinked,
    location,
    isMyPageAccordionOpened,
    handleLogoutWithRedirectToLogin,
    showContactModal,
    handleToggleContactModal,
    contactNotifications,
    totalMessagesCount,
    showLogoutModal,
    handleToggleLogoutModal,
    handleClickMenuItemLink,
    handleClickHumanResourcesMenu,
    handleClickMyPageMenu,
    canUseMissionMenu,
    canUseCareerHumanResourceMenu,
    canUseNewGraduateHumanResourceMenu,
    canUseFollowerMenu,
    canUseFollowingHumanResources,
    logoRoute,
    isSwitching,
  } = useSidebar();

  return (
    <AppSidebar isShrinked={isSidebarShrinked} data-testid="side-bar">
      <SidebarContents>
        <LogoWrapper>
          <Link to={logoRoute} onClick={handleClickMenuItemLink}>
            <Logo
              width={isSidebarShrinked ? 36 : 85}
              height={isSidebarShrinked ? 14 : 32}
              data-testid="sidebar_logo"
            />
          </Link>
        </LogoWrapper>
        <MyAccountsSelector />
        {!isSwitching && (
          <>
            <Menu data-testid="sidebar-menu">
              {(canUseCareerHumanResourceMenu ||
                canUseNewGraduateHumanResourceMenu) && (
                <MenuItem data-testid="menu-human-resources">
                  <Menu>
                    <MenuItem onClick={handleClickHumanResourcesMenu}>
                      <HumanResourcesMenuItemButton
                        type="button"
                        isShrinked={isSidebarShrinked}
                      >
                        <IconHumanResource />
                        <SidebarTransition isShrinked={isSidebarShrinked}>
                          <MenuItemLinkLabel>
                            {t('menu.humanResources')}
                          </MenuItemLinkLabel>
                        </SidebarTransition>
                      </HumanResourcesMenuItemButton>
                    </MenuItem>
                  </Menu>

                  <SidebarTransition isShrinked={isSidebarShrinked}>
                    <MenuItemInner>
                      <Menu>
                        {canUseCareerHumanResourceMenu && (
                          <MenuItem>
                            <MenuItemInnerLink
                              data-testid="human_resources_career"
                              to={{
                                pathname:
                                  PROTECTED_ROUTES.PRIVATE_HUMAN_RESOURCES_CAREER,
                              }}
                              activetarget={
                                PROTECTED_ROUTES.PRIVATE_HUMAN_RESOURCES_CAREER
                              }
                            >
                              <SidebarTransition isShrinked={isSidebarShrinked}>
                                <MenuItemInnerLinkLabel>
                                  {t('menu.humanResourcesCareer')}
                                </MenuItemInnerLinkLabel>
                              </SidebarTransition>
                            </MenuItemInnerLink>
                          </MenuItem>
                        )}
                        {canUseNewGraduateHumanResourceMenu && (
                          <MenuItem>
                            <MenuItemInnerLink
                              data-testid="human_resources_new_graduate"
                              to={{
                                pathname:
                                  PROTECTED_ROUTES.PRIVATE_HUMAN_RESOURCES_NEW_GRADUATE,
                              }}
                              activetarget={
                                PROTECTED_ROUTES.PRIVATE_HUMAN_RESOURCES_NEW_GRADUATE
                              }
                            >
                              <SidebarTransition isShrinked={isSidebarShrinked}>
                                <MenuItemInnerLinkLabel>
                                  {t('menu.humanResourcesNewGraduate')}
                                </MenuItemInnerLinkLabel>
                              </SidebarTransition>
                            </MenuItemInnerLink>
                          </MenuItem>
                        )}
                      </Menu>
                    </MenuItemInner>
                  </SidebarTransition>
                </MenuItem>
              )}
              {canUseMissionMenu && (
                <MenuItem>
                  <MenuItemLink
                    data-testid="find_mission"
                    isShrinked={isSidebarShrinked}
                    onClick={handleClickMenuItemLink}
                    to={{
                      pathname: PROTECTED_ROUTES.PRIVATE_MISSIONS,
                      state: { from: PROTECTED_ROUTES.PRIVATE_MISSIONS },
                    }}
                    from={
                      (location && location.state && location.state.from) ||
                      undefined
                    }
                    activetarget={PROTECTED_ROUTES.PRIVATE_MISSIONS}
                  >
                    <IconMissionList />
                    <SidebarTransition isShrinked={isSidebarShrinked}>
                      <MenuItemLinkLabel>
                        {t('menu.missionList')}
                      </MenuItemLinkLabel>
                    </SidebarTransition>
                  </MenuItemLink>
                </MenuItem>
              )}
              {canUseFollowerMenu && (
                <MenuItem>
                  <MenuItemLink
                    data-testid="followers_mission"
                    isShrinked={isSidebarShrinked}
                    onClick={handleClickMenuItemLink}
                    to={{
                      pathname: PROTECTED_ROUTES.PRIVATE_FOLLOWERS_MISSIONS,
                      state: {
                        from: PROTECTED_ROUTES.PRIVATE_FOLLOWERS_MISSIONS,
                      },
                    }}
                    from={
                      (location && location.state && location.state.from) ||
                      undefined
                    }
                    activetarget={PROTECTED_ROUTES.PRIVATE_FOLLOWERS_MISSIONS}
                  >
                    <IconFollowerList />
                    <SidebarTransition isShrinked={isSidebarShrinked}>
                      <MenuItemLinkLabel>
                        {t('menu.followersMissions')}
                      </MenuItemLinkLabel>
                    </SidebarTransition>
                    {!isSidebarShrinked && contactNotifications.length > 0 && (
                      <NotificationWrapper>
                        <Badge count={contactNotifications.length} />
                      </NotificationWrapper>
                    )}
                  </MenuItemLink>
                </MenuItem>
              )}
              <MenuItem>
                <MenuItemLink
                  to={PROTECTED_ROUTES.PRIVATE_MESSAGES}
                  isShrinked={isSidebarShrinked}
                  onClick={handleClickMenuItemLink}
                >
                  <IconChat />
                  <SidebarTransition isShrinked={isSidebarShrinked}>
                    <MenuItemLinkLabel>{t('menu.chat')}</MenuItemLinkLabel>
                  </SidebarTransition>
                </MenuItemLink>
                {!isSidebarShrinked && totalMessagesCount > 0 && (
                  <NotificationWrapper>
                    <Badge count={totalMessagesCount} />
                  </NotificationWrapper>
                )}
              </MenuItem>
            </Menu>
            <Settings>
              <Accordion
                header={
                  <Menu>
                    <MenuItem onClick={handleClickMyPageMenu}>
                      <MenuItemButton
                        type="button"
                        data-testid="mypage"
                        isShrinked={isSidebarShrinked}
                      >
                        <IconMyPage />
                        <SidebarTransition isShrinked={isSidebarShrinked}>
                          <MenuItemLinkLabel>
                            {t('menu.myPage')}
                          </MenuItemLinkLabel>
                        </SidebarTransition>
                        {!isSidebarShrinked && (
                          <>
                            {isMyPageAccordionOpened ? (
                              <AccordionIconUp />
                            ) : (
                              <AccordionIconDown />
                            )}
                          </>
                        )}
                      </MenuItemButton>
                    </MenuItem>
                  </Menu>
                }
                show={isMyPageAccordionOpened}
              >
                <AccordionInner isShrinked={isSidebarShrinked}>
                  <Menu>
                    <MenuItem>
                      <MenuItemInnerLink
                        to={{
                          pathname: canUseFollowingHumanResources
                            ? PROTECTED_ROUTES.PRIVATE_FOLLOWING_HUMAN_RESOURCES
                            : PROTECTED_ROUTES.PRIVATE_FOLLOWING_MISSIONS,
                        }}
                        activetarget={[
                          PROTECTED_ROUTES.PRIVATE_FOLLOWING_HUMAN_RESOURCES,
                          PROTECTED_ROUTES.PRIVATE_FOLLOWING_MISSIONS,
                        ]}
                        from={
                          (location && location.state && location.state.from) ||
                          undefined
                        }
                      >
                        <SidebarTransition isShrinked={isSidebarShrinked}>
                          <MenuItemInnerLinkLabel>
                            {t('menu.following')}
                          </MenuItemInnerLinkLabel>
                        </SidebarTransition>
                      </MenuItemInnerLink>
                    </MenuItem>
                    <MenuItem>
                      <SidebarTransition isShrinked={isSidebarShrinked}>
                        <MenuItemInnerLink
                          to={PROTECTED_ROUTES.PRIVATE_SETTINGS_MISSION}
                        >
                          {!isSidebarShrinked && t('menu.settingsMissions')}
                        </MenuItemInnerLink>
                      </SidebarTransition>
                    </MenuItem>
                    <MenuItem>
                      <MenuItemInnerLink
                        to={PROTECTED_ROUTES.PRIVATE_MESSAGE_TEMPLATE}
                      >
                        <SidebarTransition isShrinked={isSidebarShrinked}>
                          <MenuItemInnerLinkLabel>
                            {t('menu.messageTemplate')}
                          </MenuItemInnerLinkLabel>
                        </SidebarTransition>
                      </MenuItemInnerLink>
                    </MenuItem>
                    <MenuItem>
                      <MenuItemInnerLink
                        to={PROTECTED_ROUTES.PRIVATE_SETTINGS_ACCOUNT}
                      >
                        <SidebarTransition isShrinked={isSidebarShrinked}>
                          <MenuItemInnerLinkLabel>
                            {t('menu.settingsAccount')}
                          </MenuItemInnerLinkLabel>
                        </SidebarTransition>
                      </MenuItemInnerLink>
                    </MenuItem>
                    <MenuItem>
                      <AccordionMenuItemButton
                        type="button"
                        onClick={handleToggleLogoutModal}
                        data-testid="logout"
                      >
                        <SidebarTransition isShrinked={isSidebarShrinked}>
                          <MenuItemInnerLinkLabel>
                            {t('menu.logout')}
                          </MenuItemInnerLinkLabel>
                        </SidebarTransition>
                      </AccordionMenuItemButton>
                    </MenuItem>
                  </Menu>
                </AccordionInner>
              </Accordion>
            </Settings>

            <SidebarFooter onToggleContactModal={handleToggleContactModal} />
          </>
        )}
      </SidebarContents>

      <ConfirmModal
        type="normal"
        show={showContactModal}
        closeModal={handleToggleContactModal}
        confirmCaption={t('common:button.close')}
        confirm={handleToggleContactModal}
      >
        {t('common:description.contact')}
      </ConfirmModal>

      <ConfirmModal
        type="normal"
        show={showLogoutModal}
        closeModal={handleToggleLogoutModal}
        cancelCaption={t('common:button.cancel')}
        confirmCaption={t('common:button.ok')}
        confirm={handleLogoutWithRedirectToLogin}
      >
        <div>{t('modal.logout.content')}</div>
      </ConfirmModal>
    </AppSidebar>
  );
}

export default Sidebar;
