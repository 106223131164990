import React, { FC, ChangeEvent, CSSProperties } from 'react';
import styled from 'styled-components/macro';
import { useTranslation } from 'react-i18next';
import { isEqualProps } from 'utils/object';
import { SupporterFormValues } from 'types/supporter';
import { MissionSettingMaxLength } from 'constants/form';
import { Textarea } from 'components/atoms';
import { FormItemLabel, MissionSettingFormItem } from 'components/molecules';

const StyledTextarea = styled(Textarea)`
  width: 445px;

  textarea {
    min-height: 704px;
    line-height: 1.5;
  }
`;

type SupportDescriptionFieldProps = {
  value: SupporterFormValues['supportDescription'];
  error: string | undefined;
  onChange: (e: ChangeEvent<HTMLTextAreaElement>) => void;
  onBlur: (e: any) => void;
  style?: CSSProperties;
};

const SupportDescriptionField: FC<SupportDescriptionFieldProps> = props => {
  const { value, error, style, onChange, onBlur } = props;

  const { t } = useTranslation('missionSetting');

  return (
    <MissionSettingFormItem required error={error} style={style}>
      <FormItemLabel
        label={t('field.supportDescription.label')}
        note={t('field.supportDescription.note', {
          maxLength: MissionSettingMaxLength.SUPPORT_DESCRIPTION,
        })}
        required
      />
      <StyledTextarea
        maxLength={MissionSettingMaxLength.SUPPORT_DESCRIPTION}
        name="supportDescription"
        value={value}
        placeholder={t('field.supportDescription.placeholder')}
        onChange={onChange}
        onBlur={onBlur}
        aria-label={t('field.supportDescription.label')}
      />
    </MissionSettingFormItem>
  );
};

export default React.memo(SupportDescriptionField, (prevProps, nextProps) =>
  isEqualProps(prevProps, nextProps, ['value', 'error']),
);
