import { useState, useCallback, ChangeEvent } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getCanUsePublishedMissionNotification } from 'modules/account';
import { UiActions } from 'modules/ui';
import RequestError from 'classes/RequestError';
import { useFormik } from 'formik';
import { boolean, object } from 'yup';
import { ChangeNotificationFormValues } from 'types/account';
import apiAccount from 'external/api/account';
import useErrorDialog from 'hooks/useErrorDialog';

export default function useChangeNotificationForm({
  initialValues,
  onSubmitDone,
}: {
  initialValues: ChangeNotificationFormValues;
  onSubmitDone: () => Promise<void>;
}) {
  const [submitDone, setSubmitDone] = useState<boolean>(false);
  const [submitConfirm, setSubmitConfirm] = useState<boolean>(false);
  const canUsePublishedMissionNotification = useSelector(
    getCanUsePublishedMissionNotification,
  );
  const dispatch = useDispatch();
  const { handleRequestError } = useErrorDialog();

  const onSubmit = async ({
    notificationDisabled,
    publishedMissionNotificationDisabled,
  }: ChangeNotificationFormValues) => {
    dispatch(UiActions.setLoading(true));
    setSubmitConfirm(!submitConfirm);
    setSubmitDone(false);
    try {
      await apiAccount.changeAccountSettings({
        notificationDisabled,
        publishedMissionNotificationDisabled,
      });
      await onSubmitDone();
    } catch (e) {
      e instanceof RequestError && handleRequestError(e);
      dispatch(UiActions.setLoading(false));
      return;
    }
    setSubmitDone(true);
    dispatch(UiActions.setLoading(false));
  };

  const {
    values,
    setFieldValue,
    handleBlur,
    handleSubmit,
    isValid,
    errors,
    dirty,
  } = useFormik<ChangeNotificationFormValues>({
    enableReinitialize: true,
    initialValues,
    onSubmit,
    validationSchema: object({
      notificationDisabled: boolean(),
      publishedMissionNotificationDisabled: boolean(),
    }),
  });

  const handleChangeRadioChecked = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const { name, value } = event.target;
      setFieldValue(name, !!Number(value));
    },
    [setFieldValue],
  );

  return {
    values,
    setFieldValue,
    handleBlur,
    handleSubmit,
    isValid,
    errors,
    dirty,
    submitDone,
    setSubmitDone,
    submitConfirm,
    setSubmitConfirm,
    handleChangeRadioChecked,
    canUsePublishedMissionNotification,
  };
}
