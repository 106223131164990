enum GAEventNames {
  FOLLOW_MISSION = 'follow_mission',
  FOLLOW_SUPPORTER = 'follow_supporter',
  FOLLOW_BACK_MISSION = 'follow_back_mission',
  FOLLOW_BACK_SUPPORTER = 'follow_back_supporter',
  SAVE_MISSION = 'save_mission',
  SAVE_SUPPORTER = 'save_supporter',
  PUBLISH_MISSION = 'publish_mission',
  PUBLISH_SUPPORTER = 'publish_supporter',
  UNPUBLISH_MISSION = 'unpublish_mission',
  UNPUBLISH_SUPPORTER = 'unpublish_supporter',
}

export default GAEventNames;
