import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Tabs,
  Tab,
  TabList,
  TabPanel,
  DetailContainer,
  DetailContents,
  DetailContentsLeft,
  DetailContentsRight,
} from 'components/molecules';
import { CoverImageField } from 'components/organisms/Details/Common/Fields';
import {
  CompanyContent,
  DemandDetailContent,
  AboutMissionContent,
  ProductDetailContent,
} from 'components/organisms/Details/MissionDetail/Contents';
import {
  TranslateButtonContent,
  ContactButtonContent,
  FooterContent,
} from 'components/organisms/Details/Common/Contents';
import { Mission } from 'proto/v1/apimodel/apimodel';
import { isEmptyMissionDemand } from 'utils/models/mission';
import useMissionDetail from './useMissionDetail';

type MissionDetailProps = {
  mission?: Mission;
  isFollowed?: boolean;
  isFollowable?: boolean;
  isTranslated?: boolean;
  showContactButton?: boolean;
  disabledContactButton?: boolean;
  onFollow?: () => void;
  onToggleTranslate?: () => void;
  onNotFoundProduct?: () => void;
};

const MissionDetail: FC<MissionDetailProps> = props => {
  const { t } = useTranslation('missionDetail');

  const {
    mission,
    onFollow,
    onToggleTranslate,
    onNotFoundProduct,
    isFollowed = false,
    isFollowable = true,
    isTranslated = false,
    showContactButton = true,
    disabledContactButton = false,
  } = props;

  const {
    detailContainerRef,
    selectedTabIndex,
    showFooter,
    showTranslateButton,
    handleTabSelect,
    handleScroll,
    handleClickProductCard,
    productDetailRefs,
    setVisibleFooterTargetRef,
  } = useMissionDetail({ mission, onNotFoundProduct });

  if (!mission) return null;

  const canContact = isFollowable && !isFollowed;

  return (
    <>
      <DetailContainer
        backgroundImage={mission.coverImage}
        onScroll={handleScroll}
        marginBottom={canContact ? 182 : 102}
        ref={detailContainerRef}
      >
        <CoverImageField name={mission.name} coverImage={mission.coverImage} />
        {showTranslateButton && (
          <TranslateButtonContent
            isTranslated={isTranslated}
            onToggleTranslate={onToggleTranslate}
          />
        )}
        <DetailContents>
          <DetailContentsLeft>
            <Tabs selectedIndex={selectedTabIndex} onSelect={handleTabSelect}>
              <TabList
                style={{
                  padding: showTranslateButton
                    ? '8px 24px 0 32px'
                    : '40px 24px 0 32px',
                  marginBottom: '40px',
                }}
              >
                <Tab>{t('tab.mission')}</Tab>
                <Tab
                  disabled={mission.missionProducts.length === 0}
                  data-testid="tab-mission-products"
                >
                  {t('tab.missionProducts')}
                </Tab>
                <Tab
                  disabled={isEmptyMissionDemand(mission.missionDemand)}
                  data-testid="tab-mission-demand"
                >
                  {t('tab.missionDemand')}
                </Tab>
              </TabList>

              <TabPanel>
                <AboutMissionContent
                  mission={mission}
                  onClickProductCard={handleClickProductCard}
                />
              </TabPanel>

              <TabPanel>
                <ProductDetailContent
                  missionProducts={mission.missionProducts}
                  productDetailRefs={productDetailRefs}
                />
              </TabPanel>

              <TabPanel>
                <DemandDetailContent
                  missionDemand={mission.missionDemand}
                  isTranslated={isTranslated}
                />
              </TabPanel>
            </Tabs>
          </DetailContentsLeft>

          <DetailContentsRight
            data-testid="company-info"
            style={showTranslateButton ? {} : { paddingTop: '32px' }}
          >
            <ContactButtonContent
              labelContact={t('button.contact')}
              labelContacted={t('button.contacted')}
              show={showContactButton}
              isFollowed={isFollowed}
              disabled={disabledContactButton}
              onFollow={onFollow}
              buttonRef={(ref: HTMLButtonElement | null) => {
                setVisibleFooterTargetRef(ref);
              }}
            />
            <CompanyContent mission={mission} />
          </DetailContentsRight>
        </DetailContents>
      </DetailContainer>

      {canContact && (
        <FooterContent
          labelContact={t('button.contact')}
          labelContacted={t('button.contacted')}
          show={showFooter}
          showContactButton={showContactButton}
          isFollowed={isFollowed}
          missionName={mission.name}
          iconImage={mission.iconImage}
          companyName={mission.companyName}
          onFollow={onFollow}
        />
      )}
    </>
  );
};

export default MissionDetail;
