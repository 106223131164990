import React, { FC, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Helmet } from 'react-helmet';
import theme from 'styles/theme';
import typography from 'styles/typography';
import styled, { css } from 'styled-components/macro';
import {
  getRole,
  getCanUseFollowingHumanResources,
  getCanUseFollowingMissions,
} from 'modules/account';
import * as PROTECTED_ROUTES from 'routes/constants/protected';
import i18n from 'i18n/i18n';
import { Heading, FlexContainer } from 'components/atoms';
import { PrivateRouteSpacer } from 'components/templates';

const StyledPrivateRouteSpacer = styled(PrivateRouteSpacer)`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

const Tabs = styled(FlexContainer)`
  margin-bottom: 16px;
`;

const tabUnderLine = css`
  &::after {
    content: '';
    display: block;
    height: 3px;
    margin: 0 auto;
    border-radius: 8px;
    background-color: ${theme.basePrimary};
  }
`;

const Tab = styled.div<{ isSelected?: boolean }>`
  ${typography.textBold}
  width: 140px;
  height: 48px;
  color: ${({ isSelected }) =>
    isSelected ? theme.basePrimary : theme.textPrimary};
  line-height: 48px;
  text-align: center;
  cursor: ${({ isSelected }) => (isSelected ? 'default' : 'pointer')};
  ${tabUnderLine}

  &::after {
    width: ${({ isSelected }) => (isSelected ? '100%' : 0)};
  }

  &:hover {
    color: ${theme.basePrimary};
    ${tabUnderLine}

    &::after {
      width: 100%;
      transition: 0.3s;
    }
  }
`;

type TabItem = {
  name: string;
  path: string;
};

const tabItemMissions: TabItem = {
  name: i18n.t('tab.missions', { ns: 'following' }),
  path: PROTECTED_ROUTES.PRIVATE_FOLLOWING_MISSIONS,
};

const tabItemHumanResources: TabItem = {
  name: i18n.t('tab.humanResources', { ns: 'following' }),
  path: PROTECTED_ROUTES.PRIVATE_FOLLOWING_HUMAN_RESOURCES,
};

const FollowingLayout: FC = props => {
  const { t } = useTranslation(['following', 'head']);
  const role = useSelector(getRole);
  const canUseFollowingHumanResources = useSelector(
    getCanUseFollowingHumanResources,
  );
  const canUseFollowingMissions = useSelector(getCanUseFollowingMissions);

  const history = useHistory();

  const tabItems = useMemo<TabItem[]>(() => {
    if (role === 'supporter') {
      return [tabItemMissions];
    }

    const items: TabItem[] = [];
    if (canUseFollowingHumanResources) {
      items.push(tabItemHumanResources);
    }
    if (canUseFollowingMissions) {
      items.push(tabItemMissions);
    }

    return items;
  }, [canUseFollowingHumanResources, canUseFollowingMissions, role]);

  return (
    <>
      <Helmet>
        <title>{t('head:title.following')}</title>
      </Helmet>
      <StyledPrivateRouteSpacer>
        <Heading level="h1" contents="private" style={{ marginBottom: '22px' }}>
          {t('title')}
        </Heading>

        <Tabs data-testid="following-tabs">
          {tabItems.map(item => (
            <Tab
              key={item.name}
              isSelected={history.location.pathname.includes(item.path)}
              onClick={() => {
                history.push(item.path, {
                  from: item.path,
                });
              }}
            >
              {item.name}
            </Tab>
          ))}
        </Tabs>

        {props.children}
      </StyledPrivateRouteSpacer>
    </>
  );
};

export default FollowingLayout;
