import React, { Suspense, FC } from 'react';
import * as H from 'history';
import { withRouter } from 'react-router-dom';
import { Loading } from 'components/atoms';
import { ErrorDialog, CookieAgreementNotification } from 'components/organisms';
import { Head } from 'components/templates';
import { getLoading } from 'modules/ui';
import { useSelector } from 'react-redux';
import useGAUserPropertyUpdater from 'hooks/useGAUserPropertyUpdater';
import useTrackPage from 'hooks/useTrackPage';
import ErrorBoundary from 'components/templates/GlobalLayout/ErrorBoundary';

const GlobalLayout: FC<{ history: H.History }> = props => {
  const isLoading = useSelector(getLoading);
  useGAUserPropertyUpdater();
  useTrackPage(props.history);
  return (
    <>
      <Head />
      <ErrorBoundary>
        <Suspense fallback={<Loading />}>
          {props.children}
          {isLoading && <Loading />}
        </Suspense>
      </ErrorBoundary>
      <ErrorDialog />
      <CookieAgreementNotification />
    </>
  );
};

export default withRouter(GlobalLayout);
