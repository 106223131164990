import React from 'react';
import styled from 'styled-components/macro';
import { useTranslation } from 'react-i18next';
import { Mission } from 'proto/v1/apimodel/apimodel';
import { DetailTitle } from 'components/molecules';
import MissionJobOfferCard from './MissionJobOfferCard';

const MissionJobOfferCards = styled.div`
  display: flex;
  flex-direction: column;
  gap: 18px;
`;

type Props = {
  missionJobOffers: Mission['missionJobOffers'];
};

const MissionJobOffersField: React.VFC<Props> = ({ missionJobOffers }) => {
  const { t } = useTranslation('missionDetail');

  return (
    <div>
      <DetailTitle>{t('field.missionJobOffers.label')}</DetailTitle>
      <MissionJobOfferCards>
        {missionJobOffers
          .filter(missionJobOffer => missionJobOffer.published)
          .map(missionJobOffer => (
            <MissionJobOfferCard
              key={missionJobOffer.id}
              missionJobOffer={missionJobOffer}
            />
          ))}
      </MissionJobOfferCards>
    </div>
  );
};

export default MissionJobOffersField;
