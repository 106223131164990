import { onAuthStateChanged } from 'firebase/auth';
import { useEffect, useCallback, useState, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { UiActions } from 'modules/ui';
import { getMissionId } from 'modules/account';
import apiMission from 'external/api/mission';
import apiFirebase from 'external/firebase/firebase';
import { Mission } from 'proto/v1/apimodel/apimodel';
import { MissionForm } from 'types/mission';
import axios from 'axios';
import RequestError from 'classes/RequestError';
import useErrorDialog from 'hooks/useErrorDialog';

type Payload = {
  setMission: React.Dispatch<React.SetStateAction<Mission | undefined>>;
  setForm: React.Dispatch<React.SetStateAction<MissionForm>>;
  setIsFirstPublished: React.Dispatch<React.SetStateAction<boolean>>;
};

const useFetchMissionForm = ({
  setMission,
  setForm,
  setIsFirstPublished,
}: Payload) => {
  const { t } = useTranslation(['error']);
  const dispatch = useDispatch();
  const ownerMissionId = useSelector(getMissionId);

  const [isFetchedMissionForm, setIsFetchedMissionForm] = useState<boolean>(
    false,
  );

  const { handleRequestError } = useErrorDialog();

  const source = useMemo(() => axios.CancelToken.source(), []);

  const fetchMissionForm = useCallback(async () => {
    dispatch(UiActions.setLoading(true));
    let data;
    try {
      ({ data } = await apiMission.getMissionForm(
        ownerMissionId,
        source.token,
      ));
    } catch (error: unknown) {
      dispatch(UiActions.setLoading(false));
      if (error instanceof RequestError) {
        handleRequestError(error, t('error:failedGettingAny'));
      }
      return;
    }
    if (data.form) setForm(data.form);
    if (data.mission) setMission(data.mission);
    setIsFirstPublished(data.firstPublished);
    setIsFetchedMissionForm(true);
    dispatch(UiActions.setLoading(false));
  }, [
    dispatch,
    handleRequestError,
    ownerMissionId,
    setForm,
    setIsFirstPublished,
    setMission,
    source.token,
    t,
  ]);

  useEffect(() => {
    const unsubscribeAuthStateChanged = onAuthStateChanged(
      apiFirebase.auth(),
      user => {
        if (user) {
          fetchMissionForm();
        } else {
          source?.cancel();
        }
      },
    );

    return () => {
      unsubscribeAuthStateChanged();
      source?.cancel();
    };
  }, [ownerMissionId, dispatch, fetchMissionForm, source]);

  return {
    fetchMissionForm,
    isFetchedMissionForm,
  };
};

export default useFetchMissionForm;
