import React, { FC } from 'react';
import styled from 'styled-components/macro';
import { Mission } from 'proto/v1/apimodel/apimodel';
import typography from 'styles/typography';
import { WrapText } from 'components/atoms';

const CompanyName = styled(WrapText)`
  ${typography.headlineDefault}
  margin-bottom: 8px;
`;

type CompanyNameFieldProps = {
  companyName: Mission['companyName'];
};

const CompanyNameField: FC<CompanyNameFieldProps> = ({ companyName }) => {
  return <CompanyName>{companyName}</CompanyName>;
};

export default CompanyNameField;
