import { createSelector } from 'reselect';
import { MyAccount } from 'types/account';
import { createAction, ActionsUnion } from './helper';

/*
 * Redux Action actionTypes
 */

export const SET_LOGIN_ACCOUNT_ID = 'myAccounts/SET_LOGIN_ACCOUNT_ID';
export const CLEAR_LOGIN_ACCOUNT_ID = 'myAccounts/CLEAR_LOGIN_ACCOUNT_ID';
export const SET_MY_ACCOUNTS = 'myAccounts/SET_MY_ACCOUNTS';
export const SET_IS_SWITCHING = 'myAccounts/SET_IS_SWITCHING';

/*
 * Redux Actions
 */

export const MyAccountsActions = {
  setLoginAccountId: (loginAccountId?: string | undefined) =>
    createAction(SET_LOGIN_ACCOUNT_ID, loginAccountId),
  clearLoginAccountId: () => createAction(CLEAR_LOGIN_ACCOUNT_ID),
  setMyAccounts: (myAccounts: MyAccount[]) =>
    createAction(SET_MY_ACCOUNTS, myAccounts),
  setIsSwitching: (isSwitching: boolean) =>
    createAction(SET_IS_SWITCHING, isSwitching),
};
export type Actions = ActionsUnion<typeof MyAccountsActions>;

/*
 * Selectors
 */
export const getMyAccountsState = (state: any): MyAccountsState =>
  state.myAccounts;

export const getLoginAccountId = createSelector(
  [getMyAccountsState],
  state => state.loginAccountId,
);

export const getMyAccounts = createSelector(
  [getMyAccountsState],
  state => state.myAccounts,
);

export const getMyMissions = createSelector([getMyAccountsState], state =>
  state.myAccounts.filter(myAccount => !!myAccount.missionId),
);

export const getMySupporters = createSelector([getMyAccountsState], state =>
  state.myAccounts.filter(myAccount => !!myAccount.supporterId),
);

export const getIsSwitching = createSelector(
  [getMyAccountsState],
  state => state.isSwitching,
);

/*
 * Reducer
 */

export type MyAccountsState = {
  loginAccountId?: string | undefined;
  myAccounts: MyAccount[];
  isSwitching: boolean;
};

const initState: MyAccountsState = {
  myAccounts: [],
  isSwitching: false,
};

export default function reducer(state = initState, action: Actions) {
  if (!action) return state;

  switch (action.type) {
    case SET_LOGIN_ACCOUNT_ID: {
      return {
        ...state,
        loginAccountId: action.payload,
      };
    }
    case CLEAR_LOGIN_ACCOUNT_ID: {
      return {
        ...state,
        loginAccountId: undefined,
      };
    }
    case SET_MY_ACCOUNTS: {
      return {
        ...state,
        myAccounts: action.payload,
      };
    }
    case SET_IS_SWITCHING: {
      return {
        ...state,
        isSwitching: action.payload,
      };
    }
    default: {
      return state;
    }
  }
}
