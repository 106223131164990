import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { ButtonM } from 'components/atoms';
import { Modal, ModalContent, ModalFooter } from 'components/molecules';

type SaveConfirmationModalProps = {
  show: boolean;
  onOk: () => void;
  onCancel: () => void;
};

const SaveConfirmationModal: FC<SaveConfirmationModalProps> = ({
  show,
  onOk,
  onCancel,
}) => {
  const { t } = useTranslation('missionSetting');
  return (
    <Modal type={'saveMissionConfirmation'} show={show} closeModal={onCancel}>
      <ModalContent data-testid="modal-save-mission-confirmation">
        {t('modal.saveMissionConfirmation.content')}
      </ModalContent>
      <ModalFooter>
        <ButtonM buttonTheme="cancel" type="button" onClick={onCancel}>
          {t('button.returnToEntryPage')}
        </ButtonM>
        <ButtonM onClick={onOk} buttonTheme="default" type="button">
          {t('button.saveContents')}
        </ButtonM>
      </ModalFooter>
    </Modal>
  );
};

export default SaveConfirmationModal;
