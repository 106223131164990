import React, { FC, ComponentProps } from 'react';
import { useTranslation } from 'react-i18next';
import { ButtonM } from 'components/atoms';
import { Modal, ModalContent, ModalFooter } from 'components/molecules';

type Props = ComponentProps<typeof Modal>;

const AlertModal: FC<Props> = props => {
  const { closeModal, children } = props;

  const { t } = useTranslation('common');

  return (
    <Modal {...props}>
      <ModalContent data-testid="alert-modal">{children}</ModalContent>
      <ModalFooter>
        <ButtonM
          buttonTheme="default"
          onClick={closeModal}
          data-testid="alert-modal-button-close"
        >
          {t('common:button.ok')}
        </ButtonM>
      </ModalFooter>
    </Modal>
  );
};

export default AlertModal;
