import React, { FC, CSSProperties } from 'react';
import styled from 'styled-components/macro';
import { WrapText, LinkText } from 'components/atoms';
import { DetailTitle } from 'components/molecules';
import { Mission } from 'proto/v1/apimodel/apimodel';

const Wrapper = styled.div``;

const TextWrapper = styled(WrapText)`
  min-height: 248px;
  overflow: auto;
  line-height: 26px;
`;

type MissionDetailMissionDescriptionProps = {
  label: string;
  missionDescription: Mission['missionDescription'];
  style?: CSSProperties;
};

const MissionDetailMissionDescription: FC<MissionDetailMissionDescriptionProps> = props => {
  const { label, missionDescription, style } = props;

  return (
    <Wrapper style={style}>
      <DetailTitle>{label}</DetailTitle>
      <TextWrapper>
        <LinkText color="secondary">{missionDescription}</LinkText>
      </TextWrapper>
    </Wrapper>
  );
};

export default MissionDetailMissionDescription;
