import React, { FC, useEffect, useCallback, useState, useRef } from 'react';
import styled, { css } from 'styled-components';
import useSupporterFilter from 'components/pages/Private/Supporters/useSupporterFilter';
import { useTranslation } from 'react-i18next';
import { FlexContainer, About } from 'components/atoms';
import { AddressPicker, SupportAreaPicker } from '..';
import AboutSupporterModal from './AboutSupporterModal';

const ABOUT_WIDTH = 162;
const CALCULATE_PICKERS_WIDTH_THRESHOLD = 14;

const Pickers = styled.div<{ pickersWidth?: number }>`
  display: flex;
  align-items: center;
  height: 68px;
  ${({ pickersWidth }) =>
    pickersWidth &&
    css`
      width: ${pickersWidth}px;
    `}
`;

const AboutWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: ${ABOUT_WIDTH}px;
`;

type SupporterFilterWithAboutProps = ReturnType<typeof useSupporterFilter> & {
  cardWidth: number;
  cardMargin: number;
};

const SupporterFilterWithAbout: FC<SupporterFilterWithAboutProps> = props => {
  const {
    form,
    supportersFilterValues,
    selectedPicker,
    togglePicker,
    closePicker,
    isActiveSupportAreaPicker,
    isActiveAddressPicker,
    handleSupportAreaPickerSubmit,
    handleAddressPickerSubmit,
    cardWidth,
    cardMargin,
  } = props;

  const wrapperRef = useRef<HTMLDivElement | null>(null);
  const [pickersWidth, setPickersWidth] = useState<number | undefined>();

  const calculatePickersWidth = useCallback(
    (wrapperWidth: number) => {
      let nextWidth = cardWidth;
      const increase = cardMargin + cardWidth;
      while (
        nextWidth + increase <
        wrapperWidth - CALCULATE_PICKERS_WIDTH_THRESHOLD
      ) {
        nextWidth += increase;
      }
      return nextWidth - ABOUT_WIDTH;
    },
    [cardMargin, cardWidth],
  );

  const updatePickersWidth = useCallback(() => {
    setPickersWidth(
      wrapperRef.current?.clientWidth
        ? calculatePickersWidth(wrapperRef.current.clientWidth)
        : undefined,
    );
  }, [calculatePickersWidth]);

  const callbackRef = useCallback(
    (element: HTMLDivElement | null) => {
      wrapperRef.current = element;
      if (element) setPickersWidth(calculatePickersWidth(element.clientWidth));
    },
    [calculatePickersWidth],
  );

  useEffect(() => {
    window?.addEventListener('resize', updatePickersWidth);
    return () => window?.removeEventListener('resize', updatePickersWidth);
  }, [updatePickersWidth]);

  const [showAboutSupporterModal, setShowAboutSupporterModal] = useState(false);
  const handleClickAbout = useCallback(
    () => setShowAboutSupporterModal(true),
    [],
  );
  const handleCloseAboutModal = useCallback(
    () => setShowAboutSupporterModal(false),
    [],
  );

  const { t } = useTranslation(['supporterList', 'common']);
  return (
    <>
      <FlexContainer alignItems="stretch" flexDirection="row" ref={callbackRef}>
        <Pickers pickersWidth={pickersWidth}>
          <SupportAreaPicker
            isActive={isActiveSupportAreaPicker}
            isSelected={selectedPicker === 'supportArea'}
            onClickTag={() => togglePicker('supportArea')}
            onClickOverlay={closePicker}
            onSubmit={handleSupportAreaPickerSubmit}
            form={{ supportAreas: form?.supportAreas }}
            defaultValues={{
              supportAreaIds: supportersFilterValues.supportAreaIds,
            }}
          />
          <AddressPicker
            isActive={isActiveAddressPicker}
            isSelected={selectedPicker === 'address'}
            onClickTag={() => togglePicker('address')}
            onClickOverlay={closePicker}
            onSubmit={handleAddressPickerSubmit}
            form={{
              japanPrefectures: form?.japanPrefectures,
              countries: [
                {
                  countryId: '',
                  name: t('common:selectBox.notSelected'),
                  phoneCountryCode: '',
                },
                ...(form?.countries || []),
              ],
            }}
            defaultValues={{
              countryId: supportersFilterValues.countryId,
              japanPrefectureIds: supportersFilterValues.japanPrefectureIds,
            }}
          />
        </Pickers>
        <AboutWrapper>
          <About text={t('aboutSupporter.label')} onClick={handleClickAbout} />
        </AboutWrapper>
      </FlexContainer>
      <AboutSupporterModal
        show={showAboutSupporterModal}
        closeModal={handleCloseAboutModal}
      />
    </>
  );
};

export default SupporterFilterWithAbout;
