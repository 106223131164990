import React, { FC } from 'react';
import { Supporter } from 'proto/v1/apimodel/apimodel';
import {
  DetailContainer,
  DetailContents,
  DetailContentsLeft,
  DetailContentsRight,
} from 'components/molecules';
import { CoverImageField } from 'components/organisms/Details/Common/Fields';
import {
  TranslateButtonContent,
  ContactButtonContent,
  FooterContent,
} from 'components/organisms/Details/Common/Contents';
import { useTranslation } from 'react-i18next';
import useSupporterDetail from './useSupporterDetail';
import { AboutSupporterContent, CompanyContent } from './Contents';

type SupporterDetailProps = {
  supporter?: Supporter;
  isPreview?: boolean;
  isFollowed?: boolean;
  isFollowable?: boolean;
  isTranslated?: boolean;
  showContactButton?: boolean;
  disabledContactButton?: boolean;
  onFollow?: () => void;
  onToggleTranslate?: () => void;
};

const SupporterDetail: FC<SupporterDetailProps> = props => {
  const {
    supporter,
    isPreview = false,
    onFollow,
    onToggleTranslate,
    isFollowed = false,
    isFollowable = true,
    isTranslated = false,
    showContactButton = true,
    disabledContactButton = false,
  } = props;

  const {
    detailContainerRef,
    showFooter,
    showTranslateButton,
    handleScroll,
    setVisibleFooterTargetRef,
  } = useSupporterDetail(supporter);

  const { t } = useTranslation('supporterDetail');

  if (!supporter) return null;

  const canContact = isFollowable && !isFollowed;

  return (
    <>
      <DetailContainer
        backgroundImage={supporter.coverImage}
        onScroll={handleScroll}
        marginBottom={canContact ? 182 : 102}
        isPreview={isPreview}
        ref={detailContainerRef}
      >
        <CoverImageField
          name={supporter.name}
          coverImage={supporter.coverImage}
        />
        {showTranslateButton && (
          <TranslateButtonContent
            isTranslated={isTranslated}
            onToggleTranslate={onToggleTranslate}
          />
        )}
        <DetailContents>
          <DetailContentsLeft
            style={showTranslateButton ? {} : { paddingTop: '32px ' }}
          >
            <AboutSupporterContent supporter={supporter} />
          </DetailContentsLeft>
          <DetailContentsRight
            data-testid="company-info"
            style={showTranslateButton ? {} : { paddingTop: '32px ' }}
          >
            <ContactButtonContent
              labelContact={t('button.contact')}
              labelContacted={t('button.contacted')}
              show={showContactButton}
              isFollowed={isFollowed}
              disabled={disabledContactButton}
              isPreview={isPreview}
              onFollow={onFollow}
              buttonRef={(ref: HTMLButtonElement | null) => {
                setVisibleFooterTargetRef(ref);
              }}
            />
            <CompanyContent supporter={supporter} />
          </DetailContentsRight>
        </DetailContents>
      </DetailContainer>

      {canContact && (
        <FooterContent
          labelContact={t('button.contact')}
          labelContacted={t('button.contacted')}
          show={showFooter}
          showContactButton={showContactButton}
          isFollowed={isFollowed}
          isPreview={isPreview}
          missionName={supporter.name}
          iconImage={supporter.iconImage}
          companyName={supporter.companyName}
          onFollow={onFollow}
        />
      )}
    </>
  );
};

export default SupporterDetail;
