import React, { FC, useCallback, ChangeEvent } from 'react';
import { useTranslation } from 'react-i18next';
import { MissionCategory } from 'proto/v1/apimodel/apimodel';
import { MissionForm, MissionFormValues } from 'types/mission';
import { MISSION_CATEGORIES_MAX_COUNT } from 'constants/config';
import { isEqualProps } from 'utils/object';
import {
  CategorySelectableTag,
  MissionSettingFormItem,
  FormItemLabel,
} from 'components/molecules';

type MissionCategoriesFieldProps = {
  value: MissionFormValues['missionCategories'];
  error: string | undefined;
  form: MissionForm['missionCategories'];
  onChange: (
    event: ChangeEvent<HTMLInputElement>,
    targetCategory: MissionCategory,
  ) => void;
};

const MissionCategoriesField: FC<MissionCategoriesFieldProps> = props => {
  const { value, error, form, onChange } = props;

  const { t } = useTranslation('missionSetting');

  const isCategoryIdSelected = useCallback(
    (targetCategoryId: number) => {
      if (!value) return false;
      return value.some(
        (selectedCategoryId: number) => selectedCategoryId === targetCategoryId,
      );
    },
    [value],
  );

  return (
    <MissionSettingFormItem required error={error}>
      <FormItemLabel
        label={t('field.missionCategories.label')}
        note={t('field.missionCategories.note', {
          maxCount: MISSION_CATEGORIES_MAX_COUNT,
        })}
        required
      />

      <div>
        {form &&
          form.map(category => (
            <CategorySelectableTag
              colors={{
                backgroundColor: category.backgroundColor,
                inactiveBackgroundColor: category.inactiveBackgroundColor,
                inactiveTextColor: category.inactiveTextColor,
              }}
              key={category.categoryId}
              name={category.name}
              onChange={(e: ChangeEvent<HTMLInputElement>) => {
                onChange(e, category);
              }}
              value={category.categoryId}
              checked={isCategoryIdSelected(category.categoryId)}
              disabled={
                !isCategoryIdSelected(category.categoryId) &&
                value.length >= MISSION_CATEGORIES_MAX_COUNT
              }
            >
              {category.name}
            </CategorySelectableTag>
          ))}
      </div>
    </MissionSettingFormItem>
  );
};

export default React.memo(MissionCategoriesField, (prevProps, nextProps) =>
  isEqualProps(prevProps, nextProps, ['value', 'error', 'form']),
);
