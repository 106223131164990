/* eslint-disable */
import './polyfills';
import React from 'react';
import ReactDOM from 'react-dom';
/* eslint-enable */
import './i18n/i18n';
import apiFirebase from './external/firebase/firebase';
import App from './App';

apiFirebase.init();
const root = document.getElementById('root');
if (root) {
  ReactDOM.render(<App />, root);
}
