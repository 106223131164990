import React from 'react';
import styled, { css } from 'styled-components/macro';
import { useTranslation } from 'react-i18next';
import { Matter } from 'proto/v1/apimodel/apimodel';
import theme from 'styles/theme';
import { QuestionCircleGray } from 'assets/svg';
import { EllipsisBox } from 'components/atoms';
import {
  HumanResourceCardBodyItemLabel,
  HumanResourceCardBodyItemContent,
} from 'components/molecules';

const Wrapper = styled.div<{ isDetail: boolean }>`
  padding: 18px 32px 18px 32px;
  background-color: ${theme.baseWhite};
  ${({ isDetail }) =>
    isDetail &&
    css`
      padding: 36px 36px 30px 36px;
    `}
`;

const MainFieldTop = styled.div`
  display: flex;
`;

const MainFieldTopLeft = styled.div`
  padding-right: 28px;
  border-right: 1px solid ${theme.borderTable};
`;

const MainFieldTopRight = styled.div`
  padding-left: 28px;
`;

const SelfIntroduction = styled.div`
  display: flex;
  margin-top: 12px;
`;

const ProgramingExperience = styled.div`
  margin-bottom: 4px;

  &:last-child {
    margin-bottom: 0;
  }
`;

const MainFieldItem = styled.div<{ isDetail: boolean }>`
  display: flex;
  margin-bottom: 16px;
  ${({ isDetail }) =>
    isDetail &&
    css`
      margin-bottom: 24px;
    `}

  &:last-child {
    margin-bottom: 0;
  }
`;

const MainFieldItemContent = styled(HumanResourceCardBodyItemContent)`
  width: 240px;
`;

const DttHelpIcon = styled(QuestionCircleGray)`
  margin-left: 8px;
  cursor: pointer;

  &:hover {
    opacity: 0.8;
  }
`;

const Output = styled.a.attrs({ target: '_blank', rel: 'noopener' })`
  display: block;
  max-width: 238px;
  margin-bottom: 4px;
  overflow: hidden;
  color: ${theme.textPrimary};
  font-size: 14px;
  line-height: 14px;
  text-decoration: none;
  text-overflow: ellipsis;
  white-space: nowrap;

  &:hover {
    color: ${theme.textPrimaryHover};
    text-decoration: underline;
  }

  &:last-child {
    margin-bottom: 0;
  }
`;

type Props = {
  matter: Matter;
  onOpenAboutDttModal: () => void;
  isDetail?: boolean;
};

const MainField: React.VFC<Props> = ({
  matter,
  onOpenAboutDttModal,
  isDetail = false,
}) => {
  const { humanResource } = matter;
  const { t } = useTranslation(['matter']);
  return (
    <Wrapper isDetail={isDetail}>
      <MainFieldTop>
        <MainFieldTopLeft>
          <MainFieldItem isDetail={isDetail}>
            <HumanResourceCardBodyItemLabel>
              {t('field.pastExperienceAchievements.label', {
                lng: 'ja',
              })}
            </HumanResourceCardBodyItemLabel>
            <MainFieldItemContent>
              {humanResource?.newGraduate?.pastExperienceAchievements &&
              humanResource.newGraduate.pastExperienceAchievements.length > 0
                ? humanResource.newGraduate.pastExperienceAchievements
                    .map(
                      pastExperienceAchievement =>
                        pastExperienceAchievement.name,
                    )
                    .join(' / ')
                : '-'}
            </MainFieldItemContent>
          </MainFieldItem>
          <MainFieldItem isDetail={isDetail}>
            <HumanResourceCardBodyItemLabel>
              {t('field.dtt.label', {
                lng: 'ja',
              })}
              <DttHelpIcon
                onClick={e => {
                  e.stopPropagation();
                  onOpenAboutDttModal();
                }}
              />
            </HumanResourceCardBodyItemLabel>
            <MainFieldItemContent>
              {humanResource?.dttScore && humanResource?.dttRank
                ? `${humanResource.dttScore} / ${humanResource.dttRank.name}`
                : '-'}
            </MainFieldItemContent>
          </MainFieldItem>
          <MainFieldItem isDetail={isDetail}>
            <HumanResourceCardBodyItemLabel>
              {t('field.englishSkill.label', {
                lng: 'ja',
              })}
            </HumanResourceCardBodyItemLabel>
            <MainFieldItemContent>
              {humanResource?.englishSkill?.name ?? '-'}
            </MainFieldItemContent>
          </MainFieldItem>
          <MainFieldItem style={{ marginBottom: 0 }} isDetail={isDetail}>
            <HumanResourceCardBodyItemLabel>
              {t('field.certifications.label', {
                lng: 'ja',
              })}
            </HumanResourceCardBodyItemLabel>
            <MainFieldItemContent>
              {humanResource?.certifications &&
              humanResource?.certifications.length > 0
                ? humanResource?.certifications.join(' / ')
                : '-'}
            </MainFieldItemContent>
          </MainFieldItem>
          {isDetail && (
            <>
              {humanResource?.outputs && humanResource.outputs.length > 0 && (
                <MainFieldItem
                  style={{ marginBottom: 0, marginTop: '24px' }}
                  isDetail={isDetail}
                >
                  <HumanResourceCardBodyItemLabel>
                    {t('field.outputs.label', {
                      lng: 'ja',
                    })}
                  </HumanResourceCardBodyItemLabel>
                  <MainFieldItemContent>
                    {humanResource?.outputs.map((output, index) => (
                      // eslint-disable-next-line react/no-array-index-key
                      <Output key={index} href={output}>
                        {output}
                      </Output>
                    ))}
                  </MainFieldItemContent>
                </MainFieldItem>
              )}
              <MainFieldItem
                style={{ marginBottom: 0, marginTop: '24px' }}
                isDetail={isDetail}
              >
                <HumanResourceCardBodyItemLabel>
                  {t('field.programmingExperiences.label', {
                    lng: 'ja',
                  })}
                </HumanResourceCardBodyItemLabel>
                <MainFieldItemContent>
                  {humanResource?.newGraduate?.programmingExperiences &&
                  humanResource.newGraduate.programmingExperiences.length > 0
                    ? humanResource.newGraduate.programmingExperiences.map(
                        (programmingExperience, index) => (
                          <ProgramingExperience
                            // eslint-disable-next-line react/no-array-index-key
                            key={index}
                          >
                            <p>{programmingExperience.languageName}</p>
                            <p>{programmingExperience.level?.name}</p>
                          </ProgramingExperience>
                        ),
                      )
                    : '-'}
                </MainFieldItemContent>
              </MainFieldItem>
            </>
          )}
        </MainFieldTopLeft>

        <MainFieldTopRight>
          <MainFieldItem isDetail={isDetail}>
            <HumanResourceCardBodyItemLabel>
              {t('field.preferredWorkStyle.label', {
                lng: 'ja',
              })}
            </HumanResourceCardBodyItemLabel>
            <MainFieldItemContent>
              {humanResource?.newGraduate?.preferredWorkStyle?.name ?? '-'}
            </MainFieldItemContent>
          </MainFieldItem>
          <MainFieldItem isDetail={isDetail}>
            <HumanResourceCardBodyItemLabel>
              {t('field.preferredBusinessTypes.label', {
                lng: 'ja',
              })}
            </HumanResourceCardBodyItemLabel>
            <MainFieldItemContent>
              {humanResource?.newGraduate?.preferredBusinessTypes &&
              humanResource.newGraduate.preferredBusinessTypes.length > 0
                ? humanResource.newGraduate.preferredBusinessTypes
                    .map(preferredOccupation => preferredOccupation.name)
                    .join(' / ')
                : '-'}
            </MainFieldItemContent>
          </MainFieldItem>
          <MainFieldItem style={{ marginBottom: 0 }} isDetail={isDetail}>
            <HumanResourceCardBodyItemLabel>
              {t('field.preferredOccupations.label', {
                lng: 'ja',
              })}
            </HumanResourceCardBodyItemLabel>
            <MainFieldItemContent>
              {humanResource?.preferredOccupations &&
              humanResource?.preferredOccupations.length > 0
                ? humanResource?.preferredOccupations
                    .map(preferredOccupation => preferredOccupation.name)
                    .join(' / ')
                : '-'}
            </MainFieldItemContent>
          </MainFieldItem>
          {isDetail && (
            <>
              <MainFieldItem
                style={{ marginBottom: 0, marginTop: '24px' }}
                isDetail={isDetail}
              >
                <HumanResourceCardBodyItemLabel>
                  {t('field.preferredWorkLocations.label', {
                    lng: 'ja',
                  })}
                </HumanResourceCardBodyItemLabel>
                <MainFieldItemContent>
                  {humanResource?.preferredWorkLocations &&
                  humanResource.preferredWorkLocations.length > 0
                    ? humanResource.preferredWorkLocations
                        .map(
                          preferredWorkLocation =>
                            preferredWorkLocation.japanPrefecture?.name ??
                            t('field.preferredWorkLocations.overseas', {
                              lng: 'ja',
                            }),
                        )
                        .join(' / ')
                    : '-'}
                </MainFieldItemContent>
              </MainFieldItem>
            </>
          )}
        </MainFieldTopRight>
      </MainFieldTop>

      {!isDetail && (
        <SelfIntroduction>
          <HumanResourceCardBodyItemLabel>
            {t('field.selfIntroduction.label', {
              lng: 'ja',
            })}
          </HumanResourceCardBodyItemLabel>
          <HumanResourceCardBodyItemContent
            style={{ width: '672px' }}
            fontWeight="normal"
          >
            <EllipsisBox line={2} width={'672px'} maxHeight={'45px'}>
              {humanResource?.selfIntroduction}
            </EllipsisBox>
          </HumanResourceCardBodyItemContent>
        </SelfIntroduction>
      )}
    </Wrapper>
  );
};

export default MainField;
