import { useMemo, useCallback } from 'react';
import { useHistory } from 'react-router-dom';

const useHistoryState = <T extends {}>(
  key: string,
  comparator: (a: T | undefined, b: T | undefined) => boolean,
) => {
  const history = useHistory<any>();

  const historyState = useMemo(
    () =>
      history.location.state && history.location?.state[key]
        ? (history.location?.state[key] as T)
        : undefined,
    [history.location, key],
  );

  const replace = useCallback(
    (state: T | undefined) => {
      if (comparator(historyState, state)) return;
      history.replace(
        `${history.location.pathname}${history.location.search}${history.location.hash}`,
        {
          ...(history.location.state ?? {}),
          [key]: state,
        },
      );
    },
    [comparator, history, historyState, key],
  );

  const push = useCallback(
    (path: string, state: T) => history.push(path, { [key]: state }),
    [history, key],
  );

  return {
    historyState,
    replace,
    push,
  };
};

export default useHistoryState;
