import { CancelToken } from 'axios';
import {
  GetMissionsRequest,
  GetMissionsResponse,
  GetMissionRequest,
  GetMissionResponse,
  GetMissionSearchConditionsResponse,
  GetMissionProductsResponse,
  GetMissionProductsRequest,
} from 'proto/v1/commonservice/commonservice';
import {
  GetMissionFormResponse,
  UpdateMissionRequest,
  UpdateMissionResponse,
  CreateAnotherMissionResponse,
  DeleteMyMissionRequest,
  DeleteMyMissionResponse,
  GetContactMissionFormRequest,
  GetContactMissionFormResponse,
  GetContactSupporterFormRequest,
  GetContactSupporterFormResponse,
  FollowMissionRequest,
  FollowMissionResponse,
  FollowSupporterRequest,
  FollowSupporterResponse,
  GetMissionUsePlansResponse,
} from 'proto/v1/missionservice/missionservice';
import {
  GetMissionsRequestQuery,
  GetMissionProductsQuery,
} from 'types/mission';
import client from './config/client';
import apiFirebase from '../firebase/firebase';

export default {
  async getMissions(
    params: GetMissionsRequestQuery,
    requestCancelToken?: CancelToken,
  ): Promise<{ data: GetMissionsResponse }> {
    const token = await apiFirebase.currentToken();
    const req: GetMissionsRequest = {
      limit: params.limit ?? 20,
      q: params.q ?? '',
      demandIds: params.demandIds ?? [],
      financingSeriesIds: params.financingSeriesIds ?? [],
      corporateAttributeIds: params.corporateAttributeIds ?? [],
      japanPrefectureIds: params.japanPrefectureIds ?? [],
      categoryIds: params.categoryIds ?? [],
      countryId: params.countryId,
    };

    const url = `${process.env.REACT_APP_API_URL}/v1/search_missions`;
    return client(token).post(url, GetMissionsRequest.toJSON(req), {
      cancelToken: requestCancelToken,
    });
  },
  async getMission(
    params: GetMissionRequest,
    requestCancelToken?: CancelToken,
  ): Promise<{ data: GetMissionResponse }> {
    const token = await apiFirebase.currentToken();
    const url = `${process.env.REACT_APP_API_URL}/v1/missions/${params.missionId}`;
    return client(token).get(url, {
      params,
      cancelToken: requestCancelToken,
    });
  },
  async getMissionProducts(
    params: GetMissionProductsQuery,
    requestCancelToken?: CancelToken,
  ): Promise<{ data: GetMissionProductsResponse }> {
    const token = await apiFirebase.currentToken();
    const req: GetMissionProductsRequest = {
      limit: params.limit ?? 18,
      q: params.q ?? '',
      isForBusiness: !!params.isForBusiness,
      isForCustomer: !!params.isForCustomer,
      hasMemberBenefit: !!params.hasMemberBenefit,
    };
    const url = `${process.env.REACT_APP_API_URL}/v1/search_products`;
    return client(token).post(url, GetMissionProductsRequest.toJSON(req), {
      cancelToken: requestCancelToken,
    });
  },
  async getMissionForm(
    missionId?: string,
    requestCancelToken?: CancelToken,
  ): Promise<{ data: GetMissionFormResponse }> {
    const token = await apiFirebase.currentToken();
    const url = `${process.env.REACT_APP_API_URL}/v1/missions/${missionId}/form`;
    return client(token).get(url, { cancelToken: requestCancelToken });
  },
  async createAnotherMission(
    requestCancelToken?: CancelToken,
  ): Promise<{
    data: CreateAnotherMissionResponse;
  }> {
    const token = await apiFirebase.currentToken();
    const url = `${process.env.REACT_APP_API_URL}/v1/missionowner/missions`;
    return client(token).post(url, { cancelToken: requestCancelToken });
  },
  async deleteMyMission(
    req: DeleteMyMissionRequest,
    requestCancelToken?: CancelToken,
  ): Promise<{ data: DeleteMyMissionResponse }> {
    const token = await apiFirebase.currentToken();
    const url = `${process.env.REACT_APP_API_URL}/v1/missionowner/missions/${req.missionId}`;
    return client(token).delete(url, { cancelToken: requestCancelToken });
  },
  async putUpdateMission(
    mission: UpdateMissionRequest,
  ): Promise<{ data: UpdateMissionResponse }> {
    const token = await apiFirebase.currentToken();
    const url = `${process.env.REACT_APP_API_URL}/v1/missions/${mission.missionId}`;
    return client(token).put(url, mission);
  },
  async getMissionSearchConditions(
    requestCancelToken: CancelToken,
  ): Promise<{ data: GetMissionSearchConditionsResponse }> {
    const token = await apiFirebase.currentToken();
    const url = `${process.env.REACT_APP_API_URL}/v1/search_conditions/mission`;
    return client(token).get(url, { cancelToken: requestCancelToken });
  },

  async getContactMissionForm(
    params: GetContactMissionFormRequest,
    requestCancelToken: CancelToken,
  ): Promise<{ data: GetContactMissionFormResponse }> {
    const token = await apiFirebase.currentToken();
    const url = `${process.env.REACT_APP_API_URL}/v1/mission/follow_mission_form`;
    return client(token).get(url, { params, cancelToken: requestCancelToken });
  },

  async getContactSupporterForm(
    params: GetContactSupporterFormRequest,
    requestCancelToken: CancelToken,
  ): Promise<{ data: GetContactSupporterFormResponse }> {
    const token = await apiFirebase.currentToken();
    const url = `${process.env.REACT_APP_API_URL}/v1/mission/follow_supporter_form`;
    return client(token).get(url, { params, cancelToken: requestCancelToken });
  },

  async followMission(
    params: FollowMissionRequest,
  ): Promise<{ data: FollowMissionResponse }> {
    const token = await apiFirebase.currentToken();
    const url = `${process.env.REACT_APP_API_URL}/v1/mission/follow_mission`;
    return client(token).put(url, params);
  },

  async followSupporter(
    params: FollowSupporterRequest,
  ): Promise<{ data: FollowSupporterResponse }> {
    const token = await apiFirebase.currentToken();
    const url = `${process.env.REACT_APP_API_URL}/v1/mission/follow_supporter`;
    return client(token).put(url, params);
  },

  async getMissionUsePlans(
    requestCancelToken?: CancelToken,
  ): Promise<{ data: GetMissionUsePlansResponse }> {
    const token = await apiFirebase.currentToken();
    const url = `${process.env.REACT_APP_API_URL}/v1/mission_use_plans`;
    return client(token)
      .get(url, { cancelToken: requestCancelToken })
      .then(x => {
        return {
          data: GetMissionUsePlansResponse.fromJSON(x.data),
        };
      });
  },
};
