import React, { FC } from 'react';
import styled from 'styled-components/macro';
import theme from 'styles/theme';
import { imageSelector } from 'utils/imageSelector';
import { Mission } from 'proto/v1/apimodel/apimodel';
import { noImageCover } from 'assets/png';
import { WrapText } from 'components/atoms';

const CoverImage = styled.div<{ imageSrc: string }>`
  position: relative;
  height: 440px;
  background-image: url(${props => props.imageSrc});
  background-position: center;
  background-size: cover;
`;

const TransparentLayer = styled.div`
  width: 100%;
  height: 100%;
  background: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0.46) 0%,
    rgba(0, 0, 0, 0) 24% 70%,
    rgba(0, 0, 0, 0.3) 100%
  );
`;

const CoverImageContent = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  padding: 32px;
`;

const MissionName = styled(WrapText)`
  width: 800px;
  color: ${theme.baseWhite};
  font-size: 24px;
  font-weight: bold;
  line-height: 1.5;
`;

type CoverImageFieldProps = {
  name: Mission['name'];
  coverImage: Mission['coverImage'];
};

const CoverImageField: FC<CoverImageFieldProps> = ({ name, coverImage }) => {
  return (
    <CoverImage
      imageSrc={imageSelector(coverImage, 'large') || noImageCover}
      data-testid="cover-image"
    >
      <TransparentLayer>
        <CoverImageContent>
          <MissionName>{name}</MissionName>
        </CoverImageContent>
      </TransparentLayer>
    </CoverImage>
  );
};

export default CoverImageField;
