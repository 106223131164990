import React, { useState, useCallback } from 'react';
import { Matter } from 'proto/v1/apimodel/apimodel';
import { CSSTransition } from 'react-transition-group';
import styled from 'styled-components/macro';
import {
  CareerMattersFilterValues,
  NewGraduateMattersFilterValues,
} from 'types/matter';
import BulkScoutScreenContent from './BulkScoutScreenContent';

const TRANSITION_DURATION = 250;

const Screen = styled.div<{ show: boolean }>`
  display: ${({ show }) => (show ? 'block' : 'none')};
  position: absolute;
  z-index: 5;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transform: scale(0.8);
  transition-property: transform, opacity;
  transition-duration: ${TRANSITION_DURATION}ms;
  transition-timing-function: cubic-bezier(0.645, 0.045, 0.355, 1);
  opacity: 0;
  will-change: transform, opacity;

  &.enter {
    transform: scale(1);
    opacity: 1;
  }

  &.enter-done {
    transform: scale(1);
    opacity: 1;
  }

  &.exit {
    transform: scale(0.8);
    opacity: 0;
  }

  &.exit-done {
    transform: scale(0.8);
    opacity: 0;
  }
`;

export type Props = {
  show: boolean;
  matterPublishIds: Matter['matterPublishId'][];
  excludeMatterPublishIds: Matter['matterPublishId'][];
  onBulkScout: (
    matterIds: Matter['matterId'][],
    message: string,
  ) => Promise<void>;
  onClose: () => void;
  shouldFetchAll: boolean;
  onShowExceedMaxBulkScoutError: () => void;
  career?: {
    filterValues: CareerMattersFilterValues;
  };
  newGraduate?: {
    filterValues: NewGraduateMattersFilterValues;
  };
};

const BulkScoutScreen: React.VFC<Props> = props => {
  const [showContent, setShowContent] = useState<boolean>(false);

  const handleEnter = useCallback(() => {
    setShowContent(true);
  }, []);

  const handleExited = useCallback(() => {
    setShowContent(false);
  }, []);

  return (
    <CSSTransition
      in={props.show}
      timeout={TRANSITION_DURATION}
      onEnter={handleEnter}
      onExited={handleExited}
    >
      <Screen data-testid="bulk-scout-screen" show={showContent}>
        {showContent && <BulkScoutScreenContent {...props} />}
      </Screen>
    </CSSTransition>
  );
};

export default BulkScoutScreen;
