import React, { FC, ChangeEvent, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { MissionFormValues } from 'types/mission';
import { isEqualProps } from 'utils/object';
import {
  MISSION_COMPANY_ESTABLISHED_YEAR_INITIAL_VALUE,
  MISSION_COMPANY_ESTABLISHED_YEAR_MIN,
  MISSION_COMPANY_ESTABLISHED_MONTH_INITIAL_VALUE,
} from 'constants/config';
import { range } from 'utils/array';
import { FlexContainer, Selectbox } from 'components/atoms';
import { MissionSettingFormItem, FormItemLabel } from 'components/molecules';

type CompanyEstablishedDateFieldProps = {
  values?: MissionFormValues['companyEstablishedDate'];
  onChangeYear: (e: ChangeEvent<HTMLSelectElement>) => void;
  onChangeMonth: (e: ChangeEvent<HTMLSelectElement>) => void;
  onBlur: (e: any) => void;
};

const FieldContainer = styled(FlexContainer)`
  align-items: flex-end;
`;

const DateLabel = styled.span`
  margin-right: 20px;
  margin-left: 8px;
`;

const d = new Date();
const currentYear = d.getFullYear();
const currentMonth = d.getMonth() + 1;

const CompanyEstablishedDateField: FC<CompanyEstablishedDateFieldProps> = props => {
  const { values, onChangeYear, onChangeMonth, onBlur } = props;
  const { t, i18n } = useTranslation('missionSetting');

  const years = useMemo(
    () =>
      range(
        MISSION_COMPANY_ESTABLISHED_YEAR_MIN,
        new Date().getFullYear(),
      ).map(year => ({ year })),
    [],
  );

  const months = useMemo(() => {
    const maxMonth = values?.year === currentYear ? currentMonth : 12;
    return range(1, maxMonth).map(month => ({ month }));
  }, [values]);

  const yearSelect = (
    <Selectbox
      width="95px"
      textAlign="right"
      name="companyEstablishedDate.year"
      options={years}
      idName="year"
      textName="year"
      value={values?.year ?? MISSION_COMPANY_ESTABLISHED_YEAR_INITIAL_VALUE}
      onBlur={onBlur}
      onChange={onChangeYear}
      aria-label={t('field.companyEstablishedDate.year.label')}
    />
  );

  const monthSelect = (
    <Selectbox
      width="95px"
      textAlign="right"
      name="companyEstablishedDate.month"
      options={months}
      idName="month"
      textName="month"
      value={values?.month ?? MISSION_COMPANY_ESTABLISHED_MONTH_INITIAL_VALUE}
      onBlur={onBlur}
      onChange={onChangeMonth}
      aria-label={t('field.companyEstablishedDate.month.label')}
    />
  );

  return (
    <MissionSettingFormItem>
      <FormItemLabel label={t('field.companyEstablishedDate.label')} required />
      <FieldContainer>
        {i18n.language.startsWith('ja') && (
          <>
            {yearSelect}
            <DateLabel>年</DateLabel>
            {monthSelect}
            <DateLabel>月</DateLabel>
          </>
        )}
        {!i18n.language.startsWith('ja') && (
          <>
            <div style={{ marginRight: 8 }}>{monthSelect}</div>
            {yearSelect}
          </>
        )}
      </FieldContainer>
    </MissionSettingFormItem>
  );
};

export default React.memo(CompanyEstablishedDateField, (prevProps, nextProps) =>
  isEqualProps(prevProps, nextProps, ['values']),
);
