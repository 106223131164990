import styled from 'styled-components/macro';
import theme from 'styles/theme';

const PickerFooter = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 16px 0 24px 0;
  background-color: ${theme.baseWhite};
`;

export default PickerFooter;
