import styled from 'styled-components/macro';

type FlexContainerProps = {
  alignItems?: 'center' | 'start' | 'stretch';
  justifyContent?:
    | 'space-between'
    | 'center'
    | 'flex-start'
    | 'flex-end'
    | 'start';
  flexWrap?: 'wrap' | 'no-wrap';
  flexDirection?: 'row' | 'column';
};

const FlexContainer = styled.div`
  display: flex;
  flex-direction: ${({ flexDirection = 'row' }: FlexContainerProps) =>
    flexDirection};
  flex-wrap: ${({ flexWrap = 'wrap' }: FlexContainerProps) => flexWrap};
  align-items: ${({ alignItems = 'start' }: FlexContainerProps) => alignItems};
  justify-content: ${({ justifyContent = 'start' }: FlexContainerProps) =>
    justifyContent};
  width: 100%;
`;

export default FlexContainer;
