export enum LocalStorageKey {
  STORAGE_INFO = 'storage',
  IS_AGREED_TO_COOKIE = 'isAgreedToCookie',
  IS_ALLOWED_SHOW_FOLLOW_BACK_CONFIRM_MODAL = 'isAllowedShowFollowBackConfirmModal',
  CHAT_MESSAGES_TO_RESEND = 'chatMessagesToResend',
  DEPRECATED_APP_STATE = 'appState',
  RECOMMENDED_TO_EDIT_MISSION = 'recommendedToEditMission',
  SHOW_ABOUT_SCOUT_MODAL_INITIAL = 'showAboutScoutModalInitial',
  DEPRECATED_SHOW_ABOUT_TIMELINE_MODAL_INITIAL = 'showAboutTimelineModalInitial', // Deprecated
}
export const CURRENT_VERSION = 0;
