import React, { FC, ComponentProps } from 'react';
import styled, { css } from 'styled-components/macro';
import { useTranslation } from 'react-i18next';
import typography from 'styles/typography';
import theme from 'styles/theme';
import { Checkbox, ButtonS } from 'components/atoms';
import {
  Picker,
  PickerHeading,
  PickerContent,
  PickerFooter,
} from 'components/molecules';
import { FinancingSeries, Demand } from 'proto/v1/apimodel/apimodel';
import { DemandPickerValues, DemandMissionFilterValues } from 'types/mission';
import { DemandId } from 'constants/models/mission';
import useDemandPicker from './useDemandPicker';

const Form = styled.form`
  width: 790px;
`;

const HeadingCheckbox = styled(Checkbox)`
  &&& {
    display: inline-block;
    margin-right: 0;
    margin-bottom: 0;
    margin-left: 16px;

    label {
      font-weight: normal;
    }
  }
`;

const PickerCheckboxes = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
`;

const PickerCheckbox = styled(Checkbox)`
  &&& {
    display: flex;
    align-items: flex-start;
    width: 226px;
    height: 53px;
    margin-right: 24px;
    margin-bottom: 0;

    &:nth-child(3n) {
      margin-right: 0;
    }

    label {
      display: flex;
      align-items: flex-start;
      line-height: 1.5;

      & div {
        margin: 2px 8px 0 0;
      }
    }
  }
`;

const FinancingSeriesCheckboxes = styled.div`
  display: flex;
`;

const FinancingSeriesCheckbox = styled(Checkbox)`
  &&& {
    display: flex;
    min-width: 134px;
    margin-right: 0;
    margin-bottom: 0;
  }
`;

const Note = styled.div`
  ${typography.textNote};
  margin-top: 32px;
  color: ${theme.textSecondary};
`;

const FinancingSeriesLabel = styled.div<{ disabled: boolean }>`
  display: flex;
  flex-wrap: wrap;
  ${({ disabled }) =>
    disabled &&
    css`
      color: ${theme.baseDisabled};
    `}
`;

const DEMAND_ORDER: { [id: number]: number } = {
  [DemandId.COLLABORATION]: 1,
  [DemandId.INVEST]: 2,
  [DemandId.FUNDRAISING]: 3,
  [DemandId.SERVICE_SUGGESTION]: 4,
  [DemandId.SERVICE_INFORMATION]: 5,
  [DemandId.TRANSFER_OF_BUSINESS]: 6,
  [DemandId.TRANSFERRED_OF_BUSINESS]: 7,
  [DemandId.ACCEPT_SECONDMENT]: 8,
  [DemandId.SEND_EMPLOYEE]: 9,
  [DemandId.FURNISHED_OFFICE_RENTAL_AVAILABILITY]: 10,
  [DemandId.FURNISHED_OFFICE_RENTAL_REQUEST]: 11,
};

type PickerProps = ComponentProps<typeof Picker>;

type DemandPickerProps = {
  onSubmit: (values: DemandPickerValues) => void;
  form: {
    demands: Demand[] | undefined;
    financingSeries: FinancingSeries[] | undefined;
  };
  defaultValues: DemandMissionFilterValues;
} & Pick<
  PickerProps,
  'isActive' | 'isSelected' | 'onClickTag' | 'onClickOverlay'
>;

const DemandPicker: FC<DemandPickerProps> = props => {
  const {
    isActive,
    isSelected,
    onClickTag,
    onClickOverlay,
    onSubmit,
    form,
    defaultValues,
  } = props;

  const { t } = useTranslation(['missionList', 'common']);

  const {
    formik,
    handleChangeDemands,
    handleChangeFinancingSeries,
    handleChangeCheckAllDemands,
    handleChangeCheckAllFinancingSeries,
    isDisabledFinancingSeries,
  } = useDemandPicker({ onSubmit, form, defaultValues, isSelected });

  const { values, handleSubmit } = formik;

  return (
    <Picker
      label={t('picker.demand.label')}
      isSelected={isSelected}
      isActive={isActive}
      onClickTag={onClickTag}
      onClickOverlay={onClickOverlay}
    >
      <Form onSubmit={handleSubmit} data-testid="demand-picker">
        <PickerHeading>
          {t('picker.demand.field.demands.label')}
          <HeadingCheckbox
            name="checkAllDemands"
            onChange={handleChangeCheckAllDemands}
            checked={values.checkAllDemands}
            aria-label={t('picker.checkbox.toggleCheckAll')}
            data-testid="checkbox-check-all-demands"
          >
            {t('picker.checkbox.toggleCheckAll')}
          </HeadingCheckbox>
        </PickerHeading>
        <PickerContent style={{ paddingBottom: '16px' }}>
          <PickerCheckboxes>
            {form?.demands &&
              form.demands
                .sort(
                  (a, b) =>
                    (DEMAND_ORDER[a.id] ?? 99) - (DEMAND_ORDER[b.id] ?? 99),
                )
                .map((item, index) => (
                  <PickerCheckbox
                    key={item.id}
                    name={`demandIds[${index}]`}
                    onChange={handleChangeDemands}
                    value={item.id}
                    checked={values.demandIds.includes(item.id)}
                    aria-label={item.demandName}
                  >
                    {item.demandName}
                  </PickerCheckbox>
                ))}
          </PickerCheckboxes>
          <Note>{t('picker.demand.description.selectFinancingSeries')}</Note>
        </PickerContent>

        <PickerHeading>
          <FinancingSeriesLabel disabled={isDisabledFinancingSeries}>
            {t('picker.demand.field.financingSeries.label')}
          </FinancingSeriesLabel>
          <HeadingCheckbox
            name="checkAllFinancingSeries"
            onChange={handleChangeCheckAllFinancingSeries}
            checked={values.checkAllFinancingSeries}
            aria-label={t('picker.checkbox.toggleCheckAll')}
            disabled={isDisabledFinancingSeries}
            data-testid="checkbox-check-all-financing-series"
          >
            {t('picker.checkbox.toggleCheckAll')}
          </HeadingCheckbox>
        </PickerHeading>
        <PickerContent>
          <FinancingSeriesCheckboxes>
            {form?.financingSeries &&
              form.financingSeries.map((item, index) => (
                <FinancingSeriesCheckbox
                  key={item.seriesId}
                  name={`financingSeriesIds[${index}]`}
                  onChange={handleChangeFinancingSeries}
                  value={item.seriesId}
                  checked={values.financingSeriesIds.includes(item.seriesId)}
                  aria-label={item.name}
                  disabled={isDisabledFinancingSeries}
                >
                  {item.name}
                </FinancingSeriesCheckbox>
              ))}
          </FinancingSeriesCheckboxes>
        </PickerContent>

        <PickerFooter>
          <ButtonS buttonTheme="default" type="submit">
            {t('common:button.save')}
          </ButtonS>
        </PickerFooter>
      </Form>
    </Picker>
  );
};

export default DemandPicker;
