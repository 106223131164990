import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Mission } from 'proto/v1/apimodel/apimodel';
import { DetailSubTitle, DetailCompanyField } from 'components/molecules';

type FundFieldProps = {
  fund: Mission['fund'];
};

const FundField: FC<FundFieldProps> = ({ fund }) => {
  const { t } = useTranslation('missionDetail');

  return (
    <DetailCompanyField>
      <DetailSubTitle>{t('field.fund.label')}</DetailSubTitle>
      <p>{fund ? fund.displayText : '-'}</p>
    </DetailCompanyField>
  );
};

export default FundField;
