import React, { useEffect, useCallback, ChangeEvent } from 'react';
import styled from 'styled-components/macro';
import { useTranslation } from 'react-i18next';
import { useFormik } from 'formik';
import { string, object, number } from 'yup';
import { Occupation } from 'proto/v1/apimodel/apimodel';
import { MissionSettingMaxLength } from 'constants/form';
import {
  MissionJobOfferFormValue,
  MissionJobOfferFormModalValues,
} from 'types/form';
import { Selectbox, ButtonM, Textarea } from 'components/atoms';
import {
  FormItem,
  Modal,
  ModalContent,
  ModalFooter,
  FormItemLabel,
} from 'components/molecules';

const StyledModalContent = styled(ModalContent)`
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  height: 366px;
  text-align: left;
`;

const OccupationsSelectBox = styled(Selectbox)`
  width: 100%;
`;

const JobDescriptionTextarea = styled(Textarea)`
  width: 100%;
  height: 165px;
  margin-bottom: 8px;

  textarea {
    width: 672px;
    resize: none;
  }
`;

type Props = {
  show: boolean;
  onClose: () => void;
  onSave: (
    values: MissionJobOfferFormModalValues,
    targetIndex: number | undefined,
  ) => void;
  index: number | undefined;
  missionJobOffer?: MissionJobOfferFormValue;
  occupations: Occupation[];
};

const MissionJobOfferFormModal: React.VFC<Props> = ({
  show,
  missionJobOffer,
  index,
  onClose,
  onSave,
  occupations,
}) => {
  const { t } = useTranslation(['missionSetting', 'common']);

  const {
    values,
    errors,
    touched,
    dirty,
    resetForm,
    handleBlur,
    handleChange,
    setFieldValue,
    isValid,
  } = useFormik<MissionJobOfferFormModalValues>({
    initialValues: {
      occupationId: missionJobOffer?.occupationId
        ? missionJobOffer.occupationId
        : 0,
      jobDescription: missionJobOffer?.jobDescription
        ? missionJobOffer.jobDescription
        : '',
    },
    onSubmit: () => {},
    validationSchema: object({
      occupationId: number().positive(t('validation.noSelect')),
      jobDescription: string(),
    }),
  });

  const isDisabledSubmitButton = !isValid || !dirty;

  const handleChangeOccupationId = useCallback(
    (event: ChangeEvent<HTMLSelectElement>) => {
      const { value } = event.target;
      setFieldValue('occupationId', parseInt(value, 10), true);
    },
    [setFieldValue],
  );

  // Reset states when this modal opened.
  useEffect(() => {
    if (show) resetForm();
  }, [resetForm, show]);

  return (
    <Modal show={show} closeModal={onClose} height="535px">
      <StyledModalContent data-testid="modal-mission-job-offer-form">
        <FormItem
          error={touched.occupationId ? errors.occupationId : undefined}
          style={{ marginBottom: '36px', width: '100%' }}
        >
          <FormItemLabel
            label={t('modal.missionJobOfferForm.field.occupation.label')}
            required
          />
          <OccupationsSelectBox
            name="occupationId"
            idName="id"
            textName="name"
            value={values.occupationId}
            options={occupations}
            onChange={handleChangeOccupationId}
            onBlur={handleBlur}
            aria-label={t('modal.missionJobOfferForm.field.occupation.label')}
            noSelectText={t(
              'modal.missionJobOfferForm.field.occupation.placeholder',
            )}
            noSelectValue={0}
          />
        </FormItem>

        <FormItemLabel
          label={t('modal.missionJobOfferForm.field.jobDescription.label')}
          note={t('modal.missionJobOfferForm.field.jobDescription.note', {
            maxLength:
              MissionSettingMaxLength.MISSION_JOB_OFFER_JOB_DESCRIPTION,
          })}
        />
        <JobDescriptionTextarea
          maxLength={MissionSettingMaxLength.MISSION_JOB_OFFER_JOB_DESCRIPTION}
          name="jobDescription"
          value={values.jobDescription}
          placeholder={t(
            'modal.missionJobOfferForm.field.jobDescription.placeholder',
          )}
          onChange={handleChange}
          onBlur={handleBlur}
          aria-label={t('modal.missionJobOfferForm.field.jobDescription.label')}
        />
      </StyledModalContent>

      <ModalFooter>
        <ButtonM type="button" buttonTheme="cancel" onClick={onClose}>
          {t('common:button.cancel')}
        </ButtonM>
        <ButtonM
          type="button"
          buttonTheme="default"
          onClick={() => {
            onSave(values, index);
          }}
          disabled={isDisabledSubmitButton}
        >
          {t('common:button.resister')}
        </ButtonM>
      </ModalFooter>
    </Modal>
  );
};

export default MissionJobOfferFormModal;
