import styled from 'styled-components/macro';

const TwoColumnContent = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 20px;

  .content-inner {
    width: 100%;

    &:first-child {
      margin-right: 56px;
    }
  }
`;

export default TwoColumnContent;
