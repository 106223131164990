import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components/macro';
import theme from 'styles/theme';
import typography from 'styles/typography';
import { ScoutingHumanResource, ScoutingHumanResourceForm } from 'types/scout';
import { SelectionStep } from 'proto/v1/apimodel/apimodel';
import { Spinner } from 'visits-style';
import {
  Table,
  TableHead,
  TableCell,
  TableBody,
  TableRow,
  ButtonM,
} from 'components/atoms';
import { SELECTION_STEP_ID } from 'constants/models/matter';
import FollowingHumanResourcesTableRow from './FollowingHumanResourcesTableRow';

const StyledTableBody = styled(TableBody)`
  height: calc(100% - 74px);
`;

const StyledTableRow = styled(TableRow)`
  padding: 0 12px;
`;

const TableCellTh = styled(TableCell).attrs({ as: 'th' })`
  justify-content: flex-start;
  min-height: 0;
  padding: 16px 20px;
`;

const LoadingInfoRow = styled(TableRow)`
  tbody tr&&& {
    display: block;
    min-height: 0;
    border-top: none;
  }
`;

const LoadingInfo = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 10px;
  margin-bottom: 20px;
`;

const LoadingMessage = styled.p`
  ${typography.textNote}
  margin-bottom: 8px;
  color: ${theme.textSecondary};
`;

const SpinnerContainer = styled.div`
  margin-top: 10px;
`;

const NotfoundTableRow = styled(TableRow)`
  &:hover {
    background-color: transparent;
    cursor: default;
  }
`;

const HumanResourcesNotfound = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  transform: translateY(-50%) translateX(-50%);
  color: ${theme.textSecondary};
  line-height: 1.5;
  text-align: center;
`;

const COLUMN_WIDTH = ['184px', '182px', '258px', '122px', '196px', '172px'];

type Props = {
  scoutingHumanResources: ScoutingHumanResource[] | null;
  refTableBody?:
    | React.RefObject<HTMLTableSectionElement>
    | ((instance: HTMLTableSectionElement | null) => void);
  isFetchingNext: boolean;
  errorOnFetchNext: { message: string } | null;
  onScrollTable?: () => void;
  onClickRetry: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  formItems: ScoutingHumanResourceForm;
  selectionStepState: {
    [matterId: string]: SelectionStep['id'];
  };
  onChangeSelectionStep: (
    scoutingHumanResource: ScoutingHumanResource | undefined,
    selectionStepId: SelectionStep['id'],
  ) => Promise<void>;
  onOpenReportJoiningDateModal: (
    scoutingHumanResource: ScoutingHumanResource | undefined,
  ) => void;
  onClickShowProfile: (
    scoutingHumanResource: ScoutingHumanResource | undefined,
  ) => Promise<void>;
};

const FollowingHumanResourcesTable: FC<Props> = props => {
  const {
    scoutingHumanResources,
    refTableBody,
    isFetchingNext,
    errorOnFetchNext,
    onScrollTable,
    onClickRetry,
    formItems,
    selectionStepState,
    onChangeSelectionStep,
    onOpenReportJoiningDateModal,
    onClickShowProfile,
  } = props;

  const { t } = useTranslation(['followingHumanResources', 'common']);

  return (
    <Table>
      <TableHead data-testid="following-human-resource-table">
        <StyledTableRow>
          <TableCellTh cellWidth={COLUMN_WIDTH[0]}>
            {t('table.employmentType', { lng: 'ja' })}
          </TableCellTh>
          <TableCellTh cellWidth={COLUMN_WIDTH[1]}>
            {t('table.name', { lng: 'ja' })}
          </TableCellTh>
          <TableCellTh cellWidth={COLUMN_WIDTH[2]}>
            {t('table.profile', { lng: 'ja' })}
          </TableCellTh>
          <TableCellTh cellWidth={COLUMN_WIDTH[3]}>
            {t('table.joiningDate', { lng: 'ja' })}
          </TableCellTh>
          <TableCellTh cellWidth={COLUMN_WIDTH[4]}>
            {t('table.selectionStep', { lng: 'ja' })}
          </TableCellTh>
          <TableCellTh cellWidth={COLUMN_WIDTH[5]}>
            {t('table.status', { lng: 'ja' })}
          </TableCellTh>
        </StyledTableRow>
      </TableHead>
      <StyledTableBody
        style={{ position: 'relative' }}
        ref={refTableBody}
        onScroll={onScrollTable}
      >
        {scoutingHumanResources &&
          scoutingHumanResources.map(scoutingHumanResource => (
            <FollowingHumanResourcesTableRow
              scoutingHumanResource={scoutingHumanResource}
              columnWidth={COLUMN_WIDTH}
              key={scoutingHumanResource.scoutId}
              selectionSteps={formItems.selectionSteps}
              selectedSelectionStepId={
                selectionStepState[scoutingHumanResource.scoutId]
                  ? selectionStepState[scoutingHumanResource.scoutId]
                  : scoutingHumanResource?.selectionStep?.id ??
                    SELECTION_STEP_ID.NO_SELECTED
              }
              onChangeSelectionStep={onChangeSelectionStep}
              onOpenReportJoiningDateModal={onOpenReportJoiningDateModal}
              onClickShowProfile={onClickShowProfile}
            />
          ))}
        {scoutingHumanResources && scoutingHumanResources.length === 0 && (
          <NotfoundTableRow>
            <TableCell>
              <HumanResourcesNotfound>
                {t('description.notFound', { lng: 'ja' })}
              </HumanResourcesNotfound>
            </TableCell>
          </NotfoundTableRow>
        )}
        <LoadingInfoRow>
          {(isFetchingNext || errorOnFetchNext) && (
            <TableCell colSpan={6} style={{ width: '100%', display: 'block' }}>
              <LoadingInfo>
                {isFetchingNext && (
                  <SpinnerContainer>
                    <Spinner size="40px" />
                  </SpinnerContainer>
                )}
                {errorOnFetchNext && (
                  <>
                    <LoadingMessage>
                      <p>{errorOnFetchNext.message.replace('\n', '')}</p>
                    </LoadingMessage>
                    <ButtonM buttonTheme="default" onClick={onClickRetry}>
                      {t('common:button.retry')}
                    </ButtonM>
                  </>
                )}
              </LoadingInfo>
            </TableCell>
          )}
        </LoadingInfoRow>
      </StyledTableBody>
    </Table>
  );
};

export default FollowingHumanResourcesTable;
