import React, {
  FC,
  useCallback,
  useEffect,
  useRef,
  useMemo,
  useState,
  KeyboardEvent,
} from 'react';
import { useDispatch } from 'react-redux';
import { UiActions } from 'modules/ui';
import styled, { css } from 'styled-components/macro';
import theme from 'styles/theme';
import typography from 'styles/typography';
import { isMacOS } from 'utils/userAgent';
import {
  MessageAttachment,
  MessageSend,
  Template,
  TemplateCompany,
  TemplateMember,
  TemplateSchool,
} from 'assets/svg';
import { useTranslation } from 'react-i18next';
import { FormikContextType } from 'formik';
import { ChatRoom, MessageTemplate } from 'proto/v1/apimodel/apimodel';
import apiMessageTemplate from 'external/api/messageTemplate';
import RequestError from 'classes/RequestError';
import useErrorDialog from 'hooks/useErrorDialog';
import { FlexContainer, TextareaAutoSize } from 'components/atoms';

const FILE_SIZE_LIMIT_MB = 25;

const MESSAGE_TEXT_AREA_MIN_ROWS = 4;
const MESSAGE_TEXT_AREA_MAX_ROWS = 15;

const KEY_DOWN_ENTER_SUB_KEY_DEFAULT = 'Ctrl';
const KEY_DOWN_ENTER_SUB_KEY_MAC = '⌘';

const StyledForm = styled.form`
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  background-color: ${theme.baseWhite};
`;

const StyledTextarea = styled(TextareaAutoSize)`
  min-height: 116px;
  padding: 16px 16px 0 16px;
  border: 1px solid ${theme.borderDefault};
  border-bottom: none;
  border-radius: 8px 8px 0 0;
  resize: none;
`;

const MessageButtons = styled.div<{ isDisabled: boolean }>`
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  border: 1px solid ${theme.borderDefault};
  border-top: none;
  border-radius: 0 0 8px 8px;
  ${({ isDisabled }) =>
    isDisabled &&
    css`
      background-color: ${theme.bgMain};
    `}
`;

const MessageButtonsLeft = styled.div`
  display: flex;
  padding-left: 12px;
`;

const MessageButtonsRight = styled.div`
  display: flex;
  padding-right: 4px;
`;

const buttonStyle = css`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 44px;
  height: 44px;
  padding: 0;
  border: none;
  outline: none;
  background-color: transparent;
  cursor: pointer;

  &:disabled {
    cursor: default;
  }
`;

const FileUploadButton = styled.button.attrs({ type: 'button' })`
  ${buttonStyle}

  input {
    display: none;
  }

  &:enabled:hover {
    & > svg {
      fill: ${theme.basePrimary};
    }
  }
`;

const TemplateButtonBase = styled.button.attrs({ type: 'button' })`
  ${buttonStyle}
`;

const TemplateButton = styled(TemplateButtonBase)`
  position: relative;

  &:disabled {
    svg {
      fill: ${theme.baseDisabled};
    }
  }
`;

const TemplateMemberButton = styled(TemplateButtonBase)`
  &:disabled {
    svg {
      & > path,
      rect {
        stroke: ${theme.baseDisabled};
      }
    }
  }
`;
const TemplateCompanyButton = styled(TemplateButtonBase)`
  &:disabled {
    & > svg > path {
      stroke: ${theme.baseDisabled};
    }
  }
`;

const TemplateSchoolButton = styled(TemplateButtonBase)`
  &:disabled {
    svg {
      path:not(:nth-child(5)) {
        stroke: ${theme.baseDisabled};
      }

      path:nth-child(5) {
        fill: ${theme.baseDisabled};
      }

      path:nth-child(7) {
        fill: ${theme.baseDisabled};
      }
    }
  }
`;

const SendButton = styled.button`
  ${buttonStyle}
`;

const SendButtonInner = styled.div`
  ${buttonStyle}
  width: 32px;
  height: 32px;
  border-radius: 8px;

  /* stylelint-disable-next-line selector-type-no-unknown */
  ${SendButton}:enabled & {
    background-color: ${theme.basePrimary};
  }
`;

const AttachmentTooltip = styled(FlexContainer).attrs({
  flexDirection: 'column',
})`
  ${typography.textNote}
  visibility: collapse;
  position: absolute;
  right: 32px;
  bottom: 40px;
  width: 166px;
  height: 125px;
  padding: 8px;
  border-radius: 8px;
  background-color: ${theme.baseWhite};
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.32);

  /* stylelint-disable-next-line selector-type-no-unknown */
  ${FileUploadButton}:enabled:hover & {
    visibility: visible;
  }
`;

const AttachmentTooltipLabel = styled.div`
  margin-bottom: 4px;
`;
const AttachmentTooltipAcceptFileFormats = styled.div`
  flex-grow: 1;
  width: 100%;
  color: ${theme.textSecondary};
  text-align: left;
`;
const AttachmentTooltipFileSize = styled.div`
  color: ${theme.textSecondary};
`;

const TemplateTooltip = styled.div`
  display: flex;
  visibility: collapse;
  position: absolute;
  bottom: 40px;
  left: auto;
  align-items: center;
  justify-content: center;
  width: 65px;
  height: 30px;
  border-radius: 8px;
  background-color: ${theme.baseWhite};
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.32);
  /* stylelint-disable-next-line selector-type-no-unknown */
  ${TemplateButtonBase}:enabled:hover & {
    visibility: visible;
  }
`;

const TemplateContainerTooltip = styled(TemplateTooltip)`
  width: 100px;
  /* stylelint-disable-next-line selector-type-no-unknown */
  ${TemplateButtonBase}:hover & {
    visibility: visible;
  }
`;

const TemplateTooltipLabel = styled.div``;

const TemplateContainer = styled.div`
  position: absolute;
  right: 14px;
  bottom: 100%;
  width: 226px;
  max-height: 165px;
  overflow-y: auto;
  background: ${theme.baseWhite};
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.17);
`;

const TemplateItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 40px;
  border-radius: 4px;

  &:hover {
    background: #f1f2f9;
  }
`;

const DescriptionSubmit = styled.p`
  ${typography.textNote}
  position: absolute;
  right: 0;
  bottom: -18px;
  color: ${theme.textSecondary};
`;

const keyDownEnterSubKey = isMacOS()
  ? KEY_DOWN_ENTER_SUB_KEY_MAC
  : KEY_DOWN_ENTER_SUB_KEY_DEFAULT;

const ACCEPT_FILE_FORMATS = [
  { accept: ['.jpg', '.jpeg'], label: 'JPEG' },
  { accept: ['.png'], label: 'PNG' },
  { accept: ['.pdf'], label: 'PDF' },
  { accept: ['.doc', '.docx'], label: 'Word' },
  { accept: ['.xls', '.xlsx'], label: 'Excel' },
  { accept: ['.ppt', '.pptx'], label: 'PowerPoint' },
];

type MessageFormProps = {
  formik: FormikContextType<{ message: string }>;
  selectedChatRoom: ChatRoom | null;
  isUncontactable: boolean;
  hasUncontactableError: boolean;
  uploadFile: (file: File, dataUri: string) => void;
  onFileSizeExceeded: () => void;
};

const MessageForm: FC<MessageFormProps> = props => {
  const {
    formik,
    selectedChatRoom,
    isUncontactable,
    hasUncontactableError,
    uploadFile,
    onFileSizeExceeded,
  } = props;

  const { t, i18n } = useTranslation(['chat', 'common']);

  const {
    values,
    handleChange,
    handleSubmit,
    isSubmitting,
    resetForm,
    submitForm,
    isValid,
    dirty,
    setFieldValue,
  } = formik;
  const [showMessageTemplate, setShowMessageTemplate] = useState<boolean>(
    false,
  );
  const [messageTemplates, setMessageTemplates] = useState<MessageTemplate[]>(
    [],
  );
  const messageTextareaRef = useRef<HTMLTextAreaElement>(null);

  const insertText = useCallback(
    (valueToInsert: string) => {
      const pos = messageTextareaRef.current?.selectionStart ?? 0;
      setFieldValue(
        'message',
        `${values.message.slice(0, pos)}${valueToInsert}${values.message.slice(
          pos,
        )}`,
      );
    },
    [setFieldValue, values.message],
  );

  const canSendMessageByKeyDown = useCallback(
    (e: KeyboardEvent<HTMLTextAreaElement>) => {
      if (!isValid) return false;
      if (e.key !== 'Enter') return false;
      if (isMacOS()) return e.metaKey; // command key
      return e.ctrlKey;
    },
    [isValid],
  );

  const handleTextareaKeyDown = useCallback(
    (e: KeyboardEvent<HTMLTextAreaElement>) => {
      if (!canSendMessageByKeyDown(e)) return;
      submitForm();
      e.preventDefault();
    },
    [canSendMessageByKeyDown, submitForm],
  );

  const fileInputRef = useRef<HTMLInputElement>(null);

  const handleChangeFile = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const file = event.target.files && event.target.files[0];
      if (file) {
        // use Megabytes unit size（1MB = 1000000Byte, not 1048576Byte)
        if (file.size / 1000000 < FILE_SIZE_LIMIT_MB) {
          const reader = new FileReader();
          reader.onload = () => {
            uploadFile(file, reader.result as string);
          };
          reader.readAsDataURL(file);
        } else {
          onFileSizeExceeded();
        }
      }
      event.target.value = '';
    },
    [onFileSizeExceeded, uploadFile],
  );

  const handleTemplateMember = useCallback(
    (chatRoom: ChatRoom | null) => {
      if (!chatRoom?.chatUser?.innovator) {
        return;
      }
      const { career, newGraduate, innovatorId } = chatRoom.chatUser.innovator;

      let msg = innovatorId;
      if (career && `${career.lastName}${career.firstName}` !== '') {
        msg = `${career.lastName} ${career.firstName}`;
      } else if (
        newGraduate &&
        `${newGraduate.lastName}${newGraduate.firstName}` !== ''
      ) {
        msg = `${newGraduate.lastName} ${newGraduate.firstName}`;
      }
      insertText(msg);
      messageTextareaRef.current?.focus();
    },
    [insertText],
  );

  const handleTemplateSchool = useCallback(
    (chatRoom: ChatRoom | null) => {
      if (!chatRoom?.chatUser?.innovator) {
        return;
      }
      const { career, newGraduate } = chatRoom.chatUser.innovator;

      let msg = '';
      if (career && career.academicBackground?.school?.name) {
        msg = career.academicBackground.school.name;
      } else if (newGraduate && newGraduate.academicBackground?.school?.name) {
        msg = newGraduate.academicBackground.school.name;
      }
      insertText(msg);
      messageTextareaRef.current?.focus();
    },

    [insertText],
  );

  const handleTemplateCompany = useCallback(
    (chatRoom: ChatRoom | null) => {
      if (chatRoom?.chatUser?.innovator?.career?.companyName) {
        insertText(chatRoom.chatUser.innovator.career.companyName);
      }
      messageTextareaRef.current?.focus();
    },
    [insertText],
  );

  const dispatch = useDispatch();
  const { handleRequestError } = useErrorDialog();

  const fetchMessageTemplates = useCallback(async () => {
    let data;
    dispatch(UiActions.setLoading(true));
    try {
      ({ data } = await apiMessageTemplate.getMessageTemplates());
    } catch (error: unknown) {
      dispatch(UiActions.setLoading(false));
      if (error instanceof RequestError) {
        handleRequestError(error);
      }
      return;
    }
    dispatch(UiActions.setLoading(false));
    setMessageTemplates(data.messageTemplates);
  }, [dispatch, handleRequestError]);

  const handleClickTemplateButton = useCallback(() => {
    setShowMessageTemplate(!showMessageTemplate);
  }, [showMessageTemplate]);

  const handleSelectMessageTemplate = useCallback(
    async (messageTemplate: MessageTemplate, chatRoom: ChatRoom | null) => {
      if (!chatRoom?.chatUser?.accountId) return;
      let data;
      dispatch(UiActions.setLoading(true));
      try {
        ({ data } = await apiMessageTemplate.useMessageTemplate({
          messageTemplateId: messageTemplate.id,
          accountId: chatRoom?.chatUser.accountId,
        }));
      } catch (error: unknown) {
        dispatch(UiActions.setLoading(false));
        if (error instanceof RequestError) {
          handleRequestError(error);
        }
        return;
      }
      dispatch(UiActions.setLoading(false));

      setFieldValue('message', data.message);
      messageTextareaRef.current?.focus();
      setShowMessageTemplate(false);
    },
    [dispatch, handleRequestError, setFieldValue],
  );

  const isDisabledMessageForm =
    !selectedChatRoom || isUncontactable || hasUncontactableError;

  const isDisabledMessageFormButton = isDisabledMessageForm || isSubmitting;

  useEffect(() => {
    fetchMessageTemplates();
    resetForm();
  }, [fetchMessageTemplates, resetForm]);

  return useMemo(
    () => (
      <StyledForm onSubmit={handleSubmit}>
        <StyledTextarea
          name="message"
          ref={messageTextareaRef}
          value={values.message}
          onChange={handleChange}
          placeholder={t('field.message.placeholder', {
            subKey: keyDownEnterSubKey,
          })}
          maxLength={1000}
          minRows={MESSAGE_TEXT_AREA_MIN_ROWS}
          maxRows={MESSAGE_TEXT_AREA_MAX_ROWS}
          onKeyDown={handleTextareaKeyDown}
          disabled={isDisabledMessageForm}
          data-testid="text-area-message"
        />
        <MessageButtons isDisabled={isDisabledMessageForm}>
          <MessageButtonsLeft>
            {selectedChatRoom?.chatUser?.innovator && (
              <TemplateMemberButton
                disabled={isDisabledMessageFormButton}
                onClick={() => handleTemplateMember(selectedChatRoom)}
                data-testid="template-member"
              >
                <TemplateMember />
                <TemplateTooltip data-testid="template-member-tooltip">
                  <TemplateTooltipLabel>
                    {t('templateMessage.member')}
                  </TemplateTooltipLabel>
                </TemplateTooltip>
              </TemplateMemberButton>
            )}
            {selectedChatRoom?.chatUser?.innovator && (
              <TemplateSchoolButton
                disabled={isDisabledMessageFormButton}
                onClick={() => handleTemplateSchool(selectedChatRoom)}
                data-testid="template-school"
              >
                <TemplateSchool />
                <TemplateTooltip data-testid="template-school-tooltip">
                  <TemplateTooltipLabel>
                    {t('templateMessage.school')}
                  </TemplateTooltipLabel>
                </TemplateTooltip>
              </TemplateSchoolButton>
            )}
            {selectedChatRoom?.chatUser?.innovator?.career && (
              <TemplateCompanyButton
                disabled={isDisabledMessageFormButton}
                onClick={() => handleTemplateCompany(selectedChatRoom)}
                data-testid="template-company"
              >
                <TemplateCompany />
                <TemplateTooltip>
                  <TemplateTooltipLabel data-testid="template-company-tooltip">
                    {t('templateMessage.company')}
                  </TemplateTooltipLabel>
                </TemplateTooltip>
              </TemplateCompanyButton>
            )}
          </MessageButtonsLeft>
          <MessageButtonsRight>
            <TemplateButton
              disabled={
                isDisabledMessageFormButton || messageTemplates.length === 0
              }
              onClick={handleClickTemplateButton}
              onBlur={() => {
                setShowMessageTemplate(false);
              }}
              data-testid="template"
            >
              <Template />
              <TemplateContainerTooltip>
                <TemplateTooltipLabel data-testid="template-tooltip">
                  {t('templateMessage.template')}
                </TemplateTooltipLabel>
              </TemplateContainerTooltip>
              {showMessageTemplate && messageTemplates.length > 0 && (
                <TemplateContainer>
                  {messageTemplates.map(messageTemplate => (
                    <TemplateItem
                      data-testid="message-template-item"
                      key={messageTemplate.id}
                      onMouseDown={() =>
                        handleSelectMessageTemplate(
                          messageTemplate,
                          selectedChatRoom,
                        )
                      }
                    >
                      {messageTemplate.name}
                    </TemplateItem>
                  ))}
                </TemplateContainer>
              )}
            </TemplateButton>
            <FileUploadButton
              disabled={isDisabledMessageFormButton}
              onClick={() => fileInputRef.current?.click()}
              data-testid="file-upload"
            >
              <MessageAttachment />
              <input
                type="file"
                onChange={handleChangeFile}
                multiple={false}
                accept={Array.prototype
                  .concat(...ACCEPT_FILE_FORMATS.map(fmt => fmt.accept))
                  .join(',')}
                ref={fileInputRef}
                data-testid="file-upload-input"
              />

              <AttachmentTooltip>
                <AttachmentTooltipLabel>
                  {t('attachment.formats')}
                </AttachmentTooltipLabel>
                <AttachmentTooltipAcceptFileFormats>
                  {ACCEPT_FILE_FORMATS.map(f => f.label).join(
                    i18n.language.startsWith('ja') ? '、' : ', ',
                  )}
                </AttachmentTooltipAcceptFileFormats>
                <AttachmentTooltipLabel>
                  {t('attachment.fileSize')}
                </AttachmentTooltipLabel>
                <AttachmentTooltipFileSize>{`${FILE_SIZE_LIMIT_MB}MB`}</AttachmentTooltipFileSize>
              </AttachmentTooltip>
            </FileUploadButton>
            <SendButton
              type="submit"
              disabled={!dirty || !isValid || isDisabledMessageFormButton}
              data-testid="submit-message"
            >
              <SendButtonInner>
                <MessageSend />
              </SendButtonInner>
            </SendButton>
          </MessageButtonsRight>
        </MessageButtons>
        {values.message && (
          <DescriptionSubmit>
            {t('description.submit', {
              subKey: keyDownEnterSubKey,
            })}
          </DescriptionSubmit>
        )}
      </StyledForm>
    ),
    [
      dirty,
      handleChange,
      handleChangeFile,
      handleClickTemplateButton,
      handleSelectMessageTemplate,
      handleSubmit,
      handleTemplateCompany,
      handleTemplateMember,
      handleTemplateSchool,
      handleTextareaKeyDown,
      i18n.language,
      isDisabledMessageForm,
      isDisabledMessageFormButton,
      isValid,
      messageTemplates,
      selectedChatRoom,
      showMessageTemplate,
      t,
      values.message,
    ],
  );
};

export default MessageForm;
