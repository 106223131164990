import React, { FC, ChangeEvent } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components/macro';
import typography from 'styles/typography';
import { isEqualProps } from 'utils/object';
import { Checkbox, ErrorMessage } from 'components/atoms';
import { MissionDemandFieldItem } from 'components/molecules';

const StyledCheckbox = styled(Checkbox)`
  &&& {
    margin-bottom: 0;
  }
`;

const Description = styled.div`
  ${typography.textDefault}
  width: 422px;
`;

const StyledErrorMessage = styled(ErrorMessage)`
  position: absolute;
  top: 82px;
  left: 0;
`;

type ServiceSuggestionFieldProps = {
  wantServiceSuggestion: boolean;
  error: string | undefined;
  onChangeWantServiceSuggestion: (e: ChangeEvent<HTMLInputElement>) => void;
};

const ServiceSuggestionField: FC<ServiceSuggestionFieldProps> = props => {
  const { wantServiceSuggestion, error, onChangeWantServiceSuggestion } = props;
  const { t } = useTranslation('missionSetting');

  return (
    <MissionDemandFieldItem style={{ position: 'relative' }}>
      <StyledCheckbox
        name="missionDemand.wantServiceSuggestion"
        checked={wantServiceSuggestion}
        onChange={onChangeWantServiceSuggestion}
        aria-label={t('field.missionDemand.checkbox.wantServiceSuggestion')}
      >
        {t('field.missionDemand.checkbox.wantServiceSuggestion')}
      </StyledCheckbox>

      {wantServiceSuggestion && (
        <Description>
          {t('field.missionDemand.serviceSuggestion.description')}
        </Description>
      )}

      {error && <StyledErrorMessage>{error}</StyledErrorMessage>}
    </MissionDemandFieldItem>
  );
};

export default React.memo(ServiceSuggestionField, (prevProps, nextProps) =>
  isEqualProps(prevProps, nextProps, ['wantServiceSuggestion', 'error']),
);
