import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { ButtonM } from 'components/atoms';
import { Modal, ModalContent, ModalFooter } from 'components/molecules';

type Props = {
  show: boolean;
  onClose: () => void;
};

const MessageTemplateDeletedModal: FC<Props> = ({ show, onClose }) => {
  const { t } = useTranslation(['messageTemplate', 'common']);

  return (
    <Modal show={show} closeModal={onClose}>
      <ModalContent data-testid="modal-message-template-deleted">
        {t('modal.messageTemplateDeleted.content')}
      </ModalContent>
      <ModalFooter>
        <ButtonM buttonTheme="default" onClick={onClose}>
          {t('common:button.close')}
        </ButtonM>
      </ModalFooter>
    </Modal>
  );
};

export default MessageTemplateDeletedModal;
