import { css } from 'styled-components';

/* stylelint-disable property-no-vendor-prefix */

export default css`
  ::-webkit-scrollbar {
    width: 6px;
    -webkit-appearance: none;
    background-color: #eee;
  }

  ::-webkit-scrollbar-thumb {
    border: none;
    border-radius: 5px;
    background-color: rgba(0, 0, 0, 0.5);
    box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
  }
`;
