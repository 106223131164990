import React, { FC, ChangeEvent } from 'react';
import { useTranslation } from 'react-i18next';
import { MissionMemberFormValue } from 'types/form';
import { isEqualProps } from 'utils/object';
import { MissionSettingMaxLength } from 'constants/form';
import { TextInput } from 'components/atoms';
import { MissionMembersFieldItem } from 'components/molecules';

type NameFieldProps = {
  index: number;
  value: MissionMemberFormValue['name'];
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
  onBlur: (e: any) => void;
};

const NameField: FC<NameFieldProps> = props => {
  const { index, value, onChange, onBlur } = props;

  const { t } = useTranslation('missionSetting');

  return (
    <MissionMembersFieldItem>
      <TextInput
        maxLength={MissionSettingMaxLength.SUPPORTER_MEMBER_NAME}
        name={`supporterMembers[${index}].name`}
        type="text"
        value={value}
        placeholder={t('field.supporterMembers.subField.name.placeholder', {
          maxLength: MissionSettingMaxLength.SUPPORTER_MEMBER_NAME,
        })}
        outline
        onChange={onChange}
        onBlur={onBlur}
        data-testid="member-name"
      />
    </MissionMembersFieldItem>
  );
};

export default React.memo(NameField, (prevProps, nextProps) =>
  isEqualProps(prevProps, nextProps, ['value']),
);
