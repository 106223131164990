import React, { FC, HTMLAttributes } from 'react';
import styled from 'styled-components/macro';
import theme from 'styles/theme';
import typography from 'styles/typography';
import { ValueOf } from 'types/common';
import {
  EMPLOYMENT_TYPE_LABEL,
  EMPLOYMENT_TYPE,
} from 'constants/models/matter';
import { getEmploymentType } from 'utils/models/matter';

const EMPLOYMENT_TYPE_TAG_COLOR = {
  [EMPLOYMENT_TYPE.NEW_GRADUATE]: '#17B3DA',
  [EMPLOYMENT_TYPE.CAREER]: '#5D82E1',
  [EMPLOYMENT_TYPE.OUT_SOURCING]: '#E9900B',
  [EMPLOYMENT_TYPE.ADVISOR]: '#F55660',
};

const Tag = styled.div<{
  isPublished: boolean;
  type: ValueOf<typeof EMPLOYMENT_TYPE>;
}>`
  ${typography.labelBold}
  box-sizing: border-box;
  padding: 2px 10px 1px 10px;
  border: 1px solid ${theme.borderDefault};
  border-radius: 5px;
  background-color: ${theme.baseWhite};
  color: ${({ type, isPublished }) =>
    isPublished ? EMPLOYMENT_TYPE_TAG_COLOR[type] : theme.textSecondary};
  line-height: 1.5;
`;

type Props = {
  employmentTypeId: number | undefined;
  isPublished: boolean;
  isNewGraduate: boolean;
} & HTMLAttributes<HTMLDivElement>;

const EmploymentTypeTag: FC<Props> = ({
  employmentTypeId,
  isPublished,
  isNewGraduate,
  ...rest
}) => {
  const employmentType = getEmploymentType(employmentTypeId, isNewGraduate);

  if (!employmentType) return null;

  return (
    <Tag isPublished={isPublished} type={employmentType} {...rest}>
      {EMPLOYMENT_TYPE_LABEL[employmentType]}
    </Tag>
  );
};

export default EmploymentTypeTag;
