import React, { FC } from 'react';
import styled from 'styled-components/macro';
import { useTranslation } from 'react-i18next';
import { WrapText, LinkText } from 'components/atoms';
import { Images } from 'proto/v1/apimodel/apimodel';
import typography from 'styles/typography';
import { noImageSquare } from 'assets/png';
import { imageSelector } from 'utils/imageSelector';
import { DetailSubTitle } from 'components/molecules';
import theme from 'styles/theme';

const Members = styled.div`
  width: 100%;
`;

const Member = styled.div`
  margin-bottom: 32px;
  overflow: hidden;

  &:last-child {
    margin-bottom: 0;
  }
`;

const MemberIconName = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 16px;
`;

const MemberName = styled(DetailSubTitle)`
  width: 188px;
  color: ${theme.textPrimary};
`;

const MemberCareer = styled(WrapText)`
  ${typography.textSub}
  overflow: auto;
`;

const MemberIconImage = styled.img`
  width: 64px;
  min-width: 64px;
  height: 64px;
  min-height: 64px;
  margin-right: 16px;
`;

type MissionMembersFieldProps = {
  members: {
    name: string;
    career: string;
    iconImage: Images | undefined;
  }[];
};

const MissionMembersField: FC<MissionMembersFieldProps> = ({ members }) => {
  const { t } = useTranslation('missionDetail');
  return (
    <>
      <DetailSubTitle>{t('field.missionMembers.label')}</DetailSubTitle>
      <Members data-testid="members">
        {members.map((member, index) => (
          <Member key={`member-${index.toString()}`} data-testid="member">
            <MemberIconName>
              <MemberIconImage
                src={imageSelector(member.iconImage, 'small') || noImageSquare}
                alt={member.name}
                data-testid="member-image"
              />
              <MemberName>
                <WrapText>{member.name}</WrapText>
              </MemberName>
            </MemberIconName>
            <MemberCareer>
              <LinkText color={'secondary'}>{member.career}</LinkText>
            </MemberCareer>
          </Member>
        ))}
      </Members>
    </>
  );
};

export default MissionMembersField;
