import React, { FC, useRef, useEffect, useState } from 'react';
import styled, { css } from 'styled-components/macro';
import { useTranslation } from 'react-i18next';
import { LinkText, WrapText } from 'components/atoms';
import { Mission, MissionDemand } from 'proto/v1/apimodel/apimodel';
import theme from 'styles/theme';
import typography from 'styles/typography';
import {
  Collaboration,
  Financing,
  Information,
  SearchBusiness,
  Secondment,
  Office,
} from 'assets/svg';
import {
  isSelectedDemandInvestFinancingSeries,
  isSelectedDemandFundraisingFinancingSeries,
} from 'utils/models/mission';
import { FundIds } from 'constants/models/mission';
import { DetailContentsLeftItem } from 'components/molecules';

const Wrapper = styled(DetailContentsLeftItem)`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
`;

const DemandName = styled.p`
  ${typography.headlineDefault};
  margin-bottom: 8px;
  color: ${theme.textPrimary};
  font-weight: normal;
`;

const DemandNote = styled.p`
  ${typography.textSub}
  margin-bottom: 8px;
  color: ${theme.textSecondary};
`;

const DemandDescription = styled(WrapText)`
  ${typography.textDefault};
  color: ${theme.textPrimary};
`;

const DemandItem = styled.div<{ height: number | undefined }>`
  width: 226px;
  min-height: ${({ height = 212 }) => `${height}px`};
  margin-bottom: 32px;
  padding: 24px 16px;
  border-width: 1px;
  border-style: solid;
  border-radius: 8px;
  border-color: ${theme.borderMissionDemand};
`;

const DemandServiceInformationIssues = styled(DemandDescription)`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
`;

const DemandServiceInformationIssue = styled(DemandDescription)`
  &:not(:last-child) {
    margin-bottom: 32px;
  }
`;

const DemandServiceSuggestionDescription = styled(DemandDescription)`
  margin-bottom: 32px;
`;

const demandIconStyle = css`
  display: block;
  width: 22px;
  height: 22px;
  margin-bottom: 5px;
  fill: #333;
`;

const CollaborationIcon = styled(Collaboration)`
  ${demandIconStyle}
`;

const FinancingIcon = styled(Financing)`
  ${demandIconStyle}
`;

const ServiceInformationIcon = styled(Information)`
  ${demandIconStyle}
`;

const SearchBusinessIcon = styled(SearchBusiness)`
  ${demandIconStyle}
`;

const SecondmentIcon = styled(Secondment)`
  ${demandIconStyle}
`;

const FurnishedOfficeRentalIcon = styled(Office)`
  ${demandIconStyle}
`;

const formatDemandInvestFinancingSeries = (
  missionDemand: Mission['missionDemand'],
) => {
  if (!missionDemand || !missionDemand.invest?.financingSeries) return '';
  return missionDemand.invest.financingSeries.map(v => v.name).join(' / ');
};

type DemandDetailContentProps = {
  missionDemand?: MissionDemand;
  isTranslated?: boolean;
};

const DemandDetailContent: FC<DemandDetailContentProps> = props => {
  const { missionDemand, isTranslated } = props;
  const { t } = useTranslation('missionDetail');

  const collaborationRefNoInput = useRef<HTMLDivElement>(null);
  const collaborationRefWant = useRef<HTMLDivElement>(null);
  const collaborationRefGive = useRef<HTMLDivElement>(null);
  const fundraisingRef = useRef<HTMLDivElement>(null);
  const investRef = useRef<HTMLDivElement>(null);
  const serviceSuggestionRef = useRef<HTMLDivElement>(null);
  const serviceInformationRef = useRef<HTMLDivElement>(null);
  const transferOfBusinessRef = useRef<HTMLDivElement>(null);
  const transferredOfBusinessRef = useRef<HTMLDivElement>(null);
  const acceptSecondmentRef = useRef<HTMLDivElement>(null);
  const sendEmployeeRef = useRef<HTMLDivElement>(null);
  const furnishedOfficeRentalAvailabilityRef = useRef<HTMLDivElement>(null);
  const furnishedOfficeRentalRequestRef = useRef<HTMLDivElement>(null);
  const [demandItemHeight, setDemandItemHeight] = useState<number | undefined>(
    undefined,
  );

  // Ref: VI-2284 CSS Gridレイアウトを試してみたが、IEで表示が崩れるためJSで調整することにした
  useEffect(() => {
    const demandItemRefs = [
      collaborationRefNoInput,
      collaborationRefWant,
      collaborationRefGive,
      fundraisingRef,
      investRef,
      serviceSuggestionRef,
      serviceInformationRef,
      transferOfBusinessRef,
      transferredOfBusinessRef,
      acceptSecondmentRef,
      sendEmployeeRef,
      furnishedOfficeRentalAvailabilityRef,
      furnishedOfficeRentalRequestRef,
    ];
    if (!isTranslated) {
      setDemandItemHeight(undefined);
      return;
    }
    let maxHeight = 0;
    demandItemRefs.forEach(ref => {
      if (ref.current && ref.current.clientHeight > maxHeight) {
        maxHeight = ref.current.clientHeight;
      }
    });
    setDemandItemHeight(maxHeight);
  }, [missionDemand, isTranslated]);

  return (
    <Wrapper data-testid="demand-detail">
      {missionDemand?.collaboration && (
        <>
          {/* 1件のみ登録できるように変更になった */}
          {!missionDemand.collaboration.assets[0]?.want &&
            !missionDemand.collaboration.assets[0]?.give && (
              <DemandItem
                ref={collaborationRefNoInput}
                height={demandItemHeight}
              >
                <CollaborationIcon />
                <DemandName>
                  {t('field.missionDemand.detail.collaboration.name')}
                </DemandName>
              </DemandItem>
            )}
          {missionDemand.collaboration.assets[0]?.want && (
            <DemandItem ref={collaborationRefWant} height={demandItemHeight}>
              <CollaborationIcon />
              <DemandName>
                {t('field.missionDemand.detail.collaboration.name')}
              </DemandName>
              <DemandNote>
                {t('field.missionDemand.detail.collaboration.want.note')}
              </DemandNote>
              <DemandDescription>
                <LinkText color={'secondary'}>
                  {missionDemand.collaboration.assets[0]?.want}
                </LinkText>
              </DemandDescription>
            </DemandItem>
          )}
          {missionDemand.collaboration.assets[0]?.give && (
            <DemandItem ref={collaborationRefGive} height={demandItemHeight}>
              <CollaborationIcon />
              <DemandName>
                {t('field.missionDemand.detail.collaboration.name')}
              </DemandName>
              <DemandNote>
                {t('field.missionDemand.detail.collaboration.give.note')}
              </DemandNote>
              <DemandDescription>
                <LinkText color={'secondary'}>
                  {missionDemand.collaboration.assets[0]?.give}
                </LinkText>
              </DemandDescription>
            </DemandItem>
          )}
        </>
      )}

      {isSelectedDemandFundraisingFinancingSeries(missionDemand) && (
        <DemandItem
          ref={fundraisingRef}
          height={demandItemHeight}
          data-testid="demand-fundraising"
        >
          <FinancingIcon />
          <DemandName>
            {t('field.missionDemand.detail.fundraising.name')}
          </DemandName>
          <DemandNote>
            {t('field.missionDemand.detail.fundraising.note')}
          </DemandNote>
          <DemandDescription>
            {t('field.missionDemand.detail.fundraising.description', {
              value: missionDemand?.fundraising?.financingSeries?.name,
            })}
          </DemandDescription>
        </DemandItem>
      )}
      {isSelectedDemandInvestFinancingSeries(missionDemand) && (
        <DemandItem
          ref={investRef}
          height={demandItemHeight}
          data-testid="demand-invest"
        >
          <FinancingIcon />
          <DemandName>{t('field.missionDemand.detail.invest.name')}</DemandName>
          <DemandNote>{t('field.missionDemand.detail.invest.note')}</DemandNote>
          <DemandDescription>
            {t('field.missionDemand.detail.invest.description', {
              value: formatDemandInvestFinancingSeries(missionDemand),
            })}
          </DemandDescription>
        </DemandItem>
      )}

      {missionDemand?.serviceSuggestion && (
        <DemandItem ref={serviceSuggestionRef} height={demandItemHeight}>
          <ServiceInformationIcon />
          <DemandName>
            {t('field.missionDemand.detail.serviceSuggestion.name')}
          </DemandName>
          <DemandNote>
            {t('field.missionDemand.detail.serviceSuggestion.note')}
          </DemandNote>
          <DemandServiceSuggestionDescription>
            {t('field.missionDemand.detail.serviceSuggestion.description')}
          </DemandServiceSuggestionDescription>
        </DemandItem>
      )}
      {missionDemand?.serviceInformation && (
        <DemandItem ref={serviceInformationRef} height={demandItemHeight}>
          <ServiceInformationIcon />
          <DemandName>
            {t('field.missionDemand.detail.serviceInformation.name')}
          </DemandName>
          {missionDemand.serviceInformation.issues[0] && (
            <>
              <DemandNote>
                {t('field.missionDemand.detail.serviceInformation.note')}
              </DemandNote>
              <DemandServiceInformationIssues>
                {/* 1件のみ登録できるように変更になった */}
                <>
                  <DemandServiceInformationIssue data-testid="demand-service-information-issue">
                    <LinkText color={'secondary'}>
                      {missionDemand.serviceInformation.issues[0]}
                    </LinkText>
                  </DemandServiceInformationIssue>
                </>
              </DemandServiceInformationIssues>
            </>
          )}
        </DemandItem>
      )}

      {missionDemand?.transferOfBusiness && (
        <DemandItem ref={transferOfBusinessRef} height={demandItemHeight}>
          <SearchBusinessIcon />
          <DemandName>
            {t('field.missionDemand.detail.transferOfBusiness.name')}
          </DemandName>
          <DemandNote>
            {t('field.missionDemand.detail.transferOfBusiness.note')}
          </DemandNote>
          <DemandDescription>
            {t('field.missionDemand.detail.transferOfBusiness.description')}
          </DemandDescription>
        </DemandItem>
      )}
      {missionDemand?.transferredOfBusiness && (
        <DemandItem
          ref={transferredOfBusinessRef}
          height={demandItemHeight}
          data-testid="demand-transferred-of-business"
        >
          <SearchBusinessIcon />
          <DemandName>
            {t('field.missionDemand.detail.transferredOfBusiness.name')}
          </DemandName>
          {(missionDemand.transferredOfBusiness.fund?.fundId ?? FundIds.NA) !==
            FundIds.NA && (
            <>
              <DemandNote>
                {t('field.missionDemand.detail.transferredOfBusiness.note')}
              </DemandNote>
              <DemandDescription>
                {t(
                  'field.missionDemand.detail.transferredOfBusiness.description',
                  {
                    value:
                      missionDemand?.transferredOfBusiness?.fund?.displayText,
                  },
                )}
              </DemandDescription>
            </>
          )}
        </DemandItem>
      )}

      {missionDemand?.acceptSecondment && (
        <DemandItem
          ref={acceptSecondmentRef}
          height={demandItemHeight}
          data-testid="demand-accept-secondment"
        >
          <SecondmentIcon />
          <DemandName>
            {t('field.missionDemand.detail.acceptSecondment.name')}
          </DemandName>
          {missionDemand?.acceptSecondment?.work && (
            <>
              <DemandNote>
                {t('field.missionDemand.detail.acceptSecondment.note')}
              </DemandNote>
              <DemandDescription>
                <LinkText color={'secondary'}>
                  {missionDemand?.acceptSecondment?.work}
                </LinkText>
              </DemandDescription>
            </>
          )}
        </DemandItem>
      )}
      {missionDemand?.sendEmployee && (
        <DemandItem
          ref={sendEmployeeRef}
          height={demandItemHeight}
          data-testid="demand-send-employee"
        >
          <SecondmentIcon />
          <DemandName>
            {t('field.missionDemand.detail.sendEmployee.name')}
          </DemandName>
          {missionDemand?.sendEmployee?.work && (
            <>
              <DemandNote>
                {t('field.missionDemand.detail.sendEmployee.note')}
              </DemandNote>
              <DemandDescription>
                <LinkText color={'secondary'}>
                  {missionDemand?.sendEmployee?.work}
                </LinkText>
              </DemandDescription>
            </>
          )}
        </DemandItem>
      )}
      {missionDemand?.furnishedOfficeRentalAvailability && (
        <DemandItem
          ref={furnishedOfficeRentalAvailabilityRef}
          height={demandItemHeight}
        >
          <FurnishedOfficeRentalIcon />
          <DemandName style={{ fontSize: '14px' }}>
            {t(
              'field.missionDemand.detail.furnishedOfficeRentalAvailability.name',
            )}
          </DemandName>
          <DemandNote>
            {t(
              'field.missionDemand.detail.furnishedOfficeRentalAvailability.note',
            )}
          </DemandNote>
          <DemandDescription>
            {t(
              'field.missionDemand.detail.furnishedOfficeRentalAvailability.description',
            )}
          </DemandDescription>
        </DemandItem>
      )}
      {missionDemand?.furnishedOfficeRentalRequest && (
        <DemandItem
          ref={furnishedOfficeRentalRequestRef}
          height={demandItemHeight}
        >
          <FurnishedOfficeRentalIcon />
          <DemandName style={{ fontSize: '14px' }}>
            {t('field.missionDemand.detail.furnishedOfficeRentalRequest.name')}
          </DemandName>
          <DemandNote>
            {t('field.missionDemand.detail.furnishedOfficeRentalRequest.note')}
          </DemandNote>
          <DemandDescription>
            {t(
              'field.missionDemand.detail.furnishedOfficeRentalRequest.description',
            )}
          </DemandDescription>
        </DemandItem>
      )}
    </Wrapper>
  );
};

export default DemandDetailContent;
