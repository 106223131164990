import { MissionForm, MissionFormValues } from 'types/mission';
import { SupporterForm, SupporterFormValues } from 'types/supporter';
import { Mission, Supporter } from 'proto/v1/apimodel/apimodel';
import {
  isEmptyOfficeImage,
  isEmptyMissionMember,
  isEmptyMissionProduct,
} from 'utils/models/mission';
import {
  isEmptySupporterMember,
  isEmptyGalleryImage,
} from 'utils/models/supporter';
import { FinancingSeriesIds } from 'constants/models/mission';

export const convertCountryFormValue = (
  value: MissionFormValues['countryId'] | SupporterFormValues['countryId'],
  formCountries: MissionForm['countries'] | SupporterForm['countries'],
): Mission['country'] | Supporter['country'] =>
  formCountries.find(country => country.countryId === value);

export const convertJapanPrefectureFormValue = (
  value:
    | MissionFormValues['japanPrefectureId']
    | SupporterFormValues['japanPrefectureId'],
  formJapanPrefectures:
    | MissionForm['japanPrefectures']
    | SupporterForm['japanPrefectures'],
): Mission['japanPrefecture'] | Supporter['japanPrefecture'] =>
  formJapanPrefectures.find(
    japanPrefecture => japanPrefecture.japanPrefectureId === value,
  );

export const convertMemberRangeFormValue = (
  value:
    | MissionFormValues['memberRangeId']
    | SupporterFormValues['memberRangeId'],
  formMemberRanges: MissionForm['memberRanges'] | SupporterForm['memberRanges'],
): Mission['memberRange'] | Supporter['memberRange'] =>
  formMemberRanges.find(memberRange => memberRange.memberRangeId === value);

export const convertFundFormValue = (
  value: MissionFormValues['fundId'],
  formFunds: MissionForm['funds'],
): Mission['fund'] => formFunds.find(fund => fund.fundId === value);

export const convertMissionCategoriesFormValue = (
  value: MissionFormValues['missionCategories'],
  formMissionCategories: MissionForm['missionCategories'],
): Mission['missionCategories'] =>
  formMissionCategories.filter(formMissionCategory =>
    value.includes(formMissionCategory.categoryId),
  );

export const convertMissionDemandFormValue = (
  value: MissionFormValues['missionDemand'],
  formFinancingSeries: MissionForm['financingSeries'],
  formFunds: MissionForm['funds'],
): Mission['missionDemand'] => {
  return {
    collaboration: value.wantCollaboration ? value.collaboration : undefined,
    invest: value.wantInvest
      ? {
          financingSeries: formFinancingSeries.filter(
            formFinancingSeriesItem =>
              (formFinancingSeriesItem.seriesId === FinancingSeriesIds.SEED &&
                value.wantInvestSeed) ||
              (formFinancingSeriesItem.seriesId === FinancingSeriesIds.EARLY &&
                value.wantInvestEarly) ||
              (formFinancingSeriesItem.seriesId === FinancingSeriesIds.MIDDLE &&
                value.wantInvestMiddle) ||
              (formFinancingSeriesItem.seriesId === FinancingSeriesIds.LATER &&
                value.wantInvestLater),
          ),
        }
      : undefined,
    fundraising: value.wantFundraising
      ? {
          financingSeries: formFinancingSeries.find(
            formFinancingSeriesItem =>
              formFinancingSeriesItem.seriesId ===
              value.fundraising?.financingSeriesId,
          ),
        }
      : undefined,
    serviceInformation: value.wantServiceInformation
      ? value.serviceInformation
      : undefined,
    serviceSuggestion: value.wantServiceSuggestion ? {} : undefined,
    transferOfBusiness: value.wantTransferOfBusiness ? {} : undefined,
    transferredOfBusiness: value.wantTransferredOfBusiness
      ? {
          fund: formFunds.find(
            formFund => value.transferredOfBusiness?.fundId === formFund.fundId,
          ),
        }
      : undefined,
    acceptSecondment: value.wantAcceptSecondment
      ? value.acceptSecondment
      : undefined,
    furnishedOfficeRentalAvailability: value.wantFurnishedOfficeRentalAvailability
      ? {}
      : undefined,
    furnishedOfficeRentalRequest: value.wantFurnishedOfficeRentalRequest
      ? {}
      : undefined,
    sendEmployee: value.wantSendEmployee ? value.sendEmployee : undefined,
  };
};

export const convertMissionMembersFormValue = (
  value: MissionFormValues['missionMembers'],
): Mission['missionMembers'] =>
  value.filter(member => !isEmptyMissionMember(member));

export const convertSupporterMembersFormValue = (
  value: SupporterFormValues['supporterMembers'],
): Supporter['supporterMembers'] =>
  value.filter(member => !isEmptySupporterMember(member));

export const convertMissionProductsFormValue = (
  value: MissionFormValues['missionProducts'],
): Mission['missionProducts'] =>
  value
    .map(({ hasMemberBenefit, ...missionProduct }) => ({
      ...missionProduct,
      memberBenefit: hasMemberBenefit ? missionProduct.memberBenefit : '',
      tags: missionProduct.tags.map(tag => tag.tag),
    }))
    .filter(missionProduct => !isEmptyMissionProduct(missionProduct));

export const convertMissionOfficeImagesFormValue = (
  value: MissionFormValues['missionOfficeImages'],
): Mission['missionOfficeImages'] =>
  value.filter(officeImage => !isEmptyOfficeImage(officeImage));

export const convertSupportAreasFormValue = (
  value: SupporterFormValues['supportAreaIds'],
  formSupportAreas: SupporterForm['supportAreas'],
): Supporter['supportAreas'] =>
  formSupportAreas.filter(formSupportArea =>
    value.includes(formSupportArea.supportAreaId),
  );

export const convertGalleryImagesFormValue = (
  value: SupporterFormValues['galleryImages'],
): Supporter['galleryImages'] =>
  value.filter(galleryImage => !isEmptyGalleryImage(galleryImage));

export const convertMissionJobOffersFormValue = (
  value: MissionFormValues['missionJobOffers'],
  formOccupations: MissionForm['occupations'],
): Mission['missionJobOffers'] => {
  const occupationsMap = formOccupations.reduce<{ [id: number]: string }>(
    (a, c) => {
      a[c.id] = c.name;
      return a;
    },
    {},
  );
  return value.map(missionJobOffer => ({
    id: missionJobOffer.id,
    occupation: {
      id: missionJobOffer.occupationId,
      name: occupationsMap[missionJobOffer.occupationId],
    },
    jobDescription: missionJobOffer.jobDescription,
    published: missionJobOffer.published,
  }));
};
