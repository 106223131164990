import React, { createContext, useContext, useMemo, FC } from 'react';
import useMissionForm from './useMissionForm/useMissionForm';

type MissionFormContextType = ReturnType<typeof useMissionForm> | undefined;

const MissionFormContext = createContext<MissionFormContextType>(undefined);

export const MissionFormContextProvider: FC = ({ children }) => {
  const useMissionFormContext = useMissionForm();

  const value = useMemo(() => useMissionFormContext, [useMissionFormContext]);

  return (
    <MissionFormContext.Provider value={value}>
      {children}
    </MissionFormContext.Provider>
  );
};

// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
const useMissionFormContext = () => useContext(MissionFormContext)!;

export default useMissionFormContext;
