import { useCallback, ChangeEvent } from 'react';
import { SupportAreaFormValue } from 'types/form';
import { MissionSettingFormik } from './useMissionSettingFormik';

type Payload = {
  supportAreaIds: SupportAreaFormValue;
  setFieldValue: MissionSettingFormik['setFieldValue'];
  setFieldTouched: MissionSettingFormik['setFieldTouched'];
};

const useSupportAreasField = ({
  supportAreaIds,
  setFieldValue,
  setFieldTouched,
}: Payload) => {
  const handleChangeSupportArea = useCallback(
    (event: ChangeEvent<HTMLInputElement>): void => {
      const target = event.currentTarget;
      const targetSupportAreaId = Number(target.value);
      if (target.checked) {
        setFieldValue('supportAreaIds', [
          ...supportAreaIds,
          Number(event.target.value),
        ]);
      } else {
        setFieldValue(
          'supportAreaIds',
          supportAreaIds.filter(
            supportAreaId => supportAreaId !== targetSupportAreaId,
          ),
        );
      }
      setFieldTouched('supportAreaIds', true, false);
    },
    [setFieldTouched, setFieldValue, supportAreaIds],
  );

  return {
    handleChangeSupportArea,
  };
};

export default useSupportAreasField;
