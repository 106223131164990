import { SupporterFormValues } from 'types/supporter';
import useMissionSettingFormik from '../../hooks/useMissionSettingFormik';
import { SupporterFormState } from './useSupporterFormState';
import { validationSchema } from '../validationSchema';

const useSupporterFormFormik = ({ initialValues }: SupporterFormState) => {
  const formik = useMissionSettingFormik<SupporterFormValues>({
    initialValues,
    validationSchema,
  });

  return formik;
};

export type SupporterFormFormik = ReturnType<typeof useSupporterFormFormik>;

export default useSupporterFormFormik;
