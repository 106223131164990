import React, { FC } from 'react';
import styled from 'styled-components/macro';

const StyledTable = styled.table`
  display: block;
  width: 100%;
  height: 100%;
`;

const TableWrapper = styled.div`
  width: 1156px;
  height: 100%;
  overflow: hidden;
  border-radius: 8px;
  box-shadow: 0 3px 8px rgba(0, 0, 0, 0.15);
`;

const Table: FC<{}> = ({ children }) => (
  <TableWrapper>
    <StyledTable>{children}</StyledTable>
  </TableWrapper>
);

export default Table;
