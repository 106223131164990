import React from 'react';
import { Link } from 'react-router-dom';
import * as PROTECTED_ROUTES from 'routes/constants/protected';

export default function NotFound() {
  return (
    <div style={{ padding: '30px' }}>
      <h1>404</h1>
      <p>Not Found</p>
      <Link to={PROTECTED_ROUTES.PRIVATE_HUMAN_RESOURCES_CAREER}>back</Link>
    </div>
  );
}
