import React, { FC, ComponentProps } from 'react';
import styled from 'styled-components/macro';
import { Field } from 'visits-style';
import { ErrorMessage } from 'components/atoms';

const StyledField = styled(Field)`
  &&& {
    position: relative;
    width: auto;
    margin: 0 0 20px;

    & > h5 > i {
      display: none;
    }
  }
`;

const StyledErrorMessage = styled(ErrorMessage)`
  position: absolute;
  bottom: -18px;
  left: 0;
`;

type FormItemProps = { error?: string | any } & ComponentProps<typeof Field>;

const FormItem: FC<FormItemProps> = ({ error, children, ...rest }) => {
  return (
    <StyledField {...rest} data-testid="form-item">
      {children}
      {error && (
        <StyledErrorMessage data-testid="form-error">
          {error}
        </StyledErrorMessage>
      )}
    </StyledField>
  );
};

export default FormItem;
