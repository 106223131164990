import React, { FC, ChangeEvent } from 'react';
import { useTranslation } from 'react-i18next';
import { MissionForm, MissionFormValues } from 'types/mission';
import { isEqualProps } from 'utils/object';
import { Selectbox } from 'components/atoms';
import { FormItem, FormItemLabel } from 'components/molecules';

type JapanPrefectureFieldProps = {
  value: MissionFormValues['japanPrefectureId'];
  form: MissionForm['japanPrefectures'];
  error: string | undefined;
  onChange: (e: ChangeEvent<HTMLSelectElement>) => void;
  onBlur: (e: any) => void;
};

const JapanPrefectureField: FC<JapanPrefectureFieldProps> = props => {
  const { value, error, form, onChange, onBlur } = props;

  const { t } = useTranslation('missionSetting');

  return (
    <FormItem error={error} style={{ marginBottom: 0 }}>
      <FormItemLabel
        label={t('field.address.subField.japanPrefecture.label')}
        level="secondary"
        required
      />
      <Selectbox
        margin="0 10px 0 0"
        name="japanPrefectureId"
        options={form}
        idName="japanPrefectureId"
        value={value}
        onBlur={onBlur}
        onChange={onChange}
        aria-label={t('field.address.subField.japanPrefecture.label')}
        style={{ width: '96px' }}
      />
    </FormItem>
  );
};

export default React.memo(JapanPrefectureField, (prevProps, nextProps) =>
  isEqualProps(prevProps, nextProps, ['value', 'error', 'form']),
);
