import { Supporter } from 'proto/v1/apimodel/apimodel';
import { SupporterForm, SupporterFormValues } from 'types/supporter';
import {
  convertCountryFormValue,
  convertJapanPrefectureFormValue,
  convertMemberRangeFormValue,
  convertSupporterMembersFormValue,
  convertSupportAreasFormValue,
  convertGalleryImagesFormValue,
} from '../convertFromFormValue';

const convertSupporterFormValuesToSupporter: (
  values: SupporterFormValues,
  form: SupporterForm,
) => Supporter = (values, form) => {
  return {
    supporterId: values.supporterId,
    name: values.name,
    supportDescription: values.supportDescription,
    iconImage: values.iconImage,
    coverImage: values.coverImage,
    strengthPoint: values.strengthPoint,
    companyName: values.companyName,
    companyEstablishedDate: values.companyEstablishedDate,
    companyUrl: values.companyUrl,
    country: convertCountryFormValue(values.countryId, form.countries),
    japanPrefecture: convertJapanPrefectureFormValue(
      values.japanPrefectureId,
      form.japanPrefectures,
    ),
    otherAddress: values.otherAddress,
    memberRange: convertMemberRangeFormValue(
      values.memberRangeId,
      form.memberRanges,
    ),
    supporterMembers: convertSupporterMembersFormValue(values.supporterMembers),
    supportAreas: convertSupportAreasFormValue(
      values.supportAreaIds,
      form.supportAreas,
    ),
    galleryImages: convertGalleryImagesFormValue(values.galleryImages),
    language: '',
    degreeOfAttention: 0,
  };
};

export default convertSupporterFormValuesToSupporter;
