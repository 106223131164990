import common from './locale/common.json';
import head from './locale/head.json';
import error from './locale/error.json';
import login from './locale/login.json';
import footer from './locale/footer.json';
import cookieAgreement from './locale/cookieAgreement.json';
import password from './locale/password.json';
import email from './locale/email.json';
import sidebar from './locale/sidebar.json';
import missionList from './locale/missionList.json';
import productList from './locale/productList.json';
import supporterList from './locale/supporterList.json';
import followersMissions from './locale/followersMissions.json';
import following from './locale/following.json';
import followingMissions from './locale/followingMissions.json';
import followingHumanResources from './locale/followingHumanResources.json';
import accountSetting from './locale/accountSetting.json';
import missionDetail from './locale/missionDetail.json';
import supporterDetail from './locale/supporterDetail.json';
import missionSetting from './locale/missionSetting.json';
import chat from './locale/chat.json';
import humanResource from './locale/humanResource.json';
import matter from './locale/matter.json';
import messageTemplate from './locale/messageTemplate.json';
import messageTemplateSetting from './locale/messageTemplateSetting.json';
import missionForm from './locale/missionForm.json';
import scoutConfirm from './locale/scoutConfirm.json';

export default {
  common,
  head,
  error,
  footer,
  cookieAgreement,
  login,
  password,
  email,
  sidebar,
  missionList,
  productList,
  supporterList,
  followersMissions,
  following,
  followingMissions,
  followingHumanResources,
  accountSetting,
  missionDetail,
  supporterDetail,
  missionSetting,
  chat,
  humanResource,
  matter,
  messageTemplate,
  messageTemplateSetting,
  missionForm,
  scoutConfirm,
};
