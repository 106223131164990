import React, { FC } from 'react';
import styled from 'styled-components/macro';
import { Matter } from 'proto/v1/apimodel/apimodel';
import { isEqualProps } from 'utils/object';
import {
  NewGraduateHeadField,
  NewGraduateMainField,
  NewGraduateResearchRoomsField,
  NewGraduateInternshipExperiencesField,
  NewGraduateStudyAbroadExperiencesField,
  NewGraduateClubExperiencesField,
  SelfIntroductionField,
} from './Fields';

const Card = styled.div`
  width: 864px;
`;

const CardBody = styled.div``;

type Props = {
  matter: Matter;
  onOpenAboutDttModal: () => void;
  isFavorite?: boolean;
  onClickFavorite?: (matter: Matter, favorite: boolean) => void;
};

const HumanResourceNewGraduateDetailCard: FC<Props> = ({
  matter,
  onOpenAboutDttModal,
  isFavorite,
  onClickFavorite,
}) => {
  return (
    <Card
      id={`matter-${matter.matterId}`}
      data-testid="human-resource-new-graduate-detail-card"
    >
      <NewGraduateHeadField
        matter={matter}
        isFavorite={isFavorite}
        onClickFavorite={onClickFavorite}
        isDetail
      />

      <CardBody>
        <NewGraduateMainField
          matter={matter}
          onOpenAboutDttModal={onOpenAboutDttModal}
          isDetail
        />

        <NewGraduateResearchRoomsField
          researchRooms={matter.humanResource?.newGraduate?.researchRooms}
        />

        <NewGraduateInternshipExperiencesField
          internshipExperiences={
            matter.humanResource?.newGraduate?.internshipExperiences
          }
        />

        <NewGraduateStudyAbroadExperiencesField
          studyAbroadExperiences={
            matter.humanResource?.newGraduate?.studyAbroadExperiences
          }
        />

        <NewGraduateClubExperiencesField
          clubExperiences={matter.humanResource?.newGraduate?.clubExperiences}
        />

        <SelfIntroductionField
          selfIntroduction={matter.humanResource?.selfIntroduction}
        />
      </CardBody>
    </Card>
  );
};

export default React.memo(
  HumanResourceNewGraduateDetailCard,
  (prevProps, nextProps) => isEqualProps(prevProps, nextProps),
);
