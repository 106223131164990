import React, { FC, ComponentProps, useCallback } from 'react';
import { NavLink } from 'react-router-dom';
import theme from 'styles/theme';

type SidebarNavLinkProps = ComponentProps<typeof NavLink> & {
  activetarget?: string | string[];
  from?: string;
  isShrinked?: boolean; // To avoid react recognize error, define isShrinked props here.
};

const SidebarNavLink: FC<SidebarNavLinkProps> = props => {
  const { activetarget, from, isShrinked, ...rest } = props;

  const isActiveNavLink = useCallback(
    match => {
      if (!from || !activetarget) return !!match;
      if (Array.isArray(activetarget)) {
        return activetarget.includes(from);
      }
      return from === activetarget;
    },
    [activetarget, from],
  );

  return (
    <NavLink
      {...rest}
      exact
      activeStyle={{
        background: theme.bgMenuHover,
      }}
      isActive={isActiveNavLink}
    />
  );
};

export default SidebarNavLink;
