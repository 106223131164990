import React, { FC, useMemo } from 'react';
import { TwoColumnContent } from 'components/atoms';
import { SupporterMembersField, GalleryImagesField } from '../../../Fields';
import useSupporterFormContext from '../../useSupporterFormContext';

const RequiredFieldsContainer: FC = () => {
  const {
    formik,
    supporterMembersError,
    handleAddMember,
    handleDeleteMember,
    handleChangeSupporterMemberIconImage,
    handleAddGalleryImage,
    handleChangeGalleryImage,
    handleDeleteGalleryImage,
    handleExceedImageFileSize,
  } = useSupporterFormContext();

  const { values, handleChange, handleBlur } = formik;

  return useMemo(
    () => (
      <TwoColumnContent style={{ marginBottom: 0 }}>
        <div className="content-inner">
          <SupporterMembersField
            value={values.supporterMembers}
            onAddMember={handleAddMember}
            onDeleteMember={handleDeleteMember}
            error={supporterMembersError}
            onChangeSupporterMemberName={handleChange}
            onChangeSupporterMemberCareer={handleChange}
            onChangeSupporterMemberIconImage={
              handleChangeSupporterMemberIconImage
            }
            onExceedImageFileSize={handleExceedImageFileSize}
            onBlur={handleBlur}
          />
        </div>

        <div className="content-inner">
          <GalleryImagesField
            value={values.galleryImages}
            onChangeImage={handleChangeGalleryImage}
            onAddImage={handleAddGalleryImage}
            onDeleteImage={handleDeleteGalleryImage}
            onExceedImageFileSize={handleExceedImageFileSize}
          />
        </div>
      </TwoColumnContent>
    ),
    [
      handleAddGalleryImage,
      handleAddMember,
      handleBlur,
      handleChange,
      handleChangeGalleryImage,
      handleChangeSupporterMemberIconImage,
      handleDeleteGalleryImage,
      handleDeleteMember,
      handleExceedImageFileSize,
      supporterMembersError,
      values.galleryImages,
      values.supporterMembers,
    ],
  );
};

export default RequiredFieldsContainer;
