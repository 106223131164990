import styled, { css } from 'styled-components/macro';
import theme from 'styles/theme';

const HumanResourceCardBodyItem = styled.div<{
  background?: boolean;
}>`
  display: flex;
  padding: 20px 36px;
  background-color: ${theme.baseWhite};
  ${({ background }) =>
    background &&
    css`
      background-color: ${theme.bgAdd};
    `}
`;

export default HumanResourceCardBodyItem;
