import React, { createContext, useContext, useMemo, FC } from 'react';
import useSupporterForm from './useSupporterForm';

type SupporterFormContextType = ReturnType<typeof useSupporterForm> | undefined;

const SupporterFormContext = createContext<SupporterFormContextType>(undefined);

export const SupporterFormContextProvider: FC = ({ children }) => {
  const useSupporterFormContext = useSupporterForm();

  const value = useMemo(() => useSupporterFormContext, [
    useSupporterFormContext,
  ]);

  return (
    <SupporterFormContext.Provider value={value}>
      {children}
    </SupporterFormContext.Provider>
  );
};

// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
const useSupporterFormContext = () => useContext(SupporterFormContext)!;

export default useSupporterFormContext;
