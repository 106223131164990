import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  DocumentChange,
  DocumentData,
  QuerySnapshot,
} from 'firebase/firestore';
import { getAccountId } from 'modules/account';
import apiFirebase from 'external/firebase/firebase';
import { NotificationActions } from 'modules/notification';

export default function useNotification() {
  const dispatch = useDispatch();
  const accountId = useSelector(getAccountId);
  type NotificationMessageType = 'contact' | 'message';

  const subscribeUpdateMessage = useCallback(
    (UpdateMessage: () => void) => {
      if (!accountId) return;
      return apiFirebase.getNotifications(
        accountId,
        'message',
        (snapshot: QuerySnapshot<DocumentData>) => {
          const changes = snapshot.docChanges();
          if (changes.length === 0) return;
          return UpdateMessage();
        },
      );
    },
    [accountId],
  );

  const subscribeDeleteMessage = useCallback(
    (handler: (changes: DocumentChange<DocumentData>[]) => void) => {
      if (!accountId) return;
      return apiFirebase.getNotifications(
        accountId,
        'deleted_message',
        (snapshot: QuerySnapshot<DocumentData>) => {
          const changes = snapshot.docChanges();
          if (changes.length === 0) return;
          handler(changes);
        },
      );
    },
    [accountId],
  );

  const subscribeNotification = useCallback(
    (messageType: NotificationMessageType) => {
      if (!accountId) return;
      return apiFirebase.getNotifications(
        accountId,
        messageType,
        (snapshot: QuerySnapshot<DocumentData>) => {
          const changes = snapshot.docChanges();
          if (changes.length === 0) return;

          switch (messageType) {
            case 'contact':
              return dispatch(
                NotificationActions.setContactNotifications(changes),
              );
            case 'message':
              return dispatch(
                NotificationActions.setMessageNotifications(changes),
              );
          }
        },
      );
    },
    [dispatch, accountId],
  );

  return {
    subscribeNotification,
    subscribeUpdateMessage,
    subscribeDeleteMessage,
  };
}
