import styled from 'styled-components/macro';

const TableCell = styled.td<{ cellWidth?: string }>`
  display: flex;
  flex-grow: 0;
  flex-shrink: 0;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-start;
  width: ${({ cellWidth = '100%' }) => cellWidth};
  min-height: 77px;
  padding: 24px;
  border: none;
  font-size: 14px;
  font-weight: normal;
  line-height: 1.5;
  word-wrap: break-word;
  white-space: pre-wrap;
  overflow-wrap: break-word;
`;

export default TableCell;
