import { UpdateMissionRequest } from 'proto/v1/missionservice/missionservice';
import { MissionFormValues } from 'types/mission';
import {
  getIconImageToUpdate,
  getCoverImageToUpdate,
  getJapanPrefectureToUpdate,
  getMissionDemandToUpdate,
  getMissionMembersToUpdate,
  getMissionOfficeImagesToUpdate,
  getMissionProductsToUpdate,
  getMissionJobOffersUpdate,
} from '../update';

const getMissionToUpdate = (
  values: MissionFormValues,
): UpdateMissionRequest => {
  return {
    missionId: values.missionId,
    name: values.name,
    iconImage: getIconImageToUpdate(values.iconImage),
    coverImage: getCoverImageToUpdate(values.coverImage),
    missionDescription: values.missionDescription,
    strengthPoint: values.strengthPoint,
    companyName: values.companyName,
    countryId: values.countryId,
    japanPrefectureId: getJapanPrefectureToUpdate(
      values.countryId,
      values.japanPrefectureId,
    ),
    otherAddress: values.otherAddress,
    memberRangeId: values.memberRangeId,
    fundId: values.fundId,
    missionCategories: values.missionCategories,
    missionMembers: getMissionMembersToUpdate(values.missionMembers),
    missionDemand: getMissionDemandToUpdate(values.missionDemand),
    companyUrl: values.companyUrl,
    missionOfficeImages: getMissionOfficeImagesToUpdate(
      values.missionOfficeImages,
    ),
    missionProducts: getMissionProductsToUpdate(values.missionProducts),
    companyEstablishedDate: values.companyEstablishedDate,
    missionJobOffers: getMissionJobOffersUpdate(values.missionJobOffers),
  };
};

export default getMissionToUpdate;
