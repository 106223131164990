import React from 'react';
import styled from 'styled-components/macro';
import { useTranslation } from 'react-i18next';
import { Heading, Wall, ButtonS, TextInput } from 'components/atoms';
import { ConfirmModal, FormItem } from 'components/molecules';
import useChangeEmailAddressForm from './useChangeEmailAddressForm';

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 16px;
  padding-top: 16px;
`;

const Field = styled(FormItem)`
  &&& {
    width: 413px;
    margin-bottom: 48px;

    &:last-child {
      margin-bottom: 0;
    }
  }
`;

const CurrentMailAddress = styled.p`
  height: 40px;
  padding: 0.75rem 0.625rem;
`;

export default function ChangeEmailAddressForm({
  currentEmailAddress,
}: {
  currentEmailAddress: string;
}) {
  const { t } = useTranslation(['accountSetting', 'common']);
  const {
    values,
    handleChange,
    handleBlur,
    handleSubmit,
    isValid,
    errors,
    dirty,
    submitDone,
    setSubmitDone,
    submitConfirm,
    setSubmitConfirm,
  } = useChangeEmailAddressForm();
  return (
    <>
      <Container>
        <Heading level="h2" contents="private">
          {t('form.changeEmail.title')}
        </Heading>
        <ButtonS
          buttonTheme="default"
          onClick={() => setSubmitConfirm(!submitConfirm)}
          type="button"
          disabled={!dirty || !isValid}
          data-testid="change-email-submit-button"
        >
          {t('button.submit')}
        </ButtonS>
      </Container>

      <Wall color="baseWhite">
        <Field>
          <Heading
            style={{ marginBottom: '12px' }}
            level="h3"
            contents="private"
          >
            {t('form.changeEmail.field.currentEmail.label')}
          </Heading>
          <CurrentMailAddress data-testid="current-email-address">
            {currentEmailAddress}
          </CurrentMailAddress>
        </Field>
        <Field error={errors.email} style={{ marginBottom: '20px' }}>
          <Heading
            style={{ marginBottom: '24px' }}
            level="h3"
            contents="private"
          >
            {t('form.changeEmail.field.email.label')}
          </Heading>
          <TextInput
            name="email"
            value={values.email}
            onChange={handleChange}
            onBlur={handleBlur}
            aria-label={t('form.changeEmail.field.email.label')}
            outline
          />
        </Field>
      </Wall>
      <ConfirmModal
        show={submitConfirm}
        confirmCaption={t('common:button.ok')}
        confirm={() => {
          handleSubmit();
        }}
        closeModal={() => setSubmitConfirm(!submitConfirm)}
      >
        <p data-testid="modal-confirm-change-email">
          {t('form.changeEmail.modal.confirmChangeEmail.content')}
        </p>
      </ConfirmModal>

      <ConfirmModal
        show={submitDone}
        confirmCaption={t('common:button.ok')}
        confirm={() => {
          setSubmitDone(!submitDone);
        }}
        closeModal={() => {
          setSubmitDone(!submitDone);
        }}
      >
        <p data-testid="modal-success-change-email">
          {t('form.changeEmail.modal.successChangeEmail.content')}
        </p>
      </ConfirmModal>
    </>
  );
}
