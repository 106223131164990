import styled from 'styled-components/macro';

const DetailCompanyField = styled.div`
  margin-bottom: 24px;

  &:last-child {
    margin-bottom: 0;
  }
`;

export default DetailCompanyField;
