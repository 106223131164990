import React from 'react';
import { Mission } from 'proto/v1/apimodel/apimodel';
import { isCompanyCorporateAttribute } from 'utils/models/mission';
import { DetailContentsRightItem } from 'components/molecules';
import {
  AddressField,
  IconImageField,
  CompanyNameField,
  CompanyUrlField,
  MissionMembersField,
  CompanyEstablishedDateField,
  MemberRangeField,
  MissionCategoriesField,
} from 'components/organisms/Details/Common/Fields';
import {
  DemandTagsField,
  CorporateAttributesField,
  FundField,
} from 'components/organisms/Details/MissionDetail/Fields';

type Props = { mission: Mission; forInnovator?: boolean };

const CompanyContent: React.VFC<Props> = ({
  mission,
  forInnovator = false,
}) => {
  return (
    <>
      <DetailContentsRightItem>
        <IconImageField iconImage={mission.iconImage} />
        <CompanyNameField companyName={mission.companyName} />
        {mission.companyUrl && (
          <CompanyUrlField companyUrl={mission.companyUrl} />
        )}
        <MissionCategoriesField missionCategories={mission.missionCategories} />
        {!forInnovator && mission.missionDemand && (
          <DemandTagsField missionDemand={mission.missionDemand} />
        )}
        <CorporateAttributesField
          corporateAttributes={mission.corporateAttributes}
        />
        {isCompanyCorporateAttribute(mission.corporateAttributes) && (
          <CompanyEstablishedDateField
            companyEstablishedDate={mission.companyEstablishedDate}
          />
        )}
        <AddressField
          country={mission.country}
          japanPrefecture={mission.japanPrefecture}
          otherAddress={mission.otherAddress}
        />
        <MemberRangeField memberRange={mission.memberRange} />
        <FundField fund={mission.fund} />
      </DetailContentsRightItem>

      {mission.missionMembers.length > 0 && (
        <DetailContentsRightItem borderTop>
          <MissionMembersField members={mission.missionMembers} />
        </DetailContentsRightItem>
      )}
    </>
  );
};

export default CompanyContent;
