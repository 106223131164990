import { ValidateToPublishStepsResult } from 'types/form';
import { MissionFormValues } from 'types/mission';
import {
  stepCompanySchema,
  stepAboutMissionRequiredSchema,
  stepMissionDemandSchema,
  stepMissionJobOffersSchema,
} from './validationSchema';

// eslint-disable-next-line import/prefer-default-export
export const validateMissionFormValuesToPublish = (
  values: MissionFormValues,
  canUseMissionFormStepMissionDemand: boolean,
  canUseMissionFormStepMissionJobOffers: boolean,
): {
  isValid: boolean;
  stepsResult: ValidateToPublishStepsResult;
} => {
  const isValidStepCompany = stepCompanySchema.isValidSync(values);
  const isValidStepAboutMission = stepAboutMissionRequiredSchema.isValidSync(
    values,
  );

  if (
    canUseMissionFormStepMissionDemand &&
    !canUseMissionFormStepMissionJobOffers
  ) {
    const isValidStepMissionDemand = stepMissionDemandSchema.isValidSync(
      values,
    );

    return {
      isValid:
        isValidStepCompany &&
        isValidStepAboutMission &&
        isValidStepMissionDemand,
      stepsResult: {
        company: isValidStepCompany,
        aboutMission: isValidStepAboutMission,
        missionJobOffers: true, // Always true for the mission that can use only the mission demand step.
        missionDemand: isValidStepMissionDemand,
      },
    };
  }

  if (
    !canUseMissionFormStepMissionDemand &&
    canUseMissionFormStepMissionJobOffers
  ) {
    const isValidStepMissionJobOffers = stepMissionJobOffersSchema.isValidSync(
      values,
    );

    return {
      isValid:
        isValidStepCompany &&
        isValidStepAboutMission &&
        isValidStepMissionJobOffers,
      stepsResult: {
        company: isValidStepCompany,
        aboutMission: isValidStepAboutMission,
        missionJobOffers: isValidStepMissionJobOffers,
        missionDemand: true, // Always true for the mission that can use only the mission job offers step.
      },
    };
  }

  const isValidStepMissionJobOffers = stepMissionJobOffersSchema.isValidSync(
    values,
  );
  const isValidStepMissionDemand = stepMissionDemandSchema.isValidSync(values);

  return {
    isValid:
      isValidStepCompany &&
      isValidStepAboutMission &&
      (isValidStepMissionJobOffers || isValidStepMissionDemand),
    stepsResult: {
      company: isValidStepCompany,
      aboutMission: isValidStepAboutMission,
      missionJobOffers: isValidStepMissionJobOffers,
      missionDemand: isValidStepMissionDemand,
    },
  };
};
