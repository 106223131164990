import React, { FC, ComponentProps } from 'react';
import styled from 'styled-components/macro';
import typography from 'styles/typography';
import BaseButton from './BaseButton';

const StyledButtonM = styled(BaseButton)<ComponentProps<typeof BaseButton>>`
  ${typography.textDefault}
  width: 200px;
  height: 40px;
  padding: 6px 16px;
  text-align: center;
`;

const ButtonM: FC<ComponentProps<typeof BaseButton>> = props => (
  <StyledButtonM {...props}>{props.children}</StyledButtonM>
);
export default ButtonM;
