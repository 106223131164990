import { initializeApp } from 'firebase/app';
import { Analytics, getAnalytics } from 'firebase/analytics';
import {
  Auth,
  browserLocalPersistence,
  getAuth,
  getIdToken,
  setPersistence,
  signInWithCustomToken,
  signOut,
} from 'firebase/auth';
import {
  Firestore,
  collection,
  getFirestore,
  onSnapshot,
  QuerySnapshot,
  DocumentData,
} from 'firebase/firestore';
import firebaseConfig from 'external/firebase/config/firebase';
import logger from 'external/logger';

export const doAnalytics =
  process.env.NODE_ENV === 'production' &&
  !!process.env.REACT_APP_FIREBASE_MEASUREMENT_ID &&
  window.location.host.indexOf('local') === -1;

let auth: Auth;
let firestore: Firestore;
let analytics: Analytics;

let unsubscribes: (() => void)[] = [];

export default {
  init() {
    const firebaseApp = initializeApp(firebaseConfig);
    auth = getAuth(firebaseApp);
    firestore = getFirestore(firebaseApp);
    if (doAnalytics) analytics = getAnalytics(firebaseApp);
  },
  setPersistence() {
    return setPersistence(auth, browserLocalPersistence);
  },
  login(token: string): Promise<any> {
    return signInWithCustomToken(auth, token);
  },
  currentToken(): Promise<string> {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    return getIdToken(auth.currentUser!);
  },
  logout(): Promise<void> {
    unsubscribes.forEach(x => x());
    unsubscribes = [];
    return signOut(auth);
  },
  authUser() {
    return auth.currentUser;
  },
  auth() {
    return auth;
  },
  firestore() {
    return firestore;
  },
  analytics() {
    return analytics;
  },
  getNotifications(
    accountId: string,
    messageType: string,
    callbackOnSnapshot: (snapshot: QuerySnapshot<DocumentData>) => void,
  ) {
    if (accountId === '') return;
    const docRef = collection(
      firestore,
      `account_notifications/${accountId}/${messageType}`,
    );
    const unsubscribe = onSnapshot(
      docRef,
      callbackOnSnapshot,
      (error: Error) => {
        logger.exception(error, { extra: { collection } });
      },
    );
    unsubscribes.push(unsubscribe);
    return () => {
      unsubscribes = unsubscribes.filter(x => x !== unsubscribe);
      unsubscribe();
    };
  },
};
