import React, { FC, CSSProperties } from 'react';
import styled from 'styled-components/macro';
import theme from 'styles/theme';

type WrapperProps = { backgroundImage: string | undefined };

const Wrapper = styled.div`
  position: relative;
  z-index: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  background-color: ${theme.baseWhite};
  ${({ backgroundImage }: WrapperProps) =>
    backgroundImage && `background-image: url(${backgroundImage});`}
  background-position: center;
  background-size: cover;

  &::before {
    content: '';
    position: absolute;
    z-index: -1;
    top: -20px;
    right: -20px;
    width: calc(100% + 40px);
    height: calc(100% + 40px);
    background: inherit;
    filter: blur(20px);
  }
`;

const TransparentLayer = styled.div`
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.75);
`;

type BackgroundImageWallProps = {
  backgroundImage: string | undefined;
  style?: CSSProperties;
};

const BackgroundImageWall: FC<BackgroundImageWallProps> = props => (
  <Wrapper backgroundImage={props.backgroundImage}>
    <TransparentLayer>{props.children}</TransparentLayer>
  </Wrapper>
);

export default BackgroundImageWall;
