import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components/macro';
import { NewGraduateProfileForMatter } from 'proto/v1/apimodel/apimodel';
import {
  HumanResourceCardBodyItem,
  HumanResourceCardBodyItemLabel,
  HumanResourceCardBodyItemContent,
  HumanResourceCardBodyListItemContent,
} from 'components/molecules';

const ResearchRoom = styled.div`
  &:not(:last-child) {
    margin-bottom: 16px;
  }
`;

type NewGraduateResearchRoomsFieldProps = {
  researchRooms: NewGraduateProfileForMatter['researchRooms'] | undefined;
};

const NewGraduateResearchRoomsField: FC<NewGraduateResearchRoomsFieldProps> = props => {
  const { researchRooms } = props;
  const { t } = useTranslation(['matter']);

  return (
    <HumanResourceCardBodyItem background>
      <HumanResourceCardBodyItemLabel>
        {t('field.researchRooms.label', {
          lng: 'ja',
        })}
      </HumanResourceCardBodyItemLabel>
      <HumanResourceCardBodyItemContent>
        <div>
          {researchRooms && researchRooms.length > 0
            ? researchRooms.map((researchRoom, index) => (
                // eslint-disable-next-line react/no-array-index-key
                <ResearchRoom key={index}>
                  <div>{researchRoom.name}</div>
                  <HumanResourceCardBodyListItemContent>
                    {researchRoom.content}
                  </HumanResourceCardBodyListItemContent>
                </ResearchRoom>
              ))
            : '-'}
        </div>
      </HumanResourceCardBodyItemContent>
    </HumanResourceCardBodyItem>
  );
};

export default NewGraduateResearchRoomsField;
