import React from 'react';
import { useTranslation } from 'react-i18next';
import { ButtonM } from 'components/atoms';
import { Modal, ModalContent, ModalFooter } from 'components/molecules';

type Props = {
  show: boolean;
  onOk: () => void;
  onCancel: () => void;
  isPublished: boolean;
};

const NoInputSupporterConfirmationModal: React.VFC<Props> = ({
  show,
  onOk,
  onCancel,
  isPublished,
}) => {
  const { t } = useTranslation('missionSetting');
  return (
    <Modal show={show} closeModal={onCancel}>
      <ModalContent data-testid="modal-save-required-no-input">
        {t('modal.saveRequiredNoInputSupporterConfirmation.content')}
        {isPublished && (
          <>
            <br />
            <br />
            {t('modal.saveRequiredNoInputSupporterConfirmation.description')}
          </>
        )}
      </ModalContent>
      <ModalFooter>
        <ButtonM onClick={onCancel} buttonTheme="cancel">
          {t('button.returnToEntryPage')}
        </ButtonM>
        <ButtonM
          onClick={onOk}
          buttonTheme="default"
          type="button"
          data-testid="modal-save-required-no-input-button"
        >
          {t('button.saveContents')}
        </ButtonM>
      </ModalFooter>
    </Modal>
  );
};

export default NoInputSupporterConfirmationModal;
