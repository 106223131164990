import React, { FC, useRef, useEffect, useState } from 'react';
import styled from 'styled-components/macro';
import { useTranslation } from 'react-i18next';
import theme from 'styles/theme';
import typography from 'styles/typography';
import scrollbar from 'styles/scrollbar';
import { ChatRoom } from 'components/molecules';
import { ButtonS, ItemLoading } from 'components/atoms';
import { ChatRoom as ChatRoomType, ChatUser } from 'proto/v1/apimodel/apimodel';

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  padding-right: 4px;
  overflow-x: hidden;
  overflow-y: auto;
  background: ${theme.baseWhite};
  ${scrollbar};
`;

const LoadingErrorContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 32px;
  margin-bottom: 30px;
`;

const LoadingMessage = styled.div`
  ${typography.textNote}
  margin-bottom: 4px;
  color: ${theme.textSecondary};
  text-align: center;
`;

const LoadingContainer = styled.div`
  margin-top: 24px;
  padding: 0 10px;
`;

type ChatRoomListProps = {
  chatRooms: ChatRoomType[] | null;
  selectedChatRoomId: string | null;
  isFetching: boolean;
  errorOnFetch: { message: string } | null;
  onClick: (
    e: React.MouseEvent<HTMLDivElement>,
    chatRoom: ChatRoomType,
  ) => void;
  onClickRetry: () => void;
  listContainerRef:
    | React.RefObject<HTMLDivElement>
    | ((instance: HTMLDivElement | null) => void);
  onScroll: () => void;
  onClickInnovatorIcon: (innovator: ChatUser['innovator']) => void;
};

const MemoizedChatRoom = React.memo(ChatRoom, (prevProps, nextProps) => {
  return (
    prevProps.chatRoom === nextProps.chatRoom &&
    prevProps.selected === nextProps.selected &&
    prevProps.onClick === nextProps.onClick
  );
});

const ChatRoomList: FC<ChatRoomListProps> = props => {
  const {
    chatRooms,
    selectedChatRoomId,
    onClick,
    onClickRetry,
    isFetching,
    errorOnFetch,
    listContainerRef,
    onScroll,
    onClickInnovatorIcon,
  } = props;

  const { t } = useTranslation(['common']);

  const [completedFirstScroll, setCompletedFirstScroll] = useState<boolean>(
    false,
  );

  const selectedElementRef = useRef<HTMLTableSectionElement>(null);

  useEffect(() => {
    // When the list was rendered first time, scroll to position of selected chat room
    if (
      !completedFirstScroll &&
      typeof selectedChatRoomId === 'string' &&
      selectedChatRoomId.length > 0 &&
      selectedElementRef.current
    ) {
      selectedElementRef.current.scrollIntoView();
      setCompletedFirstScroll(true);
    }
  }, [selectedChatRoomId, completedFirstScroll]);

  return (
    <Wrapper
      ref={listContainerRef}
      onScroll={onScroll}
      data-testid="chat-rooms"
    >
      {chatRooms &&
        chatRooms.length > 0 &&
        chatRooms.map((chatRoom: ChatRoomType) => {
          const isSelected = chatRoom.chatRoomId === selectedChatRoomId;
          return (
            <MemoizedChatRoom
              key={chatRoom.chatRoomId}
              refToElement={isSelected ? selectedElementRef : undefined}
              selected={isSelected}
              chatRoom={chatRoom}
              onClick={onClick}
              onClickInnovatorIcon={onClickInnovatorIcon}
            />
          );
        })}
      {isFetching && (
        <LoadingContainer>
          <ItemLoading size="40px" />
        </LoadingContainer>
      )}

      {errorOnFetch && (
        <LoadingErrorContainer>
          <LoadingMessage>
            <p>{errorOnFetch.message.replace('\n', '')}</p>
          </LoadingMessage>
          <ButtonS
            className="button"
            buttonTheme="default"
            onClick={onClickRetry}
          >
            {t('common:button.retry')}
          </ButtonS>
        </LoadingErrorContainer>
      )}
    </Wrapper>
  );
};

export default ChatRoomList;
