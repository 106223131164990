import { ComponentProps } from 'react';
import styled from 'styled-components/macro';
import { Tag } from 'components/atoms';
import theme from 'styles/theme';
import typography from 'styles/typography';

type CategoryTagProps = ComponentProps<typeof Tag> & {
  backgroundColor?: string;
};

const CategoryTag = styled(Tag)`
  ${typography.labelCategory}
  min-width: 100px;
  height: 24px;
  margin-bottom: 4px;
  padding-top: 4px;
  padding-bottom: 5px;
  border-radius: 16px;
  color: ${theme.baseWhite};
  ${({ backgroundColor }: CategoryTagProps) =>
    `background: ${backgroundColor && backgroundColor};`}

  &:not(:last-child) {
    margin-right: 4px;
  }
`;

export default CategoryTag;
