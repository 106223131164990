import React, { FC, useCallback, ChangeEvent, CSSProperties } from 'react';
import styled, { css } from 'styled-components/macro';
import theme from 'styles/theme';
import { FiImage } from 'react-icons/fi';
import { IMAGE_FILE_SIZE_LIMIT } from 'constants/config';

type StyledWrapperProps = {
  imageSrc?: string;
  width?: number;
  height?: number;
};

const Wrapper = styled.label<StyledWrapperProps>`
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;
  width: ${({ width }) => width || 108}px;
  height: ${({ height }) => height || 108}px;
  cursor: pointer;
  ${({ imageSrc }) =>
    imageSrc
      ? css`
          background-image: url(${imageSrc});
          background-repeat: no-repeat;
          background-size: contain;
          background-position: center;
          background-color: ${theme.baseWhite};
        `
      : css`
          background: ${theme.borderDefault};
        `}

  &.disabled {
    cursor: default;
  }

  &:hover {
    svg {
      ${({ imageSrc }) =>
        imageSrc &&
        css`
          display: block;
        `}
    }

    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: rgba(0, 0, 0, 0.4);
    }
  }

  svg {
    z-index: 0;
    width: 30px;
    height: 30px;
    ${({ imageSrc }) =>
      imageSrc &&
      css`
        display: none;
      `}
  }

  input {
    display: none;
  }
`;

type ImageUploaderProps = {
  onUpload: (uploadedImage: string) => void;
  onExceededFileSizeLimit: () => void;
  imageSrc?: string;
  name?: string;
  width?: number;
  height?: number;
  style?: CSSProperties;
};

const ImageUploader: FC<ImageUploaderProps> = ({
  onUpload,
  onExceededFileSizeLimit,
  name,
  imageSrc,
  width,
  height,
  style,
}) => {
  const handleChangeImage = useCallback(
    (event: ChangeEvent<HTMLInputElement>): void => {
      event.preventDefault();
      const files = event.target && event.target.files;

      if (files && files.length > 0) {
        // use Kilobytes unit size（1KB = 1000Byte, not 1024Byte)
        if (files[0].size / 1000 < IMAGE_FILE_SIZE_LIMIT) {
          const reader = new FileReader();
          reader.onload = () => {
            onUpload(reader.result as string);
          };
          reader.readAsDataURL(files[0]);
        } else {
          onExceededFileSizeLimit();
        }
      }
      event.target.value = '';
    },
    [onUpload, onExceededFileSizeLimit],
  );

  return (
    <Wrapper imageSrc={imageSrc} width={width} height={height} style={style}>
      <FiImage size={32} color={theme.white} />
      <input
        type="file"
        name={name}
        onChange={handleChangeImage}
        accept="image/jpeg,image/png"
      />
    </Wrapper>
  );
};

export default ImageUploader;
