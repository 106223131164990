import React, { ChangeEvent, FC } from 'react';
import styled from 'styled-components/macro';
import { useTranslation } from 'react-i18next';
import { isEqualProps } from 'utils/object';
import { MissionSettingMaxLength } from 'constants/form';
import { FlexContainer, Checkbox, Textarea } from 'components/atoms';
import { FormItem, MissionDemandFieldItem } from 'components/molecules';

const StyledCheckbox = styled(Checkbox)`
  &&& {
    margin-bottom: 0;
  }
`;

const StyledForm = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .input-fields {
    width: 100%;
  }
`;

const StyledFormItem = styled(FormItem)`
  &&& {
    min-height: 0;
    margin-bottom: 8px;
  }

  &&&:last-child {
    margin-bottom: 0;
  }
`;

const StyledTextarea = styled(Textarea)`
  position: relative;
  width: 445px;

  textarea {
    min-height: 97px;
    line-height: 1.5;
  }

  small {
    display: block;
    position: absolute;
    bottom: -14px;
    left: 0;
    width: auto;
  }
`;

type ServiceInformationFieldProps = {
  wantServiceInformation: boolean;
  issue: string;
  onChangeWantServiceInformation: (e: ChangeEvent<HTMLInputElement>) => void;
  onChangeIssue: (e: ChangeEvent<HTMLTextAreaElement>) => void;
  onBlur: (e: any) => void;
};

const ServiceInformationField: FC<ServiceInformationFieldProps> = props => {
  const {
    wantServiceInformation,
    issue,
    onChangeWantServiceInformation,
    onChangeIssue,
    onBlur,
  } = props;

  const { t } = useTranslation('missionSetting');

  return (
    <MissionDemandFieldItem>
      <StyledCheckbox
        name="missionDemand.wantServiceInformation"
        checked={wantServiceInformation}
        onChange={onChangeWantServiceInformation}
        aria-label={t('field.missionDemand.checkbox.wantServiceInformation')}
      >
        {t('field.missionDemand.checkbox.wantServiceInformation')}
      </StyledCheckbox>
      {wantServiceInformation && (
        <FlexContainer flexDirection="column">
          <StyledForm>
            <StyledFormItem>
              <StyledTextarea
                name={`missionDemand.serviceInformation.issues[0]`}
                value={issue}
                maxLength={
                  MissionSettingMaxLength.MISSION_DEMAND_SERVICE_INFORMATION_ISSUE
                }
                placeholder={t(
                  'field.missionDemand.serviceInformationIssue.placeholder',
                  {
                    maxLength:
                      MissionSettingMaxLength.MISSION_DEMAND_SERVICE_INFORMATION_ISSUE,
                  },
                )}
                onChange={onChangeIssue}
                onBlur={onBlur}
                data-testid="service-information-issue"
              />
            </StyledFormItem>
          </StyledForm>
        </FlexContainer>
      )}
    </MissionDemandFieldItem>
  );
};

export default React.memo(ServiceInformationField, (prevProps, nextProps) =>
  isEqualProps(prevProps, nextProps, ['wantServiceInformation', 'issue']),
);
