import React, { FC, ChangeEvent } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components/macro';
import { FinancingSeries } from 'proto/v1/apimodel/apimodel';
import { isEqualProps } from 'utils/object';
import typography from 'styles/typography';
import { Checkbox, Selectbox } from 'components/atoms';
import { FormItem, MissionDemandFieldItem } from 'components/molecules';

const FinancingSeriesField = styled(FormItem)`
  &&& {
    width: 100%;
    margin-bottom: 0;
    margin-left: 29px;
  }
`;

const Label = styled.p`
  ${typography.textDefault}
  margin-bottom: 5px;
`;

const StyledSelectbox = styled(Selectbox)`
  width: 180px;
`;

type FundraisingFieldProps = {
  wantFundraising: boolean;
  form: FinancingSeries[];
  financingSeriesId: number;
  onChangeWantFundraising: (e: ChangeEvent<HTMLInputElement>) => void;
  onChangeFinancingSeries: (e: ChangeEvent<HTMLSelectElement>) => void;
  onBlur: (e: any) => void;
  error: any;
};

const FundraisingField: FC<FundraisingFieldProps> = props => {
  const {
    wantFundraising,
    form,
    financingSeriesId,
    onChangeWantFundraising,
    onChangeFinancingSeries,
    onBlur,
    error,
  } = props;
  const { t } = useTranslation('missionSetting');
  return (
    <MissionDemandFieldItem>
      <Checkbox
        name="missionDemand.wantFundraising"
        onChange={onChangeWantFundraising}
        checked={wantFundraising}
        aria-label={t('field.missionDemand.checkbox.wantRaiseFund')}
      >
        {t('field.missionDemand.checkbox.wantRaiseFund')}
      </Checkbox>
      {wantFundraising && (
        <FinancingSeriesField error={error}>
          <Label>{t('field.missionDemand.financingSeries.label')}</Label>
          <StyledSelectbox
            name="missionDemand.fundraising.financingSeriesId"
            options={form}
            idName="seriesId"
            value={financingSeriesId}
            onChange={onChangeFinancingSeries}
            onBlur={onBlur}
            disabled={!wantFundraising}
            aria-label={t('field.missionDemand.financingSeries.label')}
          />
        </FinancingSeriesField>
      )}
    </MissionDemandFieldItem>
  );
};

export default React.memo(FundraisingField, (prevProps, nextProps) =>
  isEqualProps(prevProps, nextProps, [
    'wantFundraising',
    'form',
    'financingSeriesId',
    'error',
  ]),
);
