import styled from 'styled-components/macro';
import theme from 'styles/theme';
import typography from 'styles/typography';

const ErrorMessage = styled.div`
  ${typography.textError};
  color: ${theme.baseError};
`;

export default ErrorMessage;
