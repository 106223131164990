import React, { FC } from 'react';
import { MissionDemand } from 'proto/v1/apimodel/apimodel';
import { useTranslation } from 'react-i18next';
import {
  isSelectedDemandFundraisingFinancingSeries,
  isSelectedDemandInvestFinancingSeries,
} from 'utils/models/mission';
import styled from 'styled-components';
import {
  MissionDemandTag,
  DetailSubTitle,
  DetailCompanyField,
} from 'components/molecules';

type DemandTagsFieldProps = {
  missionDemand: MissionDemand;
};

const StyledMissionDemandTag = styled(MissionDemandTag)`
  &&& {
    margin-top: 8px;
    margin-right: 4px;
    margin-bottom: 0;
  }
`;

const DemandTagsField: FC<DemandTagsFieldProps> = ({ missionDemand }) => {
  const { t } = useTranslation('missionDetail');

  return (
    <DetailCompanyField>
      <DetailSubTitle style={{ marginBottom: 0 }}>
        {t('field.missionDemand.label')}
      </DetailSubTitle>
      {missionDemand.collaboration && (
        <StyledMissionDemandTag>
          {t('field.missionDemand.tag.collaboration')}
        </StyledMissionDemandTag>
      )}
      {isSelectedDemandFundraisingFinancingSeries(missionDemand) && (
        <StyledMissionDemandTag>
          {t('field.missionDemand.tag.fundraising')}
        </StyledMissionDemandTag>
      )}
      {isSelectedDemandInvestFinancingSeries(missionDemand) && (
        <StyledMissionDemandTag>
          {t('field.missionDemand.tag.invest')}
        </StyledMissionDemandTag>
      )}
      {missionDemand.serviceSuggestion && (
        <StyledMissionDemandTag>
          {t('field.missionDemand.tag.serviceSuggestion')}
        </StyledMissionDemandTag>
      )}
      {missionDemand.serviceInformation && (
        <StyledMissionDemandTag>
          {t('field.missionDemand.tag.serviceInformation')}
        </StyledMissionDemandTag>
      )}
      {missionDemand.transferOfBusiness && (
        <StyledMissionDemandTag>
          {t('field.missionDemand.tag.transferOfBusiness')}
        </StyledMissionDemandTag>
      )}
      {missionDemand?.transferredOfBusiness && (
        <StyledMissionDemandTag>
          {t('field.missionDemand.tag.transferredOfBusiness')}
        </StyledMissionDemandTag>
      )}
      {missionDemand?.acceptSecondment && (
        <StyledMissionDemandTag>
          {t('field.missionDemand.tag.acceptSecondment')}
        </StyledMissionDemandTag>
      )}
      {missionDemand?.sendEmployee && (
        <StyledMissionDemandTag>
          {t('field.missionDemand.tag.sendEmployee')}
        </StyledMissionDemandTag>
      )}
      {missionDemand?.furnishedOfficeRentalAvailability && (
        <StyledMissionDemandTag>
          {t('field.missionDemand.tag.furnishedOfficeRentalAvailability')}
        </StyledMissionDemandTag>
      )}
      {missionDemand?.furnishedOfficeRentalRequest && (
        <StyledMissionDemandTag>
          {t('field.missionDemand.tag.furnishedOfficeRentalRequest')}
        </StyledMissionDemandTag>
      )}
    </DetailCompanyField>
  );
};

export default DemandTagsField;
