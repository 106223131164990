import React, { useState, useCallback } from 'react';
import theme from 'styles/theme';
import styled, { css } from 'styled-components/macro';
import { ImageSelectorList } from 'components/molecules';
import { Images } from 'proto/v1/apimodel/apimodel';
import { imageSelector } from 'utils/imageSelector';

const ImageWrap = styled.div`
  position: relative;
  height: 323px;
`;

const Image = styled.div<{ imageSrc: string | undefined }>`
  position: absolute;
  top: 0;
  width: 483px;
  height: 323px;
  ${({ imageSrc }) =>
    imageSrc &&
    css`
      background-image: url(${imageSrc});
      background-repeat: no-repeat;
      background-size: contain;
      background-position: center;
      background-color: ${theme.baseWhite};
    `}
  transition: opacity 0.3s linear;
  opacity: 0;

  /* using additional class name to active transition animation.
   If using extended component, animation doesn't work by dom rendering */
  &.selected {
    opacity: 1;
  }
`;

const ImageSelectorListWrap = styled.div`
  margin-top: 12px;
`;

const ImageGallery = ({ images }: { images: (Images | undefined)[] }) => {
  const [selectedIndex, setSelectedIndex] = useState<number | null>(
    images.length > 0 ? 0 : null,
  );

  const handleSelectImage = useCallback(
    ({ index }: { index: number }) => {
      setSelectedIndex(index);
    },
    [setSelectedIndex],
  );

  return (
    <>
      <ImageWrap>
        {images.map((sourceImages, index) => {
          const imageUrl = imageSelector(sourceImages, 'large');
          return (
            <Image
              // eslint-disable-next-line react/no-array-index-key
              key={index}
              imageSrc={imageUrl}
              className={selectedIndex === index ? 'selected' : ''}
              data-testid="office-image"
            />
          );
        })}
      </ImageWrap>
      {/* show selectors only when registered multiple images */}
      {images.length > 1 && (
        <ImageSelectorListWrap>
          <ImageSelectorList
            imageUrlList={images.map(
              image => imageSelector(image, 'small') || '',
            )}
            onSelectImage={handleSelectImage}
          />
        </ImageSelectorListWrap>
      )}
    </>
  );
};
export default ImageGallery;
