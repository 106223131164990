import React, { FC, useMemo } from 'react';
import { Country, JapanPrefecture } from 'proto/v1/apimodel/apimodel';
import styled from 'styled-components/macro';
import { TableCell } from 'components/atoms';
import theme from 'styles/theme';
import { useTranslation } from 'react-i18next';

const StyledTableCell = styled(TableCell)`
  justify-content: center;
  padding: 24px 24px 24px 0;
`;

const MissionLink = styled.button`
  display: inline-block;
  width: 100%;
  height: 100%;
  padding: 0;
  border: none;
  outline: none;
  background: transparent;
  text-align: left;
  appearance: none;
  cursor: pointer;
`;

const NameWrapper = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 8px;
`;

const Name = styled.div`
  width: 100%;
`;

const CompanyName = styled.div`
  margin-bottom: 8px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const OtherInfo = styled.div`
  color: ${theme.textSecondary};
  font-size: 10px;
  line-height: 1.5;
`;

type MissionCellProps = {
  accountId: string;
  follower:
    | {
        missionId?: string;
        supporterId?: string;
        name: string;
        companyName: string;
        country: Country | undefined;
        japanPrefecture: JapanPrefecture | undefined;
      }
    | undefined;
  followedAt: number;
  onClick: (
    id: string,
    role: 'mission' | 'supporter',
    accountId: string,
  ) => void;
  cellWidth?: string;
};

const MissionCell: FC<MissionCellProps> = props => {
  const { accountId, follower, followedAt, onClick, cellWidth } = props;
  const { t, i18n } = useTranslation(['followersMissions']);
  const formattedDate = useMemo(() => {
    const isJa = i18n.language.startsWith('ja');
    const d = new Date(followedAt);
    const year = d.getFullYear();
    const month = isJa
      ? d.getMonth() + 1
      : d.toLocaleString(i18n.language, { month: 'short' });
    const date = d.getDate();
    const hours = d.getHours();
    const minutes = d.getMinutes().toString().padStart(2, '0');
    return isJa
      ? `${year} / ${month} / ${date} / ${hours}:${minutes}`
      : `${month} / ${date} / ${year} / ${hours}:${minutes}`;
  }, [followedAt, i18n.language]);

  if (!follower) return null;
  const address =
    follower?.country?.countryId === 'JPN' && follower.japanPrefecture?.name
      ? follower.japanPrefecture?.name
      : follower.country?.name;

  return (
    <StyledTableCell cellWidth={cellWidth}>
      <MissionLink
        type="button"
        onClick={() =>
          onClick(
            follower.missionId ?? follower.supporterId ?? '',
            follower.missionId ? 'mission' : 'supporter',
            accountId,
          )
        }
        data-testid="mission-link"
      >
        <NameWrapper>
          <Name>{follower.name}</Name>
        </NameWrapper>
        <CompanyName>{follower.companyName}</CompanyName>
        <OtherInfo>{`${t('cell.address')} : ${address} / ${t(
          'cell.contactDate',
        )} : ${formattedDate}`}</OtherInfo>
      </MissionLink>
    </StyledTableCell>
  );
};

export default MissionCell;
