import useUploadImage from '../../hooks/useUploadImage';
import useAddressField from '../../hooks/useAddressField';
import useCompanyEstablishedDateField from '../../hooks/useCompanyEstablishedDateField';
import useSupportAreasField from '../../hooks/useSupportAreasField';
import useSupporterMembersField from '../../hooks/useSupporterMembersField';
import useGalleryImagesField from '../../hooks/useGalleryImagesField';
import useSupporterFormState from './useSupporterFormState';
import useFetchSupporterForm from './useFetchSupporterForm';
import useSupporterFormFormik from './useSupporterFormFormik';
import useSupporterFormMenu from './useSupporterFormMenu';
import useSupporterFormFields from './useSupporterFormFields';
import useUpdateSupporter from './useUpdateSupporter';

const useSupporterForm = () => {
  const supporterFormState = useSupporterFormState();

  useFetchSupporterForm(supporterFormState);

  const formik = useSupporterFormFormik(supporterFormState);

  const {
    handleScroll,
    formError,
    saveInput,
    handleClickPreview,
    handleChangeChecked,
    handleChangeSelectedValueAsNumber,
    isPublishable,
    isDisabledSaveButton,
  } = useSupporterFormFields(supporterFormState, formik);

  const {
    openMenu,
    closeMenu,
    handleClickMenuUnPublish,
  } = useSupporterFormMenu(supporterFormState);

  const {
    updateSupporter,
    publishSupporter,
    unPublishSupporter,
  } = useUpdateSupporter(supporterFormState);

  const {
    handleExceedImageFileSize,
    handleCroppedImage,
    handleClickCropperCloseButton,
  } = useUploadImage({
    imageCropperState: supporterFormState.imageCropperState,
    openModal: supporterFormState.openModal,
    saveImageCropper: supporterFormState.saveImageCropper,
    closeImageCropper: supporterFormState.closeImageCropper,
    setFieldValue: formik.setFieldValue,
    setFieldTouched: formik.setFieldTouched,
  });

  const { handleChangeOtherAddress } = useAddressField({
    setFieldValue: formik.setFieldValue,
  });

  const { handleChangeCompanyEstablishedYear } = useCompanyEstablishedDateField(
    {
      companyEstablishedDate: formik.values.companyEstablishedDate,
      setFieldValue: formik.setFieldValue,
    },
  );

  const { handleChangeSupportArea } = useSupportAreasField({
    supportAreaIds: formik.values.supportAreaIds,
    setFieldValue: formik.setFieldValue,
    setFieldTouched: formik.setFieldTouched,
  });

  const {
    handleAddMember,
    handleDeleteMember,
    handleChangeSupporterMemberIconImage,
    supporterMembersError,
  } = useSupporterMembersField({
    supporterMembers: formik.values.supporterMembers,
    openImageCropper: supporterFormState.openImageCropper,
    getAddedItemId: supporterFormState.getAddedItemId,
    touched: formik.touched.supporterMembers,
    errors: formik.errors.supporterMembers,
    setFieldValue: formik.setFieldValue,
    setFieldTouched: formik.setFieldTouched,
  });

  const {
    handleAddGalleryImage,
    handleChangeGalleryImage,
    handleDeleteGalleryImage,
  } = useGalleryImagesField({
    galleryImages: formik.values.galleryImages,
    getAddedItemId: supporterFormState.getAddedItemId,
    openImageCropper: supporterFormState.openImageCropper,
    setFieldValue: formik.setFieldValue,
  });

  return {
    ...supporterFormState,
    formik,
    handleScroll,
    isPublishable,
    isDisabledSaveButton,
    formError,
    updateSupporter,
    publishSupporter,
    unPublishSupporter,
    saveInput,
    openMenu,
    closeMenu,
    handleClickMenuUnPublish,
    handleClickPreview,
    handleChangeChecked,
    handleChangeSelectedValueAsNumber,
    handleChangeOtherAddress,
    handleChangeCompanyEstablishedYear,
    handleExceedImageFileSize,
    handleCroppedImage,
    handleClickCropperCloseButton,
    handleChangeSupportArea,
    handleAddMember,
    handleDeleteMember,
    handleChangeSupporterMemberIconImage,
    supporterMembersError,
    handleAddGalleryImage,
    handleChangeGalleryImage,
    handleDeleteGalleryImage,
  };
};

export default useSupporterForm;
