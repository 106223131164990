import React, { FC } from 'react';
import styled from 'styled-components/macro';
import { useTranslation } from 'react-i18next';
import theme from 'styles/theme';
import typography from 'styles/typography';
import { MessageTemplate } from 'proto/v1/apimodel/apimodel';
import {
  Template,
  TemplateCompany,
  TemplateMember,
  TemplateSchool,
} from 'assets/svg';
import { IconMenuItem, ButtonM, ButtonS, Textarea } from 'components/atoms';
import {
  MessageTemplateFormModal,
  MessageTemplateSavedModal,
  ScoutConfirmModal,
} from 'components/organisms';
import { SCOUT_MESSAGE_MAX_LENGTH } from 'constants/models/scout';
import useScoutMessageForm from './useScoutMessageForm';

const ContentInner = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  margin-bottom: 40px;
  padding: 34px 20px 28px 26px;
  border-radius: 10px;
  background-color: ${theme.bgMain};
`;

const Note = styled.p`
  ${typography.textSub}
  margin-bottom: 12px;
  color: ${theme.textSecondary};
`;

const Form = styled.form`
  display: flex;
  flex: 1;
  flex-direction: column;
`;

const FormHead = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 12px;
`;

const SaveAsTemplateButton = styled(ButtonS)`
  padding-top: 5px;
  border: 1px solid ${theme.basePrimary};
  background: ${theme.bgMain};
  color: ${theme.basePrimary};

  &:hover {
    opacity: 0.8;
  }

  &:disabled {
    opacity: 0.4;
  }
`;

const InputCount = styled.div`
  align-self: flex-end;
  ${typography.textNote}
  color: ${theme.textSecondary};
`;

const ScoutMessageTextarea = styled(Textarea)`
  flex: 1;
  margin-bottom: 20px;

  textarea {
    min-height: 360px;
    resize: none;
  }
`;

const TemplateMenu = styled.div`
  display: flex;
  justify-content: space-between;
`;

const TemplateMenuLeft = styled.div`
  display: flex;
  gap: 32px;
`;

const TemplateMenuRight = styled.div`
  position: relative;
`;

const SubmitButtonContainer = styled.div`
  display: flex;
  justify-content: center;
`;

const TemplateContainer = styled.div`
  position: absolute;
  right: 13%;
  bottom: 200%;
  width: 226px;
  max-height: 165px;
  overflow-y: auto;
  background: ${theme.baseWhite};
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.17);
`;

const TemplateItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 40px;
  border-radius: 4px;

  &:hover {
    background: #f1f2f9;
  }
`;

const TemplateButton = styled.button.attrs({ type: 'button' })`
  display: inline-flex;
  border: 0;
  background-color: transparent;

  &:disabled {
    svg {
      fill: ${theme.baseDisabled};
    }

    div {
      cursor: default;
    }
  }
`;

type Props = {
  type: 'career' | 'newGraduate';
  onScout: (message: string) => Promise<void>;
  insertTexts: {
    memberName: string;
    companyName: string;
    schoolName: string;
  };
  onUseMessageTemplate: (messageTemplate: MessageTemplate) => Promise<string>;
  canSendScout?: boolean;
};

const ScoutMessageForm: FC<Props> = ({
  type,
  onScout,
  insertTexts,
  onUseMessageTemplate,
  canSendScout = true,
}) => {
  const {
    formik,
    messageTextareaRef,
    handleClickMemberNameMenu,
    handleClickSchoolNameMenu,
    handleClickCompanyNameMenu,
    handleClickTemplateMenu,
    handleSelectMessageTemplate,
    handleSaveMessageTemplate,
    showMessageTemplateFormModal,
    openMessageTemplateFormModal,
    closeMessageTemplateFormModal,
    showMessageTemplateSavedModal,
    closeMessageTemplateSavedModal,
    openScoutConfirmModal,
    showScoutConfirmModal,
    closeScoutConfirmModal,
    messageTemplates,
    showMessageTemplate,
    handleBlurMessageTemplate,
  } = useScoutMessageForm({
    onScout,
    insertTexts,
    onUseMessageTemplate,
  });
  const { handleSubmit, handleChange, handleBlur, isValid, values } = formik;
  const { t } = useTranslation(['messageTemplate', 'humanResource', 'common']);

  return (
    <>
      <Form onSubmit={handleSubmit} data-testid="scout-message-form">
        <ContentInner>
          <Note>
            {t('humanResource:scoutMessageForm.note', {
              lng: 'ja',
            })}
          </Note>

          <FormHead>
            <InputCount>
              {t('humanResource:scoutMessageForm.field.message.inputCount', {
                lng: 'ja',
                currentLength: values.message.length,
                maxLength: SCOUT_MESSAGE_MAX_LENGTH,
              })}
            </InputCount>
            <SaveAsTemplateButton
              type="button"
              disabled={!values.message || !isValid}
              onClick={openMessageTemplateFormModal}
            >
              {t('humanResource:scoutMessageForm.button.saveAsTemplate', {
                lng: 'ja',
              })}
            </SaveAsTemplateButton>
          </FormHead>

          <ScoutMessageTextarea
            ref={messageTextareaRef}
            maxLength={SCOUT_MESSAGE_MAX_LENGTH}
            name="message"
            value={values.message}
            placeholder={t(
              'humanResource:scoutMessageForm.field.message.placeholder',
              {
                lng: 'ja',
              },
            )}
            aria-label={t(
              'humanResource:scoutMessageForm.field.message.label',
              {
                lng: 'ja',
              },
            )}
            onChange={handleChange}
            onBlur={handleBlur}
          />

          <TemplateMenu>
            <TemplateMenuLeft>
              <IconMenuItem
                data-testid="insert-member-name"
                icon={<TemplateMember />}
                label={t('messageTemplate:menu.memberName', {
                  lng: 'ja',
                })}
                onClick={handleClickMemberNameMenu}
              />
              <IconMenuItem
                data-testid="insert-school-name"
                icon={<TemplateSchool />}
                label={t('messageTemplate:menu.schoolName', {
                  lng: 'ja',
                })}
                onClick={handleClickSchoolNameMenu}
              />
              {type === 'career' && (
                <IconMenuItem
                  data-testid="insert-company-name"
                  icon={<TemplateCompany />}
                  label={t('messageTemplate:menu.companyName', {
                    lng: 'ja',
                  })}
                  onClick={handleClickCompanyNameMenu}
                />
              )}
            </TemplateMenuLeft>
            <TemplateMenuRight>
              {showMessageTemplate && messageTemplates.length > 0 && (
                <TemplateContainer>
                  {messageTemplates.map(messageTemplate => (
                    <TemplateItem
                      data-testid="message-template-item"
                      key={messageTemplate.id}
                      onMouseDown={() =>
                        handleSelectMessageTemplate(messageTemplate)
                      }
                    >
                      {messageTemplate.name}
                    </TemplateItem>
                  ))}
                </TemplateContainer>
              )}
              <TemplateButton
                disabled={messageTemplates.length === 0}
                onClick={handleClickTemplateMenu}
                onBlur={handleBlurMessageTemplate}
                data-testid="template"
              >
                <IconMenuItem
                  icon={<Template />}
                  label={t('messageTemplate:menu.template', {
                    lng: 'ja',
                  })}
                />
              </TemplateButton>
            </TemplateMenuRight>
          </TemplateMenu>
        </ContentInner>
      </Form>

      <SubmitButtonContainer>
        <ButtonM
          onClick={openScoutConfirmModal}
          buttonTheme="default"
          disabled={!values.message || !isValid || !canSendScout}
        >
          {t('common:button.send', { lng: 'ja' })}
        </ButtonM>
      </SubmitButtonContainer>

      <ScoutConfirmModal
        show={showScoutConfirmModal}
        onClose={closeScoutConfirmModal}
        onBulkScout={handleSubmit}
      />

      <MessageTemplateFormModal
        show={showMessageTemplateFormModal}
        messageTemplate={{ template: values.message }}
        onClose={closeMessageTemplateFormModal}
        onSave={handleSaveMessageTemplate}
      />

      <MessageTemplateSavedModal
        show={showMessageTemplateSavedModal}
        onClose={closeMessageTemplateSavedModal}
      />
    </>
  );
};

export default ScoutMessageForm;
