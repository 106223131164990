import { Tab } from 'react-tabs';
import styled, { css } from 'styled-components/macro';
import theme from 'styles/theme';
import typography from 'styles/typography';

const tabUnderLine = css`
  &::after {
    content: '';
    display: block;
    height: 3px;
    margin: 0 auto;
    border-radius: 8px;
    background-color: ${theme.basePrimary};
  }
`;

const StyledTab = styled(Tab)`
  ${typography.textBold}
  ${tabUnderLine}
  display: inline-block;
  flex: 1;
  height: 48px;
  color: ${theme.textPrimary};
  line-height: 48px;
  text-align: center;
  cursor: pointer;

  &::after {
    width: 0;
  }

  &:hover {
    color: ${theme.basePrimary};
    ${tabUnderLine}

    &::after {
      width: 100%;
      transition: 0.3s;
    }
  }

  &:focus {
    outline: none;
  }

  &[aria-selected='true'] {
    color: ${theme.basePrimary};
    cursor: default;

    &::after {
      width: 100%;
    }
  }

  &[aria-disabled='true'] {
    color: ${theme.baseDisabled};
    cursor: default;
    pointer-events: none;
  }
`;

export default StyledTab;
