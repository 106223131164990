import React, { FC, useMemo } from 'react';
import { FooterContent } from '../../../Contents';
import useSupporterFormContext from '../../useSupporterFormContext';

const FooterContainer: FC = () => {
  const { openModal, isPublishable, footerWidth } = useSupporterFormContext();

  return useMemo(
    () => (
      <FooterContent
        onClick={() => openModal('publishMissionConfirmation')}
        disabled={!isPublishable}
        width={footerWidth}
      />
    ),
    [footerWidth, isPublishable, openModal],
  );
};

export default FooterContainer;
