import styled from 'styled-components/macro';
import { Textarea } from 'visits-style';
import theme from 'styles/theme';

const StyledTextarea = styled(Textarea)`
  textarea {
    min-height: 180px;
    border: 1px solid ${theme.borderDefault};
    font-size: 14px;
    line-height: 1.5;

    &:hover,
    &:focus,
    &:active {
      border-color: ${theme.borderDefault};
      box-shadow: none;
    }

    &::placeholder {
      color: ${theme.borderDefault};
    }
  }

  &:hover {
    textarea:not(:disabled):not(:focus) {
      border-color: ${theme.borderDefault};
    }
  }
`;

export default StyledTextarea;
