import styled, { css } from 'styled-components/macro';
import { FiChevronUp, FiChevronDown } from 'react-icons/fi';
import {
  Building,
  Monitor,
  ThumbsUp,
  MessageSquare,
  PaperAirplane,
  Mail,
  User,
} from 'assets/svg';

const iconStyle = css`
  position: relative;
  bottom: 2px;
`;

const AccordionIconStyle = css`
  position: absolute;
  top: 50%;
  right: 20px;
  transform: translateY(-50%);
`;

const accordionIconAttrs = {
  strokeWidth: 2,
  size: 16,
};

export const IconMissionList = styled(Building)`
  ${iconStyle}
`;

export const IconProductList = styled(Monitor)`
  ${iconStyle}
`;

export const IconSupporterList = styled(ThumbsUp)`
  ${iconStyle}
`;

export const IconHumanResource = styled(MessageSquare)`
  ${iconStyle}
`;

export const IconFollowerList = styled(PaperAirplane)`
  ${iconStyle}
`;

export const IconChat = styled(Mail)`
  ${iconStyle}
`;

export const IconMyPage = styled(User)`
  ${iconStyle}
`;

export const AccordionIconUp = styled(FiChevronUp).attrs(accordionIconAttrs)`
  ${AccordionIconStyle}
`;

export const AccordionIconDown = styled(FiChevronDown).attrs(
  accordionIconAttrs,
)`
  ${AccordionIconStyle}
`;
