import React, { FC, HTMLAttributes } from 'react';
import styled, { FlattenSimpleInterpolation } from 'styled-components/macro';
import { Delete, ErrorMessage } from 'components/atoms';

const Container = styled.div`
  width: fit-content;
`;

const DeleteWrap = styled.div`
  display: flex;
  visibility: hidden;
  justify-content: flex-end;
  height: 32px;

  /* stylelint-disable-next-line selector-type-no-unknown */
  ${Container}:hover & {
    visibility: visible;
  }
`;

const BlockErrorMessage = styled(ErrorMessage)<{
  customStyle?: FlattenSimpleInterpolation;
}>`
  padding: 0 0 10px 14px;
  ${({ customStyle }) => customStyle}
`;

type Props = {
  onDelete: () => void;
  errorMessage?: string;
  errorMessageStyle?: FlattenSimpleInterpolation;
} & HTMLAttributes<HTMLDivElement>;

const DeletableBlock: FC<Props> = ({
  onDelete,
  children,
  errorMessage,
  errorMessageStyle,
  ...rest
}) => {
  return (
    <Container {...rest}>
      <div>{children}</div>
      <DeleteWrap>
        <Delete onClick={() => onDelete()} />
      </DeleteWrap>
      {errorMessage && (
        <BlockErrorMessage customStyle={errorMessageStyle}>
          {errorMessage}
        </BlockErrorMessage>
      )}
    </Container>
  );
};

export default DeletableBlock;
