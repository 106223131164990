import styled from 'styled-components/macro';
import { FormItem } from 'components/molecules';

const MissionFormItem = styled(FormItem)`
  &&& {
    margin-bottom: 48px;
  }
`;

export default MissionFormItem;
