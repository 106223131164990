import React, { FC, ChangeEvent } from 'react';
import styled from 'styled-components/macro';
import { useTranslation } from 'react-i18next';
import { MissionProductFormValue } from 'types/form';
import { MissionSettingMaxLength } from 'constants/form';
import { isEqualProps } from 'utils/object';
import { Textarea, Radio } from 'components/atoms';
import {
  FormItemSubHeading,
  MissionSettingFormItem,
} from 'components/molecules';

const RadioSet = styled.div`
  display: flex;
  margin-bottom: 16px;

  /* stylelint-disable-next-line selector-type-no-unknown */
  ${Radio}:not(:last-child) {
    margin-right: 96.5px;
  }

  /* stylelint-disable-next-line selector-type-no-unknown */
  ${Radio}:last-child label {
    margin-right: 0;
  }
`;

const MemberBenefitTextarea = styled(Textarea)`
  textarea {
    width: 445px;
    height: 94px;
    min-height: 94px;
  }
`;

type MemberBenefitFieldProps = {
  index: number;
  values: Pick<MissionProductFormValue, 'hasMemberBenefit' | 'memberBenefit'>;
  error: string | undefined;
  onChangeHasMemberBenefit: (e: ChangeEvent<HTMLInputElement>) => void;
  onChangeMemberBenefit: (e: ChangeEvent<HTMLTextAreaElement>) => void;
  onBlurMemberBenefit: (e: any) => void;
};

const MemberBenefitField: FC<MemberBenefitFieldProps> = props => {
  const {
    index,
    values,
    error,
    onChangeHasMemberBenefit,
    onChangeMemberBenefit,
    onBlurMemberBenefit,
  } = props;

  const { t } = useTranslation('missionSetting');

  return (
    <MissionSettingFormItem error={error}>
      <FormItemSubHeading
        label={t('field.missionProducts.subField.memberBenefit.label')}
        note={t('field.missionProducts.subField.memberBenefit.note', {
          maxLength: MissionSettingMaxLength.MISSION_PRODUCT_MEMBER_BENEFIT,
        })}
      />
      <RadioSet>
        <Radio
          name={`missionProducts[${index}].hasMemberBenefit`}
          value={1}
          checked={values.hasMemberBenefit === true}
          onChange={onChangeHasMemberBenefit}
          aria-label={t(
            'field.missionProducts.subField.memberBenefit.radio.hasMemberBenefit',
          )}
        >
          {t(
            'field.missionProducts.subField.memberBenefit.radio.hasMemberBenefit',
          )}
        </Radio>
        <Radio
          name={`missionProducts[${index}].hasMemberBenefit`}
          value={0}
          checked={values.hasMemberBenefit === false}
          onChange={onChangeHasMemberBenefit}
          aria-label={t(
            'field.missionProducts.subField.memberBenefit.radio.notHasMemberBenefit',
          )}
        >
          {t(
            'field.missionProducts.subField.memberBenefit.radio.notHasMemberBenefit',
          )}
        </Radio>
      </RadioSet>
      {values.hasMemberBenefit === true && (
        <MemberBenefitTextarea
          maxLength={MissionSettingMaxLength.MISSION_PRODUCT_MEMBER_BENEFIT}
          name={`missionProducts[${index}].memberBenefit`}
          value={values.memberBenefit}
          placeholder={t(
            'field.missionProducts.subField.memberBenefit.placeholder',
          )}
          aria-label={t('field.missionProducts.subField.memberBenefit.label')}
          onChange={onChangeMemberBenefit}
          onBlur={onBlurMemberBenefit}
        />
      )}
    </MissionSettingFormItem>
  );
};

export default React.memo(MemberBenefitField, (prevProps, nextProps) =>
  isEqualProps(prevProps, nextProps, ['values', 'error']),
);
