import { ButtonHTMLAttributes } from 'react';
import styled from 'styled-components/macro';
import buttonThemes, { ThemesType } from './buttonThemes';

type BaseButtonType = {
  buttonTheme: ThemesType;
} & ButtonHTMLAttributes<HTMLButtonElement>;

const BaseButton = styled.button<BaseButtonType>`
  border: none;
  border-radius: 8px;
  outline: none;
  cursor: pointer;

  &:disabled {
    cursor: default;
  }

  ${({ buttonTheme }) => buttonThemes[buttonTheme]}
`;

export default BaseButton;
