import { Mission } from 'proto/v1/apimodel/apimodel';
import { MissionForm, MissionFormValues } from 'types/mission';
import {
  convertCountryFormValue,
  convertJapanPrefectureFormValue,
  convertMemberRangeFormValue,
  convertFundFormValue,
  convertMissionCategoriesFormValue,
  convertMissionDemandFormValue,
  convertMissionMembersFormValue,
  convertMissionProductsFormValue,
  convertMissionOfficeImagesFormValue,
  convertMissionJobOffersFormValue,
} from '../convertFromFormValue';

const convertMissionFormValuesToMission: (
  values: MissionFormValues,
  form: MissionForm,
  context: { corporateAttributes: Mission['corporateAttributes'] },
) => Mission = (values, form, context) => {
  return {
    missionId: values.missionId,
    name: values.name,
    missionDescription: values.missionDescription,
    iconImage: values.iconImage,
    coverImage: values.coverImage,
    strengthPoint: values.strengthPoint,
    companyName: values.companyName,
    companyEstablishedDate: values.companyEstablishedDate,
    companyUrl: values.companyUrl,
    country: convertCountryFormValue(values.countryId, form.countries),
    japanPrefecture: convertJapanPrefectureFormValue(
      values.japanPrefectureId,
      form.japanPrefectures,
    ),
    otherAddress: values.otherAddress,
    memberRange: convertMemberRangeFormValue(
      values.memberRangeId,
      form.memberRanges,
    ),
    fund: convertFundFormValue(values.fundId, form.funds),
    missionCategories: convertMissionCategoriesFormValue(
      values.missionCategories,
      form.missionCategories,
    ),
    missionDemand: convertMissionDemandFormValue(
      values.missionDemand,
      form.financingSeries,
      form.funds,
    ),
    missionMembers: convertMissionMembersFormValue(values.missionMembers),
    missionProducts: convertMissionProductsFormValue(values.missionProducts),
    missionOfficeImages: convertMissionOfficeImagesFormValue(
      values.missionOfficeImages,
    ),
    missionJobOffers: convertMissionJobOffersFormValue(
      values.missionJobOffers,
      form.occupations,
    ),
    corporateAttributes: context.corporateAttributes,
    language: '',
    matchPercentage: 0,
    degreeOfAttention: 0,
  };
};

export default convertMissionFormValuesToMission;
