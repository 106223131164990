import React, { FC, ComponentProps } from 'react';
import { Tooltip } from 'components/atoms';
import { IoMdInformationCircle } from 'react-icons/io';
import styled from 'styled-components/macro';
import theme from 'styles/theme';

const Icon = styled(IoMdInformationCircle)`
  width: 16px;
  height: 16px;
  margin-right: 2px;
  color: ${theme.textSecondary};
  cursor: pointer;
`;

type TooltipProps = ComponentProps<typeof Tooltip>;

type InformationIconProps = { description: string } & Partial<TooltipProps>;

const InformationIcon: FC<InformationIconProps> = ({
  description,
  position = 'top',
}) => {
  return (
    <Tooltip label={description} position={position}>
      <Icon />
    </Tooltip>
  );
};

export default InformationIcon;
