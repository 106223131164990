export const IMAGE_FILE_SIZE_LIMIT = 2000;
export const CHATROOM_GET_COUNT = 20;
export const MESSAGE_GET_COUNT = 20;
export const MISSION_COMPANY_ESTABLISHED_YEAR_MIN = 1870;
export const MISSION_COMPANY_ESTABLISHED_YEAR_INITIAL_VALUE = 2010;
export const MISSION_COMPANY_ESTABLISHED_MONTH_INITIAL_VALUE = 1;
export const MISSION_CATEGORIES_MAX_COUNT = 3;
export const MEMBERS_MAX_COUNT = 6;
export const MISSION_PRODUCTS_MAX_COUNT = 3;
export const MISSION_PRODUCT_PRODUCT_TAGS_MAX_COUNT = 3;
export const MISSION_JOB_OFFERS_MAX_COUNT = 50;
export const MISSION_OFFICE_IMAGES_MAX_COUNT = 6;
export const GALLERY_IMAGES_MAX_COUNT = 6;
export const ICON_IMAGE_ASPECT_RATIO = 1 / 1;
export const COVER_IMAGE_ASPECT_RATIO = 16 / 9;
export const OFFICE_IMAGE_ASPECT_RATIO = 3 / 2;
export const FOLLOWERS_LIMIT = 10;
export const MISSION_MAX_COUNT = 10;
export const WORK_LOCATION_OVERSEAS_ID = 99;
export const SUGGEST_INTERVAL_MS = 1000;
