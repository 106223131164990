import styled from 'styled-components/macro';
import theme from 'styles/theme';
import typography from 'styles/typography';

const HumanResourceCardBodyItemLabel = styled.div`
  ${typography.textSub};
  display: flex;
  align-items: center;
  width: 128px;
  min-width: 128px;
  color: ${theme.textSecondary};
  font-weight: bold;
  word-wrap: break-word;
  white-space: pre-wrap;
  overflow-wrap: break-word;
`;

export default HumanResourceCardBodyItemLabel;
