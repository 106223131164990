import { ChangeEvent, useCallback, useEffect } from 'react';
import { useFormik } from 'formik';
import { JapanPrefecture } from 'proto/v1/apimodel/apimodel';
import { AddressPickerValues, AddressFilterValues } from 'types/mission';

const useAddressPicker = ({
  form,
  onSubmit,
  defaultValues,
  isSelected,
}: {
  onSubmit: (values: AddressPickerValues) => void;
  form: { japanPrefectures: JapanPrefecture[] | undefined };
  defaultValues: AddressFilterValues;
  isSelected: boolean;
}) => {
  const allJapanPrefectureIds = form.japanPrefectures
    ? form.japanPrefectures.map(v => v.japanPrefectureId)
    : [];

  const isCheckedAllDefaultJapanPrefectureIds =
    defaultValues.japanPrefectureIds.length === allJapanPrefectureIds.length;

  const formik = useFormik<AddressPickerValues>({
    initialValues: {
      checkAllJapanPrefectures: isCheckedAllDefaultJapanPrefectureIds,
      countryId: defaultValues.countryId || 'JPN',
      japanPrefectureIds: defaultValues.japanPrefectureIds,
    },
    onSubmit,
    enableReinitialize: true,
  });

  const { values, setFieldValue, resetForm } = formik;

  const handleChangeJapanPrefecture = useCallback(
    (event: ChangeEvent<HTMLInputElement>): void => {
      const { target } = event;
      const targetValue = Number(target.value);
      const { japanPrefectureIds } = values;
      if (target.checked) {
        setFieldValue('japanPrefectureIds', [
          ...japanPrefectureIds,
          targetValue,
        ]);
      } else {
        setFieldValue(
          'japanPrefectureIds',
          japanPrefectureIds.filter(
            japanPrefectureId => japanPrefectureId !== targetValue,
          ),
        );
      }
    },
    [setFieldValue, values],
  );

  const handleChangeCheckAllJapanPrefectures = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const { checked } = event.currentTarget;
      setFieldValue('japanPrefectureIds', checked ? allJapanPrefectureIds : []);
      setFieldValue('checkAllJapanPrefectures', checked);
    },
    [allJapanPrefectureIds, setFieldValue],
  );

  useEffect(() => {
    if (!isSelected) resetForm();
  }, [isSelected, resetForm]);

  return {
    formik,
    handleChangeJapanPrefecture,
    handleChangeCheckAllJapanPrefectures,
  };
};

export default useAddressPicker;
