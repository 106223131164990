import {
  Mission,
  MissionMember,
  MissionDemand,
  MissionCategory,
  MissionOfficeImage,
  MissionProduct,
  CorporateAttribute,
  MissionDemandCollaborationAsset,
} from 'proto/v1/apimodel/apimodel';
import {
  MissionProductTagFormValue,
  MissionProductFormValue,
  MissionDemandFormValue,
} from 'types/form';
import { imageSelector } from 'utils/imageSelector';
import { CorporateAttributeIds } from 'constants/models/mission';

export const isEmptyCollaborationAsset = (
  asset: MissionDemandCollaborationAsset,
) => asset.give === '' && asset.want === '';

export const isEmptyMissionMember = (member: MissionMember) =>
  member.name === '' &&
  member.career === '' &&
  !imageSelector(member.iconImage);

export const isFullInfoMissionMember = (member: MissionMember) =>
  member.name !== '' && member.career !== '' && imageSelector(member.iconImage);

export const isEmptyOfficeImage = (officeImage: MissionOfficeImage) =>
  !imageSelector(officeImage.image);

export const isEmptyMissionProductTags = (
  tags: (string | MissionProductTagFormValue)[],
) =>
  tags.length === 0 ||
  tags.every(tag => (typeof tag === 'object' ? tag.tag === '' : tag === ''));

export const isEmptyMissionProduct = (
  missionProduct: MissionProduct | MissionProductFormValue,
) =>
  missionProduct.name === '' &&
  missionProduct.overview === '' &&
  missionProduct.url === '' &&
  missionProduct.problem === '' &&
  missionProduct.solution === '' &&
  missionProduct.memberBenefit === '' &&
  !missionProduct.productTarget?.isForBusiness &&
  !missionProduct.productTarget?.isForCustomer &&
  !imageSelector(missionProduct.image) &&
  isEmptyMissionProductTags(missionProduct.tags);

export const isSelectedDemandInvestFinancingSeries = (
  missionDemand: Mission['missionDemand'],
) =>
  !!missionDemand?.invest?.financingSeries &&
  missionDemand.invest.financingSeries.length > 0;

export const isSelectedDemandFundraisingFinancingSeries = (
  missionDemand: Mission['missionDemand'],
) =>
  !!missionDemand?.fundraising?.financingSeries &&
  missionDemand.fundraising.financingSeries.seriesId !== 0;

export const isEmptyMissionDemand = (
  missionDemand: MissionDemand | undefined,
) => {
  if (!missionDemand) return true;

  const {
    collaboration,
    invest,
    serviceInformation,
    serviceSuggestion,
    transferOfBusiness,
    transferredOfBusiness,
    acceptSecondment,
    sendEmployee,
    furnishedOfficeRentalAvailability,
    furnishedOfficeRentalRequest,
  } = missionDemand;

  // collaboration
  if (collaboration) {
    return false;
  }
  // invest
  if (invest && invest.financingSeries.length > 0) {
    return false;
  }
  // fundraising
  if (isSelectedDemandFundraisingFinancingSeries(missionDemand)) {
    return false;
  }
  // serviceInformation
  if (serviceInformation) {
    return false;
  }
  // serviceSuggestion
  if (serviceSuggestion) {
    return false;
  }
  // transferOfBusiness
  if (transferOfBusiness) {
    return false;
  }
  // transferredOfBusiness
  if (transferredOfBusiness) {
    return false;
  }
  // acceptSecondment
  if (acceptSecondment) {
    return false;
  }
  // sendEmployee
  if (sendEmployee) {
    return false;
  }
  // furnishedOfficeRentalAvailability
  if (furnishedOfficeRentalAvailability) {
    return false;
  }
  // furnishedOfficeRentalRequest
  if (furnishedOfficeRentalRequest) {
    return false;
  }

  return true;
};

export const isSelectedMissionDemand = (
  missionDemand: MissionDemandFormValue,
) => {
  if (!missionDemand) return false;

  const {
    wantCollaboration,
    wantInvest,
    wantInvestSeed,
    wantInvestEarly,
    wantInvestMiddle,
    wantInvestLater,
    wantFundraising,
    fundraising,
    wantServiceSuggestion,
    wantServiceInformation,
    wantTransferOfBusiness,
    wantTransferredOfBusiness,
    wantAcceptSecondment,
    wantSendEmployee,
    wantFurnishedOfficeRentalAvailability,
    wantFurnishedOfficeRentalRequest,
  } = missionDemand;

  return (
    wantCollaboration ||
    (wantFundraising && fundraising && fundraising.financingSeriesId > 0) ||
    (wantInvest &&
      (wantInvestSeed ||
        wantInvestEarly ||
        wantInvestMiddle ||
        wantInvestLater)) ||
    wantServiceSuggestion ||
    wantServiceInformation ||
    wantTransferOfBusiness ||
    wantTransferredOfBusiness ||
    wantAcceptSecondment ||
    wantSendEmployee ||
    wantFurnishedOfficeRentalAvailability ||
    wantFurnishedOfficeRentalRequest
  );
};

export const sortedMissionCategories = (missionCategories: MissionCategory[]) =>
  [...missionCategories].sort((a, b) => a.displayOrder - b.displayOrder);

export const isCompanyCorporateAttribute = (
  corporateAttributes?: CorporateAttribute[],
) =>
  corporateAttributes && corporateAttributes?.length
    ? [
        CorporateAttributeIds.STARTUP,
        CorporateAttributeIds.ENTERPRISE,
        CorporateAttributeIds.INVESTOR,
      ].includes(corporateAttributes[0].id)
    : false;
