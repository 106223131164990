import React, { FC, CSSProperties } from 'react';
import styled from 'styled-components/macro';
import theme from 'styles/theme';

const StyledWall = styled.div`
  margin-bottom: 24px;
  padding: 24px;
  border-radius: 8px;
  background: ${(props: WallProps) => theme[props.color]};
  box-shadow: 0 3px 8px rgba(0, 0, 0, 0.15);
`;

type WallProps = {
  color: string;
  style?: CSSProperties;
};

const Wall: FC<WallProps> = props => {
  return <StyledWall {...props}>{props.children}</StyledWall>;
};

export default Wall;
