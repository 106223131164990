import React, { Component, ErrorInfo } from 'react';
import logger from 'external/logger';

type ErrorState = {
  error: boolean;
};

class ErrorBoundary extends Component<any, ErrorState> {
  public state: ErrorState = { error: false };

  // TODO: VI-830 should be replaced by hook
  public static getDerivedStateFromError(error: any) {
    return { error: true };
  }

  public componentDidMount() {
    window.onunhandledrejection = (e: PromiseRejectionEvent) => {
      logger.error(`[ErrorBoundary:onunhandledrejection] ${e.reason}`);
    };
  }

  public componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    logger.exception(error, { extra: errorInfo });
  }

  public render() {
    return (
      <>
        {this.state.error && (
          <>
            {/* VI-2112 エラーダイアログを出していたが、ユーザとしては何も対処できないので出さないように変更した */}
            {/* https://visitstech.slack.com/archives/C012AV1QDT6/p1587457564004600 */}
          </>
        )}
        {this.props.children}
      </>
    );
  }
}
export default ErrorBoundary;
