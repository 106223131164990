import React, { FC } from 'react';
import styled from 'styled-components/macro';
import theme from 'styles/theme';
import typography from 'styles/typography';

const StyledBadge = styled.span`
  ${typography.labelBold}
  display: inline-block;
  min-width: 26px;
  height: 20px;
  padding: 4px 8px;
  border-radius: 18px;
  background: ${theme.labelBadge};
  color: ${theme.baseWhite};
  text-align: center;
`;

type BadgeProps = {
  count: number;
};

const Badge: FC<BadgeProps> = props => (
  <StyledBadge {...props}>
    {props.count > 999 ? '999 +' : props.count}
  </StyledBadge>
);

export default Badge;
