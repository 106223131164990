import { CancelToken } from 'axios';
import {
  GetMatterRequest,
  GetMatterResponse,
  GetMattersResponse,
  GetMatterSearchConditionsRequest,
  GetMatterSearchConditionsResponse,
  GetSkillsRequest,
  GetSkillsResponse,
  PutMatterViewedRequest,
  PutMatterViewedResponse,
  GetSchoolsRequest,
  GetSchoolsResponse,
  GetMattersTotalCountRequest,
  GetMattersTotalCountResponse,
  PutMatterFavoriteRequest,
  PutMatterFavoriteResponse,
} from 'proto/v1/commonservice/commonservice';
import { GetMattersRequestQuery } from 'types/supporter';
import {
  BulkScoutMatterRequest,
  BulkScoutMatterResponse,
  GetMattersForBulkScoutRequest,
  GetMattersForBulkScoutResponse,
  ScoutMatterRequest,
  ScoutMatterResponse,
} from 'proto/v1/matterservice/matterservice';
import client from './config/client';
import apiFirebase from '../firebase/firebase';

export default {
  async getMatters(
    req: GetMattersRequestQuery,
    cancelToken?: CancelToken,
  ): Promise<{ data: GetMattersResponse }> {
    const token = await apiFirebase.currentToken();
    const url = `${process.env.REACT_APP_API_URL}/v1/search_matters`;
    return client(token)
      .post(url, req, {
        cancelToken,
      })
      .then(x => {
        return {
          data: GetMattersResponse.fromJSON(x.data),
        };
      });
  },

  async getMatter(
    req: GetMatterRequest,
    cancelToken?: CancelToken,
  ): Promise<{ data: GetMatterResponse }> {
    const token = await apiFirebase.currentToken();
    const url = `${process.env.REACT_APP_API_URL}/v1/matters/${req.matterId}`;
    return client(token)
      .get(url, { cancelToken })
      .then(x => {
        return {
          data: GetMatterResponse.fromJSON(x.data),
        };
      });
  },

  async scoutMatter(
    req: ScoutMatterRequest,
  ): Promise<{ data: ScoutMatterResponse }> {
    const token = await apiFirebase.currentToken();
    const url = `${process.env.REACT_APP_API_URL}/v1/matter/scout`;
    return client(token)
      .post(url, req)
      .then(x => {
        return {
          data: ScoutMatterResponse.fromJSON(x.data),
        };
      });
  },

  async getMatterSearchConditions(
    req: GetMatterSearchConditionsRequest,
    cancelToken?: CancelToken,
  ): Promise<{ data: GetMatterSearchConditionsResponse }> {
    const token = await apiFirebase.currentToken();
    const url = `${process.env.REACT_APP_API_URL}/v1/search_conditions/matters`;
    return client(token)
      .get(url, { params: req, cancelToken })
      .then(x => {
        return {
          data: GetMatterSearchConditionsResponse.fromJSON(x.data),
        };
      });
  },

  async getSkills(
    req: GetSkillsRequest,
    cancelToken?: CancelToken,
  ): Promise<{ data: GetSkillsResponse }> {
    const token = await apiFirebase.currentToken();
    const url = `${process.env.REACT_APP_API_URL}/v1/search_skills`;
    return client(token)
      .post(url, req, { cancelToken })
      .then(x => {
        return {
          data: GetSkillsResponse.fromJSON(x.data),
        };
      });
  },

  async getSchools(
    req: GetSchoolsRequest,
    cancelToken?: CancelToken,
  ): Promise<{ data: GetSchoolsResponse }> {
    const token = await apiFirebase.currentToken();
    const url = `${process.env.REACT_APP_API_URL}/v1/search_schools`;
    return client(token)
      .post(url, req)
      .then(x => {
        return {
          data: GetSchoolsResponse.fromJSON(x.data),
        };
      });
  },

  async putMatterViewed(
    req: PutMatterViewedRequest,
    cancelToken?: CancelToken,
  ): Promise<{ data: PutMatterViewedResponse }> {
    const token = await apiFirebase.currentToken();
    const url = `${process.env.REACT_APP_API_URL}/v1/matters/viewed`;
    return client(token)
      .put(url, req, { cancelToken })
      .then(x => {
        return {
          data: PutMatterViewedResponse.fromJSON(x.data),
        };
      });
  },

  async putMatterFavorite(
    req: PutMatterFavoriteRequest,
    cancelToken?: CancelToken,
  ): Promise<{ data: PutMatterFavoriteResponse }> {
    const token = await apiFirebase.currentToken();
    const url = `${process.env.REACT_APP_API_URL}/v1/matters/favorite`;
    return client(token)
      .put(url, req, { cancelToken })
      .then(x => {
        return {
          data: PutMatterFavoriteResponse.fromJSON(x.data),
        };
      });
  },

  async getMattersTotalCount(
    req: GetMattersTotalCountRequest,
    cancelToken?: CancelToken,
  ): Promise<{ data: GetMattersTotalCountResponse }> {
    const token = await apiFirebase.currentToken();
    const url = `${process.env.REACT_APP_API_URL}/v1/search_matters/total_count`;
    return client(token)
      .post(url, req, {
        cancelToken,
      })
      .then(x => {
        return {
          data: GetMattersTotalCountResponse.fromJSON(x.data),
        };
      });
  },

  async getMattersForBulkScout(
    req: GetMattersForBulkScoutRequest,
    cancelToken?: CancelToken,
  ): Promise<{ data: GetMattersForBulkScoutResponse }> {
    const token = await apiFirebase.currentToken();
    const url = `${process.env.REACT_APP_API_URL}/v1/matters_for_bulk_scout`;
    return client(token)
      .post(url, req, {
        cancelToken,
      })
      .then(x => {
        return {
          data: GetMattersForBulkScoutResponse.fromJSON(x.data),
        };
      });
  },

  async bulkScoutMatter(
    req: BulkScoutMatterRequest,
  ): Promise<{ data: BulkScoutMatterResponse }> {
    const token = await apiFirebase.currentToken();
    const url = `${process.env.REACT_APP_API_URL}/v1/matter/bulk_scout`;
    return client(token)
      .post(url, req)
      .then(x => {
        return {
          data: BulkScoutMatterResponse.fromJSON(x.data),
        };
      });
  },
};
