export enum AttentionDegreeValues {
  INCREASED = 1,
  DECREASED = -1,
  NOT_CHANGED = 0,
}

export enum DemandId {
  COLLABORATION = 1,
  FUNDRAISING = 2,
  INVEST = 3,
  SERVICE_INFORMATION = 4,
  SERVICE_SUGGESTION = 5,
  TRANSFER_OF_BUSINESS = 6,
  TRANSFERRED_OF_BUSINESS = 7,
  ACCEPT_SECONDMENT = 8,
  SEND_EMPLOYEE = 9,
  FURNISHED_OFFICE_RENTAL_AVAILABILITY = 10,
  FURNISHED_OFFICE_RENTAL_REQUEST = 11,
}

export enum JapanPrefectureIds {
  TOKYO = 1,
}

export enum CorporateAttributeIds {
  STARTUP = 1,
  ENTERPRISE = 2,
  INVESTOR = 3,
  PUBLIC = 5,
  RESEARCH = 6,
  OTHERS = 7,
}

export enum FinancingSeriesIds {
  NA = 0,
  SEED = 1,
  EARLY = 2,
  MIDDLE = 3,
  LATER = 4,
}

export enum FundIds {
  NA = 0,
  // No other values are used as constants
}

export const MISSION_FORM_STEP = {
  COMPANY: 1,
  ABOUT_MISSION: 2,
  MISSION_PRODUCTS: 3,
  MISSION_JOB_OFFERS: 4,
  MISSION_DEMAND: 5,
} as const;

export const MISSION_USE_PLAN_ID = {
  OPEN_INNOVATION: 1,
  CAREER: 2,
  NEW_GRADUATE_SUBSCRIPTION: 3,
  NEW_GRADUATE_PAY_FOR_PERFORMANCE: 4,
} as const;
