import { useState, useCallback, useEffect, useMemo } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import * as PROTECTED_ROUTES from 'routes/constants/protected';
import {
  getIsSidebarShrinked,
  getIsScoutFormOpened,
  UiActions,
} from 'modules/ui';
import {
  getCanUseMissionMenu,
  getCanUseCareerHumanResourceMenu,
  getCanUseNewGraduateHumanResourceMenu,
  getCanUseFollowerMenu,
  getActiveAccount,
  getMissionUsePlans,
  getCanUseFollowingHumanResources,
} from 'modules/account';
import { getIsSwitching } from 'modules/myAccounts';
import { getNotification } from 'modules/notification';
import { useSelector, useDispatch } from 'react-redux';
import useLogout from 'hooks/useLogout';
import useContactModal from 'hooks/useContactModal';
import { getInitialPrivateRoute } from 'utils/models/account';

const shouldBeMyPageAccordionOpened = (pathname: string) =>
  [
    PROTECTED_ROUTES.PRIVATE_SETTINGS_MISSION,
    PROTECTED_ROUTES.PRIVATE_SETTINGS_ACCOUNT,
    PROTECTED_ROUTES.PRIVATE_FOLLOWING_MISSIONS,
    PROTECTED_ROUTES.PRIVATE_FOLLOWING_HUMAN_RESOURCES,
  ].includes(pathname);

const useSidebar = () => {
  const dispatch = useDispatch();
  const location = useLocation<{
    from?: string;
  }>();
  const history = useHistory();
  const [isMyPageAccordionOpened, setIsMyPageAccordionOpened] = useState<
    boolean
  >(shouldBeMyPageAccordionOpened(location.pathname));

  const { handleLogoutWithRedirectToLogin } = useLogout();
  const { showContactModal, handleToggleContactModal } = useContactModal();
  const [showLogoutModal, setShowLogoutModal] = useState<boolean>(false);
  const { contactNotifications, messageNotifications } = useSelector(
    getNotification,
  );

  const isSidebarShrinked = useSelector(getIsSidebarShrinked);
  const isScoutFormOpened = useSelector(getIsScoutFormOpened);
  const isSwitching = useSelector(getIsSwitching);

  const activeAccount = useSelector(getActiveAccount);
  const missionUsePlans = useSelector(getMissionUsePlans);

  const canUseMissionMenu = useSelector(getCanUseMissionMenu);
  const canUseCareerHumanResourceMenu = useSelector(
    getCanUseCareerHumanResourceMenu,
  );
  const canUseNewGraduateHumanResourceMenu = useSelector(
    getCanUseNewGraduateHumanResourceMenu,
  );
  const canUseFollowerMenu = useSelector(getCanUseFollowerMenu);
  const canUseFollowingHumanResources = useSelector(
    getCanUseFollowingHumanResources,
  );

  const totalMessagesCount: number = messageNotifications.reduce(
    (prev, current) => prev + current.count,
    0,
  );

  const handleToggleLogoutModal = useCallback(() => {
    setShowLogoutModal(!showLogoutModal);
  }, [showLogoutModal]);

  const handleClickMenuItemLink = useCallback(() => {
    if (isSidebarShrinked) dispatch(UiActions.setIsSidebarShrinked(false));
  }, [dispatch, isSidebarShrinked]);

  const handleClickHumanResourcesMenu = useCallback(() => {
    if (isSidebarShrinked) {
      if (!isScoutFormOpened) {
        dispatch(UiActions.setIsSidebarShrinked(false));
        history.push(
          canUseCareerHumanResourceMenu
            ? PROTECTED_ROUTES.PRIVATE_HUMAN_RESOURCES_CAREER
            : PROTECTED_ROUTES.PRIVATE_HUMAN_RESOURCES_NEW_GRADUATE,
        );
      }
    }
  }, [
    isSidebarShrinked,
    isScoutFormOpened,
    dispatch,
    history,
    canUseCareerHumanResourceMenu,
  ]);

  const handleClickMyPageMenu = useCallback(() => {
    if (isSidebarShrinked) {
      dispatch(UiActions.setIsSidebarShrinked(false));
      setIsMyPageAccordionOpened(true);
      history.push(PROTECTED_ROUTES.PRIVATE_FOLLOWING_MISSIONS);
    } else {
      setIsMyPageAccordionOpened(isOpened => !isOpened);
    }
  }, [dispatch, history, isSidebarShrinked]);

  const logoRoute = useMemo(() => {
    return getInitialPrivateRoute({
      mission: activeAccount?.mission,
      supporter: activeAccount?.supporter,
      missionUsePlans,
    });
  }, [activeAccount, missionUsePlans]);

  // Close accordion when this sidebar is shrinking.
  useEffect(() => {
    if (isSidebarShrinked) {
      setIsMyPageAccordionOpened(false);
    }
  }, [isSidebarShrinked]);

  return {
    isSidebarShrinked,
    location,
    isMyPageAccordionOpened,
    handleLogoutWithRedirectToLogin,
    showContactModal,
    handleToggleContactModal,
    contactNotifications,
    totalMessagesCount,
    showLogoutModal,
    handleToggleLogoutModal,
    handleClickMenuItemLink,
    handleClickHumanResourcesMenu,
    handleClickMyPageMenu,
    canUseMissionMenu,
    canUseCareerHumanResourceMenu,
    canUseNewGraduateHumanResourceMenu,
    canUseFollowerMenu,
    canUseFollowingHumanResources,
    logoRoute,
    isSwitching,
  };
};

export default useSidebar;
