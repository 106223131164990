import { Unsubscribe } from 'firebase/auth';
import { createSelector } from 'reselect';
import { Supporter, Mission, MissionUsePlan } from 'proto/v1/apimodel/apimodel';
import { MISSION_USE_PLAN_ID } from 'constants/models/mission';
import { ValueOf } from 'types/common';
import { ActionsUnion, createAction } from './helper';

type SetIdsPayload = {
  userId?: string;
  accountId?: string;
  missionId?: string;
  supporterId?: string;
};

/*
 * Redux Action actionTypes
 */

export const SET_IDS = 'account/SET_IDS';
export const SET_ACCOUNT = 'account/SET_ACCOUNT';
export const SET_IS_LOGGED_IN = 'account/SET_IS_LOGGED_IN';
export const SET_TOKEN = 'account/SET_TOKEN';
export const CLEAR_ACCOUNT = 'account/CLEAR';
export const SET_UNSUBSCRIBE_LOGOUT = 'account/SET_UNSUBSCRIBE_LOGOUT';
export const SET_MISSION_USE_PLANS = 'account/SET_MISSION_USE_PLANS';

/*
 * Redux Actions
 */

export const AccountActions = {
  setIds: (ids: SetIdsPayload) => createAction(SET_IDS, ids),
  setAccount: (account: {
    mission?: Mission;
    supporter?: Supporter;
    published: boolean;
  }) => createAction(SET_ACCOUNT, account),
  setIsLoggedIn: (isLoggedIn: boolean) =>
    createAction(SET_IS_LOGGED_IN, isLoggedIn),
  setToken: (token: string) => createAction(SET_TOKEN, token),
  setUnsubscribeLogout: (unsubscribeLogout: Unsubscribe) =>
    createAction(SET_UNSUBSCRIBE_LOGOUT, unsubscribeLogout),
  setMissionUsePlans: (missionUsePlans: MissionUsePlan[]) =>
    createAction(SET_MISSION_USE_PLANS, missionUsePlans),
  clear: () => createAction(CLEAR_ACCOUNT),
};
export type Actions = ActionsUnion<typeof AccountActions>;

/*
 * Selectors
 */
export const getAccountInfo = (state: any): AccountState => state.account;

export const getActiveAccount = createSelector(
  [getAccountInfo],
  account => account.account,
);

export const getIsLoggedIn = createSelector(
  [getAccountInfo],
  account => account.isLoggedIn,
);

export const getAccountId = createSelector(
  [getAccountInfo],
  account => account.accountId,
);

export const getUserId = createSelector(
  [getAccountInfo],
  account => account.userId,
);

export const getMissionId = createSelector(
  [getAccountInfo],
  account => account.missionId,
);

export const getSupporterId = createSelector(
  [getAccountInfo],
  account => account.supporterId,
);

export const getPublished = createSelector(
  [getAccountInfo],
  account => !!account.published,
);

export const getRole = createSelector([getAccountInfo], account => {
  if (account.missionId) return 'mission';
  if (account.supporterId) return 'supporter';
  return null;
});

export const getUnsubscribeLogout = createSelector(
  [getAccountInfo],
  account => account.unsubscribeLogout,
);

export const getMissionUsePlans = createSelector(
  [getAccountInfo],
  account => account.missionUsePlans,
);

const canUseByUsePlanIds = (
  missionUsePlans: MissionUsePlan[] | undefined,
  wantUsePlanIds: ValueOf<typeof MISSION_USE_PLAN_ID>[],
) => {
  if (!missionUsePlans) return false;
  return missionUsePlans
    .map(missionUsePlan => missionUsePlan.usePlanId)
    .some(usePlanId => (wantUsePlanIds as number[]).includes(usePlanId));
};

export const getCanUseCareerHumanResourceMenu = createSelector(
  [getAccountInfo],
  account => {
    if (!account.account?.mission) return true;
    return canUseByUsePlanIds(account.missionUsePlans, [
      MISSION_USE_PLAN_ID.CAREER,
    ]);
  },
);

export const getCanUseNewGraduateHumanResourceMenu = createSelector(
  [getAccountInfo],
  account => {
    if (!account.account?.mission) return true;
    return canUseByUsePlanIds(account.missionUsePlans, [
      MISSION_USE_PLAN_ID.NEW_GRADUATE_SUBSCRIPTION,
      MISSION_USE_PLAN_ID.NEW_GRADUATE_PAY_FOR_PERFORMANCE,
    ]);
  },
);

export const getCanUseMissionMenu = createSelector(
  [getAccountInfo],
  account => {
    if (!account.account?.mission) return true;
    return canUseByUsePlanIds(account.missionUsePlans, [
      MISSION_USE_PLAN_ID.OPEN_INNOVATION,
    ]);
  },
);

export const getCanUseFollowerMenu = createSelector(
  [getAccountInfo],
  account => {
    if (!account.account?.mission) return true;
    return canUseByUsePlanIds(account.missionUsePlans, [
      MISSION_USE_PLAN_ID.OPEN_INNOVATION,
    ]);
  },
);

export const getCanUseMissionFormStepMissionJobOffers = createSelector(
  [getAccountInfo],
  account => {
    if (!account.account?.mission) return true;
    return canUseByUsePlanIds(account.missionUsePlans, [
      MISSION_USE_PLAN_ID.CAREER,
      MISSION_USE_PLAN_ID.NEW_GRADUATE_SUBSCRIPTION,
      MISSION_USE_PLAN_ID.NEW_GRADUATE_PAY_FOR_PERFORMANCE,
    ]);
  },
);

export const getCanUseMissionFormStepMissionDemand = createSelector(
  [getAccountInfo],
  account => {
    if (!account.account?.mission) return true;
    return canUseByUsePlanIds(account.missionUsePlans, [
      MISSION_USE_PLAN_ID.OPEN_INNOVATION,
    ]);
  },
);

export const getCanUseFollowingHumanResources = createSelector(
  [getAccountInfo],
  account => {
    if (account.account?.supporter) return false;
    if (!account.account?.mission) return true;
    return canUseByUsePlanIds(account.missionUsePlans, [
      MISSION_USE_PLAN_ID.CAREER,
      MISSION_USE_PLAN_ID.NEW_GRADUATE_SUBSCRIPTION,
      MISSION_USE_PLAN_ID.NEW_GRADUATE_PAY_FOR_PERFORMANCE,
    ]);
  },
);

export const getCanUseFollowingMissions = createSelector(
  [getAccountInfo],
  account => {
    if (!account.account?.mission || account.account.supporter) return true;
    return canUseByUsePlanIds(account.missionUsePlans, [
      MISSION_USE_PLAN_ID.OPEN_INNOVATION,
    ]);
  },
);

export const getCanUsePublishedMissionNotification = createSelector(
  [getAccountInfo],
  account => {
    if (!account.account?.mission) return true;
    return canUseByUsePlanIds(account.missionUsePlans, [
      MISSION_USE_PLAN_ID.OPEN_INNOVATION,
    ]);
  },
);

/*
 * Reducer
 */

export type AccountState = {
  readonly isLoggedIn?: boolean;
  userId: string;
  accountId: string;
  missionId?: string;
  supporterId?: string;
  published?: boolean;
  unsubscribeLogout?: Unsubscribe;
  // TODO: Refactor account info
  account:
    | {
        mission: Mission | undefined;
        supporter: Supporter | undefined;
      }
    | undefined;
  missionUsePlans: MissionUsePlan[] | undefined;
};

const initState: AccountState = {
  isLoggedIn: false,
  userId: '',
  accountId: '',
  missionId: undefined,
  supporterId: undefined,
  published: false,
  account: undefined,
  missionUsePlans: [],
};

export default function reducer(state = initState, action: Actions) {
  if (!action) return state;

  switch (action.type) {
    case SET_IDS: {
      return {
        ...state,
        userId: action.payload.userId,
        accountId: action.payload.accountId,
        missionId: action.payload.missionId,
        supporterId: action.payload.supporterId,
      };
    }
    case SET_ACCOUNT: {
      return {
        ...state,
        account: {
          mission: action.payload.mission,
          supporter: action.payload.supporter,
        },
        published: action.payload.published,
      };
    }
    case SET_TOKEN: {
      return { ...state, token: action.payload };
    }
    case SET_IS_LOGGED_IN: {
      return { ...state, isLoggedIn: action.payload };
    }
    case SET_UNSUBSCRIBE_LOGOUT: {
      return { ...state, unsubscribeLogout: action.payload };
    }
    case SET_MISSION_USE_PLANS: {
      return { ...state, missionUsePlans: action.payload };
    }
    case CLEAR_ACCOUNT: {
      return initState;
    }
    default:
      return state;
  }
}
