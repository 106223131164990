import React, { FC, useState, useCallback, useEffect } from 'react';
import styled from 'styled-components';
import theme from 'styles/theme';
import { HorizonEllipsis } from 'assets/svg';
import { FiTrash2 } from 'react-icons/fi';
import { Overlay } from 'components/molecules';
import { useTranslation } from 'react-i18next';

const Menu = styled.div`
  position: relative;
  margin-right: 8px;
`;

const MenuButton = styled.button<{ active: boolean }>`
  width: 32px;
  height: 32px;
  padding: 0;
  border: none;
  border-radius: 16px;
  outline: none;
  background: ${({ active }) => (active ? '#D5DAF4' : '#eee')};
  cursor: pointer;

  :hover {
    background: #d5daf4;
  }
`;

const MenuContainer = styled.div`
  position: absolute;
  z-index: 2;
  right: 0;
  bottom: 100%;
  margin-bottom: 5px;
  border-radius: 5px;
  background: ${theme.baseWhite};
  box-shadow: 0 3px 8px rgba(0, 0, 0, 0.15);
`;

const MenuItem = styled.div`
  display: flex;
  flex-direction: row;
  padding: 8px;
  white-space: nowrap;
  cursor: pointer;

  :hover {
    background: #f1f2f9;
  }

  > *:first-child {
    margin-right: 5px;
  }
`;

type MessageMenuProps = {
  show: boolean;
  onDeleteMessage: () => void;
  onCloseMenu?: (mousePos: { mouseX: number; mouseY: number } | null) => void;
};

const MessageMenu: FC<MessageMenuProps> = ({
  show,
  onDeleteMessage,
  onCloseMenu,
}) => {
  const { t } = useTranslation('chat');

  const [showMenu, setShowMenu] = useState(false);

  const closeMenu = useCallback(
    (pos: { mouseX: number; mouseY: number } | null) => {
      setShowMenu(false);
      onCloseMenu && onCloseMenu(pos);
    },
    [onCloseMenu],
  );

  const handleDeleteMessage = useCallback(() => {
    onDeleteMessage();
    closeMenu(null);
  }, [closeMenu, onDeleteMessage]);

  useEffect(() => {
    if (!show && showMenu) setShowMenu(false);
  }, [closeMenu, show, showMenu]);

  return (
    <>
      {show && (
        <Menu>
          {showMenu && (
            <>
              <Overlay onDismiss={pos => closeMenu(pos)} />
              <MenuContainer>
                <MenuItem
                  onClick={handleDeleteMessage}
                  data-testid="message-menu-delete"
                >
                  <FiTrash2 />
                  <div>{t('delete')}</div>
                </MenuItem>
              </MenuContainer>
            </>
          )}
          <MenuButton
            active={showMenu}
            onClick={() => setShowMenu(value => !value)}
            data-testid="message-menu-button"
          >
            <HorizonEllipsis />
          </MenuButton>
        </Menu>
      )}
    </>
  );
};

export default MessageMenu;
