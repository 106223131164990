import React, { FC } from 'react';
import styled from 'styled-components/macro';
import FilterTag from '../FilterTag';

const Wrapper = styled.div`
  position: relative;

  &:not(:last-child) {
    margin-right: 12px;
  }
`;

const PickerContainer = styled.div`
  position: absolute;
  z-index: 2;
  top: 50px;
  left: 0;
  min-width: 640px;
  overflow: hidden;
  border-radius: 3px;
  box-shadow: 0 6px 10px rgba(0, 0, 0, 0.4);
`;

const Overlay = styled.div`
  position: fixed;
  z-index: 1;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`;

type PickerProps = {
  label: string;
  isActive: boolean;
  isSelected: boolean;
  onClickTag: () => void;
  onClickOverlay: () => void;
};

const Picker: FC<PickerProps> = props => {
  const {
    children,
    label,
    isActive,
    isSelected,
    onClickTag,
    onClickOverlay,
  } = props;

  return (
    <>
      <Wrapper>
        <FilterTag
          isSelected={isSelected}
          isActive={isActive}
          onClick={onClickTag}
        >
          {label}
        </FilterTag>
        {isSelected && <PickerContainer>{children}</PickerContainer>}
      </Wrapper>
      {isSelected && (
        <Overlay onClick={onClickOverlay} data-testid="picker-overlay" />
      )}
    </>
  );
};

export default Picker;
