import React, { useMemo } from 'react';
import styled from 'styled-components/macro';
import { useTranslation } from 'react-i18next';
import ImageTypes from 'constants/imageTypes';
import { imageSelector } from 'utils/imageSelector';
import { ButtonXL, Wall } from 'components/atoms';
import MissionFormHeader from '../../MissionFormHeader';
import MissionFormFooter from '../../MissionFormFooter';
import MissionPreview from '../../MissionPreview';
import {
  MissionNameField,
  StrengthPointField,
  MissionDescriptionField,
  CoverImageField,
  MemberRangeField,
  MissionOfficeImagesField,
  MissionMembersField,
  MissionCategoriesField,
} from '../../../Fields';
import {
  ImageFileSizeExceededModal,
  SaveConfirmationModal,
  NoInputMissionConfirmationModal,
  InvalidToSaveModal,
  SaveCompleteModal,
  PublishMissionConfirmationModal,
  PublishCompleteModal,
  InvalidToPublishModal,
  UnpublishConfirmationModal,
  UnpublishCompleteModal,
  ImageCropperModal,
  LeavePageConfirmationModal,
} from '../../../Modals';
import useStepAboutMission from './useStepAboutMission';

const Form = styled.form``;

const Layout = styled.div`
  display: grid;
  grid-template:
    'left-fields              ........................ right-fields            ' auto
    'mission-categories-field mission-categories-field mission-categories-field' auto
    / 1fr 64px 1fr;
`;

const StepAboutMissionWall = styled(Wall)`
  padding-top: 26px;
`;

const StepAboutMission: React.VFC = () => {
  const { t } = useTranslation('missionForm');

  const {
    handleClickNext,
    movePrevStep,
    isPublished,
    isFirstPublished,
    formik,
    formError,
    handleChangeSelectedValueAsNumber,
    form,
    isDisabledSaveButton,
    openImageCropper,
    imageCropperState,
    cropperAspectRatio,
    handleCroppedImage,
    handleExceedImageFileSize,
    handleClickCropperCloseButton,
    modalState,
    closeModal,
    handleClickSave,
    validateToSaveErrors,
    handleClickSaveConfirmationOK,
    handleChangeCategory,
    handleAddMember,
    handleDeleteMember,
    handleChangeMissionMemberIconImage,
    missionMembersError,
    handleAddOfficeImage,
    handleChangeOfficeImage,
    handleDeleteOfficeImage,
    missionOfficeImagesFieldRef,
    handleClickPublish,
    handleClickPublishConfirmationOk,
    handleClickUnPublishConfirmationOk,
    validateToPublishStepsResult,
    openPreview,
    canUseMissionFormStepMissionDemand,
    canUseMissionFormStepMissionJobOffers,
  } = useStepAboutMission();

  const {
    values,
    handleChange,
    handleBlur,
    dirty,
    setFieldValue,
    setFieldTouched,
  } = formik;

  return useMemo(
    () => (
      <>
        <MissionFormHeader
          isDisabledSaveButton={isDisabledSaveButton}
          isPublished={isPublished}
          onClickPreview={openPreview}
          onClickSave={handleClickSave}
          type="required"
          title={t('header.aboutMission.title')}
        />

        <Form data-testid="mission-form-step-about-mission">
          <StepAboutMissionWall color="baseWhite">
            <Layout>
              <div style={{ gridArea: 'left-fields' }}>
                <MissionNameField
                  value={values.name}
                  error={formError('name')}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />

                <StrengthPointField
                  value={values.strengthPoint}
                  error={formError('strengthPoint')}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />

                <MissionDescriptionField
                  value={values.missionDescription}
                  error={formError('missionDescription')}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />

                <MemberRangeField
                  value={values.memberRangeId}
                  error={formError('memberRangeId')}
                  form={form.memberRanges}
                  onChange={handleChangeSelectedValueAsNumber}
                  onBlur={handleBlur}
                />
              </div>

              <div style={{ gridArea: 'right-fields' }}>
                <CoverImageField
                  imageSrc={imageSelector(values.coverImage, 'large')}
                  error={formError('coverImage')}
                  onUpload={uploadedImage => {
                    openImageCropper(ImageTypes.COVER, uploadedImage);
                  }}
                  onExceededFileSizeLimit={handleExceedImageFileSize}
                />

                <MissionOfficeImagesField
                  value={values.missionOfficeImages}
                  onChangeImage={handleChangeOfficeImage}
                  onAddImage={handleAddOfficeImage}
                  onDeleteImage={handleDeleteOfficeImage}
                  onExceedImageFileSize={handleExceedImageFileSize}
                  ref={missionOfficeImagesFieldRef}
                />

                <MissionMembersField
                  value={values.missionMembers}
                  onAddMember={handleAddMember}
                  onDeleteMember={handleDeleteMember}
                  error={missionMembersError}
                  onChangeMissionMemberName={handleChange}
                  onChangeMissionMemberCareer={handleChange}
                  onChangeMissionMemberIconImage={
                    handleChangeMissionMemberIconImage
                  }
                  onExceedImageFileSize={handleExceedImageFileSize}
                  onBlur={handleBlur}
                />
              </div>
              <div style={{ gridArea: 'mission-categories-field' }}>
                <MissionCategoriesField
                  value={values.missionCategories}
                  error={formError('missionCategories')}
                  form={form.missionCategories}
                  onChange={handleChangeCategory}
                />
              </div>
            </Layout>
          </StepAboutMissionWall>
        </Form>

        <MissionFormFooter
          buttons={
            isFirstPublished
              ? [
                  <ButtonXL
                    type="button"
                    buttonTheme="gradation"
                    onClick={handleClickPublish}
                    key="publish"
                    disabled={isPublished ? !dirty : false}
                  >
                    {t('button.publish')}
                  </ButtonXL>,
                ]
              : [
                  <ButtonXL
                    type="button"
                    buttonTheme="cancel"
                    onClick={movePrevStep}
                    key="go-back"
                  >
                    {t('button.back')}
                  </ButtonXL>,
                  <ButtonXL
                    type="button"
                    buttonTheme="default"
                    onClick={handleClickNext}
                    key="go-next"
                  >
                    {t('button.next')}
                  </ButtonXL>,
                ]
          }
        />

        <MissionPreview
          values={values}
          step="aboutMission"
          forInnovator={
            !canUseMissionFormStepMissionDemand &&
            canUseMissionFormStepMissionJobOffers
          }
        />

        <ImageFileSizeExceededModal
          show={
            modalState.show && modalState.content === 'imageFileSizeExceeded'
          }
          closeModal={closeModal}
        />
        <SaveConfirmationModal
          show={
            modalState.show && modalState.content === 'saveMissionConfirmation'
          }
          onOk={handleClickSaveConfirmationOK}
          onCancel={closeModal}
        />
        <NoInputMissionConfirmationModal
          show={
            modalState.show &&
            modalState.content === 'saveRequiredNoInputConfirmation'
          }
          onCancel={closeModal}
          onOk={handleClickSaveConfirmationOK}
          isPublished={isPublished}
          stepsResult={validateToPublishStepsResult}
          canUseStepJobOffers={canUseMissionFormStepMissionJobOffers}
          canUseStepMissionDemand={canUseMissionFormStepMissionDemand}
        />
        <InvalidToSaveModal
          show={modalState.show && modalState.content === 'invalidToSave'}
          onClose={closeModal}
          errors={validateToSaveErrors}
        />
        <SaveCompleteModal
          show={
            modalState.show && modalState.content === 'saveMissionCompleted'
          }
          onClose={closeModal}
        />
        <InvalidToPublishModal
          show={modalState.show && modalState.content === 'invalidToPublish'}
          onClose={closeModal}
          stepsResult={validateToPublishStepsResult}
          canUseStepJobOffers={canUseMissionFormStepMissionJobOffers}
          canUseStepMissionDemand={canUseMissionFormStepMissionDemand}
        />
        <PublishMissionConfirmationModal
          show={
            modalState.show &&
            modalState.content === 'publishMissionConfirmation'
          }
          onOk={handleClickPublishConfirmationOk}
          onCancel={closeModal}
        />
        <PublishCompleteModal
          show={
            modalState.show && modalState.content === 'publishMissionCompleted'
          }
          onClose={closeModal}
        />
        <UnpublishConfirmationModal
          show={
            modalState.show &&
            modalState.content === 'unPublishMissionConfirmation'
          }
          onOk={handleClickUnPublishConfirmationOk}
          onCancel={closeModal}
        />
        <UnpublishCompleteModal
          show={
            modalState.show &&
            modalState.content === 'unPublishMissionCompleted'
          }
          onClose={closeModal}
        />
        <ImageCropperModal
          imageCropperState={imageCropperState}
          aspectRatio={cropperAspectRatio}
          onCroppedImage={croppedImage =>
            handleCroppedImage({ croppedImage, setFieldValue, setFieldTouched })
          }
          handleClickCropperCloseButton={(type, index) =>
            handleClickCropperCloseButton({ type, setFieldTouched, index })
          }
        />
        <LeavePageConfirmationModal when={dirty} />
      </>
    ),
    [
      canUseMissionFormStepMissionDemand,
      canUseMissionFormStepMissionJobOffers,
      closeModal,
      cropperAspectRatio,
      dirty,
      form.memberRanges,
      form.missionCategories,
      formError,
      handleAddMember,
      handleAddOfficeImage,
      handleBlur,
      handleChange,
      handleChangeCategory,
      handleChangeMissionMemberIconImage,
      handleChangeOfficeImage,
      handleChangeSelectedValueAsNumber,
      handleClickCropperCloseButton,
      handleClickNext,
      handleClickPublish,
      handleClickPublishConfirmationOk,
      handleClickSave,
      handleClickSaveConfirmationOK,
      handleClickUnPublishConfirmationOk,
      handleCroppedImage,
      handleDeleteMember,
      handleDeleteOfficeImage,
      handleExceedImageFileSize,
      imageCropperState,
      isDisabledSaveButton,
      isFirstPublished,
      isPublished,
      missionMembersError,
      missionOfficeImagesFieldRef,
      modalState.content,
      modalState.show,
      movePrevStep,
      openImageCropper,
      openPreview,
      setFieldTouched,
      setFieldValue,
      t,
      validateToPublishStepsResult,
      validateToSaveErrors,
      values,
    ],
  );
};

export default StepAboutMission;
