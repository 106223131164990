import { lazy } from 'react';

const Login = lazy(
  () => import(/* webpackChunkName: 'login' */ 'components/pages/Public/Login'),
);
const PasswordReset = lazy(
  () =>
    import(
      /* webpackChunkName: 'resetPassword' */ 'components/pages/Public/Password/Reset/PasswordReset'
    ),
);
const PasswordRegister = lazy(
  () =>
    import(
      /* webpackChunkName: 'passwordRegister' */ 'components/pages/Public/Password/Register/PasswordRegister'
    ),
);
const TermsOfService = lazy(
  () =>
    import(
      /* webpackChunkName: 'terms' */ 'components/pages/Public/TermsOfService/TermsOfService'
    ),
);
const VerificationEmail = lazy(
  () =>
    import(
      /* webpackChunkName: 'verificationEmail' */ 'components/pages/Public/Email/VerificationEmail'
    ),
);

export {
  Login,
  PasswordReset,
  PasswordRegister,
  TermsOfService,
  VerificationEmail,
};
