import React from 'react';
import styled, { css } from 'styled-components/macro';
import { useTranslation } from 'react-i18next';
import i18n from 'i18n/i18n';
import theme from 'styles/theme';
import typography from 'styles/typography';
import { Checkbox } from 'components/atoms';
import { MatterForBulkScout } from 'types/matter';

const HumanResource = styled.li<{ isSelected: boolean }>`
  display: flex;
  gap: 18px;
  padding: 12px 12px;
  border-bottom: 1px solid ${theme.borderDefault};
  cursor: pointer;
  ${({ isSelected }) =>
    isSelected &&
    css`
      background-color: ${theme.tableHover};
    `}

  &:hover {
    background-color: ${theme.tableHover};
  }
`;

const HumanResourceLeft = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const HumanResourceRight = styled.div``;

const HumanResourceCheckBox = styled(Checkbox)`
  &&& {
    min-width: auto;
    margin-right: 0;
    margin-bottom: 0;

    div {
      margin: 0;
    }
  }
`;

const CareerJobHistoryName = styled.div`
  ${typography.headlineDefault}
  color: ${theme.textPrimary};
`;

const CareerJobHistoryWorkingPeriods = styled.div`
  ${typography.textNote}
  color: ${theme.textPrimary};
`;

const NewGraduateSchoolName = styled.div`
  ${typography.headlineDefault}
  color: ${theme.textPrimary};
`;

const NewGraduateAcademicBackground = styled.div`
  ${typography.textNote}
  color: ${theme.textPrimary};
`;

const HumanResourceExtraInfo = styled.div`
  ${typography.textNote}
  color: ${theme.basePrimary};
`;

const formatCareerJobHistoryWorkingPeriods = (
  jobHistory: NonNullable<MatterForBulkScout['career']>['jobHistory'],
) => {
  if (!jobHistory) return '';
  const { startYear, startMonth, endYear, endMonth } = jobHistory;

  const start = i18n.t('humanResource:bulkScout.career.jobHistory.yearMonth', {
    year: startYear,
    month: startMonth,
    lng: 'ja',
  });
  const end =
    endYear && endMonth
      ? i18n.t('humanResource:bulkScout.career.jobHistory.yearMonth', {
          year: endYear,
          month: endMonth,
          lng: 'ja',
        })
      : i18n.t('humanResource:bulkScout.career.jobHistory.current', {
          lng: 'ja',
        });

  return `${start}〜${end}`;
};

const formatCareerAcademicBackground = (
  academicBackground: NonNullable<
    MatterForBulkScout['career']
  >['academicBackground'],
) => {
  if (!academicBackground) return '';
  const { school, yearOfGraduation } = academicBackground;
  return `${school?.name}${i18n.t(
    'humanResource:bulkScout.career.academicBackground.yearOfGraduation',
    {
      year: yearOfGraduation,
      lng: 'ja',
    },
  )}`;
};

const formatNewGraduateAcademicBackground = (
  academicBackground: NonNullable<
    MatterForBulkScout['newGraduate']
  >['academicBackground'],
) => {
  if (!academicBackground) return '';
  const {
    faculty,
    department,
    yearOfGraduation,
    monthOfGraduation,
  } = academicBackground;

  return `${faculty} ${department?.name} ${i18n.t(
    'humanResource:bulkScout.newGraduate.academicBackground.yearMonthOfGraduation',
    {
      year: yearOfGraduation,
      month: monthOfGraduation,
      lng: 'ja',
    },
  )}`;
};

type Props = {
  matter: MatterForBulkScout;
  isChecked: boolean;
  isSelected: boolean;
  onClick: (matterId: MatterForBulkScout['matterId']) => void;
  onChangeCheckBox: (e: React.ChangeEvent<HTMLInputElement>) => void;
};

const HumanResourceListItem: React.VFC<Props> = ({
  matter,
  isSelected,
  isChecked,
  onClick,
  onChangeCheckBox,
}) => {
  const { t } = useTranslation('humanResource');

  return (
    <HumanResource
      key={matter.matterId}
      data-testid="bulk-scout-screen-human-resource"
      isSelected={isSelected}
      onClick={() => onClick(matter.matterId)}
    >
      <HumanResourceLeft onClick={e => e.stopPropagation()}>
        <HumanResourceCheckBox
          name="matterId"
          checked={isChecked}
          value={matter.matterId}
          onChange={onChangeCheckBox}
          data-testid="bulk-scout-screen-human-resource-checkbox"
        />
      </HumanResourceLeft>
      <HumanResourceRight>
        {matter.career && (
          <>
            <CareerJobHistoryName>
              {matter.career.jobHistory?.name}
            </CareerJobHistoryName>
            <CareerJobHistoryWorkingPeriods>
              {formatCareerJobHistoryWorkingPeriods(matter.career.jobHistory)}
            </CareerJobHistoryWorkingPeriods>
            <HumanResourceExtraInfo>
              {matter.career.jobHistory?.occupation?.name} /{' '}
              {formatCareerAcademicBackground(matter.career.academicBackground)}
            </HumanResourceExtraInfo>
          </>
        )}
        {matter.newGraduate && (
          <>
            <NewGraduateSchoolName>
              {matter.newGraduate.academicBackground?.school?.name}
            </NewGraduateSchoolName>
            <NewGraduateAcademicBackground>
              {formatNewGraduateAcademicBackground(
                matter.newGraduate.academicBackground,
              )}
            </NewGraduateAcademicBackground>
            <HumanResourceExtraInfo>
              {t('bulkScout.newGraduate.dttRank.label', {
                lng: 'ja',
              })}{' '}
              {matter.newGraduate.dttRank?.name}
            </HumanResourceExtraInfo>
          </>
        )}
      </HumanResourceRight>
    </HumanResource>
  );
};

export default HumanResourceListItem;
