import React from 'react';
import styled from 'styled-components/macro';
import { useTranslation } from 'react-i18next';
import theme from 'styles/theme';
import scrollbar from 'styles/scrollbar';
import typography from 'styles/typography';
import { MESSAGE_TEMPLATE_REPLACE_TEXT } from 'constants/models/messageTemplate';
import { CrossGray } from 'assets/svg';
import { Checkbox } from 'components/atoms';
import { ScoutMessageForm, HumanResourceDetail } from 'components/organisms';
import useBulkScoutScreenContent from './useBulkScoutScreenContent';
import HumanResourceListItem from './HumanResourceListItem';
import { Props } from './BulkScoutScreen';

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  background-color: ${theme.baseWhite};
`;

const Head = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 62px;
  padding: 0 16px;
  border-bottom: 1px solid ${theme.borderDefault};
`;

const Title = styled.div`
  ${typography.titlePage}
`;

const Body = styled.div`
  display: flex;
  height: calc(100% - 62px);
`;

const BodyLeft = styled.div`
  display: flex;
  flex-direction: column;
  width: 430px;
  border-right: 1px solid ${theme.borderDefault};
`;

const BodyRight = styled.div`
  flex: 1;
`;

const CloseIcon = styled(CrossGray)`
  cursor: pointer;

  &:hover {
    opacity: 0.8;
  }
`;

const HumanResourceListHead = styled.div`
  display: flex;
  align-items: center;
  padding: 4px 12px;
  border-bottom: 1px solid ${theme.borderDefault};
`;

const SelectAllCheckBox = styled(Checkbox)`
  &&& {
    min-width: auto;
    margin-right: 0;
    margin-bottom: 0;
  }
`;

const SelectAllCheckBoxLabel = styled.span`
  color: ${theme.basePrimary};
  font-size: 11px;
  line-height: 1;
`;

const HumanResourceList = styled.ul`
  ${scrollbar};
  overflow-y: auto;
`;

const BulkScoutFormContent = styled.div`
  ${scrollbar};
  display: flex;
  flex: 1;
  flex-direction: column;
  height: 100%;
  padding: 24px 24px 36px 24px;
  overflow-y: auto;
`;

const BulkScoutScreenContent: React.VFC<Props> = ({
  matterPublishIds,
  excludeMatterPublishIds,
  onBulkScout,
  onClose,
  shouldFetchAll,
  career,
  newGraduate,
  onShowExceedMaxBulkScoutError,
}) => {
  const { t } = useTranslation('humanResource');

  const {
    matters,
    isCheckedSelectAll,
    handleChangeSelectAll,
    selectedMatterIds,
    handleChangeHumanResourceCheckBox,
    handleUseMessageTemplate,
    handleScout,
    humanResourceDetailState,
    handleClickHumanResourceItem,
    handleExitedHumanResourceDetail,
    handleCloseHumanResourceDetail,
  } = useBulkScoutScreenContent({
    shouldFetchAll,
    matterPublishIds,
    excludeMatterPublishIds,
    onBulkScout,
    onShowExceedMaxBulkScoutError,
    careerFilterValues: career?.filterValues,
    newGraduateFilterValues: newGraduate?.filterValues,
    onClose,
  });

  if (matters.length === 0) return null;

  return (
    <Wrapper data-testid="bulk-scout-screen-content">
      <Head>
        <Title>{t('bulkScout.title', { lng: 'ja' })}</Title>
        <CloseIcon onClick={onClose} />
      </Head>
      <Body>
        <BodyLeft>
          <HumanResourceListHead>
            <SelectAllCheckBox
              name="selectAll"
              checked={isCheckedSelectAll}
              onChange={handleChangeSelectAll}
              data-testid="select-all-bulk-scout-screen-human-resource-checkbox"
            >
              <SelectAllCheckBoxLabel data-testid="select-all-bulk-scout-screen-human-resource-checkbox-label">
                {t('bulkScout.checkbox.selectAll', {
                  selectedCount: selectedMatterIds.length,
                  lng: 'ja',
                })}
              </SelectAllCheckBoxLabel>
            </SelectAllCheckBox>
          </HumanResourceListHead>

          <HumanResourceList>
            {matters.map(matter => (
              <HumanResourceListItem
                key={matter.matterId}
                matter={matter}
                isSelected={
                  matter.matterId === humanResourceDetailState.matter?.matterId
                }
                onClick={handleClickHumanResourceItem}
                isChecked={selectedMatterIds.includes(matter.matterId)}
                onChangeCheckBox={handleChangeHumanResourceCheckBox}
              />
            ))}
          </HumanResourceList>
        </BodyLeft>

        <BodyRight>
          <BulkScoutFormContent>
            <ScoutMessageForm
              type={career ? 'career' : 'newGraduate'}
              onScout={handleScout}
              onUseMessageTemplate={handleUseMessageTemplate}
              insertTexts={{
                memberName: MESSAGE_TEMPLATE_REPLACE_TEXT.MEMBER_NAME,
                schoolName: MESSAGE_TEMPLATE_REPLACE_TEXT.SCHOOL_NAME,
                companyName: MESSAGE_TEMPLATE_REPLACE_TEXT.COMPANY_NAME,
              }}
              canSendScout={selectedMatterIds.length > 0}
            />
          </BulkScoutFormContent>
        </BodyRight>
      </Body>

      <HumanResourceDetail
        show={humanResourceDetailState.show}
        matter={humanResourceDetailState.matter}
        onExited={handleExitedHumanResourceDetail}
        onClose={handleCloseHumanResourceDetail}
      />
    </Wrapper>
  );
};

export default BulkScoutScreenContent;
