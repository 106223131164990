import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components/macro';
import { NewGraduateProfileForMatter } from 'proto/v1/apimodel/apimodel';
import {
  HumanResourceCardBodyItem,
  HumanResourceCardBodyItemLabel,
  HumanResourceCardBodyItemContent,
  HumanResourceCardBodyListItemContent,
} from 'components/molecules';

const InternshipExperience = styled.div`
  &:not(:last-child) {
    margin-bottom: 16px;
  }
`;

type NewGraduateInternshipExperiencesFieldProps = {
  internshipExperiences:
    | NewGraduateProfileForMatter['internshipExperiences']
    | undefined;
};

const NewGraduateInternshipExperiencesField: FC<NewGraduateInternshipExperiencesFieldProps> = props => {
  const { internshipExperiences } = props;
  const { t } = useTranslation(['matter']);

  return (
    <HumanResourceCardBodyItem>
      <HumanResourceCardBodyItemLabel>
        {t('field.internshipExperiences.label', {
          lng: 'ja',
        })}
      </HumanResourceCardBodyItemLabel>
      <HumanResourceCardBodyItemContent>
        <div>
          {internshipExperiences && internshipExperiences.length > 0
            ? internshipExperiences.map((internshipExperience, index) => (
                // eslint-disable-next-line react/no-array-index-key
                <InternshipExperience key={index}>
                  <div>{internshipExperience.companyName}</div>
                  <HumanResourceCardBodyListItemContent>
                    {t('periods', {
                      startYear: internshipExperience.startYear,
                      startMonth: internshipExperience.startMonth,
                      endYear: internshipExperience.endYear,
                      endMonth: internshipExperience.endMonth,
                      lng: 'ja',
                    })}
                  </HumanResourceCardBodyListItemContent>
                </InternshipExperience>
              ))
            : '-'}
        </div>
      </HumanResourceCardBodyItemContent>
    </HumanResourceCardBodyItem>
  );
};

export default NewGraduateInternshipExperiencesField;
