import React, { FC } from 'react';
import { Supporter } from 'proto/v1/apimodel/apimodel';
import { DetailContentsRightItem } from 'components/molecules';
import {
  AddressField,
  IconImageField,
  CompanyNameField,
  MissionMembersField,
  CompanyEstablishedDateField,
  MemberRangeField,
  CompanyUrlField,
} from 'components/organisms/Details/Common/Fields';
import { SupportAreasField } from 'components/organisms/Details/SupporterDetail/Fields';

type CompanyContentProps = { supporter: Supporter };

const CompanyContent: FC<CompanyContentProps> = ({ supporter }) => {
  return (
    <>
      <DetailContentsRightItem>
        <IconImageField iconImage={supporter.iconImage} />
        <CompanyNameField companyName={supporter.companyName} />
        {supporter.companyUrl && (
          <CompanyUrlField companyUrl={supporter.companyUrl} />
        )}
        <CompanyEstablishedDateField
          companyEstablishedDate={supporter.companyEstablishedDate}
        />
        <AddressField
          country={supporter.country}
          japanPrefecture={supporter.japanPrefecture}
          otherAddress={supporter.otherAddress}
        />
        <MemberRangeField memberRange={supporter.memberRange} />
        <SupportAreasField supportAreas={supporter.supportAreas} />
      </DetailContentsRightItem>

      {supporter.supporterMembers.length > 0 && (
        <DetailContentsRightItem borderTop>
          <MissionMembersField members={supporter.supporterMembers} />
        </DetailContentsRightItem>
      )}
    </>
  );
};

export default CompanyContent;
