import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { UiActions } from 'modules/ui';
import RequestError from 'classes/RequestError';
import { useFormik } from 'formik';
import { string, object } from 'yup';
import apiAccount from 'external/api/account';
import useErrorDialog from 'hooks/useErrorDialog';

type Values = {
  email: string;
};

export default function useChangeEmailAddressForm() {
  const [submitDone, setSubmitDone] = useState<boolean>(false);
  const [submitConfirm, setSubmitConfirm] = useState<boolean>(false);
  const { t } = useTranslation(['error']);
  const dispatch = useDispatch();
  const { handleRequestError } = useErrorDialog();

  const onSubmit = async ({ email }: Values) => {
    dispatch(UiActions.setLoading(true));
    setSubmitConfirm(!submitConfirm);
    setSubmitDone(false);
    try {
      await apiAccount.changeUserSettings({
        email: { value: email },
      });
    } catch (e) {
      e instanceof RequestError && handleRequestError(e);
      dispatch(UiActions.setLoading(false));
      return;
    }
    setSubmitDone(true);
    dispatch(UiActions.setLoading(false));
  };

  const {
    values,
    handleChange,
    handleBlur,
    handleSubmit,
    isValid,
    errors,
    dirty,
  } = useFormik<Values>({
    initialValues: { email: '' },
    onSubmit,
    validationSchema: object({
      email: string().email(t('error:invalidEmail')),
    }),
  });

  return {
    values,
    handleChange,
    handleBlur,
    handleSubmit,
    isValid,
    errors,
    dirty,
    submitDone,
    setSubmitDone,
    submitConfirm,
    setSubmitConfirm,
  };
}
