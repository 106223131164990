import useMenu from '../../hooks/useMenu';
import { SupporterFormState } from './useSupporterFormState';

const useSupporterFormMenu = ({
  openModal,
  setShowMenu,
}: SupporterFormState) => {
  const { openMenu, closeMenu, handleClickMenuUnPublish } = useMenu({
    openModal,
    setShowMenu,
  });

  return {
    openMenu,
    closeMenu,
    handleClickMenuUnPublish,
  };
};

export default useSupporterFormMenu;
