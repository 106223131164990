import React, { forwardRef, ChangeEvent, RefObject } from 'react';
import styled from 'styled-components/macro';
import { useTranslation } from 'react-i18next';
import theme from 'styles/theme';
import { MissionFormValues } from 'types/mission';
import { MissionProductsError } from 'types/form';
import { imageSelector } from 'utils/imageSelector';
import { isEqualProps } from 'utils/object';
import { Delete } from 'components/atoms';
import { MissionSettingFormItem, FormItemLabel } from 'components/molecules';
import {
  ImageField,
  NameField,
  OverviewField,
  UrlField,
  TagsField,
  ProblemField,
  SolutionField,
  MemberBenefitField,
  ProductTargetField,
} from './SubFields';

const Wrapper = styled.div`
  /* For offset of scrollIntoView for missionProductsField */
  margin-top: -20px;
  padding-top: 20px;
`;

const TitleWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 36px;
  margin-bottom: 16px;
`;

const Fields = styled.div`
  position: relative;
  margin-bottom: 16px;
  padding: 24px;
  border-radius: 8px;
  background: ${theme.bgSecondary};

  &:last-child {
    margin-bottom: 0;
  }

  &:hover {
    background-color: ${theme.bgAdd};
  }
`;

const FieldsTwoColumns = styled.div`
  display: flex;
  margin-bottom: 24px;
`;

const FieldsLeft = styled.div`
  flex: 1;
  margin-right: 48px;
`;

const FieldsRight = styled.div`
  flex: 1;
`;

const ProductDelete = styled(Delete)`
  visibility: hidden;
  position: absolute;
  right: 24px;
  bottom: 8px;

  /* stylelint-disable-next-line selector-type-no-unknown */
  ${Fields}:hover & {
    visibility: visible;
  }
`;

type MissionProductsFieldProps = {
  value: MissionFormValues['missionProducts'];
  error: MissionProductsError[] | undefined;
  onAddProduct: () => void;
  onDeleteProduct: (index: number) => void;
  onChangeText: (
    e: ChangeEvent<HTMLInputElement> | ChangeEvent<HTMLTextAreaElement>,
  ) => void;
  onChangeChecked: (e: ChangeEvent<HTMLInputElement>) => void;
  onChangeImage: (imageSrc: string, index: number) => void;
  onChangeHasMemberBenefit: (
    e: ChangeEvent<HTMLInputElement>,
    index: number,
  ) => void;
  onBlurText: (e: any) => void;
  onExceedImageFileSize: () => void;
  productRefs: RefObject<HTMLDivElement>[];
};

const MissionProductsField = forwardRef<
  HTMLDivElement,
  MissionProductsFieldProps
>((props, ref) => {
  const {
    value,
    error,
    onDeleteProduct,
    onChangeText,
    onBlurText,
    onChangeChecked,
    onChangeImage,
    onChangeHasMemberBenefit,
    onExceedImageFileSize,
    productRefs,
  } = props;

  const { t } = useTranslation('missionSetting');

  return (
    <Wrapper ref={ref} data-testid="mission-products-field">
      <MissionSettingFormItem style={{ marginBottom: 0 }}>
        <TitleWrapper>
          <FormItemLabel
            label={t('field.missionProducts.label')}
            note={t('field.missionProducts.note')}
            style={{ marginBottom: 0 }}
          />
        </TitleWrapper>

        {value.map((missionProduct, index) => (
          <Fields
            key={missionProduct.missionProductId || index}
            ref={productRefs[index]}
            data-testid="product-fields"
          >
            <FieldsTwoColumns>
              <FieldsLeft>
                <ImageField
                  index={index}
                  imageSrc={imageSelector(missionProduct.image)}
                  error={error && error[index]?.image}
                  onUpload={uploadedImage => {
                    onChangeImage(uploadedImage, index);
                  }}
                  onExceededFileSizeLimit={onExceedImageFileSize}
                />

                <NameField
                  index={index}
                  value={missionProduct.name}
                  error={error && error[index]?.name}
                  onChange={onChangeText}
                  onBlur={onBlurText}
                />

                <OverviewField
                  index={index}
                  value={missionProduct.overview}
                  error={error && error[index]?.overview}
                  onChange={onChangeText}
                  onBlur={onBlurText}
                />

                <UrlField
                  index={index}
                  value={missionProduct.url}
                  error={error && error[index]?.url}
                  onChange={onChangeText}
                  onBlur={onBlurText}
                />

                <TagsField
                  index={index}
                  value={missionProduct.tags}
                  error={error && error[index]?.tags}
                  onChange={onChangeText}
                  onBlur={onBlurText}
                />
              </FieldsLeft>

              <FieldsRight>
                <ProblemField
                  index={index}
                  error={error && error[index]?.problem}
                  value={missionProduct.problem}
                  onChange={onChangeText}
                  onBlur={onBlurText}
                />

                <SolutionField
                  index={index}
                  error={error && error[index]?.solution}
                  value={missionProduct.solution}
                  onChange={onChangeText}
                  onBlur={onBlurText}
                />

                <MemberBenefitField
                  index={index}
                  values={{
                    hasMemberBenefit: missionProduct.hasMemberBenefit,
                    memberBenefit: missionProduct.memberBenefit,
                  }}
                  error={
                    error &&
                    (error[index]?.memberBenefit ||
                      error[index]?.hasMemberBenefit)
                  }
                  onChangeHasMemberBenefit={event =>
                    onChangeHasMemberBenefit(event, index)
                  }
                  onChangeMemberBenefit={onChangeText}
                  onBlurMemberBenefit={onBlurText}
                />

                <ProductTargetField
                  index={index}
                  value={missionProduct.productTarget}
                  error={error && error[index]?.productTarget}
                  onChange={onChangeChecked}
                />
              </FieldsRight>
            </FieldsTwoColumns>

            <ProductDelete onClick={() => onDeleteProduct(index)} />
          </Fields>
        ))}
      </MissionSettingFormItem>
    </Wrapper>
  );
});

export default React.memo(MissionProductsField, (prevProps, nextProps) =>
  isEqualProps(prevProps, nextProps, ['value', 'error']),
);
