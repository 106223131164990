import React, { FC, ComponentProps } from 'react';
import styled from 'styled-components/macro';
import { useTranslation } from 'react-i18next';
import typography from 'styles/typography';
import { Checkbox, ButtonS } from 'components/atoms';
import {
  Picker,
  PickerHeading,
  PickerContent,
  PickerFooter,
  CategorySelectableTag,
} from 'components/molecules';
import { MissionCategory } from 'proto/v1/apimodel/apimodel';
import { CategoryPickerValues, CategoryFilterValues } from 'types/mission';
import useCategoryPicker from './useCategoryPicker';

const Form = styled.form`
  width: 680px;
`;

const HeadingCheckbox = styled(Checkbox)`
  &&& {
    display: inline-block;
    margin-right: 0;
    margin-bottom: 0;
    margin-left: 16px;

    label {
      font-weight: normal;
    }
  }
`;

const CategorySelectableTags = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-bottom: -16px;
`;

const StyledCategorySelectableTag = styled(CategorySelectableTag)`
  ${typography.textDefault}
  min-width: 138px;
  margin-right: 20px;
  margin-bottom: 16px;

  label {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 32px;
    margin-bottom: 0;
    padding: 6px 12px 5px 12px;
    border-radius: 16px;
  }
`;

type PickerProps = ComponentProps<typeof Picker>;

type CategoryPickerProps = {
  onSubmit: (values: CategoryPickerValues) => void;
  form: { categories: MissionCategory[] | undefined };
  defaultValues: CategoryFilterValues;
} & Pick<
  PickerProps,
  'isActive' | 'isSelected' | 'onClickTag' | 'onClickOverlay'
>;

const CategoryPicker: FC<CategoryPickerProps> = props => {
  const {
    isActive,
    isSelected,
    onClickTag,
    onClickOverlay,
    onSubmit,
    form,
    defaultValues,
  } = props;

  const { t } = useTranslation(['missionList', 'common']);

  const {
    formik,
    handleChangeCategory,
    handleChangeCheckAllCategories,
  } = useCategoryPicker({
    onSubmit,
    form,
    defaultValues,
    isSelected,
  });

  const { values, handleSubmit } = formik;

  return (
    <Picker
      label={t('picker.category.label')}
      isSelected={isSelected}
      isActive={isActive}
      onClickTag={onClickTag}
      onClickOverlay={onClickOverlay}
    >
      <Form onSubmit={handleSubmit} data-testid="category-picker">
        <PickerHeading>
          {t('picker.category.field.categories.label')}
          <HeadingCheckbox
            name="checkAllCategories"
            onChange={handleChangeCheckAllCategories}
            checked={values.checkAllCategories}
            aria-label={t('picker.checkbox.toggleCheckAll')}
          >
            {t('picker.checkbox.toggleCheckAll')}
          </HeadingCheckbox>
        </PickerHeading>

        <PickerContent style={{ paddingRight: 0 }}>
          <CategorySelectableTags>
            {form?.categories &&
              form.categories.map(item => (
                <StyledCategorySelectableTag
                  key={item.categoryId}
                  name="categoryIds"
                  onChange={handleChangeCategory}
                  value={item.categoryId}
                  checked={values.categoryIds.includes(item.categoryId)}
                  colors={{
                    backgroundColor: item.backgroundColor,
                    inactiveBackgroundColor: item.inactiveBackgroundColor,
                    inactiveTextColor: item.inactiveTextColor,
                  }}
                  aria-label={item.name}
                >
                  {item.name}
                </StyledCategorySelectableTag>
              ))}
          </CategorySelectableTags>
        </PickerContent>

        <PickerFooter>
          <ButtonS buttonTheme="default" type="submit">
            {t('common:button.save')}
          </ButtonS>
        </PickerFooter>
      </Form>
    </Picker>
  );
};

export default CategoryPicker;
