import React, { FC } from 'react';
import styled from 'styled-components/macro';
import { TableCell } from 'components/atoms';
import { CategoryTag } from 'components/molecules';
import { MissionCategory } from 'proto/v1/apimodel/apimodel';

const StyledTableCell = styled(TableCell)`
  justify-content: center;
`;

const CategoryList = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
`;

const StyledTag = styled(CategoryTag)`
  width: 100%;
  margin-bottom: 8px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

type CategoryCellProps = {
  categories?: MissionCategory[];
  cellWidth?: string;
};

const CategoryCell: FC<CategoryCellProps> = ({ categories, cellWidth }) => {
  return (
    <StyledTableCell cellWidth={cellWidth}>
      <CategoryList>
        {categories &&
          categories.length > 0 &&
          categories.map(category => (
            <StyledTag
              backgroundColor={category.backgroundColor}
              key={category.categoryId}
              round
            >
              {category.name}
            </StyledTag>
          ))}
      </CategoryList>
    </StyledTableCell>
  );
};

export default CategoryCell;
