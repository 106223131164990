import styled from 'styled-components/macro';
import theme from 'styles/theme';
import typography from 'styles/typography';

const UrlLink = styled.a.attrs({ target: '_blank', rel: 'noopener' })`
  ${typography.textLink}
  display: inline-block;
  color: ${theme.textSecondary};

  &:hover {
    color: ${theme.textSecondaryHover};
  }
`;

export default UrlLink;
