import { DocumentChange } from 'firebase/firestore';
import { ActionsUnion, createAction } from './helper';

/*
 * Redux Action actionTypes
 */

export const SET_CONTACT_NOTIFICATION = 'notifications/contact/SET_CONTACT';
export const SET_MESSAGE_NOTIFICATION = 'notifications/message/SET_CONTACT';
export const CLEAR_NOTIFICATION = 'notifications/CLEAR';

/*
 * Redux Actions
 */
export const NotificationActions = {
  setContactNotifications: (firestoreDocumentChange: DocumentChange[]) =>
    createAction(SET_CONTACT_NOTIFICATION, firestoreDocumentChange),
  setMessageNotifications: (firestoreDocumentChange: DocumentChange[]) =>
    createAction(SET_MESSAGE_NOTIFICATION, firestoreDocumentChange),
  clearNotification: () => createAction(CLEAR_NOTIFICATION),
};
export type Actions = ActionsUnion<typeof NotificationActions>;

/*
 * Selectors
 */
export const getNotification = (state: any): NotificationState =>
  state.notification;

/*
 * Reducer
 */
export type Notification = {
  id: string;
  count: number;
};

type NotificationState = {
  readonly contactNotifications: Notification[];
  readonly messageNotifications: Notification[];
};

const initState: NotificationState = {
  contactNotifications: [],
  messageNotifications: [],
};

const updateContactNotifications = (
  firestoreDocumentChange: DocumentChange[],
  currentNotifications: Notification[],
): Notification[] => {
  return firestoreDocumentChange.reduce((prev, current) => {
    if (current.type === 'added' && current.doc.data().count) {
      prev.push({
        id: current.doc.id,
        count: current.doc.data().count,
      });
    }
    if (current.type === 'modified') {
      const removedTargetIndex = prev.findIndex(
        prevCurrent => prevCurrent.id === current.doc.id,
      );
      removedTargetIndex !== -1 && prev.splice(removedTargetIndex, 1);
      prev.push({
        id: current.doc.id,
        count: current.doc.data().count,
      });
    }
    if (current.type === 'removed') {
      const removedTargetIndex = prev.findIndex(
        prevCurrent => prevCurrent.id === current.doc.id,
      );
      prev.splice(removedTargetIndex, 1);
    }
    return prev;
  }, currentNotifications);
};

export default function reducer(state = initState, action: Actions) {
  if (!action) return state;

  switch (action.type) {
    case SET_CONTACT_NOTIFICATION: {
      const currentNotifications = [...state.contactNotifications];
      return {
        ...state,
        contactNotifications: updateContactNotifications(
          action.payload,
          currentNotifications,
        ),
      };
    }
    case SET_MESSAGE_NOTIFICATION: {
      const messageNotifications = [...state.messageNotifications];
      return {
        ...state,
        messageNotifications: updateContactNotifications(
          action.payload,
          messageNotifications,
        ),
      };
    }
    case CLEAR_NOTIFICATION: {
      return initState;
    }
    default:
      return state;
  }
}
