import React, { FC } from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { resources as i18nResources, localePath } from 'i18n/i18n';
import { useLocation } from 'react-router-dom';

const DEFAULT_TITLE = 'VISITS innovators';

const Head: FC = () => {
  const { t } = useTranslation('head');
  const location = useLocation();
  const canonicalUrl = `${process.env.REACT_APP_BASE_URL}${
    localePath === '/ja' ? '/ja' : ''
  }${location.pathname}`;

  return (
    <Helmet
      defaultTitle={DEFAULT_TITLE}
      titleTemplate={`%s | ${DEFAULT_TITLE}`}
    >
      <meta name="description" content={t('description')} />
      <meta property="og:description" content={t('description')} />
      {Object.keys(i18nResources).map(locale => (
        <link
          rel="alternate"
          href={`${process.env.REACT_APP_BASE_URL}/${locale}${location.pathname}`}
          hrefLang={locale}
          key={locale}
        />
      ))}
      <link
        rel="alternate"
        hrefLang="x-default"
        href={`${process.env.REACT_APP_BASE_URL}${location.pathname}`}
      />
      <link rel="canonical" href={canonicalUrl} />
    </Helmet>
  );
};

export default Head;
