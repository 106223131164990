import React, { FC, ChangeEvent } from 'react';
import styled from 'styled-components/macro';
import { useTranslation } from 'react-i18next';
import { MissionProductFormValue } from 'types/form';
import { isEqualProps } from 'utils/object';
import { Checkbox } from 'components/atoms';
import {
  FormItemSubHeading,
  MissionSettingFormItem,
} from 'components/molecules';

const Checkboxes = styled.div`
  display: flex;
`;

type ProductTargetFieldProps = {
  index: number;
  value: MissionProductFormValue['productTarget'];
  error: string | undefined;
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
};

const ProductTargetField: FC<ProductTargetFieldProps> = props => {
  const { index, value, error, onChange } = props;

  const { t } = useTranslation('missionSetting');

  return (
    <MissionSettingFormItem error={error} style={{ marginBottom: 0 }}>
      <FormItemSubHeading
        label={t('field.missionProducts.subField.productTarget.label')}
      />
      <Checkboxes>
        <Checkbox
          name={`missionProducts[${index}].productTarget.isForBusiness`}
          onChange={onChange}
          checked={value?.isForBusiness || false}
          aria-label={t(
            'field.missionProducts.subField.productTarget.checkbox.isForBusiness',
          )}
        >
          {t(
            'field.missionProducts.subField.productTarget.checkbox.isForBusiness',
          )}
        </Checkbox>
        <Checkbox
          name={`missionProducts[${index}].productTarget.isForCustomer`}
          onChange={onChange}
          checked={value.isForCustomer || false}
          aria-label={t(
            'field.missionProducts.subField.productTarget.checkbox.isForCustomer',
          )}
        >
          {t(
            'field.missionProducts.subField.productTarget.checkbox.isForCustomer',
          )}
        </Checkbox>
      </Checkboxes>
    </MissionSettingFormItem>
  );
};

export default React.memo(ProductTargetField, (prevProps, nextProps) =>
  isEqualProps(prevProps, nextProps, ['value', 'error']),
);
