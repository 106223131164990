import React, { useMemo } from 'react';
import styled from 'styled-components/macro';
import { Stepper } from 'components/molecules';
import useMissionFormContext from './useMissionFormContext';

const Wrapper = styled.div`
  width: 716px;
`;

const MissionFormStepper: React.VFC = () => {
  const {
    currentStepIndex,
    stepperSteps,
    moveStepTo,
  } = useMissionFormContext();

  return useMemo(
    () => (
      <Wrapper data-testid="mission-form-stepper">
        <Stepper
          steps={stepperSteps}
          currentStepIndex={currentStepIndex}
          onClickStep={moveStepTo}
        />
      </Wrapper>
    ),
    [currentStepIndex, moveStepTo, stepperSteps],
  );
};

export default MissionFormStepper;
