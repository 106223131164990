import React, { useCallback } from 'react';
import styled from 'styled-components/macro';
import { Method } from 'axios';
import { useTranslation } from 'react-i18next';
import { ButtonM } from 'components/atoms';
import { Modal, ModalContent, ModalFooter } from 'components/molecules';
import {
  errorDialogActions,
  getIsShown,
  getOptions,
  ErrorDialogTypes,
} from 'modules/errorDialog';
import { useSelector, useDispatch } from 'react-redux';
import ErrorTypes from 'constants/errorTypes';

const isGetRequest = (method?: Method | undefined) =>
  !!method && (method === 'get' || method === 'GET');

const shouldShowReloadButton = ({
  dialogType,
  method,
}: {
  dialogType?: ErrorDialogTypes;
  method?: Method;
}) => {
  if (!dialogType) return isGetRequest(method);
  return dialogType === ErrorDialogTypes.RELOAD;
};

const shouldShowCloseButton = ({
  dialogType,
  method,
}: {
  dialogType?: ErrorDialogTypes;
  method?: Method;
}) => {
  if (!dialogType) return !isGetRequest(method);
  return dialogType === ErrorDialogTypes.CLOSE;
};

const Content = styled.div`
  margin-bottom: 16px;
  line-height: 1.5;
  text-align: center;
`;

function ErrorDialog() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const isDialogShown = useSelector(getIsShown);
  const options = useSelector(getOptions);

  const { message, method, errorType, type: dialogType, showCloseIcon } =
    options || {};

  const handleClickClose = useCallback(() => {
    dispatch(errorDialogActions.close());
    options?.onClose && options.onClose();
  }, [dispatch, options]);

  const handleClickReload = useCallback(() => {
    options?.onClose && options.onClose();
    window.location.reload();
  }, [options]);

  return (
    <Modal
      show={
        !!options && isDialogShown && errorType !== ErrorTypes.CANCELED_ERROR
      }
      closeModal={handleClickClose}
      hideCloseButton={!showCloseIcon}
    >
      <ModalContent data-testid="error-modal-content">
        <Content>
          {message &&
            message.split('\n').map((item, index) => (
              // eslint-disable-next-line react/no-array-index-key
              <p key={index}>{item}</p>
            ))}
        </Content>
      </ModalContent>
      <ModalFooter>
        {dialogType !== ErrorDialogTypes.INITIAL && (
          <>
            {shouldShowReloadButton({ dialogType, method }) && (
              <ButtonM buttonTheme="default" onClick={handleClickReload}>
                {t('button.reload')}
              </ButtonM>
            )}
            {shouldShowCloseButton({ dialogType, method }) && (
              <ButtonM buttonTheme="default" onClick={handleClickClose}>
                {t('button.close')}
              </ButtonM>
            )}
          </>
        )}
      </ModalFooter>
    </Modal>
  );
}

export default ErrorDialog;
