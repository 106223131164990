import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { ButtonM } from 'components/atoms';
import { Modal, ModalContent, ModalFooter } from 'components/molecules';

type Props = {
  show: boolean;
  onClose: () => void;
  onBulkScout: () => void;
};

const ScoutConfirmModal: FC<Props> = ({ show, onClose, onBulkScout }) => {
  const { t } = useTranslation(['scoutConfirm', 'common']);

  return (
    <Modal show={show} closeModal={onClose}>
      <ModalContent data-testid="modal-scout-confirm">
        {t('modal.scoutConfirm.content', { lng: 'ja' })}
      </ModalContent>
      <ModalFooter>
        <ButtonM
          buttonTheme="default"
          onClick={onClose}
          data-testid="modal-scout-confirm-cancel"
        >
          {t('common:button.cancel', { lng: 'ja' })}
        </ButtonM>
        <ButtonM buttonTheme="default" onClick={onBulkScout}>
          {t('common:button.ok', { lng: 'ja' })}
        </ButtonM>
      </ModalFooter>
    </Modal>
  );
};

export default ScoutConfirmModal;
