import React from 'react';
import theme from 'styles/theme';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { persistStore } from 'redux-persist';
import { ThemeProvider } from 'styled-components/macro';
import Routes from 'routes/Routes';
import store from 'store';
import { BrowserRouter } from 'react-router-dom';
import GlobalStyle from 'styles/global';
import { GlobalLayout } from 'components/templates';
import { localePath } from 'i18n/i18n';
import { initLocalStorage } from 'utils/storage';

// todo strict type
// @ts-ignore
const persistor = persistStore(store);

initLocalStorage();

export default function App() {
  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <ThemeProvider theme={theme}>
          <BrowserRouter basename={localePath}>
            <GlobalStyle />
            <GlobalLayout>
              <Routes />
            </GlobalLayout>
          </BrowserRouter>
        </ThemeProvider>
      </PersistGate>
    </Provider>
  );
}
