import React, { FC } from 'react';
import styled from 'styled-components/macro';
import { Mission } from 'proto/v1/apimodel/apimodel';
import { UrlLink } from 'components/atoms';

const CompanyUrlLink = styled(UrlLink)`
  margin-bottom: 24px;
`;

const CompanyUrl = styled.span`
  display: inline-block;
  max-width: 248px;
  overflow: hidden;
  text-decoration: underline;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

type CompanyUrlFieldProps = {
  companyUrl: Mission['companyUrl'];
};

const CompanyUrlField: FC<CompanyUrlFieldProps> = ({ companyUrl }) => {
  return (
    <CompanyUrlLink href={companyUrl} data-testid="company-url">
      <CompanyUrl>{companyUrl}</CompanyUrl>
    </CompanyUrlLink>
  );
};

export default CompanyUrlField;
