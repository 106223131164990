import React, { FC } from 'react';
import styled from 'styled-components';
import useSupporterFilter from 'components/pages/Private/Supporters/useSupporterFilter';
import { useTranslation } from 'react-i18next';
import { AddressPicker, SupportAreaPicker } from '..';

const Pickers = styled.div`
  display: flex;
  align-items: center;
  height: 68px;
`;

type SupporterFilterProps = ReturnType<typeof useSupporterFilter>;

const SupporterFilter: FC<SupporterFilterProps> = props => {
  const {
    form,
    supportersFilterValues,
    selectedPicker,
    togglePicker,
    closePicker,
    isActiveSupportAreaPicker,
    isActiveAddressPicker,
    handleSupportAreaPickerSubmit,
    handleAddressPickerSubmit,
  } = props;

  const { t } = useTranslation(['common']);
  return (
    <Pickers>
      <SupportAreaPicker
        isActive={isActiveSupportAreaPicker}
        isSelected={selectedPicker === 'supportArea'}
        onClickTag={() => togglePicker('supportArea')}
        onClickOverlay={closePicker}
        onSubmit={handleSupportAreaPickerSubmit}
        form={{ supportAreas: form?.supportAreas }}
        defaultValues={{
          supportAreaIds: supportersFilterValues.supportAreaIds,
        }}
      />
      <AddressPicker
        isActive={isActiveAddressPicker}
        isSelected={selectedPicker === 'address'}
        onClickTag={() => togglePicker('address')}
        onClickOverlay={closePicker}
        onSubmit={handleAddressPickerSubmit}
        form={{
          japanPrefectures: form?.japanPrefectures,
          countries: [
            {
              countryId: '',
              name: t('common:selectBox.notSelected'),
              phoneCountryCode: '',
            },
            ...(form?.countries || []),
          ],
        }}
        defaultValues={{
          countryId: supportersFilterValues.countryId,
          japanPrefectureIds: supportersFilterValues.japanPrefectureIds,
        }}
      />
    </Pickers>
  );
};

export default SupporterFilter;
