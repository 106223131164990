import React, { FC, ChangeEvent } from 'react';
import { useTranslation } from 'react-i18next';
import { MissionDemandFormValue } from 'types/form';
import { isEqualProps } from 'utils/object';
import { Checkbox } from 'components/atoms';
import { MissionDemandFieldItem } from 'components/molecules';

type FurnishedOfficeRentalRequestFieldProps = {
  value: MissionDemandFormValue['wantFurnishedOfficeRentalRequest'];
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
};

const FurnishedOfficeRentalRequestField: FC<FurnishedOfficeRentalRequestFieldProps> = props => {
  const { value, onChange } = props;

  const { t } = useTranslation('missionSetting');

  return (
    <MissionDemandFieldItem>
      <Checkbox
        name="missionDemand.wantFurnishedOfficeRentalRequest"
        checked={value}
        onChange={onChange}
        aria-label={t(
          'field.missionDemand.checkbox.wantFurnishedOfficeRentalRequest',
        )}
      >
        {t('field.missionDemand.checkbox.wantFurnishedOfficeRentalRequest')}
      </Checkbox>
    </MissionDemandFieldItem>
  );
};

export default React.memo(
  FurnishedOfficeRentalRequestField,
  (prevProps, nextProps) => isEqualProps(prevProps, nextProps, ['value']),
);
