import React, { FC, ComponentProps } from 'react';
import styled, { css } from 'styled-components/macro';
import { Link } from 'react-router-dom';
import { Card, ImageCenter, FlexContainer } from 'components/atoms';
import * as PROTECTED_ROUTES from 'routes/constants/protected';
import { GetMissionProductsResponse_MissionProductSummary as MissionProduct } from 'proto/v1/commonservice/commonservice';
import { imageSelector } from 'utils/imageSelector';
import { noImageSquare } from 'assets/png';
import theme from 'styles/theme';
import typography from 'styles/typography';
import { Hash } from 'assets/svg';
import { useTranslation } from 'react-i18next';

const StyledCard = styled(Card)`
  width: 369px;
  border: none;
  background-color: transparent;

  > div {
    padding: 0;
  }
`;

const ProductImage = styled.div<{ image: string | undefined }>`
  display: flex;
  align-items: flex-end;
  width: 100%;
  height: 207px;
  margin-bottom: 8px;
  padding: 8px;
  border-radius: 8px;
  ${({ image }) =>
    css`
      background-repeat: no-repeat;
      background-position: center;
      background: linear-gradient(
          180deg,
          rgba(102, 102, 102, 0) 62.8%,
          rgba(0, 0, 0, 0.4) 100%
        ),
        url(${image});
      background-size: contain;
    `}
`;

const Company = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
`;

const IconImage = styled(ImageCenter)`
  padding: 4px;
  border-radius: 4px;
  background-origin: content-box;
  background-color: ${theme.white};
`;

const CompanyName = styled.div`
  margin-left: 8px;
  color: ${theme.white};
`;

const ProductName = styled.div`
  ${typography.textCardTitle}
  width: 100%;
  margin-bottom: 8px;
  color: ${theme.textPrimary};
`;

const ProductOverviewWrapper = styled(FlexContainer)`
  width: 100%;
  margin-bottom: 8px;
`;

const ProductOverview = styled.div`
  ${typography.labelDefault}
  color: ${theme.textPrimary};
`;

const ProductTagsHash = styled(Hash)`
  margin-right: 7px;
`;

const ProductTagsWrapper = styled(FlexContainer)`
  align-items: center;
  width: 100%;
  color: ${theme.textSecondary};
`;

const ProductTags = styled.div`
  ${typography.labelTags}
  flex: 1;
  overflow: hidden;
  color: ${theme.textCardSecondary};
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const MemberBenefitBackground = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 48px;
  height: 48px;
  border-radius: 8px 0 0 0;
  background-image: linear-gradient(
    135deg,
    #f83d60,
    #f83d60 34px,
    transparent 34px,
    transparent
  );
`;

const MemberBenefitText = styled.div<{ lng: string }>`
  position: absolute;
  top: ${({ lng }) => (lng.startsWith('ja') ? '10px' : '12px')};
  left: ${({ lng }) => (lng.startsWith('ja') ? '-16px' : '-14px')};
  width: 68px;
  transform: rotate(-45deg);
  color: ${theme.white};
  font-size: 10px;
  font-weight: bold;
  line-height: 12px;
  text-align: center;
`;

const Target = styled.div<{ lng: string }>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: ${({ lng }) => (lng.startsWith('ja') ? '40px' : '54px')};
  height: 20px;
  margin-left: 6px;
  border: 1px solid ${theme.borderDefault};
  border-radius: 4px;
  background: ${theme.white};
  color: ${theme.textCardSecondary};
  font-size: 10px;
  line-height: 14px;
`;

export type MissionProductCardProps = ComponentProps<typeof Card> & {
  product: MissionProduct;
};

const MissionProductCard: FC<MissionProductCardProps> = props => {
  const { t, i18n } = useTranslation(['productList']);
  const { missionId, companyName, iconImage, product } = props.product;
  if (!product) return null;
  return (
    <StyledCard {...props}>
      <Link
        style={{ textDecoration: 'none' }}
        to={{
          pathname: `${PROTECTED_ROUTES.PRIVATE_PRODUCTS}/${missionId}`,
          hash: `${product.missionProductId}`,
        }}
      >
        <ProductImage image={imageSelector(product.image, 'small')}>
          <Company>
            <IconImage
              imageSrc={imageSelector(iconImage, 'small') ?? noImageSquare}
              width={48}
              height={48}
            />
            <CompanyName>{companyName}</CompanyName>
          </Company>
          {!!product.memberBenefit && (
            <>
              <MemberBenefitBackground />
              <MemberBenefitText lng={i18n.language}>
                {t('cards.memberBenefit')}
              </MemberBenefitText>
            </>
          )}
        </ProductImage>
        <ProductName>{product.name}</ProductName>
        <ProductOverviewWrapper alignItems={'center'}>
          <ProductOverview>{product.overview}</ProductOverview>
          {product.productTarget?.isForBusiness && (
            <Target lng={i18n.language}>{t('cards.forBusiness')}</Target>
          )}
          {product.productTarget?.isForCustomer && (
            <Target lng={i18n.language}>{t('cards.forCustomer')}</Target>
          )}
        </ProductOverviewWrapper>
        <ProductTagsWrapper>
          <ProductTagsHash />
          <ProductTags>{product.tags.join(' / ')}</ProductTags>
        </ProductTagsWrapper>
      </Link>
    </StyledCard>
  );
};

export default React.memo(
  MissionProductCard,
  (prevProps, nextProps) => prevProps.product === nextProps.product,
);
